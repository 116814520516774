import React, {Fragment} from "react";
import {getAccountFio} from "@vokymlak/kabinet-ui";

function TicketInfoView(props) {
  const {object} = props;

  function getAmountCosts(object) {
    let cost = 0;

    if (!!object && object.labor_costs) {
      object.labor_costs.forEach(c => {
        cost += c.time_spent
      })
    }

    return cost
  }

  return (
    <Fragment>
      <div style={{
        display: "flex",
        alignItems: "center",
        justifyContent: " flex-start",
        width: "100%",
        marginTop: 8,
        marginBottom: 4
      }}>

        {/*<div style={{*/}
        {/*  padding: "4px 8px",*/}
        {/*  backgroundColor: ((!!object.quantity && (object.quantity > 0 ? "#2e7d3233" : "#bf360c33")) || "#20202010"),*/}
        {/*  borderRadius: 4,*/}
        {/*  marginRight: 12,*/}
        {/*  textAlign: "right"*/}
        {/*}}>*/}
        {/*  <div style={{*/}
        {/*    fontSize: 21,*/}
        {/*    fontWeight: 700,*/}
        {/*  }}>*/}
        {/*    {!object.is_pcs && (*/}
        {/*      <>*/}
        {/*        {(!!object.quantity && !!object.measurement_unit_type ? (object.quantity / object.measurement_unit_type.ratio).toFixed(3) : !!object.quantity && object.quantity.toFixed(3)) || "0"}*/}
        {/*        {!!object.measurement_unit_type && <span style={{*/}
        {/*          marginLeft: 4,*/}
        {/*          fontWeight: 400,*/}
        {/*          fontSize: 13*/}
        {/*        }}>{object.measurement_unit_type.designation + '.'}</span>}*/}
        {/*      </>*/}
        {/*    )}*/}

        {/*    {object.is_pcs && (*/}
        {/*      <>*/}
        {/*        {(!!object.quantity && (object.quantity)) || "0"}*/}
        {/*      </>*/}
        {/*    )}*/}
        {/*  </div>*/}
        {/*  <div style={{*/}
        {/*    fontSize: 12,*/}
        {/*    fontWeight: 600,*/}
        {/*  }}>*/}
        {/*    кол.*/}
        {/*  </div>*/}
        {/*</div>*/}

        {!!object.ticket_topic && (
          <>
            <div style={{
              padding: "4px 8px",
              backgroundColor: "#20202010",
              borderRadius: 4,
              marginRight: 12,
              textAlign: "left"
            }}>
              <div style={{
                fontSize: 12,
                fontWeight: 400,
              }}>
                Тема
              </div>
              <div style={{
                fontSize: 15,
                fontWeight: 700,
              }}>
                {object.ticket_topic.title}
              </div>
            </div>
          </>
        )}

        {/*<div style={{*/}
        {/*  padding: "4px 8px",*/}
        {/*  backgroundColor: "#20202010",*/}
        {/*  borderRadius: 4,*/}
        {/*  marginRight: 12,*/}
        {/*  textAlign: "left"*/}
        {/*}}>*/}
        {/*  <div style={{*/}
        {/*    fontSize: 12,*/}
        {/*    fontWeight: 400,*/}
        {/*  }}>*/}
        {/*    Автор*/}
        {/*  </div>*/}
        {/*  <div style={{*/}
        {/*    fontSize: 15,*/}
        {/*    fontWeight: 700,*/}
        {/*  }}>*/}
        {/*    {getAccountFio(object.author)}*/}
        {/*  </div>*/}
        {/*</div>*/}

        {!!object.ticket_topic && !!object.executor && (
          <div style={{
            height: 32,
            width: 2,
            backgroundColor: "#20202010",
            borderRadius: 4,
            marginRight: 12,
          }}/>
        )}

        {!!object.executor && (
          <>
            <div style={{
              padding: "4px 8px",
              backgroundColor: "#20202010",
              borderRadius: 4,
              marginRight: 12,
              textAlign: "left"
            }}>
              <div style={{
                fontSize: 12,
                fontWeight: 400,
              }}>
                Исполнитель
              </div>
              <div style={{
                fontSize: 15,
                fontWeight: 700,
              }}>
                {getAccountFio(object.executor)}
              </div>
            </div>
          </>
        )}

        {((!!object.time_estimate || !!object.labor_costs) && (!!object.ticket_topic || !!object.executor)) && (
          <>
            <div style={{
              height: 32,
              width: 2,
              backgroundColor: "#20202010",
              borderRadius: 4,
              marginRight: 12,
            }}/>
          </>
        )}

        {!!object.time_estimate && (
          <>

            <div style={{
              padding: "4px 8px",
              backgroundColor: "#20202010",
              borderRadius: 4,
              marginRight: 12,
              textAlign: "right"
            }}>
              <div style={{
                fontSize: 12,
                fontWeight: 400,
              }}>
                Оценка
              </div>
              <div style={{
                fontSize: 15,
                fontWeight: 700,
              }}>
                {object.time_estimate / 100}
                <span style={{
                  marginLeft: 4,
                  fontSize: 12,
                  fontWeight: 400,
                }}>(ч.)</span>
              </div>
            </div>
          </>
        )}

        {!!object.labor_costs && (
          <>
            <div style={{
              padding: "4px 8px",
              backgroundColor: "#20202010",
              borderRadius: 4,
              marginRight: 12,
              textAlign: "right"
            }}>
              <div style={{
                fontSize: 12,
                fontWeight: 400,
              }}>
                Затраты
              </div>
              <div style={{
                fontSize: 15,
                fontWeight: 700,
              }}>
                {getAmountCosts(object) / 100}
                <span style={{
                  marginLeft: 4,
                  fontSize: 12,
                  fontWeight: 400,
                }}>(ч.)</span>
              </div>
            </div>
          </>
        )}

      </div>
    </Fragment>
  );
}

export default TicketInfoView;
