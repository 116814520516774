import React, {useEffect, useRef, useState} from "react";
import { ROOT_URL } from "../../../../../config/url";
import {useList, useAction} from "@vokymlak/kabinet-ui";
import moment from "moment/moment";

function useContractSpecifications(props) {
  const [selectSpecification, setSelectSpecification] = useState(null);

  const {
    list: [list, count, setList],
    update: [isUpdateList, setIsUpdateList, updateList],
    search: [searchQuery, setSearchQuery],
  } = useList({
    apiUrl: "/api/contract/" + props.contract.uuid + "/specifications",
    rootUrl: ROOT_URL,
    isSearch: true
  });

  const inputSearchText = useRef(null);

  function onChangeSearchText() {
    setSearchQuery(inputSearchText.current.value);
  }

  const { setAction } = useAction(props.contract.uuid, () => updateList());

  useEffect(() => {
    if (!!list) {
      const listUuid = list.map((i) => i.uuid);
      setAction([...listUuid, props.contract.uuid]);
    } else {
      setAction(props.contract.uuid);
    }
  }, [list]);

  const [modalSpecificationImport, setModalSpecificationImport] = useState(null);
  const [modalSpecificationAdd, setModalSpecificationAdd] = useState(null);
  const [modalSpecificationEdit, setModalSpecificationEdit] = useState(null);

  const [isModalNewContractsDelete, setIsModalNewContractsDelete] = useState(false);

  const isNewAdded = (list) => {
    let isNewAdded = false;

    if (!!list) {
      list.forEach(item => {
        if (moment(item.created_at).format("YYYY-MM-DD")
          === moment().format("YYYY-MM-DD")) {
          isNewAdded = true;
        }
      })
    }

    return isNewAdded
  }

  return {
    searchQuery,
    inputSearchText,
    onChangeSearchText,

    object: props.contract,

    list,
    setList,
    count,

    setIsUpdateList,
    isUpdateList,

    modalSpecificationAdd,
    setModalSpecificationAdd,

    modalSpecificationEdit,
    setModalSpecificationEdit,

    setModalSpecificationImport,
    modalSpecificationImport,

    selectSpecification,
    setSelectSpecification,

    isNewAdded,
    isModalNewContractsDelete,
    setIsModalNewContractsDelete,
  };
}

export default useContractSpecifications;
