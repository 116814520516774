// Роль администратора.
export const ROLE_ADMINISTRATOR = 'ADMINISTRATOR'
export const ROLE_DIRECTOR = 'DIRECTOR'
export const ROLE_EMPLOYEE = 'EMPLOYEE'
export const ROLE_CLIENT = 'CLIENT'

// Код ошибки таймаута запроса.
export const ERROR_CODE_ECONNABORTED = 'ECONNABORTED'
// Код ошибки отмены запроса.
export const ERR_CANCELED = 'ERR_CANCELED'

// Код ошибки 400.
export const ERR_BAD_REQUEST = 'ERR_BAD_REQUEST'

// Тикет идея.
export const TICKET_TYPE_IDEA = 'IDEA'
// Тикет ошибка.
export const TICKET_TYPE_ERROR = 'ERROR'

// Тикет запланировано.
export const TICKET_STATUS_PLANNED = 'PLANNED'
// Тикет завершено.
export const TICKET_STATUS_COMPLETED = 'COMPLETED'
// Тикет отклонено.
export const TICKET_STATUS_REJECTED = 'REJECTED'

// Промежуточное исполнение.
export const LOGIC_INTERMEDIATE_EXECUTION = "INTERMEDIATE_EXECUTION"
// Дублирование контроля.
export const LOGIC_DUPLICATION_OF_CONTROL = "DUPLICATION_OF_CONTROL"
// Плановая дата исполнения.
export const LOGIC_PLANNED_DATE_EXECUTION = "PLANNED_DATE_EXECUTION"

// Текущий.
export const CONTRACT_VALIDITY_TYPE_CURRENT = "CURRENT"
// Переходящий.
export const CONTRACT_VALIDITY_TYPE_ROLLING = "ROLLING"
// Долгосрочный.
export const CONTRACT_VALIDITY_TYPE_LASTING = "LASTING"
