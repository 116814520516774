import React, {
  Fragment,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import "./HandbookArticle.scss";
import axios from "axios";
import { ROOT_URL } from "../../../../config/url";
import {
  useNotification,
  getErrorMessageByKey,
  ButtonIcon, newUuid,
} from "@vokymlak/kabinet-ui";
import styleEditPanel from "./EditPanel.module.scss";
import styleArticle from "./Article.module.scss";
import ModalAddEditorLink from "./ModalAddEditorLink";
import ModalAddEditorVideo from "./ModalAddEditorVideo";
import ModalAddEditorImage from "./ModalAddEditorImage";
import ModalAddEditorTable from "./ModalAddEditorTable";
import ModalAddEditorLinkFile from "./ModalAddEditorLinkFile";

const replaceNewLinesWithParagraphs = (text) => {
  const paragraphs = text.split(/\r\n|\n\r|\r|\n/);
  return paragraphs
    .map((paragraph) => `<p>${paragraph}</p>`)
    .join("")
    .replaceAll("<p></p>", "");
};

function escape_text(text) {
  let map = {
    "&": "&amp;",
    "<": "&lt;",
    ">": "&gt;",
    '"': "&quot;",
    "'": "&#039;",
  };
  return text.replace(/[&<>"']/g, function (m) {
    return map[m];
  });
}

export const HandbookArticleEditor = (props) => {
  const {
    object,

    setIsUpdateObject,
    isUpdateObject,
  } = props;

  const { notification } = useNotification();

  // TODO https://stackoverflow.com/questions/60581285/execcommand-is-now-obsolete-whats-the-alternative
  // TODO https://snipp.ru/php/contenteditable
  // TODO http://www.foreui.com/articles/Key_Code_Table.htm

  const editable = useRef(null);

  const [isSave, setIsSave] = useState(false);
  const [editorContent, setEditorContent] = useState("");

  useLayoutEffect(() => {
    // Переносы строк.
    document.execCommand("defaultParagraphSeparator", false, "p");
  }, []);

  useEffect(() => {
    const backColor = document.queryCommandSupported("backColor");
    console.log("backColor:", backColor);
    const heading = document.queryCommandSupported("heading\t");
    console.log("heading:", heading);
    const justifyLeft = document.queryCommandSupported("justifyLeft");
    console.log("justifyLeft:", justifyLeft);
    const bold = document.queryCommandSupported("bold");
    console.log("bold:", bold);
    const justifyRight = document.queryCommandSupported("justifyRight");
    console.log("justifyRight:", justifyRight);
    const contentReadOnly = document.queryCommandSupported("contentReadOnly");
    console.log("contentReadOnly:", contentReadOnly);
    const increaseFontSize = document.queryCommandSupported("increaseFontSize");
    console.log("increaseFontSize:", increaseFontSize);
    const outdent = document.queryCommandSupported("outdent");
    console.log("outdent:", outdent);
    const copy = document.queryCommandSupported("copy");
    console.log("copy:", copy);
    const indent = document.queryCommandSupported("indent");
    console.log("indent:", indent);
    const paste = document.queryCommandSupported("paste");
    console.log("paste:", paste);
    const createLink = document.queryCommandSupported("createLink");
    console.log("createLink:", createLink);
    const insertBrOnReturn = document.queryCommandSupported("insertBrOnReturn");
    console.log("insertBrOnReturn:", insertBrOnReturn);
    const redo = document.queryCommandSupported("redo");
    console.log("redo:", redo);
    const cut = document.queryCommandSupported("cut");
    console.log("cut:", cut);
    const insertHorizontalRule = document.queryCommandSupported(
      "insertHorizontalRule"
    );
    console.log("insertHorizontalRule:", insertHorizontalRule);
    const removeFormat = document.queryCommandSupported("removeFormat");
    console.log("removeFormat:", removeFormat);
    const decreaseFontSize = document.queryCommandSupported("decreaseFontSize");
    console.log("decreaseFontSize:", decreaseFontSize);
    const insertHTML = document.queryCommandSupported("insertHTML");
    console.log("insertHTML:", insertHTML);
    const selectAll = document.queryCommandSupported("selectAll");
    console.log("selectAll:", selectAll);
    const isDelete = document.queryCommandSupported("delete");
    console.log("delete:", isDelete);
    const insertImage = document.queryCommandSupported("insertImage");
    console.log("insertImage:", insertImage);
    const strikeThrough = document.queryCommandSupported("strikeThrough");
    console.log("strikeThrough:", strikeThrough);

    // enableInlineTableEditing	insertOrderedList	subscript
    // enableObjectResizing	insertUnorderedList	superscript
    // fontName	insertParagraph	underline
    // fontSize	insertText	undo
    // foreColor	italic	unlink
    // formatBlock	justifyCenter	useCSS
    // forwardDelete	justifyFull	styleWithCSS
  }, []);

  useEffect(() => {
    if (object) {
      setEditorContent(
        !object.article ||
          (!!object.article &&
            (object.article === "<p></p>" || object.article.length === 0))
          ? "<p><br></p>"
          : object.article
      );
    } else {
      setEditorContent(null);
    }
  }, [object]);

  useEffect(() => {
    // console.log(editorContent);

    let content = editorContent;
    if (
      !content ||
      (!!content &&
        (content === "<p></p>" ||
          content === "<p><br></p>" ||
          content.length === 0))
    ) {
      content = null;
    }
    if (!!object) {
      if (content !== object.article) {
        setIsSave(true);
      } else {
        setIsSave(false);
      }
    }
  }, [editorContent]);

  const changeEditor = () => {
    let content = editable.current.innerHTML;
    if (
      !content ||
      (!!content && (content === "<p></p>" || content.length === 0))
    ) {
      content = "<p><br></p>";
      editable.current.innerHTML = content;
    }
    setEditorContent(content);
  };

  const changeSave = () => {
    let content = editorContent;
    if (
      content === "<p><br></p>" ||
      content === "<p></p>" ||
      content.length === 0
    ) {
      content = null;
    }

    const jsonData = JSON.stringify({
      article: content,
    });

    axios
      .put("/api/handbook-article/" + object.uuid + "/edit", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        setIsUpdateObject(!isUpdateObject);
        notification("Изменения сохранены", "green");
        setIsSave(false);
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  // TODO (placeholder) Удаляет контент нужно сделать так если вставить курсов  пустой едитор то вернуть <p><br></p>
  // useEffect(() => {
  //   const handleFocusOut = (e) => {
  //     e.preventDefault()
  //     const element = editable.current;
  //     if (!element.textContent.replace(' ', '').length) {
  //       element.innerHTML = '';
  //     }
  //   };
  //
  //   document.body.addEventListener('focusout', handleFocusOut);
  //
  //   return () => {
  //     document.body.removeEventListener('focusout', handleFocusOut);
  //   };
  // }, []);

  useEffect(() => {
    const handleKeyDown = (e) => {
      // Табуляция
      if (e.keyCode === 9) {
        e.preventDefault();

        const editor = e.target;
        const doc = editor.ownerDocument.defaultView;
        const sel = doc.getSelection();
        const range = sel.getRangeAt(0);
        const tabNode = document.createTextNode("\t");
        range.insertNode(tabNode);
        range.setStartAfter(tabNode);
        range.setEndAfter(tabNode);
        sel.removeAllRanges();
        sel.addRange(range);
      } else if (e.ctrlKey && e.keyCode === 83) {
        e.preventDefault();

        // Сохраняем изменение.
        changeSave();

        const editor = e.target;
        const doc = editor.ownerDocument.defaultView;
        const sel = doc.getSelection();
        const range = sel.getRangeAt(0);
        const node = document.createTextNode("");
        range.insertNode(node);
        range.setStartAfter(node);
        range.setEndAfter(node);
        sel.removeAllRanges();
        sel.addRange(range);
      }

      // На все кнопки сохраняем изменение.
      changeEditor();
    };

    document.body.addEventListener("keydown", handleKeyDown);

    return () => {
      document.body.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handlePaste = (event) => {
    event.preventDefault();
    const text = event.clipboardData.getData("text/plain");
    document.execCommand(
      "insertHtml",
      false,
      replaceNewLinesWithParagraphs(escape_text(text))
    );
  };

  const textEdit = (command, tag) => {
    console.log(command);
    document.execCommand(command, false, tag);
  };


  const getRange = () => {
    // Сохраняем текущее выделение
    let selection = window.getSelection();
    let range = selection.getRangeAt(0);

    return range

  }

  const [isModalAddEditorLink, setIsModalAddEditorLink] = useState(null);
  const [isModalAddEditorVideo, setIsModalAddEditorVideo] = useState(null);
  const [isModalAddEditorImage, setIsModalAddEditorImage] = useState(null);
  const [isModalAddEditorFile, setIsModalAddEditorFile] = useState(null);
  const [isModalAddEditorTable, setIsModalAddEditorTable] = useState(null);


  const selectFontName = useRef(null);
  const selectFontSize = useRef(null);
  const inputForeColor = useRef(null);
  const inputHiliteColor = useRef(null);

  const changeTextEdit = (command, val) => {
    document.execCommand('styleWithCSS', false, true);
    document.execCommand(command, false, val);
    document.execCommand('styleWithCSS', false, false);
  };

  const getNode = () => {

    let selection = window.getSelection();
    let range = selection.getRangeAt(0);

    console.log(range.commonAncestorContainer)
  }

  return (
    !!object && (
      <Fragment>
        <div className={styleEditPanel._}>
          <button
            onClick={() => textEdit("bold")}
            style={{
              backgroundColor: "inherit",
              border: "none",
            }}
          >
            <ButtonIcon
              tooltipText={"Жирный"}
              iconName={"edit-format-bold"}
              iconColor={"#202020"}
              size={20}
              style={{ margin: 4 }}
            />
          </button>
          <button
            onClick={() => textEdit("italic")}
            style={{
              backgroundColor: "inherit",
              border: "none",
            }}
          >
            <ButtonIcon
              tooltipText={"Курсив"}
              iconName={"edit-format-italic"}
              iconColor={"#202020"}
              size={20}
              style={{ margin: 4 }}
            />
          </button>
          <button
            onClick={() => textEdit("underline")}
            style={{
              backgroundColor: "inherit",
              border: "none",
            }}
          >
            <ButtonIcon
              tooltipText={"Подчеркнутый"}
              iconName={"edit-format-underlined"}
              iconColor={"#202020"}
              size={20}
              style={{ margin: 4 }}
            />
          </button>
          <button
            onClick={() => textEdit("strikethrough")}
            style={{
              backgroundColor: "inherit",
              border: "none",
            }}
          >
            <ButtonIcon
              tooltipText={"Перечёркнутый"}
              iconName={"edit-format-strikethrough"}
              iconColor={"#202020"}
              size={20}
              style={{ margin: 4 }}
            />
          </button>
          <button
            onClick={() => textEdit("superscript")}
            style={{
              backgroundColor: "inherit",
              border: "none",
            }}
          >
            <ButtonIcon
              tooltipText={"Верхний индекс"}
              iconName={"edit-superscript"}
              iconColor={"#202020"}
              size={20}
              style={{ margin: 4 }}
            />
          </button>
          <button
            onClick={() => textEdit("subscript")}
            style={{
              backgroundColor: "inherit",
              border: "none",
            }}
          >
            <ButtonIcon
              tooltipText={"Нижний индекс"}
              iconName={"edit-subscript"}
              iconColor={"#202020"}
              size={20}
              style={{ margin: 4 }}
            />
          </button>

          <div className={styleEditPanel._hr}></div>

          <button
            onClick={() => textEdit("formatBlock", "h1")}
            style={{
              backgroundColor: "inherit",
              border: "none",
            }}
          >
            <ButtonIcon
              tooltipText={"Заголовок 1"}
              iconName={"edit-format-h1"}
              iconColor={"#202020"}
              size={20}
              style={{ margin: 4 }}
            />
          </button>
          <button
            onClick={() => textEdit("formatBlock", "h2")}
            style={{
              backgroundColor: "inherit",
              border: "none",
            }}
          >
            <ButtonIcon
              tooltipText={"Заголовок 2"}
              iconName={"edit-format-h2"}
              iconColor={"#202020"}
              size={20}
              style={{ margin: 4 }}
            />
          </button>
          <button
            onClick={() => textEdit("formatBlock", "h3")}
            style={{
              backgroundColor: "inherit",
              border: "none",
            }}
          >
            <ButtonIcon
              tooltipText={"Заголовок 3"}
              iconName={"edit-format-h3"}
              iconColor={"#202020"}
              size={20}
              style={{ margin: 4 }}
            />
          </button>
          <button
            onClick={() => textEdit("formatBlock", "p")}
            style={{
              backgroundColor: "inherit",
              border: "none",
            }}
          >
            <ButtonIcon
              tooltipText={"Параграф"}
              iconName={"edit-format-paragraph"}
              iconColor={"#202020"}
              size={20}
              style={{ margin: 4 }}
            />
          </button>
          <button
            onClick={() => textEdit("formatBlock", "blockquote")}
            style={{
              backgroundColor: "inherit",
              border: "none",
            }}
          >
            <ButtonIcon
              tooltipText={"Цитата"}
              iconName={"edit-format-quote"}
              iconColor={"#202020"}
              size={20}
              style={{ margin: 4 }}
            />
          </button>

          <div className={styleEditPanel._hr}></div>

          <button
            onClick={() => textEdit("insertUnorderedList")}
            style={{
              backgroundColor: "inherit",
              border: "none",
            }}
          >
            <ButtonIcon
              tooltipText={"Маркированный список"}
              iconName={"edit-format-list-bulleted"}
              iconColor={"#202020"}
              size={20}
              style={{ margin: 4 }}
            />
          </button>
          <button
            onClick={() => textEdit("insertOrderedList")}
            style={{
              backgroundColor: "inherit",
              border: "none",
            }}
          >
            <ButtonIcon
              tooltipText={"Нумерованный список"}
              iconName={"edit-format-list-numbered"}
              iconColor={"#202020"}
              size={20}
              style={{ margin: 4 }}
            />
          </button>

          <div className={styleEditPanel._hr}></div>

          <button
            onClick={() => textEdit("justifyLeft")}
            style={{
              backgroundColor: "inherit",
              border: "none",
            }}
          >
            <ButtonIcon
              tooltipText={"По левому краю"}
              iconName={"edit-format-align-left"}
              iconColor={"#202020"}
              size={20}
              style={{ margin: 4 }}
            />
          </button>
          <button
            onClick={() => textEdit("justifyCenter")}
            style={{
              backgroundColor: "inherit",
              border: "none",
            }}
          >
            <ButtonIcon
              tooltipText={"По центру"}
              iconName={"edit-format-align-center"}
              iconColor={"#202020"}
              size={20}
              style={{ margin: 4 }}
            />
          </button>
          <button
            onClick={() => textEdit("justifyRight")}
            style={{
              backgroundColor: "inherit",
              border: "none",
            }}
          >
            <ButtonIcon
              tooltipText={"По правому краю"}
              iconName={"edit-format-align-right"}
              iconColor={"#202020"}
              size={20}
              style={{ margin: 4 }}
            />
          </button>
          <button
            onClick={() => textEdit("justifyFull")}
            style={{
              backgroundColor: "inherit",
              border: "none",
            }}
          >
            <ButtonIcon
              tooltipText={"По ширине"}
              iconName={"edit-format-align-justify"}
              iconColor={"#202020"}
              size={20}
              style={{ margin: 4 }}
            />
          </button>

          <div className={styleEditPanel._hr}></div>

          <button
            onClick={() => textEdit("insertHorizontalRule")}
            style={{
              backgroundColor: "inherit",
              border: "none",
            }}
          >
            <ButtonIcon
              tooltipText={"Горизонтальная линия"}
              iconName={"edit-horizontal-rule"}
              iconColor={"#202020"}
              size={20}
              style={{ margin: 4 }}
            />
          </button>

          <div className={styleEditPanel._hr}></div>

          <button
            onClick={() => textEdit("undo")}
            style={{
              backgroundColor: "inherit",
              border: "none",
            }}
          >
            <ButtonIcon
              tooltipText={"Отмена действия"}
              iconName={"edit-undo"}
              iconColor={"#202020"}
              size={20}
              style={{ margin: 4 }}
            />
          </button>
          <button
            onClick={() => textEdit("redo")}
            style={{
              backgroundColor: "inherit",
              border: "none",
            }}
          >
            <ButtonIcon
              tooltipText={"Повтор действия"}
              iconName={"edit-redo"}
              iconColor={"#202020"}
              size={20}
              style={{ margin: 4 }}
            />
          </button>
          <button
            onClick={() => textEdit("delete")}
            style={{
              backgroundColor: "inherit",
              border: "none",
            }}
          >
            <ButtonIcon
              tooltipText={"Удаление выделенного"}
              iconName={"edit-delete"}
              iconColor={"#202020"}
              size={20}
              style={{ margin: 4 }}
            />
          </button>
          <button
            onClick={() => textEdit("selectAll")}
            style={{
              backgroundColor: "inherit",
              border: "none",
            }}
          >
            <ButtonIcon
              tooltipText={"Выделить всё"}
              iconName={"edit-select-all"}
              iconColor={"#202020"}
              size={20}
              style={{ margin: 4 }}
            />
          </button>
          <button
            onClick={() => textEdit("removeFormat")}
            style={{
              backgroundColor: "inherit",
              border: "none",
            }}
          >
            <ButtonIcon
              tooltipText={"Очистить стили"}
              iconName={"edit-format-clear"}
              iconColor={"#202020"}
              size={20}
              style={{ margin: 4 }}
            />
          </button>
          <button
            onClick={() => textEdit("cut")}
            style={{
              backgroundColor: "inherit",
              border: "none",
            }}
          >
            <ButtonIcon
              tooltipText={"Вырезать"}
              iconName={"edit-cut"}
              iconColor={"#202020"}
              size={20}
              style={{ margin: 4 }}
            />
          </button>
          <button
            onClick={() => textEdit("copy")}
            style={{
              backgroundColor: "inherit",
              border: "none",
            }}
          >
            <ButtonIcon
              tooltipText={"Копировать"}
              iconName={"edit-content-copy"}
              iconColor={"#202020"}
              size={20}
              style={{ margin: 4 }}
            />
          </button>
          {isSave && (
            <ButtonIcon
              tooltipText={"Сохранить"}
              iconName={"custom-save"}
              iconColor={"#0061ff"}
              size={18}
              style={{ marginLeft: 12 }}
              onClick={() => changeSave()}
            />
          )}
        </div>
        <div className={styleEditPanel._}>
          <button
            onClick={() => setIsModalAddEditorLink(getRange())}
            style={{
              backgroundColor: "inherit",
              border: "none",
            }}
          >
            <ButtonIcon
              tooltipText={"Ссылка"}
              iconName={"edit-link"}
              iconColor={"#202020"}
              size={20}
              style={{ margin: 4 }}
            />
          </button>
          <button
            onClick={() => textEdit("unlink")}
            style={{
              backgroundColor: "inherit",
              border: "none",
            }}
          >
            <ButtonIcon
              tooltipText={"Удалить ссылку"}
              iconName={"edit-link-off"}
              iconColor={"#202020"}
              size={20}
              style={{ margin: 4 }}
            />
          </button>
          <button
            onClick={() => setIsModalAddEditorVideo(getRange())}
            style={{
              backgroundColor: "inherit",
              border: "none",
            }}
          >
            <ButtonIcon
              tooltipText={"Видео"}
              iconName={"edit-youtube-activity"}
              iconColor={"#202020"}
              size={20}
              style={{ margin: 4 }}
            />
          </button>
          <button
            onClick={() => setIsModalAddEditorImage(getRange())}
            style={{
              backgroundColor: "inherit",
              border: "none",
            }}
          >
            <ButtonIcon
              tooltipText={"Изображение"}
              iconName={"edit-add-photo-alternate"}
              iconColor={"#202020"}
              size={20}
              style={{ margin: 4 }}
            />
          </button>

          <button
            onClick={() => setIsModalAddEditorFile(getRange())}
            style={{
              backgroundColor: "inherit",
              border: "none",
            }}
          >
            <ButtonIcon
              tooltipText={"Файл"}
              iconName={"custom-folder"}
              iconColor={"#202020"}
              size={20}
              style={{ margin: 4 }}
            />
          </button>

          <select className="toolbar-font" ref={selectFontName}
                  onChange={() => changeTextEdit('fontName', selectFontName.current.value)}
          defaultValue={"none"}>
            <option value="none" disabled="disabled">Шрифт</option>
            <option value="Arial">Arial</option>
            <option value="Calibri">Calibri</option>
            {/*<option value="Courier New">Courier New</option>*/}
            {/*<option value="Georgia">Georgia</option>*/}
            {/*<option value="Impact">Impact</option>*/}
            {/*<option value="Tahoma">Tahoma</option>*/}
            <option value="Times New Roman">Times New Roman</option>
            {/*<option value="Verdana">Verdana</option>*/}
          </select>
          <select className="toolbar-size" ref={selectFontSize}
                  onChange={() => changeTextEdit('fontSize', selectFontSize.current.value)}
                  defaultValue={"none"}>
            <option value="none" disabled="disabled">Размер</option>
            <option value="1">10</option>
            <option value="2">12</option>
            <option value="3">14</option>
            <option value="4">16</option>
            <option value="5">18</option>
            <option value="6">20</option>
            <option value="7">26</option>
          </select>
          <span>Цвет</span> <input className="toolbar-color" type="color" value="#ff0000"
                                   ref={inputForeColor}
                                   onChange={() => changeTextEdit('foreColor', inputForeColor.current.value)}/>
          <span>Фон</span> <input className="toolbar-bg" type="color" value="#ffff00"
                                  ref={inputHiliteColor}
                                  onChange={() => changeTextEdit('hiliteColor', inputHiliteColor.current.value)}/>

          <button
            onClick={() => setIsModalAddEditorTable(getRange())}
            style={{
              backgroundColor: "inherit",
              border: "none",
            }}
          >
            <ButtonIcon
              tooltipText={"Таблица"}
              iconName={"edit-table"}
              iconColor={"#202020"}
              size={20}
              style={{ margin: 4 }}
            />
          </button>
          {/*<button*/}
          {/*  onClick={() => getNode()}*/}
          {/*  style={{*/}
          {/*    backgroundColor: "inherit",*/}
          {/*    border: "none",*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <ButtonIcon*/}
          {/*    tooltipText={"Получить объект"}*/}
          {/*    iconName={"edit-format-italic"}*/}
          {/*    iconColor={"#202020"}*/}
          {/*    size={20}*/}
          {/*    style={{ margin: 4 }}*/}
          {/*  />*/}
          {/*</button>*/}
        </div>

        <div
          id="editor"
          className={styleArticle.article + " " + styleArticle.edit}
          contentEditable="true"
          ref={editable}
          style={{
            width: 796.661608,
            height: "calc(100% - 24px - 12px - 56px - 64px - 64px)",
          }}
          dangerouslySetInnerHTML={{
            __html:
              !object.article ||
              (!!object.article &&
                (object.article === "<p></p>" || object.article.length === 0))
                ? "<p><br></p>"
                : object.article,
          }}
          placeholder="Введите текст"
          onInput={changeEditor}
          onPaste={handlePaste}
        ></div>

        {!!isModalAddEditorLink && (
          <ModalAddEditorLink
            range={isModalAddEditorLink}
            // successfulRequest={(link) => textEdit("CreateLink", link)}
            successfulRequest={(link) => textEdit("insertHTML", link)}
            handleClose={() => setIsModalAddEditorLink(null)}
          />
        )}
        {!!isModalAddEditorVideo && (
          <ModalAddEditorVideo
            range={isModalAddEditorVideo}
            successfulRequest={(link) => textEdit("insertHTML", link)}
            handleClose={() => setIsModalAddEditorVideo(null)}
          />
        )}
        {!!isModalAddEditorImage && (
          <ModalAddEditorImage
            object={object.handbook_entry}
            range={isModalAddEditorImage}
            successfulRequest={(link) => textEdit("insertHTML", link)}
            handleClose={() => setIsModalAddEditorImage(null)}
          />
        )}
        {!!isModalAddEditorFile && (
          <ModalAddEditorLinkFile
            object={object.handbook_entry}
            range={isModalAddEditorFile}
            successfulRequest={(link) => textEdit("insertHTML", link)}
            handleClose={() => setIsModalAddEditorFile(null)}
          />
        )}
        {!!isModalAddEditorTable && (
          <ModalAddEditorTable
            range={isModalAddEditorTable}
            successfulRequest={(link) => textEdit("insertHTML", link)}
            handleClose={() => setIsModalAddEditorTable(null)}
          />
        )}
      </Fragment>
    )
  );
};
