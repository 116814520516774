import React from "react";
import { ProjectContractsTableHead } from "./ProjectContractsTableHead";
import { ProjectContractsList } from "./ProjectContractsList";
import {ProjectContractsTotal} from "./ProjectContractsTotal";

export const ProjectContractsTable = (props) => {
  const { object, list } = props;

  return (
    !!object && (
      <table className="Table">
        <thead className="Thead">
          <ProjectContractsTableHead {...props} />
        </thead>
        <tbody>{!!list && <ProjectContractsList {...props} />}
        {!!list && <ProjectContractsTotal {...props} />}</tbody>
      </table>
    )
  );
};
