import React, { Fragment, useState } from "react";
import { NavLink } from "react-router-dom";
import moment from "moment";
import { getAccountFio, Icon, TextLink } from "@vokymlak/kabinet-ui";
import ModalProjectTaskExecute from "../../ScreenObjects/Project/components/ProjectTasks/ModalProjectTaskExecute";

export const ProjectTasksList = (props) => {
  const [modalProjectTaskExecute, setModalProjectTaskExecute] = useState(null);

  return (
    !!props.list && (
      <Fragment>
        {props.list.map((item) => (
          <tr
            className="Table__tr Table__tr_padding Table__tr_border Table__tr_hover"
            key={"item_" + item.uuid}
            style={{
              backgroundColor: props.isRed
                ? "#ef9a9a"
                : !!item.date_execution
                ? "#a5d6a7"
                : moment(item.date_control).format("YYYY-MM-DD") ===
                  moment().format("YYYY-MM-DD")
                ? item.is_priority
                  ? "#ffe082"
                  : "inherit"
                : !!moment(
                    moment(item.date_control).format("YYYY-MM-DD")
                  ).isSameOrBefore(moment().format("YYYY-MM-DD"))
                ? "#ef9a9a"
                : item.is_priority
                ? "#ffe082"
                : "inherit",
            }}
          >
            <td className="Table__td Table__td_border">
              <div
                className="Td__text Td__text_center"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {item.is_priority && (
                  <Icon name={"custom-priority"} size={16} color={"#202020"} />
                )}
                {!item.is_priority && "-"}
              </div>
            </td>

            <td className="Table__td Table__td_border">
              <NavLink to={"/project/" + item.project.uuid + "/tasks"}>
                <div
                  className="Td__text Td__text_center"
                  style={{
                    cursor: "pointer",
                    color: "#0064ff",
                    textDecoration: "underline",
                  }}
                >
                  {(!!item.project &&
                    !!item.project.name &&
                    item.project.name) ||
                    "-"}
                </div>
              </NavLink>
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text Td__text_center">
                {(!!item.name && item.name) || "-"}
              </div>
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text Td__text_center">
                {(!!item.note && <TextLink text={item.note} />) || "-"}
              </div>
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text Td__text_center">
                {!!item.date_control
                  ? moment(item.date_control).format("DD.MM.YYYY")
                  : "-"}
              </div>
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text Td__text_center">
                {(!!item.who && getAccountFio(item.who)) || "-"}
              </div>
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text Td__text_center">
                {(!!item.whom && getAccountFio(item.whom)) || "-"}
              </div>
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text Td__text_center">
                {!!item.date_execution && (
                  <>
                    <div
                      className={
                        "Action__button Action__button_gray Action__button_mini"
                      }
                      onClick={() => setModalProjectTaskExecute(item)}
                    >
                      {moment(item.date_execution).format("DD.MM.YYYY")}
                    </div>
                    <div
                      style={{
                        fontSize: 12,
                        fontWeight: 400,
                      }}
                    >
                      {(!!item.executor && getAccountFio(item.executor)) || "-"}
                    </div>
                  </>
                )}
                {!item.date_execution && (
                  <div
                    className={
                      "Action__button Action__button_blue Action__button_maxi"
                    }
                    onClick={() => setModalProjectTaskExecute(item)}
                  >
                    Отметить
                  </div>
                )}
              </div>
            </td>
          </tr>
        ))}

        {!!modalProjectTaskExecute && (
          <ModalProjectTaskExecute
            projectTask={modalProjectTaskExecute}
            successfulRequest={props.successfulRequest}
            handleClose={() => setModalProjectTaskExecute(null)}
          />
        )}
      </Fragment>
    )
  );
};
