import React, { useEffect } from "react";
import { ROOT_URL } from "../../../../../config/url";
import styleContent from "../../../../../styles/modules/Block.module.scss";
import {getAccountFio, Icon, Loading, TextSearchMarker, Tooltip, useList} from "@vokymlak/kabinet-ui";
import { NavLink } from "react-router-dom";

function CompanyProjectsList(props) {
  const {
    list: [list],
    update: [_isUpdateList, _setIsUpdateList, updateList],
  } = useList({
    apiUrl: "/api/company/" + props.company.uuid + "/projects",
    rootUrl: ROOT_URL,
  });

  useEffect(() => {
    updateList();
  }, [props.isUpdate]);

  return (
    <>
      {/*{!list && (*/}
      {/*  <div className={"Page__loader"}>*/}
      {/*    <Loading />*/}
      {/*  </div>*/}
      {/*)}*/}

      {!!list && list.length > 0 && (
        <>
          <div
            className={styleContent._block + " " + styleContent._block_bottom}
          >
            <div className={styleContent._block__title}>Проекты</div>
            <div className={styleContent._block__content}>
              <table className="Table">
                <tbody>
                  {list.map((item) => (
                    <tr
                      className="Table__tr Table__tr_hover"
                      key={props.company.uuid + ":" + item.uuid}
                      style={{ cursor: "pointer" }}
                    >
                      <td className="Table__td">
                        <NavLink
                          to={"/project/" + item.uuid + "/contracts"}
                          style={{ width: "100%" }}
                          target={"_black"}
                        >
                          <div
                            className="Td__title"
                            style={{
                              fontSize: 17,
                              fontWeight: 700,
                            }}
                          >
                            <div>
                              <div>
                                {item.name}
                                {!!item.project_year && (
                                  <span
                                    style={{
                                      opacity: 0.5,
                                      marginLeft: 4,
                                      fontSize: 13,
                                    }}
                                  >
                                    {item.project_year}
                                  </span>
                                )}

                                {!!item.law && (
                                  <span
                                    style={{
                                      marginLeft: 4,
                                      fontSize: 13,
                                      opacity: 0.5,
                                    }}
                                  >
                                    {"("}
                                    {item.law.name}
                                    {")"}
                                  </span>
                                )}
                              </div>
                              {!!item.company && (
                                <div
                                  style={{
                                    fontSize: 13,
                                    fontWeight: 500,
                                  }}
                                >
                                  {item.company.short_name}
                                </div>
                              )}

                              {/*{(!!item.executor || !!item.manager) && (*/}
                              {/*  <div*/}
                              {/*    style={{*/}
                              {/*      display: "flex",*/}
                              {/*      flexDirection: "row",*/}
                              {/*      flexWrap: "wrap",*/}
                              {/*      alignItems: "center",*/}
                              {/*      marginTop: 6,*/}
                              {/*    }}*/}
                              {/*  >*/}
                              {/*    {!!item.manager && (*/}
                              {/*      <div*/}
                              {/*        style={{*/}
                              {/*          fontSize: 13,*/}
                              {/*          fontWeight: 500,*/}
                              {/*        }}*/}
                              {/*      >*/}
                              {/*        <div*/}
                              {/*          style={{*/}
                              {/*            fontSize: 12,*/}
                              {/*            fontWeight: 400,*/}
                              {/*            padding: "3px 9px 3px 9px",*/}
                              {/*            backgroundColor: "#20202015",*/}
                              {/*            marginRight: 4,*/}
                              {/*            borderRadius: 24,*/}
                              {/*            display: "flex",*/}
                              {/*            alignItems: "center",*/}
                              {/*            position: "relative",*/}
                              {/*          }}*/}
                              {/*        >*/}
                              {/*          <span*/}
                              {/*            style={{*/}
                              {/*              fontSize: 10,*/}
                              {/*              position: "absolute",*/}
                              {/*              left: 12,*/}
                              {/*              top: -6,*/}
                              {/*              opacity: 0.75,*/}
                              {/*            }}*/}
                              {/*          >*/}
                              {/*            Куратор*/}
                              {/*          </span>*/}
                              {/*          <span>*/}
                              {/*            {getAccountFio(item.manager)}*/}
                              {/*          </span>*/}
                              {/*        </div>*/}
                              {/*      </div>*/}
                              {/*    )}*/}
                              {/*    {!!item.executor && (*/}
                              {/*      <div*/}
                              {/*        style={{*/}
                              {/*          fontSize: 13,*/}
                              {/*          fontWeight: 500,*/}
                              {/*        }}*/}
                              {/*      >*/}
                              {/*        <div*/}
                              {/*          style={{*/}
                              {/*            fontSize: 12,*/}
                              {/*            fontWeight: 400,*/}
                              {/*            padding: "3px 9px 3px 9px",*/}
                              {/*            backgroundColor: "#20202015",*/}
                              {/*            marginRight: 4,*/}
                              {/*            borderRadius: 24,*/}
                              {/*            display: "flex",*/}
                              {/*            alignItems: "center",*/}
                              {/*            position: "relative",*/}
                              {/*          }}*/}
                              {/*        >*/}
                              {/*          <span*/}
                              {/*            style={{*/}
                              {/*              fontSize: 10,*/}
                              {/*              position: "absolute",*/}
                              {/*              left: 12,*/}
                              {/*              top: -6,*/}
                              {/*              opacity: 0.75,*/}
                              {/*            }}*/}
                              {/*          >*/}
                              {/*            Специалист*/}
                              {/*          </span>*/}
                              {/*          <span>*/}
                              {/*            {getAccountFio(item.executor)}*/}
                              {/*          </span>*/}
                              {/*        </div>*/}
                              {/*      </div>*/}
                              {/*    )}*/}
                              {/*  </div>*/}
                              {/*)}*/}
                            </div>
                          </div>
                        </NavLink>
                      </td>
                      <td className="Table__td Table__td_child"
                      style={{
                        borderRadius: 0
                      }}>
                        <div
                          style={{
                            height: 24,
                            display: "flex",
                            marginLeft: 12,
                          }}
                        >
                        <Tooltip
                          text={"Перейти"}
                          style={{
                            marginLeft: 8,
                          }}
                        >
                          <div style={{ height: 24 }}>
                            <NavLink to={"/project/" + item.uuid + "/contracts"} target={"_black"}>
                              <Icon
                                name={"custom-circle-arrow-right"}
                                size={24}
                                color={"#202020"}
                              />
                            </NavLink>
                          </div>
                        </Tooltip>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default CompanyProjectsList;
