import React, {useState} from "react";
import KpiTicketAnalytics from "./components/KpiTicketAnalytics/KpiTicketAnalytics";
import {ScrollBlock} from "../../../../components/ScrollBlock/ScrollBlock";
import {ButtonIcon, Select} from "@vokymlak/kabinet-ui";


export const TimeTrackerTicketsPage = (props) => {
  const {
    list,

    monthText,
    selectMonth,
    selectYear,


    changeSelectMonthMinus,
    changeSelectMonthPlus,

    selectEmployee,
    getEmployeesSelectOptions,
    changeSelectEmployee,
  } = props;

  const [listCalendar, setListCalendar] = useState(null);

  const getAllAmount = (list) => {
    return list.map(c => c.time).reduce((a, b) => a + b, 0)
  }

  return (
    <ScrollBlock style={{ width: "100%", height: "100%" }}>
    <div className="Page">
      <div className="Page__title">
        <div
          className="Title__text"
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: 21,
            fontWeight: 700,
          }}
        >
          Трекер по тикетам
          {!!listCalendar && (
            <span style={{
              marginLeft: 12,
              fontSize: 21,
            }}>
                          {(Number(getAllAmount(listCalendar)
                          ) / 100).toFixed(2)}
              <span
                style={{
                  marginLeft: 2,
                  fontSize: 12,
                  fontWeight: 400,
                  opacity: 0.75,
                }}
              >
                            ч.
                          </span>

                        </span>
          )}

          <span style={{ width: 220, marginLeft: 16, fontWeight: 400 }}>
               <Select
                 isFixed={true}
                 isDefaultOption={false}
                 value={selectEmployee}
                 options={
                   !!list
                     ? getEmployeesSelectOptions(list)
                     : getEmployeesSelectOptions([])
                 }
                 label="Исполнитель"
                 onChange={(event) => (changeSelectEmployee(Number(event.target.value)))}
               />
            </span>
        </div>


        <div className="Title__settings">
          <div
            className="Title__text"
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: 21,
              fontWeight: 700,
              marginLeft: 12,
            }}
          >
            {monthText(selectMonth)}
            <span style={{ fontSize: 12, opacity: 0.75, marginLeft: 6 }}>
                {selectYear}
              </span>
            <ButtonIcon
              tooltipText={"Назад"}
              iconName={"custom-circle-arrow-left"}
              iconColor={"#202020"}
              size={24}
              style={{ marginLeft: 12 }}
              onClick={() => changeSelectMonthMinus()}
            />
            <ButtonIcon
              tooltipText={"В&nbsp;перёд"}
              iconName={"custom-circle-arrow-right"}
              iconColor={"#202020"}
              size={24}
              style={{ marginLeft: 6 }}
              onClick={() => changeSelectMonthPlus()}
            />
            {/*<ButtonIcon*/}
            {/*  tooltipText={"Обновить"}*/}
            {/*  iconName={"custom-change-circle"}*/}
            {/*  iconColor={"#20202075"}*/}
            {/*  size={28}*/}
            {/*  style={{ marginLeft: 12 }}*/}
            {/*  onClick={() => null}*/}
            {/*/>*/}
          </div>
        </div>
      </div>

      <KpiTicketAnalytics
        date={selectYear + "-" + selectMonth + "-01"}
        selectEmployee={selectEmployee}
        setListCalendar={setListCalendar}
      />
    </div>
    </ScrollBlock>
  );
}
