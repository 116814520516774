import React, {useEffect, useState} from "react";
import {Route, Routes, Navigate} from "react-router-dom";
import "./Tickets.scss";
import {TicketsMenu} from "./TicketsMenu/TicketsMenu";
import {TicketsTopics} from "./TicketsTopics";
import {Tickets} from "./Tickets";
import {useProfile} from "@vokymlak/kabinet-ui";
import TimeTrackerTickets from "./TimeTrackerTickets/TimeTrackerTickets";

export const TicketsRouting = () => {
  const { profile } = useProfile();

  return (
    <div className="Tickets">
      <div className="Block" >
        <div className="App App_vertical">
          <div className="Navigate" style={{
            width: 320,
          }}>
            <TicketsMenu />
          </div>

          <div className="Content" style={{
            borderLeft: "2px solid #20202015",
          }}>
            <Routes>
              <Route
                path="/topic/:ticketTopicUuid"
                element={<Tickets />}
              />

              <Route
                path="/all"
                element={<Tickets isCount={true} />}
              />

              <Route
                path="/no-topic"
                element={<Tickets isTopicNull={true} />}
              />

              <Route
                path="/archive"
                element={<Tickets isArchive={true} />}
              />

              <Route
                path="/author"
                element={<Tickets authorId={profile.id} />}
              />

              <Route
                path="/executor"
                element={<Tickets executorId={profile.id} />}
              />

              <Route
                path="/time-tracker"
                element={<TimeTrackerTickets />}
              />

              <Route path="/topics" element={<TicketsTopics />} />
              <Route exact path="/" element={<Navigate to="./all" replace />} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
};
