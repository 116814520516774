import React, { useRef, useState } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../../config/url";
import {
  useNotification,
  getErrorMessageByKey,
  jsonFormatText,
  isValidateNullText,
  Modal,
  Input,
  Select, useList,
} from "@vokymlak/kabinet-ui";

function isSameDomainLink(link) {
  let currentDomain = window.location.hostname;
  let parser = document.createElement('a');
  parser.href = link;
  let linkDomain = parser.hostname;
  return currentDomain === linkDomain;
}

function ModalAddEditorLink(props) {
  const inputTitle = useRef(null);
  const selectArticleGroupUuid = useRef(null);
  const [fileName, setFileName] = useState(null)

  const {
    list: [list],
  } = useList({ apiUrl: "/api/catalog-entries", rootUrl: ROOT_URL });

  const { notification } = useNotification();

  const changeAdd = (event) => {
    event.preventDefault();

    const title = jsonFormatText(inputTitle.current.value);
    const folderUuid = selectArticleGroupUuid.current.value;

    if (isValidateNullText(title)) {
      notification("Заполните все обязательные поля", "red");
      return;
    }

    const rootUrl = ''
    const textLink = `<a href="${rootUrl}/api/catalog/file/${folderUuid}" target="_blank">${title}</a>`;

    let selection = window.getSelection();
    // Устанавливаем выделение заново
    selection.removeAllRanges();
    selection.addRange(props.range);

    props.successfulRequest(textLink);
    props.handleClose();
  };

  const handleClose = () => {
    let selection = window.getSelection();
    // Устанавливаем выделение заново
    selection.removeAllRanges();
    selection.addRange(props.range);

    props.handleClose();
  };

  const getSelectOptions = (list) => {
    const options = [
      {
        value: "null",
        title: "Не выбрано",
      },
    ];

    list.map((item) => {
      if (item.catalog_entry_type.tag === "FILE") {
        const option = {
          value: item.file.uuid,
          title: item.title,
        };

        options.push(option);
      }


        if (item.catalog_entry_type.tag === "FOLDER" && !!item.entries) {
          item.entries.map((entry) => {
            if (entry.catalog_entry_type.tag === "FILE") {
              const option = {
                value: entry.file.uuid,
                title: entry.title,
              };

              options.push(option);
            }
          });
        }
    });

    return options;
  };

  const onChangeFile = (fileUuid) => {
    let fileName = null
    if (!!list && fileUuid.length > 0) {
      getSelectOptions(list).map(o => {
        if (o.value === fileUuid) {
          fileName = o.title
        }
      })
    }
    return setFileName(fileName)
  }

  return (
    <Modal handleClose={() => handleClose()}>
      <form className="Form" onSubmit={(event) => changeAdd(event)}>
        <div className="Form__title">Ссылка на файл</div>


        <div className="Form__field">
          <Select
            reference={selectArticleGroupUuid}
            isFixed={true}
            options={!!list ? getSelectOptions(list) : getSelectOptions([])}
            label="Файл"
            onChange={event => onChangeFile(event.target.value)}
          />
        </div>

        <div className="Form__field Form__field_last">
          <Input reference={inputTitle} value={!!fileName && fileName} type="text" label="Название ссылки на файл *" />
        </div>

        <div className="Form__actions Form__actions_center">
          <button className="Action__button Action__button_all Action__button_blue">
            Добавить
          </button>
        </div>

        <div className="Form__actions Form__actions_center">
          <div className="Action__link" onClick={() => handleClose()}>
            Отмена
          </div>
        </div>
      </form>
    </Modal>
  );
}

export default ModalAddEditorLink;
