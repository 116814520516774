import React, { useEffect, useState } from "react";
import { ROOT_URL } from "../../../../../config/url";
import styleContent from "../../../../../styles/modules/Block.module.scss";
import moment from "moment";
import ModalCompanyFnsCertificateEdit from "./ModalCompanyFnsCertificateEdit";
import {Icon, Loading, useAction, useList} from "@vokymlak/kabinet-ui";

function CompanyFnsCertificateList(props) {
  const {
    list: [list],
    update: [_isUpdateList, _setIsUpdateList, updateList],
  } = useList({
    apiUrl: "/api/company/" + props.company.uuid + "/fns-certificates",
    rootUrl: ROOT_URL,
  });

  useEffect(() => {
    updateList();
  }, [props.isUpdate]);

  const [modalCompanyFnsCertificateEdit, setModalCompanyFnsCertificateEdit] =
    useState(null);

  const { setAction } = useAction([props.company.uuid], () =>
    updateList()
  );

  useEffect(() => {
    if (!!list) {
      const listUuid = list.map((i) => i.uuid);
      setAction([...listUuid, props.company.uuid]);
    } else {
      setAction(props.company.uuid);
    }
  }, [list]);

  const getDaysDifference = (date1, date2) => {
    return moment(date2, 'YYYY-MM-DD').
    diff(moment(date1, 'YYYY-MM-DD'), 'days');
  }

  return (
    <>
      {/*{!list && (*/}
      {/*  <div className={"Page__loader"}>*/}
      {/*    <Loading />*/}
      {/*  </div>*/}
      {/*)}*/}

      {!!list && list.length > 0 && (
        <>
          <div
            className={styleContent._block + " " + styleContent._block_bottom}
          >
            <div className={styleContent._block__title}>Сертификаты</div>
            <div className={styleContent._block__content}>
              <table className="Table">
                <tbody>
                  {list.map((item) => (
                    <tr
                      className="Table__tr Table__tr_hover"
                      key={props.company.uuid + ":" + item.uuid}
                      style={{
                        cursor: "pointer",

                        backgroundColor: !!moment(
                          moment(item.fns_certificate.after).format(
                            "YYYY-MM-DD"
                          )
                        ).isSameOrBefore(moment().format("YYYY-MM-DD"))
                          ? "#ef9a9a"
                          : getDaysDifference(moment().format("YYYY-MM-DD"), moment(item.fns_certificate.after).format(
                            "YYYY-MM-DD"
                          )) > (365 * 5)
                            ? "#ffe082" : false,
                      }}
                      onClick={() => setModalCompanyFnsCertificateEdit(item)}
                    >
                      <td className="Table__td" style={{
                        paddingBottom: 8
                      }}>

                        <div style={{
                          display: "flex"
                        }}>
                          <div style={{
                            marginRight: 8
                          }}>
                            <Icon
                              name={!!item.fns_certificate.is_fns && item.fns_certificate.is_fns ? "custom-award-star" : "custom-workspace-premium"}
                              size={42}
                              color={"#202020"}
                            />
                          </div>

                        <div
                          className="Td__title"
                          style={{
                            fontSize: 15,
                            fontWeight: 400,
                          }}
                        >
                          <div
                            style={{
                              marginBottom: 4,
                            }}
                          >
                            {item.fns_certificate.subject}
                            <span
                              style={{
                                fontSize: 15,
                                opacity: 0.75,
                                marginLeft: 4
                              }}
                            >
                              {!!item.fns_certificate.email &&
                                "(" + item.fns_certificate.email + ")"}
                            </span>
                          </div>

                          <div
                            style={{
                              fontSize: 11,
                              fontWeight: 700,
                              backgroundColor: "#202020",
                              display: "inline-block",
                              color: "white",
                              padding: "2px 6px",
                              borderRadius: 24,
                              marginRight: 4,
                            }}
                          >
                            {!!item.fns_certificate && item.fns_certificate.is_fns ? 'Сертификат ФНС' : 'Сертификат'}
                          </div>

                          <div
                            style={{
                              fontSize: 11,
                              fontWeight: 700,
                              backgroundColor: "#202020",
                              display: "inline-block",
                              color: "white",
                              padding: "2px 6px",
                              borderRadius: 24,
                              marginRight: 4,
                            }}
                          >
                            Название {item.fns_certificate.name}
                          </div>
                          <div
                            style={{
                              fontSize: 11,
                              fontWeight: 700,
                              backgroundColor: "#202020",
                              display: "inline-block",
                              color: "white",
                              padding: "2px 6px",
                              borderRadius: 24,
                              marginRight: 4,
                            }}
                          >
                            Действителен с{" "}
                            {moment(item.fns_certificate.before).format(
                              "DD.MM.YYYY"
                            )}{" "}
                            по{" "}
                            {moment(item.fns_certificate.after).format(
                              "DD.MM.YYYY"
                            )}
                          </div>
                        </div>
                        </div>
                      </td>
                      <td className="Table__td">
                        <div
                          className="Td__title"  style={{
                            textAlign: "right"
                        }}>
                          <div style={{
                            display: "inline-block"
                          }}>
                          {getDaysDifference(moment().format("YYYY-MM-DD"), moment(item.fns_certificate.after).format(
                            "YYYY-MM-DD"
                          )) > 0 && (
                            <div
                              style={{
                                backgroundColor:
                                  getDaysDifference(moment().format("YYYY-MM-DD"), moment(item.fns_certificate.after).format(
                                    "YYYY-MM-DD"
                                  )) < (31)
                                    ? "#cf2f00" :
                                    getDaysDifference(moment().format("YYYY-MM-DD"), moment(item.fns_certificate.after).format(
                                      "YYYY-MM-DD"
                                    )) > (365 * 5)
                                      ? "#202020" : "#1565c0",
                                padding: "0 4px",
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "nowrap",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: 24,
                                marginLeft: 12,
                              }}
                            >
                              <div
                                style={{
                                  padding: "4px 4px",
                                  color: "white",
                                  fontSize: 14,
                                  pointerEvents: "auto",
                                  fontWeight: 700,
                                  cursor: "default"
                                }}
                              >
                                {!!item.fns_certificate.after &&
                                  getDaysDifference(moment().format("YYYY-MM-DD"), moment(item.fns_certificate.after).format("YYYY-MM-DD"))}
                                &nbsp;д.
                              </div>
                            </div>
                          )}
                          {getDaysDifference(moment().format("YYYY-MM-DD"), moment(item.fns_certificate.after).format(
                            "YYYY-MM-DD"
                          )) < 0 && (
                            <div
                              style={{
                                backgroundColor: "#202020",
                                padding: "0 4px",
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "nowrap",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: 24,
                                marginLeft: 12,
                              }}
                            >
                              <div
                                style={{
                                  padding: "4px 4px",
                                  color: "white",
                                  fontSize: 14,
                                  pointerEvents: "auto",
                                  fontWeight: 700,
                                  cursor: "default"
                                }}
                              >
                                Недействительный
                              </div>
                            </div>
                          )}
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          {!!modalCompanyFnsCertificateEdit && (
            <ModalCompanyFnsCertificateEdit
              fnsCertificate={modalCompanyFnsCertificateEdit}
              successfulRequest={() => updateList()}
              handleClose={() => setModalCompanyFnsCertificateEdit(null)}
            />
          )}
        </>
      )}
    </>
  );
}

export default CompanyFnsCertificateList;
