import React, {useEffect, useState} from "react";
import { ContractStagesTableHead } from "./ContractStagesTableHead";
import { ContractStagesList } from "./ContractStagesList";
import {DragDropContext, Droppable} from "react-beautiful-dnd";

export const StrictModeDroppable = ({ children, ...props }) => {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  if (!enabled) {
    return null;
  }

  return <Droppable {...props}>{children}</Droppable>;
};

export const ContractStagesTable = (props) => {
  const {
    object,
    list,

    isDragAndDrop,
    onDragEnd,
    onDragStart,
  } = props;

  return (
    !!object && (
      <table className="Table">
        <thead className="Thead">
          <ContractStagesTableHead {...props} />
        </thead>
        {isDragAndDrop && (
          <DragDropContext
            onDragEnd={onDragEnd}
            onDragStart={onDragStart}
          >
            <StrictModeDroppable droppableId={"droppable"}>
              {(provided) => (
                <tbody
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                {!!list && <ContractStagesList {...props} />}
                {provided.placeholder}
                </tbody>
              )}
            </StrictModeDroppable>
          </DragDropContext>
        )}
              {!isDragAndDrop && (
        <tbody>{!!list && <ContractStagesList {...props} />}</tbody>
                )}
      </table>
    )
  );
};
