import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import {ROOT_URL} from "../../../config/url";
import {
  getAccountFio,
  getErrorMessageByKey,
  Input,
  isValidateNullText,
  jsonFormatText,
  Modal,
  useNotification,
  useObject,
} from "@vokymlak/kabinet-ui";

export const ModalEditAccount = (props) => {
  const [isDelete, setIsDelete] = useState(false);

  const {notification} = useNotification();

  const {
    object: [account],
    update: [isUpdateObject, setIsUpdateObject],
    setApiUrl,
  } = useObject({
    apiUrl: "/api/account/" + props.account.uuid,
    rootUrl: ROOT_URL,
  });

  useEffect(() => {
    if (props.account) {
      setApiUrl("/api/account/" + props.account.uuid);
    }
  }, [props.account]);

  const changeDelete = (event) => {
    event.preventDefault();

    axios
      .delete("/api/account/" + props.account.uuid + "/delete", {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Аккаунт удалён", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    !!account && (
      <>
        <Modal handleClose={() => props.handleClose()}>
          {!isDelete && (
            <div className="Form">
              <div
                style={{
                  marginBottom: 12,
                }}
              >
                <div
                  className="Form__title"
                  style={{fontSize: 19, fontWeight: 700}}
                >
                  {getAccountFio(account)}
                  <div className="Text__signature">Аккаунт</div>
                </div>
              </div>

              <div className="Form__actions">
                <div
                  className="Action__link Action__link_red"
                  onClick={() => setIsDelete(true)}
                >
                  Удалить
                </div>
                <div className="Action__link" onClick={() => props.handleClose()}>
                  Отмена
                </div>
              </div>
            </div>
          )}

          {!!isDelete && (
            <form className="Form" onSubmit={(event) => changeDelete(event)}>
              <div
                className="Form__title"
                style={{fontSize: 19, fontWeight: 700}}
              >
                {getAccountFio(account)}
                <div className="Text__signature">Удалить</div>
              </div>

              <div className="Form__actions Form__actions_center">
                <button className="Action__button Action__button_all Action__button_red">
                  Продолжить
                </button>
              </div>

              <div className="Form__actions Form__actions_center">
                <div className="Action__link" onClick={() => setIsDelete(false)}>
                  Отмена
                </div>
              </div>
            </form>
          )}
        </Modal>
      </>
    )
  );
};
