import React, { useState, useEffect } from "react";
import styles from "./Input.module.scss";

interface IInputProps {
  value: string | number | null;
  label: string | null;
  isFixed: boolean | false;
  type: string | "text";
  reference: React.MutableRefObject<null> | null;
  disabled: boolean | false;
  isFocus: boolean | false;
  isDashed: boolean | false;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void | null;
}
interface IInputViewProps extends IInputProps {
  setIsFocus: (isFocus: boolean) => void;
  classNameValueFocuse: (
    value: string | number | null,
    isFocus: boolean,
    isFixed: boolean
  ) => string | null;
  changeInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
type InputProps = IInputProps;
export type InputViewProps = IInputViewProps;

export default function useInput(props: InputProps): InputViewProps {
  const [value, setValue] = useState(props.value);
  const [label, setLabel] = useState(props.label);
  const [isFixed, setIsFixed] = useState(props.isFixed);
  const [type, setType] = useState(props.type);
  const [reference, setReference] = useState(props.reference);
  const [disabled, setDisabled] = useState(props.disabled);
  const [isFocus, setIsFocus] = useState(props.isFocus);
  const [isDashed, setIsDashed] = useState(props.isDashed);

  useEffect(() => {
    return () => {
      setValue(null);
      setLabel(null);
      setIsFixed(false);
      setType("text");
      setReference(null);
      setDisabled(false);
      setIsFocus(false);
      setIsDashed(false);
    };
  }, []);

  useEffect(() => {
    setValue(props.value);
    setLabel(props.label);
    setIsFixed(props.isFixed);
    setType(props.type);
    setReference(props.reference);
    setDisabled(props.disabled);
    setIsFocus(props.isFocus);
    setIsDashed(props.isDashed);
  }, [
    props.value,
    props.label,
    props.isFixed,
    props.type,
    props.reference,
    props.disabled,
    props.isFocus,
    props.isDashed,
  ]);

  const classNameValueFocuse = (
    value: string | number | null,
    isFocus: boolean,
    isFixed: boolean
  ): string | null => {
    if (isFocus) {
      return styles._field__box_value + " " + styles._field__box_focused;
    } else if (isFixed) {
      return styles._field__box_value;
    } else if (!!value) {
      return styles._field__box_value;
    }

    return null;
  };

  const changeInput = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setValue(event.target.value || null);
    if (!!props.onChange) {
      props.onChange(event);
    }
  };

  return {
    value,
    label,
    isFixed,
    type,
    reference,
    disabled,
    isFocus,
    setIsFocus,
    isDashed,
    classNameValueFocuse,
    changeInput,
  };
}
