import React, { useState } from "react";
import { ROOT_URL } from "../../../../../config/url";
import { useAction, useList } from "@vokymlak/kabinet-ui";
import { useNotification } from "@vokymlak/kabinet-ui";
import { useObject } from "@vokymlak/kabinet-ui";

function useProjectSmp(props) {
  const {
    object: [object],
    update: [isUpdateObject, setIsUpdateObject],
  } = useObject({
    apiUrl: "/api/project/" + props.project.uuid + "",
    rootUrl: ROOT_URL,
  });

  const {
    list: [list],
    update: [isUpdateList, setIsUpdateList],
  } = useList({
    apiUrl: "/api/project/" + props.project.uuid + "/smp-expenses",
    rootUrl: ROOT_URL,
  });

  const {
    object: [objectSmp],
    update: [isUpdateSmp, setIsUpdateSmp],
  } = useObject({
    apiUrl: "/api/project/" + props.project.uuid + "/smp",
    rootUrl: ROOT_URL,
  });

  useAction("isUpdate", () => setIsUpdateObject(!isUpdateObject));

  const { notification } = useNotification();

  const getAllAmount = (list) => {
    let amount = 0;
    if (!!list) {
      list.forEach((i) => {
        if (!!i.amount) {
          amount += i.amount;
        }
      });
    }
    return amount;
  };

  return {
    object,
    setIsUpdateObject,
    isUpdateObject,
    list,
    isUpdateList,
    setIsUpdateList,
    getAllAmount,

    objectSmp,
    isUpdateSmp,
    setIsUpdateSmp
  };
}

export default useProjectSmp;
