import React, { useEffect, useState } from "react";
import ModalControlGroupAdd from "./ModalControlGroupAdd";
import ModalControlGroupEdit from "./ModalControlGroupEdit";
import ModalControlGroupPointAdd from "./ModalControlGroupPointAdd";
import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";
import {Tooltip, Loading, Icon} from "@vokymlak/kabinet-ui";


function ControlGroupsList(props) {
  const {
    list,
    setList,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    setIsUpdateList,
    isUpdateList,

    modalItemEdit,
    setModalItemEdit,
    isModalObjectAdd,
    setIsModalObjectAdd,
    modalObjectItemAdd,
    setModalObjectItemAdd,

    deleteControlGroupPoint,
  } = props;

  return (
    <ScrollBlock
      style={{ width: '100%', height: '100%' }}>
    <div className="Page">
      <div className="Page__title">
        <div
          className="Title__text"
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: 27,
            fontWeight: 700,
          }}
        >
          Группы контроля
        </div>

        <div className="Title__settings">
          {!!list && (
            <div
              style={{
                margin: "0 12px",
              }}
              className="Button__text"
              onClick={() => changeAllUnroll(list, "uuid")}
            >
              {list.length === unrolls.length ? "Свернуть" : "Развернуть"}
            </div>
          )}

          <div
            style={{
              margin: "0 12px",
            }}
            className="Button__text"
            onClick={() => (setList(null), setIsUpdateList(!isUpdateList))}
          >
            Обновить
          </div>

          <Tooltip text={"Добавить"} style={{ marginLeft: 24 }}>
            <div
              className="Button__icon Button__icon_42"
              onClick={() => setIsModalObjectAdd(true)}
            >
              <Icon name={"custom-add"} size={42} color={""} />
            </div>
          </Tooltip>
        </div>
      </div>

      <div className="Page__content">
        <ul className="List">
          {!list && (
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                flexWrap: "nowrap",
                alignItems: "center",
                justifyContent: "center",
                padding: "100px 0",
              }}
            >
              <Loading />
            </div>
          )}

          {!!list &&
            list.map((item) => (
              <React.Fragment key={"item_" + item.uuid}>
                <li
                  className={
                    isUnroll(item.uuid) ? "List__item active" : "List__item"
                  }
                  onClick={() => changeUnroll(item.uuid)}
                >
                  <div className="Item__title">
                    <div className="Element__title">{item.name}</div>
                  </div>
                  <div className="Item__settings">
                    <div className="Element__datetime"></div>
                  </div>
                </li>

                {isUnroll(item.uuid) && (
                  <li className="List__content">
                    <div>
                      <table className="Table">
                        <tbody>
                          {!!item.points &&
                            item.points.map((point, index) => (
                              <tr className="Table__tr" key={index}>
                                <td className="Table__td">
                                  <div className="Td__title">
                                    {index + 1}
                                    {". "}
                                    {point.control_point.name}
                                  </div>
                                </td>
                                <td className="Table__td Table__td_child">
                                  <div
                                    className="Button__icon Button__icon_32"
                                    onClick={() =>
                                      deleteControlGroupPoint(point.uuid)
                                    }
                                  >
                                    <Icon
                                      name={"custom-delete"}
                                      size={32}
                                      color={""}
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="Content__footer">
                      <div className="Footer__title">
                        <div
                          className="Button__text"
                          onClick={() => setModalObjectItemAdd(item)}
                        >
                          Добавить точку
                        </div>
                      </div>
                      <div className="Footer__settings">
                        <Tooltip
                          text={"Редактировать"}
                          style={{ marginLeft: 24 }}
                        >
                          <div
                            className="Button__icon Button__icon_42"
                            onClick={() => setModalItemEdit(item)}
                          >
                            <Icon name={"custom-edit"} size={42} color={""} />
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                  </li>
                )}
              </React.Fragment>
            ))}
        </ul>
      </div>

      {!!modalItemEdit && (
        <ModalControlGroupEdit
          controlGroup={modalItemEdit}
          successfulRequest={() => setIsUpdateList(!isUpdateList)}
          handleClose={() => setModalItemEdit(null)}
        />
      )}
      {!!isModalObjectAdd && (
        <ModalControlGroupAdd
          successfulRequest={() => setIsUpdateList(!isUpdateList)}
          handleClose={() => setIsModalObjectAdd(false)}
        />
      )}
      {!!modalObjectItemAdd && (
        <ModalControlGroupPointAdd
          controlGroup={modalObjectItemAdd}
          successfulRequest={() => setIsUpdateList(!isUpdateList)}
          handleClose={() => setModalObjectItemAdd(null)}
        />
      )}

    </div>
    </ScrollBlock>
  );
}

export default ControlGroupsList;
