import React, { useState } from "react";
import { ROOT_URL } from "../../../../config/url";
import moment from "moment";
import {getAccountFio, useList} from "@vokymlak/kabinet-ui";

export const useTimeTrackerTickets = () => {
  const {
    list: [list],
  } = useList({
    apiUrl: "/api/employees/list",
    rootUrl: ROOT_URL,
  });

  const [selectMonth, setSelectMonth] = useState(moment().format("MM"));
  const [selectYear, setSelectYear] = useState(moment().format("YYYY"));

  const changeSelectMonthMinus = () => {
    if (Number(selectMonth) > 1) {
      setSelectMonth(
        Number(selectMonth) - 1 < 10
          ? "0" + (Number(selectMonth) - 1)
          : String(Number(selectMonth) - 1)
      );
    } else {
      setSelectMonth("12");
      setSelectYear(Number(selectYear) - 1);
    }
  };

  const changeSelectMonthPlus = () => {
    if (Number(selectMonth) < 12) {
      setSelectMonth(
        Number(selectMonth) + 1 < 10
          ? "0" + (Number(selectMonth) + 1)
          : String(Number(selectMonth) + 1)
      );
    } else {
      setSelectMonth("01");
      setSelectYear(Number(selectYear) + 1);
    }
  };

  const monthText = (month) => {
    const months = [
      "январь",
      "февраль",
      "март",
      "апрель",
      "май",
      "июнь",
      "июль",
      "август",
      "сентябрь",
      "октябрь",
      "ноябрь",
      "декабрь",
    ];

    return (
      months[Number(month) - 1].charAt(0).toUpperCase() +
      months[Number(month) - 1].slice(1)
    );
  };

  const [selectEmployee, setSelectEmployee] = useState(0);
  const changeSelectEmployee = (accountId) => {
    setSelectEmployee(Number(accountId));
  };

  const getEmployeesSelectOptions = (list) => {
    const options = [
      {
        value: "0",
        title: "Не выбрано",
      },
    ];

    list.map((item) => {
      const option = {
        value: item.account.id,
        title: getAccountFio(item.account),
      };

      options.push(option);
    });

    options.push({
      value: 1,
      title: "Администратор",
    });

    return options;
  };

  return {
    list,

    monthText,
    selectMonth,
    selectYear,


    changeSelectMonthMinus,
    changeSelectMonthPlus,

    selectEmployee,
    getEmployeesSelectOptions,
    changeSelectEmployee,
  };
}

export default useTimeTrackerTickets;
