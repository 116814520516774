import React, {useState} from "react";
import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";
import styleTumbler from "../../../styles/modules/Tumbler.module.scss";
import {Navigate, NavLink, Route, Routes} from "react-router-dom";
import ContractSpecifications from "./components/ContractSpecifications/ContractSpecifications";
import ContractStages from "./components/ContractStages/ContractStages";
import ContractActs from "./components/ContractActs/ContractActs";
import ContractPayments from "./components/ContractPayments/ContractPayments";
import {ButtonIcon} from "@vokymlak/kabinet-ui";
import ModalContractEditWithoutVolume from "./ModalContractEditWithoutVolume";
import ModalProjectContractImport from "./ModalProjectContractImport";
import ContractPfhd from "./components/ContractPfhd/ContractPfhd";

function ContractPage(props) {
  const {object, setIsUpdateObject, isUpdateObject} = props;

  const [modalContractEdit, setModalContractEdit] = useState(null)
  const [modalImport, setModalImport] = useState(null)

  return (
    !!object && (
      <ScrollBlock style={{width: "100%", height: "100%"}}>
        <div className="Page">
          <div
            className="Page__title"
            style={{
              marginBottom: 12,
            }}
          >
            <div
              className="Title__text"
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                flexWrap: "nowrap",
                justifyContent: "flex-start",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  fontSize: 19,
                  fontWeight: 700,
                }}
              >
                <div>
                  {(!!object && object.contract_number) || "-"} ({(!!object && object.registry_number) || "-"})
                  <div style={{
                    fontSize: 10,
                    fontWeight: 400
                  }}>
                    {(!!object && !!object.ris_id && object.ris_id) ||
                      "-"} / {(!!object && !!object.ris_entity_id && object.ris_entity_id) ||
                    "-"}
                  </div>
                </div>
                <span
                  style={{
                    fontSize: 11,
                    maxWidth: 240,
                    fontWeight: 500,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    marginTop: 4,
                    textOverflow: "ellipsis",
                  }}
                >
                  {!!object &&
                    !!object.project &&
                    !!object.project.name &&
                    object.project.name}
                </span>
              </div>

              {!!object &&
                object.is_without_volume && (
                  <div
                    style={{
                      backgroundColor: "#202020",
                      padding: "0 6px",
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "nowrap",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 24,
                      marginLeft: 12,
                    }}
                  >
                    <div
                      style={{
                        padding: "4px 4px",
                        color: "white",
                        fontSize: 14,
                        pointerEvents: "auto",
                        fontWeight: 700,
                        cursor: "default"
                      }}
                    >
                      Без объёма
                    </div>
                  </div>
                )}

              <ButtonIcon
                tooltipText={"Редактировать"}
                iconName={"custom-edit"}
                iconColor={"#202020"}
                size={36}
                style={{
                  marginLeft: 16,
                  zIndex: 9,
                }}
                onClick={() => setModalContractEdit(object)}
              />

              {!!object.ris_id && (
                <ButtonIcon
                  tooltipText={"Импорт"}
                  iconName={"custom-cloud-upload"}
                  iconColor={"#202020"}
                  size={26}
                  style={{marginLeft: 12}}
                  onClick={() => setModalImport(object)}
                />
              )}
            </div>


            <div className="Title__settings">
              <ul className={styleTumbler._}>
                <li className={styleTumbler._link}>
                  <NavLink
                    className={({isActive}) =>
                      isActive
                        ? [styleTumbler._text, styleTumbler.active].join(" ")
                        : styleTumbler._text
                    }
                    to={"./specifications"}
                    style={{
                      position: "relative",
                    }}
                  >
                    Спецификации
                  </NavLink>
                </li>
                <li className={styleTumbler._link}>
                  <NavLink
                    className={({isActive}) =>
                      isActive
                        ? [styleTumbler._text, styleTumbler.active].join(" ")
                        : styleTumbler._text
                    }
                    to={"./stages"}
                    style={{
                      position: "relative",
                    }}
                  >
                    Этапы
                  </NavLink>
                </li>
                <li className={styleTumbler._link}>
                  <NavLink
                    className={({isActive}) =>
                      isActive
                        ? [styleTumbler._text, styleTumbler.active].join(" ")
                        : styleTumbler._text
                    }
                    to={"./acts"}
                    style={{
                      position: "relative",
                    }}
                  >
                    Акты
                  </NavLink>
                </li>
                <li className={styleTumbler._link}>
                  <NavLink
                    className={({isActive}) =>
                      isActive
                        ? [styleTumbler._text, styleTumbler.active].join(" ")
                        : styleTumbler._text
                    }
                    to={"./payments"}
                    style={{
                      position: "relative",
                    }}
                  >
                    Платежи
                  </NavLink>
                </li>
                <li className={styleTumbler._link}>
                  <NavLink
                    className={({isActive}) =>
                      isActive
                        ? [styleTumbler._text, styleTumbler.active].join(" ")
                        : styleTumbler._text
                    }
                    to={"./pfhd"}
                    style={{
                      position: "relative",
                    }}
                  >
                    ПФХД
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>

          <hr className={"Page__hr"} style={{marginBottom: 6}}/>

          <Routes>
            <Route
              path="/specifications"
              element={<ContractSpecifications contract={object}/>}
            />
            <Route
              path="/stages"
              element={<ContractStages contract={object}/>}
            />
            <Route
              path="/acts"
              element={<ContractActs contract={object}/>}
            />
            <Route
              path="/payments"
              element={<ContractPayments contract={object}/>}
            />
            <Route
              path="/pfhd"
              element={<ContractPfhd contract={object}/>}
            />

            <Route exact path="/" element={<Navigate to="./specifications"/>}/>
          </Routes>

          {!!modalContractEdit && (
            <ModalContractEditWithoutVolume
              contract={modalContractEdit}
              successfulRequest={() => setIsUpdateObject(!isUpdateObject)}
              handleClose={() => setModalContractEdit(null)}
            />
          )}

          {!!modalImport && (
            <ModalProjectContractImport
              project={modalImport.project}
              contract={modalImport}
              successfulRequest={() => setIsUpdateObject(!isUpdateObject)}
              handleClose={() => setModalImport(null)}
            />
          )}
        </div>
      </ScrollBlock>
    )
  );
}

export default ContractPage;
