import React from "react";
import { ROOT_URL } from "../../../config/url";
import { useAction, useUnroll, useList } from "@vokymlak/kabinet-ui";

function useSettingsView() {
  const {
    list: [list, _count, setList],
    update: [isUpdateList, setIsUpdateList, changeUpdate],
  } = useList({
    apiUrl: "/api/settings/view",
    rootUrl: ROOT_URL,
  });

  const { isUnroll, changeUnroll } = useUnroll();

  useAction("isUpdate", () => changeUpdate());

  return {
    list,
    setList,

    isUnroll,
    changeUnroll,

    setIsUpdateList,
    isUpdateList,
  };
}

export default useSettingsView;
