import React, {useState} from "react";
import styleContent from "../../../../../../styles/modules/Block.module.scss";
import moment from "moment/moment";
import {Icon, Tooltip} from "@vokymlak/kabinet-ui";

function ActPaymentsList(props) {
  const {act} = props

  return (
    <>
      {!!act && !!act.payments && !!act.payments.length > 0 && (
        <>
          <div
            className={styleContent._block + " " + styleContent._block_bottom}
          >
            <div className={styleContent._block__title} style={{
              opacity: 1
            }}>
              <div
                style={{
                  fontSize: 19,
                  fontWeight: 900,
                }}
              >
                <span style={{opacity: .5}}>Платежи</span>

                <span style={{ fontSize: 12, opacity: 0.75, marginLeft: 6 }}>
                ({act.payments.length})
              </span>
                <span style={{
                  marginLeft: 12,
                  fontSize: 21,
                }}>
                  {(new Intl.NumberFormat("ru-RU").format(
                      (Number(act.payments.map(a => ((!!a.amount && a.amount) || 0)).reduce((a, b) => a + b, 0)
                      ) / 100).toFixed(2)
                    )) ||
                    "-"}
                  <span
                    style={{
                      marginLeft: 2,
                      fontSize: 12,
                      fontWeight: 400,
                      opacity: 0.75,
                    }}
                  >
                            руб.
                          </span>

                        </span>
              </div>
            </div>
            <div className={styleContent._block__content}>
              <table className="Table">
                <tbody>
                  {act.payments.map((item) => (
                    <tr
                      className="Table__tr"
                      key={props.act.uuid + ":" + item.uuid}
                    >
                      <td className="Table__td">
                          <div
                            className="Td__title"
                          >
                            <div>
                              <div style={{
                                fontSize: 19,
                                fontWeight: 700
                              }}>
                                {!!item.number && "№ " + item.number} {!!item.date_number && " от " + moment(item.date_number).format("DD.MM.YYYY")}
                                {!item.date_number && !item.number && "-"}
                                {!!item.files && (
                                  <Tooltip text={'Прикрепления'} style={{height: 14, marginLeft: 8}}>
                                    <Icon
                                      name={"custom-attach-file"}
                                      color={"#202020"}
                                      size={14}
                                    />
                                  </Tooltip>
                                )}
                              </div>
                              <div style={{
                                fontSize: 13,
                                fontWeight: 400
                              }}>
                                {!!item.date_receipt && moment(item.date_receipt).format("DD.MM.YYYY") + " (получен)"}
                              </div>
                            </div>
                          </div>
                      </td>
                      <td className="Table__td">
                        <div
                          className="Td__title"
                        >
                          <div style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center"
                          }}>
                            {!!item.contract_stage && (
                              <div style={{
                                padding: "4px 8px",
                                backgroundColor: "#ffffff75",
                                borderRadius: 4,
                                marginLeft: 12,
                                textAlign: "right"
                              }}>
                                <div style={{
                                  fontSize: 21,
                                  fontWeight: 700,
                                }}>
                                  {(!!item.contract_stage.name && item.contract_stage.name) || "-"}
                                </div>
                                <div style={{
                                  fontSize: 10,
                                  fontWeight: 400,
                                  opacity: .75,
                                }}>Этап</div>
                              </div>
                            )}
                            {!!item.contract_act && (
                              <div style={{
                                padding: "4px 8px",
                                backgroundColor: "#ffffff75",
                                borderRadius: 4,
                                marginLeft: 12,
                                textAlign: "right"
                              }}>
                                <div style={{
                                  fontSize: 21,
                                  fontWeight: 700,
                                }}>
                                  {!!item.contract_act.number && "№ " + item.contract_act.number} {!!item.contract_act.date_number && " от " + moment(item.contract_act.date_number).format("DD.MM.YYYY")}
                                  {!item.contract_act.date_number && !item.contract_act.number && "-"}
                                </div>
                                <div style={{
                                  fontSize: 10,
                                  fontWeight: 400,
                                  opacity: .75,
                                }}>Акт</div>
                              </div>
                            )}
                            {(!!item.contract_stage || !!item.contract_act) && (
                              <div style={{
                                height: 32,
                                width: 2,
                                backgroundColor: "#20202025",
                                borderRadius: 4,
                                marginLeft: 12,
                                textAlign: "right"
                              }}>
                              </div>
                            )}

                            <div style={{
                              padding: "4px 8px",
                              backgroundColor: "#ffffff75",
                              borderRadius: 4,
                              marginLeft: 12,
                              textAlign: "right"
                            }}>
                              <div style={{
                                fontSize: 21,
                                fontWeight: 700,
                              }}>
                                {(!!item.amount && new Intl.NumberFormat("ru-RU").format(
                                    (item.amount / 100).toFixed(2)
                                  )) ||
                                  "-"}
                              </div>
                              <div style={{
                                fontSize: 10,
                                fontWeight: 400,
                                opacity: .75,
                              }}>Сумма</div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default ActPaymentsList;
