import React, {useEffect, useRef, useState} from "react";
import "./InputText.scss";
import {ReactComponent as IconEyeSolid} from "./eye-solid.svg";
import {ReactComponent as IconEyeSlashSolid} from "./eye-slash-solid.svg";

export const InputText = (props) => {
  const [isUpdate, setIsUpdate] = useState(false);

  const [name, setName] = useState(props.name);
  const [type, setType] = useState(props.type || "text");

  const [label, setLabel] = useState(props.label || null);
  const [isRequired, setIsRequired] = useState(props.isRequired || false);
  const [placeholder, setPlaceholder] = useState(props.placeholder || null);
  const [disabled, setDisabled] = useState(props.disabled || false);
  const [isViewPassword, setIsViewPassword] = useState(props.isViewPassword || false);

  const [value, setValue] = useState(props.value || null);
  const [isFocus, setIsFocus] = useState(false);
  const [isFixed, setIsFixed] = useState(props.isFixed || false);
  const [isError, setIsError] = useState(props.isError || false);

  const [ref, setRef] = useState(props.reference);
  const input = useRef(null);

  useEffect(() => {
    return () => {
      setValue("");
      setLabel(null);
      setIsFixed(false);
      setType("text");
      setRef(null);
      setDisabled(false);
      setIsFocus(false);
      setPlaceholder(null);
      setIsRequired(false);
      setName(null);
      setIsError(false);
    };
  }, []);

  useEffect(() => {
    setValue(props.value || '');
    setLabel(props.label || null);
    setIsFixed(props.isFixed || false);
    setType(props.type);
    setRef(props.reference || null);
    setDisabled(props.disabled || false);
    setPlaceholder(props.placeholder || null);
    setIsRequired(props.isRequired || false);
    setName(props.name || null);
    setIsError(props.isError || false);
  }, [
    props.value,
    props.label,
    props.isFixed,
    props.type,
    props.reference,
    props.disabled,
    props.placeholder,
    props.isRequired,
    props.name,
    props.isError,
    isUpdate
  ]);

  const classNameValueFocus = (value, isFocus, isFixed) => {
    if (isFocus) {
      return " active focus";
    } else if (isFixed) {
      return " active";
    } else if (!!value) {
      return " active";
    }

    return null;
  };

  function onChange(event) {
    setIsUpdate(prevState => !prevState);
    setValue(event.target.value || null);
    if (props.onChange) {
      props.onChange(event);
    }
  }

  return (
    <div className={"Field"}>
        <label>
          {label && (
            <span
              className={"Label " + classNameValueFocus(value, isFocus, isFixed)}
              style={{
                fontWeight: 400,
                color: isError && "#f44336",
                opacity: isError && 1
              }}
            >
              {label}
              {isRequired && (
                <span style={{
                  marginLeft: 4,
                  color: "#f44336",
                }}>*</span>
              )}
            </span>
          )}
          {isViewPassword && (
            <div className="Eye" onClick={type !== 'password' ? () => setType('password') : () => setType('text')}>
              {type !== 'password' && (<IconEyeSolid/>)}
              {type === 'password' && (<IconEyeSlashSolid/>)}
            </div>
          )}
          <input
            className={"Input"}
            style={{
              borderColor: isError && "#f4433675"
            }}
            type={type}
            value={props.value || ""}
            autoComplete="off"
            placeholder={placeholder}
            disabled={disabled}
            ref={ref || input}
            name={name}
            autoFocus={props.autoFocus}
            onChange={(event) => onChange(event)}
            onWheel={(e) => {
              e.currentTarget.blur();
            }}
            onFocus={(e) => {
              setIsFocus(true);
            }}
            onBlur={(e) => {
              setIsFocus(false);
            }}
          />
        </label>
    </div>
  );
}
