import React from "react";
import {ButtonIcon, Loading} from "@vokymlak/kabinet-ui";
import {CalcProcurementTable} from "./CalcProcurementTable";
import {CalcP4Table} from "./CalcP4Table";
import {CalcP5Table} from "./CalcP5Table";
import {CalcP02Table} from "./CalcP02Table";
import {CalcP03Table} from "./CalcP03Table";
import {CalcCompeteTable} from "./CalcCompeteTable";
import {CalcEShopTable} from "./CalcEShopTable";
import {CalcEpTable} from "./CalcEpTable";
import {CalcEfficiencyFactorTable} from "./CalcEfficiencyFactorTable";

function ProjectCalcContractsPage(props) {
  const {
    object,

    calculations,
    setCalculations,

    isUpdateCalculations,
    setIsUpdateCalculations,
  } = props;

  return (
    <>
      <div className="Page__title">
        <div className="Title__text">
          <div
            className="Title__text"
            style={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
              fontSize: 19,
              fontWeight: 700,
              marginRight: 6,
            }}
          >
            <div>Контрактация</div>
          </div>
        </div>

        <div className="Title__settings">
          <ButtonIcon
            tooltipText={"Обновить"}
            iconName={"custom-change-circle"}
            iconColor={"#20202075"}
            size={28}
            style={{marginLeft: 8}}
            onClick={() => (
              setCalculations(null),
                setIsUpdateCalculations(!isUpdateCalculations)
            )}
          />
        </div>
      </div>

      {!calculations && (
        <div className={"Page__loader"}>
          <Loading/>
        </div>
      )}

      {!!calculations && (
        <div className="Page__content">
          <CalcProcurementTable {...props} />

          {object.law.tag === "44" && <CalcP4Table {...props} />}

          {object.law.tag === "44" && <CalcP5Table {...props} />}

          {object.law.tag === "223" && <CalcP02Table {...props} />}

          {object.law.tag === "223" && <CalcP03Table {...props} />}

          <CalcEpTable {...props} />

          <CalcEShopTable {...props} />

          <CalcCompeteTable {...props} />

          <CalcEfficiencyFactorTable {...props} />

          <br/><br/><br/><br/><br/>
          <br/><br/><br/><br/><br/>
          <br/><br/><br/><br/><br/>
          <br/><br/><br/><br/><br/>
          <br/><br/><br/><br/><br/>
        </div>
      )}
    </>
  );
}

export default ProjectCalcContractsPage;
