import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../../../config/url";
import { getErrorMessageByKey, useNotification, Input, Textarea, Modal } from "@vokymlak/kabinet-ui";

function ModalProjectSmpItemAdd(props) {
  const [project, setProject] = useState(null);

  const inputName = useRef(null);
  const inputAmount = useRef(null);

  const { notification } = useNotification();

  useEffect(() => {
    if (props.project) {
      axios
        .get("/api/project/" + props.project.uuid, {
          withCredentials: false,
          baseURL: ROOT_URL,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("tokenSession"),
          },
        })
        .then((response) => {
          setProject(response.data ? response.data : null);
        })
        .catch((error) => {
          notification(getErrorMessageByKey(error.response.data.error), "red");
        });
    }

    return () => {
      setProject(null);
    };
  }, [props.project]);

  const numberConvert = (numberString) => {
    return !!numberString && numberString.length > 0
      ? Number(String(Number(numberString).toFixed(2)).replace(".", ""))
      : null;
  };

  const changeAdd = (event) => {
    event.preventDefault();

    const name = inputName.current.value;
    const amount = inputAmount.current.value;

    if (!name || name.trim().length === 0) {
      return;
    }

    const jsonData = JSON.stringify({
      title: name.trim(),
      amount: numberConvert(amount),
    });

    axios
      .post(
        "/api/project/" + props.project.uuid + "/smp-expense/add",
        jsonData,
        {
          withCredentials: false,
          baseURL: ROOT_URL,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("tokenSession"),
          },
        }
      )
      .then(() => {
        props.successfulRequest();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });

    props.handleClose();
  };

  return (
    !!project && (
      <Modal handleClose={() => props.handleClose()}>
        <form className="Form" onSubmit={(event) => changeAdd(event)}>
          <div className="Form__title">
            {project.name}
            <div className="Text__signature">Расход</div>
          </div>

          <div className="Form__field">
            <Textarea reference={inputName} label="Наименование *" />
          </div>

          <div className="Form__field">
            <Input reference={inputAmount} type="number" label="Сумма" />
          </div>

          <div className="Form__actions Form__actions_center">
            <button className="Action__button Action__button_all Action__button_blue">
              Добавить
            </button>
          </div>

          <div className="Form__actions Form__actions_center">
            <div className="Action__link" onClick={() => props.handleClose()}>
              Отмена
            </div>
          </div>
        </form>
      </Modal>
    )
  );
}

export default ModalProjectSmpItemAdd;
