import React, {Fragment} from "react";
import {Input} from "../../../components/Input";
import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";
import {Loading, Select} from "@vokymlak/kabinet-ui";

const ParserView = (props) => {
    const {
        object,
        inputLogin,
      inputPassword,
        onChangeParser,
        isRequest,

      selectFzType,
      changeSelectFzType,
      selectYear,
      changeSelectYear,
    } = props;

    return (
      <ScrollBlock style={{ width: "100%", height: "100%" }}>
        <div className="Page">
            <div className="Page__title">
                <div className="Title__text" style={{display: "flex"}}>
                    Парсинг
                </div>

                <div className="Title__settings">
                </div>
            </div>

            <div className="Page__content">
                <div>
                    <div className="Form__field Form__field_last">
                        <Input reference={inputLogin} type="text" label="Логин" value={""}/>
                    </div>
                    <div className="Form__field Form__field_last">
                        <Input reference={inputPassword} type="text" label="Пароль" value={""}/>
                    </div>
                    <div className="Form__field Form__field_last">
              <Select
                isFixed={true}
                options={[
                    {
                        value: "null",
                        title: "Не выбран",
                    },
                    {
                        value: "44",
                        title: "44",
                    },
                    {
                        value: "223",
                        title: "223",
                    },
                ]}
                label="Закон"
                value={selectFzType}
                onChange={(event) => changeSelectFzType(event.target.value)}
              />
                    </div>
                  <div className="Form__field Form__field_last">
                    <Select
                      isFixed={true}
                      options={[
                        {
                          value: "null",
                          title: "Не выбран",
                        },
                        {
                          value: "2020",
                          title: "2020",
                        },
                        {
                          value: "2021",
                          title: "2021",
                        },
                        {
                          value: "2022",
                          title: "2022",
                        },
                        {
                          value: "2023",
                          title: "2023",
                        },
                        {
                          value: "2024",
                          title: "2024",
                        },
                      ]}
                      label="Год"
                      value={selectYear}
                      onChange={(event) => changeSelectYear(event.target.value)}
                    />
                  </div>
                    <br/>
                    <div className="Form__actions Form__actions_center">
                        <button
                            className="Action__button Action__button_all Action__button_blue"
                            onClick={!isRequest ? () => onChangeParser() : () => null}
                        >
                            {!isRequest ? "Начать" : <Loading size="medium" color="blue" /> }
                        </button>
                    </div>
                </div>
                <div>
                    {!!object && (
                      <table className="Table">
                          <tbody>
                          <tr className="Table__tr">
                              <td className="Table__td" style={{width: 162}}>
                                  <div className="Td__title">-</div>
                              </td>
                              <td className="Table__td">
                              </td>
                          </tr>
                          <tr className="Table__tr">
                              <td className="Table__td" style={{width: 162}}>
                                  <div className="Td__title">
                                      Скриншот
                                  </div>
                              </td>
                              <td className="Table__td">
                                  {!!object.full_screenshot && (
                                  <img
                                    src={"data:image/png;base64," + object.full_screenshot}
                                    style={{
                                        width: 640,
                                    }}
                                  />
                                  )}
                              </td>
                          </tr>

                          </tbody>
                      </table>
                    )}
                </div>
            </div>
        </div>
      </ScrollBlock>
    );
};

export default ParserView;
