import React, {useState} from "react";
import styleContent from "../../../../styles/modules/Block.module.scss";
import ModalProjectEmployeeEdit from "./ModalProjectEmployeeEdit";
import {getAccountFio} from "@vokymlak/kabinet-ui";
import {ROOT_URL} from "../../../../config/url";
import {Avatar} from "../../../../components/Avatar/Avatar";

function ProjectEmployeesList(props) {
  const {
    setIsUpdateList,
    isUpdateList,
    object
  } = props

  const [modalObjectEdit, setModalObjectEdit] =
    useState(null);

  function isMD5Hash(str) {
    // Проверяем, соответствует ли строка формату MD5 хэша.
    const md5Regex = /^[a-f0-9]{32}$/i;
    return md5Regex.test(str);
  }

  return (
    <>
      {!!object.employees && object.employees.length > 0 && (
        <>
          <div
            className={styleContent._block + " " + styleContent._block_bottom}
          >
            <div className={styleContent._block__title}>
              Сотрудники{" "}
              {!!object.employees &&
                " (" + object.employees.length + ")"}
            </div>
            <div className={styleContent._block__content}>
              <table className="Table">
                <tbody>
                {object.employees.map((item) => (
                  <tr
                    className="Table__tr Table__tr_hover"
                    key={item.uuid}
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => setModalObjectEdit(item)}
                  >
                    <td className="Table__td" style={{
                      paddingBottom: 12
                    }}>

                      <div style={{
                        display: "flex",
                      }}>
                        <div style={{
                          marginRight: 8,
                          height: 42
                        }}>
                          {!!item.employee.avatar && isMD5Hash(item.employee.avatar) && (
                            <img height={42} width={42} style={{
                              borderRadius: 42,
                            }} src={ROOT_URL + "/api/account/avatar/" + item.employee.avatar}/>
                          )}
                          {!item.employee.avatar && (
                            <Avatar size={42} value={getAccountFio(item.employee)}/>
                          )}
                        </div>

                        <div
                          className="Td__title"
                          style={{
                            fontSize: 15,
                            fontWeight: 400,
                          }}
                        >
                          <div
                            style={{
                              marginBottom: 4,
                            }}
                          >
                            {getAccountFio(item.employee)}
                            <span
                              style={{
                                fontSize: 11,
                                opacity: 0.75,
                                marginLeft: 4
                              }}
                            >
                              {!!item.employee.position &&
                                "(" + item.employee.position + ")"}
                            </span>
                          </div>

                          {!!item.employee.login && (
                            <div
                              style={{
                                fontSize: 11,
                                fontWeight: 700,
                                backgroundColor: "#202020",
                                display: "inline-block",
                                color: "white",
                                padding: "2px 6px",
                                borderRadius: 24,
                                marginRight: 4,
                              }}
                            >
                              {item.employee.login}
                            </div>
                          )}

                          {!!item.employee.email && (
                            <div
                              style={{
                                fontSize: 11,
                                fontWeight: 700,
                                backgroundColor: "#202020",
                                display: "inline-block",
                                color: "white",
                                padding: "2px 6px",
                                borderRadius: 24,
                                marginRight: 4,
                              }}
                            >
                              {item.employee.email}
                            </div>
                          )}
                        </div>
                      </div>
                    </td>
                    <td className="Table__td">
                      <div
                        className="Td__title" style={{
                        textAlign: "right"
                      }}>
                        <div style={{
                          display: "inline-block"
                        }}>
                          <div
                            style={{
                              backgroundColor: "#202020",
                              padding: "0 4px",
                              display: "flex",
                              flexDirection: "row",
                              flexWrap: "nowrap",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: 24,
                              marginLeft: 12,
                            }}
                          >
                            {!!item.project_employee_role_type && (
                              <div
                                style={{
                                  padding: "4px 4px",
                                  color: "white",
                                  fontSize: 14,
                                  pointerEvents: "auto",
                                  fontWeight: 700,
                                  cursor: "default"
                                }}
                              >
                                {item.project_employee_role_type.name}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
          </div>

          {!!modalObjectEdit && (
            <ModalProjectEmployeeEdit
              object={modalObjectEdit}
              successfulRequest={() => setIsUpdateList(!isUpdateList)}
              handleClose={() => setModalObjectEdit(null)}
            />
          )}
        </>
      )}
    </>
  );
}

export default ProjectEmployeesList;
