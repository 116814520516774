import React, { useRef, useState } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../../config/url";
import {
  useNotification,
  getErrorMessageByKey,
  jsonFormatText,
  isValidateNullText,
  Modal,
  Input,
  Select, formatBytes,
} from "@vokymlak/kabinet-ui";

function ModalAddCatalogEntry(props) {
  const inputTitle = useRef(null);
  const inputCatalogEntryTypeTag = useRef(null);

  const inputFile = useRef(null);
  const [files, setFiles] = useState(null);
  const [catalogEntryType, setCatalogEntryType] = useState("FILE");


  const fileSend = (event) => {
    const files = event.target.files;
    setFiles(Array.from(files));
  };

  const { notification } = useNotification();

  const changeAdd = (event) => {
    event.preventDefault();

    const catalogEntryTypeTag = inputCatalogEntryTypeTag.current.value;

    if (catalogEntryTypeTag === "FILE") {
      if (!files || (!!files && files.length === 0)) {
        notification("Прикрепите файл", "red");
        return;
      }

      if (!!files && files.filter(f => f.size > 262144000).length > 0) {
        notification("Один из файлов превышает допустимый размер в 25Мб", "red");
        return;
      }

      if (!!files && files.reduce(function(previousValue, currentValue) {
        return {
          size: previousValue.size + currentValue.size
        }
      }).size > (262144000 * 10)) {
        notification("Общий размер файлов превышает 250Мб", "red");
        return;
      }

      if (!!files && files.length > 10) {
        notification("Можно прикрепить не больше 10 файлов за раз", "red");
        return;
      }

      let formData = new FormData();
      files.forEach(f => {
        formData.append("files", f);
      })

      axios
        .post(
          "/api/catalog-entry/add/files",
          formData,
          {
            withCredentials: false,
            baseURL: ROOT_URL,
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + localStorage.getItem("tokenSession"),
            },
          }
        )
        .then(() => {
          props.successfulRequest();
          notification("Файлы прикреплены", "green");
          props.handleClose();
        })
        .catch((error) => {
          notification(getErrorMessageByKey(error.response.data.error), "red");
        });
    }

    if (catalogEntryTypeTag === "FOLDER") {
      const title = jsonFormatText(inputTitle.current.value);

      if (isValidateNullText(title)) {
        notification("Заполните все обязательные поля", "red");
        return;
      }

      const jsonData = JSON.stringify({
        title: title,
      });

      axios
        .post("/api/catalog-entry/add/folder", jsonData, {
          withCredentials: false,
          baseURL: ROOT_URL,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("tokenSession"),
          },
        })
        .then(() => {
          props.successfulRequest();
          notification("Папка добавлена", "green");
          props.handleClose();
        })
        .catch((error) => {
          notification(getErrorMessageByKey(error.response.data.error), "red");
        });
    }
  };

  return (
    <Modal handleClose={() => props.handleClose()}>
      <form className="Form" onSubmit={(event) => changeAdd(event)}>
        <div className="Form__title">Каталог</div>

        <div className="Form__field">
          <Select
            isFixed={true}
            reference={inputCatalogEntryTypeTag}
            options={[
              {
                title: "Файл",
                value: "FILE",
              },
              {
                title: "Папка",
                value: "FOLDER",
              },
            ]}
            label="Тип"
            onChange={(event) => setCatalogEntryType(event.target.value)}
          />
        </div>

        {catalogEntryType === "FOLDER" && (
        <div className="Form__field Form__field_last">
          <Input reference={inputTitle} type="text" label="Наименование *" />
        </div>
          )}

        {catalogEntryType === "FILE" && (
          <>
        <div className="Form__field">
          <Input
            reference={inputFile}
            type="file"
            label="Файлы"
            isFixed={true}
            onChange={(event) => fileSend(event)}
            isMultiple={true}
          />
        </div>

        <div className="Form__field Form__field_last">
          {!!files && files.map((file, index) => (
            <div key={'file_' + index} style={{
              backgroundColor: "#f1f1f1",
              padding: "6px 10px",
              borderRadius: 5,
              fontSize: 15,
              marginBottom: 4,
              marginTop: 4
            }}>
              {file.name}
              <div>
                {formatBytes(file.size)}
              </div>
            </div>
          ))}
        </div>
        </>
        )}

        <div className="Form__actions Form__actions_center">
          <button className="Action__button Action__button_all Action__button_blue">
            Добавить
          </button>
        </div>
      </form>
    </Modal>
  );
}

export default ModalAddCatalogEntry;
