import React, {Component, useState} from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import './ScrollBlock.scss'

export const ScrollBlock = (props) => {

  const renderView = ({ style, ...props }) => {
    const viewStyle = {
      padding: 0,
    };
    return (
      <div
        style={{ ...style, ...viewStyle }}
        {...props}/>
    );
  }

  const renderThumbHorizontal = ({ style, ...props }) => {
    const thumbStyle = {
      backgroundColor: '#202020',
      borderRadius: 2,
      opacity: .33,
      cursor: "pointer",
    };
    return (
      <div
        style={{ ...style, ...thumbStyle }}
        className="track-thumb-horizontal"
        {...props}/>
    );
  }

  const renderThumbVertical = ({ style, ...props }) => {
    const thumbStyle = {
      backgroundColor: '#202020',
      borderRadius: 2,
      opacity: .33,
      cursor: "pointer",
    };
    return (
      <div
        style={{ ...style, ...thumbStyle }}
        className="track-thumb-vertical"
        {...props}/>
    );
  }

    return (
      <Scrollbars
        renderView={renderView}
        renderTrackHorizontal={props => <div {...props} className="track-horizontal"/>}
        renderTrackVertical={props => <div {...props} className="track-vertical"/>}
        renderThumbHorizontal={renderThumbHorizontal}
        renderThumbVertical={renderThumbVertical}
        {...props}/>
    );
}
