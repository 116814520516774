import React, {useEffect, useRef, useState} from "react";
import { ROOT_URL } from "../../../../../config/url";
import {useList, useAction} from "@vokymlak/kabinet-ui";

function useContractSpecifications(props) {
  const {
    list: [list, count, setList],
    update: [isUpdateList, setIsUpdateList, updateList],
    search: [searchQuery, setSearchQuery],
  } = useList({
    apiUrl: "/api/client/contract/" + props.contract.uuid + "/specifications",
    rootUrl: ROOT_URL,
    isSearch: true
  });

  const inputSearchText = useRef(null);

  function onChangeSearchText() {
    setSearchQuery(inputSearchText.current.value);
  }

  const { setAction } = useAction(props.contract.uuid, () => updateList());

  useEffect(() => {
    if (!!list) {
      const listUuid = list.map((i) => i.uuid);
      setAction([...listUuid, props.contract.uuid]);
    } else {
      setAction(props.contract.uuid);
    }
  }, [list]);

  return {
    searchQuery,
    inputSearchText,
    onChangeSearchText,

    object: props.contract,

    list,
    setList,
    count,

    setIsUpdateList,
    isUpdateList,
  };
}

export default useContractSpecifications;
