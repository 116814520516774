import React, { useEffect, useState } from "react";
import { ROOT_URL } from "../../../../../../../config/url";
import { useAction, useList } from "@vokymlak/kabinet-ui";

function useProjectTasks(props) {
  const [isArchive, setIsArchive] = useState(false);

  const getParams = () => {
    let params = null;
    if (isArchive) {
      params = {
        ...params,
        archive: isArchive,
      };
    }
    return params;
  };

  const {
    list: [list, _count, setList],
    update: [isUpdateList, setIsUpdateList, updateList],
    setApiUrlParams,
  } = useList({
    apiUrl: "/api/client/project/" + props.project.uuid + "/finance-client-tasks",
    rootUrl: ROOT_URL,
    urlParams: { ...getParams() },
  });

  useEffect(() => {
    setApiUrlParams({ ...getParams() });
  }, [isArchive]);

  const { setAction } = useAction("project-finance-client-tasks", () => updateList());

  useEffect(() => {
    if (!!list) {
      const listUuid = list.map((i) => i.uuid);
      setAction([...listUuid, "project-finance-client-tasks"]);
    } else {
      setAction("project-finance-client-tasks");
    }
  }, [list]);

  const [selectContracts, setSelectContracts] = useState([]);

  const isSelectContract = (selectList, uuid) => {
    let isTrue = false
    selectList.forEach(u => {
      if (u === uuid) {
        isTrue = true;
      }
    });
    return isTrue
  }

  const isSelectAllContract = (list, selectList) => {
    return !!list && !!selectList && selectList.length === list.length
  }

  const changeSelectContract = (uuid) => {
    let isNew = true;
    let index = 0;

    selectContracts.forEach((u, i) => {
      if (u === uuid) {
        isNew = false;
        index = i;
      }
    });

    if (isNew) {
      setSelectContracts([...selectContracts, uuid]);
    } else {
      selectContracts.splice(index, 1);
      setSelectContracts([...selectContracts]);
    }
  };

  const changeSelectAllContract = (list, selectList) => {
    const selectContracts = []

    if (!!list && !!selectList && selectList.length !== list.length) {
      list.forEach(item => {
        selectContracts.push(item.uuid)
      });
    }

    setSelectContracts(selectContracts);
  };

  return {
    object: props.project,

    list,
    setList,
    setIsUpdateList,
    isUpdateList,

    isArchive,
    setIsArchive,

    selectContracts,
    setSelectContracts,
    isSelectContract,
    isSelectAllContract,
    changeSelectContract,
    changeSelectAllContract,
  };
}

export default useProjectTasks;
