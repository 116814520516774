import React, { useEffect, useRef, useState } from "react";
import { ROOT_URL } from "../../../config/url";
import {
  useAction,
  useList,
  useUnroll,
  useLocalStorage, useObject,
} from "@vokymlak/kabinet-ui";
import {ROLE_DIRECTOR, ROLE_EMPLOYEE} from "../../../config/constants";

export const useHome = () => {
  const {
    list: [listEmployees],
  } = useList({
    apiUrl: "/api/employees/list",
    rootUrl: ROOT_URL,
    urlParams: {
      roles: [ROLE_DIRECTOR, ROLE_EMPLOYEE],
    }
  });

  const [filterProjects, setFilterProjects] = useLocalStorage(
    "filterHome",
    {
      filterEmployees: [],
    }
  );

  const [filterEmployees, setFilterEmployees] = useState(
    filterProjects.filterEmployees || []
  );

  useEffect(() => {
    setFilterProjects({
      filterEmployees,
    });
  }, [
    filterEmployees,
  ]);

  const getParams = () => {
    let params = null;
    return params;
  };

  const getUrlParams = () => {
    return {
      ...getParams(),
      employee:
        (!!filterEmployees &&
          filterEmployees.length > 0 &&
          filterEmployees.join(",")) ||
        "",
    };
  };

  const {
    object: [object, setObject],
    update: [isUpdateObject, setIsUpdateObject, updateObject],
    params: [_paramsProjects, setParamsProjects],
  } = useObject({
    apiUrl: "/api/home",
    rootUrl: ROOT_URL,
    urlParams: getUrlParams(),
  });

  useEffect(() => {
    setParamsProjects({ ...getUrlParams() });
  }, [
    filterEmployees,
  ]);

  const [isModalFilter, setIsModalFilter] = useState(false);

  const { setAction } = useAction(["projects", "appeals"], () => updateObject());

  const deleteFilter = (filters, filter, setFilter) => {
    let index = null;
    if (!!filters) {
      filters.forEach((sf, i) => {
        if (sf === String(filter)) {
          index = i;
        }
      });
    }
    if (!!index || String(index) === "0") {
      filters.splice(index, 1);
      setFilter([...filters]);
    }
  };

  const getEmployeeByFilter = (array, filter) => {
    let object = null;
    if (!!array) {
      array.forEach((o) => {
        if (o.account.uuid === filter) {
          object = o;
        }
      });
    }
    return object;
  };

  return {
    object,
    setObject,

    isUpdateObject,
    setIsUpdateObject,

    isModalFilter,
    setIsModalFilter,
    filterEmployees,
    setFilterEmployees,
    listEmployees,
    getEmployeeByFilter,
    deleteFilter,
  };
};
