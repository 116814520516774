import React, { useRef, useState } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../../config/url";
import {
  getErrorMessageByKey,
  useNotification,
  Modal,
  useObject,
  Input,
  jsonFormatText,
  isValidateNullText,
} from "@vokymlak/kabinet-ui";

function ModalEditHandbookEntry(props) {
  const [isPublic, setIsPublic] = useState(false);

  const {
    object: [object],
  } = useObject({
    apiUrl: "/api/handbook-entry/" + props.object.uuid,
    rootUrl: ROOT_URL,
    callbackResponse: (data) => {
      setIsPublic(!!data && data.is_public);
    },
    callbackError: () => {
      props.handleClose();
    },
  });

  const inputTitle = useRef(null);

  const { notification } = useNotification();
  const [isDelete, setIsDelete] = useState(false);

  const changeEdit = (event) => {
    event.preventDefault();

    const title = jsonFormatText(inputTitle.current.value);

    if (isValidateNullText(title)) {
      notification("Заполните все обязательные поля", "red");
      return;
    }

    const jsonData = JSON.stringify({
      title: title,
      is_public: isPublic,
    });

    axios
      .put("/api/handbook-entry/" + props.object.uuid + "/edit", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Данные сохранены", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const changeDelete = (event) => {
    event.preventDefault();

    axios
      .delete("/api/handbook-entry/" + props.object.uuid + "/delete", {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Запись удалена", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    !!object && (
      <Modal handleClose={() => props.handleClose()}>
        {!isDelete && (
          <form className="Form" onSubmit={(event) => changeEdit(event)}>
            <div className="Form__title">
              {object.title}
              <div className="Text__signature">
                {object.handbook_entry_type.tag === "ARTICLE"
                  ? "Статья"
                  : object.handbook_entry_type.tag === "GROUP_ARTICLES"
                  ? "Группа статей"
                  : object.handbook_entry_type.tag === "HEADING"
                  ? "Заголовок"
                  : null}
              </div>
            </div>

            <div className="Form__field">
              <Input
                reference={inputTitle}
                type="text"
                label="Наименование *"
                value={object.title}
              />
            </div>

            <div className="Form__field Form__field_last">
              <div
                className="Button__swith"
                style={{
                  padding: "8px",
                  width: "100%",
                  border: isPublic
                    ? "2px solid #ffe082"
                    : "2px dashed #20202015",
                  backgroundColor: isPublic ? "#ffe082" : "#ffffff",
                  borderRadius: 6,
                  marginTop: 20,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  cursor: "pointer",
                }}
                onClick={() => setIsPublic(!isPublic)}
              >
                <div
                  className="Swith__text"
                  style={{
                    fontSize: 17,
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Опубликовать
                </div>
                <div
                  className={
                    "Swith Swith_gray" +
                    ((isPublic && " Swith_active Swith_active_gray") || "")
                  }
                ></div>
              </div>
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_blue">
                Применить
              </button>
            </div>

            <div className="Form__actions Form__actions_center">
              <div
                className="Action__link Action__link_red"
                onClick={() => setIsDelete(true)}
              >
                Удалить
              </div>
              {/*<div className="Action__link" onClick={() => props.handleClose()}>*/}
              {/*  Отмена*/}
              {/*</div>*/}
            </div>
          </form>
        )}
        {isDelete && (
          <form className="Form" onSubmit={(event) => changeDelete(event)}>
            <div className="Form__title">
              {object.title}
              <div className="Text__signature">Удалить</div>
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_red">
                Удалить
              </button>
            </div>

            <div className="Form__actions Form__actions_center">
              <div className="Action__link" onClick={() => setIsDelete(false)}>
                Отмена
              </div>
            </div>
          </form>
        )}
      </Modal>
    )
  );
}

export default ModalEditHandbookEntry;
