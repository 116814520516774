import React from "react";
import {Icon, Modal,} from "@vokymlak/kabinet-ui";
import {NavLink} from "react-router-dom";

function ProjectPfhdContractTableFact(props) {
  const {
    object
  } = props;

  return (
    !!object &&
          <table className="Table">
            <thead className="Thead">
            <tr className="Table__tr Table__tr_padding Table__tr_border">
              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#fff59d",
                }}
                colSpan={6}
              >
                <div className="Th__title" style={{height: 16}}>
                  Факт
                </div>
              </th>
            </tr>

            <tr className="Table__tr Table__tr_padding Table__tr_border">
              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#fff59d",
                  height: 38
                }}
              >
                <div className="Th__title" style={{height: 16}}>
                  {/*<Icon*/}
                  {/*  name={"custom-circle-arrow-right"}*/}
                  {/*  size={16}*/}
                  {/*  color={"#20202050"}*/}
                  {/*/>*/}
                </div>
              </th>

              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#fff59d",
                }}
              >
                <div className="Th__title">Номер договора</div>
              </th>

              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#fff59d",
                }}
              >
                <div className="Th__title">Реестровый номер</div>
              </th>

              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#fff59d",
                }}
              >
                <div className="Th__title">Предмет договора</div>
              </th>

              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#fff59d",
                }}
              >
                <div className="Th__title">Контрагент</div>
              </th>

              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#fff59d",
                }}
              >
                <div className="Th__title">Сумма по платежам</div>
              </th>
            </tr>
            </thead>
            <tbody>

            {!!object.pfhd_contracts && object.pfhd_contracts.map(item => (!!item.contract &&
              <tr
                className={
                  "Table__tr Table__tr_padding Table__tr_border"
                }
                key={"item_" + item.uuid}
              >
                <td
                  className="Table__td Table__td_border"
                  style={{
                    backgroundColor: "#fff59d50",
                  }}
                >
                  <NavLink to={"/contract/" + item.contract.uuid + '/specifications'} target={"_blank"}>
                    <div
                      className="Td__text Td__text_center"
                      style={{height: 20, cursor: "pointer"}}
                    >
                      <Icon
                        name={"custom-circle-arrow-right"}
                        size={20}
                        color={"#202020"}
                      />
                    </div>
                  </NavLink>
                </td>

                <td
                  className="Table__td Table__td_border"
                  style={{
                    backgroundColor: "#fff59d50",
                  }}
                >
                  <div className="Td__text Td__text_center">
                    {(!!item.contract.contract_number &&
                      item.contract.contract_number) || "-"}
                  </div>
                </td>

                <td
                  className="Table__td Table__td_border"
                  style={{
                    backgroundColor: "#fff59d50",
                  }}
                >
                  <div className="Td__text Td__text_center">
                    {(!!item.contract.registry_number &&
                      item.contract.registry_number) || "-"}
                  </div>
                </td>

                <td
                  className="Table__td Table__td_border"
                  style={{
                    backgroundColor: "#fff59d50",
                  }}
                >
                  <div className="Td__text">
                    {(!!item.contract.subject &&
                      item.contract.subject) || "-"}
                  </div>
                </td>

                <td
                  className="Table__td Table__td_border"
                  style={{
                    backgroundColor: "#fff59d50",
                  }}
                >
                  <div className="Td__text Td__text_center">
                    {(!!item.contract.supplier &&
                      item.contract.supplier) || "-"}
                  </div>
                </td>

                <td
                  className="Table__td Table__td_border"
                  style={{
                    backgroundColor: "#fff59d50",
                  }}
                >
                  <div className="Td__text Td__text_center">
                    {(!!item.count_amount &&
                        new Intl.NumberFormat("ru-RU").format(
                          (item.count_amount / 100).toFixed(2)
                        )) ||
                      "0,00"}
                  </div>
                </td>
              </tr>
            ))}

            <tr
              className={
                "Table__tr Table__tr_padding Table__tr_border"
              }
            >
              <td
                className="Table__td Table__td_border"
                style={{
                  backgroundColor: "#fff59d50",
                }}
                colSpan={4}
              >
              </td>

              <td
                className="Table__td Table__td_border"
                style={{
                  backgroundColor: "#fff59d50",
                }}
              >
                <div className="Td__text Td__text_center">
                  Сумма
                </div>
              </td>

              <td
                className="Table__td Table__td_border"
                style={{
                  backgroundColor: "#fff59d50",
                }}
              >
                <div className="Td__text Td__text_center">
                  {!!object.pfhd_contracts && new Intl.NumberFormat("ru-RU").format(
                    (object.pfhd_contracts.reduce((sum, item) => sum + (item.count_amount || 0), 0) / 100).toFixed(2)
                  )}
                </div>
              </td>
            </tr>

            <tr
              className={
                "Table__tr Table__tr_padding Table__tr_border"
              }
            >
              <td
                className="Table__td Table__td_border"
                style={{
                  backgroundColor: "#fff59d50",
                }}
                colSpan={4}
              >
              </td>

              <td
                className="Table__td Table__td_border"
                style={{
                  backgroundColor: "#fff59d50",
                }}
              >
                <div className="Td__text Td__text_center">
                  Остаток
                </div>
              </td>

              <td
                className="Table__td Table__td_border"
                style={{
                  backgroundColor: "#fff59d50",
                }}
              >
                <div className="Td__text Td__text_center">
                  {(!!object.amount && !!object.pfhd_contracts &&
                    new Intl.NumberFormat("ru-RU").format(
                      ((object.amount - object.pfhd_contracts.reduce((sum, item) => sum + (item.count_amount || 0), 0)) / 100).toFixed(2)
                    )) || "-"}
                </div>
              </td>
            </tr>

            </tbody>
          </table>
  );
}

export default ProjectPfhdContractTableFact;
