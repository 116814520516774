import React, {useRef} from "react";
import axios from "axios";
import {ROOT_URL} from "../../../../../config/url";
import {getErrorMessageByKey, Input, Modal, useNotification, useObject} from "@vokymlak/kabinet-ui";

function ModalProjectAmountEdit(props) {
  const {
    object: [project],
  } = useObject({
    apiUrl: "/api/project/" + props.project.uuid,
    rootUrl: ROOT_URL,
    callbackError: () => {
      props.handleClose();
    },
  });

  const inputAmount = useRef(null);

  const {notification} = useNotification();

  const numberConvert = (numberString) => {
    return !!numberString && numberString.length > 0
      ? Number(String(Number(numberString).toFixed(2)).replace(".", ""))
      : null;
  };

  const changeEdit = (event) => {
    event.preventDefault();

    const amount = inputAmount.current.value;

    const jsonData = JSON.stringify({
      project: {
        uuid: project.uuid
      },
      amount_type: props.amountType,
      amount: numberConvert(amount),
    });

    axios
      .put("/api/project-amount/save", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Данные сохранены", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    !!project && (
      <>
        <Modal handleClose={() => props.handleClose()}>
          <form className="Form" onSubmit={(event) => changeEdit(event)}>
            <div className="Form__title">
              {props.amountType === 'EFFICIENCY_RATIO_PLAN' && 'План'}
              {props.amountType === 'E_SHOP_PLAN' && 'План эл. магазина'}
              {props.amountType === 'COMPETE_PLAN' && 'План конкурентных закупок'}
              {props.amountType === 'EFFICIENCY_RATIO' && 'Коэффициент эффективности'}
              {/*<div className="Text__signature"></div>*/}
            </div>

            <div className="Form__field">
              <Input
                reference={inputAmount}
                type="number"
                label={props.amountType === 'EFFICIENCY_RATIO' ? "Процент" : "Сумма"}
                value={props.amount !== null ? props.amount === 0 ? '0' : props.amount / 100 : ''}
              />
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_blue">
                Сохранить
              </button>
            </div>
          </form>
        </Modal>
      </>
    )
  );
}

export default ModalProjectAmountEdit;
