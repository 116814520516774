import React, {useRef} from "react";
import axios from "axios";
import {ROOT_URL} from "../../../../../config/url";
import {getErrorMessageByKey, Input, jsonFormatText, Modal, useNotification, useObject,} from "@vokymlak/kabinet-ui";

function ModalPlanAdd(props) {
  const {
    object: [object],
  } = useObject({
    apiUrl: "/api/project-pfhd/" + props.object.uuid,
    rootUrl: ROOT_URL,
    callbackError: () => {
      props.handleClose();
    },
  });

  const inputSubject = useRef(null);
  const inputSupplier = useRef(null);
  const inputAmount = useRef(null);

  const {notification} = useNotification();

  const numberConvert = (numberString) => {
    return !!numberString && numberString.length > 0
      ? Number(String(Number(numberString).toFixed(2)).replace(".", ""))
      : null;
  };

  const changeAdd = (event) => {
    event.preventDefault();

    const subject = jsonFormatText(inputSubject.current.value);
    const supplier = jsonFormatText(inputSupplier.current.value);
    const amount = inputAmount.current.value;

    const jsonData = JSON.stringify({
      subject:
        !!subject && subject.length > 0 ? subject : null,
      supplier:
        !!supplier && supplier.length > 0 ? supplier : null,

      amount: numberConvert(amount),
    });

    axios
      .post("/api/project-pfhd/" + props.object.uuid + "/plan/add", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("План добавлен", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    !!object && (
      <Modal handleClose={() => props.handleClose()}>
        <form className="Form" onSubmit={(event) => changeAdd(event)}>
          <div className="Form__title">
            Новый
            <br/>
            {!!object.ris_id && "№ " + object.ris_id}
            <div className="Text__signature">План</div>
          </div>

          <div className="Form__field">
            <Input reference={inputSubject} label="Предмет договора"/>
          </div>

          <div className="Form__field">
            <Input reference={inputSupplier} label="Контрагент"/>
          </div>

          <div className="Form__field Form__field_last">
            <Input
              reference={inputAmount}
              type="number"
              label="Сумма"
            />
          </div>

          <div className="Form__actions Form__actions_center">
            <button className="Action__button Action__button_all Action__button_blue">
              Добавить
            </button>
          </div>
        </form>
      </Modal>
    )
  );
}

export default ModalPlanAdd;
