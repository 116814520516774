import React, {useRef} from "react";
import axios from "axios";
import {ROOT_URL} from "../../../config/url";
import {
  getErrorMessageByKey,
  Input,
  jsonFormatText,
  Modal,
  Textarea,
  useNotification,
  useObject
} from "@vokymlak/kabinet-ui";

function ModalMyMarkContractEdit(props) {
  const {
    object: [object],
  } = useObject({
    apiUrl: "/api/client/contract/" + props.contract.uuid,
    rootUrl: ROOT_URL,
    callbackError: () => {
      props.handleClose();
    },
  });

  const inputCtExecutionSource = useRef(null);
  const inputCtExecutionAmount = useRef(null);
  const inputCtExecutionNote = useRef(null);

  const {notification} = useNotification();

  const numberConvert = (numberString) => {
    return !!numberString && numberString.length > 0
      ? Number(String(Number(numberString).toFixed(2)).replace(".", ""))
      : null;
  };

  const changeEdit = (event) => {
    event.preventDefault();

    const ctExecutionSource = jsonFormatText(inputCtExecutionSource.current.value);
    const ctExecutionAmount = inputCtExecutionAmount.current.value;
    const ctExecutionNote = jsonFormatText(inputCtExecutionNote.current.value);

    const jsonData = JSON.stringify({
      ct_execution_source: ctExecutionSource,
      ct_execution_amount: numberConvert(ctExecutionAmount),
      ct_execution_note: ctExecutionNote,
    });

    axios
      .put("/api/client/contract/" + props.contract.uuid + "/edit", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Данные сохранены", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    !!object && (
      <Modal handleClose={() => props.handleClose()}>
        <form className="Form" onSubmit={(event) => changeEdit(event)}>
          <div className="Form__title">
            {object.registry_number && (
              <span style={{
                marginRight: 6
              }}>
                <span style={{
                  fontSize: 12, marginRight: 2
                }}>№</span>
                {object.registry_number}
                </span>
            )}
            {object.contract_number}
            <div className="Text__signature">{object.project.project_year}{" "}{object.project.name}</div>
          </div>

          <div className="Form__field">
            <div className="Field__label">
              Предмет
            </div>
            <div className="Field__text">
              {(!!object.subject && object.subject) || "-"}
            </div>
          </div>

          <div className="Form__field">
            <div className="Field__label">
              Поставщик
            </div>
            <div className="Field__text">
              {(!!object.supplier && object.supplier) || "-"}
            </div>
          </div>

          <div className="Form__field">
            <Input
              reference={inputCtExecutionSource}
              label="Источники исполнения"
              value={!!object.ct_execution_source && object.ct_execution_source}
            />
          </div>

          <div className="Form__field">
            <Input
              reference={inputCtExecutionAmount}
              type="number"
              label="Сумма исполнения"
              value={!!object.ct_execution_amount && object.ct_execution_amount / 100}
            />
          </div>

          <div className="Form__field Form__field_last">
            <Textarea
              reference={inputCtExecutionNote}
              label="Комментарий по исполнению"
              value={object.ct_execution_note}
            />
          </div>

          <div className="Form__actions Form__actions_center">
            <button className="Action__button Action__button_all Action__button_blue">
              Закрыть договор
            </button>
          </div>

          <div className="Form__actions Form__actions_center">
            <div className="Action__link" onClick={() => props.handleClose()}>
              Отмена
            </div>
          </div>
        </form>
      </Modal>
    )
  );
}

export default ModalMyMarkContractEdit;
