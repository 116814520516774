import React, { useRef, useState } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../../../../config/url";
import {
  Modal,
  getErrorMessageByKey,
  useNotification,
  Input,
  useObject,
  jsonFormatText, Select, useList,
} from "@vokymlak/kabinet-ui";
import moment from "moment/moment";

function ModalActPaymentEdit(props) {
  const {
    list: [listStages],
  } = useList({ apiUrl: "/api/contract/" + props.contract.uuid + "/stages", rootUrl: ROOT_URL });
  // const {
  //   list: [listActs],
  // } = useList({ apiUrl: "/api/contract/" + props.contract.uuid + "/acts", rootUrl: ROOT_URL });

  const {
    object: [contract],
  } = useObject({
    apiUrl: "/api/contract/" + props.contract.uuid,
    rootUrl: ROOT_URL,
    callbackError: () => {
      props.handleClose();
    },
  });

  const {
    object: [object],
  } = useObject({
    apiUrl: "/api/contract-payment/" + props.payment.uuid,
    rootUrl: ROOT_URL,
    callbackError: () => {
      props.handleClose();
    },
  });

  const [isDelete, setIsDelete] = useState(false);

  const inputDateReceipt = useRef(null);

  const inputDate = useRef(null);
  const inputNumber = useRef(null);

  const inputAmount = useRef(null);

  const selectContractStageId = useRef(null);
  const selectContractActId = useRef(null);

  const { notification } = useNotification();

  const numberConvert = (numberString) => {
    return !!numberString && numberString.length > 0
      ? Number(String(Number(numberString).toFixed(2)).replace(".", ""))
      : null;
  };


  const changeEdit = (event) => {
    event.preventDefault();

    const dateReceipt = inputDateReceipt.current.value;

    const date = inputDate.current.value;
    const number = jsonFormatText(inputNumber.current.value);
    const amount = inputAmount.current.value;

    const contractStageId = selectContractStageId.current.value;
    const contractActId = selectContractActId.current.value;

    const jsonData = JSON.stringify({
      date_receipt: !!dateReceipt && dateReceipt.length > 0 ? dateReceipt : null,

      date_number:
        !!date && date.length > 0 ? date : null,
      number: number,
      amount: numberConvert(amount),

      contract_stage_id:
        contractStageId.length > 0
          ? contractStageId === "null"
            ? null
            : Number(contractStageId)
          : null,
      contract_act_id: props.act.id,
    });

    axios
      .put("/api/contract-payment/" + props.payment.uuid + "/edit", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Данные сохранены", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const changeDelete = (event) => {
    event.preventDefault();

    axios
      .delete("/api/contract-payment/" + props.payment.uuid + "/delete", {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Платёж удалён", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const getContractStageSelectOptions = (list) => {
    const options = [
      {
        value: "null",
        title: "Не выбрано",
      },
    ];

    list.map((item) => {
      const option = {
        value: item.id,
        title: item.name,
      };

      options.push(option);
    });

    return options;
  };

  const getContractActSelectOptions = (list) => {
    const options = [
      // {
      //   value: "null",
      //   title: "Не выбрано",
      // },
    ];

    list.map((item) => {
      const option = {
        value: item.id,
        title: ((!!item.number && "№ " + item.number) || "№") + ((!!item.date_number && " от " + moment(item.date_number).format("DD.MM.YYYY")) || ""),
      };

      options.push(option);
    });

    return options;
  };

  return (
    !!object &&
    !!contract &&
    !!listStages && (
      <Modal handleClose={() => props.handleClose()}>
        {!isDelete && (
          <form className="Form" onSubmit={(event) => changeEdit(event)}>
            <div className="Form__title">
              {!!object.number && "№ " + object.number} {!!object.date_number && " от " + moment(object.date_number).format("DD.MM.YYYY")}
              <div className="Text__signature">Платёж</div>
            </div>

            <div className="Form__field">
              <Input reference={inputDateReceipt}
                     type="date"
                     isFixed={true} label="Дата получения документа"
                     value={
                       !!object.date_receipt
                         ? moment(object.date_receipt).format("YYYY-MM-DD")
                         : null
                     }/>
            </div>

            <div className="Form__field">
              <Input reference={inputDate}
                     type="date"
                     isFixed={true} label="Дата"
                     value={
                       !!object.date_number
                         ? moment(object.date_number).format("YYYY-MM-DD")
                         : null
                     }/>
            </div>

            <div className="Form__field">
              <Input reference={inputNumber} label="Номер"
                     value={!!object.number && object.number}/>
            </div>

            <div className="Form__field">
              <Input
                reference={inputAmount}
                type="number"
                label="Сумма"
                value={!!object.amount && object.amount / 100}
              />
            </div>

            <div className="Form__field">
              <Select
                isFixed={true}
                reference={selectContractStageId}
                options={!!listStages ? getContractStageSelectOptions(listStages) : getContractStageSelectOptions([])}
                label="Этап"
                value={!!object.contract_stage_id ? String(object.contract_stage_id) : "null"}
              />
            </div>

            <div className="Form__field Form__field_last">
              <Select
                isFixed={true}
                reference={selectContractActId}
                options={getContractActSelectOptions([props.act])}
                label="Акт"
                value={props.act.id}
              />
            </div>


            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_blue">
                Применить
              </button>
            </div>

            <div className="Form__actions Form__actions_center">
              <div
                className="Action__link Action__link_red"
                onClick={() => setIsDelete(true)}
              >
                Удалить
              </div>
            </div>
          </form>
        )}

        {isDelete && (
          <form className="Form" onSubmit={(event) => changeDelete(event)}>
            <div className="Form__title">
              {!!object.number && "№ " + object.number} {!!object.date_number && " от " + moment(object.date_number).format("DD.MM.YYYY")}
              <div className="Text__signature">Удалить</div>
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_red">
                Удалить
              </button>
            </div>

            <div className="Form__actions Form__actions_center">
              <div className="Action__link" onClick={() => setIsDelete(false)}>
                Отмена
              </div>
            </div>
          </form>
        )}
      </Modal>
    )
  );
}

export default ModalActPaymentEdit;
