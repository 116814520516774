import React, { useEffect } from "react";
import { ROOT_URL } from "../../../../../config/url";
import { useList, useAction } from "@vokymlak/kabinet-ui";

function useProjectGeneralTasks(props) {
  const {
    list: [list, _count, setList],
    update: [isUpdateList, setIsUpdateList, updateList],
  } = useList({
    apiUrl: "/api/project/" + props.project.uuid + "/general-tasks",
    rootUrl: ROOT_URL,
  });

  const { setAction } = useAction("general-tasks", () => updateList());

  useEffect(() => {
    if (!!list) {
      const listUuid = list.map((i) => i.uuid);
      setAction([...listUuid, "general-tasks"]);
    } else {
      setAction("general-tasks");
    }
  }, [list]);

  return {
    object: props.project,

    list,
    setList,

    setIsUpdateList,
    isUpdateList,
  };
}

export default useProjectGeneralTasks;
