import React from "react";
import moment from "moment";
import ListTicketKpi from "./ListTicketKpi";
import ReactECharts from "echarts-for-react";
import {Marker} from "@vokymlak/kabinet-ui";

function KpiTicketAnalyticsView(props) {
  const {
    selectEmployee,
    date,
    changeSelectDay,
    selectDate,
    toDay,
    weekDayText,
    days,

    option,
    list,

    getDayCount,
    isDayMarker,
  } = props;

  const getAmount = (list) => {
    return list.map(c => c.time).reduce((a, b) => a + b, 0)
  }

  return (
    <>
      <div
        className="Page__title"
        style={{
          marginBottom: 72,
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        {!!days &&
          days.map((day, index) => (
            <div
              key={index}
              onClick={() => changeSelectDay(day.day)}
              style={{
                backgroundColor: "white",
                borderRadius: 7,
                padding: "3px 7px",
                minWidth: 56,
                margin: 2,
                cursor: "pointer",
                border:
                  Number(moment(selectDate, "YYYY-MM-DD").format("DD")) ===
                  Number(day.day) &&
                  Number(moment(selectDate, "YYYY-MM-DD").format("MM")) ===
                  Number(moment(date).format("MM")) &&
                  Number(moment(selectDate, "YYYY-MM-DD").format("YYYY")) ===
                  Number(moment(date).format("YYYY"))
                    ? Number(toDay.format("DD")) === Number(day.day) &&
                    Number(toDay.format("MM")) ===
                    Number(moment(date).format("MM")) &&
                    Number(toDay.format("YYYY")) ===
                    Number(moment(date).format("YYYY"))
                      ? "3px solid #f44336"
                      : "3px solid #202020"
                    : "3px solid white",
                position: "relative",
                opacity:
                  moment(
                    moment(date).format("YYYY") +
                    "-" +
                    moment(date).format("MM") +
                    "-" +
                    day.day,
                    "YYYY-MM-DD"
                  ).weekday() === 0 ||
                  moment(
                    moment(date).format("YYYY") +
                    "-" +
                    moment(date).format("MM") +
                    "-" +
                    day.day,
                    "YYYY-MM-DD"
                  ).weekday() === 6
                    ? 0.5
                    : 1,
              }}
            >
              <div
                style={{
                  color:
                    Number(toDay.format("DD")) === Number(day.day) &&
                    Number(toDay.format("MM")) ===
                    Number(moment(date).format("MM")) &&
                    Number(toDay.format("YYYY")) ===
                    Number(moment(date).format("YYYY"))
                      ? "#f44336"
                      : "#202020",
                  fontSize: 11,
                  textTransform: "capitalize",
                }}
              >
                {weekDayText(
                  moment(
                    moment(date).format("YYYY") +
                    "-" +
                    moment(date).format("MM") +
                    "-" +
                    day.day,
                    "YYYY-MM-DD"
                  ).weekday()
                )}
              </div>
              <div
                style={{
                  color:
                    Number(toDay.format("DD")) === Number(day.day) &&
                    Number(toDay.format("MM")) ===
                    Number(moment(date).format("MM")) &&
                    Number(toDay.format("YYYY")) ===
                    Number(moment(date).format("YYYY"))
                      ? "#f44336"
                      : "#202020",
                  fontSize: 17,
                  fontWeight: 700,
                }}
              >
                {day.day}
              </div>
              <div
                style={{
                  position: "absolute",
                  top: 3,
                  right: 3,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {isDayMarker(
                  list,
                  moment(props.date).format("YYYY"),
                  moment(props.date).format("MM"),
                  day.day
                ) && (
                  <Marker
                    size={6}
                    style={{
                      marginLeft: 2,
                    }}
                    color={"#0064ff"}
                  />
                )}
              </div>
            </div>
          ))}
      </div>

      <div className="Page__content">
        <ReactECharts
          option={option(
            list,
            moment(props.date).format("YYYY"),
            moment(props.date).format("MM"),
            days
          )}
          style={{height: "192px", width: "100%"}}
        />
      </div>

      <hr className={"Page__hr"}/>

      <div
        className="Page__title"
        style={{
          marginBottom: 12,
        }}
      >
        <div
          className="Title__text"
          style={{
            fontSize: 21,
            fontWeight: 900,
          }}
        >
          {moment(selectDate, "YYYY-MM-DD").format("DD.MM.YYYY")}

          {!!list && (
            <span style={{
              marginLeft: 12,
              fontSize: 21,
            }}>
                         {(Number(getAmount(list)
            ) / 100).toFixed(2)}
                            <span
                              style={{
                                marginLeft: 2,
                                fontSize: 12,
                                fontWeight: 400,
                                opacity: 0.75,
                              }}
                            >
                            ч.
                          </span>
                        </span>
          )}
        </div>
      </div>

      <div className="Page__content">
        <ListTicketKpi account={selectEmployee} date={selectDate}/>
      </div>
    </>
  );
}

export default KpiTicketAnalyticsView;
