import React, { useState, useRef } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../config/url";
import {
  getErrorMessageByKey,
  useNotification,
  useObject,
  Input,
  useList,
  Select,
  getAccountFio,
  jsonFormatText,
  isValidateNullText,
  Modal,
} from "@vokymlak/kabinet-ui";

function ModalCompanyEdit(props) {
  const {
    object: [object],
  } = useObject({
    apiUrl: "/api/company/" + props.company.uuid,
    rootUrl: ROOT_URL,
    callbackError: () => {
      props.handleClose();
    },
  });

  const {
    list: [list],
  } = useList({ apiUrl: "/api/clients/list", rootUrl: ROOT_URL });

  const [isArchive, setIsArchive] = useState(false);

  const inputShortName = useRef(null);
  const inputFullName = useRef(null);
  const inputOgrn = useRef(null);
  const inputInn = useRef(null);
  const inputKpp = useRef(null);
  const inputLegalAddress = useRef(null);
  const inputEmail = useRef(null);
  const inputPhone = useRef(null);
  const selectClientId = useRef(null);

  const { notification } = useNotification();

  const changeEdit = (event) => {
    event.preventDefault();

    const shortName = jsonFormatText(inputShortName.current.value);
    const fullName = jsonFormatText(inputFullName.current.value);
    const ogrn = jsonFormatText(inputOgrn.current.value);
    const inn = jsonFormatText(inputInn.current.value);
    const kpp = jsonFormatText(inputKpp.current.value);
    const legalAddress = jsonFormatText(inputLegalAddress.current.value);
    const email = jsonFormatText(inputEmail.current.value);
    const phone = jsonFormatText(inputPhone.current.value);
    // const clientId = selectClientId.current.value;

    if (isValidateNullText(shortName)) {
      notification("Заполните все обязательные поля", "red");
      return;
    }

    const jsonData = JSON.stringify({
      short_name: shortName,
      full_name: fullName,
      ogrn: ogrn,
      inn: inn,
      kpp: kpp,
      legal_address: legalAddress,
      email: email,
      phone: phone,
      // client_id:
      //   clientId.length > 0
      //     ? clientId === "null"
      //       ? null
      //       : Number(clientId)
      //     : null,
    });

    axios
      .put("/api/company/" + props.company.uuid + "/edit", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Данные сохранены", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const changeArchive = (event) => {
    event.preventDefault();

    axios
      .get("/api/company/" + props.company.uuid + "/archive", {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Компания в архиве", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const getSelectOptions = (list) => {
    const options = [
      {
        value: "null",
        title: "Не выбрано",
      },
    ];

    list.map((item) => {
      const option = {
        value: item.account.id,
        title: getAccountFio(item.account),
      };

      options.push(option);
    });

    return options;
  };

  return (
    !!object &&
    !!list && (
      <Modal handleClose={() => props.handleClose()}>
        {!isArchive && (
          <form className="Form" onSubmit={(event) => changeEdit(event)}>
            <div className="Form__title">
              {object.short_name}
              <div className="Text__signature">Компания</div>
            </div>

            <div className="Form__field">
              <Input
                reference={inputShortName}
                label="Краткое наименование *"
                value={object.short_name}
              />
            </div>

            <div className="Form__field">
              <Input
                reference={inputFullName}
                label="Полное наименование"
                value={object.full_name}
              />
            </div>

            <div className="Form__field">
              <Input reference={inputOgrn} label="ОГРН" value={object.ogrn} />
            </div>

            <div className="Form__field">
              <Input reference={inputInn} label="ИНН" value={object.inn} />
            </div>

            <div className="Form__field">
              <Input reference={inputKpp} label="КПП" value={object.kpp} />
            </div>

            <div className="Form__field">
              <Input
                reference={inputLegalAddress}
                label="Юридический адрес"
                value={object.legal_address}
              />
            </div>

            <div className="Form__field">
              <Input
                reference={inputEmail}
                label="Email"
                value={object.email}
              />
            </div>

            <div className="Form__field">
              <Input
                reference={inputPhone}
                label="Телефон (7...)"
                value={object.phone}
              />
            </div>

            {/*<div className="Form__field Form__field_last">*/}
            {/*  <Select*/}
            {/*    isFixed={true}*/}
            {/*    reference={selectClientId}*/}
            {/*    options={!!list ? getSelectOptions(list) : getSelectOptions([])}*/}
            {/*    label="Клиент"*/}
            {/*    value={!!object.client_id ? String(object.client_id) : "null"}*/}
            {/*  />*/}
            {/*</div>*/}

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_blue">
                Применить
              </button>
            </div>

            {/*<div className="Form__actions">*/}
            {/*  <div className="Action__link" onClick={() => setIsArchive(true)}>*/}
            {/*    В архив*/}
            {/*  </div>*/}
            {/*  <div className="Action__link" onClick={() => props.handleClose()}>*/}
            {/*    Отмена*/}
            {/*  </div>*/}
            {/*</div>*/}
          </form>
        )}
        {isArchive && (
          <form className="Form" onSubmit={(event) => changeArchive(event)}>
            <div className="Form__title">
              {object.short_name}
              <div className="Text__signature">В архив</div>
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_red">
                Переместить
              </button>
            </div>

            <div className="Form__actions Form__actions_center">
              <div className="Action__link" onClick={() => setIsArchive(false)}>
                Отмена
              </div>
            </div>
          </form>
        )}
      </Modal>
    )
  );
}

export default ModalCompanyEdit;
