import React, { useState } from "react";
import { ROOT_URL } from "../../../config/url";
import {
  useAction,
  useNotification,
  getErrorMessageByKey,
  useList,
  useUnroll,
} from "@vokymlak/kabinet-ui";
import axios from "axios";

function useSettingLogics() {
  const {
    list: [list, _count, setList],
    update: [isUpdateList, setIsUpdateList],
  } = useList({
    apiUrl: "/api/logics",
    rootUrl: ROOT_URL,
  });

  const { unrolls, isUnroll, changeUnroll, changeAllUnroll } = useUnroll();

  const [modalObjectItemAdd, setModalObjectItemAdd] = useState(null);
  const [modalObjectItem2Add, setModalObjectItem2Add] = useState(null);
  const [modalObjectItem3Add, setModalObjectItem3Add] = useState(null);
  const [modalObjectItem4Add, setModalObjectItem4Add] = useState(null);

  useAction("isUpdate", () => setIsUpdateList(!isUpdateList));

  const { notification } = useNotification();

  const deleteItem = (itemUuid) => {
    axios
      .delete("/api/logic-contract-status/" + itemUuid + "/delete", {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        setIsUpdateList(!isUpdateList);
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const deleteItem2 = (itemUuid) => {
    axios
      .delete("/api/logic-purchase-base/" + itemUuid + "/delete", {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        setIsUpdateList(!isUpdateList);
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const deleteItem3 = (itemUuid) => {
    axios
      .delete("/api/logic-purchase-way/" + itemUuid + "/delete", {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        setIsUpdateList(!isUpdateList);
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const deleteItem4 = (itemUuid) => {
    axios
      .delete("/api/logic-control-point/" + itemUuid + "/delete", {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        setIsUpdateList(!isUpdateList);
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return {
    list,
    setList,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    setIsUpdateList,
    isUpdateList,

    modalObjectItemAdd,
    setModalObjectItemAdd,
    modalObjectItem2Add,
    setModalObjectItem2Add,
    modalObjectItem3Add,
    setModalObjectItem3Add,
    modalObjectItem4Add,
    setModalObjectItem4Add,

    deleteItem,
    deleteItem2,
    deleteItem3,
    deleteItem4,
  };
}

export default useSettingLogics;
