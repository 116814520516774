import React, {useEffect, useRef, useState} from "react";
import { ROOT_URL } from "../../../config/url";
import { useAction, useUnroll, useList } from "@vokymlak/kabinet-ui";

function useFnsCertificates() {
  const {
    list: [list, count, setList],
    update: [isUpdateList, setIsUpdateList, updateList],
    search: [searchQuery, setSearchQuery],
  } = useList({
    apiUrl: "/api/fns-certificates",
    rootUrl: ROOT_URL,
    isSearch: true,
  });

  const { unrolls, isUnroll, changeUnroll, changeAllUnroll } = useUnroll();

  const inputSearchText = useRef(null);

  const { setAction } = useAction("fns-certificates", () => updateList());

  useEffect(() => {
    if (!!list) {
      const listUuid = list.map((i) => i.uuid);
      setAction([...listUuid, "fns-certificates"]);
    } else {
      setAction("fns-certificates");
    }
  }, [list]);

  function onChangeSearchText() {
    setSearchQuery(inputSearchText.current.value);
  }

  const [modalCompanyFnsCertificateDownload, setModalCompanyFnsCertificateDownload] =
    useState(null);

  return {
    list,
    setList,
    count,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    inputSearchText,
    onChangeSearchText,
    searchQuery,

    setIsUpdateList,
    isUpdateList,

    modalCompanyFnsCertificateDownload,
    setModalCompanyFnsCertificateDownload,
  };
}

export default useFnsCertificates;
