import React, { useEffect, useState } from "react";

import {Tooltip, Loading, Icon} from "@vokymlak/kabinet-ui";
import ModalLawContractStatusAdd from "./ModalLawContractStatusAdd";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";


export const StrictModeDroppable = ({ children, ...props }) => {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  if (!enabled) {
    return null;
  }

  return <Droppable {...props}>{children}</Droppable>;
};

function LawContractStatusesList(props) {
  const {
    list,
    setList,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    setIsUpdateList,
    isUpdateList,

    modalObjectItemAdd,
    setModalObjectItemAdd,

    deleteLawContractStatus,

    onDragEnd,
    onDragStart,
    isDrag,
  } = props;

  return (
    <ScrollBlock
      style={{ width: '100%', height: '100%' }}>
    <div className="Page">
      <div className="Page__title">
        <div
          className="Title__text"
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: 27,
            fontWeight: 700,
          }}
        >
          Условия статусов
        </div>

        <div className="Title__settings">
          {!!list && (
            <div
              style={{
                margin: "0 12px",
              }}
              className="Button__text"
              onClick={() => changeAllUnroll(list, "tag")}
            >
              {list.length === unrolls.length ? "Свернуть" : "Развернуть"}
            </div>
          )}

          <div
            style={{
              margin: "0 12px",
            }}
            className="Button__text"
            onClick={() => (setList(null), setIsUpdateList(!isUpdateList))}
          >
            Обновить
          </div>
        </div>
      </div>

      <div className="Page__content">
        <ul className="List">
          {!list && (
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                flexWrap: "nowrap",
                alignItems: "center",
                justifyContent: "center",
                padding: "100px 0",
              }}
            >
              <Loading />
            </div>
          )}

          {!!list &&
            list.map((item) => (
              <React.Fragment key={"item_" + item.tag}>
                <li
                  className={
                    isUnroll(item.tag) ? "List__item active" : "List__item"
                  }
                  onClick={() => changeUnroll(item.tag)}
                >
                  <div className="Item__title">
                    <div className="Element__title">{item.name}</div>
                  </div>
                  <div className="Item__settings">
                    <div className="Element__datetime"></div>
                  </div>
                </li>

                {isUnroll(item.tag) && (
                  <li className="List__content">
                    <div>
                      <table className="Table">
                        {/*<tbody>*/}

                        <DragDropContext
                          onDragEnd={onDragEnd}
                          onDragStart={onDragStart}
                        >
                          <StrictModeDroppable droppableId={String(item.tag)}>
                            {(provided) => (
                              <tbody
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                              >
                                {!!item.contract_statuses &&
                                  item.contract_statuses.map(
                                    (lawContractStatus, index) => (
                                      <Draggable
                                        draggableId={String(
                                          lawContractStatus.uuid
                                        )}
                                        index={index}
                                        key={
                                          "draggable_" + lawContractStatus.uuid
                                        }
                                      >
                                        {(provided) => (
                                          <tr
                                            className="Table__tr"
                                            ref={provided.innerRef}
                                            {...provided.dragHandleProps}
                                            {...provided.draggableProps}
                                          >
                                            {/*<tr className="Table__tr" key={index}>*/}
                                            <td className="Table__td">
                                              <div className="Td__title">
                                                {index + 1}
                                                {". "}
                                                {
                                                  lawContractStatus
                                                    .contract_status.name
                                                }
                                              </div>
                                            </td>
                                            <td className="Table__td Table__td_child">
                                              {!isDrag && (
                                                <div
                                                  className="Button__icon Button__icon_32"
                                                  onClick={() =>
                                                    deleteLawContractStatus(
                                                      lawContractStatus.uuid
                                                    )
                                                  }
                                                >
                                                  <Icon
                                                    name={"custom-delete"}
                                                    size={32}
                                                    color={""}
                                                  />
                                                </div>
                                              )}
                                            </td>
                                            {/*</tr>*/}
                                          </tr>
                                        )}
                                      </Draggable>
                                    )
                                  )}
                                {provided.placeholder}
                              </tbody>
                            )}
                          </StrictModeDroppable>
                        </DragDropContext>
                        {/*</tbody>*/}
                      </table>
                    </div>

                    <div className="Content__footer">
                      <div className="Footer__title">
                        <div
                          className="Button__text"
                          onClick={() => setModalObjectItemAdd(item)}
                        >
                          Добавить статус
                        </div>
                      </div>
                      <div className="Footer__settings"></div>
                    </div>
                  </li>
                )}
              </React.Fragment>
            ))}
        </ul>
      </div>

      {!!modalObjectItemAdd && (
        <ModalLawContractStatusAdd
          law={modalObjectItemAdd}
          successfulRequest={() => setIsUpdateList(!isUpdateList)}
          handleClose={() => setModalObjectItemAdd(null)}
        />
      )}
    </div>
    </ScrollBlock>
  );
}

export default LawContractStatusesList;
