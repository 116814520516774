import React, { useEffect, useState } from "react";
import { ROOT_URL } from "../../../../../config/url";
import {useList, useAction, useUnroll} from "@vokymlak/kabinet-ui";

function useContractActs(props) {
  const {
    list: [list, count, setList],
    update: [isUpdateList, setIsUpdateList, updateList],
  } = useList({
    apiUrl: "/api/client/contract/" + props.contract.uuid + "/acts",
    rootUrl: ROOT_URL,
  });

  const { unrolls, isUnroll, changeUnroll, changeAllUnroll } = useUnroll();

  const { setAction } = useAction(props.contract.uuid, () => updateList());

  useEffect(() => {
    if (!!list) {
      const listUuid = list.map((i) => i.uuid);
      setAction([...listUuid, props.contract.uuid]);
    } else {
      setAction(props.contract.uuid);
    }
  }, [list]);

  return {
    object: props.contract,

    list,
    setList,
    count,

    setIsUpdateList,
    isUpdateList,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,
  };
}

export default useContractActs;
