import React, {useState} from "react";
import {ButtonIcon, Icon, Tooltip} from "@vokymlak/kabinet-ui";
import ModalProjectSmpEdit from "./ModalProjectSmpEdit";
import ModalProjectSmpItemAdd from "./ModalProjectSmpItemAdd";
import ModalProjectSmpItemEdit from "./ModalProjectSmpItemEdit";

function ProjectSmpView(props) {
  const {
    object,
    setIsUpdateObject,
    isUpdateObject,
    list,
    isUpdateList,
    setIsUpdateList,
    getAllAmount,
    objectSmp,
    isUpdateSmp,
    setIsUpdateSmp
  } = props;

  const [isModalProjectSmpEdit, setIsModalProjectSmpEdit] = useState(false);
  const [isModalProjectSmpItemAdd, setIsModalProjectSmpItemAdd] =
    useState(false);
  const [modalProjectSmpItemEdit, setModalProjectSmpItemEdit] = useState(false);

  return (
    <>
      {!!object && (
        <>
          <div className="Page__title">
            <div className="Title__text">
              <div
                className="Title__text"
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  fontSize: 19,
                  fontWeight: 700,
                  marginRight: 6,
                }}
              >
                <div>Расчет СМП</div>
              </div>
            </div>

            <div className="Title__settings">
              <ButtonIcon
                tooltipText={"Обновить"}
                iconName={"custom-change-circle"}
                iconColor={"#20202075"}
                size={28}
                style={{marginLeft: 8}}
                onClick={() => (
                  setIsUpdateObject(!isUpdateObject),
                    setIsUpdateList(!isUpdateList),
                    setIsUpdateSmp(!isUpdateSmp)
                )}
              />
            </div>
          </div>

          <div
            className="Page__content"
            style={{
              marginBottom: 24,
            }}
          >
            <table
              className="Table"
              style={{
                width: 560,
              }}
            >
              <thead className="Thead">
              <tr className="Table__tr Table__tr_padding Table__tr_border">
                <th
                  className="Table__th Table__th_border"
                  style={{
                    backgroundColor: "#dfdfdf",
                  }}
                >
                  <div
                    className="Th__title"
                    style={{
                      textAlign: "left",
                      fontWeight: 700,
                    }}
                  >
                    СГОЗ
                  </div>
                </th>
                <th
                  className="Table__th Table__th_border"
                  style={{
                    backgroundColor: "#dfdfdf",
                    width: 120,
                  }}
                >
                  <div
                    className="Th__title"
                    style={{
                      fontWeight: 700,
                    }}
                  >
                    {(!!objectSmp && !!objectSmp.sgoz_amount &&
                        new Intl.NumberFormat("ru-RU").format(
                          Number(objectSmp.sgoz_amount / 100).toFixed(2)
                        )) ||
                      "-"}
                  </div>
                </th>
                <th
                  className="Table__th Table__th_border"
                  style={{
                    backgroundColor: "#dfdfdf",
                  }}
                >
                  <Tooltip text={"Сумма&nbsp;СГОЗ"} style={{marginLeft: 0}}>
                    <div
                      style={{
                        width: 17,
                        backgroundColor: "#202020",
                        color: "white",
                        borderRadius: "50%",
                        display: "inline-block",
                        padding: "2px 0",
                        cursor: "default",
                        fontSize: 11,
                        fontWeight: 700,
                        textAlign: "center",
                      }}
                    >
                      ?
                    </div>
                  </Tooltip>
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                className={
                  "Table__tr Table__tr_padding Table__tr_border Table__tr_hover "
                }
              >
                <td
                  className="Table__td Table__td_border"
                  style={{
                    backgroundColor: "#dfdfdf50",
                  }}
                >
                  <div className="Td__text ">Переходящие</div>
                </td>
                <td
                  className="Table__td Table__td_border"
                  style={{
                    backgroundColor: "#dfdfdf50",
                    width: 120,
                  }}
                >
                  <div className="Td__text Td__text_center">
                    {(!!object.amount_transients &&
                        new Intl.NumberFormat("ru-RU").format(
                          Number(object.amount_transients / 100).toFixed(2)
                        )) ||
                      "-"}
                  </div>
                </td>
                <td
                  className="Table__td Table__td_border"
                  style={{
                    backgroundColor: "#dfdfdf50",
                    width: 36,
                  }}
                >
                  <div
                    className="Td__text Td__text_center"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "nowrap",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {/*<div*/}
                    {/*  className="Button__icon Button__icon_36"*/}
                    {/*  // onClick={() => setModalProjectTaskEdit(item)}*/}
                    {/*>*/}
                    {/*  <Icon name={""} size={36} color={""} />*/}
                    {/*</div>*/}
                  </div>
                </td>
              </tr>

              {/*<tr*/}
              {/*  className={*/}
              {/*    "Table__tr Table__tr_padding Table__tr_border Table__tr_hover "*/}
              {/*  }*/}
              {/*>*/}
              {/*  <td*/}
              {/*    className="Table__td Table__td_border"*/}
              {/*    style={{*/}
              {/*      backgroundColor: "#dfdfdf50",*/}
              {/*    }}*/}
              {/*  >*/}
              {/*    <div className="Td__text ">*/}
              {/*      Переходящие (За исключением пп 1352)*/}
              {/*    </div>*/}
              {/*  </td>*/}
              {/*  <td*/}
              {/*    className="Table__td Table__td_border"*/}
              {/*    style={{*/}
              {/*      backgroundColor: "#dfdfdf50",*/}
              {/*      width: 120*/}
              {/*    }}*/}
              {/*  >*/}
              {/*    <div className="Td__text Td__text_center">*/}
              {/*      {!!object.amount_transients && new Intl.NumberFormat("ru-RU").format(*/}
              {/*        Number((!!object.amount_pp1352*/}
              {/*            ? object.amount_transients - object.amount_pp1352*/}
              {/*            : object.amount_transients)  / 100).toFixed(2)*/}
              {/*      ) || "-"}*/}
              {/*    </div>*/}
              {/*  </td>*/}
              {/*  <td*/}
              {/*    className="Table__td Table__td_border"*/}
              {/*    style={{*/}
              {/*      backgroundColor: "#dfdfdf50",*/}
              {/*      width: 36*/}
              {/*    }}*/}
              {/*  >*/}
              {/*    <div*/}
              {/*      className="Td__text Td__text_center"*/}
              {/*      style={{*/}
              {/*        display: "flex",*/}
              {/*        flexDirection: "row",*/}
              {/*        flexWrap: "nowrap",*/}
              {/*        alignItems: "center",*/}
              {/*        justifyContent: "center",*/}

              {/*      }}*/}
              {/*    >*/}
              {/*      <div*/}
              {/*        className="Button__icon Button__icon_36"*/}
              {/*        // onClick={() => setModalProjectTaskEdit(item)}*/}
              {/*      >*/}
              {/*        <Icon name={""} size={36} color={""} />*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </td>*/}
              {/*</tr>*/}

              <tr
                className={
                  "Table__tr Table__tr_padding Table__tr_border Table__tr_hover "
                }
              >
                <td
                  className="Table__td Table__td_border"
                  style={{
                    backgroundColor: "#dfdfdf50",
                  }}
                >
                  <div className="Td__text ">ПП 1352</div>
                </td>
                <td
                  className="Table__td Table__td_border"
                  style={{
                    backgroundColor: "#dfdfdf50",
                    width: 120,
                  }}
                >
                  <div className="Td__text Td__text_center">
                    {(!!object.amount_pp1352 &&
                        new Intl.NumberFormat("ru-RU").format(
                          Number(object.amount_pp1352 / 100).toFixed(2)
                        )) ||
                      "-"}
                  </div>
                </td>
                <td
                  className="Table__td Table__td_border"
                  style={{
                    backgroundColor: "#dfdfdf50",
                    width: 36,
                  }}
                >
                  <div
                    className="Td__text Td__text_center"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "nowrap",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {/*<div*/}
                    {/*  className="Button__icon Button__icon_36"*/}
                    {/*  // onClick={() => setModalProjectTaskEdit(item)}*/}
                    {/*>*/}
                    {/*  <Icon name={""} size={36} color={""} />*/}
                    {/*</div>*/}
                  </div>
                </td>
              </tr>

              {/*<tr*/}
              {/*  className={*/}
              {/*    "Table__tr Table__tr_padding Table__tr_border Table__tr_hover "*/}
              {/*  }*/}
              {/*>*/}
              {/*  <td*/}
              {/*    className="Table__td Table__td_border"*/}
              {/*    style={{*/}
              {/*      backgroundColor: "#dfdfdf50",*/}
              {/*    }}*/}
              {/*  >*/}
              {/*    <div className="Td__text ">*/}
              {/*      Субъекты естественных монополий*/}
              {/*    </div>*/}
              {/*  </td>*/}
              {/*  <td*/}
              {/*    className="Table__td Table__td_border"*/}
              {/*    style={{*/}
              {/*      backgroundColor: "#dfdfdf50",*/}
              {/*      width: 120*/}
              {/*    }}*/}
              {/*  >*/}
              {/*    <div className="Td__text Td__text_center">*/}
              {/*      {!!object.amount_monopolies && new Intl.NumberFormat("ru-RU").format(*/}
              {/*        Number(object.amount_monopolies / 100).toFixed(2)*/}
              {/*      ) || "-"}*/}
              {/*    </div>*/}
              {/*  </td>*/}
              {/*  <td*/}
              {/*    className="Table__td Table__td_border"*/}
              {/*    style={{*/}
              {/*      backgroundColor: "#dfdfdf50",*/}
              {/*      width: 36*/}
              {/*    }}*/}
              {/*  >*/}
              {/*    <div*/}
              {/*      className="Td__text Td__text_center"*/}
              {/*      style={{*/}
              {/*        display: "flex",*/}
              {/*        flexDirection: "row",*/}
              {/*        flexWrap: "nowrap",*/}
              {/*        alignItems: "center",*/}
              {/*        justifyContent: "center",*/}

              {/*      }}*/}
              {/*    >*/}
              {/*      <div*/}
              {/*        className="Button__icon Button__icon_36"*/}
              {/*        // onClick={() => setModalProjectTaskEdit(item)}*/}
              {/*      >*/}
              {/*        <Icon name={""} size={36} color={""} />*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </td>*/}
              {/*</tr>*/}

              <tr
                className={
                  "Table__tr Table__tr_padding Table__tr_border Table__tr_hover "
                }
              >
                <td
                  className="Table__td Table__td_border"
                  style={{
                    backgroundColor: "#dfdfdf",
                  }}
                  colSpan={3}
                >
                  <div
                    className="Td__text Td__text_center"
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => setIsModalProjectSmpEdit(true)}
                  >
                    Редактировать
                  </div>
                </td>
              </tr>
              </tbody>
            </table>

            <br/>

            <table
              className="Table"
              style={{
                width: 560,
              }}
            >
              <tbody>
              {!!list &&
                list.map((item) => (
                  <tr
                    className={
                      "Table__tr Table__tr_padding Table__tr_border Table__tr_hover "
                    }
                    key={item.uuid}
                  >
                    <td
                      className="Table__td Table__td_border"
                      style={{
                        backgroundColor: "#dfdfdf50",
                      }}
                    >
                      <div
                        className="Td__text "
                        style={{
                          fontStyle: "italic",
                        }}
                      >
                        {(!!item.title && item.title) || "-"}
                      </div>
                    </td>
                    <td
                      className="Table__td Table__td_border"
                      style={{
                        backgroundColor: "#dfdfdf50",
                        width: 120,
                      }}
                    >
                      <div className="Td__text Td__text_center">
                        {(!!item.amount &&
                            new Intl.NumberFormat("ru-RU").format(
                              Number(item.amount / 100).toFixed(2)
                            )) ||
                          "-"}
                      </div>
                    </td>
                    <td
                      className="Table__td Table__td_border"
                      style={{
                        backgroundColor: "#dfdfdf50",
                        width: 36,
                      }}
                    >
                      <div
                        className="Td__text Td__text_center"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "nowrap",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          className="Button__icon Button__icon_36"
                          onClick={() => setModalProjectSmpItemEdit(item)}
                        >
                          <Icon name={"custom-edit"} size={36} color={""}/>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}

              <tr
                className={
                  "Table__tr Table__tr_padding Table__tr_border Table__tr_hover "
                }
              >
                <td
                  className="Table__td Table__td_border"
                  style={{
                    backgroundColor: "#bbdefb",
                  }}
                  colSpan={3}
                >
                  <div
                    className="Td__text Td__text_center"
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => setIsModalProjectSmpItemAdd(true)}
                  >
                    Добавить расход
                  </div>
                </td>
              </tr>
              </tbody>
            </table>

            <br/>

            <table
              className="Table"
              style={{
                width: 560,
              }}
            >
              <tbody>
              {object.law.tag === "44" && (
                <tr
                  className={
                    "Table__tr Table__tr_padding Table__tr_border Table__tr_hover "
                  }
                >
                  <td
                    className="Table__td Table__td_border"
                    style={{
                      backgroundColor: "#dfdfdf50",
                    }}
                  >
                    <div className="Td__text ">Сумма СМП</div>
                  </td>
                  <td
                    className="Table__td Table__td_border"
                    style={{
                      backgroundColor: "#dfdfdf50",
                      width: 120,
                    }}
                  >
                    <div className="Td__text Td__text_center">
                      {(!!objectSmp &&
                          !!objectSmp.smp_starting_price_status &&
                          new Intl.NumberFormat("ru-RU").format(
                            Number(
                              objectSmp.smp_starting_price_status / 100
                            ).toFixed(2)
                          )) ||
                        "-"}
                    </div>
                  </td>
                  <td
                    className="Table__td Table__td_border"
                    style={{
                      backgroundColor: "#dfdfdf50",
                      width: 36,
                    }}
                  >
                    <div
                      className="Td__text Td__text_center"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "nowrap",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Tooltip
                        text={
                          'Суммируется&nbsp;по&nbsp;"Цена&nbsp;договора", при&nbsp;отсутствии&nbsp;используется&nbsp;"Начальная&nbsp;цена" (по&nbsp;СМП)'
                        }
                        style={{marginLeft: 0}}
                      >
                        <div
                          style={{
                            width: 17,
                            backgroundColor: "#202020",
                            color: "white",
                            borderRadius: "50%",
                            display: "inline-block",
                            padding: "2px 0",
                            cursor: "default",
                            fontSize: 11,
                            fontWeight: 700,
                            textAlign: "center",
                          }}
                        >
                          ?
                        </div>
                      </Tooltip>
                      {/*<div*/}
                      {/*  className="Button__icon Button__icon_36"*/}
                      {/*  // onClick={() => setModalProjectTaskEdit(item)}*/}
                      {/*>*/}
                      {/*  <Icon name={""} size={36} color={""} />*/}
                      {/*</div>*/}
                    </div>
                  </td>
                </tr>
              )}

              {object.law.tag === "223" && (
                <tr
                  className={
                    "Table__tr Table__tr_padding Table__tr_border Table__tr_hover "
                  }
                >
                  <td
                    className="Table__td Table__td_border"
                    style={{
                      backgroundColor: "#dfdfdf50",
                    }}
                  >
                    <div className="Td__text ">Сумма СМП</div>
                  </td>
                  <td
                    className="Table__td Table__td_border"
                    style={{
                      backgroundColor: "#dfdfdf50",
                      width: 120,
                    }}
                  >
                    <div className="Td__text Td__text_center">
                      {(!!objectSmp &&
                          !!objectSmp.smp_starting_price_status &&
                          new Intl.NumberFormat("ru-RU").format(
                            Number(
                              objectSmp.smp_starting_price_status / 100
                            ).toFixed(2)
                          )) ||
                        "-"}
                    </div>
                  </td>
                  <td
                    className="Table__td Table__td_border"
                    style={{
                      backgroundColor: "#dfdfdf50",
                      width: 36,
                    }}
                  >
                    <div
                      className="Td__text Td__text_center"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "nowrap",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Tooltip
                        text={
                          'Суммируется&nbsp;по&nbsp;"Цена&nbsp;договора", при&nbsp;отсутствии&nbsp;используется&nbsp;"Начальная&nbsp;цена" (по&nbsp;СМП)'
                        }
                        style={{marginLeft: 0}}
                      >
                        <div
                          style={{
                            width: 17,
                            backgroundColor: "#202020",
                            color: "white",
                            borderRadius: "50%",
                            display: "inline-block",
                            padding: "2px 0",
                            cursor: "default",
                            fontSize: 11,
                            fontWeight: 700,
                            textAlign: "center",
                          }}
                        >
                          ?
                        </div>
                      </Tooltip>
                      {/*<div*/}
                      {/*  className="Button__icon Button__icon_36"*/}
                      {/*  // onClick={() => setModalProjectTaskEdit(item)}*/}
                      {/*>*/}
                      {/*  <Icon name={""} size={36} color={""} />*/}
                      {/*</div>*/}
                    </div>
                  </td>
                </tr>
              )}

              <tr
                className={
                  "Table__tr Table__tr_padding Table__tr_border Table__tr_hover "
                }
              >
                <td
                  className="Table__td Table__td_border"
                  style={{
                    backgroundColor: "#dfdfdf50",
                  }}
                >
                  <div className="Td__text ">СМП 25%</div>
                </td>
                <td
                  className="Table__td Table__td_border"
                  style={{
                    backgroundColor: "#dfdfdf50",
                    width: 120,
                  }}
                >
                  <div className="Td__text Td__text_center">
                    {(!!objectSmp && !!objectSmp.sgoz_amount &&
                        new Intl.NumberFormat("ru-RU").format(
                          Number(
                            ((objectSmp.sgoz_amount -
                                (!!object.amount_transients
                                  ? object.amount_transients
                                  : 0) -
                                (!!object.amount_pp1352
                                  ? object.amount_pp1352
                                  : 0) -
                                getAllAmount(list)) /
                              100) *
                            0.25
                          ).toFixed(2)
                        )) ||
                      "-"}
                  </div>
                </td>
                <td
                  className="Table__td Table__td_border"
                  style={{
                    backgroundColor: "#dfdfdf50",
                    width: 36,
                  }}
                >
                  <div
                    className="Td__text Td__text_center"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "nowrap",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Tooltip
                      text={
                        '25%&nbsp;от&nbsp;СГОЗ&nbsp;за&nbsp;вычетом "Переходящие"&nbsp;и&nbsp;"ПП&nbsp;1352"&nbsp;и&nbsp;расходов'
                      }
                      style={{marginLeft: 0}}
                    >
                      <div
                        style={{
                          width: 17,
                          backgroundColor: "#202020",
                          color: "white",
                          borderRadius: "50%",
                          display: "inline-block",
                          padding: "2px 0",
                          cursor: "default",
                          fontSize: 11,
                          fontWeight: 700,
                          textAlign: "center",
                        }}
                      >
                        ?
                      </div>
                    </Tooltip>
                    {/*<div*/}
                    {/*  className="Button__icon Button__icon_36"*/}
                    {/*  // onClick={() => setModalProjectTaskEdit(item)}*/}
                    {/*>*/}
                    {/*  <Icon name={""} size={36} color={""} />*/}
                    {/*</div>*/}
                  </div>
                </td>
              </tr>
              {object.law.tag === "223" && (
                <tr
                  className={
                    "Table__tr Table__tr_padding Table__tr_border Table__tr_hover "
                  }
                >
                  <td
                    className="Table__td Table__td_border"
                    style={{
                      backgroundColor: "#dfdfdf50",
                    }}
                  >
                    <div className="Td__text ">СМП 20%</div>
                  </td>
                  <td
                    className="Table__td Table__td_border"
                    style={{
                      backgroundColor: "#dfdfdf50",
                      width: 120,
                    }}
                  >
                    <div className="Td__text Td__text_center">
                      {(!!objectSmp && !!objectSmp.sgoz_amount &&
                          new Intl.NumberFormat("ru-RU").format(
                            Number(
                              ((objectSmp.sgoz_amount -
                                  (!!object.amount_transients
                                    ? object.amount_transients
                                    : 0) -
                                  (!!object.amount_pp1352
                                    ? object.amount_pp1352
                                    : 0) -
                                  getAllAmount(list)) /
                                100) *
                              0.2
                            ).toFixed(2)
                          )) ||
                        "-"}
                    </div>
                  </td>
                  <td
                    className="Table__td Table__td_border"
                    style={{
                      backgroundColor: "#dfdfdf50",
                      width: 36,
                    }}
                  >
                    <div
                      className="Td__text Td__text_center"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "nowrap",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Tooltip
                        text={
                          '20%&nbsp;от&nbsp;СГОЗ&nbsp;за&nbsp;вычетом "Переходящие"&nbsp;и&nbsp;"ПП&nbsp;1352"&nbsp;и&nbsp;расходов'
                        }
                        style={{marginLeft: 0}}
                      >
                        <div
                          style={{
                            width: 17,
                            backgroundColor: "#202020",
                            color: "white",
                            borderRadius: "50%",
                            display: "inline-block",
                            padding: "2px 0",
                            cursor: "default",
                            fontSize: 11,
                            fontWeight: 700,
                            textAlign: "center",
                          }}
                        >
                          ?
                        </div>
                      </Tooltip>
                      {/*<div*/}
                      {/*  className="Button__icon Button__icon_36"*/}
                      {/*  // onClick={() => setModalProjectTaskEdit(item)}*/}
                      {/*>*/}
                      {/*  <Icon name={""} size={36} color={""} />*/}
                      {/*</div>*/}
                    </div>
                  </td>
                </tr>
              )}
              </tbody>
            </table>
          </div>
        </>
      )}

      {!!isModalProjectSmpEdit && (
        <ModalProjectSmpEdit
          project={object}
          successfulRequest={() => setIsUpdateObject(!isUpdateObject)}
          handleClose={() => setIsModalProjectSmpEdit(false)}
        />
      )}

      {!!isModalProjectSmpItemAdd && (
        <ModalProjectSmpItemAdd
          project={object}
          successfulRequest={() => setIsUpdateList(!isUpdateList)}
          handleClose={() => setIsModalProjectSmpItemAdd(false)}
        />
      )}

      {!!modalProjectSmpItemEdit && (
        <ModalProjectSmpItemEdit
          object={modalProjectSmpItemEdit}
          successfulRequest={() => setIsUpdateList(!isUpdateList)}
          handleClose={() => setModalProjectSmpItemEdit(null)}
        />
      )}
    </>
  );
}

export default ProjectSmpView;
