import React, {createRef, useEffect, useState} from 'react';
import axios from "axios";
import "../components/Auth.scss";
import {Link, useNavigate, useParams} from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import {ROOT_URL} from "../../../config/url";
import {InputText} from "../components/InputText/InputText";
import {z} from "zod";
import {toast} from "sonner";
import logotype from "../components/logotype.png";
import {ReactComponent as IconLock} from "../components/lock.svg";

const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY;

function isUUIDv4(s) {
  const pattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return pattern.test(s);
}

export const ResetPassword = () => {
  let {resetKey} = useParams();
  const navigate = useNavigate();

  if (!isUUIDv4(resetKey)) {
    navigate("/");
  }

  const [isResetSuccess, setIsResetSuccess] = useState(false);
  const [isReset, setIsReset] = useState(null);
  const [isRequest, setIsRequest] = useState(false);

  // Данные формы.
  const [formData, setFormData] = useState({
    password: null,
  });

  // Данные об ошибках полей формы.
  const [formErrors, setFormErrors] = useState({
    password: false,
  });

  useEffect(() => {
    axios.get(
      'api/auth/reset/'+resetKey,
      {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(() => {
        setIsRequest(false);
        setIsReset('SUCCESS');
      })
      .catch(error => {
        setIsRequest(false);
        console.error(error);
        setIsReset('ERROR');
      });
  }, [resetKey]);

  const [recaptchaError, setRecaptchaError] = useState(false);

  const recaptchaRef = createRef();

  const changeFormData = (event) => {
    let {name, value} = event.target;

    let newValue = value.trim()

    if (newValue === '') {
      newValue = null
    }

    setFormData(prevData => ({
      ...prevData,
      [name]: newValue
    }));

    setFormErrors(prevError => ({
      ...prevError,
      [name]: false
    }));
  };

  const handleReset = async () => {
    const FormDataSchema = z.object({
      password:
        z.string().min(6).max(32).
        regex(/^[a-zA-Z0-9!@#$%^&*\-_=+?<>,.:;|'"№(){}\[\]\\\/]+$/)
    });

    try {
      FormDataSchema.parse({...formData});
    } catch (error) {
      if (error instanceof z.ZodError) {
        // Обработка ошибок.
        error.issues.forEach(err => {
          err.path.forEach(name => {
            setFormErrors(prevError => ({
              ...prevError,
              [name]: true
            }));
          })
        })
      }

      toast.error(`Пожалуйста, укажите корректно новый пароль`);
      return;
    }

    if (recaptchaError) {
      await onRecaptchaResolved();
    } else {
      recaptchaRef.current.execute();
    }
  }

  async function onRecaptchaResolved() {
    const jsonData = JSON.stringify({
      new_password: formData.password,
    });

    setIsRequest(true);

    axios.post(
      'api/auth/reset/'+resetKey,
      jsonData,
      {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(() => {
        setIsRequest(false);
        setIsResetSuccess(true);
      })
      .catch(error => {
        setIsRequest(false);
        console.error(error);
        toast.error('Что то пошло не так, попробуйте сменить пароль чуть позже');
      });
  }

  return (
    <div className="Auth"
         onContextMenu={(event) => event.preventDefault()}
    >
      {!isResetSuccess && (
        <>
          {isReset === 'SUCCESS' && (
            <div className="Form show">
              <div className="Form__logotype">
                <img src={logotype} alt={"АБВЗАКУПКИ"} height={40}/>
                <div style={{
                  fontSize: 15,
                  fontWeight: 400,
                  marginBottom: 24
                }}>
                  Личный кабинет
                </div>
              </div>

              <div className="Form__title">
                Смена пароля
              </div>
              <div className="Form__field">
                <InputText
                  type={"text"}
                  isViewPassword={true}
                  label={"Новый пароль"}
                  name="password"
                  value={formData.password}
                  onChange={changeFormData}
                  isError={formErrors['password']}
                />
              </div>

              <div className="Form__field" style={{
                marginBottom: 16,
                textAlign: "center"
              }}>
            <span style={{fontSize: 13, fontWeight: 400, opacity: .75}}>
              Длинна пароля - от 6 до 32 символов, допустимы латинские буквы, цифры и специальные символы
            </span>
              </div>

              <div className="Form__actions Form__actions_center">
                <button
                  className="Action__button Action__button_all Action__button_login"
                  style={{
                    opacity: isRequest && 0.5,
                    cursor: isRequest && "default"
                  }}
                  onClick={isRequest ? () => null : () => handleReset()}
                >
                  Применить пароль
                </button>
              </div>

              <div className="Form__links">
                <Link className="Action__link Action__link_blue" to={"/login"}>
                  К входу
                </Link>
              </div>
            </div>
          )}

          {isReset === 'ERROR' && (
            <div>
              <div className="Form__links" style={{
                textAlign: "center"
              }}>
                <div style={{opacity: .33, fontSize: 24, marginBottom: 12}}>
                  Страница по данному адресу недоступна
                </div>
                <Link className="Action__link Action__link_blue" to={"/login"}>
                  К входу
                </Link>
              </div>
            </div>
          )}
        </>
      )}

      {isResetSuccess && (
        <div className="Form show" style={{
          backgroundColor: "inherit",
          boxShadow: "none",
          width: 426
        }}>
          <div className="Form__title" style={{
            textAlign: "center",
            opacity: .33
          }}>
            Смена пароля
          </div>

          <div className="Form__field" style={{
            display: "flex",
            justifyContent: "center"
          }}>
            <IconLock height={128} fill={"#0064ff"}/>
          </div>

          <div className="Form__field" style={{
            display: "flex",
            justifyContent: "center"
          }}>
            <div style={{
              textAlign: "center"
            }}>
              Ваш пароль успешно обновлен! Теперь вы можете использовать его для входа в сервис
            </div>
          </div>

          <div className="Form__links">
            <Link className="Action__link Action__link_blue" to={"/login"}>К входу</Link>
          </div>
        </div>
      )}

      <ReCAPTCHA
        ref={recaptchaRef}
        locale="ru"
        size="invisible"
        sitekey={RECAPTCHA_KEY}
        onChange={onRecaptchaResolved}
        onErrored={() => setRecaptchaError(true)}
      />
    </div>
  );
}
