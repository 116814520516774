import React, {useEffect, useRef} from "react";
import { ROOT_URL } from "../../../config/url";
import { useAction, useUnroll, useList } from "@vokymlak/kabinet-ui";

function useMyProjects() {
  const {
    list: [list, count, setList],
    update: [isUpdateList, setIsUpdateList, updateList],
  } = useList({
    apiUrl: "/api/client/projects/list",
    rootUrl: ROOT_URL,
  });

  const { setAction } = useAction([], () => updateList());

  useEffect(() => {
    if (!!list) {
      const listUuid = list.map((i) => i.uuid);
      setAction([...listUuid]);
    } else {
      setAction([]);
    }
  }, [list]);

  return {
    list,
    setList,
    count,

    setIsUpdateList,
    isUpdateList,
  };
}

export default useMyProjects;
