import React, { useState, useRef } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../../../config/url";
import moment from "moment";
import {
  Modal,
  Textarea,
  Input,
  getErrorMessageByKey,
  Icon,
  useNotification,
  useObject,
  jsonFormatText,
} from "@vokymlak/kabinet-ui";

function ModalProjectGeneralTaskEdit(props) {
  const [isPriority, setIsPriority] = useState(false);

  const {
    object: [object],
  } = useObject({
    apiUrl: "/api/general-task-project/" + props.object.uuid,
    rootUrl: ROOT_URL,
    callbackError: () => {
      props.handleClose();
    },
  });

  const inputNote = useRef(null);
  const inputDateControl = useRef(null);

  const [isDelete, setIsDelete] = useState(false);

  const { notification } = useNotification();

  const changeEdit = (event) => {
    event.preventDefault();

    const note = jsonFormatText(inputNote.current.value);
    const dateControl = inputDateControl.current.value;

    const jsonData = JSON.stringify({
      note: note,
      date_control:
        !!dateControl && dateControl.length > 0 ? dateControl : null,
      is_priority: isPriority,
    });

    axios
      .put(
        "/api/general-task-project/" + props.object.uuid + "/edit",
        jsonData,
        {
          withCredentials: false,
          baseURL: ROOT_URL,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("tokenSession"),
          },
        }
      )
      .then(() => {
        props.successfulRequest();
        notification("Данные сохранены", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const changeDelete = (event) => {
    event.preventDefault();

    axios
      .delete("/api/general-task-project/" + props.object.uuid + "/delete", {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Проект из общей задачи удалён", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    !!object && (
      <Modal handleClose={() => props.handleClose()}>
        {!isDelete && (
          <form className="Form" onSubmit={(event) => changeEdit(event)}>
            <div className="Form__title">
              {object.general_task.title}
              <div className="Text__signature">Общая задача</div>
            </div>

            <div className="Form__field">
              <Textarea
                reference={inputNote}
                label="Примечание"
                value={object.note}
              />
            </div>

            <div className="Form__field">
              <Input
                reference={inputDateControl}
                type="date"
                isFixed={true}
                label="Дата исполнения *"
                value={
                  !!object.date_control
                    ? moment(object.date_control).format("YYYY-MM-DD")
                    : null
                }
              />
            </div>

            <div className="Form__field Form__field_last">
              <div
                className="Button__swith"
                style={{
                  padding: "8px",
                  width: "100%",
                  border: isPriority
                    ? "2px solid #ffe082"
                    : "2px dashed #20202015",
                  backgroundColor: isPriority ? "#ffe082" : "#ffffff",
                  borderRadius: 6,
                  marginTop: 20,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  cursor: "pointer",
                }}
                onClick={() => setIsPriority(!isPriority)}
              >
                <div
                  className="Swith__text"
                  style={{
                    fontSize: 17,
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span style={{ marginRight: 8 }}>
                    <Icon
                      name={"custom-priority"}
                      size={22}
                      color={isPriority ? "#202020" : "#bdbdbd"}
                    />
                  </span>
                  Высокий приоритет
                </div>
                <div
                  className={
                    "Swith Swith_gray" +
                    ((isPriority && " Swith_active Swith_active_gray") || "")
                  }
                ></div>
              </div>
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_blue">
                Применить
              </button>
            </div>

            <div className="Form__actions Form__actions_center">
              <div
                className="Action__link Action__link_red"
                onClick={() => setIsDelete(true)}
              >
                Удалить
              </div>
              {/*<div className="Action__link" onClick={() => props.handleClose()}>*/}
              {/*  Отмена*/}
              {/*</div>*/}
            </div>
          </form>
        )}
        {isDelete && (
          <form className="Form" onSubmit={(event) => changeDelete(event)}>
            <div className="Form__title">
              {object.general_task.title}
              <div className="Text__signature">Удалить</div>
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_red">
                Удалить
              </button>
            </div>

            <div className="Form__actions Form__actions_center">
              <div className="Action__link" onClick={() => setIsDelete(false)}>
                Отмена
              </div>
            </div>
          </form>
        )}
      </Modal>
    )
  );
}

export default ModalProjectGeneralTaskEdit;
