import React, {useRef, useState} from "react";
import axios from "axios";
import { ROOT_URL } from "../../../config/url";
import {
  getErrorMessageByKey,
  useNotification,
  useList,
  Input,
  Select,
  Modal,
  getAccountFio,
  isValidateNullText,
  jsonFormatText,
} from "@vokymlak/kabinet-ui";
import {
  ROLE_DIRECTOR,
  ROLE_EMPLOYEE
} from "../../../config/constants";

function ModalProjectAdd(props) {
  const [isImportByNumbers, setIsImportByNumbers] = useState(false);

  const {
    list: [listCompanies],
  } = useList({ apiUrl: "/api/companies/list", rootUrl: ROOT_URL });

  const {
    list: [listManagers],
  } = useList({
    apiUrl: "/api/employees/list",
    rootUrl: ROOT_URL,
    urlParams: {
      roles: [ROLE_DIRECTOR, ROLE_EMPLOYEE]
    }
  });

  const {
    list: [listClients],
  } = useList({
    apiUrl: "/api/clients/list",
    rootUrl: ROOT_URL,
  });

  const {
    list: [listLaws],
  } = useList({ apiUrl: "/api/laws", rootUrl: ROOT_URL });

  const inputName = useRef(null);
  const selectCompanyId = useRef(null);
  const selectManagerId = useRef(null);
  const selectLawTag = useRef(null);
  const selectExecutorId = useRef(null);
  const selectProjectYear = useRef(null);
  const selectClientId = useRef(null);

  const { notification } = useNotification();

  const changeAdd = (event) => {
    event.preventDefault();

    const name = jsonFormatText(inputName.current.value);
    const companyId = selectCompanyId.current.value;
    // const managerId = selectManagerId.current.value;
    const lawTag = selectLawTag.current.value;
    // const executorId = selectExecutorId.current.value;
    const projectYear = selectProjectYear.current.value;
    // const clientId = selectClientId.current.value;

    if (isValidateNullText(name)) {
      notification("Заполните все обязательные поля", "red");
      return;
    }

    const jsonData = JSON.stringify({
      name: name,
      company_id:
        companyId.length > 0
          ? companyId === "null"
            ? null
            : Number(companyId)
          : null,
      // manager_id:
      //   managerId.length > 0
      //     ? managerId === "null"
      //       ? null
      //       : Number(managerId)
      //     : null,
      law: {
        tag: lawTag.length > 0 ? (lawTag === "null" ? null : lawTag) : null,
      },
      // executor_id:
      //   executorId.length > 0
      //     ? executorId === "null"
      //       ? null
      //       : Number(executorId)
      //     : null,
      project_year:
        projectYear.length > 0
          ? projectYear === "null"
            ? null
            : Number(projectYear)
          : null,
      // client_id:
      //   clientId.length > 0
      //     ? clientId === "null"
      //       ? null
      //       : Number(clientId)
      //     : null,
      is_import_by_numbers: isImportByNumbers,
    });

    axios
      .post("/api/project/add", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Проект добавлен", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const getCompaniesSelectOptions = (list) => {
    const options = [
      {
        value: "null",
        title: "Не выбрано",
      },
    ];

    list.map((item) => {
      const option = {
        value: item.id,
        title: item.short_name,
      };

      options.push(option);
    });

    return options;
  };

  const getAccountsSelectOptions = (list) => {
    const options = [
      {
        value: "null",
        title: "Не выбрано",
      },
    ];

    list.map((item) => {
      const option = {
        value: item.account.id,
        title: getAccountFio(item.account),
      };

      options.push(option);
    });

    return options;
  };

  const getLawsSelectOptions = (list) => {
    const options = [];

    list.map((item) => {
      const option = {
        value: item.tag,
        title: item.name,
      };

      options.push(option);
    });

    return options;
  };

  return (
    <Modal handleClose={() => props.handleClose()}>
      <form className="Form" onSubmit={(event) => changeAdd(event)}>
        <div className="Form__title">
          Новый
          <div className="Text__signature">Проект</div>
        </div>

        <div className="Form__field">
          <Input reference={inputName} label="Название *"/>
        </div>

        <div className="Form__field">
          <Select
            isFixed={true}
            reference={selectLawTag}
            options={
              !!listLaws
                ? getLawsSelectOptions(listLaws)
                : getLawsSelectOptions([])
            }
            label="Закон"
          />
        </div>

        <div className="Form__field">
          <Select
            isFixed={true}
            reference={selectCompanyId}
            options={
              !!listCompanies
                ? getCompaniesSelectOptions(listCompanies)
                : getCompaniesSelectOptions([])
            }
            label="Компания"
          />
        </div>

        {/*<div className="Form__field">*/}
        {/*  <Select*/}
        {/*    isFixed={true}*/}
        {/*    reference={selectManagerId}*/}
        {/*    options={*/}
        {/*      !!listManagers*/}
        {/*        ? getAccountsSelectOptions(listManagers)*/}
        {/*        : getAccountsSelectOptions([])*/}
        {/*    }*/}
        {/*    label="Куратор"*/}
        {/*  />*/}
        {/*</div>*/}

        {/*<div className="Form__field">*/}
        {/*  <Select*/}
        {/*    isFixed={true}*/}
        {/*    reference={selectExecutorId}*/}
        {/*    options={*/}
        {/*      !!listManagers*/}
        {/*        ? getAccountsSelectOptions(listManagers)*/}
        {/*        : getAccountsSelectOptions([])*/}
        {/*    }*/}
        {/*    label="Специалист по исполнению"*/}
        {/*  />*/}
        {/*</div>*/}

        <div className="Form__field Form__field_last">
          <Select
            isFixed={true}
            reference={selectProjectYear}
            options={[
              {
                value: "null",
                title: "Не выбран",
              },
              {
                value: "2020",
                title: "2020",
              },
              {
                value: "2021",
                title: "2021",
              },
              {
                value: "2022",
                title: "2022",
              },
              {
                value: "2023",
                title: "2023",
              },
              {
                value: "2024",
                title: "2024",
              },
              {
                value: "2025",
                title: "2025",
              },
              {
                value: "2026",
                title: "2026",
              },
              {
                value: "2027",
                title: "2027",
              },
              {
                value: "2028",
                title: "2028",
              },
              {
                value: "2029",
                title: "2029",
              },
              {
                value: "2030",
                title: "2030",
              },
            ]}
            label="Год проекта"
          />
        </div>


        {/*<div className="Form__field Form__field_last">*/}
        {/*  <Select*/}
        {/*    isFixed={true}*/}
        {/*    reference={selectClientId}*/}
        {/*    options={*/}
        {/*      !!listClients*/}
        {/*        ? getAccountsSelectOptions(listClients)*/}
        {/*        : getAccountsSelectOptions([])*/}
        {/*    }*/}
        {/*    label="Клиент"*/}
        {/*  />*/}
        {/*</div>*/}

        <div className="Form__field Form__field_last">
          <div
            className="Button__swith"
            style={{
              padding: "8px",
              width: "100%",
              border: isImportByNumbers
                ? "2px solid #ffe082"
                : "2px dashed #20202015",
              // border: "2px dashed #20202015",
              backgroundColor: isImportByNumbers
                ? "#ffe082"
                : "#ffffff",
              borderRadius: 6,
              marginTop: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              cursor: "pointer",
            }}
            onClick={() =>
              setIsImportByNumbers(!isImportByNumbers)
            }
          >
            <div
              className="Swith__text"
              style={{
                fontSize: 17,
                fontWeight: 600,
                display: "flex",
                alignItems: "center",
                // color: isPriority ? "#ff3c00" : "#202020"
              }}
            >
              Импорт договоров по номерам РИС
            </div>
            <div
              className={
                "Swith Swith_gray" +
                ((isImportByNumbers &&
                    " Swith_active Swith_active_gray") ||
                  "")
              }
            ></div>
          </div>
        </div>

        <div className="Form__actions Form__actions_center">
          <button className="Action__button Action__button_all Action__button_blue">
            Добавить
          </button>
        </div>

        {/*<div className="Form__actions Form__actions_center">*/}
        {/*  <div className="Action__link" onClick={() => props.handleClose()}>*/}
        {/*    Отмена*/}
        {/*  </div>*/}
        {/*</div>*/}
      </form>
    </Modal>
  );
}

export default ModalProjectAdd;
