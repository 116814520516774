import React, { Fragment } from "react";
import { ButtonIcon, Loading } from "@vokymlak/kabinet-ui";
import { ScrollBlock } from "../../../components/ScrollBlock/ScrollBlock";
import LawList from "./LawList";

function SettingsViewPage(props) {
  const {
    list,
    setList,

    isUnroll,
    changeUnroll,

    setIsUpdateList,
    isUpdateList,
  } = props;

  return (
    <ScrollBlock style={{ width: "100%", height: "100%" }}>
      <div className="Page">
        <div className="Page__title">
          <div
            className="Title__text"
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: 21,
              fontWeight: 700,
            }}
          >
            Просмотр настроек
          </div>

          <div className="Title__settings">
            <ButtonIcon
              tooltipText={"Обновить"}
              iconName={"custom-change-circle"}
              iconColor={"#20202075"}
              size={28}
              style={{ marginLeft: 8 }}
              onClick={() => (setList(null), setIsUpdateList(!isUpdateList))}
            />
          </div>
        </div>

        <hr className={"Page__hr"} />

        {!list && (
          <div className={"Page__loader"}>
            <Loading />
          </div>
        )}

        {!!list && (
          <div className="Page__content">
            <ul className="List">
              {!!list &&
                list.map((item) => (
                  <Fragment key={"item_" + item.tag}>
                    <li
                      className={
                        isUnroll(item.tag) ? "List__item active" : "List__item"
                      }
                      onClick={() => changeUnroll(item.tag)}
                    >
                      <div className="Item__title">
                        <div className="Element__title">{item.name}</div>
                      </div>
                      <div className="Item__settings">
                        <div className="Element__datetime"></div>
                      </div>
                    </li>

                    {isUnroll(item.tag) && <LawList law={item} />}
                  </Fragment>
                ))}
            </ul>
          </div>
        )}
      </div>
    </ScrollBlock>
  );
}

export default SettingsViewPage;
