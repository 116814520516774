import React from "react";
import {ErrorExecutionContractsList} from "./ErrorExecutionContractsList";
import {Icon} from "@vokymlak/kabinet-ui";

export const ErrorExecutionContractsTable = (props) => {
  const {
    isUpdateList,
    setIsUpdateList,

    list
  } = props;

  return (
    <table className="Table">
      <thead className="Thead">
      <tr className="Table__tr Table__tr_padding Table__tr_border">
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
            width: "0%",
          }}
        >
          <div
            className="Th__title"
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
          </div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">Проект</div>
        </th>

        <>
          <th
            className="Table__th Table__th_border"
            style={{
              backgroundColor: "#fff59d",
            }}
          >
            <div className="Th__title">Обновлено</div>
          </th>
        </>

        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">Номер договора</div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">Реестровый номер</div>
        </th>

        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">Дата заключения</div>
        </th>
        <th
          className="Table__th Table__th_border Table__th_sticky"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">Предмет</div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#bdbdbd",
          }}
        >
          <div className="Th__title">Цена договора</div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#c5b7de"
          }}
        >
          <div
            className="Th__title"
            >
            Комментарий по исполнению
          </div>
        </th>

        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">Поставщик</div>
        </th>

        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#bbdefb",
          }}
        >
          <div className="Th__title">ЭД Договор зарегистрирован</div>
        </th>

        <>
          <th
            className="Table__th Table__th_border"
            style={{
              backgroundColor: "#bbdefb",
            }}
          >
            <div className="Th__title">Ошибка регистрации</div>
          </th>
        </>
        <>
          <th
            className="Table__th Table__th_border"
            style={{
              backgroundColor: "#bbdefb",
            }}
          >
            <div className="Th__title">Комментарий к ошибке</div>
          </th>
        </>

        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#c5cae9",
          }}
        >
          <div className="Th__title">Примечание</div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#ffe082",
          }}
        >
          <div className="Th__title">Статус</div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#ffe082",
          }}
        >
          <div className="Th__title">Срок действия</div>
        </th>

      </tr>
      </thead>
      <tbody>
      <ErrorExecutionContractsList
        list={list}
        successfulRequest={() => (
          setIsUpdateList(!isUpdateList)
        )}
      />
      </tbody>
    </table>
  );
};
