import React, { useState } from "react";
import { ButtonIcon, Loading } from "@vokymlak/kabinet-ui";
import { ProjectTasksTable } from "./ProjectTasksTable";

function ProjectTasksPage(props) {
  const {
    list,
    setList,
    setIsUpdateList,
    isUpdateList,
  } = props;

  return (
    <>
      <div className="Page__title">
        <div className="Title__text">
          <div
            className="Title__text"
            style={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
              fontSize: 19,
              fontWeight: 700,
              marginRight: 6,
            }}
          >
            <div>
              Задачи клиенту
              {!!list && (
                <span style={{ fontSize: 12, opacity: 0.75, marginLeft: 6 }}>
                  ({list.length})
                </span>
              )}
            </div>
          </div>
        </div>

        <div className="Title__settings">
          <ButtonIcon
            tooltipText={"Обновить"}
            iconName={"custom-change-circle"}
            iconColor={"#20202075"}
            size={28}
            style={{ marginLeft: 8 }}
            onClick={() => (setList(null), setIsUpdateList(!isUpdateList))}
          />
        </div>
      </div>

      {!list && (
        <div className={"Page__loader"}>
          <Loading />
        </div>
      )}

      {!!list && (
        <div className="Page__content">
          <ProjectTasksTable {...props} />
        </div>
      )}
    </>
  );
}

export default ProjectTasksPage;
