import React, { useRef, useState } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../../../config/url";
import {
  getErrorMessageByKey,
  useNotification,
  Input,
  Modal,
} from "@vokymlak/kabinet-ui";

function ModalSpecificationImport(props) {
  const inputContractNumber = useRef(null);
  const [file, setFile] = useState(null);

  const { notification } = useNotification();

  const fileSend = (event) => {
    const contractNumber = event.target.files[0];
    setFile(contractNumber);
  };

  const changeImport = (event) => {
    event.preventDefault();

    if (!file) {
      return;
    }

    let formData = new FormData();
    formData.append("file", file);

    axios
      .post(
        "/api/contract/" + props.contract.uuid + "/specifications/" +
        (!!props.contract.project && !!props.contract.project.law && props.contract.project.law.tag === "44" ? "import" : "ris"),
        formData,
        {
          withCredentials: false,
          baseURL: ROOT_URL,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + localStorage.getItem("tokenSession"),
          },
        }
      )
      .then(() => {
        props.successfulRequest();
        notification("Импорт завершён", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    <Modal handleClose={() => props.handleClose()}>
      <form className="Form" onSubmit={(event) => changeImport(event)}>
        <div className="Form__title">
          Импорт
          <div className="Text__signature">Спецификаций</div>
        </div>

        <div className="Form__field Form__field_last">
          <Input
            reference={inputContractNumber}
            type="file"
            label={!!props.contract.project && !!props.contract.project.law && props.contract.project.law.tag === "44" ? "Excel" : "Json"}
            isFixed={true}
            onChange={(event) => fileSend(event)}
          />
        </div>

        <div className="Form__actions Form__actions_center">
          <button className="Action__button Action__button_all Action__button_blue">
            Импортировать
          </button>
        </div>

        {/*<div className="Form__actions Form__actions_center">*/}
        {/*  <div className="Action__link" onClick={() => props.handleClose()}>*/}
        {/*    Отмена*/}
        {/*  </div>*/}
        {/*</div>*/}
      </form>
    </Modal>
  );
}

export default ModalSpecificationImport;
