import { useState, useEffect, useContext } from "react";
import { ViewerContext } from "../../contexts/viewer";
import useViewer from "../../hooks/useViewer";

export default function useViewerView() {
  const { viewerState, viewerDispatch } = useContext(ViewerContext);
  const [file, setFile] = useState(null);
  const [mimeType, setMimeType] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { viewerClose } = useViewer();

  useEffect(() => {
    return () => {
      setFile(null);
      setMimeType(null);
      setIsLoading(false);
    };
  }, []);

  useEffect(() => {
    setFile(viewerState.file);
    setMimeType(viewerState.mimeType);
    setIsLoading(viewerState.isLoading);
  }, [viewerState]);

  return {
    file,
    mimeType,
    viewerClose,
    isLoading,
  };
}
