import React, { useRef } from "react";
import Modal from "../../../components/Modal/Modal";
import axios from "axios";
import { ROOT_URL } from "../../../config/url";
import useList from "../../../hooks/useList";
import {getErrorMessageByKey, useNotification, Select} from "@vokymlak/kabinet-ui";

function ModalLogicContractStatusAdd(props) {
  const {
    list: [list],
  } = useList("/api/contract-statuses", ROOT_URL);

  const selectContractStatusUuid = useRef(null);

  const { notification } = useNotification();

  const changeAdd = (event) => {
    event.preventDefault();

    const contractStatusUuid = selectContractStatusUuid.current.value;

    if (
      !contractStatusUuid ||
      contractStatusUuid.length === 0 ||
      (contractStatusUuid.length > 0 && contractStatusUuid === "null")
    ) {
      return;
    }

    axios
      .get(
        "/api/contract-group-contract-status/contract-group/" +
        props.object.uuid +
        "/contract-status/" +
        contractStatusUuid +
        "/add",
        {
          withCredentials: false,
          baseURL: ROOT_URL,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("tokenSession"),
          },
        }
      )
      .then(() => {
        props.successfulRequest();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });

    props.handleClose();
  };

  const getSelectOptions = (list) => {
    const options = [
      {
        value: "null",
        title: "Не выбрано",
      },
    ];

    list.map((item) => {
      const option = {
        value: item.uuid,
        title: item.name,
      };

      options.push(option);
    });

    return options;
  };

  return (
    <Modal handleClose={() => props.handleClose}>
      <form className="Form" onSubmit={(event) => changeAdd(event)}>
        <div className="Form__title">
          {props.object.name}
          <div className="Text__signature">Добавить статус</div>
        </div>

        <div className="Form__field Form__field_last">
          <Select
            isFixed={true}
            reference={selectContractStatusUuid}
            options={
              !!list
                ? getSelectOptions(list)
                : getSelectOptions([])
            }
            label="Способ закупки"
          />
        </div>

        <div className="Form__actions Form__actions_center">
          <button className="Action__button Action__button_all Action__button_blue">
            Добавить
          </button>
        </div>

        <div className="Form__actions Form__actions_center">
          <div className="Action__link" onClick={() => props.handleClose()}>
            Отмена
          </div>
        </div>
      </form>
    </Modal>
  );
}

export default ModalLogicContractStatusAdd;
