import React, { useState, useRef } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../../../config/url";
import {
  getAccountFio,
  getErrorMessageByKey,
  useList,
  useNotification,
  Select,
  Textarea,
  Icon,
  Input,
  Modal,
  useObject,
  jsonFormatText,
  isValidateNullText,
} from "@vokymlak/kabinet-ui";
import moment from "moment/moment";
import {ROLE_DIRECTOR, ROLE_EMPLOYEE} from "../../../../../config/constants";

function ModalProjectTaskEdit(props) {
  const {
    list: [list],
  } = useList({
    apiUrl: "/api/employees/list",
    rootUrl: ROOT_URL,
    urlParams: {
      roles: [ROLE_DIRECTOR, ROLE_EMPLOYEE],
    }
  });

  const [isPriority, setIsPriority] = useState(false);

  const {
    object: [projectTask],
  } = useObject({
    apiUrl: "/api/project-task/" + props.projectTask.uuid,
    rootUrl: ROOT_URL,
    callbackResponse: (data) => {
      setIsPriority(!!data && data.is_priority);
    },
    callbackError: () => {
      props.handleClose();
    },
  });

  const inputName = useRef(null);
  const inputNote = useRef(null);
  const inputDateControl = useRef(null);
  const selectWhomId = useRef(null);

  const [isDelete, setIsDelete] = useState(false);
  const [isArchive, setIsArchive] = useState(false);
  const [isReArchive, setIsReArchive] = useState(false);

  const { notification } = useNotification();

  const changeEdit = (event) => {
    event.preventDefault();

    const name = jsonFormatText(inputName.current.value);
    const note = jsonFormatText(inputNote.current.value);
    const dateControl = inputDateControl.current.value;
    const whomId = selectWhomId.current.value;

    if (isValidateNullText(name) || !dateControl || dateControl.length === 0) {
      notification("Заполните все обязательные поля", "red");
      return;
    }

    const jsonData = JSON.stringify({
      name: name,
      note: note,
      date_control:
        !!dateControl && dateControl.length > 0 ? dateControl : null,
      whom_id:
        whomId.length > 0 ? (whomId === "null" ? null : Number(whomId)) : null,
      is_priority: isPriority,
    });

    axios
      .put("/api/project-task/" + props.projectTask.uuid + "/edit", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Данные сохранены", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const changeArchive = (event) => {
    event.preventDefault();

    axios
      .get("/api/project-task/" + props.projectTask.uuid + "/archive", {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Задача проекта в архиве", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const changeReArchive = (event) => {
    event.preventDefault();

    axios
      .get("/api/project-task/" + props.projectTask.uuid + "/re-archive", {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Задача проекта восстановлена", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const changeDelete = (event) => {
    event.preventDefault();

    axios
      .delete("/api/project-task/" + props.projectTask.uuid + "/delete", {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Задача проекта удалена", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const getSelectOptions = (list) => {
    const options = [
      {
        value: "null",
        title: "Не выбрано",
      },
    ];

    list.map((item) => {
      const option = {
        value: item.account.id,
        title: getAccountFio(item.account),
      };

      options.push(option);
    });

    return options;
  };

  return (
    !!projectTask &&
    !!list && (
      <Modal handleClose={() => props.handleClose()}>
        {!isDelete && !isArchive && !isReArchive && (
          <form className="Form" onSubmit={(event) => changeEdit(event)}>
            <div className="Form__title">
              {projectTask.name}
              <div className="Text__signature">Задача проекта</div>
            </div>

            <div className="Form__field">
              <Textarea
                reference={inputName}
                label="Наименование *"
                value={projectTask.name}
              />
            </div>

            <div className="Form__field">
              <Textarea
                reference={inputNote}
                label="Примечание"
                value={projectTask.note}
              />
            </div>

            <div className="Form__field">
              <Input
                reference={inputDateControl}
                type="date"
                isFixed={true}
                label="Дата исполнения *"
                value={
                  !!projectTask.date_control
                    ? moment(projectTask.date_control).format("YYYY-MM-DD")
                    : null
                }
              />
            </div>

            <div className="Form__field">
              <Select
                isFixed={true}
                reference={selectWhomId}
                options={!!list ? getSelectOptions(list) : getSelectOptions([])}
                label="Исполнитель"
                value={
                  !!projectTask.whom_id ? String(projectTask.whom_id) : "null"
                }
              />
            </div>

            <div className="Form__field Form__field_last">
              <div
                className="Button__swith"
                style={{
                  padding: "8px",
                  width: "100%",
                  border: isPriority
                    ? "2px solid #ffe082"
                    : "2px dashed #20202015",
                  backgroundColor: isPriority ? "#ffe082" : "#ffffff",
                  borderRadius: 6,
                  marginTop: 20,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  cursor: "pointer",
                }}
                onClick={() => setIsPriority(!isPriority)}
              >
                <div
                  className="Swith__text"
                  style={{
                    fontSize: 17,
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span style={{ marginRight: 8 }}>
                    <Icon
                      name={"custom-priority"}
                      size={22}
                      color={isPriority ? "#202020" : "#bdbdbd"}
                    />
                  </span>
                  Высокий приоритет
                </div>
                <div
                  className={
                    "Swith Swith_gray" +
                    ((isPriority && " Swith_active Swith_active_gray") || "")
                  }
                ></div>
              </div>
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_blue">
                Применить
              </button>
            </div>

            <div className="Form__actions">
              <div
                className="Action__link Action__link_red"
                onClick={() => setIsDelete(true)}
              >
                Удалить
              </div>

              {!projectTask.archived_at && (
                <div
                  className="Action__link Action__link_gray"
                  onClick={() => setIsArchive(true)}
                >
                  В архив
                </div>
              )}
              {!!projectTask.archived_at && (
                <div
                  className="Action__link Action__link_blue"
                  onClick={() => setIsReArchive(true)}
                >
                  Из архива
                </div>
              )}

              {/*<div className="Action__link" onClick={() => props.handleClose()}>*/}
              {/*  Отмена*/}
              {/*</div>*/}
            </div>
          </form>
        )}

        {isArchive && (
          <form className="Form" onSubmit={(event) => changeArchive(event)}>
            <div className="Form__title">
              {projectTask.name}
              <div className="Text__signature">В архив</div>
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_gray">
                Переместить
              </button>
            </div>

            <div className="Form__actions Form__actions_center">
              <div className="Action__link" onClick={() => setIsArchive(false)}>
                Отмена
              </div>
            </div>
          </form>
        )}

        {isReArchive && (
          <form className="Form" onSubmit={(event) => changeReArchive(event)}>
            <div className="Form__title">
              {projectTask.name}
              <div className="Text__signature">Из архива</div>
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_blue">
                Восстановить
              </button>
            </div>

            <div className="Form__actions Form__actions_center">
              <div
                className="Action__link"
                onClick={() => setIsReArchive(false)}
              >
                Отмена
              </div>
            </div>
          </form>
        )}

        {isDelete && (
          <form className="Form" onSubmit={(event) => changeDelete(event)}>
            <div className="Form__title">
              {projectTask.name}
              <div className="Text__signature">Удалить</div>
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_red">
                Удалить
              </button>
            </div>

            <div className="Form__actions Form__actions_center">
              <div className="Action__link" onClick={() => setIsDelete(false)}>
                Отмена
              </div>
            </div>
          </form>
        )}
      </Modal>
    )
  );
}

export default ModalProjectTaskEdit;
