import React, { Fragment, useState } from "react";
import { NavLink } from "react-router-dom";
import moment from "moment";
import { getAccountFio, Icon } from "@vokymlak/kabinet-ui";
import ModalControlPointExecute from "../../ScreenObjects/Project/components/ProjectContracts/ModalControlPointExecute";

export const ProcurementTasksList = (props) => {
  const [modalControlPointExecute, setModalControlPointExecute] =
    useState(null);

  return (
    !!props.list && (
      <Fragment>
        {props.list.map((item) => (
          <tr
            className="Table__tr Table__tr_padding Table__tr_border Table__tr_hover"
            key={"item_" + item.uuid}
            style={{
              backgroundColor: props.isRed
                ? "#ef9a9a"
                : !!item.date_execution
                  ? "#a5d6a7"
                  : moment(item.date_control).format("YYYY-MM-DD") ===
                  moment().format("YYYY-MM-DD")
                    ? item.is_priority
                      ? "#ffe082"
                      : "inherit"
                    : !!moment(
                      moment(item.date_control).format("YYYY-MM-DD")
                    ).isSameOrBefore(moment().format("YYYY-MM-DD"))
                      ? "#ef9a9a"
                      : item.is_priority
                        ? "#ffe082"
                        : "inherit",
            }}
          >
            <td className="Table__td Table__td_border">
              <div
                className="Td__text Td__text_center"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {item.is_priority && (
                  <Icon name={"custom-priority"} size={16} color={"#202020"}/>
                )}
                {!item.is_priority && "-"}
              </div>
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text Td__text_center">
                {!!item.date_control
                  ? moment(item.date_control).format("DD.MM.YYYY")
                  : null}
              </div>
            </td>
            <td
              className="Table__td Table__td_border"
              style={{
                minWidth: 150,
                maxWidth: 200,
              }}
            >
              <div
                className="Td__text Td__text_center"
                style={{
                  minWidth: 150,
                  maxWidth: 200,
                }}
              >
                {(!!item.contract &&
                    !!item.contract.subject &&
                    item.contract.subject) ||
                  "-"}
              </div>
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text Td__text_center">
                {(!!item.contract &&
                    !!item.contract.purchase_base &&
                    item.contract.purchase_base.name) ||
                  "-"}
              </div>
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text Td__text_center">
                {(!!item.contract &&
                    !!item.contract.purchase_way &&
                    item.contract.purchase_way.name) ||
                  "-"}
              </div>
            </td>

            <td className="Table__td Table__td_border">
              <div className="Td__text Td__text_center">
                {(!!item.contract &&
                    !!item.contract.registry_number &&
                    item.contract.registry_number) ||
                  "-"}
              </div>
            </td>

            <td className="Table__td Table__td_border">
              <div className="Td__text Td__text_center">
                {(!!item.contract &&
                    !!item.contract.uis_number &&
                    item.contract.uis_number) ||
                  "-"}
              </div>
            </td>

            <td className="Table__td Table__td_border">
              <div className="Td__text Td__text_center">
                {(!!item.contract &&
                    !!item.contract.amount &&
                    item.contract.amount / 100) ||
                  "-"}
              </div>
            </td>

            <td className="Table__td Table__td_border">
              <NavLink
                to={
                  "/project/" +
                  props.project.uuid +
                  "/contracts?search=" +
                  item.contract.uuid
                }
              >
                <div
                  className="Td__text Td__text_center"
                  style={{
                    cursor: "pointer",
                    color: "#0064ff",
                    textDecoration: "underline",
                  }}
                >
                  {!!item.control_point && item.control_point.name}
                </div>
              </NavLink>
            </td>
            <td
              className="Table__td Table__td_border"
              style={{
                minWidth: 150,
                maxWidth: 200,
              }}
            >
              <div
                className="Td__text Td__text_center"
                style={{
                  minWidth: 150,
                  maxWidth: 200,
                }}
              >
                {(!!item.contract &&
                    !!item.contract.note &&
                    item.contract.note) ||
                  "-"}
              </div>
            </td>
            {/*<td className="Table__td Table__td_border">*/}
            {/*  <div className="Td__text Td__text_center">*/}
            {/*    {(!!props.project &&*/}
            {/*        !!props.project.employees &&*/}
            {/*        props.project.employees.filter(f => f.project_employee_role_type.tag === 'CURATOR').map(e => (*/}
            {/*          <span key={item.uuid + "_" + e.uuid}>*/}
            {/*          {getAccountFio(e.employee)}*/}
            {/*        </span>*/}
            {/*        ))*/}
            {/*      ) ||*/}
            {/*      "-"}*/}
            {/*  </div>*/}
            {/*</td>*/}
            {/*<td className="Table__td Table__td_border">*/}
            {/*  <div className="Td__text Td__text_center">*/}
            {/*    {(!!props.project &&*/}
            {/*        !!props.project.employees &&*/}
            {/*        props.project.employees.filter(f => f.project_employee_role_type.tag === 'EXECUTOR').map(e => (*/}
            {/*          <span key={item.uuid + "_" + e.uuid}>*/}
            {/*          {getAccountFio(e.employee)}*/}
            {/*        </span>*/}
            {/*        ))*/}
            {/*      ) ||*/}
            {/*      "-"}*/}
            {/*  </div>*/}
            {/*</td>*/}
            <td className="Table__td Table__td_border">
              <div className="Td__text Td__text_center">
                {(!!item.who && getAccountFio(item.who)) || "-"}
              </div>
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text Td__text_center">
                {(!!item.whom && getAccountFio(item.whom)) || "-"}
              </div>
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text Td__text_center">
                {!!item.date_execution && (
                  <>
                    <div
                      className={
                        "Action__button Action__button_gray Action__button_mini"
                      }
                      onClick={() => setModalControlPointExecute(item)}
                    >
                      {moment(item.date_execution).format("DD.MM.YYYY")}
                    </div>
                    <div
                      style={{
                        fontSize: 12,
                        fontWeight: 400,
                      }}
                    >
                      {!!item.executor && getAccountFio(item.executor)}
                    </div>
                  </>
                )}
                {!item.date_execution && (
                  <div
                    className={
                      "Action__button Action__button_blue Action__button_maxi"
                    }
                    onClick={() => setModalControlPointExecute(item)}
                  >
                    Отметить
                  </div>
                )}
              </div>
            </td>
          </tr>
        ))}

        {!!modalControlPointExecute && (
          <ModalControlPointExecute
            contractControlPoint={modalControlPointExecute}
            successfulRequest={props.successfulRequest}
            handleClose={() => setModalControlPointExecute(null)}
          />
        )}
      </Fragment>
    )
  );
};
