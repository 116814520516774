import React, { Fragment, useState } from "react";
import ModalProjectTaskEdit from "./ModalProjectTaskEdit";
import moment from "moment";
import ModalProjectTaskExecute from "./ModalProjectTaskExecute";
import {
  getAccountFio,
  TextLink,
  Icon,
  ButtonIcon, Input,
} from "@vokymlak/kabinet-ui";

export const ProjectTasksList = (props) => {
  const {
    list,

    setIsUpdateList,
    isUpdateList,

    changeSelectContract,
    isSelectContract,
    selectContracts,
  } = props;

  const [modalProjectTaskEdit, setModalProjectTaskEdit] = useState(null);
  const [modalProjectTaskExecute, setModalProjectTaskExecute] = useState(null);

  return (
    !!list && (
      <Fragment>
        {list.map((item) => (
          <tr
            className="Table__tr Table__tr_padding Table__tr_border Table__tr_hover"
            key={"item_" + item.uuid}
            style={{
              backgroundColor: !!item.date_execution
                ? "#a5d6a7"
                : moment(item.date_control).format("YYYY-MM-DD") ===
                  moment().format("YYYY-MM-DD")
                ? item.is_priority
                  ? "#ffe082"
                  : "inherit"
                : !!moment(
                    moment(item.date_control).format("YYYY-MM-DD")
                  ).isSameOrBefore(moment().format("YYYY-MM-DD"))
                ? "#ef9a9a"
                : item.is_priority
                ? "#ffe082"
                : "inherit",
            }}
          >
            <td
              className="Table__td Table__td_border"
              style={{
                backgroundColor: "#fff9c450",
                width: 24
              }}
            >
              <div className="Th__title" style={{ height: 40 }}>
                <Input
                  type={"checkbox"}
                  onChange={() => changeSelectContract(item.uuid)}
                  isChecked={isSelectContract(selectContracts, item.uuid)}
                />
              </div>
            </td>
            <td className="Table__td Table__td_border">
              <div
                className="Td__text Td__text_center"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {item.is_priority && (
                  <Icon name={"custom-priority"} size={16} color={"#202020"} />
                )}
                {!item.is_priority && "-"}
              </div>
            </td>

            <td className="Table__td Table__td_border">
              <div className="Td__text Td__text_center">
                {!!item.updated_at
                  ? moment(item.updated_at).format("DD.MM.YYYY")
                  : moment(item.created_at).format("DD.MM.YYYY")}
              </div>
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text Td__text_center">
                {(!!item.name && item.name) || "-"}
              </div>
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text Td__text_center">
                {(!!item.note && <TextLink text={item.note} />) || "-"}
              </div>
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text Td__text_center">
                {!!item.date_control
                  ? moment(item.date_control).format("DD.MM.YYYY")
                  : "-"}
              </div>
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text Td__text_center">
                {(!!item.who && getAccountFio(item.who)) || "-"}
              </div>
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text Td__text_center">
                {(!!item.whom && getAccountFio(item.whom)) || "-"}
              </div>
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text Td__text_center">
                {!!item.date_execution && (
                  <>
                    <div
                      className={
                        "Action__button Action__button_gray Action__button_mini"
                      }
                      onClick={() => setModalProjectTaskExecute(item)}
                    >
                      {moment(item.date_execution).format("DD.MM.YYYY")}
                    </div>
                    <div
                      style={{
                        fontSize: 12,
                        fontWeight: 400,
                      }}
                    >
                      {(!!item.executor && getAccountFio(item.executor)) || "-"}
                    </div>
                  </>
                )}
                {!item.date_execution && (
                  <div
                    className={
                      "Action__button Action__button_blue Action__button_maxi"
                    }
                    onClick={() => setModalProjectTaskExecute(item)}
                  >
                    Отметить
                  </div>
                )}
              </div>
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text Td__text_center">
                <ButtonIcon
                  tooltipText={"Редактировать"}
                  iconName={"custom-edit"}
                  iconColor={"#202020"}
                  size={36}
                  onClick={() => setModalProjectTaskEdit(item)}
                />
              </div>
            </td>
          </tr>
        ))}

        {!!modalProjectTaskEdit && (
          <ModalProjectTaskEdit
            projectTask={modalProjectTaskEdit}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalProjectTaskEdit(null)}
          />
        )}

        {!!modalProjectTaskExecute && (
          <ModalProjectTaskExecute
            projectTask={modalProjectTaskExecute}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalProjectTaskExecute(null)}
          />
        )}
      </Fragment>
    )
  );
};
