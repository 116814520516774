import React, { useRef } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../config/url";
import {
  getErrorMessageByKey,
  useNotification,
  useObject,
  Modal,
  Input,
  jsonFormatText,
  isValidateNullText, Select,
} from "@vokymlak/kabinet-ui";

function ModalCompanyAuthorizeAdd(props) {
  const inputAuthorizeTypeTag = useRef(null);

  const {
    object: [object],
  } = useObject({
    apiUrl: "/api/company/" + props.company.uuid,
    rootUrl: ROOT_URL,
    callbackError: () => {
      props.handleClose();
    },
  });

  const inputName = useRef(null);
  const inputLogin = useRef(null);
  const inputPassword = useRef(null);

  const { notification } = useNotification();

  const changeAdd = (event) => {
    event.preventDefault();

    const name = jsonFormatText(inputName.current.value);
    const login = jsonFormatText(inputLogin.current.value);
    const password = jsonFormatText(inputPassword.current.value);

    const authorizeTypeTag = inputAuthorizeTypeTag.current.value;

    if (
      isValidateNullText(name) ||
      isValidateNullText(login) ||
      isValidateNullText(password)
    ) {
      notification("Заполните все обязательные поля", "red");
      return;
    }

    const jsonData = JSON.stringify({
      name: name,
      login: login,
      password: password,
      authorize_type: authorizeTypeTag.length > 0
        ? authorizeTypeTag === "null"
          ? null
          : {tag: authorizeTypeTag}
        : null,
    });

    axios
      .post("/api/company/" + props.company.uuid + "/authorize/add", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Доступ добавлен", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    !!object && (
      <Modal handleClose={() => props.handleClose()}>
        <form className="Form" onSubmit={(event) => changeAdd(event)}>
          <div className="Form__title">
            Новый доступ
            <div className="Text__signature">{object.short_name}</div>
          </div>

          <div className="Form__field">
            <Input reference={inputName} label="Наименование" />
          </div>

          <div className="Form__field">
            <Select
              isFixed={true}
              reference={inputAuthorizeTypeTag}
              options={[
                {
                  title: "Не указан",
                  value: "null",
                },
                {
                  title: "РИС",
                  value: "RIS",
                },
              ]}
              label="Тип доступа"
            />
          </div>

          <div className="Form__field">
            <Input reference={inputLogin} label="Логин" />
          </div>

          <div className="Form__field Form__field_last">
            <Input reference={inputPassword} label="Пароль" />
          </div>

          <div className="Form__actions Form__actions_center">
            <button className="Action__button Action__button_all Action__button_blue">
              Добавить
            </button>
          </div>

          {/*<div className="Form__actions Form__actions_center">*/}
          {/*  <div className="Action__link" onClick={() => props.handleClose()}>*/}
          {/*    Отмена*/}
          {/*  </div>*/}
          {/*</div>*/}
        </form>
      </Modal>
    )
  );
}

export default ModalCompanyAuthorizeAdd;
