import React, { useState } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../../../config/url";
import {
  getErrorMessageByKey,
  useNotification,
  Modal,
  useObject,
} from "@vokymlak/kabinet-ui";
import moment from "moment/moment";

function ModalNewSpecificationsDelete(props) {
  const {
    object: [contract],
  } = useObject({
    apiUrl: "/api/contract/" + props.contract.uuid,
    rootUrl: ROOT_URL,
    callbackError: () => {
      props.handleClose();
    },
  });

  const { notification } = useNotification();

  const changeAdd = (event) => {
    event.preventDefault();

    const jsonData = JSON.stringify({
      contract_id: contract.id,
      specifications: !!props.specifications ? props.specifications.filter(s => moment(s.created_at).format("YYYY-MM-DD")
        === moment().format("YYYY-MM-DD")).map(s => s.uuid) : [],
    });

    axios
      .post("/api/contract-specifications/delete", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Спецификации удалены", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    !!contract && (
      <Modal handleClose={() => props.handleClose()}>
        <form className="Form" onSubmit={(event) => changeAdd(event)}>
          <div className="Form__title">
            Добавленные спецификации ({!!props.specifications ? props.specifications.filter(s => moment(s.created_at).format("YYYY-MM-DD")
            === moment().format("YYYY-MM-DD")).length : 0})
            <div className="Text__signature">Удалить</div>
          </div>

          <div className="Form__actions Form__actions_center">
            <button className="Action__button Action__button_all Action__button_red">
              Удалить
            </button>
          </div>
        </form>
      </Modal>
    )
  );
}

export default ModalNewSpecificationsDelete;
