import React from "react";
import ProjectPfhdWrapper from "./ProjectPfhdWrapper";

function ProjectPfhd(props) {
  return (
      <ProjectPfhdWrapper
        {...props}
      />
  );
}

export default ProjectPfhd;
