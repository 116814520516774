import React, { useEffect, useRef, useState } from "react";
import { ROOT_URL } from "../../../../../config/url";
import { useSearchParams } from "react-router-dom";
import {useList, useObject, useAction, useNotification} from "@vokymlak/kabinet-ui";
import {LOGIC_INTERMEDIATE_EXECUTION, LOGIC_PLANNED_DATE_EXECUTION} from "../../../../../config/constants";

function useProjectContracts(props) {
  const [searchParams] = useSearchParams();
  const [searchParam, setSearchParam] = useState(searchParams.get("search"));
  const [isArchive, setIsArchive] = useState(false);
  const [selectView, setSelectView] = useState("all");
  const [contractGroup, setContractGroup] = useState(null);
  const [controlGroups, setControlGroups] = useState(null);
  const [selectContracts, setSelectContracts] = useState([]);
  const [selectContract, setSelectContract] = useState(null);
  const [isUis, setIsUis] = useState(false);
  const [isRis, setIsRis] = useState(false);
  const [isRegistrationError, setIsRegistrationError] = useState(false);
  const [isCtClosing, setIsCtClosing] = useState(false);

  const {notification} = useNotification();

  const isSelectContract = (selectList, uuid) => {
    let isTrue = false
    selectList.forEach(u => {
      if (u === uuid) {
        isTrue = true;
      }
    });
    return isTrue
  }

  const isSelectAllContract = (list, selectList) => {
    return !!list && !!selectList && selectList.length === list.length
  }

  const changeSelectContract = (uuid) => {
    let isNew = true;
    let index = 0;

    selectContracts.forEach((u, i) => {
      if (u === uuid) {
        isNew = false;
        index = i;
      }
    });

    if (isNew) {
      setSelectContracts([...selectContracts, uuid]);
    } else {
      selectContracts.splice(index, 1);
      setSelectContracts([...selectContracts]);
    }
  };

  const changeSelectAllContract = (list, selectList) => {
    const selectContracts = []

    if (!!list && !!selectList && selectList.length !== list.length) {
      list.forEach(item => {
        selectContracts.push(item.uuid)
      });
    }

    setSelectContracts(selectContracts);
  };

  const {
    list: [listControlGroups],
  } = useList({
    apiUrl: "/api/control-groups",
    rootUrl: ROOT_URL,
  });

  useEffect(() => {
    setSearchParam(searchParams.get("search"));
  }, [searchParams]);

  const getUrlParams = () => {
    let params = null;
    if (isArchive) {
      params = {
        ...params,
        archive: isArchive,
      };
    }
    if (isUis) {
      params = {
        ...params,
        uis: isUis,
      };
    }
    if (isRis) {
      params = {
        ...params,
        ris: isRis,
      };
    }
    if (isRegistrationError) {
      params = {
        ...params,
        "registration-error": isRegistrationError,
      };
    }
    if (isCtClosing) {
      params = {
        ...params,
        "ct-closing": isCtClosing,
      };
    }
    if (!!contractGroup) {
      params = {
        ...params,
        "purchase-basis": [],
        "is-purchase-base-null": contractGroup.is_purchase_base_null,
        "contract-statuses": [],
        "is-contract-status-null": contractGroup.is_contract_status_null,
      };
      if (!!contractGroup.purchase_basis) {
        contractGroup.purchase_basis.forEach((a) => {
          if (!!a.purchase_base) {
            params["purchase-basis"].push(a.purchase_base.uuid);
          }
        });
      }
      if (!!contractGroup.purchase_ways) {
        contractGroup.purchase_ways.forEach((a) => {
          if (!!a.purchase_way) {
            params["purchase-ways"].push(a.purchase_way.uuid);
          }
        });
      }
      if (!!contractGroup.contract_statuses) {
        contractGroup.contract_statuses.forEach((a) => {
          if (!!a.contract_status) {
            params["contract-statuses"].push(a.contract_status.uuid);
          }
        });
      }
    }
    if (!!selectView) {
      params = {
        ...params,
        view: selectView,
      };
    }
    if (!!searchParam) {
      params = {
        ...params,
        query: searchParam,
      };
    }
    return params;
  };

  const {
    list: [list, count, setList],
    update: [isUpdateList, setIsUpdateList, updateList],
    page: [pageNumber, setPageNumber, pageLimit, setPageLimit],
    search: [searchQuery, setSearchQuery],
    url: [apiUrl, _setApiUrl],
    params: [apiUrlParams, setApiUrlParams],
  } = useList({
    apiUrl: "/api/client/project/" + props.project.uuid + "/contracts",
    rootUrl: ROOT_URL,
    isPagination: true,
    paginationLimit: 250,
    isSearch: true,
    search: searchParams.get("search"),
    urlParams: getUrlParams(),
  });


  const {
    object: [total],
    update: [isUpdateTotal, setIsUpdateTotal],
    params: [_apiUrlParamsTotal, setApiUrlParamsTotal],
  } = useObject({
    apiUrl: "/api/client/project/" + props.project.uuid + "/contracts/total",
    rootUrl: ROOT_URL,
    urlParams: getUrlParams(),
  });

  useEffect(() => {
    setIsUpdateTotal(!isUpdateTotal);
  }, [isUpdateList]);

  useEffect(() => {
    setApiUrlParams({ ...getUrlParams() });
    setApiUrlParamsTotal({ ...getUrlParams() });
  }, [isArchive, isUis, isRis, isRegistrationError, isCtClosing, contractGroup, selectView, searchParam]);

  useEffect(() => {
    if (!!listControlGroups) {
      const newControlGroups = [];

      listControlGroups.map((controlGroup) => {
        controlGroup.isView = false;
        newControlGroups.push(controlGroup);
      });

      setControlGroups(newControlGroups);
    } else {
      setControlGroups(null);
    }
  }, [listControlGroups]);

  const inputSearchText = useRef(null);
  useEffect(() => {
    setPageNumber(1);
  }, [searchQuery]);

  useEffect(() => {
    setSearchParam(searchQuery);
  }, [searchQuery]);

  function onChangeSearchText() {
    setSearchQuery(inputSearchText.current.value);
  }

  const changePage = (data) => {
    if (pageNumber !== data.currentPage) {
      setPageNumber(data.currentPage);
    }
  };

  const { setAction } = useAction(props.project.uuid, () => updateList());

  useEffect(() => {
    if (!!list) {
      const listUuid = list.map((i) => i.uuid);
      setAction([...listUuid, props.project.uuid]);
    } else {
      setAction(props.project.uuid);
    }
  }, [list]);

  const getContractControlPoint = (item, controlPoint) => {
    let getContractControlPoint = null;

    if (!!item && !!item.control_points) {
      item.control_points.map((point) => {
        if (point.control_point.uuid === controlPoint.uuid) {
          getContractControlPoint = point;
        }
      });
    }

    return getContractControlPoint;
  };

  const changeSetIsViewControlGroup = (groups, group) => {
    const newControlGroups = groups;

    if (!!newControlGroups) {
      newControlGroups.map((controlGroup) => {
        if (controlGroup.uuid === group.uuid) {
          controlGroup.isView = !controlGroup.isView;
        }
      });
    }

    return setControlGroups([...newControlGroups]);
  };

  const isViewControlGroup = (groups, point) => {
    let isView = false;
    let isSearch = false;

    if (!!groups) {
      groups.map((controlGroup) => {
        if (!!controlGroup.points) {
          controlGroup.points.map((groupPoint) => {
            if (groupPoint.control_point.uuid === point.uuid) {
              isView = controlGroup.isView;
              isSearch = true;
            }
          });
        }
      });
    }

    return isSearch ? isView : true;
  };

  const getPlanDateInPoints = (item) => {
    let getPlanDate = null;
    let isSearch = false;

    if (!!item && !!item.control_points) {
      item.control_points.map((point) => {
        if (!point.date_execution && !isSearch) {
          if (!!props.listControlPoints) {
            point.isLogicIntermediateExecution = false

            props.listControlPoints.map(
              (controlPoint) => {
                if (!!controlPoint.control_point_logics) {
                  controlPoint.control_point_logics.map(
                    (controlPointLogic) => {
                      if (!!controlPointLogic.logic &&
                        controlPointLogic.logic.tag === LOGIC_PLANNED_DATE_EXECUTION &&
                        point.control_point.uuid === controlPoint.uuid
                      ) {
                        point.isLogicIntermediateExecution = true
                      }
                    });
                }
              });

            if (!point.isLogicIntermediateExecution) {
              getPlanDate = point;
              isSearch = true;
            }
          }
        }
      });
    }

    return getPlanDate;
  };

  const getIndexPage = () => {
    return (pageNumber - 1) * pageLimit;
  };

  const getPlanDateInPointsByIntermediateExecution = (item) => {
    let getPlanDate = null;
    let isSearch = false;

    if (!!item && !!item.control_points) {
      item.control_points.map((point) => {
        if (!point.date_execution && !isSearch) {
          if (!!props.listControlPoints) {
            point.isLogicIntermediateExecution = false

            props.listControlPoints.map(
              (controlPoint) => {
                if (!!controlPoint.control_point_logics) {
                  controlPoint.control_point_logics.map(
                    (controlPointLogic) => {
                      if (!!controlPointLogic.logic &&
                        controlPointLogic.logic.tag === LOGIC_PLANNED_DATE_EXECUTION &&
                        point.control_point.uuid === controlPoint.uuid
                      ) {
                        point.isLogicIntermediateExecution = true
                      }
                    });
                }
              });

            if (point.isLogicIntermediateExecution) {
              getPlanDate = point;
              isSearch = true;
            }
          }
        }
      });
    }

    return getPlanDate;
  };

  const isControlPoint = (basis, item, controlPoint) => {
    let isControlPoint = false;

    if (!!basis && !!item) {
      basis.map((base) => {
        if (
          !!item.purchase_base_id &&
          base.id === item.purchase_base_id &&
          !!base.control_points
        ) {
          base.control_points.map((point) => {
            if (point.control_point.uuid === controlPoint.uuid) {
              isControlPoint = true;
            }
          });
        }
      });

      if (!item.purchase_base_id) {
        isControlPoint = true;
      }
    }

    return isControlPoint;
  };

  const [isInfo, setIsInfo] = useState(false);
  const [isAmount, setIsAmount] = useState(false);
  const [isAddition, setIsAddition] = useState(false);
  const [isExecution, setIsExecution] = useState(false);

  const [modalContractImport, setModalContractImport] = useState(null);

  const [modalContractAdd, setModalContractAdd] = useState(null);
  const [modalContractEdit, setModalContractEdit] = useState(null);

  const [modalControlPointEdit, setModalControlPointEdit] = useState(null);
  const [modalControlPointExecute, setModalControlPointExecute] =
    useState(null);

  const [isModalContractsEditBase, setIsModalContractsEditBase] = useState(false);
  const [isModalContractsEditExecute, setIsModalContractsEditExecute] = useState(false);
  const [isModalContractsArchive, setIsModalContractsArchive] = useState(false);
  const [isModalContractsReArchive, setIsModalContractsReArchive] = useState(false);
  const [isModalContractsDelete, setIsModalContractsDelete] = useState(false);

  const [isSelectCalc, setIsSelectCalc] = useState(false);

  const getListTotal = (list) => {
    const total = {
      startingPrice: 0,
      amount: 0,
      amountCurrentYear: 0,
      planAmount: 0,
      amountPayments: 0,
      startingPriceAmount: 0,
      amountAmountPayments: 0
    }

    if (!!list) {
      list.forEach(i => {
        if (!!i.starting_price) {
          total.startingPrice += i.starting_price
        }
        if (!!i.amount) {
          total.amount += i.amount
        }
        if (!!i.amount_current_year) {
          total.amountCurrentYear += i.amount_current_year
        }
        if (!!i.plan_amount) {
          total.planAmount += i.plan_amount
        }
        if (!!i.amount_payments) {
          total.amountPayments += i.amount_payments
        }
        if (!!i.starting_price && !!i.amount) {
          total.startingPriceAmount += i.starting_price - i.amount
        }
        if (!!i.amount_payments && !!i.amount) {
          total.amountAmountPayments += i.amount - i.amount_payments
        }
      })
    }

    return total
  }

  const getSelectList = (list, selects) => {
    const selectList = []

    if (!!list && !!selects) {
      selects.forEach(s => {
        let item;
        let isPush = false;
        list.forEach(i => {
          if (i.uuid === s) {
            item = i
            isPush = true;
          }
        })
        if (isPush) {
          selectList.push(item)
        }
      })
    }

    return selectList
  }

  return {
    searchParam,

    object: props.project,

    list,
    setList,
    count,

    pageNumber,
    pageLimit,
    setPageLimit,
    changePage,

    inputSearchText,
    onChangeSearchText,
    searchQuery,

    setIsUpdateList,
    isUpdateList,

    modalContractAdd,
    setModalContractAdd,

    modalContractEdit,
    setModalContractEdit,

    listControlPoints: props.listControlPoints,

    setModalControlPointEdit,
    modalControlPointEdit,

    getContractControlPoint,
    getPlanDateInPoints,
    getPlanDateInPointsByIntermediateExecution,

    setModalContractImport,
    modalContractImport,

    isInfo,
    setIsInfo,
    isAmount,
    setIsAmount,
    isAddition,
    setIsAddition,
    isExecution,
    setIsExecution,

    controlGroups,
    changeSetIsViewControlGroup,
    isViewControlGroup,

    getIndexPage,

    setModalControlPointExecute,
    modalControlPointExecute,

    isArchive,
    setIsArchive,

    listPurchaseBasis: props.listPurchaseBasis,
    isControlPoint,

    listContractGroups: props.listContractGroups,
    contractGroup,
    setContractGroup,

    apiUrl,
    apiUrlParams,

    selectView,
    setSelectView,

    selectContracts,
    setSelectContracts,
    isSelectContract,
    isSelectAllContract,
    changeSelectContract,
    changeSelectAllContract,

    selectContract,
    setSelectContract,

    isModalContractsEditBase,
    setIsModalContractsEditBase,
    isModalContractsEditExecute,
    setIsModalContractsEditExecute,
    isModalContractsArchive,
    setIsModalContractsArchive,
    isModalContractsReArchive,
    setIsModalContractsReArchive,
    isModalContractsDelete,
    setIsModalContractsDelete,

    notification,

    getListTotal,
    getSelectList,
    isSelectCalc,
    setIsSelectCalc,

    total,
    isUpdateTotal,
    setIsUpdateTotal,

    isUis,
    setIsUis,

    isRis,
    setIsRis,

    setIsRegistrationError,
    isRegistrationError,

    setIsCtClosing,
    isCtClosing
  };
}

export default useProjectContracts;
