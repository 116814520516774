import React, {useRef, useState} from "react";
import {ROOT_URL} from "../../../config/url";
import {Modal, Select, useList} from "@vokymlak/kabinet-ui";

function ModalTasksFilter(props) {
  const {
    list: [listProjects],
  } = useList({
    apiUrl: "/api/projects/list",
    rootUrl: ROOT_URL,
  });

  const selectProjectId = useRef(null);

  const changeAddProject = () => {
    const {filterProjects, setFilterProjects} = props.useSearchFilter;

    const projectId = selectProjectId.current.value;

    if (
      !projectId ||
      projectId.length === 0 ||
      (projectId.length > 0 && projectId === "null")
    ) {
      setFilterProjects([...[]]);
    } else {
      let isNew = true;
      filterProjects.map((f) => {
        if (f === projectId) {
          isNew = false;
        }
      });
      if (isNew) {
        filterProjects.push(projectId);
        setFilterProjects([...filterProjects]);
      }
    }

    props.handleClose();
  };

  const getProjectsSelectOptions = (list) => {
    const options = [
      {
        value: "null",
        title: "Все",
      },
    ];

    list.map((item) => {
      const option = {
        value: item.id,
        title: item.name,
      };

      options.push(option);
    });

    return options;
  };

  return (
    <Modal handleClose={() => props.handleClose()}>
      <div className="Form">
        <div className="Form__title">Фильтры поиска</div>
          <>
            <div className="Form__field Form__field_last">
              <Select
                isFixed={true}
                reference={selectProjectId}
                options={
                  !!listProjects
                    ? getProjectsSelectOptions(listProjects)
                    : getProjectsSelectOptions([])
                }
                label="Проект"
              />
            </div>

            <div className="Form__actions Form__actions_center">
              <button
                className="Action__button Action__button_all Action__button_blue"
                onClick={() => changeAddProject()}
              >
                Добавить
              </button>
            </div>
          </>
      </div>
    </Modal>
  );
}

export default ModalTasksFilter;
