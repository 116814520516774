import React, { useRef, useState } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../../config/url";
import {
  getErrorMessageByKey,
  Textarea,
  useList,
  Select,
  useNotification,
  Modal,
  useObject,
  jsonFormatText,
  isValidateNullText, Input,
} from "@vokymlak/kabinet-ui";

function ModalTicketLaborCost(props) {
  const {
    object: [object],
  } = useObject({
    apiUrl: "/api/ticket/" + props.object.uuid,
    rootUrl: ROOT_URL,
    callbackError: () => {
      props.handleClose();
    },
  });

  const inputTimeSpent = useRef(null);
  const inputNote = useRef(null);
  const inputPercentageCompletion = useRef(null);

  const { notification } = useNotification();

  const numberConvert = (numberString) => {
    return !!numberString && numberString.length > 0
      ? Number(String(Number(numberString).toFixed(2)).replace(".", ""))
      : null;
  };

  const changeEdit = (event) => {
    event.preventDefault();

    const timeSpent = jsonFormatText(inputTimeSpent.current.value);
    const note = jsonFormatText(inputNote.current.value);
    const percentageCompletion = jsonFormatText(inputPercentageCompletion.current.value);

    if (isValidateNullText(timeSpent) || isValidateNullText(note)) {
      notification("Заполните все обязательные поля", "red");
      return;
    }

    const jsonData = JSON.stringify({
      time_spent: !!timeSpent && timeSpent.length > 0 ? numberConvert(timeSpent) : null,
      note: (!!note && note.trim()) || null,
      percentage_completion: !!percentageCompletion && percentageCompletion.length > 0 ? Number(percentageCompletion) : null,
    });

    axios
      .post("/api/ticket/" + props.object.uuid + "/add/labor-cost", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Данные сохранены", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    !!object && (
      <Modal handleClose={() => props.handleClose()}>
        <form className="Form" onSubmit={(event) => changeEdit(event)}>
          <div
            className="Form__title"
            style={{fontSize: 19, fontWeight: 700}}
          >
            {object.title}
            <div className="Text__signature">Тикет</div>
          </div>

          <div className="Form__field">
            <Input
              reference={inputTimeSpent}
              type="number"
              label="Затраченое время (в часах) *"
            />
          </div>

          <div className="Form__field">
            <Textarea
              reference={inputNote}
              label="Примечание *"
            />
          </div>

          <div className="Form__field Form__field_last">
            <Input
              reference={inputPercentageCompletion}
              type={"number"}
              label="Выполнено (%), при надобности"
            />
          </div>

          <div className="Form__actions Form__actions_center">
            <button className="Action__button Action__button_all Action__button_blue">
              Применить
            </button>
          </div>

          <div className="Form__actions Form__actions_center">
            <div className="Action__link" onClick={() => props.handleClose()}>
              Отмена
            </div>
          </div>
        </form>
      </Modal>
    )
  );
}

export default ModalTicketLaborCost;
