import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../config/url";
import moment from "moment";
import {
  getErrorMessageByKey,
  Textarea,
  useList,
  Select,
  useNotification,
  Modal,
  Input,
  useObject,
  jsonFormatText,
  isValidateNullText, getAccountFio,
} from "@vokymlak/kabinet-ui";

function ModalAppealEditExecutor(props) {
  const {
    object: [object],
  } = useObject({
    apiUrl: "/api/appeal/" + props.object.uuid,
    rootUrl: ROOT_URL,
    callbackError: () => {
      props.handleClose();
    },
  });

  const {
    list: [employees],
  } = useList({ apiUrl: "/api/employees/list", rootUrl: ROOT_URL });

  const inputExecutorId = useRef(null);

  const { notification } = useNotification();

  const changeEdit = (event) => {
    event.preventDefault();

    const executorId = inputExecutorId.current.value;


    const jsonData = JSON.stringify({
      executor_id: executorId.length > 0 ? (executorId === "null" ? null : Number(executorId)) : null,
    });

    axios
      .put("/api/appeal/" + props.object.uuid + "/edit/executor", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Данные сохранены", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const getSelectExecutorOptions = (list) => {
    const options = [
      {
        value: "null",
        title: "Не выбрано",
      },
    ];

    list.map((item) => {
      const option = {
        value: item.account.id,
        title: getAccountFio(item.account),
      };

      options.push(option);
    });

    return options;
  };

  return (
    !!object &&
    !!employees && (
      <Modal handleClose={() => props.handleClose()}>
          <form className="Form" onSubmit={(event) => changeEdit(event)}>
            <div
              className="Form__title"
              style={{fontSize: 19, fontWeight: 700}}
            >
              Обращение
              <div className="Text__signature">Ответственный</div>
            </div>

            <div className="Form__field">
              <Select
                isFixed={true}
                reference={inputExecutorId}
                options={employees && getSelectExecutorOptions(employees)}
                value={!!object.executor_id ? object.executor_id : null}
                label="Исполнитель"
              />
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_blue">
                Назначить
              </button>
            </div>

            <div className="Form__actions">
              <div className="Action__link" onClick={() => props.handleClose()}>
                Отмена
              </div>
            </div>
          </form>
      </Modal>
    )
  );
}

export default ModalAppealEditExecutor;
