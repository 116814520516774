import React from "react";
import { ContractSpecificationsTableHead } from "./ContractSpecificationsTableHead";
import { ContractSpecificationsList } from "./ContractSpecificationsList";

export const ContractSpecificationsTable = (props) => {
  const { object, list } = props;

  return (
    !!object && (
      <table className="Table">
        <thead className="Thead">
          <ContractSpecificationsTableHead {...props} />
        </thead>
        <tbody>{!!list && <ContractSpecificationsList {...props} />}</tbody>
      </table>
    )
  );
};
