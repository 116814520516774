import React from "react";
import { ProjectTasksList } from "./ProjectTasksList";

export const ProjectTasksTable = (props) => {
  const {} = props;

  return (
    <table className="Table">
      <thead className="Thead">
        <tr className="Table__tr Table__tr_padding Table__tr_border">
          <th
            className="Table__th Table__th_border"
            style={{
              backgroundColor: "#dfdfdf",
            }}
          >
            <div className="Th__title">Обновлено</div>
          </th>
          <th
            className="Table__th Table__th_border"
            style={{
              backgroundColor: "#dfdfdf",
            }}
          >
            <div className="Th__title">Задача</div>
          </th>
          <th
            className="Table__th Table__th_border"
            style={{
              backgroundColor: "#dfdfdf",
            }}
          >
            <div className="Th__title">Примечание</div>
          </th>
          <th
            className="Table__th Table__th_border"
            style={{
              backgroundColor: "#dfdfdf",
            }}
          >
            <div className="Th__title">Дата исполнения</div>
          </th>
          <th
            className="Table__th Table__th_border"
            style={{
              backgroundColor: "#dfdfdf",
            }}
          >
            <div className="Th__title">Назначил</div>
          </th>
          <th
            className="Table__th Table__th_border"
            style={{
              backgroundColor: "#dfdfdf",
            }}
          >
            <div className="Th__title">Исполнитель</div>
          </th>
        </tr>
      </thead>
      <tbody>
        <ProjectTasksList
          {...props}
        />
      </tbody>
    </table>
  );
};
