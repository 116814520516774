import React, { useRef, useState } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../../config/url";
import {
  useNotification,
  getErrorMessageByKey,
  jsonFormatText,
  isValidateNullText,
  Modal,
  Input,
  Select,
} from "@vokymlak/kabinet-ui";

function isSameDomainLink(link) {
  let currentDomain = window.location.hostname;
  let parser = document.createElement('a');
  parser.href = link;
  let linkDomain = parser.hostname;
  return currentDomain === linkDomain;
}

function ModalAddEditorLink(props) {
  const inputTitle = useRef(null);

  const { notification } = useNotification();

  const changeAdd = (event) => {
    event.preventDefault();

    const title = jsonFormatText(inputTitle.current.value);

    if (isValidateNullText(title)) {
      notification("Заполните все обязательные поля", "red");
      return;
    }

    let selection = window.getSelection();
    // Устанавливаем выделение заново
    selection.removeAllRanges();
    selection.addRange(props.range);

    const textLink = isSameDomainLink(title) ? `<a href="${title}">${selection.toString()}</a>` : `<a href="${title}" target="_blank">${selection.toString()}</a>`;

    props.successfulRequest(textLink);
    props.handleClose();
  };

  const handleClose = () => {
    let selection = window.getSelection();
    // Устанавливаем выделение заново
    selection.removeAllRanges();
    selection.addRange(props.range);

    props.handleClose();
  };

  return (
    <Modal handleClose={() => handleClose()}>
      <form className="Form" onSubmit={(event) => changeAdd(event)}>
        <div className="Form__title">Ссылка</div>

        <div className="Form__field">
          <Input reference={inputTitle} type="text" label="Ссылка на ресурс *" />
        </div>

        <div className="Form__actions Form__actions_center">
          <button className="Action__button Action__button_all Action__button_blue">
            Добавить
          </button>
        </div>

        <div className="Form__actions Form__actions_center">
          <div className="Action__link" onClick={() => handleClose()}>
            Отмена
          </div>
        </div>
      </form>
    </Modal>
  );
}

export default ModalAddEditorLink;
