import React, { Fragment, useEffect, useState } from "react";
import ModalEditHandbookEntry from "./ModalEditHandbookEntry";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ModalAddHandbookEntry from "./ModalAddHandbookEntry";
import {
  ButtonIcon,
  Icon,
  Loading,
  Pagination,
  Search,
  Select,
  Tooltip,
} from "@vokymlak/kabinet-ui";
import { ScrollBlock } from "../../../../components/ScrollBlock/ScrollBlock";
import ModalArticleInGroup from "./ModalArticleInGroup";
import ModalArticleFromGroup from "./ModalArticleFromGroup";

export const StrictModeDroppable = ({ children, ...props }) => {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  if (!enabled) {
    return null;
  }

  return <Droppable {...props}>{children}</Droppable>;
};

export const HandbookEntriesPage = (props) => {
  const {
    list,
    setList,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    setIsUpdateList,
    isUpdateList,

    modalItemEdit,
    setModalItemEdit,
    isModalObjectAdd,
    setIsModalObjectAdd,

    onDragEnd,
  } = props;

  const [modalArticleInGroup, setModalArticleInGroup] = useState(null);
  const [modalArticleFromGroup, setModalArticleFromGroup] = useState(null);

  return (
    <ScrollBlock style={{ width: "100%", height: "100%" }}>
      <div className="HandbookPage" style={{
        backgroundColor: "#f1f1f1"
      }}>
        <div className="Page__title">
          <div className="Title__text">
            <div
              className="Title__text"
              style={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
                fontSize: 19,
                fontWeight: 700,
              }}
            >
              Настройки оглавление
              <span
                style={{
                  fontSize: 13,
                  opacity: 0.5,
                  fontWeight: 400
                }}
              >
                          Добавьте статьи и настройте отображение
                        </span>
            </div>
          </div>

          <div className="Title__settings">
            {!!list && (
              <ButtonIcon
                tooltipText={
                  list.length === unrolls.length ? "Свернуть" : "Развернуть"
                }
                iconName={
                  list.length === unrolls.length
                    ? "custom-unfold-less"
                    : "custom-unfold-more"
                }
                iconColor={"#20202075"}
                size={28}
                style={{ marginLeft: 8 }}
                onClick={() => changeAllUnroll(list, "uuid")}
              />
            )}

            <ButtonIcon
              tooltipText={"Обновить"}
              iconName={"custom-change-circle"}
              iconColor={"#20202075"}
              size={28}
              style={{ marginLeft: 8 }}
              onClick={() => (setList(null), setIsUpdateList(!isUpdateList))}
            />

            <ButtonIcon
              tooltipText={"Добавить"}
              iconName={"custom-add"}
              iconColor={"#0064ff"}
              size={36}
              style={{ marginLeft: 12 }}
              onClick={() => setIsModalObjectAdd(true)}
            />
          </div>
        </div>

        {!list && (
          <div className={"Page__loader"}>
            <Loading />
          </div>
        )}

        {!!list && (
          <div className="Page__content">
            <ul className="List">
              <DragDropContext onDragEnd={onDragEnd}>
                <StrictModeDroppable droppableId={"droppable"}>
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {!!list &&
                        list.map((item, itemIndex) => (
                          <Draggable
                            draggableId={String(item.uuid)}
                            index={itemIndex}
                            key={"draggable_" + item.uuid}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.dragHandleProps}
                                {...provided.draggableProps}
                              >
                                <Fragment key={"item_" + item.uuid}>
                                  <li
                                    className={
                                      isUnroll(item.uuid)
                                        ? "List__item active"
                                        : "List__item"
                                    }
                                    onClick={() => changeUnroll(item.uuid)}
                                    style={{
                                      backgroundColor: !!item.is_public && item.is_public && "#0061ff25"
                                    }}
                                  >
                                    <div className="Item__title">
                                      <div
                                        className="Element__title"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        {item.title}
                                      </div>
                                    </div>
                                    <div className="Item__settings">
                                      <div className="Element__datetime"></div>
                                    </div>
                                  </li>

                                  {isUnroll(item.uuid) && (
                                    <li className="List__content">
                                      <div className="Content__header">
                                        <div className="Header__title">
                                          {item.handbook_entry_type.tag === "ARTICLE" && (
                                          <div
                                            className="Button__text"
                                            style={{
                                              marginRight: 24,
                                            }}
                                            onClick={() =>
                                              setModalArticleInGroup(item)
                                            }
                                          >
                                            Перенести в группу
                                          </div>
                                          )}
                                        </div>
                                        <div className="Header__settings">
                                          <ButtonIcon
                                            tooltipText={"Редактировать"}
                                            iconName={"custom-edit"}
                                            iconColor={"#202020"}
                                            size={36}
                                            style={{ marginLeft: 12 }}
                                            onClick={() => setModalItemEdit(item)}
                                          />
                                        </div>
                                      </div>

                                      {!!item.articles && (
                                      <div>
                                        <ul className="List"  style={{
                                          paddingTop: 8,
                                          paddingLeft: 8,
                                          paddingRight: 8,
                                          border: "2px solid #dbdbdb",
                                          borderRadius: 11
                                        }}>
                                          <DragDropContext onDragEnd={onDragEnd}>
                                            <StrictModeDroppable droppableId={item.uuid}>
                                              {(provided) => (
                                                <div ref={provided.innerRef} {...provided.droppableProps}>
                                                  {!!item.articles &&
                                                    item.articles.map((article, articleIndex) => (
                                                      <Draggable
                                                        draggableId={String(article.uuid)}
                                                        index={articleIndex}
                                                        key={"draggable_" + article.uuid}
                                                      >
                                                        {(provided) => (
                                                          <div
                                                            ref={provided.innerRef}
                                                            {...provided.dragHandleProps}
                                                            {...provided.draggableProps}
                                                          >
                                                            <Fragment key={"article_" + article.uuid}>
                                                              <li
                                                                className={
                                                                  isUnroll(article.uuid)
                                                                    ? "List__item active"
                                                                    : "List__item"
                                                                }
                                                                onClick={() => changeUnroll(article.uuid)}
                                                                style={{
                                                                  backgroundColor: !!article.is_public && article.is_public && "#0061ff25"
                                                                }}
                                                              >
                                                                <div className="Item__title">
                                                                  <div
                                                                    className="Element__title"
                                                                    style={{
                                                                      display: "flex",
                                                                      alignItems: "center",
                                                                    }}
                                                                  >
                                                                    {article.title}
                                                                  </div>
                                                                </div>
                                                                <div className="Item__settings">
                                                                  <div className="Element__datetime"></div>
                                                                </div>
                                                              </li>

                                                              {isUnroll(article.uuid) && (
                                                                <li className="List__content">
                                                                  <div className="Content__header">
                                                                    <div className="Header__title">
                                                                      {article.handbook_entry_type.tag === "ARTICLE" && (
                                                                        <div
                                                                          className="Button__text"
                                                                          style={{
                                                                            marginRight: 24,
                                                                          }}
                                                                          onClick={() =>
                                                                            setModalArticleInGroup(article)
                                                                          }
                                                                        >
                                                                          Перенести в группу
                                                                        </div>
                                                                      )}
                                                                      <div
                                                                        className="Button__text"
                                                                        style={{
                                                                          marginRight: 24,
                                                                        }}
                                                                        onClick={() =>
                                                                          setModalArticleFromGroup(article)
                                                                        }
                                                                      >
                                                                        Убрать из группы
                                                                      </div>
                                                                    </div>
                                                                    <div className="Header__settings">
                                                                      <ButtonIcon
                                                                        tooltipText={"Редактировать"}
                                                                        iconName={"custom-edit"}
                                                                        iconColor={"#202020"}
                                                                        size={36}
                                                                        style={{ marginLeft: 12 }}
                                                                        onClick={() => setModalItemEdit(article)}
                                                                      />
                                                                    </div>
                                                                  </div>
                                                                </li>
                                                              )}
                                                            </Fragment>
                                                          </div>
                                                        )}
                                                      </Draggable>
                                                    ))}
                                                  {provided.placeholder}
                                                </div>
                                              )}
                                            </StrictModeDroppable>
                                          </DragDropContext>
                                        </ul>
                                      </div>
                                        )}
                                    </li>
                                  )}
                                </Fragment>
                              </div>
                            )}
                          </Draggable>
                        ))}
                      {provided.placeholder}
                    </div>
                  )}
                </StrictModeDroppable>
              </DragDropContext>
            </ul>
          </div>
        )}

        {!!modalItemEdit && (
          <ModalEditHandbookEntry
            object={modalItemEdit}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalItemEdit(null)}
          />
        )}
        {!!isModalObjectAdd && (
          <ModalAddHandbookEntry
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setIsModalObjectAdd(false)}
          />
        )}


        {!!modalArticleInGroup && (
          <ModalArticleInGroup
            object={modalArticleInGroup}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalArticleInGroup(null)}
          />
        )}
        {!!modalArticleFromGroup && (
          <ModalArticleFromGroup
            object={modalArticleFromGroup}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalArticleFromGroup(null)}
          />
        )}
      </div>
    </ScrollBlock>
  );
};
