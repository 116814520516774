import React, { useRef } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../../../config/url";
import {
  getErrorMessageByKey,
  useNotification,
  Modal,
  useObject,
  Textarea,
  Input,
  jsonFormatText,
} from "@vokymlak/kabinet-ui";

function ModalSpecificationAdd(props) {
  const {
    object: [contract],
  } = useObject({
    apiUrl: "/api/contract/" + props.contract.uuid,
    rootUrl: ROOT_URL,
    callbackError: () => {
      props.handleClose();
    },
  });

  const inputRegistryIndex = useRef(null);
  const inputRegistryNumber = useRef(null);

  const inputKpgz = useRef(null);
  const inputSpgz = useRef(null);
  const inputKtru = useRef(null);
  const inputOkpd2 = useRef(null);
  const inputOkved2 = useRef(null);

  const inputUnitMeasurement = useRef(null);
  const inputVolume = useRef(null);

  const inputPricePerUnit = useRef(null);
  const inputAmount = useRef(null);

  const { notification } = useNotification();

  const numberConvert = (numberString) => {
    return !!numberString && numberString.length > 0
      ? Number(String(Number(numberString).toFixed(2)).replace(".", ""))
      : null;
  };

  const numberConvertV = (numberString) => {
    return !!numberString && numberString.length > 0
      ? Number(String(Number(numberString).toFixed(3)).replace(".", ""))
      : null;
  };

  const changeAdd = (event) => {
    event.preventDefault();

    const registryIndex = inputRegistryIndex.current.value;
    const registryNumber = jsonFormatText(inputRegistryNumber.current.value);

    const kpgz = jsonFormatText(inputKpgz.current.value);
    const spgz = jsonFormatText(inputSpgz.current.value);
    const ktru = jsonFormatText(inputKtru.current.value);
    const okpd2 = jsonFormatText(inputOkpd2.current.value);
    const okved2 = jsonFormatText(inputOkved2.current.value);

    const unitMeasurement = jsonFormatText(inputUnitMeasurement.current.value);
    const volume = inputVolume.current.value;

    const pricePerUnit = inputPricePerUnit.current.value;
    const amount = inputAmount.current.value;

    const jsonData = JSON.stringify({
      registry_index: Number(registryIndex),
      registry_number: registryNumber,

      kpgz: kpgz,
      spgz: spgz,
      ktru: ktru,
      okpd_2: okpd2,
      okved_2: okved2,

      unit_measurement: unitMeasurement,
      volume: numberConvertV(volume),

      price_per_unit: numberConvert(pricePerUnit),
      amount: numberConvert(amount)
    });

    axios
      .post("/api/contract/" + props.contract.uuid + "/specification/add", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Спецификация добавлена", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    !!contract && (
      <Modal handleClose={() => props.handleClose()}>
        <form className="Form" onSubmit={(event) => changeAdd(event)}>
          <div className="Form__title">
            Новый
            <div className="Text__signature">Спецификация</div>
          </div>

          <div className="Form__field">
            <Input reference={inputRegistryIndex}
                   type="number"  label="Позиция в реестре" />
          </div>

          <div className="Form__field">
            <Input reference={inputRegistryNumber} label="Реестровый номер" />
          </div>

          <div className="Form__field" style={{
            opacity: !!contract.project && !!contract.project.law && contract.project.law.tag === "223" && 0.33
          }}>
            <Textarea
              reference={inputKpgz}
              label="КПГЗ"
              disabled={!!contract.project && !!contract.project.law && contract.project.law.tag === "223"}
            />
          </div>
          <div className="Form__field">
            <Textarea
              reference={inputSpgz}
              label="СПГЗ"
            />
          </div>
          <div className="Form__field" style={{
            opacity: !!contract.project && !!contract.project.law && contract.project.law.tag === "223" && 0.33
          }}>
            <Textarea
              reference={inputKtru}
              label="КТРУ"
              disabled={!!contract.project && !!contract.project.law && contract.project.law.tag === "223"}
            />
          </div>
          <div className="Form__field">
            <Textarea
              reference={inputOkpd2}
              label="ОКПД-2"
            />
          </div>


          <div className="Form__field" style={{
            opacity: !!contract.project && !!contract.project.law && contract.project.law.tag === "44" && 0.33
          }}>
            <Textarea
              reference={inputOkved2}
              label="ОКВЭД-2"
              disabled={!!contract.project && !!contract.project.law && contract.project.law.tag === "44"}
            />
          </div>

          <div className="Form__field">
            <Input
              reference={inputUnitMeasurement}
              label="Единица измерения"
            />
          </div>
          <div className="Form__field" style={{
            opacity: contract.is_without_volume && 0.33
          }}>
            <Input
              reference={inputVolume}
              type="number"
              label="Объем"
              disabled={contract.is_without_volume}
            />
          </div>

          <div className="Form__field">
            <Input
              reference={inputPricePerUnit}
              type="number"
              label="Цена за единицу"
            />
          </div>
          <div className="Form__field Form__field_last" style={{
            opacity: contract.is_without_volume && 0.33
          }}>
            <Input
              reference={inputAmount}
              type="number"
              label="Сумма"
              disabled={contract.is_without_volume}
            />
          </div>

          <div className="Form__actions Form__actions_center">
            <button className="Action__button Action__button_all Action__button_blue">
              Добавить
            </button>
          </div>
        </form>
      </Modal>
    )
  );
}

export default ModalSpecificationAdd;
