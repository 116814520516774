import React, { useEffect, useState } from "react";
import { ROOT_URL } from "../../../../../../config/url";
import moment from "moment";
import {useList} from "@vokymlak/kabinet-ui";

function useKpiTicketAnalytics({ date, selectEmployee, setListCalendar }) {
  const [selectDate, setSelectDate] = useState(moment().format("YYYY-MM-DD"));
  const [toDay] = useState(moment);
  const [calendarDays, setCalendarDays] = useState(
    new Date(
      Number(moment(date).format("YYYY")),
      Number(moment(date).format("MM")),
      0
    ).getDate()
  );
  const [days, setDays] = useState(null);

  useEffect(() => {
    setCalendarDays(
      new Date(
        Number(moment(date).format("YYYY")),
        Number(moment(date).format("MM")),
        0
      ).getDate()
    );
  }, [date]);

  useEffect(() => {
    const days = [];
    for (let i = 0; i < calendarDays; i++) {
      let day = i + 1;

      const d = {
        day,
      };

      days.push(d);
    }

    setDays([...days]);
  }, [calendarDays]);

  const {
    list: [list, _count, setList],
    setApiUrlParams,
  } = useList({
      apiUrl: "/api/kpi/ticket/chart",
      rootUrl: ROOT_URL,
      urlParams:
        {
          account: !!selectEmployee && selectEmployee > 0 ? selectEmployee : 0,
          date: date,
        }
    }
  );

  useEffect(() => {
    setListCalendar(list);
  }, [list]);

  useEffect(() => {
    setList(null)
    const params = {
      account: !!selectEmployee && selectEmployee > 0 ? selectEmployee : 0,
      date: date,
    };
    setApiUrlParams(params);
  }, [selectEmployee, date]);

  const weekDayText = (weekDay) => {
    const weekDays = ["вс", "пн", "вт", "ср", "чт", "пт", "сб"];

    return weekDays[Number(weekDay)];
  };

  const changeSelectDay = (day) => {
    setSelectDate(
      moment(moment(date).format("YYYY-MM") + "-" + day, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      )
    );
  };

  //--------------------------------------------------------------------------------------------------------------------

  const getDayCount = (list, year, month, day) => {
    let dayCount = {
      time: 0,
      countEntries: 0,
    };

    if (!!list) {
      list.map((point) => {
        if (
          Number(moment(point.date.substring(0, 19)).format("YYYY")) ===
            Number(year) &&
          Number(moment(point.date.substring(0, 19)).format("MM")) ===
            Number(month) &&
          Number(moment(point.date.substring(0, 19)).format("DD")) ===
            Number(day)
        ) {
          dayCount.time = point.time;
          dayCount.countEntries = point.count_entries;
        }
      });
    }

    return dayCount;
  };

  const isDayMarker = (listCalendar, selectYear, selectMonth, day) => {
    let isDayMarker = false;

    if (!!listCalendar) {
      listCalendar.map((point) => {
        if (
          Number(moment(point.date.substring(0, 19)).format("YYYY")) ===
          Number(selectYear) &&
          Number(moment(point.date.substring(0, 19)).format("MM")) ===
          Number(selectMonth) &&
          Number(moment(point.date.substring(0, 19)).format("DD")) ===
          Number(day)
        ) {
          isDayMarker = true;
        }
      });
    }

    return isDayMarker;
  };

  const getOptionDays = (days) => {
    const options = [];
    if (!!days) {
      days.forEach((d) => {
        options.push(d.day);
      });
    }

    return options;
  };

  const getOptions = (list, year, month, days, countFirst, countSecond) => {
    const optionDays = [];
    if (!!days && !!list) {
      days.forEach((d) => {
        const dayCount = getDayCount(list, year, month, d);
        optionDays.push(
          Number(dayCount[countFirst]) +
            (!!countSecond ? Number(dayCount[countSecond]) : 0)
        );
      });
    }
    return optionDays;
  };

  const getMaxOption = (options) => {
    let max = 0;
    options.map((t) => {
      if (t > max) {
        max = t;
      }
    });
    return max;
  };

  const option = (lists, year, month, days) => {
    const optionDays = getOptionDays(days);

    let optionAmount = getOptions(
      lists,
      year,
      month,
      optionDays,
      "time",
      null
    );

    const maxAmount =
      optionAmount.length > 0 ? getMaxOption([...optionAmount]) : 0;

    optionAmount = optionAmount.map((a) => (a / 100).toFixed(2));

    return {
      grid: {
        left: "8px",
        right: "8px",
        bottom: "8px",
        top: "8px",
        containLabel: true,
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          crossStyle: {
            color: "#999",
          },
        },
      },
      xAxis: [
        {
          type: "category",
          data: optionDays,
          axisPointer: {
            type: "shadow",
          },
        },
      ],
      yAxis: [
        {
          type: "value",
          // name: 'Сумма',
          min: 0,
          max: (maxAmount / 100).toFixed(2),
          interval: (maxAmount / 100).toFixed(2),
          axisLabel: {
            formatter: "{value}",
          },
        },
      ],
      series: [
        {
          yAxisIndex: 0,
          name: "Время",
          type: "bar",
          data: optionAmount,
        },
      ],
      aria: {
        enabled: true,
        decal: {
          show: true,
        },
      },
    };
  };

  return {
    selectEmployee,
    date,

    changeSelectDay,
    selectDate,
    toDay,
    weekDayText,
    days,

    option,
    list,
    getDayCount,
    isDayMarker,
  };
}

export default useKpiTicketAnalytics;
