import React, {useRef, useState} from "react";
import axios from "axios";
import {
  getErrorMessageByKey,
  useNotification,
  Modal,
  Input, formatBytes,
} from "@vokymlak/kabinet-ui";
import {ROOT_URL} from "../../config/url";

function ModalProfileAvatarAdd(props) {
  const inputTicketFile = useRef(null);
  const [files, setFiles] = useState(null);

  const fileSend = (event) => {
    const files = event.target.files;
    setFiles(Array.from(files));
  };

  const { notification } = useNotification();

  const changeAdd = (event) => {
    event.preventDefault();

    if (!files || (!!files && files.length === 0)) {
      notification("Прикрепите файл", "red");
      return;
    }

    if (!!files && files.length > 1) {
      notification("Можно прикрепить не больше 1 файлов за раз", "red");
      return;
    }

    if (!!files && files.filter(f => f.size > 5242880).length > 0) {
      notification("Файл превышает допустимый размер в 5Мб", "red");
      return;
    }

    let formData = new FormData();
    files.forEach(f => {
      formData.append("files", f);
    })

    axios
      .post(
        "/api/profile/upload/avatar",
        formData,
        {
          withCredentials: false,
          baseURL: ROOT_URL,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + localStorage.getItem("tokenSession"),
          },
        }
      )
      .then(() => {
        props.successfulRequest();
        notification("Фото загружено", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    <Modal handleClose={() => props.handleClose()}>
      <form className="Form" onSubmit={(event) => changeAdd(event)}>
        <div className="Form__title">
          Новая фотография
        </div>

        <div className="Form__field">
          <Input
            reference={inputTicketFile}
            type="file"
            label="Фото"
            isFixed={true}
            onChange={(event) => fileSend(event)}
            isMultiple={false}
          />
        </div>

        <div className="Form__field Form__field_last">
          {!!files && files.map((file, index) => (
            <div key={'file_' + index} style={{
              backgroundColor: "#f1f1f1",
              padding: "6px 10px",
              borderRadius: 5,
              fontSize: 15,
              marginBottom: 4,
              marginTop: 4
            }}>
              {file.name}
              <div>
                {formatBytes(file.size)}
              </div>
            </div>
          ))}
        </div>

        <div className="Form__actions Form__actions_center">
          <button className="Action__button Action__button_all Action__button_blue">
            Загрузить
          </button>
        </div>

        <div className="Form__actions Form__actions_center">
          <div className="Action__link" onClick={() => props.handleClose()}>
            Отмена
          </div>
        </div>
      </form>
    </Modal>
  );
}

export default ModalProfileAvatarAdd;
