import React, { useState } from "react";
import { ROOT_URL } from "../../../../config/url";
import { useAction, useList, useUnroll } from "@vokymlak/kabinet-ui";

export const useCatalogEntries = () => {
  const {
    list: [list, _count, setList],
    update: [isUpdateList, setIsUpdateList],
  } = useList({ apiUrl: "/api/catalog-entries", rootUrl: ROOT_URL });

  const { unrolls, isUnroll, changeUnroll, changeAllUnroll } = useUnroll();

  const [isModalObjectAdd, setIsModalObjectAdd] = useState(false);
  const [modalItemEdit, setModalItemEdit] = useState(null);

  useAction("catalog", () => setIsUpdateList(!isUpdateList));

  return {
    list,
    setList,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    setIsUpdateList,
    isUpdateList,

    modalItemEdit,
    setModalItemEdit,
    isModalObjectAdd,
    setIsModalObjectAdd,
  };
};
