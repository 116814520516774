import React from "react";
import {
  ButtonIcon, Search,
} from "@vokymlak/kabinet-ui";
import { ContractPfhdTable } from "./ContractPfhdTable";
import ModalContractPfhdEdit from "./ModalContractPfhdEdit";

function ContractPfhdPage(props) {
  const {
    inputSearchText,
    onChangeSearchText,

    object,

    list,
    setList,
    count,

    setIsUpdateList,
    isUpdateList,

    modalSpecificationAdd,
    setModalSpecificationAdd,

    modalSpecificationEdit,
    setModalSpecificationEdit,

    setModalSpecificationImport,
    modalSpecificationImport,

    isNewAdded,
    isModalNewContractsDelete,
    setIsModalNewContractsDelete,
  } = props;

  return (
    !!object && (
      <>
        <div
          className="Page__title"
          style={{
            marginTop: 0,
            marginBottom: 6,
          }}
        >
          <div className="Title__text">
            <div
              className="Title__text"
              style={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
                fontSize: 19,
                fontWeight: 700,
                marginRight: 6,
              }}
            >
              <div>
                ПФХД
                {!!list && (
                  <span style={{ fontSize: 12, opacity: 0.75, marginLeft: 6 }}>
                    ({count})
                  </span>
                )}
              </div>
            </div>
            <span style={{ width: 140, marginLeft: 6, marginRight: 6 }}>
              <Search
                reference={inputSearchText}
                isFixed={true}
                label={"Поиск"}
                onChange={() => onChangeSearchText()}
              />
            </span>
          </div>

          <div className="Title__settings">
            <ButtonIcon
              tooltipText={"Обновить"}
              iconName={"custom-change-circle"}
              iconColor={"#20202075"}
              size={28}
              style={{ marginLeft: 12 }}
              onClick={() => (setList(null), setIsUpdateList(!isUpdateList))}
            />
          </div>
        </div>

        {!!list && (
          <div
            className="Page__content"
            style={{
              position: "relative",
              height: "calc(100% - 48px - 26px - 2px - 10px - 28px)",
            }}
          >
            <div
              style={{
                overflow: "auto",
                width: "100%",
                height: "100%",
              }}
            >
              <ContractPfhdTable {...props} />
            </div>
          </div>
        )}

        {!!modalSpecificationEdit && (
          <ModalContractPfhdEdit
            pfhd={modalSpecificationEdit}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalSpecificationEdit(null)}
          />
        )}
      </>
    )
  );
}

export default ContractPfhdPage;
