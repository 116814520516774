import React from "react";
import { Icon } from "@vokymlak/kabinet-ui";
import { ProcurementTasksList } from "./ProcurementTasksList";

export const ProcurementTasksTable = (props) => {
  const { item, setIsUpdateList, isUpdateList } = props;

  return (
    <table className="Table">
      <thead className="Thead">
      <tr className="Table__tr Table__tr_padding Table__tr_border">
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
            width: "0%",
          }}
        >
          <div
            className="Th__title"
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Icon name={"custom-priority"} size={12} color={"#202020"}/>
          </div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">Дата</div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">Предмет</div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">Закон</div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">Способ закупки</div>
        </th>

        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title Th__title_center">Реестровый номер</div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">Номер в ЕИС</div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">Цена договора</div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">Этап</div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">Примечание</div>
        </th>
        {/*<th*/}
        {/*  className="Table__th Table__th_border"*/}
        {/*  style={{*/}
        {/*    backgroundColor: "#dfdfdf",*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <div className="Th__title">Куратор</div>*/}
        {/*</th>*/}
        {/*<th*/}
        {/*  className="Table__th Table__th_border"*/}
        {/*  style={{*/}
        {/*    backgroundColor: "#dfdfdf",*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <div className="Th__title">Специалист</div>*/}
        {/*</th>*/}
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">Назначил</div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">Исполнитель</div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">Отметка</div>
        </th>
      </tr>
      </thead>
      <tbody>
      <ProcurementTasksList
        list={item.procurement_tasks}
        project={item}
        isRed={false}
        successfulRequest={() => setIsUpdateList(!isUpdateList)}
      />
      </tbody>
    </table>
  );
};
