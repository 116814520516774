import React from "react";
import { useParams } from "react-router-dom";

function useCalculator() {
  let { projectUuid } = useParams();

  return {};
}

export default useCalculator;
