import React, { useRef } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../config/url";
import {
  getErrorMessageByKey,
  useNotification,
  Modal,
  useObject,
  Input,
  useList, Select, Textarea, jsonFormatText, Search, TextSearchMarker, Pagination, ButtonIcon,
} from "@vokymlak/kabinet-ui";
import moment from "moment/moment";

function ModalContractItemSelect(props) {
  const getParams = () => {
    let params = null;
    params = {
      ...params,
      view: 'current',
      ris: 'true'
    };

    return params
  }

  const {
    list: [list, count, setList],
    update: [isUpdateList, setIsUpdateList, updateList],
    page: [pageNumber, setPageNumber, pageLimit, setPageLimit],
    search: [searchQuery, setSearchQuery],
  } = useList({
    apiUrl: "/api/client/project/" + props.selectProjectUuid + "/contracts",
    rootUrl: ROOT_URL,
    isPagination: true,
    paginationLimit: 250,
    isSearch: true,
    urlParams: getParams()
  });

  const changeAdd = (item) => {
        props.successfulRequest(item);
        props.handleClose();
  };

  const inputSearchText = useRef(null);
  function onChangeSearchText() {
    setSearchQuery(inputSearchText.current.value);
  }

  const changePage = (data) => {
    if (pageNumber !== data.currentPage) {
      setPageNumber(data.currentPage);
    }
  };

  return (
    !!list && (
      <Modal handleClose={() => props.handleClose()} contentStyle={{
        width: 740
      }}>
        <form className="Form">
          <div className="Form__title">
            Выбор договора
          </div>

          <div className="Form__field">
            <div className="Page__title">
              <div
                className="Title__text"
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: 21,
                  fontWeight: 700,
                }}
              >
                Договоры
                {!!list && (
                  <span style={{fontSize: 12, opacity: 0.75, marginLeft: 6}}>
                  ({count})
                </span>
                )}
                <span style={{width: 220, marginLeft: 16}}>
                <Search
                  reference={inputSearchText}
                  type="text"
                  label={"Поиск"}
                  isFixed={true}
                  onChange={() => onChangeSearchText()}
                />
              </span>
              </div>

              <div className="Title__settings">
                <ButtonIcon
                  tooltipText={"Обновить"}
                  iconName={"custom-change-circle"}
                  iconColor={"#20202075"}
                  size={28}
                  style={{marginLeft: 8}}
                  onClick={() => (setList(null), setIsUpdateList(!isUpdateList))}
                />

                {!!count && !!pageLimit && (
                  <div
                    style={{
                      marginLeft: 12,
                      width: 64,
                    }}
                  >
                    <Select
                      isFixed={true}
                      isDefaultOption={false}
                      value={pageLimit}
                      options={[
                        {
                          value: 10,
                          title: 10,
                        },
                        {
                          value: 25,
                          title: 25,
                        },
                        {
                          value: 50,
                          title: 50,
                        },
                        {
                          value: 75,
                          title: 75,
                        },
                        {
                          value: 100,
                          title: 100,
                        },
                      ]}
                      label="Лимит"
                      onChange={(event) => setPageLimit(Number(event.target.value))}
                    />
                  </div>
                )}

                {!!count && count > pageLimit && (
                  <div style={{marginLeft: 8}}>
                    <Pagination
                      totalRecords={count}
                      pageLimit={pageLimit}
                      currentPage={pageNumber}
                      onPageChanged={(data) => changePage(data)}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          <hr className={"Page__hr"} style={{marginBottom: 24}}/>

          <div className="Form__field Form__field_last"
               style={{
                 maxHeight: 250,
                 overflowY: "auto"
               }}>
            {list.map((item, index) => (
              <div key={"item_" + index} onClick={() => changeAdd(item)} style={{
                padding: "4px 8px",
                border: "1px solid #dbdbdb",
                cursor: "pointer",
                marginBottom: 6,
                borderRadius: 5,
              }}>
                <div>
                  <div>
                    {!!item.project && (
                      <div>
                        {!!item.project.name && (
                          <span
                            style={{
                              marginRight: 4,
                              fontSize: 13,
                              fontWeight: 500,
                            }}
                          >
                                    {item.project.project_year}{" "}{item.project.name}
                              </span>
                        )}
                      </div>
                    )}
                  </div>
                  <div>
                    {item.registry_number && (
                      <span style={{
                        marginRight: 6
                      }}>
                                <span style={{
                                  fontSize: 12,
                                  marginRight: 2
                                }}>№</span>
                                <TextSearchMarker
                                  text={item.registry_number + " "}
                                  search={searchQuery}
                                />
                              </span>
                    )}
                    <TextSearchMarker
                      text={item.contract_number}
                      search={searchQuery}
                    />
                  </div>
                </div>
                {!!item.subject && (
                  <div>
                      <span style={{
                        fontSize: 14
                      }}>
                                <TextSearchMarker
                                  text={item.subject}
                                  search={searchQuery}
                                />
                              </span>
                  </div>
                )}
              </div>
            ))}
          </div>

          {!!props.item && (
            <div className="Form__actions Form__actions_center">
              <div
                className="Action__link Action__link_red"
                onClick={() => (props.successfulRequest(null), props.handleClose())}
              >
                Удалить выбранный
              </div>
            </div>
          )}

          <div className="Form__actions Form__actions_center">
            <div className="Action__link" onClick={() => props.handleClose()}>
              Отмена
            </div>
          </div>
        </form>
      </Modal>
    )
  );
}

export default ModalContractItemSelect;
