import React, {useRef, useState} from "react";
import axios from "axios";
import { ROOT_URL } from "../../../../config/url";
import {
  getErrorMessageByKey,
  useNotification,
  useObject,
  useList,
  Select,
  Modal, Input, formatBytes,
} from "@vokymlak/kabinet-ui";

function ModalAddFileInFolder(props) {
  const {
    object: [object],
  } = useObject({
    apiUrl: "/api/catalog-entry/" + props.object.uuid,
    rootUrl: ROOT_URL,
    callbackError: () => {
      props.handleClose();
    },
  });

  const inputFile = useRef(null);
  const [files, setFiles] = useState(null);


  const fileSend = (event) => {
    const files = event.target.files;
    setFiles(Array.from(files));
  };


  const { notification } = useNotification();

  const changeEdit = (event) => {
    event.preventDefault();

    if (!files || (!!files && files.length === 0)) {
      notification("Прикрепите файл", "red");
      return;
    }

    if (!!files && files.filter(f => f.size > 262144000).length > 0) {
      notification("Один из файлов превышает допустимый размер в 25Мб", "red");
      return;
    }

    if (!!files && files.reduce(function(previousValue, currentValue) {
      return {
        size: previousValue.size + currentValue.size
      }
    }).size > (262144000 * 10)) {
      notification("Общий размер файлов превышает 250Мб", "red");
      return;
    }

    if (!!files && files.length > 10) {
      notification("Можно прикрепить не больше 10 файлов за раз", "red");
      return;
    }

    let formData = new FormData();
    files.forEach(f => {
      formData.append("files", f);
    })

    axios
      .post(
        "/api/catalog-entry/"+ props.object.uuid +"/add-in-folder/files",
        formData,
        {
          withCredentials: false,
          baseURL: ROOT_URL,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + localStorage.getItem("tokenSession"),
          },
        }
      )
      .then(() => {
        props.successfulRequest();
        notification("Файлы прикреплены", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    !!object && (
      <Modal handleClose={() => props.handleClose()}>
          <form className="Form" onSubmit={(event) => changeEdit(event)}>
            <div className="Form__title">
              {object.title}
              <div className="Text__signature">Добавить файлы</div>
            </div>

            <div className="Form__field">
              <Input
                reference={inputFile}
                type="file"
                label="Файлы"
                isFixed={true}
                onChange={(event) => fileSend(event)}
                isMultiple={true}
              />
            </div>

            <div className="Form__field Form__field_last">
              {!!files && files.map((file, index) => (
                <div key={'file_' + index} style={{
                  backgroundColor: "#f1f1f1",
                  padding: "6px 10px",
                  borderRadius: 5,
                  fontSize: 15,
                  marginBottom: 4,
                  marginTop: 4
                }}>
                  {file.name}
                  <div>
                    {formatBytes(file.size)}
                  </div>
                </div>
              ))}
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_blue">
                Добавить
              </button>
            </div>
          </form>
      </Modal>
    )
  );
}

export default ModalAddFileInFolder;
