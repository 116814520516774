import React, {useState} from "react";
import {Icon, Tooltip} from "@vokymlak/kabinet-ui";
import styleContent from "../../../../../styles/modules/Block.module.scss";
import ModalProjectPurchaseWayEdit from "./ModalProjectPurchaseWayEdit";

export const CalcP5Table = (props) => {
  const {
    object,
    calculations,
    setIsUpdateCalculations,
    isUpdateCalculations,
    isClient,
  } = props;

  const [modalEdit, setModalEdit] = useState(null);

  function getP5(calculations) {
    return (!!calculations.sgoz_amount &&
    Number((calculations.sgoz_amount) * 0.5) > 30_000_000_00
      ? 30_000_000_00
      : !!calculations.sgoz_amount && Number((calculations.sgoz_amount) * 0.5) < 30_000_000_00
        ? Number((calculations.sgoz_amount) * 0.5)
        : 0)
  }

  return (!!calculations && (<div className={styleContent._block + " " + styleContent._block_bottom}>
        <div className={styleContent._block__title}>п.5 ч.1 ст.93</div>
        <div className={styleContent._block__content}>
          <table className="Table">
            <thead className="Thead">
            <tr className="Table__tr Table__tr_padding Table__tr_border">
              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#fff59d",
                }}
              >
                <div className="Th__title">
                  СГОЗ
                  <Tooltip
                    text={"Сумма&nbsp;СГОЗ"}
                    style={{marginLeft: 4, height: 16}}
                  >
                    <Icon name={"custom-help"} color={"#202020"} size={16}/>
                  </Tooltip>
                </div>
              </th>
              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#fff59d",
                }}
              >
                <div className="Th__title">
                  п.5 - 44 (50%)
                  <Tooltip
                    text={"50%&nbsp;от&nbsp;СГОЗ,&nbsp;до&nbsp;30&nbsp;000&nbsp;000"}
                    style={{marginLeft: 4, height: 16}}
                  >
                    <Icon name={"custom-help"} color={"#202020"} size={16}/>
                  </Tooltip>
                </div>
              </th>
              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#fff59d",
                }}
              >
                <div className="Th__title">
                  п.5 - 44 (сумма)
                  <Tooltip
                    text={'Суммируется&nbsp;по&nbsp;"Сумма&nbsp;текущего&nbsp;года"&nbsp;(п.5)'}
                    style={{marginLeft: 4, height: 16}}
                  >
                    <Icon name={"custom-help"} color={"#202020"} size={16}/>
                  </Tooltip>
                </div>
              </th>
              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#dbdbdb",
                }}
              >
                <div className="Th__title">
                  п.5 - 44 (Переходящие)
                  {!isClient && (
                  <Tooltip
                    text={'Редактируемое&nbsp;поле для&nbsp;лимитов&nbsp;договоров, которые&nbsp;еще не&nbsp;перерегистрировали'}
                    style={{marginLeft: 4, height: 20}}
                  >
                    <Icon name={"custom-edit"} color={"#202020"} size={20}/>
                  </Tooltip>
                    )}
                </div>
              </th>
              {/*<th*/}
              {/*  className="Table__th Table__th_border"*/}
              {/*  style={{*/}
              {/*    backgroundColor: "#fff59d",*/}
              {/*  }}*/}
              {/*>*/}
              {/*  <div className="Th__title">*/}
              {/*    п.5 - 44 (сумма эл. магазина)*/}
              {/*    <Tooltip*/}
              {/*      text={*/}
              {/*        'Суммируется&nbsp;по&nbsp;"Сумма&nbsp;текущего&nbsp;года" (ЭМ&nbsp;п.5)'*/}
              {/*      }*/}
              {/*      style={{ marginLeft: 4, height: 16 }}*/}
              {/*    >*/}
              {/*      <Icon name={"custom-help"} color={"#202020"} size={16} />*/}
              {/*    </Tooltip>*/}
              {/*  </div>*/}
              {/*</th>*/}
              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#fff59d",
                }}
              >
                <div className="Th__title">
                  п.5 - 44 (разница)
                  <Tooltip
                    text={"Разница&nbsp;между&nbsp;50%&nbsp;от&nbsp;СГОЗ и&nbsp;суммой&nbsp;текущего&nbsp;года&nbsp;(п.5)"}
                    style={{marginLeft: 4, height: 16}}
                  >
                    <Icon name={"custom-help"} color={"#202020"} size={16}/>
                  </Tooltip>
                </div>
              </th>
            </tr>
            </thead>
            <tbody>
            <tr
              className={"Table__tr Table__tr_padding Table__tr_border Table__tr_hover "}
            >
              <td
                className="Table__td Table__td_border"
                style={{
                  backgroundColor: "#fff59d50",
                }}
              >
                <div className="Td__text Td__text_center">
                  {(!!calculations.sgoz_amount &&
                    new Intl.NumberFormat("ru-RU").format(Number(calculations.sgoz_amount / 100).toFixed(2))) || "-"}
                </div>
              </td>
              <td
                className="Table__td Table__td_border"
                style={{
                  backgroundColor: "#fff59d50",
                }}
              >
                <div className="Td__text Td__text_center">
                  {(!!getP5(calculations) &&
                    new Intl.NumberFormat("ru-RU").format((getP5(calculations) / 100).toFixed(2))) || "-"}
                </div>
              </td>
              <td
                className="Table__td Table__td_border"
                style={{
                  backgroundColor: "#fff59d50",
                }}
              >
                <div className="Td__text Td__text_center">
                  {(!!calculations.p5_starting_price &&
                    new Intl.NumberFormat("ru-RU").format(Number((calculations.p5_starting_price) / 100).toFixed(2))) || "-"}
                </div>
              </td>
              <td
                className="Table__td Table__td_border"
                style={{
                  backgroundColor: "#dbdbdb50", cursor: "pointer"
                }}
                onClick={!isClient ? () => setModalEdit({
                  project: object, purchaseWay: calculations.p5_purchase_way, amount: calculations.p5_transition_price
                }) : () => null}
              >
                <div className="Td__text Td__text_center">
                  {(!!calculations.p5_transition_price &&
                    new Intl.NumberFormat("ru-RU").format(Number(calculations.p5_transition_price / 100).toFixed(2))) || "-"}
                </div>
              </td>
              {/*<td*/}
              {/*  className="Table__td Table__td_border"*/}
              {/*  style={{*/}
              {/*    backgroundColor: "#fff59d50",*/}
              {/*  }}*/}
              {/*>*/}
              {/*  <div className="Td__text Td__text_center">*/}
              {/*    {(!!calculations.p5_e_shop_starting_price &&*/}
              {/*      new Intl.NumberFormat("ru-RU").format(*/}
              {/*        Number(*/}
              {/*          calculations.p5_e_shop_starting_price / 100*/}
              {/*        ).toFixed(2)*/}
              {/*      )) ||*/}
              {/*      "-"}*/}
              {/*  </div>*/}
              {/*</td>*/}
              <td
                className="Table__td Table__td_border"
                style={{
                  backgroundColor: "#fff59d50",
                }}
              >
                <div className="Td__text Td__text_center">
                  {(!!getP5(calculations) &&
                    new Intl.NumberFormat("ru-RU").format(((getP5(calculations) - calculations.p5_starting_price - calculations.p5_transition_price) / 100).toFixed(2))) || "-"}
                </div>
              </td>
            </tr>
            </tbody>
          </table>

          <div style={{
            marginTop: 8, fontSize: 14,
          }}>
            <p>
              <b>СГОЗ</b> - сумма СГОЗ
            </p>
            <p>
              <b>п.5 - 44 (50%)</b> - 50% от СГОЗ, до 30&nbsp;000&nbsp;000
            </p>
            <p>
              <b>п.5 - 44 (сумма)</b> - суммируется по "Сумма&nbsp;текущего&nbsp;года" (п.5)
            </p>
            <p>
              <b>п.5 - 44 (разница)</b> - разница между 50% от СГОЗ и суммой текущего года (п.5)
            </p>
          </div>
        </div>

        {!!modalEdit && (<ModalProjectPurchaseWayEdit
            project={modalEdit.project}
            purchaseWay={modalEdit.purchaseWay}
            amount={modalEdit.amount}
            successfulRequest={() => (setIsUpdateCalculations(!isUpdateCalculations))}
            handleClose={() => setModalEdit(null)}
          />)}
      </div>));
};
