import React from 'react'
import './Modal.css'

function Modal(props) {
  return (
    <div className="Modal">
      <i />
      <div
        className="Modal__mask"
        onClick={props.handleClose && props.handleClose()}
      />
        <div className="Modal__dialog">
          <div
            className="Modal__content"
            style={props.contentStyle && props.contentStyle}
          >
            <div className="Modal__body">
              {props.children && props.children}
            </div>
          </div>
        </div>
      <i />
    </div>
  )
}

export default Modal
