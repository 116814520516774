import React, {Fragment, useState} from "react";
import {Icon,} from "@vokymlak/kabinet-ui";
import ModalPlanAdd from "./ModalPlanAdd";
import ModalPlanEdit from "./ModalPlanEdit";

function ProjectPfhdContractTablePlan(props) {
  const {
    object
  } = props;
  
  const [isModalPlanAdd, setIsModalPlanAdd] = useState(false);
  const [modalPlanEdit, setModalPlanEdit] = useState(null);

  return (
    !!object &&
      <Fragment>
    <table className="Table">
      <thead className="Thead">
      <tr className="Table__tr Table__tr_padding Table__tr_border">
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#bbdefb",
          }}
          colSpan={4}
        >
          <div className="Th__title" style={{height: 16}}>
            План
          </div>
        </th>
      </tr>

      <tr className="Table__tr Table__tr_padding Table__tr_border">
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#bbdefb",
            height: 38
          }}
        >
          <div className="Th__title" style={{height: 16}}>
            <div style={{
              cursor: "pointer"
            }}
            onClick={() => setIsModalPlanAdd(true)}>
            <Icon
              name={"custom-add"}
              size={20}
              color={"#0061fc"}
            />
            </div>
          </div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#bbdefb",
          }}
        >
          <div className="Th__title">Предмет договора</div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#bbdefb",
          }}
        >
          <div className="Th__title">Контрагент</div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#bbdefb",
          }}
        >
          <div className="Th__title">Сумма</div>
        </th>
      </tr>
      </thead>
      <tbody>

      {!!object.pfhd_plans && object.pfhd_plans.map(item => (
        <tr
          className={
            "Table__tr Table__tr_padding Table__tr_border"
          }
          key={"item_" + item.uuid}
        >
          <td
            className="Table__td Table__td_border"
            style={{
              backgroundColor: "#bbdefb50",
            }}
          >
            <div
              className="Td__text Td__text_center"
              style={{height: 24, cursor: "pointer"}}
              onClick={() => setModalPlanEdit(item)}
            >
              <Icon
                name={"custom-edit"}
                size={24}
                color={"#202020"}
              />
            </div>
          </td>

          <td
            className="Table__td Table__td_border"
            style={{
              backgroundColor: "#bbdefb50",
            }}
          >
            <div className="Td__text">
              {(!!item.subject &&
                item.subject) || "-"}
            </div>
          </td>

          <td
            className="Table__td Table__td_border"
            style={{
              backgroundColor: "#bbdefb50",
            }}
          >
            <div className="Td__text Td__text_center">
              {(!!item.supplier &&
                item.supplier) || "-"}
            </div>
          </td>

          <td
            className="Table__td Table__td_border"
            style={{
              backgroundColor: "#bbdefb50",
            }}
          >
            <div className="Td__text Td__text_center">
              {(!!item.amount &&
                  new Intl.NumberFormat("ru-RU").format(
                    (item.amount / 100).toFixed(2)
                  )) ||
                "0,00"}
            </div>
          </td>
        </tr>
      ))}

      <tr
        className={
          "Table__tr Table__tr_padding Table__tr_border"
        }
      >
        <td
          className="Table__td Table__td_border"
          style={{
            backgroundColor: "#bbdefb50",
          }}
          colSpan={2}
        >
        </td>

        <td
          className="Table__td Table__td_border"
          style={{
            backgroundColor: "#bbdefb50",
          }}
        >
          <div className="Td__text Td__text_center">
            Сумма
          </div>
        </td>

        <td
          className="Table__td Table__td_border"
          style={{
            backgroundColor: "#bbdefb50",
          }}
        >
          <div className="Td__text Td__text_center">
            {!!object.pfhd_plans && new Intl.NumberFormat("ru-RU").format(
              (object.pfhd_plans.reduce((sum, item) => sum + (item.amount || 0), 0) / 100).toFixed(2)
            )}
          </div>
        </td>
      </tr>

      <tr
        className={
          "Table__tr Table__tr_padding Table__tr_border"
        }
      >
        <td
          className="Table__td Table__td_border"
          style={{
            backgroundColor: "#bbdefb50",
          }}
          colSpan={2}
        >
        </td>

        <td
          className="Table__td Table__td_border"
          style={{
            backgroundColor: "#bbdefb50",
          }}
        >
          <div className="Td__text Td__text_center">
            Остаток
          </div>
        </td>

        <td
          className="Table__td Table__td_border"
          style={{
            backgroundColor: "#bbdefb50",
          }}
        >
          <div className="Td__text Td__text_center">
            {(!!object.amount && !!object.pfhd_plans &&
              new Intl.NumberFormat("ru-RU").format(
                ((object.amount - object.pfhd_plans.reduce((sum, item) => sum + (item.amount || 0), 0)) / 100).toFixed(2)
              )) || "-"}
          </div>
        </td>
      </tr>

      </tbody>
    </table>


        {!!isModalPlanAdd && (
          <ModalPlanAdd
            object={object}
            successfulRequest={() => props.setIsUpdateList(!props.isUpdateList)}
            handleClose={() => setIsModalPlanAdd(false)}
          />
        )}
        {!!modalPlanEdit && (
          <ModalPlanEdit
            item={modalPlanEdit}
            object={object}
            successfulRequest={() => props.setIsUpdateList(!props.isUpdateList)}
            handleClose={() => setModalPlanEdit(null)}
          />
        )}

      </Fragment>
  );
}

export default ProjectPfhdContractTablePlan;
