import React, {useState} from "react";
import styleContent from "../../../../../../styles/modules/Block.module.scss";
import ModalActItemEdit from "./ModalActItemEdit";
import moment from "moment";
import {TextSearchMarker, Tooltip} from "@vokymlak/kabinet-ui";

function ActItemsList(props) {
  const {act, object, setIsUpdateList, isUpdateList, searchQuery} = props

  const [modalActItemEdit, setModalActItemEdit] = useState(null)

  return (
    <>
      {!!act && !!act.items && !!act.items.length > 0 && (
        <>
          <div
            className={styleContent._block + " " + styleContent._block_bottom}
          >
            <div className={styleContent._block__title} style={{
              opacity: 1
            }}>
              <div
                style={{
                  fontSize: 19,
                  fontWeight: 900,
                }}
              >
                <span style={{opacity: .5}}>Позиции</span>

                <span style={{ fontSize: 12, opacity: 0.75, marginLeft: 6 }}>
                ({act.items.length})
              </span>

                <span style={{
                  marginLeft: 12,
                  fontSize: 21,
                }}>
                  {(new Intl.NumberFormat("ru-RU").format(
                      (Number(act.items.map(a => ((!!a.volume && a.volume) || 0)).reduce((a, b) => a + b, 0)
                      ) / 1000).toFixed(3)
                    )) ||
                    "-"}
                  <span
                    style={{
                      marginLeft: 3,
                      fontSize: 12,
                      fontWeight: 400,
                      opacity: 0.75,
                    }}
                  >
                            (объём)
                          </span>

                        </span>

                <span style={{
                  marginLeft: 12,
                  fontSize: 21,
                }}>
                  {(new Intl.NumberFormat("ru-RU").format(
                      (Number(act.items.map(a => (((!!a.amount && a.amount) || 0) + ((!!a.amount_vat && a.amount_vat) || 0))).reduce((a, b) => a + b, 0)
                      ) / 100).toFixed(2)
                    )) ||
                    "-"}
                  <span
                    style={{
                      marginLeft: 2,
                      fontSize: 12,
                      fontWeight: 400,
                      opacity: 0.75,
                    }}
                  >
                            руб.
                          </span>

                        </span>

              </div>
            </div>
            <div className={styleContent._block__content}>
              <table className="Table">
                <tbody>
                  {act.items.map((item) => (
                    <tr
                      className="Table__tr Table__tr_hover"
                      key={props.act.uuid + ":" + item.uuid}
                      onClick={() => setModalActItemEdit(item)}
                      style={{ cursor: "pointer" }}
                    >
                      <td className="Table__td">
                        <div
                          className="Td__title"
                          style={{
                            fontSize: 19,
                            fontWeight: 700,
                          }}
                        >
                          {(!!item.contract_specification && (
                            item.contract_specification.registry_index + " - " +
                            item.contract_specification.registry_number + " - " +
                            item.contract_specification.spgz)) || "-"}
                        </div>
                        <div style={{
                          fontSize: 13,
                          fontWeight: 400
                        }}>
                          <Tooltip
                            text={"Реестровый номер"}
                            style={{width: "100%", height: "100%"}}
                          >
                            <div className="Td__text" style={{
                              fontWeight: 400
                            }}>
                              {(!!item.registry_number && (
                                  <TextSearchMarker
                                    text={item.registry_number + " (реестровый номер)"}
                                    search={searchQuery}
                                  />
                                )) ||
                                "-"}
                            </div>
                            <div className="Td__text" style={{
                              fontSize: 10,
                              fontWeight: 400
                            }}>
                              {(!!item.ris_id && item.ris_id) ||
                                "-"} / {(!!item.ris_entity_id && item.ris_entity_id) ||
                              "-"}
                            </div>
                          </Tooltip>
                        </div>
                        <div style={{
                          fontSize: 13,
                          fontWeight: 400
                        }}>
                          {!!item.updated_at
                            ? moment(item.updated_at).format("DD.MM.YYYY")
                            : moment(item.created_at).format("DD.MM.YYYY")}
                          &nbsp;(обновлено)
                        </div>
                      </td>

                      <td className="Table__td">
                        <div
                          className="Td__title"
                        >
                          <div style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center"
                          }}>
                            <div style={{
                              padding: "4px 8px",
                              backgroundColor: "#ffffff75",
                              borderRadius: 4,
                              marginLeft: 12,
                              textAlign: "right"
                            }}>
                              <div style={{
                                fontSize: 21,
                                fontWeight: 700,
                              }}>
                                {(!!item.volume && new Intl.NumberFormat("ru-RU").format(
                                    (item.volume / 1000).toFixed(3)
                                  )) ||
                                  "-"}
                              </div>
                              <div style={{
                                fontSize: 10,
                                fontWeight: 400,
                                opacity: .75,
                              }}>Объём</div>
                            </div>
                            <div style={{
                              padding: "4px 8px",
                              backgroundColor: "#ffffff75",
                              borderRadius: 4,
                              marginLeft: 12,
                              textAlign: "right"
                            }}>
                              <div style={{
                                fontSize: 21,
                                fontWeight: 700,
                              }}>
                                {(!!item.amount && new Intl.NumberFormat("ru-RU").format(
                                    (item.amount / 100).toFixed(2)
                                  )) ||
                                  "-"}
                              </div>
                              <div style={{
                                fontSize: 10,
                                fontWeight: 400,
                                opacity: .75,
                              }}>Сумма</div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          {!!modalActItemEdit && (
            <ModalActItemEdit
              act={act}
              actItem={modalActItemEdit}
              contract={object}
              successfulRequest={() => setIsUpdateList(!isUpdateList)}
              handleClose={() => setModalActItemEdit(null)}
            />
          )}
        </>
      )}
    </>
  );
}

export default ActItemsList;
