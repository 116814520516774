import React, {useEffect} from "react";
import {ROOT_URL} from "../../../../config/url";
import {NavLink} from "react-router-dom";
import {useAction, useList} from "@vokymlak/kabinet-ui";
import styleContent from "../../../../styles/modules/Block.module.scss";

export const EmployeeProjectsList = (props) => {
  const {
    list: [list],
    update: [isUpdateList, setIsUpdateList, changeUpdate],
  } = useList({
    apiUrl: "/api/employee/" + props.employee.uuid + "/projects",
    rootUrl: ROOT_URL,
  });

  useEffect(() => {
    setIsUpdateList(!isUpdateList);
  }, [props.employee]);

  const {setAction} = useAction(["employees", "projects"], () =>
    changeUpdate()
  );

  useEffect(() => {
    if (!!list) {
      const listUuid = list.map((i) => i.uuid);
      setAction([...listUuid, "employees", "projects"]);
    } else {
      setAction("employees", "projects");
    }
  }, [list]);

  return (
    <div>
      {!!list && list.length === 0 && (
        <div style={{
          opacity: .25,
          fontSize: 21,
          fontWeight: 400,
          width: "100%"
        }}>
          Нет привязанных проектов
        </div>
      )}

      {!!list && list.length > 0 && (
        <>
          <div
            className={styleContent._block + " " + styleContent._block_bottom}
          >
            <div className={styleContent._block__title}>
              Проекты{" "}
              {"(" + list.length + ")"}
            </div>
            <div className={styleContent._block__content}>
              <table className="Table">
                <tbody>
                {list.map((project) => (
                  <tr
                    className="Table__tr Table__tr_hover"
                    key={props.employee.uuid + ":" + project.uuid}
                    style={{cursor: "pointer"}}
                  >
                    <td
                      className="Table__td"
                      style={{
                        padding: "6px 8px",
                      }}
                    >
                      <NavLink
                        to={"/project/" + project.uuid + "/contracts"}
                        style={{width: "100%"}}
                      >
                        <div className="Td__title">
                          <div
                            style={{
                              fontSize: 19,
                              fontWeight: 700,
                            }}
                          >
                            {project.name}
                            <span
                              style={{
                                marginLeft: 4,
                                fontSize: 13,
                                opacity: 0.5,
                              }}
                            >
                          {!!project &&
                            !!project.law &&
                            " (" + project.law.tag + ")"}
                        </span>
                          </div>
                          {!!project.company && (
                            <div
                              style={{
                                fontSize: 13,
                                fontWeight: 500,
                              }}
                            >
                              {project.company.short_name}
                            </div>
                          )}
                        </div>
                      </NavLink>
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
