import axios from "axios";
import { useEffect, useState } from "react";
import {getErrorMessageByKey, useNotification} from "@vokymlak/kabinet-ui";

function useObject(apiUrl, rootUrl) {
  const [object, setObject] = useState(null);
  const [isUpdateObject, setIsUpdateObject] = useState(false);

  const { notification } = useNotification();

  useEffect(() => {
    return () => {
      setObject(null);
    };
  }, []);

  useEffect(() => {
    axios
      .get(apiUrl, {
        withCredentials: false,
        baseURL: rootUrl,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then((response) => {
        setObject(response.data ? response.data : null);
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  }, [isUpdateObject]);

  return {
    object: [object, setObject],
    update: [isUpdateObject, setIsUpdateObject],
  };
}

export default useObject;
