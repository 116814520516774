import React, {useEffect, useRef, useState} from "react";
import { ROOT_URL } from "../../../config/url";
import { useAction, useUnroll, useList } from "@vokymlak/kabinet-ui";

function useMyMarkContracts() {
  const [selectProjectUuid, setSelectProjectUuid] = useState('null')

  const {
    list: [projects],
  } = useList(
    {
      apiUrl: "/api/client/projects/list",
      rootUrl: ROOT_URL,
    });

  const getParams = () => {
    let params = null;
    params = {
      ...params,
      view: 'current',
    };
    if (!!selectProjectUuid && selectProjectUuid !== "null") {
      params = {
        ...params,
        project: selectProjectUuid,
      };
    }

    return params
  }

  const {
    list: [list, count, setList],
    update: [isUpdateList, setIsUpdateList, updateList],
    page: [pageNumber, setPageNumber, pageLimit, setPageLimit],
    search: [searchQuery, setSearchQuery],
    setApiUrlParams
  } = useList({
    apiUrl: "/api/client/projects/contracts",
    rootUrl: ROOT_URL,
    isPagination: true,
    paginationLimit: 250,
    isSearch: true,
    urlParams: getParams()
  });

  const inputSearchText = useRef(null);
  useEffect(() => {
    setPageNumber(1);
  }, [searchQuery]);
  const onChangeSearchText = () => {
    setSearchQuery(inputSearchText.current.value);
  };
  const changePage = (data) => {
    if (pageNumber !== data.currentPage) {
      setPageNumber(data.currentPage);
    }
  };

  useEffect(() => {
    setApiUrlParams({ ...getParams() });
  }, [selectProjectUuid]);

  const { unrolls, isUnroll, changeUnroll, changeAllUnroll } = useUnroll();

  return {
    projects,

    list,
    setList,
    count,

    pageNumber,
    pageLimit,
    setPageLimit,
    changePage,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    inputSearchText,
    onChangeSearchText,
    searchQuery,

    setIsUpdateList,
    isUpdateList,
    updateList,

    setSelectProjectUuid,
  };
}

export default useMyMarkContracts;
