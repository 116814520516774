import React, { useState } from "react";
import { ROOT_URL } from "../../../config/url";
import useList from "../../../hooks/useList";
import {useAction, useUnroll} from "@vokymlak/kabinet-ui";
import {getErrorMessageByKey} from "@vokymlak/kabinet-ui";
import axios from "axios";
import {useNotification} from "@vokymlak/kabinet-ui";

function usePurchaseBasis() {
  const {
    list: [list, setList],
    update: [isUpdateList, setIsUpdateList],
  } = useList("/api/purchase-basis-ways", ROOT_URL);

  const { unrolls, isUnroll, changeUnroll, changeAllUnroll } = useUnroll();

  const [isModalObjectAdd, setIsModalObjectAdd] = useState(false);
  const [modalItemEdit, setModalItemEdit] = useState(null);
  const [modalObjectItemAdd, setModalObjectItemAdd] = useState(null);
  const [isDrag, setIsDrag] = useState(false);

  useAction("isUpdate", () => setIsUpdateList(!isUpdateList));

  const { notification } = useNotification();

  const updateList = (item) => {
    if (!!list) {
      const updateList = [...list];
      updateList.map((a, i) => {
        if (a.uuid === item.uuid) {
          updateList[i] = { ...item };
        }
      });
      setList(!!updateList ? updateList : null);
    }
  };

  const deletePurchaseBaseWay = (baseWayUuid) => {
    axios
      .delete("/api/purchase-base-way/" + baseWayUuid + "/delete", {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        setIsUpdateList(!isUpdateList);
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const onDragStart = () => {
    setIsDrag(true);
  };

  const onDragEnd = (result) => {
    setIsDrag(false);

    if (result.destination) {
      const oldDroppableId = result.source.droppableId;
      const newDroppableId = result.destination.droppableId;

      const oldIndex = result.source.index;
      const newIndex = result.destination.index;
      const objectId = result.draggableId;

      if (oldDroppableId !== newDroppableId) {
        return;
      }

      if (!!list) {
        const listArray = [...list];
        let object = null;
        let array = null;

        listArray.map((item) => {
          if (oldDroppableId === item.uuid) {
            if (!!item.ways) {
              array = [...item.ways];
              item.ways.map((way) => {
                if (objectId === way.uuid) {
                  object = { ...way };
                }
              });
            }
          }
        });

        if (!!array) {
          array = dndArray(array, oldIndex, newIndex);
        }

        listArray.map((item) => {
          if (oldDroppableId === item.uuid) {
            if (!!item.ways) {
              item.ways = [...array];
            }
          }
        });

        setList([...listArray]);

        axios
          .get("/api/purchase-base-way/" + object.uuid + "/index/" + newIndex, {
            withCredentials: false,
            baseURL: ROOT_URL,
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + localStorage.getItem("tokenSession"),
            },
          })
          .then(() => {
            setIsUpdateList(!isUpdateList);
          })
          .catch(() => {
            setIsUpdateList(!isUpdateList);
          });
      }
    }
  };

  const dndArray = (arr, old_index, new_index) => {
    if (new_index >= arr.length) {
      let k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  };

  return {
    list,
    setList,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    setIsUpdateList,
    isUpdateList,

    modalItemEdit,
    setModalItemEdit,
    isModalObjectAdd,
    setIsModalObjectAdd,
    modalObjectItemAdd,
    setModalObjectItemAdd,

    updateList,

    deletePurchaseBaseWay,

    onDragEnd,
    onDragStart,
    isDrag,
  };
}

export default usePurchaseBasis;
