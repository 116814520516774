import React from "react";
import { ROOT_URL } from "../../../../../config/url";
import { useAction, useObject } from "@vokymlak/kabinet-ui";

function useProjectCalcContracts(props) {
  const {
    object: [calculations],
    update: [_isUpdateCalculations, _setIsUpdateCalculations, updateCalculations],
  } = useObject({
    apiUrl: "/api/client/project/" + props.project.uuid + "/calculations",
    rootUrl: ROOT_URL,
  });

  useAction(props.project.uuid, () => updateCalculations());

  return {
    object: props.project,

    calculations,
  };
}

export default useProjectCalcContracts;
