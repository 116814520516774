import React from 'react'
import InputView from './InputView'
import useInput from './useInput'

function Input(props) {
  return (
    <InputView {...useInput(props)} />
  )
}

export default Input
