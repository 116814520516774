import React, { useState } from "react";
import {
  Modal,
  Icon,
} from "@vokymlak/kabinet-ui";
import ModalAppealAdd from "./ModalAppealAdd";

function ModalAppealView(props) {
  const [modalObjectNew, setModalObjectNew] = useState(null);

  const getIconNameAppeal = (tag) => {
    switch (tag) {
      // case "INCLUSION_IN_THE_PROCUREMENT_PLAN_OR_SCHEDULE":
      //   return "line-event";
      case "INCLUSION_IN_THE_PROCUREMENT_PLAN_OR_SCHEDULE":
        return "line-free-cancellation";

      case "CONTRACT_AGREEMENT_OR_SUPPLEMENTARY_AGREEMENT":
        return "line-task";

      case "COORDINATION_OF_INDEPENDENT_GUARANTEES":
        return "line-task-alt";

      case "DOCUMENTS_FOR_PURCHASE":
        return "line-receipt-long";

      case "REGISTRATION_AGREEMENT":
        return "line-lab-profile";

      case "SUPPLEMENTARY_AGREEMENT_TO_REGISTRATION":
        return "line-note-stack-add";

      case "REGISTRATION_FORMALIZATION":
        return "line-contract";

      case "ONE_MORE_QUESTION":
        return "line-help";
      // case "ONE_MORE_QUESTION":
      //   return "line-unknown-document";
    }
    return null;
  }


  return (
    <>
    <Modal handleClose={() => props.handleClose()}
           dialogStyle={{

    }}
           contentStyle={{
  boxShadow: "none",
             width: 640,
             padding: 0
    }}>
      <div className="Form" style={{
        padding: 24
      }}>
        <div className="Form__title" style={{
          fontWeight: 600
        }}>
          Подать новое обращение
        </div>

        <ul className="List" style={{
          marginBottom: 0
        }}>
          <li
            className={"List__item pointer pointer_border"}
            onClick={() => setModalObjectNew("INCLUSION_IN_THE_PROCUREMENT_PLAN_OR_SCHEDULE")}
          >
            <div className="Item__title">
              <div className="Element__title" style={{
                alignItems: "flex-start"
              }}>
                <div style={{
                  padding: "2px 4px 0 0"
                }}>
                  <Icon size={32} name={getIconNameAppeal("INCLUSION_IN_THE_PROCUREMENT_PLAN_OR_SCHEDULE")} />
                </div>

                <div style={{ marginLeft: 8, fontSize: 17, fontWeight: 600 }}>
                  Включение в план закупки / план график
                  <div
                    style={{
                      fontSize: 14,
                      opacity: 0.75,
                      fontWeight: 400
                    }}
                  >
                    Направление проекта контракта и источников финансирования для включения
                  </div>
                </div>
              </div>
            </div>
            <div className="Item__settings">
              <div className="Element__datetime"></div>
            </div>
          </li>

          <li
            className={"List__item pointer pointer_border"}
            onClick={() => setModalObjectNew("CONTRACT_AGREEMENT_OR_SUPPLEMENTARY_AGREEMENT")}
          >
            <div className="Item__title">
              <div className="Element__title" style={{
                alignItems: "flex-start"
              }}>
                <div style={{
                  padding: "2px 4px 0 0"
                }}>
                  <Icon size={32} name={getIconNameAppeal("CONTRACT_AGREEMENT_OR_SUPPLEMENTARY_AGREEMENT")} />
                </div>

                <div style={{ marginLeft: 8, fontSize: 17, fontWeight: 600 }}>
                  Согласование договора / дополнительного соглашения
                  <div
                    style={{
                      fontSize: 14,
                      opacity: 0.75,
                      fontWeight: 400
                    }}
                  >
                    Направление проекта договора, проекта дополнительного соглашения для правовой проверки юристом
                  </div>
                </div>
              </div>
            </div>
            <div className="Item__settings">
              <div className="Element__datetime"></div>
            </div>
          </li>

          <li
            className={"List__item pointer pointer_border"}
            onClick={() => setModalObjectNew("COORDINATION_OF_INDEPENDENT_GUARANTEES")}
          >
            <div className="Item__title">
              <div className="Element__title" style={{
                alignItems: "flex-start"
              }}>
                <div style={{
                  padding: "2px 4px 0 0"
                }}>
                  <Icon size={32} name={getIconNameAppeal("COORDINATION_OF_INDEPENDENT_GUARANTEES")} />
                </div>

                <div style={{ marginLeft: 8, fontSize: 17, fontWeight: 600 }}>
                  Согласование независимых гарантий
                  <div
                    style={{
                      fontSize: 14,
                      opacity: 0.75,
                      fontWeight: 400
                    }}
                  >

                    Направление проекта независимой гарантии для правовой проверки юристом
                  </div>
                </div>
              </div>
            </div>
            <div className="Item__settings">
              <div className="Element__datetime"></div>
            </div>
          </li>

          <li
            className={"List__item pointer pointer_border"}
            onClick={() => setModalObjectNew("DOCUMENTS_FOR_PURCHASE")}
          >
            <div className="Item__title">
              <div className="Element__title" style={{
                alignItems: "flex-start"
              }}>
                <div style={{
                  padding: "2px 4px 0 0"
                }}>
                  <Icon size={32} name={getIconNameAppeal("DOCUMENTS_FOR_PURCHASE")} />
                </div>

                <div style={{ marginLeft: 8, fontSize: 17, fontWeight: 600 }}>
                  Документы для закупки
                  <div
                    style={{
                      fontSize: 14,
                      opacity: 0.75,
                      fontWeight: 400
                    }}
                  >
                    Направление потребности и документов для проведения закупочной процедуры.<br/>
                    Необходимые документы:<br/>
                    -техническое задание;<br/>
                    -расчет начально-максимальной стоимости договора;<br/>
                    -коммерческие предложения;<br/>
                    -источники финансирования;<br/>
                    -проект договора.<br/>
                  </div>
                </div>
              </div>
            </div>
            <div className="Item__settings">
              <div className="Element__datetime"></div>
            </div>
          </li>

          <li
            className={"List__item pointer pointer_border"}
            onClick={() => setModalObjectNew("REGISTRATION_AGREEMENT")}
          >
            <div className="Item__title">
              <div className="Element__title" style={{
                alignItems: "flex-start"
              }}>
                <div style={{
                  padding: "2px 4px 0 0"
                }}>
                  <Icon size={32} name={getIconNameAppeal("REGISTRATION_AGREEMENT")} />
                </div>

                <div style={{ marginLeft: 8, fontSize: 17, fontWeight: 600 }}>
                  Договор на регистрацию
                  <div
                    style={{
                      fontSize: 14,
                      opacity: 0.75,
                      fontWeight: 400
                    }}
                  >
                    Направление подписанного документа для регистрации с приложенными источниками финансирования
                  </div>
                </div>
              </div>
            </div>
            <div className="Item__settings">
              <div className="Element__datetime"></div>
            </div>
          </li>

          <li
            className={"List__item pointer pointer_border"}
            onClick={() => setModalObjectNew("SUPPLEMENTARY_AGREEMENT_TO_REGISTRATION")}
          >
            <div className="Item__title">
              <div className="Element__title" style={{
                alignItems: "flex-start"
              }}>
                <div style={{
                  padding: "2px 4px 0 0"
                }}>
                  <Icon size={32} name={getIconNameAppeal("SUPPLEMENTARY_AGREEMENT_TO_REGISTRATION")} />
                </div>

                <div style={{ marginLeft: 8, fontSize: 17, fontWeight: 600 }}>
                  Дополнительное соглашение на регистрацию
                  <div
                    style={{
                      fontSize: 14,
                      opacity: 0.75,
                      fontWeight: 400
                    }}
                  >
                    Направление подписанного документа для регистрации
                  </div>
                </div>
              </div>
            </div>
            <div className="Item__settings">
              <div className="Element__datetime"></div>
            </div>
          </li>

          <li
            className={"List__item pointer pointer_border"}
            onClick={() => setModalObjectNew("REGISTRATION_FORMALIZATION")}
          >
            <div className="Item__title">
              <div className="Element__title" style={{
                alignItems: "flex-start"
              }}>
                <div style={{
                  padding: "2px 4px 0 0"
                }}>
                  <Icon size={32} name={getIconNameAppeal("REGISTRATION_FORMALIZATION")} />
                </div>

                <div style={{ marginLeft: 8, fontSize: 17, fontWeight: 600 }}>
                  Исполнение на регистрацию
                  <div
                    style={{
                      fontSize: 14,
                      opacity: 0.75,
                      fontWeight: 400
                    }}
                  >
                    Направление актов выполненных работ / товарных накладных / УПД / платежных поручений на размещение
                  </div>
                </div>
              </div>
            </div>
            <div className="Item__settings">
              <div className="Element__datetime"></div>
            </div>
          </li>

          <li
            className={"List__item pointer pointer_border"}
            onClick={() => setModalObjectNew("ONE_MORE_QUESTION")}
            style={{
              marginBottom: 2
            }}
          >
            <div className="Item__title">
              <div className="Element__title" style={{
                alignItems: "flex-start"
              }}>
                <div style={{
                  padding: "2px 4px 0 0"
                }}>
                  <Icon size={32} name={getIconNameAppeal("ONE_MORE_QUESTION")} />
                </div>

                <div style={{ marginLeft: 8, fontSize: 17, fontWeight: 600 }}>
                  Иной вопрос
                  <div
                    style={{
                      fontSize: 14,
                      opacity: 0.75,
                      fontWeight: 400
                    }}
                  >
                    Вопросы иного характера
                  </div>
                </div>
              </div>
            </div>
            <div className="Item__settings">
              <div className="Element__datetime"></div>
            </div>
          </li>
        </ul>

        <div className="Form__actions Form__actions_center">
          <div className="Action__link" onClick={() => props.handleClose()}>
            Закрыть
          </div>
        </div>
      </div>
    </Modal>

      {!!modalObjectNew && (
        <ModalAppealAdd
          type={modalObjectNew}
          successfulRequest={props.successfulRequest}
          handleClose={() => (setModalObjectNew(null))}
        />
      )}
      </>
  );
}

export default ModalAppealView;
