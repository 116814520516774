import React, { useRef } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../../../config/url";
import {
  getErrorMessageByKey,
  useNotification,
  Modal,
  useObject,
  Input,
  jsonFormatText, useList, Select,
} from "@vokymlak/kabinet-ui";
import moment from "moment";

function ModalPaymentAdd(props) {
  const {
    list: [listStages],
  } = useList({ apiUrl: "/api/contract/" + props.contract.uuid + "/stages", rootUrl: ROOT_URL });
  const {
    list: [listActs],
  } = useList({ apiUrl: "/api/contract/" + props.contract.uuid + "/acts", rootUrl: ROOT_URL });

  const {
    object: [contract],
  } = useObject({
    apiUrl: "/api/contract/" + props.contract.uuid,
    rootUrl: ROOT_URL,
    callbackError: () => {
      props.handleClose();
    },
  });

  const inputDateReceipt = useRef(null);

  const inputDate = useRef(null);
  const inputNumber = useRef(null);

  const inputAmount = useRef(null);


  const selectContractStageId = useRef(null);
  const selectContractActId = useRef(null);

  const { notification } = useNotification();

  const numberConvert = (numberString) => {
    return !!numberString && numberString.length > 0
      ? Number(String(Number(numberString).toFixed(2)).replace(".", ""))
      : null;
  };

  const changeAdd = (event) => {
    event.preventDefault();

    const dateReceipt = inputDateReceipt.current.value;

    const date = inputDate.current.value;
    const number = jsonFormatText(inputNumber.current.value);
    const amount = inputAmount.current.value;

    const contractStageId = selectContractStageId.current.value;
    const contractActId = selectContractActId.current.value;

    const jsonData = JSON.stringify({
      date_receipt: !!dateReceipt && dateReceipt.length > 0 ? dateReceipt : null,

      date_number:
        !!date && date.length > 0 ? date : null,
      number: number,
      amount: numberConvert(amount),

      contract_stage_id:
        contractStageId.length > 0
          ? contractStageId === "null"
            ? null
            : Number(contractStageId)
          : null,
      contract_act_id:
        contractActId.length > 0
          ? contractActId === "null"
            ? null
            : Number(contractActId)
          : null,
    });

    axios
      .post("/api/contract/" + props.contract.uuid + "/payment/add", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Платёж добавлен", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const getContractStageSelectOptions = (list) => {
    const options = [
      {
        value: "null",
        title: "Не выбрано",
      },
    ];

    list.map((item) => {
      const option = {
        value: item.id,
        title: item.name,
      };

      options.push(option);
    });

    return options;
  };

  const getContractActSelectOptions = (list) => {
    const options = [
      {
        value: "null",
        title: "Не выбрано",
      },
    ];

    list.map((item) => {
      const option = {
        value: item.id,
        title: ((!!item.number && "№ " + item.number) || "№") + ((!!item.date_number && " от " + moment(item.date_number).format("DD.MM.YYYY")) || ""),
      };

      options.push(option);
    });

    return options;
  };

  return (
    !!contract && (
      <Modal handleClose={() => props.handleClose()}>
        <form className="Form" onSubmit={(event) => changeAdd(event)}>
          <div className="Form__title">
            Новый
            <div className="Text__signature">Платёж</div>
          </div>

          <div className="Form__field">
            <Input reference={inputDateReceipt}
                   type="date"
                   isFixed={true} label="Дата получения документа" />
          </div>

          <div className="Form__field">
            <Input reference={inputDate}
                   type="date"
                   isFixed={true} label="Дата" />
          </div>

          <div className="Form__field">
            <Input reference={inputNumber} label="Номер" />
          </div>

          <div className="Form__field">
            <Input
              reference={inputAmount}
              type="number"
              label="Сумма"
            />
          </div>

          <div className="Form__field">
            <Select
              isFixed={true}
              reference={selectContractStageId}
              options={!!listStages ? getContractStageSelectOptions(listStages) : getContractStageSelectOptions([])}
              label="Этап"
            />
          </div>

          <div className="Form__field Form__field_last">
            <Select
              isFixed={true}
              reference={selectContractActId}
              options={!!listActs ? getContractActSelectOptions(listActs) : getContractActSelectOptions([])}
              label="Акт"
            />
          </div>

          <div className="Form__actions Form__actions_center">
            <button className="Action__button Action__button_all Action__button_blue">
              Добавить
            </button>
          </div>
        </form>
      </Modal>
    )
  );
}

export default ModalPaymentAdd;
