import React, {useEffect, useState} from "react";
import "./AppealsMenuTree.scss";
import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";
import {ROOT_URL} from "../../../config/url";
import {ButtonIcon, getAccountFio, Loading, useAction, useObject, useProfile} from "@vokymlak/kabinet-ui";

export const AppealsMenuTree = (props) => {
  const {profile} = useProfile();

  const [selectEmployee, setSelectEmployee] = useState(profile);
  const [selectCompany, setSelectCompany] = useState(null);
  const [selectTab, setSelectTab] = useState('my');
  const [isEmployees, setIsEmployees] = useState(false);
  const [isCompanies, setIsCompanies] = useState(false);
  const [isMyCompanies, setIsMyCompanies] = useState(true);

  const {
    object: [allObject],
    update: [_isUpdateAllObject, _setIsUpdateAllObject, updateAllObject],
  } = useObject({apiUrl: "/api/appeal/tree/all", rootUrl: ROOT_URL});

  const {
    object: [object],
    update: [_isUpdateObject, _setIsUpdateObject, updateObject],
  } = useObject({apiUrl: "/api/appeal/tree", rootUrl: ROOT_URL});

  const {
    object: [myObject],
    update: [_isUpdateMyObject, _setIsUpdateMyObject, updateMyObject],
  } = useObject({apiUrl: "/api/appeal/tree/my", rootUrl: ROOT_URL});

  const {
    object: [companiesObject],
    update: [_isUpdateCompaniesObject, _setIsUpdateCompaniesObject, updateCompaniesObject],
  } = useObject({apiUrl: "/api/appeal/tree/companies", rootUrl: ROOT_URL});

  useAction("appeals", () => (updateObject(), updateMyObject(), updateCompaniesObject(), updateAllObject()));

  useEffect(() => {
    props.setSelectEmployee(selectEmployee);
    props.setSelectCompany(selectCompany);
    props.setSelectTab(selectTab);

  }, [selectEmployee, selectCompany, selectTab]);

  return (
    <ScrollBlock style={{width: "100%", height: "100%"}}>
      <div className="AppealsMenuTreeMenu">
        <ul className="Menu__list" style={{padding: 0}}>
          <li className={"List__link " + (selectTab === "all" && "active")}
              onClick={() => (setSelectTab('all'), setSelectEmployee(null), setSelectCompany(null))}>
            <div
              className={"Link__text"}
              style={{
                fontWeight: 700,
                fontSize: 15,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span>По всем</span>
              <div style={{
                display: "inline-block",
              }}>
                <div style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}>
                  {!!allObject && (
                    <>
                      {!!allObject.count && (
                        <div style={{
                          height: 16,
                          marginRight: 2,
                          padding: "1px 5px 1px",
                          backgroundColor: "#20202033",
                          color: "#202020",
                          fontSize: 12,
                          fontWeight: 700,
                          borderRadius: 24,
                        }}>{allObject.count}</div>
                      )}

                      {!!allObject.new_count && (
                        <div style={{
                          height: 16,
                          marginRight: 2,
                          padding: "1px 5px 1px",
                          backgroundColor: "#509d1d",
                          color: "white",
                          fontSize: 12,
                          fontWeight: 700,
                          borderRadius: 24,
                        }}>{allObject.new_count}</div>
                      )}

                      {!!allObject.work_count && (
                        <div style={{
                          height: 16,
                          marginRight: 2,
                          padding: "1px 5px 1px",
                          backgroundColor: "#0061ff",
                          color: "white",
                          fontSize: 12,
                          fontWeight: 700,
                          borderRadius: 24,
                        }}>{allObject.work_count}</div>
                      )}

                      {!!allObject.return_count && (
                        <div style={{
                          height: 16,
                          marginRight: 2,
                          padding: "1px 5px 1px",
                          backgroundColor: "#f44336",
                          color: "white",
                          fontSize: 12,
                          fontWeight: 700,
                          borderRadius: 24,
                        }}>{allObject.return_count}</div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </li>

          <li className={"List__link " + (selectTab === "my" && "active")}
              onClick={() => (setSelectTab('my'), setSelectEmployee(profile), setSelectCompany(null))}>
            <div
              className={"Link__text"}
              style={{
                fontWeight: 700,
                fontSize: 15,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span>Я исполнитель</span>
              <div style={{
                display: "inline-block",
              }}>
                <div style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}>
                  {!!myObject && !!myObject.employee && (
                    <>
                      {!!myObject.employee.executor_count && (
                        <div style={{
                          height: 16,
                          marginRight: 2,
                          padding: "1px 5px 1px",
                          backgroundColor: "#20202033",
                          color: "#202020",
                          fontSize: 12,
                          fontWeight: 700,
                          borderRadius: 24,
                        }}>{myObject.employee.executor_count}</div>
                      )}

                      {!!myObject.employee.executor_new_count && (
                        <div style={{
                          height: 16,
                          marginRight: 2,
                          padding: "1px 5px 1px",
                          backgroundColor: "#509d1d",
                          color: "white",
                          fontSize: 12,
                          fontWeight: 700,
                          borderRadius: 24,
                        }}>{myObject.employee.executor_new_count}</div>
                      )}

                      {!!myObject.employee.executor_work_count && (
                        <div style={{
                          height: 16,
                          marginRight: 2,
                          padding: "1px 5px 1px",
                          backgroundColor: "#0061ff",
                          color: "white",
                          fontSize: 12,
                          fontWeight: 700,
                          borderRadius: 24,
                        }}>{myObject.employee.executor_work_count}</div>
                      )}

                      {!!myObject.employee.executor_return_count && (
                        <div style={{
                          height: 16,
                          marginRight: 2,
                          padding: "1px 5px 1px",
                          backgroundColor: "#f44336",
                          color: "white",
                          fontSize: 12,
                          fontWeight: 700,
                          borderRadius: 24,
                        }}>{myObject.employee.executor_return_count}</div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </li>


          <li
            // className={"List__link-group " + (!selectCompany && !selectEmployee && selectTab === "employees" && "active")}
            className={"List__link-group "}
          >
            <div
              className={"Link__link-group-block"}
              style={{
                width: "100%",
                padding: "4px  2px 4px 6px",
                marginBottom: 0,
                color: "#202020",
              }}
              // onClick={
              //   !isEmployees
              //     ? () => setIsEmployees(true)
              //     : () => (setSelectTab('all'), setSelectEmployee(null), setSelectCompany(null), setIsEmployees(false))
              // }
              onClick={() => setIsMyCompanies(!isMyCompanies)}
            >
              <div>
                <div style={{
                  marginRight: 2,
                  fontWeight: 700,
                  fontSize: 15
                }}>
                  Мои компании
                </div>
                <div style={{
                  display: "inline-block",
                }}>
                  <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}>
                    {!myObject && (
                      <div>
                        <Loading size="small" color="blue"/>
                      </div>
                    )}

                    {!!myObject && !!myObject.employee && !myObject.employee.companies && (
                      <div style={{
                        height: 16,
                        marginRight: 2,
                        padding: "1px 3px 1px",
                        color: "#202020",
                        fontSize: 12,
                        fontWeight: 700,
                        borderRadius: 24,
                      }}>-</div>
                    )}

                    {!!myObject && !!myObject.employee && !!myObject.employee.companies && (
                      <>
                        {!!myObject.employee.companies.reduce((count, item) => count + item.count, 0) && (
                          <div style={{
                            height: 16,
                            marginRight: 2,
                            padding: "1px 5px 1px",
                            backgroundColor: "#20202033",
                            color: "#202020",
                            fontSize: 12,
                            fontWeight: 700,
                            borderRadius: 24,
                          }}>{myObject.employee.companies.reduce((count, item) => count + item.count, 0)}</div>
                        )}

                        {!!myObject.employee.companies.reduce((count, item) => count + item.new_count, 0) && (
                          <div style={{
                            height: 16,
                            marginRight: 2,
                            padding: "1px 5px 1px",
                            backgroundColor: "#509d1d",
                            color: "white",
                            fontSize: 12,
                            fontWeight: 700,
                            borderRadius: 24,
                          }}>{myObject.employee.companies.reduce((count, item) => count + item.new_count, 0)}</div>
                        )}

                        {!!myObject.employee.companies.reduce((count, item) => count + item.work_count, 0) && (
                          <div style={{
                            height: 16,
                            marginRight: 2,
                            padding: "1px 5px 1px",
                            backgroundColor: "#0061ff",
                            color: "white",
                            fontSize: 12,
                            fontWeight: 700,
                            borderRadius: 24,
                          }}>{myObject.employee.companies.reduce((count, item) => count + item.work_count, 0)}</div>
                        )}

                        {!!myObject.employee.companies.reduce((count, item) => count + item.return_count, 0) && (
                          <div style={{
                            height: 16,
                            marginRight: 2,
                            padding: "1px 5px 1px",
                            backgroundColor: "#f44336",
                            color: "white",
                            fontSize: 12,
                            fontWeight: 700,
                            borderRadius: 24,
                          }}>{myObject.employee.companies.reduce((count, item) => count + item.return_count, 0)}</div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
              {!!myObject && !!myObject.employee && !!myObject.employee.companies && (
                <ButtonIcon
                  tooltipText={null}
                  iconName={
                    isMyCompanies
                      ? "custom-expand-circle-up"
                      : "custom-expand-circle-down"
                  }
                  iconColor={"#20202095"}
                  size={18}
                  style={{marginLeft: 12, marginRight: 3}}
                />
              )}
            </div>

            {isMyCompanies && (
              <div>
                {!!myObject && !!myObject.employee && !!myObject.employee.companies && (
                  <ul className="List__menu" style={{
                    paddingLeft: 4,
                    paddingRight: 4,
                    marginTop: 0,
                    marginBottom: 0,
                    border: "none",
                    width: "100%",
                  }}>
                    {myObject.employee.companies.map((company) => (!!company.count && !!company.company &&
                      <li key={"my-company_" + company.company.uuid}
                          className={"List__link List__link_gray " + (!!selectCompany && selectCompany.uuid === company.company.uuid && "active")}
                          style={{
                            boxShadow: !!selectCompany && selectCompany.uuid === company.company.uuid && "0 16px 20px rgba(0, 0, 0, 0.07)",
                            padding: "4px 6px",
                            borderRadius: 5,
                            marginBottom: 4
                          }}>
                        <div
                          className={"Link__text"}
                          onClick={() => (setSelectTab('my-company'), setSelectCompany(company.company), setSelectEmployee(profile))}
                          style={{
                            color: "#202020"
                          }}
                        >
                          <div style={{
                            marginRight: 2,
                          }}>
                            {company.company.short_name}
                          </div>
                          <div style={{
                            display: "inline-block",
                          }}>
                            <div style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center"
                            }}>
                              {!!company.count && (
                                <div style={{
                                  height: 16,
                                  marginRight: 2,
                                  padding: "1px 5px 1px",
                                  backgroundColor: "#20202033",
                                  color: "#202020",
                                  fontSize: 12,
                                  fontWeight: 700,
                                  borderRadius: 24,
                                }}>{company.count}</div>
                              )}

                              {!!company.new_count && (
                                <div style={{
                                  height: 16,
                                  marginRight: 2,
                                  padding: "1px 5px 1px",
                                  backgroundColor: "#509d1d",
                                  color: "white",
                                  fontSize: 12,
                                  fontWeight: 700,
                                  borderRadius: 24,
                                }}>{company.new_count}</div>
                              )}

                              {!!company.work_count && (
                                <div style={{
                                  height: 16,
                                  marginRight: 2,
                                  padding: "1px 5px 1px",
                                  backgroundColor: "#0061ff",
                                  color: "white",
                                  fontSize: 12,
                                  fontWeight: 700,
                                  borderRadius: 24,
                                }}>{company.work_count}</div>
                              )}

                              {!!company.return_count && (
                                <div style={{
                                  height: 16,
                                  marginRight: 2,
                                  padding: "1px 5px 1px",
                                  backgroundColor: "#f44336",
                                  color: "white",
                                  fontSize: 12,
                                  fontWeight: 700,
                                  borderRadius: 24,
                                }}>{company.return_count}</div>
                              )}
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            )}
          </li>


          <li
            // className={"List__link-group " + (!selectCompany && !selectEmployee && selectTab === "employees" && "active")}
            className={"List__link-group "}
          >
            <div
              className={"Link__link-group-block"}
              style={{
                width: "100%",
                padding: "4px  2px 4px 6px",
                marginBottom: 0,
                color: "#202020",
              }}
              // onClick={
              //   !isEmployees
              //     ? () => setIsEmployees(true)
              //     : () => (setSelectTab('all'), setSelectEmployee(null), setSelectCompany(null), setIsEmployees(false))
              // }
              onClick={() => setIsEmployees(!isEmployees)}
            >
              <div>
                <div style={{
                  marginRight: 2,
                  fontWeight: 700,
                  fontSize: 15
                }}>
                  По сотрудника
                </div>
                <div style={{
                  display: "inline-block",
                }}>
                  <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}>
                    {!object && (
                      <div>
                        <Loading size="small" color="blue"/>
                      </div>
                    )}

                    {!!object && !!object.employees && (
                      <>
                        {!!object.employees.reduce((count, item) => count + item.executor_count, 0) && (
                          <div style={{
                            height: 16,
                            marginRight: 2,
                            padding: "1px 5px 1px",
                            backgroundColor: "#20202033",
                            color: "#202020",
                            fontSize: 12,
                            fontWeight: 700,
                            borderRadius: 24,
                          }}>{object.employees.reduce((count, item) => count + item.executor_count, 0)}</div>
                        )}

                        {!!object.employees.reduce((count, item) => count + item.executor_new_count, 0) && (
                          <div style={{
                            height: 16,
                            marginRight: 2,
                            padding: "1px 5px 1px",
                            backgroundColor: "#509d1d",
                            color: "white",
                            fontSize: 12,
                            fontWeight: 700,
                            borderRadius: 24,
                          }}>{object.employees.reduce((count, item) => count + item.executor_new_count, 0)}</div>
                        )}

                        {!!object.employees.reduce((count, item) => count + item.executor_work_count, 0) && (
                          <div style={{
                            height: 16,
                            marginRight: 2,
                            padding: "1px 5px 1px",
                            backgroundColor: "#0061ff",
                            color: "white",
                            fontSize: 12,
                            fontWeight: 700,
                            borderRadius: 24,
                          }}>{object.employees.reduce((count, item) => count + item.executor_work_count, 0)}</div>
                        )}

                        {!!object.employees.reduce((count, item) => count + item.executor_return_count, 0) && (
                          <div style={{
                            height: 16,
                            marginRight: 2,
                            padding: "1px 5px 1px",
                            backgroundColor: "#f44336",
                            color: "white",
                            fontSize: 12,
                            fontWeight: 700,
                            borderRadius: 24,
                          }}>{object.employees.reduce((count, item) => count + item.executor_return_count, 0)}</div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
              {/*{!object && (*/}
              {/*  <div style={{*/}
              {/*    padding: "5px 8px"*/}
              {/*  }}>*/}
              {/*    <Loading size="small" color="blue"/>*/}
              {/*  </div>*/}
              {/*)}*/}

              {!!object && (
                <ButtonIcon
                  tooltipText={null}
                  iconName={
                    isEmployees
                      ? "custom-expand-circle-up"
                      : "custom-expand-circle-down"
                  }
                  iconColor={"#20202095"}
                  size={18}
                  style={{marginLeft: 12, marginRight: 3}}
                />
              )}
            </div>

            {isEmployees && (
              <div>
                {!!object && (
                  <ul className="Menu__list" style={{
                    padding: "0 2px"
                  }}>
                    {!!object.employees && object.employees.map((item) => (
                      !!item.employee && (
                        <li
                          key={"employee_" + item.employee.uuid}
                          className={"List__group List__group_gray " + (!selectCompany && !!selectEmployee && selectEmployee.uuid === item.employee.uuid && "active")}
                          style={{
                            flexDirection: "column",
                            borderRadius: 7
                          }}
                        >
                          <div
                            className={"Link__block"}
                            style={{
                              width: "100%",
                              padding: "4px  2px 4px 6px",
                              marginBottom: 0,
                              color: "#202020"
                            }}
                            onClick={!!selectEmployee && selectEmployee.uuid === item.employee.uuid
                              ? !!selectCompany
                                ? () => (setSelectTab('employee'), setSelectEmployee(item.employee), setSelectCompany(null))
                                : () => (setSelectTab('all'), setSelectCompany(null), setSelectEmployee(null))
                              : () => (setSelectTab('employee'), setSelectEmployee(item.employee), setSelectCompany(null))}
                          >
                            <div>
                              <div style={{
                                marginRight: 2,
                              }}>
                                {getAccountFio(item.employee)}
                              </div>
                              <div style={{
                                display: "inline-block",
                              }}>
                                <div style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center"
                                }}>
                                  {!!item.executor_count && (
                                    <div style={{
                                      height: 16,
                                      marginRight: 2,
                                      padding: "1px 5px 1px",
                                      backgroundColor: "#20202033",
                                      color: "#202020",
                                      fontSize: 12,
                                      fontWeight: 700,
                                      borderRadius: 24,
                                    }}>{item.executor_count}</div>
                                  )}

                                  {!!item.executor_new_count && (
                                    <div style={{
                                      height: 16,
                                      marginRight: 2,
                                      padding: "1px 5px 1px",
                                      backgroundColor: "#509d1d",
                                      color: "white",
                                      fontSize: 12,
                                      fontWeight: 700,
                                      borderRadius: 24,
                                    }}>{item.executor_new_count}</div>
                                  )}

                                  {!!item.executor_work_count && (
                                    <div style={{
                                      height: 16,
                                      marginRight: 2,
                                      padding: "1px 5px 1px",
                                      backgroundColor: "#0061ff",
                                      color: "white",
                                      fontSize: 12,
                                      fontWeight: 700,
                                      borderRadius: 24,
                                    }}>{item.executor_work_count}</div>
                                  )}

                                  {!!item.executor_return_count && (
                                    <div style={{
                                      height: 16,
                                      marginRight: 2,
                                      padding: "1px 5px 1px",
                                      backgroundColor: "#f44336",
                                      color: "white",
                                      fontSize: 12,
                                      fontWeight: 700,
                                      borderRadius: 24,
                                    }}>{item.executor_return_count}</div>
                                  )}
                                </div>
                              </div>
                            </div>
                            {!!item.companies && (
                              <ButtonIcon
                                tooltipText={null}
                                iconName={
                                  !!selectEmployee && selectEmployee.uuid === item.employee.uuid
                                    ? "custom-expand-circle-up"
                                    : "custom-expand-circle-down"
                                }
                                iconColor={"#20202095"}
                                size={18}
                                style={{marginLeft: 12, marginRight: 3}}
                                onClick={() => null}
                              />
                            )}
                          </div>

                          {!!selectEmployee && selectEmployee.uuid === item.employee.uuid && !!item.companies && (
                            <ul className="List__menu" style={{
                              paddingLeft: 4,
                              paddingRight: 4,
                              marginTop: 0,
                              marginBottom: 0,
                              border: "none",
                              width: "100%",
                            }}>
                              {item.companies.map((company) => (!!company.count && !!company.company &&
                                <li key={company.company.uuid}
                                    className={"List__link List__link_gray " + (!!selectCompany && selectCompany.uuid === company.company.uuid && "active")}
                                    style={{
                                      boxShadow: !!selectCompany && selectCompany.uuid === company.company.uuid && "0 16px 20px rgba(0, 0, 0, 0.07)",
                                      padding: "4px 6px",
                                      borderRadius: 5,
                                      marginBottom: 4
                                    }}>
                                  <div
                                    className={"Link__text"}
                                    onClick={() => (setSelectTab('employee-company'), setSelectCompany(company.company))}
                                    style={{
                                      color: "#202020"
                                    }}
                                  >
                                    <div style={{
                                      marginRight: 2,
                                    }}>
                                      {company.company.short_name}
                                    </div>
                                    <div style={{
                                      display: "inline-block",
                                    }}>
                                      <div style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                      }}>
                                        {!!company.count && (
                                          <div style={{
                                            height: 16,
                                            marginRight: 2,
                                            padding: "1px 5px 1px",
                                            backgroundColor: "#20202033",
                                            color: "#202020",
                                            fontSize: 12,
                                            fontWeight: 700,
                                            borderRadius: 24,
                                          }}>{company.count}</div>
                                        )}

                                        {!!company.new_count && (
                                          <div style={{
                                            height: 16,
                                            marginRight: 2,
                                            padding: "1px 5px 1px",
                                            backgroundColor: "#509d1d",
                                            color: "white",
                                            fontSize: 12,
                                            fontWeight: 700,
                                            borderRadius: 24,
                                          }}>{company.new_count}</div>
                                        )}

                                        {!!company.work_count && (
                                          <div style={{
                                            height: 16,
                                            marginRight: 2,
                                            padding: "1px 5px 1px",
                                            backgroundColor: "#0061ff",
                                            color: "white",
                                            fontSize: 12,
                                            fontWeight: 700,
                                            borderRadius: 24,
                                          }}>{company.work_count}</div>
                                        )}

                                        {!!company.return_count && (
                                          <div style={{
                                            height: 16,
                                            marginRight: 2,
                                            padding: "1px 5px 1px",
                                            backgroundColor: "#f44336",
                                            color: "white",
                                            fontSize: 12,
                                            fontWeight: 700,
                                            borderRadius: 24,
                                          }}>{company.return_count}</div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          )}

                        </li>
                      )
                    ))}
                  </ul>
                )}
              </div>
            )}
          </li>


          <li
            // className={"List__link-group " + (!selectCompany && !selectEmployee && selectTab === "employees" && "active")}
            className={"List__link-group "}
          >
            <div
              className={"Link__link-group-block"}
              style={{
                width: "100%",
                padding: "4px  2px 4px 6px",
                marginBottom: 0,
                color: "#202020",
              }}
              // onClick={
              //   !isEmployees
              //     ? () => setIsEmployees(true)
              //     : () => (setSelectTab('all'), setSelectEmployee(null), setSelectCompany(null), setIsEmployees(false))
              // }
              onClick={() => setIsCompanies(!isCompanies)}
            >
              <div>
                <div style={{
                  marginRight: 2,
                  fontWeight: 700,
                  fontSize: 15
                }}>
                  По компаниям
                </div>
                <div style={{
                  display: "inline-block",
                }}>
                  <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}>
                    {!companiesObject && (
                      <div>
                        <Loading size="small" color="blue"/>
                      </div>
                    )}

                    {!!companiesObject && !!companiesObject.companies && (
                      <>
                        {!!companiesObject.companies.reduce((count, item) => count + item.count, 0) && (
                          <div style={{
                            height: 16,
                            marginRight: 2,
                            padding: "1px 5px 1px",
                            backgroundColor: "#20202033",
                            color: "#202020",
                            fontSize: 12,
                            fontWeight: 700,
                            borderRadius: 24,
                          }}>{companiesObject.companies.reduce((count, item) => count + item.count, 0)}</div>
                        )}

                        {!!companiesObject.companies.reduce((count, item) => count + item.new_count, 0) && (
                          <div style={{
                            height: 16,
                            marginRight: 2,
                            padding: "1px 5px 1px",
                            backgroundColor: "#509d1d",
                            color: "white",
                            fontSize: 12,
                            fontWeight: 700,
                            borderRadius: 24,
                          }}>{companiesObject.companies.reduce((count, item) => count + item.new_count, 0)}</div>
                        )}

                        {!!companiesObject.companies.reduce((count, item) => count + item.work_count, 0) && (
                          <div style={{
                            height: 16,
                            marginRight: 2,
                            padding: "1px 5px 1px",
                            backgroundColor: "#0061ff",
                            color: "white",
                            fontSize: 12,
                            fontWeight: 700,
                            borderRadius: 24,
                          }}>{companiesObject.companies.reduce((count, item) => count + item.work_count, 0)}</div>
                        )}

                        {!!companiesObject.companies.reduce((count, item) => count + item.return_count, 0) && (
                          <div style={{
                            height: 16,
                            marginRight: 2,
                            padding: "1px 5px 1px",
                            backgroundColor: "#f44336",
                            color: "white",
                            fontSize: 12,
                            fontWeight: 700,
                            borderRadius: 24,
                          }}>{companiesObject.companies.reduce((count, item) => count + item.return_count, 0)}</div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
              {!!companiesObject && (
                <ButtonIcon
                  tooltipText={null}
                  iconName={
                    isCompanies
                      ? "custom-expand-circle-up"
                      : "custom-expand-circle-down"
                  }
                  iconColor={"#20202095"}
                  size={18}
                  style={{marginLeft: 12, marginRight: 3}}
                />
              )}
            </div>

            {isCompanies && (
              <div>
                {!!companiesObject && !!companiesObject.companies && (
                  <ul className="List__menu" style={{
                    paddingLeft: 4,
                    paddingRight: 4,
                    marginTop: 0,
                    marginBottom: 0,
                    border: "none",
                    width: "100%",
                  }}>
                    {companiesObject.companies.map((company) => (!!company.count && !!company.company &&
                      <li key={"my-company_" + company.company.uuid}
                          className={"List__link List__link_gray " + (!!selectCompany && selectCompany.uuid === company.company.uuid && "active")}
                          style={{
                            boxShadow: !!selectCompany && selectCompany.uuid === company.company.uuid && "0 16px 20px rgba(0, 0, 0, 0.07)",
                            padding: "4px 6px",
                            borderRadius: 5,
                            marginBottom: 4
                          }}>
                        <div
                          className={"Link__text"}
                          onClick={() => (setSelectTab('company'), setSelectCompany(company.company), setSelectEmployee(null))}
                          style={{
                            color: "#202020"
                          }}
                        >
                          <div style={{
                            marginRight: 2,
                          }}>
                            {company.company.short_name}
                          </div>
                          <div style={{
                            display: "inline-block",
                          }}>
                            <div style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center"
                            }}>
                              {!!company.count && (
                                <div style={{
                                  height: 16,
                                  marginRight: 2,
                                  padding: "1px 5px 1px",
                                  backgroundColor: "#20202033",
                                  color: "#202020",
                                  fontSize: 12,
                                  fontWeight: 700,
                                  borderRadius: 24,
                                }}>{company.count}</div>
                              )}

                              {!!company.new_count && (
                                <div style={{
                                  height: 16,
                                  marginRight: 2,
                                  padding: "1px 5px 1px",
                                  backgroundColor: "#509d1d",
                                  color: "white",
                                  fontSize: 12,
                                  fontWeight: 700,
                                  borderRadius: 24,
                                }}>{company.new_count}</div>
                              )}

                              {!!company.work_count && (
                                <div style={{
                                  height: 16,
                                  marginRight: 2,
                                  padding: "1px 5px 1px",
                                  backgroundColor: "#0061ff",
                                  color: "white",
                                  fontSize: 12,
                                  fontWeight: 700,
                                  borderRadius: 24,
                                }}>{company.work_count}</div>
                              )}

                              {!!company.return_count && (
                                <div style={{
                                  height: 16,
                                  marginRight: 2,
                                  padding: "1px 5px 1px",
                                  backgroundColor: "#f44336",
                                  color: "white",
                                  fontSize: 12,
                                  fontWeight: 700,
                                  borderRadius: 24,
                                }}>{company.return_count}</div>
                              )}
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            )}
          </li>
        </ul>


      </div>
    </ScrollBlock>
  );
};
