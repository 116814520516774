import React, { useEffect, useRef, useState } from "react";
import { ROOT_URL } from "../../../../../config/url";
import {useList, useAction, useNotification, useUnroll} from "@vokymlak/kabinet-ui";
import {useViewer} from "../../../../../components/Viewer";

function useContractPayments(props) {
  const [selectPayment, setSelectPayment] = useState(null);

  const [countPaymentAmount, setCountPaymentAmount] = useState(0);

  const {notification} = useNotification();

  const { viewer } = useViewer();

  const changeViewer = (image, mimeType) => {
    viewer(image, mimeType);
  };

  const {
    list: [list, count, setList],
    update: [isUpdateList, setIsUpdateList, updateList],
  } = useList({
    apiUrl: "/api/contract/" + props.contract.uuid + "/payments",
    rootUrl: ROOT_URL,
  });

  useEffect(() => {
    if (!!list) {
      let countPaymentAmount = 0;

      list.forEach((i) => {
        countPaymentAmount += i.amount;
      });

      setCountPaymentAmount(countPaymentAmount);
    }
  }, [list]);

  const { unrolls, isUnroll, changeUnroll, changeAllUnroll } = useUnroll();

  const { setAction } = useAction(props.contract.uuid, () => updateList());

  useEffect(() => {
    if (!!list) {
      const listUuid = list.map((i) => i.uuid);
      setAction([...listUuid, props.contract.uuid]);
    } else {
      setAction(props.contract.uuid);
    }
  }, [list]);

  const [modalPaymentImport, setModalPaymentImport] = useState(null);
  const [modalPaymentAdd, setModalPaymentAdd] = useState(null);
  const [modalPaymentEdit, setModalPaymentEdit] = useState(null);

  return {
    object: props.contract,

    countPaymentAmount,

    list,
    setList,
    count,

    setIsUpdateList,
    isUpdateList,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    modalPaymentAdd,
    setModalPaymentAdd,

    modalPaymentEdit,
    setModalPaymentEdit,

    setModalPaymentImport,
    modalPaymentImport,

    selectPayment,
    setSelectPayment,

    changeViewer,
  };
}

export default useContractPayments;
