import React, { Fragment, useState } from "react";
import ModalEditCatalogEntry from "./ModalEditCatalogEntry";
import ModalAddCatalogEntry from "./ModalAddCatalogEntry";
import {
  ButtonIcon, ButtonIconExport, formatBytes, Icon,
  Loading,
} from "@vokymlak/kabinet-ui";
import { ScrollBlock } from "../../../../components/ScrollBlock/ScrollBlock";
import ModalFileInFolder from "./ModalFileInFolder";
import ModalFileFromFolder from "./ModalFileFromFolder";
import {ROOT_URL} from "../../../../config/url";
import moment from "moment";
import ModalAddFileInFolder from "./ModalAddFileInFolder";

export const CatalogEntriesPage = (props) => {
  const {
    list,
    setList,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    setIsUpdateList,
    isUpdateList,

    modalItemEdit,
    setModalItemEdit,
    isModalObjectAdd,
    setIsModalObjectAdd,
  } = props;

  const [modalArticleInGroup, setModalArticleInGroup] = useState(null);
  const [modalArticleFromGroup, setModalArticleFromGroup] = useState(null);
  const [modalFileAddInFolder, setModalFileAddInFolder] = useState(null);

  function getColorFile(text) {
    if (text.toLowerCase().includes('docx') || text.toLowerCase().includes('doc')) {
      return "#3d6cc0";
    } else if (text.toLowerCase().includes('xlsx')) {
      return "#107c41";
    } else if (text.toLowerCase().includes('pdf')) {
      return "#b9090b";
    }
    return "#202020";
  }


  return (
    <ScrollBlock style={{ width: "100%", height: "100%" }}>
      <div className="HandbookPage" style={{
        backgroundColor: "#f1f1f1"
      }}>
        <div className="Page__title">
          <div className="Title__text">
            <div
              className="Title__text"
              style={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
                fontSize: 19,
                fontWeight: 700,
              }}
            >
              Каталог файлов
              <span
                style={{
                  fontSize: 13,
                  opacity: 0.5,
                  fontWeight: 400
                }}
              >
                          Добавьте папки и файлы
                        </span>
            </div>
          </div>

          <div className="Title__settings">
            {!!list && (
              <ButtonIcon
                tooltipText={
                  list.length === unrolls.length ? "Свернуть" : "Развернуть"
                }
                iconName={
                  list.length === unrolls.length
                    ? "custom-unfold-less"
                    : "custom-unfold-more"
                }
                iconColor={"#20202075"}
                size={28}
                style={{ marginLeft: 8 }}
                onClick={() => changeAllUnroll(list, "uuid")}
              />
            )}

            <ButtonIcon
              tooltipText={"Обновить"}
              iconName={"custom-change-circle"}
              iconColor={"#20202075"}
              size={28}
              style={{ marginLeft: 8 }}
              onClick={() => (setList(null), setIsUpdateList(!isUpdateList))}
            />

            <ButtonIcon
              tooltipText={"Добавить"}
              iconName={"custom-add"}
              iconColor={"#0064ff"}
              size={36}
              style={{ marginLeft: 12 }}
              onClick={() => setIsModalObjectAdd(true)}
            />
          </div>
        </div>

        {!list && (
          <div className={"Page__loader"}>
            <Loading />
          </div>
        )}

        {!!list && (
          <div className="Page__content">
            <ul className="List">
                      {!!list &&
                        list.map(item => (
                                <Fragment key={"item_" + item.uuid}>
                                  <li
                                    className={
                                      isUnroll(item.uuid)
                                        ? "List__item active"
                                        : "List__item"
                                    }
                                    onClick={() => changeUnroll(item.uuid)}
                                    style={{
                                      backgroundColor: !!item.is_public && item.is_public && "#0061ff25"
                                    }}
                                  >
                                    {item.catalog_entry_type.tag === "FOLDER" && (
                                      <>
                                      <div className="Item__title">
                                        <div
                                          className="Element__title"
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center"
                                          }}
                                        >
                                          <span style={{marginRight: 8, height: 20}}>
                                          <Icon name={"custom-folder"} size={20} color={"#ffc200"}   />
                                          </span>

                                          <span style={{fontSize: 15}}>
                                          {item.title}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="Item__settings">
                                        <div className="Element__datetime">
                                          {!!item.entries && (
                                          <span style={{
                                            marginLeft: 12,
                                            fontSize: 15
                                          }}>
                                            <div
                                              style={{
                                                backgroundColor: "#202020",
                                                padding: "0 4px",
                                                display: "flex",
                                                flexDirection: "row",
                                                flexWrap: "nowrap",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                borderRadius: 24,
                                                marginLeft: 12,
                                              }}
                                            >
                <div
                  style={{
                    padding: "2px 2px",
                    color: "white",
                    fontSize: 12,
                    pointerEvents: "auto",
                    fontWeight: 700,
                    cursor: "default"
                  }}
                >
                   {item.entries.length}
                </div>
              </div>

                                          </span>
                                          )}
                                          {!!item.size && (
                                            <span style={{
                                              marginLeft: 12,
                                              fontSize: 15
                                            }}>
                                          {formatBytes(item.size)}
                                          </span>
                                          )}
                                        </div>
                                      </div>
                                    </>
                                    )}
                                    {item.catalog_entry_type.tag === "FILE" && (
                                      <>
                                        <div className="Item__title">
                                          <div
                                            className="Element__title"
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center"
                                            }}
                                          >
                                            {!!item.file.expansion && (
                                              <span
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "column",
                                                  alignItems: "flex-start",
                                                  justifyContent: "center",
                                                  textTransform: "uppercase",
                                                  fontSize: 10,
                                                  fontWeight: 900,
                                                  borderRadius: 3,
                                                  color: "white",
                                                  backgroundColor: getColorFile(item.file.expansion.slice(1)),
                                                  marginRight: 8,
                                                  padding: "2px 4px"
                                                }}>
                                    {item.file.expansion.slice(1)}
                                  </span>
                                            )}
                                            <span style={{
                                              fontSize: 15
                                            }}>
                                            {item.title}
                                              </span>
                                          </div>
                                        </div>
                                        <div className="Item__settings">
                                          <div className="Element__datetime">
                                            <span style={{
                                              marginLeft: 12,
                                              fontSize: 15
                                            }}>
                                                                        {!!item.file.size && formatBytes(item.file.size)}
                                                                          </span>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </li>

                                  {isUnroll(item.uuid) && (
                                    <li className="List__content">
                                      <div className="Content__header">
                                        <div className="Header__title">
                                          {item.catalog_entry_type.tag === "FILE" && (
                                          <div
                                            className="Button__text"
                                            style={{
                                              marginRight: 24,
                                            }}
                                            onClick={() =>
                                              setModalArticleInGroup(item)
                                            }
                                          >
                                            Перенести в папку
                                          </div>
                                          )}
                                          {item.catalog_entry_type.tag === "FOLDER" && (
                                            <div
                                              className="Button__text"
                                              style={{
                                                marginRight: 24,
                                              }}
                                              onClick={() =>
                                                setModalFileAddInFolder(item)
                                              }
                                            >
                                              Добавить файлы
                                            </div>
                                          )}
                                        </div>
                                        <div className="Header__settings">
                                          {item.catalog_entry_type.tag === "FILE" && (
                                          <ButtonIconExport
                                            tooltipText={"Выгрузить"}
                                            iconName={"custom-download"}
                                            iconColor={"#202020"}
                                            size={24}
                                            style={{ marginLeft: 12 }}
                                            apiUrl={
                                              "/api/catalog/file/" +
                                              item.file.uuid
                                            }
                                            rootUrl={ROOT_URL}
                                            mimeType={
                                              item.file.mime_type
                                            }
                                            fileName={
                                              item.title.trim().
                                              replaceAll(",", "_").
                                              replaceAll(" ", "_") +
                                              item.file.expansion
                                            }
                                          />
                                          )}
                                          <ButtonIcon
                                            tooltipText={"Редактировать"}
                                            iconName={"custom-edit"}
                                            iconColor={"#202020"}
                                            size={36}
                                            style={{ marginLeft: 12 }}
                                            onClick={() => setModalItemEdit(item)}
                                          />
                                        </div>
                                      </div>

                                      {!!item.entries && (
                                      <div>
                                        <ul className="List"
                                            style={{
                                          paddingTop: 8,
                                          paddingLeft: 8,
                                          borderLeft: "3px solid #dbdbdb",
                                        }}>
                                                  {!!item.entries &&
                                                    item.entries.map(entry => (
                                                            <Fragment key={"entry_" + entry.uuid}>
                                                              <li
                                                                className={
                                                                  isUnroll(entry.uuid)
                                                                    ? "List__item active"
                                                                    : "List__item"
                                                                }
                                                                onClick={() => changeUnroll(entry.uuid)}
                                                              >
                                                                {entry.catalog_entry_type.tag === "FILE" && (
                                                                  <>
                                                                    <div className="Item__title">
                                                                      <div
                                                                        className="Element__title"
                                                                        style={{
                                                                          display: "flex",
                                                                          alignItems: "center",
                                                                          justifyContent: "center"
                                                                        }}
                                                                      >
                                                                        {!!entry.file.expansion && (
                                                                          <span
                                                                            style={{
                                                                              display: "flex",
                                                                              flexDirection: "column",
                                                                              alignItems: "flex-start",
                                                                              justifyContent: "center",
                                                                              textTransform: "uppercase",
                                                                              fontSize: 10,
                                                                              fontWeight: 900,
                                                                              borderRadius: 3,
                                                                              color: "white",
                                                                              backgroundColor: getColorFile(entry.file.expansion.slice(1)),
                                                                              marginRight: 8,
                                                                              padding: "2px 4px"
                                                                            }}>
                                    {entry.file.expansion.slice(1)}
                                  </span>
                                                                        )}
                                                                        <span style={{
                                                                          fontSize: 15
                                                                        }}>
                                            {entry.title}
                                              </span>
                                                                      </div>
                                                                    </div>
                                                                    <div className="Item__settings">
                                                                      <div className="Element__datetime">
                                                                        <span style={{
                                                                          marginLeft: 12,
                                                                          fontSize: 15
                                                                        }}>
                                                                        {!!entry.file.size && formatBytes(entry.file.size)}
                                                                          </span>
                                                                      </div>
                                                                    </div>
                                                                  </>
                                                                )}
                                                              </li>

                                                              {isUnroll(entry.uuid) && (
                                                                <li className="List__content">
                                                                  <div className="Content__header">
                                                                    <div className="Header__title">
                                                                      {entry.catalog_entry_type.tag === "FILE" && (
                                                                        <div
                                                                          className="Button__text"
                                                                          style={{
                                                                            marginRight: 24,
                                                                          }}
                                                                          onClick={() =>
                                                                            setModalArticleInGroup(entry)
                                                                          }
                                                                        >
                                                                          Перенести в папку
                                                                        </div>
                                                                      )}
                                                                      <div
                                                                        className="Button__text"
                                                                        style={{
                                                                          marginRight: 24,
                                                                        }}
                                                                        onClick={() =>
                                                                          setModalArticleFromGroup(entry)
                                                                        }
                                                                      >
                                                                        Убрать из папки
                                                                      </div>
                                                                    </div>
                                                                    <div className="Header__settings">
                                                                      {entry.catalog_entry_type.tag === "FILE" && (
                                                                        <ButtonIconExport
                                                                          tooltipText={"Выгрузить"}
                                                                          iconName={"custom-download"}
                                                                          iconColor={"#202020"}
                                                                          size={24}
                                                                          style={{ marginLeft: 12 }}
                                                                          apiUrl={
                                                                            "/api/catalog/file/" +
                                                                            entry.file.uuid
                                                                          }
                                                                          rootUrl={ROOT_URL}
                                                                          mimeType={
                                                                            entry.file.mime_type
                                                                          }
                                                                          fileName={
                                                                            entry.title.trim().
                                                                            replaceAll(",", "_").
                                                                            replaceAll(" ", "_") +
                                                                            entry.file.expansion
                                                                          }
                                                                        />
                                                                      )}
                                                                      <ButtonIcon
                                                                        tooltipText={"Редактировать"}
                                                                        iconName={"custom-edit"}
                                                                        iconColor={"#202020"}
                                                                        size={36}
                                                                        style={{ marginLeft: 12 }}
                                                                        onClick={() => setModalItemEdit(entry)}
                                                                      />
                                                                    </div>
                                                                  </div>
                                                                </li>
                                                              )}
                                                            </Fragment>
                                              ))}
                                        </ul>
                                      </div>
                                        )}
                                    </li>
                                  )}
                                </Fragment>
                  ))}
            </ul>
          </div>
        )}

        {!!modalItemEdit && (
          <ModalEditCatalogEntry
            object={modalItemEdit}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalItemEdit(null)}
          />
        )}
        {!!isModalObjectAdd && (
          <ModalAddCatalogEntry
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setIsModalObjectAdd(false)}
          />
        )}


        {!!modalArticleInGroup && (
          <ModalFileInFolder
            object={modalArticleInGroup}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalArticleInGroup(null)}
          />
        )}
        {!!modalArticleFromGroup && (
          <ModalFileFromFolder
            object={modalArticleFromGroup}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalArticleFromGroup(null)}
          />
        )}

        {!!modalFileAddInFolder && (
          <ModalAddFileInFolder
            object={modalFileAddInFolder}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalFileAddInFolder(null)}
          />
        )}
      </div>
    </ScrollBlock>
  );
};
