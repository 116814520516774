import React from "react";
import axios from "axios";
import { ROOT_URL } from "../../../../config/url";
import {
  getErrorMessageByKey,
  useNotification,
  Modal,
  getAccountFio,
} from "@vokymlak/kabinet-ui";

function ModalProjectEmployeeEdit(props) {
  const {
    object
  } = props;

  const { notification } = useNotification();

  const changeDelete = (event) => {
    event.preventDefault();

    axios
      .delete("/api/project/employee/" + props.object.uuid + "/delete", {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Сотрудник отвязан", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
      <Modal handleClose={() => props.handleClose()}>
        <form className="Form" onSubmit={(event) => changeDelete(event)}>
          <div
            className="Form__title"
            style={{
              fontSize: 15,
            }}
          >
            {getAccountFio(object.employee)}
            <div className="Text__signature">Отвязать сотрудника</div>
          </div>

          <div className="Form__actions Form__actions_center">
            <button className="Action__button Action__button_all Action__button_red">
              Отвязать
            </button>
          </div>

          <div className="Form__actions Form__actions_center">
            <div className="Action__link" onClick={() => props.handleClose()}>
              Отмена
            </div>
          </div>
        </form>
      </Modal>
  );
}

export default ModalProjectEmployeeEdit;
