import React from "react";
import {ButtonIcon, Pagination, Search, Select,} from "@vokymlak/kabinet-ui";
import {ProjectPfhdTable} from "./ProjectPfhdTable";

function ProjectPfhdPage(props) {
  const {
    inputSearchText,
    onChangeSearchText,

    object,

    amount,
    isUpdateAmount,
    setIsUpdateAmount,

    list,
    setList,
    count,

    pageNumber,
    pageLimit,
    setPageLimit,
    changePage,

    setIsUpdateList,
    isUpdateList,
  } = props;

  return (
    !!object && (
      <>
        <div
          className="Page__title"
          style={{
            marginTop: 0,
            marginBottom: 6,
          }}
        >
          <div className="Title__text">
            <div
              className="Title__text"
              style={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
                fontSize: 19,
                fontWeight: 700,
                marginRight: 6,
              }}
            >
              <div>
                ПФХД
                {!!list && (
                  <span style={{fontSize: 12, opacity: 0.75, marginLeft: 6}}>
                    ({count})
                  </span>
                )}
              </div>
            </div>
            <span style={{width: 140, marginLeft: 6, marginRight: 6}}>
              <Search
                reference={inputSearchText}
                isFixed={true}
                label={"Поиск"}
                onChange={() => onChangeSearchText()}
              />
            </span>


            {!!amount && (
              <div style={{
                display: "flex",
              }}>

                <span style={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: 6,
                  backgroundColor: "#dbdbdb",
                  padding: 4,
                  borderRadius: 5
                }}>
                <span style={{fontSize: 12, fontWeight: 400}}>
                    Сумма лимита
                  </span>
                  <span style={{fontSize: 15, fontWeight: 700}}>
                    {(!!amount.amount &&
                        new Intl.NumberFormat("ru-RU").format(
                          (amount.amount / 100).toFixed(2)
                        )) ||
                      "0,00"}
                  </span>
                </span>

                {!!object && !!object.law && object.law.tag === "44" && (
                  <span style={{
                    display: "flex",
                    flexDirection: "column",
                    marginRight: 6,
                    backgroundColor: "#dbdbdb",
                    padding: 4,
                    borderRadius: 5
                  }}>
                <span style={{fontSize: 12, fontWeight: 400}}>
                    СГОЗ
                  </span>
                  <span style={{fontSize: 15, fontWeight: 700}}>
                    {(!!amount.sum_sgoz &&
                        new Intl.NumberFormat("ru-RU").format(
                          (amount.sum_sgoz / 100).toFixed(2)
                        )) ||
                      "0,00"}
                  </span>
                </span>
                )}

                <span style={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: 6,
                  backgroundColor: "#dbdbdb",
                  padding: 4,
                  borderRadius: 5
                }}>
                <span style={{fontSize: 12, fontWeight: 400}}>
                    Запланировано
                  </span>
                  <span style={{fontSize: 15, fontWeight: 700}}>
                    {(!!amount.planned &&
                        new Intl.NumberFormat("ru-RU").format(
                          (amount.planned / 100).toFixed(2)
                        )) ||
                      "0,00"}
                  </span>
                </span>

                <span style={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: 6,
                  backgroundColor: "#dbdbdb",
                  padding: 4,
                  borderRadius: 5
                }}>
                <span style={{fontSize: 12, fontWeight: 400}}>
                    Остаток
                  </span>
                  <span style={{fontSize: 15, fontWeight: 700}}>
                    {(!!amount.balance &&
                        new Intl.NumberFormat("ru-RU").format(
                          (amount.balance / 100).toFixed(2)
                        )) ||
                      "0,00"}
                  </span>
                </span>
              </div>
            )}
          </div>

          <div className="Title__settings">
            <ButtonIcon
              tooltipText={"Обновить"}
              iconName={"custom-change-circle"}
              iconColor={"#20202075"}
              size={28}
              style={{marginLeft: 12}}
              onClick={() => (setList(null), setIsUpdateList(!isUpdateList), setIsUpdateAmount(!isUpdateAmount))}
            />

            {!!count && !!pageLimit && (
              <div
                style={{
                  marginLeft: 12,
                  width: 64,
                }}
              >
                <Select
                  isFixed={true}
                  isDefaultOption={false}
                  value={pageLimit}
                  options={[
                    {
                      value: 100,
                      title: 100,
                    },
                    {
                      value: 500,
                      title: 500,
                    },
                  ]}
                  label="Лимит"
                  onChange={(event) => setPageLimit(Number(event.target.value))}
                />
              </div>
            )}

            {!!count && count > pageLimit && (
              <div style={{marginLeft: 8}}>
                <Pagination
                  totalRecords={count}
                  pageLimit={pageLimit}
                  currentPage={pageNumber}
                  onPageChanged={(data) => changePage(data)}
                />
              </div>
            )}
          </div>
        </div>

        {!!list && (
          <div
            className="Page__content"
            style={{
              position: "relative",
              height: "calc(100% - 48px - 26px - 2px - 10px - 28px)",
            }}
          >
            <div
              style={{
                overflow: "auto",
                width: "100%",
                height: "100%",
              }}
            >
              <ProjectPfhdTable {...props} />
            </div>
          </div>
        )}
      </>
    )
  );
}

export default ProjectPfhdPage;
