import React from "react";
import ContractPfhdWrapper from "./ContractPfhdWrapper";

function ContractPfhd(props) {
  return (
      <ContractPfhdWrapper
        {...props}
      />
  );
}

export default ContractPfhd;
