import React from "react";
import axios from "axios";
import {
  getErrorMessageByKey,
  useNotification,
  Modal,
} from "@vokymlak/kabinet-ui";
import {ROOT_URL} from "../../config/url";

function ModalTicketFileAdd(props) {
  const { notification } = useNotification();

  const changeAdd = (event) => {
    event.preventDefault();

    axios
      .get(
        "/api/profile/delete/avatar",
        {
          withCredentials: false,
          baseURL: ROOT_URL,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + localStorage.getItem("tokenSession"),
          },
        }
      )
      .then(() => {
        props.successfulRequest();
        notification("Фото удалено", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    <Modal handleClose={() => props.handleClose()}>
      <form className="Form" onSubmit={(event) => changeAdd(event)}>
        <div className="Form__title">
          Удаление фото
        </div>

        <div className="Form__actions Form__actions_center">
          <button className="Action__button Action__button_all Action__button_red">
            Удалить
          </button>
        </div>

        <div className="Form__actions Form__actions_center">
          <div className="Action__link" onClick={() => props.handleClose()}>
            Отмена
          </div>
        </div>
      </form>
    </Modal>
  );
}

export default ModalTicketFileAdd;
