import React from "react";
import { Loading, ButtonIcon } from "@vokymlak/kabinet-ui";
import { ProjectGeneralTasksTable } from "./ProjectGeneralTasksTable";

function ProjectGeneralTasksPage(props) {
  const {
    object,

    list,
    setList,

    setIsUpdateList,
    isUpdateList,
  } = props;

  return (
    <>
      <div className="Page__title">
        <div className="Title__text">
          <div
            className="Title__text"
            style={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
              fontSize: 19,
              fontWeight: 700,
              marginRight: 6,
            }}
          >
            <div>
              Общие задачи
              {!!list && (
                <span style={{ fontSize: 12, opacity: 0.75, marginLeft: 6 }}>
                  ({list.length})
                </span>
              )}
            </div>
          </div>
          {!!object.count_overdue_generals &&
            object.count_overdue_generals > 0 && (
              <div
                className={"Collected__count Collected__count_red"}
                style={{
                  marginRight: 2,
                  marginLeft: 2,
                  borderRadius: 24,
                }}
              >
                {object.count_overdue_generals}
              </div>
            )}
          {!!object.count_current_generals &&
            object.count_current_generals > 0 && (
              <div
                className={"Collected__count Collected__count_yellow"}
                style={{
                  marginRight: 2,
                  marginLeft: 2,
                  borderRadius: 24,
                }}
              >
                {object.count_current_generals}
              </div>
            )}
          {!!object.count_planned_generals &&
            object.count_planned_generals > 0 && (
              <div
                className={"Collected__count Collected__count_green"}
                style={{
                  marginRight: 2,
                  marginLeft: 2,
                  borderRadius: 24,
                }}
              >
                {object.count_planned_generals}
              </div>
            )}
        </div>

        <div className="Title__settings">
          <ButtonIcon
            tooltipText={"Обновить"}
            iconName={"custom-change-circle"}
            iconColor={"#20202075"}
            size={28}
            style={{ marginLeft: 8 }}
            onClick={() => (setList(null), setIsUpdateList(!isUpdateList))}
          />
        </div>
      </div>

      {!list && (
        <div className={"Page__loader"}>
          <Loading />
        </div>
      )}

      {!!list && (
        <div className="Page__content">
          <ProjectGeneralTasksTable {...props} />
        </div>
      )}
    </>
  );
}

export default ProjectGeneralTasksPage;
