import React, { useState } from "react";
import ModalCompanyAdd from "./ModalCompanyAdd";
import ModalCompanyEdit from "./ModalCompanyEdit";
import CompanyAuthorizeList from "./components/CompanyAuthorize/CompanyAuthorizeList";
import ModalCompanyAuthorizeAdd from "./ModalCompanyAuthorizeAdd";
import ModalCompanyFnsCertificateAdd from "./ModalCompanyFnsCertificateAdd";
import CompanyFnsCertificateList from "./components/CompanyFnsCertificates/CompanyFnsCertificateList";
import { ScrollBlock } from "../../../components/ScrollBlock/ScrollBlock";
import {
  Search,
  Loading,
  Pagination,
  Select,
  ButtonIcon,
  getAccountFio,
  TextSearchMarker,
} from "@vokymlak/kabinet-ui";
import styleContent from "../../../styles/modules/Block.module.scss";
import CompanyProjectsList from "./components/CompanyProjects/CompanyProjectsList";

function CompaniesPage(props) {
  const {
    list,
    setList,
    count,

    pageNumber,
    pageLimit,
    setPageLimit,
    changePage,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    inputSearchText,
    onChangeSearchText,
    searchQuery,

    setIsUpdateList,
    isUpdateList,
    updateList,
  } = props;

  const [isModalCompanyAdd, setIsModalCompanyAdd] = useState(false);
  const [modalCompanyEdit, setModalCompanyEdit] = useState(null);
  const [modalCompanyAuthorizeAdd, setModalCompanyAuthorizeAdd] =
    useState(null);
  const [modalCompanyFnsCertificateAdd, setModalCompanyFnsCertificateAdd] =
    useState(null);

  return (
    <ScrollBlock style={{ width: "100%", height: "100%" }}>
      <div className="Page">
        <div className="Page__title">
          <div
            className="Title__text"
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: 21,
              fontWeight: 700,
            }}
          >
            Компании
            {!!list && (
              <span style={{ fontSize: 12, opacity: 0.75, marginLeft: 6 }}>
                ({count})
              </span>
            )}
            <span style={{ width: 220, marginLeft: 16 }}>
              <Search
                reference={inputSearchText}
                type="text"
                label={"Поиск"}
                isFixed={true}
                onChange={() => onChangeSearchText()}
              />
            </span>
          </div>

          <div className="Title__settings">
            {!!list && (
              <ButtonIcon
                tooltipText={
                  list.length === unrolls.length ? "Свернуть" : "Развернуть"
                }
                iconName={
                  list.length === unrolls.length
                    ? "custom-unfold-less"
                    : "custom-unfold-more"
                }
                iconColor={"#20202075"}
                size={28}
                style={{ marginLeft: 8 }}
                onClick={() => changeAllUnroll(list, "uuid")}
              />
            )}

            <ButtonIcon
              tooltipText={"Обновить"}
              iconName={"custom-change-circle"}
              iconColor={"#20202075"}
              size={28}
              style={{ marginLeft: 8 }}
              onClick={() => (setList(null), setIsUpdateList(!isUpdateList))}
            />

            {!!count && !!pageLimit && (
              <div
                style={{
                  marginLeft: 12,
                  width: 64,
                }}
              >
                <Select
                  isFixed={true}
                  isDefaultOption={false}
                  value={pageLimit}
                  options={[
                    {
                      value: 100,
                      title: 100,
                    },
                    {
                      value: 500,
                      title: 500,
                    },
                  ]}
                  label="Лимит"
                  onChange={(event) => setPageLimit(Number(event.target.value))}
                />
              </div>
            )}

            {!!count && count > pageLimit && (
              <div style={{ marginLeft: 8 }}>
                <Pagination
                  totalRecords={count}
                  pageLimit={pageLimit}
                  currentPage={pageNumber}
                  onPageChanged={(data) => changePage(data)}
                />
              </div>
            )}

            <ButtonIcon
              tooltipText={"Добавить"}
              iconName={"custom-add"}
              iconColor={"#0064ff"}
              size={36}
              style={{ marginLeft: 12 }}
              onClick={() => setIsModalCompanyAdd(true)}
            />
          </div>
        </div>

        {!list && (
          <div className={"Page__loader"}>
            <Loading />
          </div>
        )}

        {!!list && (
          <div className="Page__content">
            <ul className="List">
              {list.map((item) => (
                <React.Fragment key={"item_" + item.uuid}>
                  <li
                    className={
                      isUnroll(item.uuid) ? "List__item active" : "List__item"
                    }
                    onClick={() => changeUnroll(item.uuid)}
                  >
                    <div className="Item__title">
                      <div className="Element__title">
                        <div>
                          <div>
                            <TextSearchMarker
                              text={item.short_name}
                              search={searchQuery}
                            />
                          </div>
                          <div>
                            {!!item.ogrn && (
                              <span
                                style={{
                                  marginRight: 4,
                                  fontSize: 13,
                                  fontWeight: 500,
                                }}
                              >
                                {"ОГРН: "}
                                <TextSearchMarker
                                  text={item.ogrn}
                                  search={searchQuery}
                                />
                              </span>
                            )}
                            {!!item.inn && (
                              <span
                                style={{
                                  marginRight: 4,
                                  fontSize: 13,
                                  fontWeight: 500,
                                }}
                              >
                                {"ИНН: "}
                                <TextSearchMarker
                                  text={item.inn}
                                  search={searchQuery}
                                />
                              </span>
                            )}
                            {!!item.kpp && (
                              <span
                                style={{
                                  marginRight: 4,
                                  fontSize: 13,
                                  fontWeight: 500,
                                }}
                              >
                                {" КПП: "}
                                <TextSearchMarker
                                  text={item.kpp}
                                  search={searchQuery}
                                />
                              </span>
                            )}
                          </div>
                          <div>
                            {!!item.phone && (
                              <span
                                style={{
                                  marginRight: 4,
                                  fontSize: 13,
                                  fontWeight: 500,
                                }}
                              >
                                <TextSearchMarker
                                  text={item.phone}
                                  search={searchQuery}
                                />
                              </span>
                            )}
                            {!!item.email && (
                              <span
                                style={{
                                  marginRight: 4,
                                  fontSize: 13,
                                  fontWeight: 500,
                                }}
                              >
                                <TextSearchMarker
                                  text={item.email}
                                  search={searchQuery}
                                />
                              </span>
                            )}
                          </div>
                          {!!item.company && (
                            <div
                              style={{
                                fontSize: 13,
                                fontWeight: 500,
                              }}
                            >
                              <TextSearchMarker
                                text={item.company.short_name}
                                search={searchQuery}
                              />
                            </div>
                          )}
                          {!!item.client && (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                                alignItems: "center",
                                marginTop: 6,
                              }}
                            >
                              {!!item.client && (
                                <div
                                  style={{
                                    fontSize: 13,
                                    fontWeight: 500,
                                  }}
                                >
                                  <div
                                    style={{
                                      fontSize: 12,
                                      fontWeight: 400,
                                      padding: "3px 9px 3px 9px",
                                      backgroundColor: "#20202015",
                                      marginRight: 4,
                                      borderRadius: 24,
                                      display: "flex",
                                      alignItems: "center",
                                      position: "relative",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: 10,
                                        position: "absolute",
                                        left: 12,
                                        top: -6,
                                        opacity: 0.75,
                                      }}
                                    >
                                      Клиент
                                    </span>
                                    <span>
                                      <TextSearchMarker
                                        text={getAccountFio(item.client)}
                                        search={searchQuery}
                                      />
                                    </span>
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="Item__settings">
                      <div className="Element__datetime"></div>
                    </div>
                  </li>

                  {isUnroll(item.uuid) && (
                    <li className="List__content">
                      <div className="Content__header">
                        <div className="Header__title">
                          <div className="Header__title">
                            <div
                              className="Button__text"
                              onClick={() => setModalCompanyAuthorizeAdd(item)}
                            >
                              Добавить доступ
                            </div>

                            <div
                              className="Button__text"
                              style={{
                                marginLeft: 24,
                              }}
                              onClick={() =>
                                setModalCompanyFnsCertificateAdd(item)
                              }
                            >
                              Добавить сертификат
                            </div>
                          </div>
                        </div>
                        <div className="Header__settings">
                          <ButtonIcon
                            tooltipText={"Редактировать"}
                            iconName={"custom-edit"}
                            iconColor={"#202020"}
                            size={36}
                            style={{ marginLeft: 12 }}
                            onClick={() => setModalCompanyEdit(item)}
                          />
                        </div>
                      </div>

                      <div
                        className={
                          styleContent._block + " " + styleContent._block_bottom
                        }
                      >
                        <div className={styleContent._block__content}>
                          <table className="Table">
                            <tbody>
                              {!!item.short_name && (
                                <tr className="Table__tr">
                                  <td className="Table__td">
                                    <div
                                      className="Td__title"
                                      style={{
                                        fontSize: 15,
                                      }}
                                    >
                                      Краткое наименование
                                    </div>
                                  </td>
                                  <td className="Table__td">
                                    <div
                                      className="Td__title"
                                      style={{
                                        fontSize: 15,
                                      }}
                                    >
                                      {item.short_name}
                                    </div>
                                  </td>
                                </tr>
                              )}
                              {!!item.full_name && (
                                <tr className="Table__tr">
                                  <td className="Table__td">
                                    <div
                                      className="Td__title"
                                      style={{
                                        fontSize: 15,
                                      }}
                                    >
                                      Полное наименование
                                    </div>
                                  </td>
                                  <td className="Table__td">
                                    <div
                                      className="Td__title"
                                      style={{
                                        fontSize: 15,
                                      }}
                                    >
                                      {item.full_name}
                                    </div>
                                  </td>
                                </tr>
                              )}
                              {!!item.ogrn && (
                                <tr className="Table__tr">
                                  <td className="Table__td">
                                    <div
                                      className="Td__title"
                                      style={{
                                        fontSize: 15,
                                      }}
                                    >
                                      ОГРН
                                    </div>
                                  </td>
                                  <td className="Table__td">
                                    <div
                                      className="Td__title"
                                      style={{
                                        fontSize: 15,
                                      }}
                                    >
                                      {item.ogrn}
                                    </div>
                                  </td>
                                </tr>
                              )}
                              {!!item.inn && (
                                <tr className="Table__tr">
                                  <td className="Table__td">
                                    <div
                                      className="Td__title"
                                      style={{
                                        fontSize: 15,
                                      }}
                                    >
                                      ИНН
                                    </div>
                                  </td>
                                  <td className="Table__td">
                                    <div
                                      className="Td__title"
                                      style={{
                                        fontSize: 15,
                                      }}
                                    >
                                      {item.inn}
                                    </div>
                                  </td>
                                </tr>
                              )}
                              {!!item.kpp && (
                                <tr className="Table__tr">
                                  <td className="Table__td">
                                    <div
                                      className="Td__title"
                                      style={{
                                        fontSize: 15,
                                      }}
                                    >
                                      КПП
                                    </div>
                                  </td>
                                  <td className="Table__td">
                                    <div
                                      className="Td__title"
                                      style={{
                                        fontSize: 15,
                                      }}
                                    >
                                      {item.kpp}
                                    </div>
                                  </td>
                                </tr>
                              )}
                              {!!item.legal_address && (
                                <tr className="Table__tr">
                                  <td className="Table__td">
                                    <div
                                      className="Td__title"
                                      style={{
                                        fontSize: 15,
                                      }}
                                    >
                                      Юридический адрес
                                    </div>
                                  </td>
                                  <td className="Table__td">
                                    <div
                                      className="Td__title"
                                      style={{
                                        fontSize: 15,
                                      }}
                                    >
                                      {item.legal_address}
                                    </div>
                                  </td>
                                </tr>
                              )}
                              {!!item.email && (
                                <tr className="Table__tr">
                                  <td className="Table__td">
                                    <div
                                      className="Td__title"
                                      style={{
                                        fontSize: 15,
                                      }}
                                    >
                                      Электронная почта
                                    </div>
                                  </td>
                                  <td className="Table__td">
                                    <div
                                      className="Td__title"
                                      style={{
                                        fontSize: 15,
                                      }}
                                    >
                                      {item.email}
                                    </div>
                                  </td>
                                </tr>
                              )}
                              {!!item.phone && (
                                <tr className="Table__tr">
                                  <td className="Table__td">
                                    <div
                                      className="Td__title"
                                      style={{
                                        fontSize: 15,
                                      }}
                                    >
                                      Телефон
                                    </div>
                                  </td>
                                  <td className="Table__td">
                                    <div
                                      className="Td__title"
                                      style={{
                                        fontSize: 15,
                                      }}
                                    >
                                      {item.phone}
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <CompanyAuthorizeList
                        company={item}
                        isUpdate={isUpdateList}
                      />

                      <CompanyFnsCertificateList
                        company={item}
                        isUpdate={isUpdateList}
                      />

                      <CompanyProjectsList
                        company={item}
                        isUpdate={isUpdateList}
                        isClient={false}
                      />
                    </li>
                  )}
                </React.Fragment>
              ))}
            </ul>
          </div>
        )}

        {!!modalCompanyEdit && (
          <ModalCompanyEdit
            company={modalCompanyEdit}
            successfulRequest={() => updateList()}
            handleClose={() => setModalCompanyEdit(null)}
          />
        )}
        {!!isModalCompanyAdd && (
          <ModalCompanyAdd
            successfulRequest={() => updateList()}
            handleClose={() => setIsModalCompanyAdd(false)}
          />
        )}
        {!!modalCompanyAuthorizeAdd && (
          <ModalCompanyAuthorizeAdd
            company={modalCompanyAuthorizeAdd}
            successfulRequest={() => updateList()}
            handleClose={() => setModalCompanyAuthorizeAdd(null)}
          />
        )}

        {!!modalCompanyFnsCertificateAdd && (
          <ModalCompanyFnsCertificateAdd
            company={modalCompanyFnsCertificateAdd}
            successfulRequest={() => updateList()}
            handleClose={() => setModalCompanyFnsCertificateAdd(null)}
          />
        )}
      </div>
    </ScrollBlock>
  );
}

export default CompaniesPage;
