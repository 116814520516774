import React from "react";
import ModalSpecificationAdd from "./ModalSpecificationAdd";
import ModalSpecificationEdit from "./ModalSpecificationEdit";
import ModalSpecificationImport from "./ModalSpecificationImport";
import {
  ButtonIcon, Search,
} from "@vokymlak/kabinet-ui";
import { ContractSpecificationsTable } from "./ContractSpecificationsTable";
import ModalNewSpecificationsDelete from "./ModalNewSpecificationsDelete";

function ContractSpecificationsPage(props) {
  const {
    inputSearchText,
    onChangeSearchText,

    object,

    list,
    setList,
    count,

    setIsUpdateList,
    isUpdateList,

    modalSpecificationAdd,
    setModalSpecificationAdd,

    modalSpecificationEdit,
    setModalSpecificationEdit,

    setModalSpecificationImport,
    modalSpecificationImport,

    isNewAdded,
    isModalNewContractsDelete,
    setIsModalNewContractsDelete,
  } = props;

  return (
    !!object && (
      <>
        <div
          className="Page__title"
          style={{
            marginTop: 0,
            marginBottom: 6,
          }}
        >
          <div className="Title__text">
            <div
              className="Title__text"
              style={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
                fontSize: 19,
                fontWeight: 700,
                marginRight: 6,
              }}
            >
              <div>
                Спецификации
                {!!list && (
                  <span style={{ fontSize: 12, opacity: 0.75, marginLeft: 6 }}>
                    ({count})
                  </span>
                )}
              </div>
            </div>
            <span style={{ width: 140, marginLeft: 6, marginRight: 6 }}>
              <Search
                reference={inputSearchText}
                isFixed={true}
                label={"Поиск"}
                onChange={() => onChangeSearchText()}
              />
            </span>
          </div>

          <div className="Title__settings">
            {isNewAdded(list) && (
              <ButtonIcon
                tooltipText={"Удалить добавленные"}
                iconName={"custom-delete"}
                iconColor={"#202020"}
                size={32}
                style={{ marginLeft: 12 }}
                onClick={() => setIsModalNewContractsDelete(true)}
              />
            )}

            <ButtonIcon
              tooltipText={"Обновить"}
              iconName={"custom-change-circle"}
              iconColor={"#20202075"}
              size={28}
              style={{ marginLeft: 12 }}
              onClick={() => (setList(null), setIsUpdateList(!isUpdateList))}
            />

            {/*<ButtonIcon*/}
            {/*  tooltipText={"Импорт"}*/}
            {/*  iconName={"custom-cloud-upload"}*/}
            {/*  iconColor={"#202020"}*/}
            {/*  size={26}*/}
            {/*  style={{ marginLeft: 12 }}*/}
            {/*  onClick={() => setModalSpecificationImport(object)}*/}
            {/*/>*/}

            <ButtonIcon
              tooltipText={"Добавить"}
              iconName={"custom-add"}
              iconColor={"#0064ff"}
              size={36}
              style={{ marginLeft: 12 }}
              onClick={() => setModalSpecificationAdd(true)}
            />
          </div>
        </div>

        {!!list && (
          <div
            className="Page__content"
            style={{
              position: "relative",
              height: "calc(100% - 48px - 26px - 2px - 10px - 28px)",
            }}
          >
            <div
              style={{
                overflow: "auto",
                width: "100%",
                height: "100%",
              }}
            >
              <ContractSpecificationsTable {...props} />
            </div>
          </div>
        )}

        {!!modalSpecificationImport && (
          <ModalSpecificationImport
            contract={object}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalSpecificationImport(null)}
          />
        )}
        {!!modalSpecificationAdd && (
          <ModalSpecificationAdd
            contract={object}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalSpecificationAdd(null)}
          />
        )}
        {!!modalSpecificationEdit && (
          <ModalSpecificationEdit
            specification={modalSpecificationEdit}
            contract={object}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalSpecificationEdit(null)}
          />
        )}

        {!!isModalNewContractsDelete && (
          <ModalNewSpecificationsDelete
            specifications={list}
            contract={object}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setIsModalNewContractsDelete(false)}
          />
        )}
      </>
    )
  );
}

export default ContractSpecificationsPage;
