import React, {useEffect, useState} from "react";
import { ContractStagesTableHead } from "./ContractStagesTableHead";
import { ContractStagesList } from "./ContractStagesList";

export const ContractStagesTable = (props) => {
  const {
    object,
    list,
  } = props;

  return (
    !!object && (
      <table className="Table">
        <thead className="Thead">
          <ContractStagesTableHead {...props} />
        </thead>
        <tbody>{!!list && <ContractStagesList {...props} />}</tbody>
      </table>
    )
  );
};
