import React, {useRef, useState} from "react";
import { ROOT_URL } from "../../../config/url";
import { useList, Modal, Select, getAccountFio } from "@vokymlak/kabinet-ui";
import {ROLE_DIRECTOR, ROLE_EMPLOYEE} from "../../../config/constants";

function ModalHomeFilter(props) {
  const {
    list: [listEmployees],
  } = useList({
    apiUrl: "/api/employees/list",
    rootUrl: ROOT_URL,
    urlParams: {
      roles: [ROLE_DIRECTOR, ROLE_EMPLOYEE],
    }
  });

  const selectEmployeeId = useRef(null);

  const changeAddEmployee = () => {
    const { filterEmployees, setFilterEmployees } = props.useSearchFilter;

    const employeeId = selectEmployeeId.current.value;

    if (
      !employeeId ||
      employeeId.length === 0 ||
      (employeeId.length > 0 && employeeId === "null")
    ) {
      setFilterEmployees([...[]]);
    } else {
      setFilterEmployees([employeeId]);
    }

    props.handleClose();
  };

  const getEmployeesSelectOptions = (list) => {
    const options = [
      {
        value: "null",
        title: "Все",
      },
    ];

    list.map((item) => {
      const option = {
        value: item.account.uuid,
        title: getAccountFio(item.account),
      };

      options.push(option);
    });

    return options;
  };

  return (
    <Modal handleClose={() => props.handleClose()}>
      <div className="Form">
        <div className="Form__title">Фильтры поиска</div>

            <div className="Form__field Form__field_last">
              <Select
                isFixed={true}
                reference={selectEmployeeId}
                options={
                  !!listEmployees
                    ? getEmployeesSelectOptions(listEmployees)
                    : getEmployeesSelectOptions([])
                }
                label="Сотрудник"
              />
            </div>

            <div className="Form__actions Form__actions_center">
              <button
                className="Action__button Action__button_all Action__button_blue"
                onClick={() => changeAddEmployee()}
              >
                Выбрать
              </button>
            </div>

      </div>
    </Modal>
  );
}

export default ModalHomeFilter;
