import React, {useEffect} from "react";
import {Route, Routes, Navigate, useParams, useSearchParams, useMatch} from "react-router-dom";
import "./Handbook.scss";
import { HandbookMenu } from "./HandbookMenu/HandbookMenu";
import { HandbookArticle } from "./HandbookArticle";
import { HandbookEntries } from "./HandbookEntries";
import {CatalogEntries} from "./CatalogEntries";

export const HandbookRouting = () => {


  return (
    <div className="Handbook"
         style={{
           // backgroundColor: "white",
         }}>
      {/*<div className="Menu" style={{*/}
      {/*  // borderBottom: "1px solid #ddd",*/}
      {/*  backgroundColor: "#202020",*/}
      {/*  // borderRadius: "0 0 0 7px",*/}
      {/*  boxShadow: '0 8px 12px rgba(0, 0, 0, 0.15)'*/}
      {/*}}>*/}
      {/*  <HandbookNavigate />*/}
      {/*</div>*/}
      <div className="Block" >
        <div className="App App_vertical" style={{
          // paddingLeft: 16
        }}>
          <div className="Navigate" style={{
            width: 320,
            // borderLeft: "1px solid #20202075",
            // boxShadow: "0 8px 16px rgba(0,0,0, 0.2)"
          }}>
            <HandbookMenu />
          </div>

          <div className="Content" style={{
            // borderLeft: "1px solid #ddd"

            // borderLeft: "1px solid #20202015",
            boxShadow: "-8px 0 24px rgba(0,0,0, 0.05)"
          }}>
            <Routes>
              <Route
                path="/article/:handbookEntryUuid"
                element={<HandbookArticle />}
              />
              <Route path="/entries" element={<HandbookEntries />} />
              <Route path="/explorer" element={<CatalogEntries />} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
};
