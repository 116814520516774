import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { ROOT_URL } from "../../../config/url";
import {
  getErrorMessageByKey,
  useAction,
  useList,
  useNotification,
  useProfile,
  useUnroll,
} from "@vokymlak/kabinet-ui";

export const useAccounts = () => {
  const { profile } = useProfile();

  const {
    list: [list, count, setList],
    update: [isUpdateList, setIsUpdateList, changeUpdate],
    page: [pageNumber, setPageNumber, pageLimit, setPageLimit],
    search: [searchQuery, setSearchQuery],
  } = useList({
    apiUrl: "/api/accounts",
    rootUrl: ROOT_URL,
    isPagination: true,
    paginationLimit: 100,
    isSearch: true,
  });

  const { unrolls, isUnroll, changeUnroll, changeAllUnroll } = useUnroll();

  const [isModalAddAccount, setIsModalAddAccount] = useState(false);
  const [modalEditAccount, setModalEditAccount] = useState(null);
  const [modalAddAccountRoleType, setModalAddAccountRoleType] = useState(null);

  const inputSearchText = useRef(null);
  useEffect(() => {
    setPageNumber(1);
  }, [searchQuery]);

  const { setAction } = useAction(["accounts", "isUpdate"], () => changeUpdate());

  useEffect(() => {
    if (!!list) {
      const listUuid = list.map((i) => i.uuid);
      setAction([...listUuid, "accounts", "isUpdate"]);
    } else {
      setAction(["accounts", "isUpdate"]);
    }
  }, [list]);

  const { notification } = useNotification();

  function onChangeSearchText() {
    setSearchQuery(inputSearchText.current.value);
  }

  const changePage = (data) => {
    if (pageNumber !== data.currentPage) {
      setPageNumber(data.currentPage);
    }
  };

  const isActivatedAccount = (account) => {
    axios
      .get("/api/account/" + account.uuid + "/edit/activate", {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        setIsUpdateList(!isUpdateList);
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const deleteAccountRoleType = (uuid) => {
    axios
      .delete("/api/account-role-type/" + uuid + "/delete", {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        setIsUpdateList(!isUpdateList);
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return {
    profile,

    list,
    setList,
    count,

    pageNumber,
    pageLimit,
    setPageLimit,
    changePage,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    inputSearchText,
    onChangeSearchText,
    searchQuery,

    setIsUpdateList,
    isUpdateList,

    isActivatedAccount,
    deleteAccountRoleType,

    modalEditAccount,
    setModalEditAccount,
    isModalAddAccount,
    setIsModalAddAccount,
    modalAddAccountRoleType,
    setModalAddAccountRoleType,
  };
};
