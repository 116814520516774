import React from "react";
import { Icon, Loading } from "@vokymlak/kabinet-ui";
import { ScrollBlock } from "../../../components/ScrollBlock/ScrollBlock";
import ModalLogicPurchaseWayAdd from "./ModalLogicPurchaseWayAdd";
import ModalLogicContractStatusAdd from "./ModalLogicContractStatusAdd";
import ModalLogicPurchaseBaseAdd from "./ModalLogicPurchaseBaseAdd";
import styleBlock from "../../../styles/modules/Block.module.scss";
import ModalLogicControlPointAdd from "./ModalLogicControlPointAdd";

function SettingLogicsList(props) {
  const {
    list,
    setList,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    setIsUpdateList,
    isUpdateList,

    modalObjectItemAdd,
    setModalObjectItemAdd,
    modalObjectItem2Add,
    setModalObjectItem2Add,
    modalObjectItem3Add,
    setModalObjectItem3Add,
    modalObjectItem4Add,
    setModalObjectItem4Add,

    deleteItem,
    deleteItem2,
    deleteItem3,
    deleteItem4,
  } = props;

  return (
    <ScrollBlock style={{ width: "100%", height: "100%" }}>
      <div className="Page">
        <div className="Page__title">
          <div
            className="Title__text"
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: 21,
              fontWeight: 700,
            }}
          >
            Логика
          </div>

          <div className="Title__settings">
            {!!list && (
              <div
                style={{
                  marginLeft: 12,
                }}
                className="Button__text"
                onClick={() => changeAllUnroll(list, "tag")}
              >
                {list.length === unrolls.length ? "Свернуть" : "Развернуть"}
              </div>
            )}

            <div
              style={{
                marginLeft: 12,
              }}
              className="Button__text"
              onClick={() => (setList(null), setIsUpdateList(!isUpdateList))}
            >
              Обновить
            </div>
          </div>
        </div>

        <hr className={"Page__hr"} />

        {!list && (
          <div className={"Page__loader"}>
            <Loading color={""} size={""} />
          </div>
        )}

        {!!list && (
          <div className="Page__content">
            <ul className="List">
              {list.map((item) => (
                <React.Fragment key={"item_" + item.tag}>
                  <li
                    className={
                      isUnroll(item.tag) ? "List__item active" : "List__item"
                    }
                    onClick={() => changeUnroll(item.tag)}
                  >
                    <div className="Item__title">
                      <div className="Element__title">{item.name}</div>
                    </div>
                    <div className="Item__settings">
                      <div className="Element__datetime"></div>
                    </div>
                  </li>

                  {isUnroll(item.tag) && (
                    <li className="List__content">
                      <div className="Content__header" style={{
                        marginTop: 12,
                        marginBottom: 12
                      }}>
                        <div className="Header__title">
                          <div
                            className="Button__text"
                            onClick={() => setModalObjectItem2Add(item)}
                          >
                            Добавить закон основание
                          </div>
                          <div
                            className="Button__text"
                            onClick={() => setModalObjectItem3Add(item)}
                            style={{
                              marginLeft: 24,
                            }}
                          >
                            Добавить способ закупки
                          </div>
                          <div
                            className="Button__text"
                            onClick={() => setModalObjectItemAdd(item)}
                            style={{
                              marginLeft: 24,
                            }}
                          >
                            Добавить статус
                          </div>
                          <div
                            className="Button__text"
                            onClick={() => setModalObjectItem4Add(item)}
                            style={{
                              marginLeft: 24,
                            }}
                          >
                            Добавить контрольную точку
                          </div>
                        </div>
                        <div className="Header__settings"></div>
                      </div>

                      {!!item.purchase_basis && (
                        <div
                          className={
                            styleBlock._block + " " + styleBlock._block_bottom
                          }
                        >
                          <div className={styleBlock._block__title}>
                            Закон основания
                          </div>
                          <div className={styleBlock._block__content}>
                            <table className="Table">
                              <tbody>
                                {item.purchase_basis.map(
                                  (purchaseBase, index) => (
                                    <tr className="Table__tr" key={index}>
                                      <td className="Table__td">
                                        <div className="Td__title">
                                          {index + 1}
                                          {". "}
                                          {purchaseBase.purchase_base.name}
                                        </div>
                                      </td>
                                      <td className="Table__td Table__td_child">
                                        <div
                                          className="Button__icon Button__icon_32"
                                          onClick={() =>
                                            deleteItem2(purchaseBase.uuid)
                                          }
                                        >
                                          <Icon
                                            name={"custom-delete"}
                                            size={32}
                                            color={""}
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      )}

                      {!!item.purchase_ways && (
                        <div
                          className={
                            styleBlock._block + " " + styleBlock._block_bottom
                          }
                        >
                          <div className={styleBlock._block__title}>
                            Способы закупки
                          </div>
                          <div className={styleBlock._block__content}>
                            <table className="Table">
                              <tbody>
                                {item.purchase_ways.map(
                                  (purchaseWay, index) => (
                                    <tr className="Table__tr" key={index}>
                                      <td className="Table__td">
                                        <div className="Td__title">
                                          {index + 1}
                                          {". "}
                                          {purchaseWay.purchase_way.name}
                                        </div>
                                      </td>
                                      <td className="Table__td Table__td_child">
                                        <div
                                          className="Button__icon Button__icon_32"
                                          onClick={() =>
                                            deleteItem3(purchaseWay.uuid)
                                          }
                                        >
                                          <Icon
                                            name={"custom-delete"}
                                            size={32}
                                            color={""}
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      )}

                      {!!item.contract_statuses && (
                        <div
                          className={
                            styleBlock._block + " " + styleBlock._block_bottom
                          }
                        >
                          <div className={styleBlock._block__title}>
                            Статусы
                          </div>
                          <div className={styleBlock._block__content}>
                            <table className="Table">
                              <tbody>
                                {item.contract_statuses.map(
                                  (contractStatus, index) => (
                                    <tr className="Table__tr" key={index}>
                                      <td className="Table__td">
                                        <div className="Td__title">
                                          {index + 1}
                                          {". "}
                                          {contractStatus.contract_status.name}
                                        </div>
                                      </td>
                                      <td className="Table__td Table__td_child">
                                        <div
                                          className="Button__icon Button__icon_32"
                                          onClick={() =>
                                            deleteItem(contractStatus.uuid)
                                          }
                                        >
                                          <Icon
                                            name={"custom-delete"}
                                            size={32}
                                            color={""}
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      )}

                      {!!item.control_points && (
                        <div
                          className={
                            styleBlock._block + " " + styleBlock._block_bottom
                          }
                        >
                          <div className={styleBlock._block__title}>
                            Контрольные точки
                          </div>
                          <div className={styleBlock._block__content}>
                            <table className="Table">
                              <tbody>
                              {item.control_points.map(
                                (controlPoint, index) => (
                                  <tr className="Table__tr" key={index}>
                                    <td className="Table__td">
                                      <div className="Td__title">
                                        {index + 1}
                                        {". "}
                                        {controlPoint.control_point.name}
                                      </div>
                                    </td>
                                    <td className="Table__td Table__td_child">
                                      <div
                                        className="Button__icon Button__icon_32"
                                        onClick={() =>
                                          deleteItem4(controlPoint.uuid)
                                        }
                                      >
                                        <Icon
                                          name={"custom-delete"}
                                          size={32}
                                          color={""}
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                )
                              )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      )}
                    </li>
                  )}
                </React.Fragment>
              ))}
            </ul>
          </div>
        )}

        {!!modalObjectItemAdd && (
          <ModalLogicContractStatusAdd
            object={modalObjectItemAdd}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalObjectItemAdd(null)}
          />
        )}
        {!!modalObjectItem2Add && (
          <ModalLogicPurchaseBaseAdd
            object={modalObjectItem2Add}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalObjectItem2Add(null)}
          />
        )}
        {!!modalObjectItem3Add && (
          <ModalLogicPurchaseWayAdd
            object={modalObjectItem3Add}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalObjectItem3Add(null)}
          />
        )}
        {!!modalObjectItem4Add && (
          <ModalLogicControlPointAdd
            object={modalObjectItem4Add}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalObjectItem4Add(null)}
          />
        )}
      </div>
    </ScrollBlock>
  );
}

export default SettingLogicsList;
