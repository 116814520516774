import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import {ROOT_URL} from "../../../config/url";
import {
  ROLE_DIRECTOR,
  ROLE_EMPLOYEE
} from "../../../config/constants";
import {
  getAccountFio,
  getErrorMessageByKey,
  Input,
  isValidateNullText,
  jsonFormatText,
  Modal,
  Select,
  useNotification,
  useObject,
} from "@vokymlak/kabinet-ui";
import {Avatar} from "../../../components/Avatar/Avatar";
import ModalAccountAvatarAdd from "./ModalAccountAvatarAdd";
import ModalAccountAvatarDelete from "./ModalAccountAvatarDelete";

export const ModalEditEmployee = (props) => {
  const [isPassword, setIsPassword] = useState(false);

  const inputLastName = useRef(null);
  const inputFirstName = useRef(null);
  const inputPatronymic = useRef(null);
  const inputLogin = useRef(null);
  const inputNewPassword = useRef(null);
  const inputEmail = useRef(null);
  const inputPosition = useRef(null);
  const inputRoleTypeTag = useRef(null);

  const [modalProfileAvatarAdd, setModalProfileAvatarAdd] = useState(false);
  const [modalProfileAvatarDelete, setModalProfileAvatarDelete] = useState(false);

  const {notification} = useNotification();

  const {
    object: [account],
    update: [isUpdateObject, setIsUpdateObject],
    setApiUrl,
  } = useObject({
    apiUrl: "/api/employee/" + props.account.uuid,
    rootUrl: ROOT_URL,
  });

  useEffect(() => {
    if (props.account) {
      setApiUrl("/api/employee/" + props.account.uuid);
    }
  }, [props.account]);

  const changeEdit = (event) => {
    event.preventDefault();

    const lastName = jsonFormatText(inputLastName.current.value);
    const firstName = jsonFormatText(inputFirstName.current.value);
    const patronymic = jsonFormatText(inputPatronymic.current.value);
    const login = jsonFormatText(inputLogin.current.value);
    const email = jsonFormatText(inputEmail.current.value);
    const position = jsonFormatText(inputPosition.current.value);
    const roleTypeTag = inputRoleTypeTag.current.value;

    if (
      isValidateNullText(lastName) ||
      isValidateNullText(firstName) ||
      isValidateNullText(email) ||
      isValidateNullText(login)
    ) {
      notification("Заполните все обязательные поля", "red");
      return;
    }

    const jsonData = JSON.stringify({
      last_name: lastName,
      first_name: firstName,
      patronymic: patronymic,
      login: login,
      email: email,
      position: position,
      role_type_tag: roleTypeTag,
    });

    axios
      .put("/api/employee/" + props.account.uuid + "/edit", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Данные сохранены", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const changePassword = (event) => {
    event.preventDefault();

    const newPassword = inputNewPassword.current.value.trim();

    if (!newPassword || newPassword.length === 0) {
      return;
    }

    const jsonData = JSON.stringify({
      password: newPassword,
    });

    axios
      .put("/api/employee/" + props.account.uuid + "/edit/password", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        setIsPassword(false);
        notification("Пароль изменён", "green");
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  function isMD5Hash(str) {
    // Проверяем, соответствует ли строка формату MD5 хэша.
    const md5Regex = /^[a-f0-9]{32}$/i;
    return md5Regex.test(str);
  }

  return (
    !!account && (
      <>
        <Modal handleClose={() => props.handleClose()}>
          {!isPassword && (
            <form className="Form" onSubmit={(event) => changeEdit(event)}>
              <div
                style={{
                  marginBottom: 12,
                }}
              >
                <div style={{
                  borderRadius: 96,
                  backgroundColor: "#f3f3f3",
                  height: 96,
                  width: 96,
                  marginBottom: 8,
                }}>
                  {!!account.avatar && isMD5Hash(account.avatar) && (
                    <img height={96} width={96} style={{
                      borderRadius: 96,
                    }} src={ROOT_URL + "/api/account/avatar/" + account.avatar}/>
                  )}
                  {!account.avatar && (
                    <Avatar size={96} value={getAccountFio(account)}/>
                  )}
                </div>
                {!account.avatar && (
                  <span style={{
                    display: "inline-block"
                  }}
                        className="Action__link Action__link_blue"
                        onClick={() => setModalProfileAvatarAdd(true)}
                  >
              Загрузить фото
            </span>
                )}
                {!!account.avatar && (
                  <span style={{
                    display: "inline-block"
                  }}
                        className="Action__link Action__link_red"
                        onClick={() => setModalProfileAvatarDelete(true)}
                  >
              Удалить фото
            </span>
                )}
              </div>
              <div
                className="Form__title"
                style={{fontSize: 19, fontWeight: 700}}
              >
                {getAccountFio(account)}
                <div
                  style={{
                    fontSize: 12,
                    opacity: 0.5,
                  }}
                >
                  {!!account.login && (
                    <div
                      style={{
                        fontSize: 11,
                        fontWeight: 700,
                        backgroundColor: "#202020",
                        display: "inline-block",
                        color: "white",
                        padding: "2px 6px",
                        borderRadius: 24,
                        marginRight: 4,
                      }}
                    >
                      {account.login}
                    </div>
                  )}
                </div>
                {/*<div className="Text__signature">Сотрудник</div>*/}
              </div>

              <div className="Form__field">
                <Input
                  reference={inputFirstName}
                  label="Имя *"
                  value={account.first_name}
                />
              </div>

              <div className="Form__field">
                <Input
                  reference={inputLastName}
                  label="Фамилия *"
                  value={account.last_name}
                />
              </div>

              <div className="Form__field">
                <Input
                  reference={inputPatronymic}
                  label="Отчество"
                  value={account.patronymic}
                />
              </div>

              <div className="Form__field">
                <Input reference={inputPosition}
                       label="Должность"
                       value={account.position}
                />
              </div>

              <div className="Form__field">
                <Input
                  reference={inputLogin}
                  label="Логин *"
                  value={account.login}
                />
              </div>

              <div className="Form__field">
                <Input
                  reference={inputEmail}
                  label="Электронная почта *"
                  value={account.email}
                />
              </div>

              <div className="Form__field Form__field_last">
                <Select
                  isFixed={true}
                  reference={inputRoleTypeTag}
                  options={[
                    {
                      value: ROLE_EMPLOYEE,
                      title: "Сотрудник",
                    },
                    {
                      value: ROLE_DIRECTOR,
                      title: "Руководитель",
                    },
                  ]}
                  label="Роль"
                  value={
                    !!account.account_roles && account.account_roles.length > 0
                      ? account.account_roles[0].role_type.tag
                      : null
                  }
                />
              </div>

              <div className="Form__actions Form__actions_center">
                <button className="Action__button Action__button_all Action__button_blue">
                  Применить
                </button>
              </div>

              <div className="Form__actions">
                <div
                  className="Action__link Action__link_blue"
                  onClick={() => setIsPassword(true)}
                >
                  Смена пароля
                </div>
                <div className="Action__link" onClick={() => props.handleClose()}>
                  Отмена
                </div>
              </div>
            </form>
          )}
          {!!isPassword && (
            <form className="Form" onSubmit={(event) => changePassword(event)}>
              <div
                className="Form__title"
                style={{fontSize: 19, fontWeight: 700}}
              >
                {getAccountFio(account)}
                <div className="Text__signature">Смена пароля</div>
              </div>

              <div className="Form__field Form__field_last">
                <Input reference={inputNewPassword} label="Новый пароль"/>
              </div>

              <div className="Form__actions Form__actions_center">
                <button className="Action__button Action__button_all Action__button_blue">
                  Применить
                </button>
              </div>

              <div className="Form__actions Form__actions_center">
                <div
                  className="Action__link"
                  onClick={() => setIsPassword(false)}
                >
                  Отмена
                </div>
              </div>
            </form>
          )}
        </Modal>


        {!!modalProfileAvatarAdd && (
          <ModalAccountAvatarAdd
            role={'employee'}
            account={account}
            successfulRequest={() => (setIsUpdateObject(!isUpdateObject), props.successfulRequest())}
            handleClose={() => setModalProfileAvatarAdd(null)}
          />
        )}

        {!!modalProfileAvatarDelete && (
          <ModalAccountAvatarDelete
            role={'employee'}
            account={account}
            successfulRequest={() => (setIsUpdateObject(!isUpdateObject), props.successfulRequest())}
            handleClose={() => setModalProfileAvatarDelete(null)}
          />
        )}
      </>
    )
  );
};
