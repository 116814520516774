import React, {useRef, useState} from "react";
import {ROOT_URL} from "../../../config/url";
import styleTumbler from "../../../styles/modules/Tumbler.module.scss";
import {Modal, Select, useList} from "@vokymlak/kabinet-ui";
import {ROLE_DIRECTOR, ROLE_EMPLOYEE} from "../../../config/constants";

function ModalTasksFilter(props) {
  const {
    list: [listControlPoints],
  } = useList({
    apiUrl: "/api/control-points",
    rootUrl: ROOT_URL,
  });

  const {
    list: [listProjects],
  } = useList({
    apiUrl: "/api/projects/list",
    rootUrl: ROOT_URL,
  });

  const {
    list: [listEmployees],
  } = useList({
    apiUrl: "/api/employees/list",
    rootUrl: ROOT_URL,
    urlParams: {
      roles: [ROLE_DIRECTOR, ROLE_EMPLOYEE],
    }
  });

  const selectContractStatusUuid = useRef(null);
  const selectEmployeeId = useRef(null);
  const selectProjectId = useRef(null);

  const changeAddControlPoint = () => {
    const {filterControlPoints, setFilterControlPoints} =
      props.useSearchFilter;

    const contractStatusUuid = selectContractStatusUuid.current.value;

    if (
      !contractStatusUuid ||
      contractStatusUuid.length === 0 ||
      (contractStatusUuid.length > 0 && contractStatusUuid === "null")
    ) {
      setFilterControlPoints([...[]]);
    } else {
      let isNew = true;
      filterControlPoints.map((f) => {
        if (f === contractStatusUuid) {
          isNew = false;
        }
      });
      if (isNew) {
        filterControlPoints.push(contractStatusUuid);
        setFilterControlPoints([...filterControlPoints]);
      }
    }

    props.handleClose();
  };

  const changeAddEmployee = () => {
    const {filterEmployees, setFilterEmployees} = props.useSearchFilter;

    const employeeId = selectEmployeeId.current.value;

    if (
      !employeeId ||
      employeeId.length === 0 ||
      (employeeId.length > 0 && employeeId === "null")
    ) {
      setFilterEmployees([...[]]);
    } else {
      let isNew = true;
      filterEmployees.map((f) => {
        if (f === employeeId) {
          isNew = false;
        }
      });
      if (isNew) {
        filterEmployees.push(employeeId);
        setFilterEmployees([...filterEmployees]);
      }
    }

    props.handleClose();
  };

  const changeAddEmployeeCurator = () => {
    const {filterCuratorEmployees, setFilterCuratorEmployees} = props.useSearchFilter;

    const employeeId = selectEmployeeId.current.value;

    if (
      !employeeId ||
      employeeId.length === 0 ||
      (employeeId.length > 0 && employeeId === "null")
    ) {
      setFilterCuratorEmployees([...[]]);
    } else {
      let isNew = true;
      filterCuratorEmployees.map((f) => {
        if (f === employeeId) {
          isNew = false;
        }
      });
      if (isNew) {
        filterCuratorEmployees.push(employeeId);
        setFilterCuratorEmployees([...filterCuratorEmployees]);
      }
    }

    props.handleClose();
  };

  const changeAddEmployeeSpecialist = () => {
    const {filterSpecialistEmployees, setFilterSpecialistEmployees} = props.useSearchFilter;

    const employeeId = selectEmployeeId.current.value;

    if (
      !employeeId ||
      employeeId.length === 0 ||
      (employeeId.length > 0 && employeeId === "null")
    ) {
      setFilterSpecialistEmployees([...[]]);
    } else {
      let isNew = true;
      filterSpecialistEmployees.map((f) => {
        if (f === employeeId) {
          isNew = false;
        }
      });
      if (isNew) {
        filterSpecialistEmployees.push(employeeId);
        setFilterSpecialistEmployees([...filterSpecialistEmployees]);
      }
    }

    props.handleClose();
  };

  const changeAddEmployeeExecutor = () => {
    const {filterExecutorEmployees, setFilterExecutorEmployees} = props.useSearchFilter;

    const employeeId = selectEmployeeId.current.value;

    if (
      !employeeId ||
      employeeId.length === 0 ||
      (employeeId.length > 0 && employeeId === "null")
    ) {
      setFilterExecutorEmployees([...[]]);
    } else {
      let isNew = true;
      filterExecutorEmployees.map((f) => {
        if (f === employeeId) {
          isNew = false;
        }
      });
      if (isNew) {
        filterExecutorEmployees.push(employeeId);
        setFilterExecutorEmployees([...filterExecutorEmployees]);
      }
    }

    props.handleClose();
  };

  const changeAddProject = () => {
    const {filterProjects, setFilterProjects} = props.useSearchFilter;

    const projectId = selectProjectId.current.value;

    if (
      !projectId ||
      projectId.length === 0 ||
      (projectId.length > 0 && projectId === "null")
    ) {
      setFilterProjects([...[]]);
    } else {
      let isNew = true;
      filterProjects.map((f) => {
        if (f === projectId) {
          isNew = false;
        }
      });
      if (isNew) {
        filterProjects.push(projectId);
        setFilterProjects([...filterProjects]);
      }
    }

    props.handleClose();
  };

  const getControlPointsSelectOptions = (list) => {
    const options = [
      {
        value: "null",
        title: "Все",
      },
    ];

    list.map((item) => {
      const option = {
        value: item.uuid,
        title: item.name,
      };

      options.push(option);
    });

    return options;
  };

  const getProjectsSelectOptions = (list) => {
    const options = [
      {
        value: "null",
        title: "Все",
      },
    ];

    list.map((item) => {
      const option = {
        value: item.id,
        title: item.name,
      };

      options.push(option);
    });

    return options;
  };

  const getEmployeesSelectOptions = (list) => {
    const options = [
      {
        value: "null",
        title: "Все",
      },
    ];

    list.map((item) => {
      const option = {
        value: item.account.id,
        title:
          item.account.last_name +
          " " +
          item.account.first_name +
          " " +
          (!!item.account.patronymic ? item.account.patronymic : ""),
      };

      options.push(option);
    });

    return options;
  };

  const [tumbler, setTumbler] = useState("a");

  return (
    <Modal handleClose={() => props.handleClose()}>
      <div className="Form">
        <div className="Form__title">Фильтры поиска</div>

        <div className="Form__field">
          <ul
            className={styleTumbler._}
            style={{
              justifyContent: "space-between",
              backgroundColor: "#f5f5f5",
            }}
          >
            <li className={styleTumbler._link}>
              <a
                className={
                  styleTumbler._text +
                  ((!!tumbler &&
                      tumbler === "a" &&
                      " " + styleTumbler.active + " ") ||
                    "")
                }
                onClick={() => setTumbler("a")}
                style={{
                  cursor: "pointer",
                }}
              >
                Сотрудник
              </a>
            </li>
            {/*<li className={styleTumbler._link}>*/}
            {/*  <a*/}
            {/*    className={*/}
            {/*      styleTumbler._text +*/}
            {/*      ((!!tumbler &&*/}
            {/*          tumbler === "aa" &&*/}
            {/*          " " + styleTumbler.active + " ") ||*/}
            {/*        "")*/}
            {/*    }*/}
            {/*    onClick={() => setTumbler("aa")}*/}
            {/*    style={{*/}
            {/*      cursor: "pointer",*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    Куратор*/}
            {/*  </a>*/}
            {/*</li>*/}
            {/*<li className={styleTumbler._link}>*/}
            {/*  <a*/}
            {/*    className={*/}
            {/*      styleTumbler._text +*/}
            {/*      ((!!tumbler &&*/}
            {/*          tumbler === "ab" &&*/}
            {/*          " " + styleTumbler.active + " ") ||*/}
            {/*        "")*/}
            {/*    }*/}
            {/*    onClick={() => setTumbler("ab")}*/}
            {/*    style={{*/}
            {/*      cursor: "pointer",*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    Специалист*/}
            {/*  </a>*/}
            {/*</li>*/}
            <li className={styleTumbler._link}>
              <a
                className={
                  styleTumbler._text +
                  ((!!tumbler &&
                      tumbler === "ac" &&
                      " " + styleTumbler.active + " ") ||
                    "")
                }
                onClick={() => setTumbler("ac")}
                style={{
                  cursor: "pointer",
                }}
              >
                Исполнитель
              </a>
            </li>
          </ul>
        </div>


        <div className="Form__field">
          <ul
            className={styleTumbler._}
            style={{
              justifyContent: "space-between",
              backgroundColor: "#f5f5f5",
            }}
          >
            {/*<li className={styleTumbler._link}>*/}
            {/*  <a*/}
            {/*    className={*/}
            {/*      styleTumbler._text +*/}
            {/*      ((!!tumbler &&*/}
            {/*          tumbler === "a" &&*/}
            {/*          " " + styleTumbler.active + " ") ||*/}
            {/*        "")*/}
            {/*    }*/}
            {/*    onClick={() => setTumbler("a")}*/}
            {/*    style={{*/}
            {/*      cursor: "pointer",*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    Сотрудник*/}
            {/*  </a>*/}
            {/*</li>*/}
            <li className={styleTumbler._link}>
              <a
                className={
                  styleTumbler._text +
                  ((!!tumbler &&
                      tumbler === "b" &&
                      " " + styleTumbler.active + " ") ||
                    "")
                }
                onClick={() => setTumbler("b")}
                style={{
                  cursor: "pointer",
                }}
              >
                Проект
              </a>
            </li>
            <li className={styleTumbler._link}>
              <a
                className={
                  styleTumbler._text +
                  ((!!tumbler &&
                      tumbler === "c" &&
                      " " + styleTumbler.active + " ") ||
                    "")
                }
                onClick={() => setTumbler("c")}
                style={{
                  cursor: "pointer",
                }}
              >
                Этап
              </a>
            </li>
          </ul>
        </div>

        {!!tumbler && tumbler === "a" && (
          <>
            <div className="Form__field Form__field_last">
              <Select
                isFixed={true}
                reference={selectEmployeeId}
                options={
                  !!listEmployees
                    ? getEmployeesSelectOptions(listEmployees)
                    : getEmployeesSelectOptions([])
                }
                label="Сотрудник"
              />
            </div>

            <div className="Form__actions Form__actions_center">
              <button
                className="Action__button Action__button_all Action__button_blue"
                onClick={() => changeAddEmployee()}
              >
                Добавить
              </button>
            </div>
          </>
        )}

        {!!tumbler && tumbler === "aa" && (
          <>
            <div className="Form__field Form__field_last">
              <Select
                isFixed={true}
                reference={selectEmployeeId}
                options={
                  !!listEmployees
                    ? getEmployeesSelectOptions(listEmployees)
                    : getEmployeesSelectOptions([])
                }
                label="Куратор"
              />
            </div>

            <div className="Form__actions Form__actions_center">
              <button
                className="Action__button Action__button_all Action__button_blue"
                onClick={() => changeAddEmployeeCurator()}
              >
                Добавить
              </button>
            </div>
          </>
        )}

        {!!tumbler && tumbler === "ab" && (
          <>
            <div className="Form__field Form__field_last">
              <Select
                isFixed={true}
                reference={selectEmployeeId}
                options={
                  !!listEmployees
                    ? getEmployeesSelectOptions(listEmployees)
                    : getEmployeesSelectOptions([])
                }
                label="Специалист"
              />
            </div>

            <div className="Form__actions Form__actions_center">
              <button
                className="Action__button Action__button_all Action__button_blue"
                onClick={() => changeAddEmployeeSpecialist()}
              >
                Добавить
              </button>
            </div>
          </>
        )}

        {!!tumbler && tumbler === "ac" && (
          <>
            <div className="Form__field Form__field_last">
              <Select
                isFixed={true}
                reference={selectEmployeeId}
                options={
                  !!listEmployees
                    ? getEmployeesSelectOptions(listEmployees)
                    : getEmployeesSelectOptions([])
                }
                label="Исполнитель"
              />
            </div>

            <div className="Form__actions Form__actions_center">
              <button
                className="Action__button Action__button_all Action__button_blue"
                onClick={() => changeAddEmployeeExecutor()}
              >
                Добавить
              </button>
            </div>
          </>
        )}

        {!!tumbler && tumbler === "b" && (
          <>
            <div className="Form__field Form__field_last">
              <Select
                isFixed={true}
                reference={selectProjectId}
                options={
                  !!listProjects
                    ? getProjectsSelectOptions(listProjects)
                    : getProjectsSelectOptions([])
                }
                label="Проект"
              />
            </div>

            <div className="Form__actions Form__actions_center">
              <button
                className="Action__button Action__button_all Action__button_blue"
                onClick={() => changeAddProject()}
              >
                Добавить
              </button>
            </div>
          </>
        )}

        {!!tumbler && tumbler === "c" && (
          <>
            <div className="Form__field Form__field_last">
              <Select
                isFixed={true}
                reference={selectContractStatusUuid}
                options={
                  !!listControlPoints
                    ? getControlPointsSelectOptions(listControlPoints)
                    : getControlPointsSelectOptions([])
                }
                label="Этап"
              />
            </div>

            <div className="Form__actions Form__actions_center">
              <button
                className="Action__button Action__button_all Action__button_blue"
                onClick={() => changeAddControlPoint()}
              >
                Добавить
              </button>
            </div>
          </>
        )}

        {/*<div className="Form__actions Form__actions_center">*/}
        {/*  <div className="Action__link" onClick={() => props.handleClose()}>*/}
        {/*    Отмена*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
    </Modal>
  );
}

export default ModalTasksFilter;
