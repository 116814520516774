import React, { useEffect, useRef, useState } from "react";
import { ROOT_URL } from "../../../config/url";
import { useAction, useObject } from "@vokymlak/kabinet-ui";
import { useParams } from "react-router-dom";

function useContract() {
  let { contractUuid } = useParams();

  const {
    object: [object],
    update: [isUpdateObject, setIsUpdateObject, updateObject],
  } = useObject({
    apiUrl: "/api/client/contract/" + contractUuid,
    rootUrl: ROOT_URL,
  });

  useAction(contractUuid, () => updateObject());

  return {
    object,
    isUpdateObject,
    setIsUpdateObject,
  };
}

export default useContract;
