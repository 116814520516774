import React, { useRef, useState } from "react";
import Modal from "../../../components/Modal/Modal";
import axios from "axios";
import { ROOT_URL } from "../../../config/url";
import { Input } from "../../../components/Input";
import {getErrorMessageByKey, useNotification} from "@vokymlak/kabinet-ui";
import useObject from "../../../hooks/useObject";

function ModalPurchaseWayEdit(props) {
  const {
    object: [object],
  } = useObject("/api/purchase-way/" + props.purchaseWay.uuid, ROOT_URL);

  const inputName = useRef(null);

  const [isDelete, setIsDelete] = useState(false);

  const { notification } = useNotification();

  const changeEdit = (event) => {
    event.preventDefault();

    const name = inputName.current.value;

    if (!name || name.length === 0) {
      return;
    }

    const jsonData = JSON.stringify({
      name: name,
    });

    axios
      .put("/api/purchase-way/" + props.purchaseWay.uuid + "/edit", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });

    props.handleClose();
  };

  const changeDelete = (event) => {
    event.preventDefault();

    axios
      .delete("/api/purchase-way/" + props.purchaseWay.uuid + "/delete", {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });

    props.handleClose();
  };

  return (
    !!object && (
      <Modal handleClose={() => props.handleClose}>
        {!isDelete && (
          <form className="Form" onSubmit={(event) => changeEdit(event)}>
            <div className="Form__title">
              {object.name}
              <div className="Text__signature">Способ закупки</div>
            </div>

            <div className="Form__field">
              <Input
                reference={inputName}
                type="text"
                label="Наименование *"
                value={object.name}
              />
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_blue">
                Применить
              </button>
            </div>

            <div className="Form__actions">
              <div
                className="Action__link Action__link_red"
                onClick={() => setIsDelete(true)}
              >
                Удалить
              </div>
              <div className="Action__link" onClick={() => props.handleClose()}>
                Отмена
              </div>
            </div>
          </form>
        )}
        {isDelete && (
          <form className="Form" onSubmit={(event) => changeDelete(event)}>
            <div className="Form__title">
              {object.name}
              <div className="Text__signature">Удалить</div>
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_red">
                Продолжить
              </button>
            </div>

            <div className="Form__actions Form__actions_center">
              <div className="Action__link" onClick={() => setIsDelete(false)}>
                Отмена
              </div>
            </div>
          </form>
        )}
      </Modal>
    )
  );
}

export default ModalPurchaseWayEdit;
