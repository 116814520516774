import {useEffect, useState} from 'react';

export const useCtrlKey = (onKeyDownCallback, onKeyUpCallback) => {
  const [isDown, setIsDown] = useState(false)
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 17 && !isDown) {
        setIsDown(true)
        onKeyDownCallback();
      }
    };

    const handleKeyUp = (event) => {
      if (event.keyCode === 17) {
        setIsDown(false)
        onKeyUpCallback();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [onKeyDownCallback, onKeyUpCallback]);
};
