import React, { useRef, useState } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../../../config/url";
import {
  getErrorMessageByKey,
  useNotification,
  useList,
  Modal,
  useObject,
  Select
} from "@vokymlak/kabinet-ui";

function ModalContractsEditBase(props) {
  const [purchaseBaseId, setPurchaseBaseId] = useState(null);

  const {
    list: [listPurchaseBasis],
  } = useList({ apiUrl: "/api/purchase-basis", rootUrl: ROOT_URL });

  const {
    object: [project],
  } = useObject({
    apiUrl: "/api/project/" + props.project.uuid,
    rootUrl: ROOT_URL,
    callbackError: () => {
      props.handleClose();
    },
  });

  const selectPurchaseBaseId = useRef(null);
  const selectPurchaseWayId = useRef(null);

  const { notification } = useNotification();

  const changeAdd = (event) => {
    event.preventDefault();

    const purchaseBaseId =
      (!!selectPurchaseBaseId.current && selectPurchaseBaseId.current.value) ||
      "";
    const purchaseWayId =
      (!!selectPurchaseWayId.current && selectPurchaseWayId.current.value) ||
      "";

    const jsonData = JSON.stringify({
      contracts: props.contracts,
      project_id: project.id,
      purchase_base_id:
        purchaseBaseId.length > 0
          ? purchaseBaseId === "null"
            ? null
            : Number(purchaseBaseId)
          : null,
      purchase_way_id:
        purchaseWayId.length > 0
          ? purchaseWayId === "null"
            ? null
            : Number(purchaseWayId)
          : null,
    });

    axios
      .put("/api/contracts/edit/base", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Данные сохранены", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const getPurchaseBasisSelectOptions = (list) => {
    const options = [
      {
        value: "null",
        title: "Не выбрано",
      },
    ];

    list.map((item) => {
      if (!!project && !!project.law && project.law.tag === item.law.tag) {
        const option = {
          value: item.id,
          title: item.name,
        };

        options.push(option);
      }
    });

    return options;
  };

  const getPurchaseWaysSelectOptions = (purchaseBaseId, list) => {
    const options = [
      {
        value: "null",
        title: "Не выбрано",
      },
    ];

    list.map((item) => {
      if (!!project && !!project.law && project.law.tag === item.law.tag) {
        if (!!item.ways && !!purchaseBaseId && purchaseBaseId === item.id) {
          item.ways.map((way) => {
            const option = {
              value: way.purchase_way.id,
              title: way.purchase_way.name,
            };

            options.push(option);
          });
        }
      }
    });

    return options;
  };

  const onChangeSelectPurchaseBasis = () => {
    const purchaseBaseId = selectPurchaseBaseId.current.value;
    setPurchaseBaseId(
      purchaseBaseId.length > 0
        ? purchaseBaseId === "null"
          ? null
          : Number(purchaseBaseId)
        : null
    );
  };

  return (
    !!project &&
    !!listPurchaseBasis && (
      <Modal handleClose={() => props.handleClose()}>
        <form className="Form" onSubmit={(event) => changeAdd(event)}>
          <div className="Form__title">
            Договоры
            <div className="Text__signature">Основание</div>
          </div>

          <div className="Form__field">
            <Select
              isFixed={true}
              reference={selectPurchaseBaseId}
              options={
                !!listPurchaseBasis
                  ? getPurchaseBasisSelectOptions(listPurchaseBasis)
                  : getPurchaseBasisSelectOptions([])
              }
              label="Закон-основание"
              onChange={() => onChangeSelectPurchaseBasis()}
            />
          </div>

          {!!purchaseBaseId && (
            <div className="Form__field">
              <Select
                isFixed={true}
                reference={selectPurchaseWayId}
                options={
                  !!listPurchaseBasis
                    ? getPurchaseWaysSelectOptions(
                        purchaseBaseId,
                        listPurchaseBasis
                      )
                    : getPurchaseWaysSelectOptions(purchaseBaseId, [])
                }
                value={null}
                label="Способ закупки"
              />
            </div>
          )}

          <div className="Form__actions Form__actions_center">
            <button className="Action__button Action__button_all Action__button_blue">
              Применить
            </button>
          </div>

          {/*<div className="Form__actions Form__actions_center">*/}
          {/*  <div className="Action__link" onClick={() => props.handleClose()}>*/}
          {/*    Отмена*/}
          {/*  </div>*/}
          {/*</div>*/}
        </form>
      </Modal>
    )
  );
}

export default ModalContractsEditBase;
