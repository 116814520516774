import React from "react";
import moment from "moment/moment";
import { GeneralTasksList } from "./GeneralTasksList";
import { Icon } from "@vokymlak/kabinet-ui";

export const GeneralTasksTable = (props) => {
  const {
    isUpdateCalendarTasks,
    setIsUpdateCalendarTasks,

    dayGeneralTasks,
    isUpdateDayGeneralTasks,
    setIsUpdateDayGeneralTasks,

    overdueGeneralTasks,
    isUpdateOverdueGeneralTasks,
    setIsUpdateOverdueGeneralTasks,

    isGeneralTasksOverdue,

    selectDate,
    toDay,
  } = props;

  return (
    <table className="Table">
      <thead className="Thead">
      <tr className="Table__tr Table__tr_padding Table__tr_border">
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
            width: "0%",
          }}
        >
          <div
            className="Th__title"
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Icon name={"custom-priority"} size={12} color={"#202020"} />
          </div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div
            className="Th__title"
            style={{
              textAlign: "center",
            }}
          >
            Проект
          </div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">Задача</div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">Описание</div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">Примечание</div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">Дата исполнения</div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">Отметка</div>
        </th>
      </tr>
      </thead>
      <tbody>
        {/*{!!overdueGeneralTasks &&*/}
        {/*  toDay.format("YYYY-MM-DD") ===*/}
        {/*    moment(selectDate, "YYYY-MM-DD").format("YYYY-MM-DD") && (*/}
        {/*    <GeneralTasksList*/}
        {/*      list={overdueGeneralTasks}*/}
        {/*      isRed={true}*/}
        {/*      successfulRequest={() => (*/}
        {/*        setIsUpdateCalendarTasks(!isUpdateCalendarTasks),*/}
        {/*        setIsUpdateDayGeneralTasks(!isUpdateDayGeneralTasks),*/}
        {/*        setIsUpdateOverdueGeneralTasks(!isUpdateOverdueGeneralTasks)*/}
        {/*      )}*/}
        {/*    />*/}
        {/*  )}*/}

        {isGeneralTasksOverdue && !!overdueGeneralTasks && (
            <GeneralTasksList
              list={overdueGeneralTasks}
              isRed={true}
              successfulRequest={() => (
                setIsUpdateCalendarTasks(!isUpdateCalendarTasks),
                  setIsUpdateDayGeneralTasks(!isUpdateDayGeneralTasks),
                  setIsUpdateOverdueGeneralTasks(!isUpdateOverdueGeneralTasks)
              )}
            />
          )}

        {!isGeneralTasksOverdue && !!dayGeneralTasks && (
          <GeneralTasksList
            list={dayGeneralTasks}
            isRed={false}
            successfulRequest={() => (
              setIsUpdateCalendarTasks(!isUpdateCalendarTasks),
              setIsUpdateDayGeneralTasks(!isUpdateDayGeneralTasks),
              setIsUpdateOverdueGeneralTasks(!isUpdateOverdueGeneralTasks)
            )}
          />
        )}
      </tbody>
    </table>
  );
};
