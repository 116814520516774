import React, { useRef, useState } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../config/url";
import {
  Modal,
  getErrorMessageByKey,
  useNotification,
  Textarea,
  Input,
  jsonFormatText,
  isValidateNullText,
} from "@vokymlak/kabinet-ui";

function ModalGeneralTaskAdd(props) {
  const inputTitle = useRef(null);
  const inputDescription = useRef(null);

  const inputDateControl = useRef(null);
  const [isPriority, setIsPriority] = useState(false);

  const { notification } = useNotification();

  const changeAdd = (event) => {
    event.preventDefault();

    const title = jsonFormatText(inputTitle.current.value);
    const description = jsonFormatText(inputDescription.current.value);
    const dateControl = inputDateControl.current.value;

    if (isValidateNullText(title) || isValidateNullText(dateControl)) {
      notification("Заполните все обязательные поля", "red");
      return;
    }

    const jsonData = JSON.stringify({
      title: title,
      description: description,
      is_priority: isPriority,
      date_control:
        !!dateControl && dateControl.length > 0 ? dateControl : null,
    });

    axios
      .post("/api/general-task/add", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Общая задача добавлена", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    <Modal handleClose={() => props.handleClose()}>
      <form className="Form" onSubmit={(event) => changeAdd(event)}>
        <div className="Form__title">
          Новая
          <div className="Text__signature">Общая задача</div>
        </div>

        <div className="Form__field">
          <Textarea reference={inputTitle} label="Заголовок *" />
        </div>

        <div className="Form__field">
          <Textarea reference={inputDescription} label="Описание" />
        </div>

        <div className="Form__field">
          <Input
            reference={inputDateControl}
            type="date"
            isFixed={true}
            label="Дата исполнения *"
          />
        </div>

        <div className="Form__field Form__field_last">
          <div
            className="Button__swith"
            style={{
              padding: "8px",
              width: "100%",
              border: isPriority ? "2px solid #ffe082" : "2px dashed #20202015",
              backgroundColor: isPriority ? "#ffe082" : "#ffffff",
              borderRadius: 6,
              marginTop: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              cursor: "pointer",
            }}
            onClick={() => setIsPriority(!isPriority)}
          >
            <div
              className="Swith__text"
              style={{
                fontSize: 17,
                fontWeight: 600,
                display: "flex",
                alignItems: "center",
              }}
            >
              Приоритетная
            </div>
            <div
              className={
                "Swith Swith_gray" +
                ((isPriority && " Swith_active Swith_active_gray") || "")
              }
            ></div>
          </div>
        </div>

        <div className="Form__actions Form__actions_center">
          <button className="Action__button Action__button_all Action__button_blue">
            Добавить
          </button>
        </div>

        {/*<div className="Form__actions Form__actions_center">*/}
        {/*  <div className="Action__link" onClick={() => props.handleClose()}>*/}
        {/*    Отмена*/}
        {/*  </div>*/}
        {/*</div>*/}
      </form>
    </Modal>
  );
}

export default ModalGeneralTaskAdd;
