import React, {useEffect, useRef, useState} from "react";
import { ROOT_URL } from "../../../config/url";
import {useAction, useUnroll, useList, useProfile, useObject} from "@vokymlak/kabinet-ui";

function useMyAppeals() {
  const { profile } = useProfile();

  const [isArchive, setIsArchive] = useState(false);
  const [status, setStatus] = useState("null");

  const getParams = () => {
    let params = null;
    if (isArchive) {
      params = {
        ...params,
        archive: isArchive,
      };
    }
    if (!!status && status !== "null") {
      params = {
        ...params,
        status: status,
      };
    }

    return params
  }

  const {
    list: [list, count, setList],
    update: [isUpdateList, setIsUpdateList, updateList],
    page: [pageNumber, setPageNumber, pageLimit, setPageLimit],
    search: [searchQuery, setSearchQuery],
    setApiUrlParams,
  } = useList({
    apiUrl: "/api/my/appeals",
    rootUrl: ROOT_URL,
    isPagination: true,
    paginationLimit: 25,
    isSearch: true,
    urlParams: getParams()
  });

  const {
    object: [object, setObject],
    update: [isUpdateObject, setIsUpdateObject, changeUpdateObject],
  } = useObject({
    apiUrl: "/api/my/appeals/count",
    rootUrl: ROOT_URL,
  });

  const inputSearchText = useRef(null);
  useEffect(() => {
    setPageNumber(1);
  }, [searchQuery]);
  const onChangeSearchText = () => {
    setSearchQuery(inputSearchText.current.value);
  };
  const changePage = (data) => {
    if (pageNumber !== data.currentPage) {
      setPageNumber(data.currentPage);
    }
  };

  useEffect(() => {
    setApiUrlParams({ ...getParams() });
  }, [isArchive, status]);

  const { setAction } =
    useAction(["appeals", profile.uuid], () => (updateList(), changeUpdateObject()));
  useEffect(() => {
    if (!!list) {
      const listUuid = list.map((i) => i.uuid);
      setAction([...listUuid, "appeals", profile.uuid]);
    } else {
      setAction(["appeals", profile.uuid]);
    }
  }, [list]);

  const { unrolls, isUnroll, changeUnroll, changeAllUnroll } = useUnroll();

  return {
    object,
    isUpdateObject,
    setIsUpdateObject,

    list,
    setList,
    count,

    pageNumber,
    pageLimit,
    setPageLimit,
    changePage,

    isUnroll,

    inputSearchText,
    onChangeSearchText,
    searchQuery,

    setIsUpdateList,
    isUpdateList,

    isArchive,
    setIsArchive,

    setStatus,
    status,
  };
}

export default useMyAppeals;
