import React from "react";
import "./components/Auth.scss";
import {Navigate, Route, Routes} from "react-router-dom";
import {Registration} from "./Registration/Registration";
import {Restore} from "./Restore/Restore";
import {ResetPassword} from "./ResetPassword/ResetPassword";
import {Login} from "./Login/Login";
import {Toaster} from "sonner";

export const Auth = () => {
  return (
    <>
      <Routes>
        <Route exact path="/" element={<Navigate to="/login" replace/>}/>

        <Route path="/login" element={<Login/>}/>
        {/*<Route path="/registration" element={<Registration/>}/>*/}
        <Route path="/restore" element={<Restore/>}/>
        <Route path="/reset/password/:resetKey" element={<ResetPassword/>}/>

        <Route path="/*" element={<Navigate to="/login" replace/>}/>
      </Routes>

      <Toaster
        expand={false}
        position="bottom-center"
        richColors
      />
    </>
  );
};
