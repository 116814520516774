import React, { Fragment, useEffect, useState } from "react";
import {Tooltip, Loading, Icon, ButtonIcon} from "@vokymlak/kabinet-ui";
import ModalPurchaseWayAdd from "./ModalPurchaseWayAdd";
import ModalPurchaseWayEdit from "./ModalPurchaseWayEdit";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import styleContent from "../../../styles/modules/Block.module.scss";
import styleCard from "../../../styles/modules/Card.module.scss";
import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";

export const StrictModeDroppable = ({ children, ...props }) => {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  if (!enabled) {
    return null;
  }

  return <Droppable {...props}>{children}</Droppable>;
};

function PurchaseWaysList(props) {
  const {
    list,
    setList,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    setIsUpdateList,
    isUpdateList,

    modalItemEdit,
    setModalItemEdit,
    isModalObjectAdd,
    setIsModalObjectAdd,

    onDragEndDrop,
  } = props;

  return (
    <ScrollBlock
      style={{ width: '100%', height: '100%' }}>
    <div className="Page">
      <div className="Page__title">
        <div className="Title__text">
          <div
            className="Title__text"
            style={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
              fontSize: 19,
              fontWeight: 700,
            }}
          >
            <div>
              Способы закупок
              {!!list && (
                <span style={{fontSize: 12, opacity: 0.75, marginLeft: 6}}>
                    ({list.length})
                  </span>
              )}
            </div>
            <span
              style={{
                fontSize: 12,
                fontWeight: 400,
              }}
            >
                Должны совпадать с РИС до регистра, иначе при импорте будет сбиваться (все способы закупок в этой таблице должны быть уникальны)
              </span>
          </div>
        </div>

        <div className="Title__settings">
          {!!list && (
            <div
              style={{ marginLeft: 12 }}
              className="Button__text"
              onClick={() => changeAllUnroll(list, "uuid")}
            >
              {list.length === unrolls.length ? "Свернуть" : "Развернуть"}
            </div>
          )}

          <div
            style={{ marginLeft: 12 }}
            className="Button__text"
            onClick={() => (setList(null), setIsUpdateList(!isUpdateList))}
          >
            Обновить
          </div>

          <ButtonIcon
            tooltipText={"Добавить"}
            iconName={"custom-add"}
            iconColor={"#0064ff"}
            size={36}
            style={{ marginLeft: 12 }}
            onClick={() => setIsModalObjectAdd(true)}
          />
        </div>
      </div>

      <div className="Page__content">
        <ul className="List">
          {!list && (
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                flexWrap: "nowrap",
                alignItems: "center",
                justifyContent: "center",
                padding: "100px 0",
              }}
            >
              <Loading/>
            </div>
          )}

          <DragDropContext onDragEnd={onDragEndDrop}>
            <StrictModeDroppable droppableId={"droppable"}>
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {!!list &&
                    list.map((item, index) => (
                      <Draggable
                        draggableId={String(item.uuid)}
                        index={index}
                        key={"draggable_" + item.uuid}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.dragHandleProps}
                            {...provided.draggableProps}
                          >
                            <Fragment key={"item_" + item.uuid}>
                              <li
                                className={
                                  isUnroll(item.uuid)
                                    ? "List__item active"
                                    : "List__item"
                                }
                                onClick={() => changeUnroll(item.uuid)}
                                style={{
                                  position: "relative",
                                }}
                              >
                                <div className="Item__title">
                                  <div className="Element__title">
                                    {index + 1}
                                    {" | "}
                                    {item.name}
                                  </div>

                                  <div  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    position: "absolute",
                                    top: -8,
                                    left: 0,
                                    justifyContent:" flex-end",
                                    width: "100%",
                                    padding: "0 8px",
                                  }}>

                                    {!!item.purchase_way_basis &&
                                      item.purchase_way_basis.map(p => (
                                      <div
                                        key={'purchase_base_' + p.uuid}
                                        style={{
                                          fontSize: 11,
                                          fontWeight: 700,
                                          backgroundColor: "#202020",
                                          display: "inline-block",
                                          color: "white",
                                          padding: "2px 4px 2px",
                                          borderRadius: 3,
                                          margin: "0 2px",
                                        }}
                                      >
                                        {p.purchase_base.name}
                                      </div>
                                    ))}


                                  </div>
                                </div>
                                <div className="Item__settings">
                                  <div className="Element__datetime"></div>
                                </div>
                              </li>

                              {isUnroll(item.uuid) && (
                                <li className="List__content">
                                  <div className={styleContent._block}>
                                    <div
                                      className={styleContent._block__content}
                                    >
                                      <div className={styleCard._block}>
                                        <div
                                          className={styleCard._block__number}
                                        >
                                          {!!item.purchase_way_basis
                                            ? item.purchase_way_basis.length
                                            : "0"}
                                        </div>
                                        <div
                                          className={styleCard._block__title}
                                        >
                                          Условия
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="Content__footer">
                                    <div className="Footer__title"></div>
                                    <div className="Footer__settings">
                                      <Tooltip
                                        text={"Редактировать"}
                                        style={{ marginLeft: 24 }}
                                      >
                                        <div
                                          className="Button__icon Button__icon_42"
                                          onClick={() => setModalItemEdit(item)}
                                        >
                                          <Icon
                                            name={"custom-edit"}
                                            size={42}
                                            color={""}
                                          />
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </div>
                                </li>
                              )}
                            </Fragment>
                          </div>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </StrictModeDroppable>
          </DragDropContext>
        </ul>
      </div>

      {!!modalItemEdit && (
        <ModalPurchaseWayEdit
          purchaseWay={modalItemEdit}
          successfulRequest={() => setIsUpdateList(!isUpdateList)}
          handleClose={() => setModalItemEdit(null)}
        />
      )}
      {!!isModalObjectAdd && (
        <ModalPurchaseWayAdd
          successfulRequest={() => setIsUpdateList(!isUpdateList)}
          handleClose={() => setIsModalObjectAdd(false)}
        />
      )}
    </div>
    </ScrollBlock>
  );
}

export default PurchaseWaysList;
