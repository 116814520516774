import React from "react";
import {Icon, Input} from "@vokymlak/kabinet-ui";
import { ProjectTasksList } from "./ProjectTasksList";

export const ProjectTasksTable = (props) => {
  const {
    list,

    setIsUpdateList,
    isUpdateList,

    selectContracts,
    isSelectAllContract,
    changeSelectAllContract,
  } = props;

  return (
    <table className="Table">
      <thead className="Thead">
        <tr className="Table__tr Table__tr_padding Table__tr_border">
          <th
            className="Table__th Table__th_border"
            style={{
              backgroundColor: "#dfdfdf",
              width: 24
            }}
          >
            <div className="Th__title" style={{ height: 40 }}>
              <Input type={"checkbox"} onChange={() => changeSelectAllContract(list, selectContracts)} isChecked={isSelectAllContract(list, selectContracts)}/>
            </div>
          </th>
          <th
            className="Table__th Table__th_border"
            style={{
              backgroundColor: "#dfdfdf",
              width: "0%",
            }}
          >
            <div
              className="Th__title"
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Icon name={"custom-priority"} size={12} color={"#202020"} />
            </div>
          </th>
          <th
            className="Table__th Table__th_border"
            style={{
              backgroundColor: "#dfdfdf",
            }}
          >
            <div className="Th__title">Обновлено</div>
          </th>
          <th
            className="Table__th Table__th_border"
            style={{
              backgroundColor: "#dfdfdf",
            }}
          >
            <div className="Th__title">Задача</div>
          </th>
          <th
            className="Table__th Table__th_border"
            style={{
              backgroundColor: "#dfdfdf",
            }}
          >
            <div className="Th__title">Примечание</div>
          </th>
          <th
            className="Table__th Table__th_border"
            style={{
              backgroundColor: "#dfdfdf",
            }}
          >
            <div className="Th__title">Дата исполнения</div>
          </th>
          <th
            className="Table__th Table__th_border"
            style={{
              backgroundColor: "#dfdfdf",
            }}
          >
            <div className="Th__title">Назначил</div>
          </th>
          <th
            className="Table__th Table__th_border"
            style={{
              backgroundColor: "#dfdfdf",
            }}
          >
            <div className="Th__title">Исполнитель</div>
          </th>
          <th
            className="Table__th Table__th_border"
            style={{
              backgroundColor: "#dfdfdf",
            }}
          >
            <div className="Th__title">Отметка</div>
          </th>
          <th
            className="Table__th Table__th_border"
            style={{
              backgroundColor: "#dfdfdf",
              width: "0%",
            }}
          >
            <div
              className="Th__title"
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              -
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <ProjectTasksList
          {...props}
        />
      </tbody>
    </table>
  );
};
