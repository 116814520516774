import { VIEWER_CLEAN, VIEWER_FILE, VIEWER_LOAD } from "../actionTypes";

export async function viewerLoad(dispatch) {
  dispatch({ type: VIEWER_LOAD });
}

export async function viewerFile(dispatch, file, mimeType) {
  dispatch({ type: VIEWER_FILE, file, mimeType });
}

export async function viewerClean(dispatch) {
  dispatch({ type: VIEWER_CLEAN });
}
