import React, { Fragment, useState } from "react";
import {
  getAccountFio,
  TextSearchMarker,
  Search,
  Select,
  Pagination,
  Loading,
  ButtonIcon,
} from "@vokymlak/kabinet-ui";
import ModalGeneralTaskAdd from "./ModalGeneralTaskAdd";
import ModalGeneralTaskEdit from "./ModalGeneralTaskEdit";
import moment from "moment";
import ModalGeneralTaskProjectAdd from "./ModalGeneralTaskProjectAdd";
import { ScrollBlock } from "../../../components/ScrollBlock/ScrollBlock";
import { GeneralTaskProjectsTable } from "./GeneralTaskProjectsTable";
import ModalGeneralTaskCopy from "./ModalGeneralTaskCopy";

function GeneralTasksPage(props) {
  const {
    list,
    setList,
    count,

    pageNumber,
    pageLimit,
    setPageLimit,
    changePage,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    setIsUpdateList,
    isUpdateList,

    isArchive,
    setIsArchive,

    inputSearchText,
    onChangeSearchText,
    searchQuery,
  } = props;

  const [modalItemAdd, setModalItemAdd] = useState(false);
  const [isModalObjectAdd, setIsModalObjectAdd] = useState(false);
  const [modalItemEdit, setModalItemEdit] = useState(null);
  const [modalItemCopy, setModalItemCopy] = useState(null);

  return (
    <ScrollBlock style={{ width: "100%", height: "100%" }}>
      <div className="Page">
        <div className="Page__title">
          <div
            className="Title__text"
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: 21,
              fontWeight: 700,
            }}
          >
            Общие задачи
            {!!list && (
              <span style={{ fontSize: 12, opacity: 0.75, marginLeft: 6 }}>
                ({count})
              </span>
            )}
            <ButtonIcon
              tooltipText={"Архив"}
              iconName={"custom-archive"}
              iconColor={isArchive ? "#202020" : "#20202075"}
              size={24}
              style={{
                marginLeft: 16,
              }}
              onClick={() => setIsArchive(!isArchive)}
            />
            <span style={{ width: 220, marginLeft: 16 }}>
              <Search
                reference={inputSearchText}
                type="text"
                label={"Поиск"}
                isFixed={true}
                onChange={() => onChangeSearchText()}
              />
            </span>
          </div>

          <div className="Title__settings">
            {!!list && (
              <ButtonIcon
                tooltipText={
                  list.length === unrolls.length ? "Свернуть" : "Развернуть"
                }
                iconName={
                  list.length === unrolls.length
                    ? "custom-unfold-less"
                    : "custom-unfold-more"
                }
                iconColor={"#20202075"}
                size={28}
                style={{ marginLeft: 8 }}
                onClick={() => changeAllUnroll(list, "uuid")}
              />
            )}

            <ButtonIcon
              tooltipText={"Обновить"}
              iconName={"custom-change-circle"}
              iconColor={"#20202075"}
              size={28}
              style={{ marginLeft: 8 }}
              onClick={() => (setList(null), setIsUpdateList(!isUpdateList))}
            />

            {/*{!!count && !!pageLimit && (*/}
            {/*  <div*/}
            {/*    style={{*/}
            {/*      marginLeft: 12,*/}
            {/*      width: 64,*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    <Select*/}
            {/*      isFixed={true}*/}
            {/*      isDefaultOption={false}*/}
            {/*      value={pageLimit}*/}
            {/*      options={[*/}
            {/*        {*/}
            {/*          value: 100,*/}
            {/*          title: 100,*/}
            {/*        },*/}
            {/*        {*/}
            {/*          value: 1000,*/}
            {/*          title: 1000,*/}
            {/*        },*/}
            {/*      ]}*/}
            {/*      label="Лимит"*/}
            {/*      onChange={(event) => setPageLimit(Number(event.target.value))}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*)}*/}

            {/*{!!count && count > pageLimit && (*/}
            {/*  <div style={{ marginLeft: 8 }}>*/}
            {/*    <Pagination*/}
            {/*      totalRecords={count}*/}
            {/*      pageLimit={pageLimit}*/}
            {/*      currentPage={pageNumber}*/}
            {/*      onPageChanged={(data) => changePage(data)}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*)}*/}

            <ButtonIcon
              tooltipText={"Добавить"}
              iconName={"custom-add"}
              iconColor={"#0064ff"}
              size={36}
              style={{ marginLeft: 12 }}
              onClick={() => setIsModalObjectAdd(true)}
            />
          </div>
        </div>

        {!list && (
          <div className={"Page__loader"}>
            <Loading />
          </div>
        )}

        {!!list && (
          <div className="Page__content">
            <ul className="List">
              {list.map((item) => (
                <Fragment key={"item_" + item.uuid}>
                  <li
                    className={
                      isUnroll(item.uuid) ? "List__item active" : "List__item"
                    }
                    onClick={() => changeUnroll(item.uuid)}
                    style={{
                      padding: "10px 12px",
                      backgroundColor: item.is_priority && "#ffe082",
                      position: "relative",
                    }}
                  >
                    <div
                      className="Item__title"
                      style={{
                        width: "100%",
                      }}
                    >
                      <div
                        className="Element__title"
                        style={{
                          width: "100%",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          style={{
                            fontSize: 19,
                            fontWeight: 700,
                            width: "100%",
                          }}
                        >
                          {(!!item.title && (
                            <TextSearchMarker
                              text={item.title}
                              search={searchQuery}
                            />
                          )) ||
                            "-"}
                        </div>

                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            flexDirection: "row",
                            flexWrap: "nowrap",
                            marginTop: 2,
                          }}
                        >
                          {!!item.description && (
                            <div
                              style={{
                                fontSize: 13,
                                fontWeight: 500,
                              }}
                            >
                              <TextSearchMarker
                                text={item.description}
                                search={searchQuery}
                              />
                            </div>
                          )}
                        </div>

                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            flexDirection: "row",
                            flexWrap: "nowrap",
                            opacity: 0.75,
                            marginTop: 4,
                          }}
                        >
                          <div
                            style={{
                              fontSize: 13,
                              fontWeight: 500,
                            }}
                          >
                            {!!item.updated_at
                              ? moment(item.updated_at).format(
                                  "DD.MM.YYYY HH:mm"
                                )
                              : moment(item.created_at).format(
                                  "DD.MM.YYYY HH:mm"
                                )}
                          </div>

                          {!!item.author && (
                            <div
                              style={{
                                fontSize: 13,
                                fontWeight: 500,
                              }}
                            >
                              {!!item.author && getAccountFio(item.author)}
                            </div>
                          )}
                        </div>

                        <div
                          style={{
                            height: 4,
                            width: "100%",
                            backgroundColor: "#2020200f",
                            borderRadius: 2,
                            marginTop: 4,
                          }}
                        >
                          <div
                            style={{
                              height: 4,
                              width:
                                (!!item.general_task_projects
                                  ? item.general_task_projects.filter(
                                      (gtp) => !!gtp.date_execution
                                    ).length /
                                    (item.general_task_projects.length / 100)
                                  : 0) + "%",
                              backgroundColor:
                                !!item.general_task_projects && item.general_task_projects.filter(
                                  (gtp) => !!gtp.date_execution
                                ).length /
                                  (item.general_task_projects.length / 100) <
                                100
                                  ? "#202020"
                                  : "#4caf50",
                              borderRadius: 2,
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="Item__settings">
                      <div className="Element__datetime"></div>
                    </div>
                  </li>

                  {isUnroll(item.uuid) && (
                    <li className="List__content">
                      <div className="Content__header">
                        <div className="Header__title">
                          {!item.archived_at && (
                          <div
                            className="Button__text Button__text_right"
                            onClick={() => setModalItemAdd(item)}
                          >
                            Добавить проект
                          </div>
                            )}
                          <div
                            className="Button__text Button__text_right"
                            onClick={() => setModalItemCopy(item)}
                          >
                            Дублировать задачу
                          </div>
                        </div>
                        <div className="Header__settings">
                          <ButtonIcon
                            tooltipText={"Редактировать"}
                            iconName={"custom-edit"}
                            iconColor={"#202020"}
                            size={36}
                            style={{ marginLeft: 12 }}
                            onClick={() => setModalItemEdit(item)}
                          />
                        </div>
                      </div>

                      {!!item.general_task_projects && (
                        <GeneralTaskProjectsTable {...props} item={item} />
                      )}
                    </li>
                  )}
                </Fragment>
              ))}
            </ul>
          </div>
        )}

        {!!modalItemEdit && (
          <ModalGeneralTaskEdit
            object={modalItemEdit}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalItemEdit(null)}
          />
        )}
        {!!isModalObjectAdd && (
          <ModalGeneralTaskAdd
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setIsModalObjectAdd(false)}
          />
        )}

        {!!modalItemAdd && (
          <ModalGeneralTaskProjectAdd
            object={modalItemAdd}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalItemAdd(null)}
          />
        )}

        {!!modalItemCopy && (
          <ModalGeneralTaskCopy
            object={modalItemCopy}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalItemCopy(null)}
          />
        )}
      </div>
    </ScrollBlock>
  );
}

export default GeneralTasksPage;
