import React, { Fragment } from "react";

function PurchaseBasisTable(props) {
  const { purchaseBasis } = props;

  return (
    !!purchaseBasis && (
      <table
        className="Table"
        style={{
          marginBottom: 12,
        }}
      >
        <tbody>
          {purchaseBasis.map((item) => (
            <Fragment key={"item_" + item.uuid}>
              <tr className="Table__tr Table__tr_padding Table__tr_border">
                <td
                  className="Table__td Table__td_border"
                  style={{
                    backgroundColor: "#dfdfdf",
                  }}
                >
                  <div
                    className="Td__text"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      flexWrap: "nowrap",
                    }}
                  >
                    <span
                      style={{
                        fontSize: 15,
                        fontWeight: 700,
                        color: "#202020",
                      }}
                    >
                      {item.name}
                    </span>
                    <span>
                      {!!item.purchase_base_contract_groups &&
                        item.purchase_base_contract_groups.map((group) => (
                          <div
                            key={"contract_group_" + group.contract_group.uuid}
                            style={{
                              fontSize: 11,
                              fontWeight: 700,
                              backgroundColor: "#202020",
                              display: "inline-block",
                              color: "white",
                              padding: "2px 4px 2px",
                              borderRadius: 3,
                              marginLeft: 8,
                            }}
                          >
                            {group.contract_group.name}
                          </div>
                        ))}
                      {!!item.purchase_base_logics &&
                        item.purchase_base_logics.map((group) => (
                          <div
                            key={"logic_" + group.uuid}
                            style={{
                              fontSize: 11,
                              fontWeight: 700,
                              backgroundColor: "#fff59d",
                              display: "inline-block",
                              color: "#202020",
                              padding: "2px 4px 2px",
                              borderRadius: 3,
                              marginLeft: 8,
                            }}
                          >
                            {group.logic.name}
                          </div>
                        ))}
                    </span>
                  </div>
                </td>
              </tr>
              {(!!item.control_points || !!item.ways) && (
                <tr className="Table__tr Table__tr_padding Table__tr_border">
                  {!!item.ways && (
                    <td
                      className="Table__td Table__td_border"
                      style={{
                        margin: 0,
                        padding: 0,
                        paddingLeft: 16,
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "nowrap",
                        alignItems: "flex-start",
                      }}
                    >
                      <table
                        className="Table"
                        style={{
                          margin: 0,
                        }}
                      >
                        <thead className="Thead">
                          <tr className="Table__tr Table__tr_padding Table__tr_border">
                            <th
                              className="Table__th Table__th_border"
                              style={{
                                backgroundColor: "#c5e1a5",
                              }}
                            >
                              <div className="Th__title">Способы закупки</div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {item.ways.map((item, index) => (
                            <tr
                              className="Table__tr Table__tr_padding Table__tr_border"
                              key={"item_" + item.purchase_way.uuid}
                            >
                              <td className="Table__td Table__td_border">
                                <div
                                  className="Td__text"
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    flexWrap: "nowrap",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: 15,
                                      fontWeight: 400,
                                    }}
                                  >
                                    {index + 1}. {item.purchase_way.name}
                                  </span>
                                  <span>
                                    {!!item.purchase_way
                                      .purchase_way_contract_groups &&
                                      item.purchase_way.purchase_way_contract_groups.map(
                                        (group) => (
                                          <div
                                            key={
                                              "contract_group_" +
                                              group.contract_group.uuid
                                            }
                                            style={{
                                              fontSize: 11,
                                              fontWeight: 700,
                                              backgroundColor: "#202020",
                                              display: "inline-block",
                                              color: "white",
                                              padding: "2px 4px 2px",
                                              borderRadius: 3,
                                              marginLeft: 8,
                                            }}
                                          >
                                            {group.contract_group.name}
                                          </div>
                                        )
                                      )}
                                    {!!item.purchase_way.purchase_way_logics &&
                                      item.purchase_way.purchase_way_logics.map(
                                        (group) => (
                                          <div
                                            key={"logic_" + group.uuid}
                                            style={{
                                              fontSize: 11,
                                              fontWeight: 700,
                                              backgroundColor: "#fff59d",
                                              display: "inline-block",
                                              color: "#202020",
                                              padding: "2px 4px 2px",
                                              borderRadius: 3,
                                              marginLeft: 8,
                                            }}
                                          >
                                            {group.logic.name}
                                          </div>
                                        )
                                      )}
                                  </span>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </td>
                  )}

                  {!!item.control_points && (
                    <td
                      className="Table__td Table__td_border"
                      style={{
                        margin: 0,
                        padding: 0,
                        paddingLeft: 16,
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "nowrap",
                        alignItems: "flex-start",
                      }}
                    >
                      <table
                        className="Table"
                        style={{
                          margin: 0,
                        }}
                      >
                        <thead className="Thead">
                          <tr className="Table__tr Table__tr_padding Table__tr_border">
                            <th
                              className="Table__th Table__th_border"
                              style={{
                                backgroundColor: "#bbdefb",
                              }}
                            >
                              <div className="Th__title">Контрольные точки</div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {item.control_points.map((item, index) => (
                            <tr
                              className="Table__tr Table__tr_padding Table__tr_border"
                              key={"item_" + item.control_point.uuid}
                            >
                              <td className="Table__td Table__td_border">
                                <div
                                  className="Td__text"
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    flexWrap: "nowrap",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: 15,
                                      fontWeight: 400,
                                    }}
                                  >
                                    {index + 1}. {item.control_point.name}
                                  </span>
                                  <span>
                                    {!!item.control_point
                                      .control_point_groups &&
                                      item.control_point.control_point_groups.map(
                                        (group) => (
                                          <div
                                            key={
                                              "control_group_" +
                                              group.control_group.uuid
                                            }
                                            style={{
                                              fontSize: 11,
                                              fontWeight: 700,
                                              backgroundColor: "#bbdefb",
                                              display: "inline-block",
                                              color: "#202020",
                                              padding: "2px 4px 2px",
                                              borderRadius: 3,
                                              marginLeft: 8,
                                            }}
                                          >
                                            {group.control_group.name}
                                          </div>
                                        )
                                      )}
                                    {!!item.control_point.control_point_logics &&
                                      item.control_point.control_point_logics.map(
                                        (group) => (
                                          <div
                                            key={"logic_" + group.uuid}
                                            style={{
                                              fontSize: 11,
                                              fontWeight: 700,
                                              backgroundColor: "#fff59d",
                                              display: "inline-block",
                                              color: "#202020",
                                              padding: "2px 4px 2px",
                                              borderRadius: 3,
                                              marginLeft: 8,
                                            }}
                                          >
                                            {group.logic.name}
                                          </div>
                                        )
                                      )}
                                  </span>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </td>
                  )}
                </tr>
              )}
            </Fragment>
          ))}
        </tbody>
      </table>
    )
  );
}

export default PurchaseBasisTable;
