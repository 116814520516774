import React, { useRef } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../../../config/url";
import {
  getErrorMessageByKey,
  useNotification,
  useObject,
  Input,
  Modal,
} from "@vokymlak/kabinet-ui";
import moment from "moment";

function ModalProjectTaskExecute(props) {
  const {
    object: [projectTask],
  } = useObject({
    apiUrl: "/api/project-task/" + props.projectTask.uuid,
    rootUrl: ROOT_URL,
    callbackError: () => {
      props.handleClose();
    },
  });

  const inputDate = useRef(null);

  const { notification } = useNotification();

  const changeExecute = (event) => {
    event.preventDefault();

    const date = inputDate.current.value;

    const jsonData = JSON.stringify({
      date_execution: !!date && date.length > 0 ? date : null,
    });

    axios
      .post(
        "/api/project-task/" + props.projectTask.uuid + "/edit/executor",
        jsonData,
        {
          withCredentials: false,
          baseURL: ROOT_URL,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("tokenSession"),
          },
        }
      )
      .then(() => {
        props.successfulRequest();
        notification(
          !!date && date.length > 0
            ? "Задача проекта отмечена"
            : "Отметка убрана",
          "green"
        );
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    !!projectTask && (
      <Modal handleClose={() => props.handleClose()}>
        <form className="Form" onSubmit={(event) => changeExecute(event)}>
          <div className="Form__title">
            {(!!projectTask.name && projectTask.name) || "-"}
            <div className="Text__signature">Отметить</div>
          </div>

          <div className="Form__field">
            <Input
              reference={inputDate}
              type="date"
              isFixed={true}
              label="Дата"
              value={
                !!projectTask.date_execution
                  ? moment(projectTask.date_execution).format("YYYY-MM-DD")
                  : moment().format("YYYY-MM-DD")
              }
            />
          </div>

          <div className="Form__actions Form__actions_center">
            <button className="Action__button Action__button_all Action__button_blue">
              Отметить
            </button>
          </div>

          {/*<div className="Form__actions Form__actions_center">*/}
          {/*  <div className="Action__link" onClick={() => props.handleClose()}>*/}
          {/*    Отмена*/}
          {/*  </div>*/}
          {/*</div>*/}
        </form>
      </Modal>
    )
  );
}

export default ModalProjectTaskExecute;
