import React, { useState } from "react";
import { ROOT_URL } from "../../../config/url";
import useList from "../../../hooks/useList";
import {useAction, useUnroll} from "@vokymlak/kabinet-ui";
import {getErrorMessageByKey} from "@vokymlak/kabinet-ui";
import axios from "axios";
import {useNotification} from "@vokymlak/kabinet-ui";

function usePurchaseBaseControlPoints() {
  const {
    list: [list, setList],
    update: [isUpdateList, setIsUpdateList],
  } = useList("/api/purchase-basis", ROOT_URL);

  const { unrolls, isUnroll, changeUnroll, changeAllUnroll } = useUnroll();

  const [modalObjectItemAdd, setModalObjectItemAdd] = useState(null);

  useAction("isUpdate", () => setIsUpdateList(!isUpdateList));

  const { notification } = useNotification();

  const deletePurchaseBaseControlPoint = (basePointUuid) => {
    axios
      .delete("/api/purchase-base-control-point/" + basePointUuid + "/delete", {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        setIsUpdateList(!isUpdateList);
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return {
    list,
    setList,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    setIsUpdateList,
    isUpdateList,

    modalObjectItemAdd,
    setModalObjectItemAdd,

    deletePurchaseBaseControlPoint,
  };
}

export default usePurchaseBaseControlPoints;
