import React, {useRef, useState} from "react";
import axios from "axios";
import {ROOT_URL} from "../../../../../config/url";
import moment from "moment";
import ModalControlPointEdit from "./ModalControlPointEdit";
import {
  getErrorMessageByKey,
  Icon,
  Input,
  isValidateNullText,
  jsonFormatText,
  Modal,
  Select,
  Textarea,
  useList,
  useNotification,
  useObject
} from "@vokymlak/kabinet-ui";
import {
  CONTRACT_VALIDITY_TYPE_CURRENT,
  CONTRACT_VALIDITY_TYPE_LASTING,
  CONTRACT_VALIDITY_TYPE_ROLLING
} from "../../../../../config/constants";

function compareDates(date1, date2, minYear, maxYear) {
  // Parse dates using Moment.js
  const t1 = moment(date1, "YYYY-MM-DD");
  const t2 = moment(date2, "YYYY-MM-DD");

  // Check for parsing errors
  if (!t1.isValid() || !t2.isValid()) {
    console.log("Invalid date format:", date1, date2);
    return false;
  }

  // Check if the year of the first date is not lower than the specified minYear
  if (t1.year() < minYear) {
    return false;
  }

  // Check if the year of the first date is not lower than the specified minYear
  if (t2.year() < minYear) {
    return false;
  }

  // Check if the year of the first date is not higher than the specified maxYear
  if (t1.year() > maxYear) {
    return false;
  }

  // Check if the year of the first date is not higher than the specified maxYear
  if (t2.year() > maxYear) {
    return false;
  }

  // Compare dates and return the result
  return t1.isBefore(t2);
}

function validDate(date1, minYear, maxYear) {
  // Parse dates using Moment.js
  const t1 = moment(date1, "YYYY-MM-DD");

  // Check for parsing errors
  if (!t1.isValid()) {
    console.log("Invalid date format:", date1);
    return false;
  }

  // Check if the year of the first date is not lower than the specified minYear
  if (t1.year() < minYear) {
    return false;
  }

  // Check if the year of the first date is not higher than the specified maxYear
  if (t1.year() > maxYear) {
    return false;
  }

  // Compare dates and return the result
  return true;
}

function ModalContractEdit(props) {
  const [purchaseBaseId, setPurchaseBaseId] = useState(null);
  const [isIntermediateExecution, setIsIntermediateExecution] = useState(false);
  const [isUis, setIsUis] = useState(false);
  const [isMspPurchase, setIsMspPurchase] = useState(false);
  const [isPurchaseWayEp, setIsPurchaseWayEp] = useState(false);
  const [isEmPurchase, setIsEmPurchase] = useState(false);

  const {
    list: [listLawsContractorStatuses],
  } = useList({apiUrl: "/api/laws-contract-statuses", rootUrl: ROOT_URL});
  const {
    list: [listPurchaseBasis],
  } = useList({apiUrl: "/api/purchase-basis", rootUrl: ROOT_URL});

  const {
    object: [project],
  } = useObject({
    apiUrl: "/api/project/" + props.project.uuid,
    rootUrl: ROOT_URL,
    callbackError: () => {
      props.handleClose();
    },
  });

  const {
    object: [object],
    update: [isUpdateObject, setIsUpdateObject],
  } = useObject({
    apiUrl: "/api/contract/" + props.contract.uuid,
    rootUrl: ROOT_URL,
    callbackResponse: (data) => {
      setPurchaseBaseId(
        !!data && !!data.purchase_base_id ? data.purchase_base_id : null
      );
      setIsIntermediateExecution(!!data && data.is_intermediate_execution);
      // setIsUis(!!data && data.is_uis);

      setIsMspPurchase(!!data && data.is_msp_purchase);
      setIsEmPurchase(!!data && data.is_em);
    },
    callbackError: () => {
      props.handleClose();
    },
  });

  const [isDelete, setIsDelete] = useState(false);
  const [isArchive, setIsArchive] = useState(false);
  const [isReArchive, setIsReArchive] = useState(false);

  const inputContractNumber = useRef(null);
  const inputRegistryNumber = useRef(null);
  const inputUisNumber = useRef(null);

  const inputBasisConclusion = useRef(null);

  const inputDateViewed = useRef(null);
  const inputDateValidity = useRef(null);

  const inputSubject = useRef(null);

  const inputAmount = useRef(null);

  const inputSupplier = useRef(null);

  const inputNote = useRef(null);
  const inputRegistrationErrorNote = useRef(null);


  const inputPlanAmount = useRef(null);
  const inputStartingPrice = useRef(null);
  const inputAmountCurrentYear = useRef(null);

  const inputLegalResult = useRef(null);

  const inputAmountPayments = useRef(null);
  const inputPaymentExecution = useRef(null);

  const selectPurchaseBaseId = useRef(null);
  const selectPurchaseWayId = useRef(null);
  const selectContractStatusId = useRef(null);

  const selectContractValidityTypeTag = useRef(null);

  const inputCountAppeal = useRef(null);

  const {notification} = useNotification();

  const numberConvert = (numberString) => {
    return !!numberString && numberString.length > 0
      ? Number(String(Number(numberString).toFixed(2)).replace(".", ""))
      : null;
  };

  const numberConvertC = (numberString) => {
    return !!numberString && numberString.length > 0
      ? Number(String(Number(numberString).toFixed(0)).replace(".", ""))
      : null;
  };

  const changeEdit = (event) => {
    event.preventDefault();

    const contractNumber = jsonFormatText(inputContractNumber.current.value);
    const registryNumber = jsonFormatText(inputRegistryNumber.current.value);
    const uisNumber = jsonFormatText(inputUisNumber.current.value);

    let basisConclusion = jsonFormatText(inputBasisConclusion.current.value);

    const dateViewed = inputDateViewed.current.value;
    const dateValidity = inputDateValidity.current.value;

    const subject = jsonFormatText(inputSubject.current.value);

    const amount = inputAmount.current.value;

    const supplier = jsonFormatText(inputSupplier.current.value);

    const note = jsonFormatText(inputNote.current.value);
    const registrationErrorNote = jsonFormatText(inputRegistrationErrorNote.current.value);

    const planAmount = inputPlanAmount.current.value;
    const startingPrice = inputStartingPrice.current.value;
    const amountCurrentYear = inputAmountCurrentYear.current.value;

    const legalResult = jsonFormatText(inputLegalResult.current.value);

    const amountPayments = inputAmountPayments.current.value;
    const paymentExecution = jsonFormatText(
      inputPaymentExecution.current.value
    );

    const purchaseBaseId =
      (!!selectPurchaseBaseId.current && jsonFormatText(selectPurchaseBaseId.current.value)) ||
      "";
    const purchaseWayId =
      (!!selectPurchaseWayId.current && jsonFormatText(selectPurchaseWayId.current.value)) ||
      "";
    const contractStatusId =
      (!!selectContractStatusId.current &&
        jsonFormatText(selectContractStatusId.current.value)) ||
      "";

    const contractValidityTypeTag =
      (!!selectContractValidityTypeTag.current &&
        jsonFormatText(selectContractValidityTypeTag.current.value)) ||
      "";

    const countAppeal = inputCountAppeal.current.value;

    if (!isValidateNullText(dateViewed)) {
      if (!validDate(dateViewed, 1990, 2100)) {
        notification("Заполните корректно дату заключения договора", "red");
        return
      }
    }

    if (!isValidateNullText(dateValidity)) {
      if (!validDate(dateValidity, 1990, 2100)) {
        notification("Заполните корректно срок действия договора", "red");
        return
      }
    }

    if (!isValidateNullText(dateViewed) && !isValidateNullText(dateValidity)) {
      if (!compareDates(dateViewed, dateValidity, 1990, 2100)) {
        notification("Заполните корректно дату заключения и срок действия договора", "red");
        return
      }
    }

    if (isPurchaseWayEp && !!purchaseWayId) {
      listPurchaseBasis.forEach((item) => {
        if (!!project && !!project.law && project.law.tag === item.law.tag) {
          if (!!item.ways && !!purchaseBaseId && Number(purchaseBaseId) === item.id) {
            item.ways.forEach((way) => {
              if (way.purchase_way.id === Number(purchaseWayId)) {
                basisConclusion = way.purchase_way.name
              }
            });
          }
        }
      })
    }

    const jsonData = JSON.stringify({
      contract_number: contractNumber,
      registry_number: registryNumber,
      uis_number: uisNumber,

      basis_conclusion: basisConclusion,

      date_viewed: !!dateViewed && dateViewed.length > 0 ? dateViewed : null,
      date_validity:
        !!dateValidity && dateValidity.length > 0 ? dateValidity : null,

      subject: subject,

      supplier: supplier,

      note: note,

      plan_amount: numberConvert(planAmount),
      starting_price: numberConvert(startingPrice),
      amount: numberConvert(amount),
      amount_current_year: numberConvert(amountCurrentYear),

      legal_result: legalResult,

      amount_payments: numberConvert(amountPayments),
      payment_execution: paymentExecution,

      purchase_base_id:
        purchaseBaseId.length > 0
          ? purchaseBaseId === "null"
            ? null
            : Number(purchaseBaseId)
          : null,
      purchase_way_id:
        purchaseWayId.length > 0
          ? purchaseWayId === "null"
            ? null
            : Number(purchaseWayId)
          : null,
      contract_status_id:
        contractStatusId.length > 0
          ? contractStatusId === "null"
            ? null
            : Number(contractStatusId)
          : null,

      is_intermediate_execution: isIntermediateExecution,
      // is_uis: isUis,

      count_appeal: numberConvertC(countAppeal),

      contract_validity_type_tag: contractValidityTypeTag.length > 0
        ? contractValidityTypeTag === "null"
          ? null
          : contractValidityTypeTag
        : null,

      registration_error_note: registrationErrorNote,

      is_msp_purchase: isMspPurchase,

      is_em: isEmPurchase,
    });

    axios
      .put("/api/contract/" + props.contract.uuid + "/edit", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Данные сохранены", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const changeDelete = (event) => {
    event.preventDefault();

    axios
      .delete("/api/contract/" + props.contract.uuid + "/delete", {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Договор удалён", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const changeArchive = (event) => {
    event.preventDefault();

    axios
      .get("/api/contract/" + props.contract.uuid + "/archive", {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Договор в архиве", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const changeReArchive = (event) => {
    event.preventDefault();

    axios
      .get("/api/contract/" + props.contract.uuid + "/re-archive", {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Договор восстановлен", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const getPurchaseBasisSelectOptions = (project, list) => {
    const options = [
      {
        value: "null",
        title: "Не выбрано",
      },
    ];

    list.map((item) => {
      if (!!project && !!project.law && project.law.tag === item.law.tag) {
        const option = {
          value: item.id,
          title: item.name,
        };

        options.push(option);
      }
    });

    return options;
  };

  const getPurchaseWaysSelectOptions = (project, purchaseBaseId, list) => {
    const options = [
      {
        value: "null",
        title: "Не выбрано",
      },
    ];

    list.map((item) => {
      if (!!project && !!project.law && project.law.tag === item.law.tag) {
        if (!!item.ways && !!purchaseBaseId && purchaseBaseId === item.id) {
          item.ways.map((way) => {
            const option = {
              value: way.purchase_way.id,
              title: way.purchase_way.name,
            };

            options.push(option);
          });
        }
      }
    });

    return options;
  };

  const getContractStatusSelectOptions = (project, list) => {
    const options = [
      {
        value: "null",
        title: "Не выбрано",
      },
    ];

    list.map((item) => {
      if (!!project && !!project.law && project.law.tag === item.tag) {
        if (!!item.contract_statuses) {
          item.contract_statuses.map((status) => {
            const option = {
              value: status.contract_status.id,
              title: status.contract_status.name,
            };

            options.push(option);
          });
        }
      }
    });

    return options;
  };

  const onChangeSelectPurchaseBasis = () => {
    const purchaseBaseId = selectPurchaseBaseId.current.value;
    setPurchaseBaseId(
      purchaseBaseId.length > 0
        ? purchaseBaseId === "null"
          ? null
          : Number(purchaseBaseId)
        : null
    );
  };

  const getContractControlPoint = (item, controlPoint) => {
    let getContractControlPoint = null;

    if (!!item && !!item.control_points) {
      item.control_points.map((point) => {
        if (point.control_point.uuid === controlPoint.uuid) {
          getContractControlPoint = point;
        }
      });
    }

    return getContractControlPoint;
  };

  const [modalControlPointEdit, setModalControlPointEdit] = useState(null);

  return (
    !!object &&
    !!project &&
    !!listPurchaseBasis &&
    !!listLawsContractorStatuses && (
      <>
        <Modal handleClose={() => props.handleClose()}>
          {!isDelete && !isArchive && !isReArchive && (
            <form className="Form" onSubmit={(event) => changeEdit(event)}>
              <div className="Form__title">
                {object.contract_number}
                <div className="Text__signature">Договор</div>
              </div>

              <div className="Form__field">
                <Input
                  reference={inputContractNumber}
                  label="Номер договора"
                  value={object.contract_number}
                />
              </div>

              <div className="Form__field">
                <Input
                  reference={inputRegistryNumber}
                  label="Реестровый номер"
                  value={object.registry_number}
                />
              </div>

              <div className="Form__field">
                <Input
                  reference={inputUisNumber}
                  label="Реестровый номер договора в ЕИС"
                  value={object.uis_number}
                />
              </div>

              {/*<div className="Form__field">*/}
              {/*  <div*/}
              {/*    className="Button__swith"*/}
              {/*    style={{*/}
              {/*      padding: "8px",*/}
              {/*      width: "100%",*/}
              {/*      border: isUis*/}
              {/*        ? "2px solid #ffe082"*/}
              {/*        : "2px dashed #20202015",*/}
              {/*      backgroundColor: isUis*/}
              {/*        ? "#ffe082"*/}
              {/*        : "#ffffff",*/}
              {/*      borderRadius: 6,*/}
              {/*      marginTop: 20,*/}
              {/*      display: "flex",*/}
              {/*      alignItems: "center",*/}
              {/*      justifyContent: "space-between",*/}
              {/*      cursor: "pointer",*/}
              {/*    }}*/}
              {/*    onClick={() =>*/}
              {/*      setIsUis(!isUis)*/}
              {/*    }*/}
              {/*  >*/}
              {/*    <div*/}
              {/*      className="Swith__text"*/}
              {/*      style={{*/}
              {/*        fontSize: 17,*/}
              {/*        fontWeight: 600,*/}
              {/*        display: "flex",*/}
              {/*        alignItems: "center",*/}
              {/*      }}*/}
              {/*    >*/}
              {/*      ЕИС*/}
              {/*    </div>*/}
              {/*    <div*/}
              {/*      className={*/}
              {/*        "Swith Swith_gray" +*/}
              {/*        ((isUis &&*/}
              {/*            " Swith_active Swith_active_gray") ||*/}
              {/*          "")*/}
              {/*      }*/}
              {/*    ></div>*/}
              {/*  </div>*/}
              {/*</div>*/}

              <div className="Form__field">
                <Textarea
                  reference={inputBasisConclusion}
                  label="Основание заключения с ЕП"
                  isFixed={true}
                  value={object.basis_conclusion}
                />
              </div>

              <div className="Form__field">
                <Input
                  reference={inputDateViewed}
                  type="date"
                  isFixed={true}
                  label="Дата заключения"
                  value={
                    !!object.date_viewed
                      ? moment(object.date_viewed).format("YYYY-MM-DD")
                      : null
                  }
                />
              </div>

              <div className="Form__field">
                <Input
                  reference={inputDateValidity}
                  type="date"
                  isFixed={true}
                  label="Срок действия"
                  value={
                    !!object.date_validity
                      ? moment(object.date_validity).format("YYYY-MM-DD")
                      : null
                  }
                />
              </div>

              <div className="Form__field">
                <Textarea
                  reference={inputSubject}
                  label="Предмет"
                  isFixed={true}
                  value={object.subject}
                />
              </div>

              <div className="Form__field">
                <Input
                  reference={inputPlanAmount}
                  type="number"
                  label="Сумма по плана закупки"
                  value={object.plan_amount !== null ? object.plan_amount === 0 ? '0' : object.plan_amount / 100 : ''}
                />
              </div>

              <div className="Form__field">
                <Input
                  reference={inputStartingPrice}
                  type="number"
                  label="Начальная цена"
                  value={object.starting_price !== null ? object.starting_price === 0 ? '0' : object.starting_price / 100 : ''}
                />
              </div>

              <div className="Form__field">
                <Input
                  reference={inputAmount}
                  type="number"
                  label="Цена договора"
                  value={object.amount !== null ? object.amount === 0 ? '0' : object.amount / 100 : ''}
                />
              </div>

              <div className="Form__field">
                <Input
                  reference={inputAmountCurrentYear}
                  type="number"
                  label="Сумма текущего года"
                  value={object.amount_current_year !== null ? object.amount_current_year === 0 ? '0' : object.amount_current_year / 100 : ''}
                />
              </div>

              <div className="Form__field">
                <Select
                  isFixed={true}
                  reference={selectContractValidityTypeTag}
                  options={[
                    {
                      value: "null",
                      title: "Не выбран",
                    },
                    {
                      value: CONTRACT_VALIDITY_TYPE_CURRENT,
                      title: "Текущий",
                    },
                    {
                      value: CONTRACT_VALIDITY_TYPE_ROLLING,
                      title: "Переходящий",
                    },
                    {
                      value: CONTRACT_VALIDITY_TYPE_LASTING,
                      title: "Долгосрочный",
                    },
                  ]}
                  label="Тип действия"
                  value={
                    !!object.contract_validity_type_tag ? String(object.contract_validity_type_tag) : "null"
                  }
                />
              </div>

              <div className="Form__field">
                <Input
                  reference={inputSupplier}
                  label="Поставщик"
                  value={object.supplier}
                />
              </div>

              <div className="Form__field">
                <Select
                  isFixed={true}
                  reference={selectPurchaseBaseId}
                  options={
                    !!listPurchaseBasis
                      ? getPurchaseBasisSelectOptions(project, listPurchaseBasis)
                      : getPurchaseBasisSelectOptions(project, [])
                  }
                  label="Закон-основание"
                  onChange={() => onChangeSelectPurchaseBasis()}
                  value={
                    !!object.purchase_base_id
                      ? String(object.purchase_base_id)
                      : null
                  }
                />
              </div>

              {!!purchaseBaseId && (
                <div className="Form__field">
                  <Select
                    isFixed={true}
                    reference={selectPurchaseWayId}
                    options={
                      !!listPurchaseBasis
                        ? getPurchaseWaysSelectOptions(
                          project,
                          purchaseBaseId,
                          listPurchaseBasis
                        )
                        : getPurchaseWaysSelectOptions(
                          project,
                          purchaseBaseId,
                          []
                        )
                    }
                    label="Способ закупки"
                    value={
                      !!object.purchase_way_id
                        ? String(object.purchase_way_id)
                        : null
                    }
                  />
                </div>
              )}

              {!!purchaseBaseId && (
                <div className="Form__field">
                  <div
                    className="Button__swith"
                    style={{
                      padding: "8px",
                      width: "100%",
                      border: isPurchaseWayEp
                        ? "2px solid #ffe082"
                        : "2px dashed #20202015",
                      // border: "2px dashed #20202015",
                      backgroundColor: isPurchaseWayEp
                        ? "#ffe082"
                        : "#ffffff",
                      borderRadius: 6,
                      marginTop: 20,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      setIsPurchaseWayEp(!isPurchaseWayEp)
                    }
                  >
                    <div
                      className="Swith__text"
                      style={{
                        fontSize: 17,
                        fontWeight: 600,
                        display: "flex",
                        alignItems: "center",
                        // color: isPriority ? "#ff3c00" : "#202020",
                      }}
                    >
                      Добавить "Способ закупки" в "Основание заключения с ЕП", м?
                    </div>
                    <div
                      className={
                        "Swith Swith_gray" +
                        ((isPurchaseWayEp &&
                            " Swith_active Swith_active_gray") ||
                          "")
                      }
                    ></div>
                  </div>
                </div>
              )}

              <div className="Form__field">
                <div
                  className="Button__swith"
                  style={{
                    padding: "8px",
                    width: "100%",
                    border: isMspPurchase
                      ? "2px solid #ffe082"
                      : "2px dashed #20202015",
                    // border: "2px dashed #20202015",
                    backgroundColor: isMspPurchase
                      ? "#ffe082"
                      : "#ffffff",
                    borderRadius: 6,
                    marginTop: 20,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    setIsMspPurchase(!isMspPurchase)
                  }
                >
                  <div
                    className="Swith__text"
                    style={{
                      fontSize: 17,
                      fontWeight: 600,
                      display: "flex",
                      alignItems: "center",
                      // color: isPriority ? "#ff3c00" : "#202020",
                    }}
                  >
                    Закупка у МСП
                  </div>
                  <div
                    className={
                      "Swith Swith_gray" +
                      ((isMspPurchase &&
                          " Swith_active Swith_active_gray") ||
                        "")
                    }
                  ></div>
                </div>
              </div>

              {(!!project && !!project.law && project.law.tag === "44") && (
                <div className="Form__field">
                  <div
                    className="Button__swith"
                    style={{
                      padding: "8px",
                      width: "100%",
                      border: isEmPurchase
                        ? "2px solid #ffe082"
                        : "2px dashed #20202015",
                      // border: "2px dashed #20202015",
                      backgroundColor: isEmPurchase
                        ? "#ffe082"
                        : "#ffffff",
                      borderRadius: 6,
                      marginTop: 20,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      setIsEmPurchase(!isEmPurchase)
                    }
                  >
                    <div
                      className="Swith__text"
                      style={{
                        fontSize: 17,
                        fontWeight: 600,
                        display: "flex",
                        alignItems: "center",
                        // color: isPriority ? "#ff3c00" : "#202020",
                      }}
                    >
                      Закупка в электронном магазине
                    </div>
                    <div
                      className={
                        "Swith Swith_gray" +
                        ((isEmPurchase &&
                            " Swith_active Swith_active_gray") ||
                          "")
                      }
                    ></div>
                  </div>
                </div>
              )}

              <div className="Form__field">
                <Textarea
                  reference={inputLegalResult}
                  label="Результат правового анализа"
                  isFixed={true}
                  value={object.legal_result}
                />
              </div>

              <div className="Form__field">
                <Textarea
                  reference={inputNote}
                  label="Примечание"
                  isFixed={true}
                  value={object.note}
                />
              </div>

              <div className="Form__field">
                <Select
                  isFixed={true}
                  reference={selectContractStatusId}
                  options={
                    !!listLawsContractorStatuses
                      ? getContractStatusSelectOptions(
                        project,
                        listLawsContractorStatuses
                      )
                      : getContractStatusSelectOptions(project, [])
                  }
                  label="Статус"
                  value={
                    !!object.contract_status_id
                      ? String(object.contract_status_id)
                      : null
                  }
                />
              </div>

              <div className="Form__field">
                <Input
                  reference={inputCountAppeal}
                  type="number"
                  label="Количество заказов по КП"
                  value={!!object.count_appeal && object.count_appeal}
                />
              </div>

              <div className="Form__field">
                <Input
                  reference={inputAmountPayments}
                  type="number"
                  label="Сумма по платежам"
                  value={object.amount_payments !== null ? object.amount_payments === 0 ? '0' : object.amount_payments / 100 : ''}
                />
              </div>
              <div className="Form__field">
                <Textarea
                  reference={inputPaymentExecution}
                  label="Комментарий к исполнению"
                  isFixed={true}
                  value={object.payment_execution}
                />
              </div>

              <div className="Form__field">
                <Textarea
                  reference={inputRegistrationErrorNote}
                  label="Комментарий к ошибке регистрации"
                  isFixed={true}
                  value={object.registration_error_note}
                />
              </div>

              <div className="Form__field">
                <div
                  className="Button__swith"
                  style={{
                    padding: "8px",
                    width: "100%",
                    border: isIntermediateExecution
                      ? "2px solid #ffe082"
                      : "2px dashed #20202015",
                    // border: "2px dashed #20202015",
                    backgroundColor: isIntermediateExecution
                      ? "#ffe082"
                      : "#ffffff",
                    borderRadius: 6,
                    marginTop: 20,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    setIsIntermediateExecution(!isIntermediateExecution)
                  }
                >
                  <div
                    className="Swith__text"
                    style={{
                      fontSize: 17,
                      fontWeight: 600,
                      display: "flex",
                      alignItems: "center",
                      // color: isPriority ? "#ff3c00" : "#202020",
                    }}
                  >
                    Промежуточное исполнение
                  </div>
                  <div
                    className={
                      "Swith Swith_gray" +
                      ((isIntermediateExecution &&
                          " Swith_active Swith_active_gray") ||
                        "")
                    }
                  ></div>
                </div>
              </div>

              <hr
                style={{
                  marginTop: 4,
                  border: "none",
                  borderBottom: "2px solid #e3e3e3",
                  marginBottom: 12,
                  padding: "0 2px",
                }}
              />

              <div className="Form__field Form__field_last">
                <div
                  style={{
                    fontSize: 19,
                    fontWeight: 700,
                    opacity: 0.75,
                    padding: "0 2px",
                    marginTop: 16,
                    marginBottom: 16
                  }}
                >
                  Контроль
                </div>

                <div
                  style={{
                    padding: "0 2px",
                  }}
                >
                  {!!object.purchase_base_id &&
                    listPurchaseBasis.map(
                      (base) =>
                        base.id === object.purchase_base_id &&
                        !!base.control_points &&
                        base.control_points.map((point) => (
                          <div
                            key={"point_" + point.control_point.uuid}
                            onClick={() =>
                              setModalControlPointEdit({
                                item: object,
                                controlPoint: point.control_point,
                              })
                            }
                            style={{
                              marginBottom: 12,
                              cursor: "pointer",
                              border: "2px solid #dbdbdb",
                              padding: "6px 8px",
                              borderRadius: "5px",
                              // backgroundColor: "#ffd54f"
                            }}
                          >
                            <div
                              style={{
                                fontSize: 13,
                                fontWeight: 400,
                              }}
                            >
                              {point.control_point.name}
                            </div>

                            <div
                              style={{
                                fontSize: 17,
                                fontWeight: 700,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                width: "100%",
                              }}
                            >
                              {!!getContractControlPoint(
                                  object,
                                  point.control_point
                                ) &&
                                getContractControlPoint(
                                  object,
                                  point.control_point
                                ).is_priority && (
                                  <span style={{marginRight: 4}}>
                                  <Icon
                                    name={"custom-priority"}
                                    size={16}
                                    color={
                                      !getContractControlPoint(
                                        object,
                                        point.control_point
                                      ).date_execution
                                        ? "#202020"
                                        : "#bdbdbd"
                                    }
                                  />
                                </span>
                                )}
                              {(!!getContractControlPoint(
                                    object,
                                    point.control_point
                                  ) &&
                                  moment(
                                    getContractControlPoint(
                                      object,
                                      point.control_point
                                    ).date_control
                                  ).format("DD.MM.YYYY")) ||
                                "-"}
                            </div>
                          </div>
                        ))
                    )}

                  {!object.purchase_base_id && (
                    <div
                      style={{
                        fontSize: 17,
                        fontWeight: 400,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      Закон-основание, не указано
                    </div>
                  )}
                </div>
              </div>

              <div className="Form__actions Form__actions_center">
                <button className="Action__button Action__button_all Action__button_blue">
                  Применить
                </button>
              </div>

              <div className="Form__actions">
                <div
                  className="Action__link Action__link_red"
                  onClick={() => setIsDelete(true)}
                >
                  Удалить
                </div>

                {!object.archived_at && (
                  <div
                    className="Action__link Action__link_gray"
                    onClick={() => setIsArchive(true)}
                  >
                    В архив
                  </div>
                )}
                {!!object.archived_at && (
                  <div
                    className="Action__link Action__link_blue"
                    onClick={() => setIsReArchive(true)}
                  >
                    Из архива
                  </div>
                )}

                {/*<div className="Action__link" onClick={() => props.handleClose()}>*/}
                {/*  Отмена*/}
                {/*</div>*/}
              </div>
            </form>
          )}

          {isArchive && (
            <form className="Form" onSubmit={(event) => changeArchive(event)}>
              <div className="Form__title">
                {object.contract_number}
                <div className="Text__signature">В архив</div>
              </div>

              <div className="Form__actions Form__actions_center">
                <button className="Action__button Action__button_all Action__button_gray">
                Переместить
                </button>
              </div>

              <div className="Form__actions Form__actions_center">
                <div className="Action__link" onClick={() => setIsArchive(false)}>
                  Отмена
                </div>
              </div>
            </form>
          )}

          {isReArchive && (
            <form className="Form" onSubmit={(event) => changeReArchive(event)}>
              <div className="Form__title">
                {object.contract_number}
                <div className="Text__signature">Из архива</div>
              </div>

              <div className="Form__actions Form__actions_center">
                <button className="Action__button Action__button_all Action__button_blue">
                  Восстановить
                </button>
              </div>

              <div className="Form__actions Form__actions_center">
                <div
                  className="Action__link"
                  onClick={() => setIsReArchive(false)}
                >
                  Отмена
                </div>
              </div>
            </form>
          )}

          {isDelete && (
            <form className="Form" onSubmit={(event) => changeDelete(event)}>
              <div className="Form__title">
                {object.contract_number}
                <div className="Text__signature">Удалить</div>
              </div>

              <div className="Form__actions Form__actions_center">
                <button className="Action__button Action__button_all Action__button_red">
                  Удалить
                </button>
              </div>

              <div className="Form__actions Form__actions_center">
                <div className="Action__link" onClick={() => setIsDelete(false)}>
                  Отмена
                </div>
              </div>
            </form>
          )}
        </Modal>

        {!!modalControlPointEdit && (
          <ModalControlPointEdit
            contract={modalControlPointEdit.item}
            controlPoint={modalControlPointEdit.controlPoint}
            successfulRequest={() => (
              setIsUpdateObject(!isUpdateObject), props.successfulRequest()
            )}
            handleClose={() => setModalControlPointEdit(null)}
          />
        )}
      </>
    )
  );
}

export default ModalContractEdit;
