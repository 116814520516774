import React from "react";
import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";
import {ButtonIcon, Icon, Loading, Search, TextSearchMarker,} from "@vokymlak/kabinet-ui";
import moment from "moment/moment";
import ModalCompanyFnsCertificateDownload from "./ModalCompanyFnsCertificateDownload";

function FnsCertificatesPage(props) {
  const {
    list,
    setList,
    count,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    inputSearchText,
    onChangeSearchText,
    searchQuery,

    setIsUpdateList,
    isUpdateList,

    modalCompanyFnsCertificateDownload,
    setModalCompanyFnsCertificateDownload,
  } = props;

  const getDaysDifference = (date1, date2) => {
    return moment(date2, 'YYYY-MM-DD').
    diff(moment(date1, 'YYYY-MM-DD'), 'days');
  }

  return (
    <ScrollBlock style={{ width: "100%", height: "100%" }}>
      <div className="Page">
        <div className="Page__title">
          <div
            className="Title__text"
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: 21,
              fontWeight: 700,
            }}
          >
            Сертификаты
            {!!list && (
              <span style={{ fontSize: 12, opacity: 0.75, marginLeft: 6 }}>
                ({count})
              </span>
            )}
            <span style={{ width: 220, marginLeft: 16 }}>
              <Search
                reference={inputSearchText}
                type="text"
                label={"Поиск"}
                isFixed={true}
                onChange={() => onChangeSearchText()}
              />
            </span>
          </div>

          <div className="Title__settings">
            {!!list && (
              <ButtonIcon
                tooltipText={
                  list.length === unrolls.length ? "Свернуть" : "Развернуть"
                }
                iconName={
                  list.length === unrolls.length
                    ? "custom-unfold-less"
                    : "custom-unfold-more"
                }
                iconColor={"#20202075"}
                size={28}
                style={{ marginLeft: 8 }}
                onClick={() => changeAllUnroll(list, "uuid")}
              />
            )}

            <ButtonIcon
              tooltipText={"Обновить"}
              iconName={"custom-change-circle"}
              iconColor={"#20202075"}
              size={28}
              style={{ marginLeft: 8 }}
              onClick={() => (setList(null), setIsUpdateList(!isUpdateList))}
            />
          </div>
        </div>

        {!list && (
          <div className={"Page__loader"}>
            <Loading />
          </div>
        )}

        {!!list && (
          <div className="Page__content">
            <ul className="List">
              {list.map((item) => (
                <React.Fragment key={"item_" + item.uuid}>
                  <li
                    className={
                      isUnroll(item.uuid) ? "List__item active" : "List__item"
                    }
                    style={{
                      backgroundColor: !!moment(
                        moment(item.fns_certificate.after).format(
                          "YYYY-MM-DD"
                        )
                      ).isSameOrBefore(moment().format("YYYY-MM-DD"))
                        ? "#ef9a9a"
                        : getDaysDifference(moment().format("YYYY-MM-DD"), moment(item.fns_certificate.after).format(
                          "YYYY-MM-DD"
                        )) > (365 * 5)
                          ? "#ffe082" : false,
                    }}
                    // onClick={() => changeUnroll(item.uuid)}
                    onClick={() => setModalCompanyFnsCertificateDownload(item)}
                  >
                    <div className="Item__title">
                      <div className="Element__title">
                        <div>
                          <div>
                            <TextSearchMarker
                              text={item.company.short_name}
                              search={searchQuery}
                            />
                          </div>

                          <div style={{
                            display: "flex"
                          }}>
                            <div style={{
                              marginRight: 8
                            }}>
                              <Icon
                                name={!!item.fns_certificate.is_fns && item.fns_certificate.is_fns ? "custom-award-star" : "custom-workspace-premium"}
                                size={42}
                                color={"#202020"}
                              />
                            </div>

                          <div
                            style={{
                              fontSize: 15,
                              fontWeight: 400,
                            }}
                          >
                            <div
                              style={{
                                marginBottom: 4,
                              }}
                            >
                              <TextSearchMarker
                                text={item.fns_certificate.subject}
                                search={searchQuery}
                              />
                              <span
                                style={{
                                  fontSize: 15,
                                  opacity: 0.75,
                                  marginLeft: 4
                                }}
                              >
                              {!!item.fns_certificate.email &&
                                <>(
                                <TextSearchMarker
                                  text={item.fns_certificate.email}
                                  search={searchQuery}
                                />
                                )</>}
                            </span>
                            </div>
                            <div
                              style={{
                                fontSize: 11,
                                fontWeight: 700,
                                backgroundColor: "#202020",
                                display: "inline-block",
                                color: "white",
                                padding: "2px 6px",
                                borderRadius: 24,
                                marginRight: 4,
                              }}
                            >
                              {!!item.fns_certificate && item.fns_certificate.is_fns ? 'Сертификат ФНС' : 'Сертификат'}
                            </div>

                            <div
                              style={{
                                fontSize: 11,
                                fontWeight: 700,
                                backgroundColor: "#202020",
                                display: "inline-block",
                                color: "white",
                                padding: "2px 6px",
                                borderRadius: 24,
                                marginRight: 4,
                              }}
                            >
                              Название {item.fns_certificate.name}
                            </div>
                            <div
                              style={{
                                fontSize: 11,
                                fontWeight: 700,
                                backgroundColor: "#202020",
                                display: "inline-block",
                                color: "white",
                                padding: "2px 6px",
                                borderRadius: 24,
                                marginRight: 4,
                              }}
                            >
                              Действителен с{" "}
                              {moment(item.fns_certificate.before).format(
                                "DD.MM.YYYY"
                              )}{" "}
                              по{" "}
                              {moment(item.fns_certificate.after).format(
                                "DD.MM.YYYY"
                              )}
                            </div>
                          </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="Item__settings">
                      <div className="Element__datetime">
                        {getDaysDifference(moment().format("YYYY-MM-DD"), moment(item.fns_certificate.after).format(
                          "YYYY-MM-DD"
                        )) > 0 && (
                          <div
                          style={{
                          backgroundColor:
                            getDaysDifference(moment().format("YYYY-MM-DD"), moment(item.fns_certificate.after).format(
                            "YYYY-MM-DD"
                            )) < (31)
                            ? "#cf2f00" :
                              getDaysDifference(moment().format("YYYY-MM-DD"), moment(item.fns_certificate.after).format(
                                "YYYY-MM-DD"
                              )) > (365 * 5)
                                ? "#202020" : "#1565c0",
                          padding: "0 4px",
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "nowrap",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: 24,
                          marginLeft: 12,
                        }}
                          >
                          <div
                          style={{
                          padding: "4px 4px",
                          color: "white",
                          fontSize: 14,
                          pointerEvents: "auto",
                          fontWeight: 700,
                          cursor: "default"
                        }}
                          >
                        {!!item.fns_certificate.after &&
                          getDaysDifference(moment().format("YYYY-MM-DD"), moment(item.fns_certificate.after).format("YYYY-MM-DD"))}
                            &nbsp;д.
                          </div>
                          </div>
                        )}
                        {getDaysDifference(moment().format("YYYY-MM-DD"), moment(item.fns_certificate.after).format(
                          "YYYY-MM-DD"
                        )) < 0 && (
                          <div
                            style={{
                              backgroundColor: "#202020",
                              padding: "0 4px",
                              display: "flex",
                              flexDirection: "row",
                              flexWrap: "nowrap",
                              alignItems: "center",
                              justifyContent: "center",
                              borderRadius: 24,
                              marginLeft: 12,
                            }}
                          >
                            <div
                              style={{
                                padding: "4px 4px",
                                color: "white",
                                fontSize: 14,
                                pointerEvents: "auto",
                                fontWeight: 700,
                                cursor: "default"
                              }}
                            >
                              Недействительный
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </li>

                  {isUnroll(item.uuid) && (
                    <li className="List__content">
                    </li>
                  )}
                </React.Fragment>
              ))}
            </ul>
          </div>
        )}

        {!!modalCompanyFnsCertificateDownload && (
          <ModalCompanyFnsCertificateDownload
            fnsCertificate={modalCompanyFnsCertificateDownload}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalCompanyFnsCertificateDownload(null)}
          />
        )}
      </div>
    </ScrollBlock>
  );
}

export default FnsCertificatesPage;
