import React from "react";
import styleTumbler from "../../../../../styles/modules/Tumbler.module.scss";
import {Navigate, NavLink, Route, Routes} from "react-router-dom";
import ProjectFinanceClientTasks from "./components/ProjectFinanceClientTasks/ProjectFinanceClientTasks";
import ProjectFinanceEmployeeTasks from "./components/ProjectFinanceEmployeeTasks/ProjectFinanceEmployeeTasks";

function ProjectTasksPage(props) {
  return (
    <>
      <div className="Page__title">
        <div className="Title__text">
          <div
            className="Title__text"
            style={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
              fontSize: 19,
              fontWeight: 700,
              marginRight: 6,
            }}
          >
            <div>
              Финансы
            </div>
          </div>


        </div>

        <div className="Title__settings">
          <ul className={styleTumbler._}>
            <li className={styleTumbler._link}>
              <NavLink
                className={({isActive}) =>
                  isActive
                    ? [styleTumbler._text, styleTumbler.active].join(" ")
                    : styleTumbler._text
                }
                to={"./client-tasks"}
                style={{
                  position: "relative",
                }}
              >
                Задачи клиенту
              </NavLink>
            </li>
            <li className={styleTumbler._link}>
              <NavLink
                className={({isActive}) =>
                  isActive
                    ? [styleTumbler._text, styleTumbler.active].join(" ")
                    : styleTumbler._text
                }
                to={"./employee-tasks"}
                style={{
                  position: "relative",
                }}
              >
                Задачи сотрудников
              </NavLink>
            </li>
          </ul>
        </div>
      </div>

      <Routes>
        <Route path="/client-tasks" element={<ProjectFinanceClientTasks {...props}/>}/>
        <Route path="/employee-tasks" element={<ProjectFinanceEmployeeTasks {...props}/>}/>

        <Route exact path="/" element={<Navigate to="./client-tasks"/>}/>
      </Routes>
    </>
  );
}

export default ProjectTasksPage;
