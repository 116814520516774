import React, {Fragment, useEffect, useState} from "react";
import moment from "moment";
import {getAccountFio} from "@vokymlak/kabinet-ui";

function TicketLaborCostView(props) {
  const { object } = props;

  return (
      <Fragment>
        {!!object.labor_costs && (
          <table
            className="Table"
            style={{
              marginBottom: 12,
            }}
          >
            <thead className="Thead">
            <tr className="Table__tr Table__tr_padding Table__tr_border">
              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#dfdfdf",
                }}
              >
                <div className="Th__title">Дата</div>
              </th>
              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#dfdfdf",
                }}
              >
                <div className="Th__title">Исполнитель</div>
              </th>
              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#dfdfdf",
                }}
              >
                <div className="Th__title">Сколько</div>
              </th>
              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#dfdfdf",
                }}
              >
                <div className="Th__title">Примечание</div>
              </th>
            </tr>
            </thead>
            <tbody>
            {object.labor_costs.map((cost, index) => (
              <tr
                className="Table__tr Table__tr_hover Table__tr_padding Table__tr_border"
                key={"item_" + index}
              >
                <td className="Table__td Table__td_border">
                  <div className="Td__text ">
                    {moment(cost.date).format(
                      "DD.MM.YYYY"
                    )}
                  </div>
                </td>
                <td className="Table__td Table__td_border">
                  <div className="Td__text">
                    <div
                      style={{
                        fontSize: 17,
                        fontWeight: 400,
                      }}
                    >
                      {(!!cost.executor && getAccountFio(cost.executor)) || "-"}
                    </div>
                  </div>
                </td>
                <td className="Table__td Table__td_border">
                  <div className="Td__text">
                    <div
                      style={{
                        fontSize: 17,
                        fontWeight: 400,
                      }}
                    >
                      {(!!cost.time_spent && cost.time_spent / 100) || "-" }
                    </div>
                  </div>
                </td>
                <td className="Table__td Table__td_border">
                  <div className="Td__text">
                    <div
                      style={{
                        fontSize: 17,
                        fontWeight: 400,
                      }}
                    >
                      {(!!cost.note && cost.note) || "-" }
                    </div>
                  </div>
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        )}

        {!object.labor_costs && (
          <div
            style={{
              width: "100%",
              // height: "100%",
              display: "flex",
              flexDirection: "column",
              flexWrap: "nowrap",
              alignItems: "center",
              justifyContent: "center",
              padding: "32px 0 32px",
              fontSize: 36,
              opacity: 0.1,
            }}
        >
          Нет затрат
        </div>
        )}
      </Fragment>
  );
}

export default TicketLaborCostView;
