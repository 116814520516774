import React, { useEffect, useRef, useState } from "react";
import { ROOT_URL } from "../../../config/url";
import { useList, useAction, useUnroll } from "@vokymlak/kabinet-ui";

function useGeneralTasks() {
  const {
    list: [list, count, setList],
    update: [isUpdateList, setIsUpdateList, updateList],
    page: [pageNumber, setPageNumber, pageLimit, setPageLimit],
    search: [searchQuery, setSearchQuery],
    setApiUrlParams,
  } = useList({
    apiUrl: "/api/general-tasks",
    rootUrl: ROOT_URL,
    // isPagination: true,
    // paginationLimit: 100,
    isSearch: true,
  });

  const { unrolls, isUnroll, changeUnroll, changeAllUnroll } = useUnroll();

  const [isArchive, setIsArchive] = useState(false);

  const inputSearchText = useRef(null);
  useEffect(() => {
    setPageNumber(1);
  }, [searchQuery]);

  function onChangeSearchText() {
    setSearchQuery(inputSearchText.current.value);
  }

  const changePage = (data) => {
    if (pageNumber !== data.currentPage) {
      setPageNumber(data.currentPage);
    }
  };

  useEffect(() => {
    let params = null;
    if (isArchive) {
      params = {
        ...params,
        archive: isArchive,
      };
    }
    setApiUrlParams({ ...params });
  }, [isArchive]);

  const { setAction } = useAction("general-tasks", () => updateList());

  useEffect(() => {
    if (!!list) {
      const listUuid = list.map((i) => i.uuid);
      setAction([...listUuid, "general-tasks"]);
    } else {
      setAction("general-tasks");
    }
  }, [list]);

  return {
    list,
    setList,
    count,

    pageNumber,
    pageLimit,
    setPageLimit,
    changePage,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    setIsUpdateList,
    isUpdateList,

    isArchive,
    setIsArchive,

    inputSearchText,
    onChangeSearchText,
    searchQuery,
  };
}

export default useGeneralTasks;
