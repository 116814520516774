import React, {createRef, useContext, useRef, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {AuthContext, authLogin} from "@vokymlak/kabinet-ui";
import {ROOT_URL} from "../../../config/url";
import logotype from "../components/logotype.png";
import ReCAPTCHA from "react-google-recaptcha";
import "../components/Auth.scss";
import {toast} from "sonner";
import {z} from "zod";
import {InputText} from "../components/InputText/InputText";
import moment from "moment/moment";

const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY;

function isEmail(email) {
  // Создаем регулярное выражение для проверки формата email-адреса
  const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  // Проверяем соответствие заданной строки формату email-адреса
  return re.test(email);
}

const getPhoneNumber = (value) => {
  if (!value) {
    value = '';
  }
  return value.replace(/\D/g, '').length > 11
    ? value.replace(/\D/g, '').substring(0, 11)
    : value.replace(/\D/g, '');
};

// Функция для форматирования номера телефона
const formatPhoneNumber = (value) => {
  if (value.length === 0 || value.length === 1) {
    return '+7 (';
  } else if (value.length === 2) {
    return '+7 (' + value.substring(1, 2);
  } else if (value.length === 3) {
    return '+7 (' + value.substring(1, 3);
  } else if (value.length === 4) {
    return '+7 (' + value.substring(1, 4);
  } else if (value.length === 5) {
    return '+7 (' + value.substring(1, 4) + ') ' + value.substring(4, 5);
  } else if (value.length === 6) {
    return '+7 (' + value.substring(1, 4) + ') ' + value.substring(4, 6);
  } else if (value.length === 7) {
    return '+7 (' + value.substring(1, 4) + ') ' + value.substring(4, 7);
  } else if (value.length === 8) {
    return (
      '+7 (' +
      value.substring(1, 4) +
      ') ' +
      value.substring(4, 7) +
      '-' +
      value.substring(7, 8)
    );
  } else if (value.length === 9) {
    return (
      '+7 (' +
      value.substring(1, 4) +
      ') ' +
      value.substring(4, 7) +
      '-' +
      value.substring(7, 9)
    );
  } else if (value.length === 10) {
    return (
      '+7 (' +
      value.substring(1, 4) +
      ') ' +
      value.substring(4, 7) +
      '-' +
      value.substring(7, 9) +
      '-' +
      value.substring(9, 10)
    );
  } else if (value.length === 11 || value.length > 11) {
    return (
      '+7 (' +
      value.substring(1, 4) +
      ') ' +
      value.substring(4, 7) +
      '-' +
      value.substring(7, 9) +
      '-' +
      value.substring(9, 11)
    );
  } else {
    return ''
  }
};

export const Login = () => {
  const [isPhone, setIsPhone] = useState(false);

  // Данные формы.
  const [formData, setFormData] = useState({
    login: null,
    password: null
  });

  // Данные об ошибках полей формы.
  const [formErrors, setFormErrors] = useState({
    login: false,
    password: false
  });

  const {authState, authDispatch} = useContext(AuthContext);
  const [recaptchaError, setRecaptchaError] = useState(false);
  const navigate = useNavigate();

  const recaptchaRef = createRef();

  const changeFormData = (event) => {
    let {name, value} = event.target;

    let newValue = value.trim()

    if (name === 'login' && isPhone) {
      newValue = getPhoneNumber(newValue)
    }

    if (newValue === '') {
      newValue = null
    }

    setFormData(prevData => ({
      ...prevData,
      [name]: newValue
    }));

    setFormErrors(prevError => ({
      ...prevError,
      [name]: false
    }));
  };

  const handleLogin = async () => {
    const FormDataSchema = z
      .object({
        login: isPhone ? z.string().min(11).max(11) :
          isEmail(formData.login || "") ? z.string().email() : z.string(),
        password: z.string()
      });

    try {
      FormDataSchema.parse({...formData});
    } catch (error) {
      if (error instanceof z.ZodError) {
        // Обработка ошибок.
        error.issues.forEach(err => {
          err.path.forEach(name => {
            setFormErrors(prevError => ({
              ...prevError,
              [name]: true
            }));
          })
        })
      }

      toast.error(`Пожалуйста, заполните корректно все поля`);
      return;
    }

    if (!authState.authRequest) {
      if (recaptchaError) {
        await onRecaptchaResolved();
      } else {
        recaptchaRef.current.execute();
      }
    }
  };

  async function onRecaptchaResolved() {
    const jsonData = JSON.stringify({
        login: isEmail(formData.login) ? formData.login.toLowerCase() : formData.login,
        password: formData.password
      });

    return await authLogin(
      authDispatch,
      ROOT_URL,
      jsonData,
      () => {
        navigate("/");
      },
      (authError) => {
        // toast.error(authError);
        toast.error('Неверные учётные данные');
      }
    );
  }

  const checkIsPhone = () => {
    setFormData({...formData, login: null})
    setIsPhone(!isPhone)
  }

  return (
    <div className="Auth"
      onContextMenu={(event) => event.preventDefault()}
    >
        <div className="Form show">
          <div className="Form__logotype">
            <img src={logotype} alt={"АБВЗАКУПКИ"} height={40}/>
            <div style={{
              fontSize: 15,
              fontWeight: 400,
              marginBottom: 24
            }}>
              Личный кабинет
            </div>
          </div>

          <div className="Form__title">Вход</div>

          <div className="Form__field">
            <label>
              {!isPhone && (
                <InputText
                  label={"Электронная почта или логин"}
                  name="login"
                  value={formData.login}
                  onChange={changeFormData}
                  isError={formErrors['login']}
                />
              )}
              {isPhone && (
                <InputText
                  label={"Номер телефона"}
                  name="login"
                  value={formatPhoneNumber(formData.login || "")}
                  onChange={changeFormData}
                  isError={formErrors['login']}
                />
              )}
              <div className="Action__link Action__link_blue" style={{
                marginTop: 4,
                fontSize: 15,
                padding: "1px 2px 0",
              }} onClick={() => checkIsPhone()}>
                {!isPhone && "По номеру телефона"}
                {isPhone && "По электронной почте или логину"}
              </div>
            </label>
          </div>

          <div className="Form__field Form__field_last">
            <InputText
              type={"password"}
              isViewPassword={true}
              label={"Пароль"}
              name="password"
              value={formData.password}
              onChange={changeFormData}
              isError={formErrors['password']}
            />
            <Link className="Action__link Action__link_blue" style={{
              marginTop: 4,
              fontSize: 15,
              padding: "1px 2px 0",
            }} to={"/restore"}>
              Забыли пароль?
            </Link>
          </div>

          <div className="Form__actions Form__actions_center">
            <button
              className="Action__button Action__button_all Action__button_login"
              style={{
                opacity: authState.authRequest && 0.5,
                cursor: authState.authRequest && "default"
              }}
              onClick={authState.authRequest ? () => null : () => handleLogin()}
            >
              Войти
            </button>
          </div>

          {/*<div className="Form__links">*/}
          {/*  <span style={{marginRight: 6}}>У вас нет учетной записи?</span>*/}
          {/*  <Link className="Action__link Action__link_blue" to={"/registration"}>Создать</Link>*/}
          {/*</div>*/}
      </div>

      <div className="Form__links">
        <span style={{opacity: .75}}>
          ©{" "}2019-{moment().format("YYYY")}{" "}
          <Link className="Action__link" to={"https://абв-закупки.рф"}>ООО «Абвзакупки»</Link>
        </span>
      </div>

      <ReCAPTCHA
        ref={recaptchaRef}
        locale="ru"
        size="invisible"
        sitekey={RECAPTCHA_KEY}
        onChange={onRecaptchaResolved}
        onErrored={() => setRecaptchaError(true)}
      />
    </div>
  );
};
