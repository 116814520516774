import React from "react";
import ProjectContracts from "./components/ProjectContracts/ProjectContracts";
import styleTumbler from "../../../styles/modules/Tumbler.module.scss";
import { Navigate, NavLink, Route, Routes } from "react-router-dom";
import ProjectCalcContracts from "./components/ProjectCalcContracts/ProjectCalcContracts";
import { ScrollBlock } from "../../../components/ScrollBlock/ScrollBlock";
import ProjectPfhd from "./components/ProjectPfhd/ProjectPfhd";
import ProjectFinance from "./components/ProjectFinance/ProjectFinance";

function ProjectView(props) {
  const { object } = props;

  return (
    !!object && (
      <ScrollBlock style={{ width: "100%", height: "100%" }}>
        <div className="Page">
          <div className="Page__title">
            <div className="Title__text">
              <div
                className="Title__text"
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  fontSize: 19,
                  fontWeight: 700,
                }}
              >
                <div>
                  {!!object && object.name}
                </div>
                {!!object &&
                  !!object.company &&
                  !!object.company.short_name && (
                    <span
                      style={{
                        fontSize: 12,
                        fontWeight: 400,
                        maxWidth: 240,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {!!object &&
                        !!object.company &&
                        !!object.company.short_name &&
                        object.company.short_name}
                    </span>
                  )}
              </div>
            </div>

            <div className="Title__settings">
              <ul className={styleTumbler._}>
                <li className={styleTumbler._link}>
                  <NavLink
                    className={({isActive}) =>
                      isActive
                        ? [styleTumbler._text, styleTumbler.active].join(" ")
                        : styleTumbler._text
                    }
                    to={"./contracts"}
                    style={{
                      position: "relative",
                    }}
                  >
                    Договоры
                  </NavLink>
                </li>
                <li className={styleTumbler._link}>
                  <NavLink
                    className={({isActive}) =>
                      isActive
                        ? [styleTumbler._text, styleTumbler.active].join(" ")
                        : styleTumbler._text
                    }
                    to={"./pfhd"}
                    style={{
                      position: "relative",
                    }}
                  >
                    ПФХД
                  </NavLink>
                </li>
                <li className={styleTumbler._link}>
                  <NavLink
                    className={({isActive}) =>
                      isActive
                        ? [styleTumbler._text, styleTumbler.active].join(" ")
                        : styleTumbler._text
                    }
                    to={"./calc-contracts"}
                    style={{
                      position: "relative",
                    }}
                  >
                    Контрактация
                  </NavLink>
                </li>
                <li className={styleTumbler._link}>
                  <NavLink
                    className={({isActive}) =>
                      isActive
                        ? [styleTumbler._text, styleTumbler.active].join(" ")
                        : styleTumbler._text
                    }
                    to={"./finance"}
                    style={{
                      position: "relative",
                    }}
                  >
                    Финансы
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>

          <hr className={"Page__hr"} style={{marginBottom: 6}}/>

          <Routes>
            <Route
              path="/contracts"
              element={<ProjectContracts project={object}/>}
            />
            <Route
              path="/calc-contracts"
              element={<ProjectCalcContracts project={object}/>}
            />
            <Route
              path="/pfhd"
              element={<ProjectPfhd project={object}/>}
            />

            <Route path="/finance/*" element={<ProjectFinance project={object}/>}/>
            <Route exact path="/" element={<Navigate to="./contracts"/>}/>
          </Routes>
        </div>
      </ScrollBlock>
    )
  );
}

export default ProjectView;
