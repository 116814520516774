import React from "react";
import ModalProjectEdit from "./ModalProjectEdit";
import {Navigate, NavLink, Route, Routes} from "react-router-dom";
import ProjectContracts from "./components/ProjectContracts/ProjectContracts";
import ProjectTasks from "./components/ProjectTasks/ProjectTasks";
import ProjectGeneralTasks from "./components/ProjectGeneralTasks/ProjectGeneralTasks";
import styleTumbler from "../../../styles/modules/Tumbler.module.scss";
import {ButtonIcon, getAccountFio, Icon} from "@vokymlak/kabinet-ui";
import ProjectCalcContracts from "./components/ProjectCalcContracts/ProjectCalcContracts";
import ProjectSmp from "./components/ProjectSmp/ProjectSmp";
import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";
import ModalImportContractsScheduler from "./ModalImportContractsScheduler";
import moment from "moment/moment";
import ProjectPfhd from "./components/ProjectPfhd/ProjectPfhd";
import {ROOT_URL} from "../../../config/url";
import {Avatar} from "../../../components/Avatar/Avatar";
import ProjectFinance from "./components/ProjectFinance/ProjectFinance";

function ProjectPage(props) {
  const {
    object,
    isUpdateObject,
    setIsUpdateObject,

    modalProjectEdit,
    setModalProjectEdit,

    isModalImportContractsScheduler,
    setIsModalImportContractsScheduler,
  } = props;

  function isMD5Hash(str) {
    // Проверяем, соответствует ли строка формату MD5 хэша.
    const md5Regex = /^[a-f0-9]{32}$/i;
    return md5Regex.test(str);
  }

  return (
    !!object && (
      <ScrollBlock style={{width: "100%", height: "100%"}}>
        <div className="Page">
          <div className="Page__title" style={{
            height: "auto"
          }}>
            <div className="Title__text">
              <div
                className="Title__text"
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  fontSize: 19,
                  fontWeight: 700,
                }}
              >
                <div>
                  {!!object && object.name}
                  {!!object && !!object.project_year && (
                    <span
                      style={{fontSize: 12, opacity: 0.75, marginLeft: 6}}
                    >
                      {object.project_year}
                    </span>
                  )}
                  {!!object && !!object.law && (
                    <span
                      style={{fontSize: 12, opacity: 0.75, marginLeft: 6}}
                    >
                      ({object.law.tag})
                    </span>
                  )}
                </div>
                {!!object &&
                  !!object.company &&
                  !!object.company.short_name && (
                    <span
                      style={{
                        fontSize: 12,
                        fontWeight: 400,
                        maxWidth: 240,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {!!object &&
                        !!object.company &&
                        !!object.company.short_name &&
                        object.company.short_name}
                    </span>
                  )}
              </div>

              {/*{(!!object.manager || !!object.executor) && (*/}
              {/*  <div*/}
              {/*    className="Title__text"*/}
              {/*    style={{*/}
              {/*      marginLeft: 20,*/}
              {/*      display: "flex",*/}
              {/*      alignItems: "center",*/}
              {/*      flexDirection: "row",*/}
              {/*      flexWrap: "nowrap",*/}
              {/*      justifyContent: "flex-start",*/}
              {/*    }}*/}
              {/*  >*/}
              {/*    {!!object.manager && (*/}
              {/*      <div*/}
              {/*        style={{*/}
              {/*          fontSize: 12,*/}
              {/*          fontWeight: 400,*/}
              {/*          padding: "3px 9px 3px 9px",*/}
              {/*          backgroundColor: "#dfdfdf",*/}
              {/*          margin: "0 2px",*/}
              {/*          borderRadius: 24,*/}
              {/*          display: "flex",*/}
              {/*          alignItems: "center",*/}
              {/*          position: "relative",*/}
              {/*        }}*/}
              {/*      >*/}
              {/*        <span*/}
              {/*          style={{*/}
              {/*            fontSize: 10,*/}
              {/*            position: "absolute",*/}
              {/*            left: 12,*/}
              {/*            top: -6,*/}
              {/*            opacity: 0.75,*/}
              {/*          }}*/}
              {/*        >*/}
              {/*          Куратор*/}
              {/*        </span>*/}
              {/*        <span>{getAccountFio(object.manager)}</span>*/}
              {/*      </div>*/}
              {/*    )}*/}

              {/*    {!!object.executor && (*/}
              {/*      <div*/}
              {/*        style={{*/}
              {/*          fontSize: 12,*/}
              {/*          fontWeight: 400,*/}
              {/*          padding: "3px 9px 3px 9px",*/}
              {/*          backgroundColor: "#dfdfdf",*/}
              {/*          margin: "0 2px",*/}
              {/*          borderRadius: 24,*/}
              {/*          display: "flex",*/}
              {/*          alignItems: "center",*/}
              {/*          position: "relative",*/}
              {/*        }}*/}
              {/*      >*/}
              {/*        <span*/}
              {/*          style={{*/}
              {/*            fontSize: 10,*/}
              {/*            position: "absolute",*/}
              {/*            left: 12,*/}
              {/*            top: -6,*/}
              {/*            opacity: 0.75,*/}
              {/*          }}*/}
              {/*        >*/}
              {/*          Специалист*/}
              {/*        </span>*/}
              {/*        <span>{getAccountFio(object.executor)}</span>*/}
              {/*      </div>*/}
              {/*    )}*/}
              {/*  </div>*/}
              {/*)}*/}

              <ButtonIcon
                tooltipText={"Редактировать"}
                iconName={"custom-edit"}
                iconColor={"#202020"}
                size={36}
                style={{
                  marginLeft: 16,
                  zIndex: 9,
                }}
                onClick={() => setModalProjectEdit(object)}
              />

              <ButtonIcon
                tooltipText={"Планировщик интеграции с РИС"}
                iconName={"custom-priority"}
                iconColor={
                  !!object.ris_schedulers && object.ris_schedulers[0].is_work &&
                  !!object.ris_schedulers[0].company_authorize &&
                  !object.ris_schedulers[0].company_authorize.deleted_at
                    ? "#0064ff"
                    : "#202020"
                }
                size={24}
                style={{marginLeft: 12}}
                onClick={() => setIsModalImportContractsScheduler(true)}
              />

              {!!object.ris_schedulers && object.ris_schedulers[0].is_work && !!object.ris_schedulers[0].attempt_date && (
                <div style={{
                  marginLeft: 6,
                  height: 32,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center"
                }}>
                  <div style={{
                    fontSize: 10,
                    fontWeight: 400
                  }}>
                    Дата обновления
                  </div>
                  <div style={{
                    fontSize: 12,
                    fontWeight: 400
                  }}>
                    {moment(object.ris_schedulers[0].attempt_date.substring(0, 19)).format('DD.MM.YYYY HH:mm:ss')}
                  </div>
                  {object.ris_schedulers[0].is_queue && (
                    <div style={{
                      fontSize: 10,
                      fontWeight: 400,
                      color: "#0064ff"
                    }}>
                      В очереди
                    </div>
                  )}
                  {!object.ris_schedulers[0].attempt_is_auth && (
                    <div style={{
                      fontSize: 10,
                      fontWeight: 400,
                      color: "#ff0000"
                    }}>
                      Не авторизован
                    </div>
                  )}
                </div>
              )}
            </div>

            <div className="Title__settings">
              <ul className={styleTumbler._}>
                <li className={styleTumbler._link}>
                  <NavLink
                    className={({isActive}) =>
                      isActive
                        ? [styleTumbler._text, styleTumbler.active].join(" ")
                        : styleTumbler._text
                    }
                    to={"./contracts"}
                    style={{
                      position: "relative",
                    }}
                  >
                    Договоры
                    <div
                      style={{
                        position: "absolute",
                        top: 2,
                        right: 1,
                        display: "flex",
                      }}
                    >
                      {!!object.count_overdue_points &&
                        object.count_overdue_points > 0 && (
                          <div
                            className={"Collected__count Collected__count_red"}
                            style={{
                              height: 4,
                              width: 4,
                              borderRadius: 6,
                              margin: "0 1px",
                              padding: 0,
                            }}
                          ></div>
                        )}
                      {!!object.count_current_points &&
                        object.count_current_points > 0 && (
                          <div
                            className={
                              "Collected__count Collected__count_yellow"
                            }
                            style={{
                              height: 4,
                              width: 4,
                              borderRadius: 6,
                              margin: "0 1px",
                              padding: 0,
                            }}
                          ></div>
                        )}
                      {!!object.count_planned_points &&
                        object.count_planned_points > 0 && (
                          <div
                            className={
                              "Collected__count Collected__count_green"
                            }
                            style={{
                              height: 4,
                              width: 4,
                              borderRadius: 6,
                              margin: "0 1px",
                              padding: 0,
                            }}
                          ></div>
                        )}
                    </div>
                  </NavLink>
                </li>
                <li className={styleTumbler._link}>
                  <NavLink
                    className={({isActive}) =>
                      isActive
                        ? [styleTumbler._text, styleTumbler.active].join(" ")
                        : styleTumbler._text
                    }
                    to={"./pfhd"}
                    style={{
                      position: "relative",
                    }}
                  >
                    ПФХД
                  </NavLink>
                </li>
                <li className={styleTumbler._link}>
                  <NavLink
                    className={({isActive}) =>
                      isActive
                        ? [styleTumbler._text, styleTumbler.active].join(" ")
                        : styleTumbler._text
                    }
                    to={"./calc-contracts"}
                    style={{
                      position: "relative",
                    }}
                  >
                    Контрактация
                  </NavLink>
                </li>
                <li className={styleTumbler._link}>
                  <NavLink
                    className={({isActive}) =>
                      isActive
                        ? [styleTumbler._text, styleTumbler.active].join(" ")
                        : styleTumbler._text
                    }
                    to={"./calc-smp"}
                    style={{
                      position: "relative",
                    }}
                  >
                    СМП
                  </NavLink>
                </li>
                <li className={styleTumbler._link}>
                  <NavLink
                    className={({isActive}) =>
                      isActive
                        ? [styleTumbler._text, styleTumbler.active].join(" ")
                        : styleTumbler._text
                    }
                    to={"./tasks"}
                    style={{
                      position: "relative",
                    }}
                  >
                    Задачи проекта
                    <div
                      style={{
                        position: "absolute",
                        top: 2,
                        right: 1,
                        display: "flex",
                      }}
                    >
                      {!!object.count_overdue_tasks &&
                        object.count_overdue_tasks > 0 && (
                          <div
                            className={"Collected__count Collected__count_red"}
                            style={{
                              height: 4,
                              width: 4,
                              borderRadius: 6,
                              margin: "0 1px",
                              padding: 0,
                            }}
                          ></div>
                        )}
                      {!!object.count_current_tasks &&
                        object.count_current_tasks > 0 && (
                          <div
                            className={
                              "Collected__count Collected__count_yellow"
                            }
                            style={{
                              height: 4,
                              width: 4,
                              borderRadius: 6,
                              margin: "0 1px",
                              padding: 0,
                            }}
                          ></div>
                        )}
                      {!!object.count_planned_tasks &&
                        object.count_planned_tasks > 0 && (
                          <div
                            className={
                              "Collected__count Collected__count_green"
                            }
                            style={{
                              height: 4,
                              width: 4,
                              borderRadius: 6,
                              margin: "0 1px",
                              padding: 0,
                            }}
                          ></div>
                        )}
                    </div>
                  </NavLink>
                </li>
                <li className={styleTumbler._link}>
                  <NavLink
                    className={({isActive}) =>
                      isActive
                        ? [styleTumbler._text, styleTumbler.active].join(" ")
                        : styleTumbler._text
                    }
                    to={"./general-tasks"}
                    style={{
                      position: "relative",
                    }}
                  >
                    Общие задачи
                    <div
                      style={{
                        position: "absolute",
                        top: 2,
                        right: 1,
                        display: "flex",
                      }}
                    >
                      {!!object.count_overdue_generals &&
                        object.count_overdue_generals > 0 && (
                          <div
                            className={"Collected__count Collected__count_red"}
                            style={{
                              height: 4,
                              width: 4,
                              borderRadius: 6,
                              margin: "0 1px",
                              padding: 0,
                            }}
                          ></div>
                        )}
                      {!!object.count_current_generals &&
                        object.count_current_generals > 0 && (
                          <div
                            className={
                              "Collected__count Collected__count_yellow"
                            }
                            style={{
                              height: 4,
                              width: 4,
                              borderRadius: 6,
                              margin: "0 1px",
                              padding: 0,
                            }}
                          ></div>
                        )}
                      {!!object.count_planned_generals &&
                        object.count_planned_generals > 0 && (
                          <div
                            className={
                              "Collected__count Collected__count_green"
                            }
                            style={{
                              height: 4,
                              width: 4,
                              borderRadius: 6,
                              margin: "0 1px",
                              padding: 0,
                            }}
                          ></div>
                        )}
                    </div>
                  </NavLink>
                </li>
                <li className={styleTumbler._link}>
                  <NavLink
                    className={({isActive}) =>
                      isActive
                        ? [styleTumbler._text, styleTumbler.active].join(" ")
                        : styleTumbler._text
                    }
                    to={"./finance"}
                    style={{
                      position: "relative",
                    }}
                  >
                    Финансы
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>

          {(!!object.clients || !!object.employees) && (
            <div className="Page__title" style={{
              height: "auto"
            }}>
              <div className="Title__text">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    alignItems: "center",
                  }}
                >
                  {!!object.clients && object.clients.map((item) => (
                    <div
                      key={item.uuid}
                      style={{
                        fontSize: 12,
                        fontWeight: 400,
                        padding: "3px 9px 3px 3px",
                        backgroundColor: "#20202015",
                        marginRight: 4,
                        borderRadius: 24,
                        display: "flex",
                        alignItems: "center",
                        position: "relative",
                      }}
                    >
                                      <span
                                        style={{
                                          fontSize: 10,
                                          position: "absolute",
                                          left: 16,
                                          top: -8,
                                          opacity: 1,
                                          backgroundColor: "#202020",
                                          color: "white",
                                          padding: "0px 4px",
                                          borderRadius: 24
                                        }}
                                      >
                                        Клиент
                                      </span>
                      <span style={{
                        display: "flex"
                      }}>
                                        <span>
                                          <div style={{
                                            marginRight: 4,
                                            height: 16
                                          }}>
                                            <Icon
                                              name={"custom-account-circle"}
                                              size={16}
                                              color={"#202020"}
                                            />
                                          </div>
                                        </span>
                                        <span>
                                          {getAccountFio(item.client)}
                                        </span>
                                      </span>
                    </div>
                  ))}

                  {!!object.employees && object.employees.map((item) => (
                    <div
                      key={item.uuid}
                      style={{
                        fontSize: 12,
                        fontWeight: 400,
                        padding: "3px 9px 3px 3px",
                        backgroundColor: "#20202015",
                        marginRight: 4,
                        borderRadius: 24,
                        display: "flex",
                        alignItems: "center",
                        position: "relative",
                      }}
                    >
                      {!!item.project_employee_role_type && (
                        <span
                          style={{
                            fontSize: 10,
                            position: "absolute",
                            left: 16,
                            top: -8,
                            opacity: 1,
                            backgroundColor: "#202020",
                            color: "white",
                            padding: "0px 4px",
                            borderRadius: 24,
                            whiteSpace: "nowrap"
                          }}
                        >
                                          {item.project_employee_role_type.name === 'Специалист по исполнению'
                                            ? 'Специалист'
                                            : item.project_employee_role_type.name}
                                        </span>
                      )}
                      <span style={{
                        display: "flex"
                      }}>
                                        <span>
                                          <div style={{
                                            marginRight: 4,
                                            height: 16
                                          }}>
                                            {!!item.employee.avatar && isMD5Hash(item.employee.avatar) && (
                                              <img height={16} width={16} style={{
                                                borderRadius: 16,
                                              }} src={ROOT_URL + "/api/account/avatar/" + item.employee.avatar}/>
                                            )}
                                            {!item.employee.avatar && (
                                              <Avatar size={16} value={getAccountFio(item.employee)}/>
                                            )}
                                          </div>
                                        </span>
                                        <span>
                                          {getAccountFio(item.employee)}
                                        </span>
                                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          <hr className={"Page__hr"} style={{marginBottom: 6}}/>

          <Routes>
            <Route
              path="/contracts"
              element={<ProjectContracts {...props} project={object}/>}
            />
            <Route path="/pfhd" element={<ProjectPfhd project={object}/>}/>
            <Route path="/tasks" element={<ProjectTasks project={object}/>}/>
            <Route path="/finance/*" element={<ProjectFinance project={object}/>}/>
            <Route
              path="/general-tasks"
              element={<ProjectGeneralTasks project={object}/>}
            />
            <Route
              path="/calc-contracts"
              element={<ProjectCalcContracts project={object}/>}
            />
            <Route path="/calc-smp" element={<ProjectSmp project={object}/>}/>

            <Route exact path="/" element={<Navigate to="./contracts"/>}/>
          </Routes>

          {!!modalProjectEdit && (
            <ModalProjectEdit
              project={modalProjectEdit}
              successfulRequest={() => setIsUpdateObject(!isUpdateObject)}
              handleClose={() => setModalProjectEdit(null)}
            />
          )}

          {isModalImportContractsScheduler && (
            <ModalImportContractsScheduler
              project={object}
              successfulRequest={() => setIsUpdateObject(!isUpdateObject)}
              handleClose={() => setIsModalImportContractsScheduler(false)}
            />
          )}
        </div>
      </ScrollBlock>
    )
  );
}

export default ProjectPage;
