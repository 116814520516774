import React from "react";
import ContractSpecificationsWrapper from "./ContractSpecificationsWrapper";

function ContractSpecifications(props) {
  return (
      <ContractSpecificationsWrapper
        {...props}
      />
  );
}

export default ContractSpecifications;
