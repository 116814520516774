import React, { useRef, useState } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../../config/url";
import {
  useNotification,
  getErrorMessageByKey,
  jsonFormatText,
  isValidateNullText,
  Modal,
  Input,
  Select, newUuid,
} from "@vokymlak/kabinet-ui";

function extractVideoId(url) {
  let pattern = /(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))([\w-]{11})(?:\S+)?/;
  let match = url.match(pattern);
  if (match && match[1]) {
    return match[1];
  } else {
    return null;
  }
}

function ModalAddEditorVideo(props) {
  const inputTitle = useRef(null);

  const { notification } = useNotification();

  const changeAdd = (event) => {
    event.preventDefault();

    const title = jsonFormatText(inputTitle.current.value);

    if (isValidateNullText(title)) {
      notification("Заполните все обязательные поля", "red");
      return;
    }

    const textVideo = `<p><br></p><figure id="${newUuid()}">`+
      `<iframe id="video" width="560" height="315" src="https://www.youtube.com/embed/${extractVideoId(title)}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`+
      `</figure><p><br></p>`;

    let selection = window.getSelection();
    // Устанавливаем выделение заново
    selection.removeAllRanges();
    selection.addRange(props.range);

    props.successfulRequest(textVideo);
    props.handleClose();
  };

  const handleClose = () => {
    let selection = window.getSelection();
    // Устанавливаем выделение заново
    selection.removeAllRanges();
    selection.addRange(props.range);

    props.handleClose();
  };

  return (
    <Modal handleClose={() => handleClose()}>
      <form className="Form" onSubmit={(event) => changeAdd(event)}>
        <div className="Form__title">Видео</div>

        <div className="Form__field">
          <Input reference={inputTitle} type="text" label="Ссылка на видео с youtube *" />
        </div>

        <div className="Form__actions Form__actions_center">
          <button className="Action__button Action__button_all Action__button_blue">
            Добавить
          </button>
        </div>

        <div className="Form__actions Form__actions_center">
          <div className="Action__link" onClick={() => handleClose()}>
            Отмена
          </div>
        </div>
      </form>
    </Modal>
  );
}

export default ModalAddEditorVideo;
