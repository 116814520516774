import React, { useEffect, useRef, useState } from "react";
import { ROOT_URL } from "../../../config/url";
import {
  useList,
  useProfile,
  useAction,
  useUnroll, useNotification, useObject,
} from "@vokymlak/kabinet-ui";
import {useMatch} from "react-router-dom";

function useAppeals(props) {
  const { unrolls, isUnroll, changeUnroll, changeAllUnroll } = useUnroll();
  const match = useMatch("/appeals/:appealType");

  const [isArchive, setIsArchive] = useState((!!match && !!match.params && !!match.params.appealType &&
    match.params.appealType === "archive") || false);
  const [type, setType] = useState((!!match && !!match.params && !!match.params.appealType &&
    match.params.appealType !== "all" && match.params.appealType !== "archive" && match.params.appealType) || "null");
  const [status, setStatus] = useState("null");

  useEffect(() => {
    setType((!!match && !!match.params && !!match.params.appealType &&
      match.params.appealType !== "all" && match.params.appealType !== "archive" && match.params.appealType) || "null");
  }, [match]);

  const [selectAppeal, setSelectAppeal] = useState(null);

  const { profile } = useProfile();

  const { notification } = useNotification();

  const getParams = () => {
    let params = null;
    if (isArchive) {
      params = {
        ...params,
        archive: isArchive,
      };
    }
    if (!!type && type !== "null") {
      params = {
        ...params,
        type: type,
      };
    }
    if (!!status && status !== "null") {
      params = {
        ...params,
        status: status,
      };
    }

    if (!!props.selectCompany) {
      params = {
        ...params,
        company: props.selectCompany.uuid,
      };
    }
    if (!!props.selectEmployee) {
      params = {
        ...params,
        employee: props.selectEmployee.uuid,
      };
    }
    if (!!props.selectTab) {
      params = {
        ...params,
        tab: props.selectTab,
      };
    }

    return params
  }

  const {
    list: [list, count, setList],
    update: [isUpdateList, setIsUpdateList, changeUpdate],
    page: [pageNumber, setPageNumber, pageLimit, setPageLimit],
    search: [searchQuery, setSearchQuery],
    setApiUrlParams,
  } = useList({
    apiUrl: "/api/appeals",
    rootUrl: ROOT_URL,
    isPagination: true,
    paginationLimit: 25,
    isSearch: true,
    urlParams: getParams()
  });


  useEffect(() => {
    let appeal = null
    if (!!props.object && !!props.object.types) {
        props.object.types.map((t, index) => {
          if (type === t.tag) {
            appeal = t
          }
        })
    }
    setSelectAppeal(appeal)
  }, [props.object, type]);


  const inputSearchText = useRef(null);
  useEffect(() => {
    setPageNumber(1);
  }, [searchQuery]);
  const onChangeSearchText = () => {
    setSearchQuery(inputSearchText.current.value);
  };
  const changePage = (data) => {
    if (pageNumber !== data.currentPage) {
      setPageNumber(data.currentPage);
    }
  };

  useEffect(() => {
    setApiUrlParams({ ...getParams() });
  }, [isArchive, type, status, props.selectCompany, props.selectEmployee, props.selectTab]);

  const { setAction } = useAction("appeals", () => (changeUpdate()));
  useEffect(() => {
    if (!!list) {
      const listUuid = list.map((i) => i.uuid);
      setAction([...listUuid, "appeals"]);
    } else {
      setAction("appeals");
    }
  }, [list]);

  useEffect(() => {
    props.setUpdateMenu(p => !p);
  }, [isUpdateList]);

  return {
    profile,

    notification,

    object:  props.object,

    list,
    setList,
    count,

    pageNumber,
    pageLimit,
    setPageLimit,
    changePage,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    setIsUpdateList,
    isUpdateList,

    isArchive,
    setIsArchive,

    inputSearchText,
    onChangeSearchText,
    searchQuery,

    setType,
    type,
    setStatus,
    status,

    selectAppeal,
    setSelectAppeal,
  };
}

export default useAppeals;
