import axios from "axios";
import React, {useEffect, useState} from "react";
import {getErrorMessageByKey, useNotification} from "@vokymlak/kabinet-ui";

interface IListProps {
  list: [null, React.Dispatch<React.SetStateAction<null>>];
  update: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  page: [
    number,
    React.Dispatch<React.SetStateAction<number>>,
    number,
    React.Dispatch<React.SetStateAction<number>>
  ];
  search: [string, React.Dispatch<React.SetStateAction<string>>];
  count: [number];
  apiUrl: string;
  apiUrlParams: string;
  setApiUrl: React.Dispatch<React.SetStateAction<string>>;
  setApiUrlParams: React.Dispatch<React.SetStateAction<any>>;
}

function useList(
  apiUrl: string,
  rootUrl: string,
  isPagination: boolean = false,
  paginationLimit: number = 15,
  isSearch: boolean = false,
  search: string = "",
): IListProps {
  const [list, setList] = useState(null);
  const [isUpdateList, setIsUpdateList] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(paginationLimit);
  const [searchQuery, setSearchQuery] = useState(search || "");
  const [count, setCount] = useState(0);

  const [url, setApiUrl] = useState(apiUrl);
  const [urlParams, setApiUrlParams] = useState(null);

  const { notification } = useNotification();

  useEffect(() => {
    return () => {
      setList(null);
      setCount(0);
      setSearchQuery("");
    };
  }, []);

  function escapeRegExp(string: string): string {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }
  function replaceAll(str: string, find: string, replace: string): string {
    return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
  }

  const getUrlApi = () => {
    return url + (getUrlApiParams().length > 0 ? "?" + getUrlApiParams() : "");
  }

  const getUrlApiParams = () => {
    const params: any = {
      ...(urlParams || {}),
      ...((isSearch && {query: searchQuery}) || {}),
      ...((isPagination && {
        page: pageNumber,
        limit: pageLimit
      }) || {}),
    }

    return replaceAll(new URLSearchParams(params).toString(), '%2C', ',');
  }

  useEffect(() => {
    axios
      .get(
          getUrlApi(),
        {
          withCredentials: false,
          baseURL: rootUrl,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("tokenSession"),
          },
        }
      )
      .then((response) => {
        setCount(
          isPagination
            ? Number(response.headers["total-records"])
            : (!!response.data && response.data.length) || 0
        );
        setList(response.data);
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  }, [isUpdateList, url, urlParams, pageNumber, pageLimit, searchQuery]);

  return {
    list: [list, setList],
    update: [isUpdateList, setIsUpdateList],
    page: [pageNumber, setPageNumber, pageLimit, setPageLimit],
    search: [searchQuery, setSearchQuery],
    count: [count],
    apiUrl: url,
    apiUrlParams: getUrlApiParams(),
    setApiUrl,
    setApiUrlParams,
  };
}

export default useList;
