import React, { useState, useRef } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../../../config/url";
import {
  useObject,
  useNotification,
  getErrorMessageByKey,
  Input,
  Modal,
} from "@vokymlak/kabinet-ui";

function ModalProjectSmpEdit(props) {
  const {
    object: [project],
  } = useObject({
    apiUrl: "/api/project/" + props.project.uuid,
    rootUrl: ROOT_URL,
  });

  const [isArchive, setIsArchive] = useState(false);

  const inputAmountTransients = useRef(null);
  const inputAmountPp1352 = useRef(null);
  const inputAmountMonopolies = useRef(null);

  const { notification } = useNotification();

  const numberConvert = (numberString) => {
    return !!numberString && numberString.length > 0
      ? Number(String(Number(numberString).toFixed(2)).replace(".", ""))
      : null;
  };

  const changeEdit = (event) => {
    event.preventDefault();

    const amountTransients = inputAmountTransients.current.value;
    const amountPp1352 = inputAmountPp1352.current.value;
    // const amountMonopolies =  inputAmountMonopolies.current.value;

    const jsonData = JSON.stringify({
      amount_transients: numberConvert(amountTransients),
      amount_pp1352: numberConvert(amountPp1352),
      // amount_monopolies: numberConvert(amountMonopolies),
    });

    axios
      .put("/api/project/" + props.project.uuid + "/edit/smp", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });

    props.handleClose();
  };

  return (
    !!project && (
      <Modal handleClose={() => props.handleClose()}>
        {!isArchive && (
          <form className="Form" onSubmit={(event) => changeEdit(event)}>
            <div className="Form__title">
              {project.name}
              <div className="Text__signature">Расчет СМП</div>
            </div>

            <div className="Form__field">
              <Input
                reference={inputAmountTransients}
                type="number"
                label="Переходящие"
                value={
                  !!project.amount_transients && project.amount_transients / 100
                }
              />
            </div>

            <div className="Form__field">
              <Input
                reference={inputAmountPp1352}
                type="number"
                label="ПП 1352"
                value={!!project.amount_pp1352 && project.amount_pp1352 / 100}
              />
            </div>

            {/*<div className="Form__field Form__field_last">*/}
            {/*  <Input reference={inputAmountMonopolies} type="number" label="Субъекты естественных монополий" value={!!project.amount_monopolies && (project.amount_monopolies / 100)}  />*/}
            {/*</div>*/}

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_blue">
                Применить
              </button>
            </div>

            <div className="Form__actions Form__actions_center">
              <div className="Action__link" onClick={() => props.handleClose()}>
                Отмена
              </div>
            </div>
          </form>
        )}
      </Modal>
    )
  );
}

export default ModalProjectSmpEdit;
