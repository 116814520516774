import React, {Fragment} from "react";
import {TextSearchMarker, Tooltip,} from "@vokymlak/kabinet-ui";
import moment from "moment";


export const ContractSpecificationsList = (props) => {
  const {
    list,
    object,

    setModalSpecificationEdit,

    selectSpecification,
    setSelectSpecification,

    searchQuery,
  } = props;

  function getColorCount(count) {
    const countAmount = !!count ? count : 0;

    if (countAmount > 0) {
      return "#cf2f00";
    } else if (countAmount < 0) {
      return "#1565c0";
    }
    return "#202020";
  }

  return (
    !!list && (
      <Fragment>
        {list.map(item => (
          <tr
            className={
              "Table__tr Table__tr_padding Table__tr_border Table__tr_hover " +
              (!!selectSpecification && selectSpecification === item.uuid
                ? "Table__tr_focus"
                : "")
            }
            key={"item_" + item.uuid}
            onClick={() => (
              setModalSpecificationEdit(item), setSelectSpecification(item.uuid)
            )}
            style={{
              cursor: "pointer",
            }}
          >
            <td
              className="Table__td Table__td_border"
            >
              <Tooltip
                text={"Обновлено"}
                style={{width: "100%", height: "100%"}}
              >
                <div className="Td__text Td__text_center">
                  {!!item.updated_at
                    ? moment(item.updated_at).format("DD.MM.YYYY")
                    : moment(item.created_at).format("DD.MM.YYYY")}
                </div>
              </Tooltip>
            </td>

            <td
              className="Table__td Table__td_border"
            >
              <Tooltip text={"№"} style={{width: "100%", height: "100%"}}>
                <div className="Td__text Td__text_center">
                  {(!!item.registry_index && (
                      <TextSearchMarker
                        text={item.registry_index}
                        search={searchQuery}
                      />
                    )) ||
                    "-"}
                </div>
              </Tooltip>
            </td>
            <td
              className="Table__td Table__td_border"
            >
              <Tooltip
                text={"Реестровый номер"}
                style={{width: "100%", height: "100%"}}
              >
                <div className="Td__text Td__text_center">
                  {(!!item.registry_number && (
                      <TextSearchMarker
                        text={item.registry_number}
                        search={searchQuery}
                      />
                    )) ||
                    "-"}
                </div>
                <div className="Td__text Td__text_center" style={{
                  fontSize: 10,
                  fontWeight: 400
                }}>
                  {(!!item.ris_id && item.ris_id) ||
                    "-"} / {(!!item.ris_entity_id && item.ris_entity_id) ||
                  "-"}
                </div>
              </Tooltip>
            </td>

            {!!object.project && !!object.project.law && object.project.law.tag === "44" && (
              <td
                className="Table__td Table__td_border"
                style={{
                  width: 190,
                }}
              >
                <Tooltip
                  text={"КПГЗ"}
                  style={{width: 190, height: "100%"}}
                >
                  <div className="Td__text Td__text_center">
                    {(!!item.kpgz && (
                        <TextSearchMarker
                          text={item.kpgz}
                          search={searchQuery}
                        />
                      )) ||
                      "-"}
                  </div>
                </Tooltip>
              </td>
            )}
            <td
              className="Table__td Table__td_border"
              style={{
                width: 190,
              }}
            >
              <Tooltip
                text={"СПГЗ"}
                style={{width: 190, height: "100%"}}
              >
                <div className="Td__text Td__text_center">
                  {(!!item.spgz && (
                      <TextSearchMarker
                        text={item.spgz}
                        search={searchQuery}
                      />
                    )) ||
                    "-"}
                </div>
              </Tooltip>
            </td>
            {!!object.project && !!object.project.law && object.project.law.tag === "44" && (
              <td
                className="Table__td Table__td_border"
                style={{
                  width: 190,
                }}
              >
                <Tooltip
                  text={"КТРУ"}
                  style={{width: 190, height: "100%"}}
                >
                  <div className="Td__text Td__text_center">
                    {(!!item.ktru && (
                        <TextSearchMarker
                          text={item.ktru}
                          search={searchQuery}
                        />
                      )) ||
                      "-"}
                  </div>
                </Tooltip>
              </td>
            )}
            <td
              className="Table__td Table__td_border"
              style={{
                width: 190,
              }}
            >
              <Tooltip
                text={"ОКПД-2"}
                style={{width: 190, height: "100%"}}
              >
                <div className="Td__text Td__text_center">
                  {(!!item.okpd_2 && (
                      <TextSearchMarker
                        text={item.okpd_2}
                        search={searchQuery}
                      />
                    )) ||
                    "-"}
                </div>
              </Tooltip>
            </td>

            {!!object.project && !!object.project.law && object.project.law.tag === "223" && (
              <td
                className="Table__td Table__td_border"
                style={{
                  width: 190,
                }}
              >
                <Tooltip
                  text={"ОКВЭД-2"}
                  style={{width: 190, height: "100%"}}
                >
                  <div className="Td__text Td__text_center">
                    {(!!item.okved_2 && (
                        <TextSearchMarker
                          text={item.okved_2}
                          search={searchQuery}
                        />
                      )) ||
                      "-"}
                  </div>
                </Tooltip>
              </td>
            )}

            <td
              className="Table__td Table__td_border"
              style={{
                width: 190,
              }}
            >
              <Tooltip
                text={"Единица измерения"}
                style={{width: 190, height: "100%"}}
              >
                <div className="Td__text Td__text_center">
                  {(!!item.unit_measurement && (
                      <TextSearchMarker
                        text={item.unit_measurement}
                        search={searchQuery}
                      />
                    )) ||
                    "-"}
                </div>
              </Tooltip>
            </td>
            {!!object &&
              !object.is_without_volume && (
                <>
                  <td
                    className="Table__td Table__td_border"
                  >
                    <Tooltip
                      text={"Объем"}
                      style={{width: "100%", height: "100%"}}
                    >
                      <div className="Td__text Td__text_center"
                           style={{
                             fontSize: 19,
                           }}>
                        {(!!item.volume &&
                            new Intl.NumberFormat("ru-RU").format(
                              (item.volume / 1000).toFixed(3)
                            )) ||
                          "-"}
                      </div>
                    </Tooltip>
                  </td>
                  <td
                    className="Table__td Table__td_border"
                  >
                    <Tooltip
                      text={"Не распределено"}
                      style={{width: "100%", height: "100%"}}
                    >
                      <div className="Td__text Td__text_center"
                           style={{
                             fontSize: 19,
                             color: (!!item.volume && !!item.act_items && getColorCount(
                               (Number(item.volume - item.act_items.map(a => ((!!a.volume && a.volume) || 0)).reduce((a, b) => a + b, 0)
                               ) / 1000)))
                           }}>
                        {(!!item.volume && !!item.act_items && (new Intl.NumberFormat("ru-RU").format(
                            (Number(item.volume - item.act_items.map(a => ((!!a.volume && a.volume) || 0)).reduce((a, b) => a + b, 0)
                            ) / 1000).toFixed(3)
                          ))) ||
                          "-"}
                      </div>
                    </Tooltip>
                  </td>
                </>
              )}

            <td
              className="Table__td Table__td_border"
            >
              <Tooltip
                text={"Цена за единицу"}
                style={{width: "100%", height: "100%"}}
              >
                <div className="Td__text Td__text_center"
                     style={{
                       fontSize: 19,
                     }}>
                  {(!!item.price_per_unit &&
                      new Intl.NumberFormat("ru-RU").format(
                        (item.price_per_unit / 100).toFixed(2)
                      )) ||
                    "-"}
                </div>
              </Tooltip>
            </td>
            {!!object &&
              !object.is_without_volume && (
                <>
                  <td
                    className="Table__td Table__td_border"
                  >
                    <Tooltip
                      text={"Сумма"}
                      style={{width: "100%", height: "100%"}}
                    >
                      <div className="Td__text Td__text_center"
                           style={{
                             fontSize: 19,
                           }}>
                        {(!!item.amount &&
                            new Intl.NumberFormat("ru-RU").format(
                              (item.amount / 100).toFixed(2)
                            )) ||
                          "-"}
                      </div>
                    </Tooltip>
                  </td>
                  <td
                    className="Table__td Table__td_border"
                  >
                    <Tooltip
                      text={"Не распределено"}
                      style={{width: "100%", height: "100%"}}
                    >
                      <div className="Td__text Td__text_center"
                           style={{
                             fontSize: 19,
                             color: (!!item.amount && !!item.act_items && getColorCount(
                               (Number(item.amount - item.act_items.map(a => (((!!a.amount && a.amount) || 0) + ((!!a.amount_vat && a.amount_vat) || 0))).reduce((a, b) => a + b, 0)
                               ) / 100)))
                           }}>
                        {(!!item.amount && !!item.act_items && (new Intl.NumberFormat("ru-RU").format(
                            (Number(item.amount - item.act_items.map(a => (((!!a.amount && a.amount) || 0) + ((!!a.amount_vat && a.amount_vat) || 0))).reduce((a, b) => a + b, 0)
                            ) / 100).toFixed(2)
                          ))) ||
                          "-"}
                      </div>
                    </Tooltip>
                  </td>
                </>
              )}
          </tr>
        ))}
      </Fragment>
    )
  );
};
