import React, { useState } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../../../config/url";
import {
  getErrorMessageByKey,
  useNotification,
  Modal,
  useObject,
} from "@vokymlak/kabinet-ui";

function ModalContractsEditExecution(props) {
  const [isIntermediateExecution, setIsIntermediateExecution] = useState(false);

  const {
    object: [project],
  } = useObject({
    apiUrl: "/api/project/" + props.project.uuid,
    rootUrl: ROOT_URL,
    callbackError: () => {
      props.handleClose();
    },
  });

  const { notification } = useNotification();

  const changeAdd = (event) => {
    event.preventDefault();

    const jsonData = JSON.stringify({
      contracts: props.contracts,
      project_id: project.id,
      is_intermediate_execution: isIntermediateExecution,
    });

    axios
      .put("/api/contracts/edit/execution", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Данные сохранены", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    !!project && (
      <Modal handleClose={() => props.handleClose()}>
        <form className="Form" onSubmit={(event) => changeAdd(event)}>
          <div className="Form__title">
            Договоры
            <div className="Text__signature">Промежуточное исполнение</div>
          </div>

          <div className="Form__field Form__field_last">
            <div
              className="Button__swith"
              style={{
                padding: "8px",
                width: "100%",
                border: isIntermediateExecution
                  ? "2px solid #ffe082"
                  : "2px dashed #20202015",
                backgroundColor: isIntermediateExecution
                  ? "#ffe082"
                  : "#ffffff",
                borderRadius: 6,
                marginTop: 20,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                cursor: "pointer",
              }}
              onClick={() =>
                setIsIntermediateExecution(!isIntermediateExecution)
              }
            >
              <div
                className="Swith__text"
                style={{
                  fontSize: 17,
                  fontWeight: 600,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Промежуточное исполнение
              </div>
              <div
                className={
                  "Swith Swith_gray" +
                  ((isIntermediateExecution &&
                    " Swith_active Swith_active_gray") ||
                    "")
                }
              ></div>
            </div>
          </div>

          <div className="Form__actions Form__actions_center">
            <button className="Action__button Action__button_all Action__button_blue">
              Применить
            </button>
          </div>

          {/*<div className="Form__actions Form__actions_center">*/}
          {/*  <div className="Action__link" onClick={() => props.handleClose()}>*/}
          {/*    Отмена*/}
          {/*  </div>*/}
          {/*</div>*/}
        </form>
      </Modal>
    )
  );
}

export default ModalContractsEditExecution;
