import React, { useEffect, useState } from "react";
import { ROOT_URL } from "../../../../../../config/url";
import moment from "moment/moment";
import {
  useList,
  getAccountFio, Loading
} from "@vokymlak/kabinet-ui";

function ListTicketKpi(props) {

  const {
    list: [list, _listCount, setList],
    update: [isUpdateList, setIsUpdateList],
    params: [_params, setParams]
  } = useList({
    apiUrl: "/api/kpi/ticket/list",
    rootUrl: ROOT_URL,
    urlParams: {
      date: props.date,
      account: (!!props.account && props.account > 0 ? props.account : 0)
    }
  });

  useEffect(() => {
    setList(null)
    setParams({
      date: props.date,
      account: (!!props.account && props.account > 0 ? props.account : 0)
    })
  }, [props]);

  return (
    <>
      {!list && (
        <div className={"Page__loader"}>
          <Loading />
        </div>
      )}
      {!!list && (
        <ul className="List">
          {list.map((item, index) => (
            <React.Fragment key={"item_" + item.id}>
            <li
              key={index}
              className={"List__item"}
            >
              <div className="Item__title">
                <div className="Element__title Element__title_flex">
                  <div>
                    <div>
                      # {!!item.ticket && item.ticket.id}
                    </div>
                    <div
                      style={{
                        fontSize: 12,
                        fontWeight: 400,
                      }}
                    >
                      {!!item.note && item.note}
                    </div>
                  </div>
                </div>
              </div>
              <div className="Item__settings">
                {!!item.specification && (
                  <div
                    style={{
                      backgroundColor: "#202020",
                      height: 5,
                      width: 5,
                      borderRadius: 6,
                      margin: "0 4px",
                    }}
                  ></div>
                )}
                <span
                  style={{
                    marginLeft: 12,
                    fontSize: 21,
                    fontWeight: 700,
                    marginRight: 24
                  }}
                >
                  {item.time_spent / 100}
                </span>

                {getAccountFio(item.executor)}&nbsp;|&nbsp;
                {!!item.date &&
                  moment(item.date.substring(0, 19)).format(
                    "DD.MM.YYYY HH:mm:ss"
                  )}
              </div>
            </li>
            </React.Fragment>
          ))}
        </ul>
      )}
    </>
  );
}

export default ListTicketKpi;
