import React, { useRef, useState } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../../config/url";
import {
  getErrorMessageByKey,
  Textarea,
  useList,
  Select,
  useNotification,
  Modal,
  jsonFormatText,
  isValidateNullText,
} from "@vokymlak/kabinet-ui";

function ModalTicketAdd(props) {
  const inputTitle = useRef(null);
  const inputDescription = useRef(null);

  const inputTicketTypeTag = useRef(null);
  const inputTicketTopicUuid = useRef(null);
  const [isPriority, setIsPriority] = useState(false);

  const {
    list: [types],
  } = useList({ apiUrl: "/api/ticket-types", rootUrl: ROOT_URL });
  const {
    list: [topics],
  } = useList({ apiUrl: "/api/ticket-topics", rootUrl: ROOT_URL });

  const { notification } = useNotification();

  const changeAdd = (event) => {
    event.preventDefault();

    const title = jsonFormatText(inputTitle.current.value);
    const description = jsonFormatText(inputDescription.current.value);
    const ticketTypeTag = inputTicketTypeTag.current.value;
    const ticketTopicUuid = inputTicketTopicUuid.current.value;

    if (isValidateNullText(title)) {
      notification("Заполните все обязательные поля", "red");
      return;
    }

    const jsonData = JSON.stringify({
      title: (!!title && title.trim() )|| null,
      description: (!!description && description.trim()) || null,
      ticket_type: {
        tag: ticketTypeTag,
      },
      is_priority: isPriority,
      ticket_topic:
        ticketTopicUuid.length > 0
          ? ticketTopicUuid === "null"
            ? null
            : {
              uuid: ticketTopicUuid,
            }
          : null,
    });

    axios
      .post("/api/ticket/add", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Тикет добавлен", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const getSelectOptions = (list) => {
    const options = [];

    list.map((type) => {
      const option = {
        value: type.tag,
        title: type.name,
      };

      options.push(option);
    });

    return options;
  };

  const getSelectTopicOptions = (list) => {
    const options = [
      {
        value: "null",
        title: "Не выбрано",
      },
    ];

    list.map((type) => {
      const option = {
        value: type.uuid,
        title: type.title,
      };

      options.push(option);
    });

    return options;
  };

  return (
    <Modal handleClose={() => props.handleClose()}>
      <form className="Form" onSubmit={(event) => changeAdd(event)}>
        <div className="Form__title">
          Новый
          <div className="Text__signature">Тикет</div>
        </div>

        <div className="Form__field">
          <Select
            isFixed={true}
            reference={inputTicketTypeTag}
            options={types && getSelectOptions(types)}
            label="Тип тикета"
          />
        </div>

        <div className="Form__field">
          <Textarea reference={inputTitle} label="Заголовок *"/>
        </div>

        <div className="Form__field">
          <Textarea reference={inputDescription} label="Описание"/>
        </div>

        <div className="Form__field">
          <div
            className="Button__swith"
            style={{
              padding: "8px",
              width: "100%",
              border: isPriority ? "2px solid #ffe082" : "2px dashed #20202015",
              backgroundColor: isPriority ? "#ffe082" : "#ffffff",
              borderRadius: 6,
              marginTop: 20,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              cursor: "pointer",
            }}
            onClick={() => setIsPriority(!isPriority)}
          >
            <div
              className="Swith__text"
              style={{
                fontSize: 17,
                fontWeight: 600,
                display: "flex",
                alignItems: "center",
              }}
            >
              Приоритетная
            </div>
            <div
              className={
                "Swith Swith_gray" +
                ((isPriority && " Swith_active Swith_active_gray") || "")
              }
            ></div>
          </div>
        </div>

        <div className="Form__field Form__field_last">
          <Select
            isFixed={true}
            reference={inputTicketTopicUuid}
            options={topics && getSelectTopicOptions(topics)}
            label="Тема"
          />
        </div>

        <div className="Form__actions Form__actions_center">
          <button className="Action__button Action__button_all Action__button_blue">
            Добавить
          </button>
        </div>

        <div className="Form__actions Form__actions_center">
          <div className="Action__link" onClick={() => props.handleClose()}>
            Отмена
          </div>
        </div>
      </form>
    </Modal>
  );
}

export default ModalTicketAdd;
