import React, { useRef, useState } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../../config/url";
import {
  getErrorMessageByKey,
  Textarea,
  useList,
  Select,
  useNotification,
  Modal,
  useObject,
  jsonFormatText,
  isValidateNullText,
} from "@vokymlak/kabinet-ui";

function ModalTicketEdit(props) {
  const [isPriority, setIsPriority] = useState(false);

  const {
    object: [object],
  } = useObject({
    apiUrl: "/api/ticket/" + props.object.uuid,
    rootUrl: ROOT_URL,
    callbackResponse: (data) => {
      setIsPriority(!!data && data.is_priority);
    },
    callbackError: () => {
      props.handleClose();
    },
  });

  const {
    list: [types],
  } = useList({ apiUrl: "/api/ticket-types", rootUrl: ROOT_URL });
  const {
    list: [topics],
  } = useList({ apiUrl: "/api/ticket-topics", rootUrl: ROOT_URL });

  const inputTitle = useRef(null);
  const inputDescription = useRef(null);

  const inputTicketTypeTag = useRef(null);
  const inputTicketTopicUuid = useRef(null);

  const [isDelete, setIsDelete] = useState(false);

  const { notification } = useNotification();

  const changeEdit = (event) => {
    event.preventDefault();

    const title = jsonFormatText(inputTitle.current.value);
    const description = jsonFormatText(inputDescription.current.value);
    const ticketTypeTag = inputTicketTypeTag.current.value;
    const ticketTopicUuid = inputTicketTopicUuid.current.value;

    if (isValidateNullText(title)) {
      notification("Заполните все обязательные поля", "red");
      return;
    }

    const jsonData = JSON.stringify({
      title: (!!title && title.trim() )|| null,
      description: (!!description && description.trim()) || null,
      ticket_type: {
        tag: ticketTypeTag,
      },
      is_priority: isPriority,
      ticket_topic:
        ticketTopicUuid.length > 0
          ? ticketTopicUuid === "null"
            ? null
            : {
              uuid: ticketTopicUuid,
            }
          : null,
    });

    axios
      .put("/api/ticket/" + props.object.uuid + "/edit", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Данные сохранены", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const changeDelete = (event) => {
    event.preventDefault();

    axios
      .delete("/api/ticket/" + props.object.uuid + "/delete", {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Тикет удалён", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const getSelectOptions = (list) => {
    const options = [];

    list.map((type) => {
      const option = {
        value: type.tag,
        title: type.name,
      };

      options.push(option);
    });

    return options;
  };

  const getSelectTopicOptions = (list) => {
    const options = [
      {
        value: "null",
        title: "Не выбрано",
      },
    ];

    list.map((type) => {
      const option = {
        value: type.uuid,
        title: type.title,
      };

      options.push(option);
    });

    return options;
  };


  return (
    !!object &&
    !!types &&
    !!topics && (
      <Modal handleClose={() => props.handleClose()}>
        {!isDelete && (
          <form className="Form" onSubmit={(event) => changeEdit(event)}>
            <div
              className="Form__title"
              style={{fontSize: 19, fontWeight: 700}}
            >
              {object.title}
              <div className="Text__signature">Тикет</div>
            </div>

            <div className="Form__field">
              <Select
                isFixed={true}
                reference={inputTicketTypeTag}
                options={types && getSelectOptions(types)}
                value={!!object.ticket_type ? object.ticket_type.tag : null}
                label="Тип тикета"
              />
            </div>

            <div className="Form__field">
              <Textarea
                reference={inputTitle}
                label="Заголовок *"
                value={object.title}
              />
            </div>

            <div className="Form__field">
              <Textarea
                reference={inputDescription}
                label="Описание"
                value={object.description}
              />
            </div>

            <div className="Form__field">
              <div
                className="Button__swith"
                style={{
                  padding: "8px",
                  width: "100%",
                  border: isPriority
                    ? "2px solid #ffe082"
                    : "2px dashed #20202015",
                  backgroundColor: isPriority ? "#ffe082" : "#ffffff",
                  borderRadius: 6,
                  marginTop: 20,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  cursor: "pointer",
                }}
                onClick={() => setIsPriority(!isPriority)}
              >
                <div
                  className="Swith__text"
                  style={{
                    fontSize: 17,
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Приоритетная
                </div>
                <div
                  className={
                    "Swith Swith_gray" +
                    ((isPriority && " Swith_active Swith_active_gray") || "")
                  }
                ></div>
              </div>
            </div>

            <div className="Form__field Form__field_last">
              <Select
                isFixed={true}
                reference={inputTicketTopicUuid}
                options={topics && getSelectTopicOptions(topics)}
                value={!!object.ticket_topic ? object.ticket_topic.uuid : null}
                label="Тема"
              />
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_blue">
                Применить
              </button>
            </div>

            <div className="Form__actions">
              <div
                className="Action__link Action__link_red"
                onClick={() => setIsDelete(true)}
              >
                Удалить
              </div>
              <div className="Action__link" onClick={() => props.handleClose()}>
                Отмена
              </div>
            </div>
          </form>
        )}
        {isDelete && (
          <form className="Form" onSubmit={(event) => changeDelete(event)}>
            <div
              className="Form__title"
              style={{fontSize: 19, fontWeight: 700}}
            >
              {object.title}
              <div className="Text__signature">Удалить</div>
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_red">
                Продолжить
              </button>
            </div>

            <div className="Form__actions Form__actions_center">
              <div className="Action__link" onClick={() => setIsDelete(false)}>
                Отмена
              </div>
            </div>
          </form>
        )}
      </Modal>
    )
  );
}

export default ModalTicketEdit;
