import React, { useState, useRef } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../config/url";
import {
  getErrorMessageByKey,
  useNotification,
  Modal,
  Input,
  useList,
  Select,
  useObject,
  jsonFormatText,
  isValidateNullText,
  getAccountFio,
} from "@vokymlak/kabinet-ui";
import {ROLE_DIRECTOR, ROLE_EMPLOYEE} from "../../../config/constants";

function ModalProjectClientAdd(props) {
  const {
    list: [listClients],
  } = useList({
    apiUrl: "/api/clients/list",
    rootUrl: ROOT_URL,
  });

  const {
    object: [project],
  } = useObject({
    apiUrl: "/api/project/" + props.project.uuid,
    rootUrl: ROOT_URL,
    callbackError: () => {
      props.handleClose();
    },
  });

  const selectClientId = useRef(null);

  const { notification } = useNotification();

  const changeEdit = (event) => {
    event.preventDefault();

    const clientId = selectClientId.current.value;

    if (isValidateNullText(clientId) || clientId === "null") {
      notification("Выберите клиента", "red");
      return;
    }

    const jsonData = JSON.stringify({
      client_id:
        clientId.length > 0
          ? clientId === "null"
            ? null
            : Number(clientId)
          : null,
    });

    axios
      .post("/api/project/" + props.project.uuid + "/client/add", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Данные сохранены", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const getAccountsSelectOptions = (list) => {
    const options = [
      {
        value: "null",
        title: "Не выбрано",
      },
    ];

    list.map((item) => {
      const option = {
        value: item.account.id,
        title: getAccountFio(item.account),
      };

      options.push(option);
    });

    return options;
  };

  return (
    !!project &&
    !!listClients && (
      <Modal handleClose={() => props.handleClose()}>
          <form className="Form" onSubmit={(event) => changeEdit(event)}>
            <div className="Form__title">
              {project.name}
              <div className="Text__signature">Проект</div>
            </div>

            <div className="Form__field Form__field_last">
              <Select
                isFixed={true}
                reference={selectClientId}
                options={
                  !!listClients
                    ? getAccountsSelectOptions(listClients)
                    : getAccountsSelectOptions([])
                }
                label="Клиент"
              />
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_blue">
                Добавить
              </button>
            </div>
          </form>
      </Modal>
    )
  );
}

export default ModalProjectClientAdd;
