import React, {useState} from "react";
import {Route, Routes, Navigate} from "react-router-dom";
import "./Appeals.scss";
import { AppealsMenu } from "./AppealsMenu/AppealsMenu";
import {AppealsType} from "./AppealsType";
import {AppealsMenuTree} from "./AppealsMenuTree/AppealsMenuTree";
import {useProfile} from "@vokymlak/kabinet-ui";

export const AppealsRouting = () => {
  const {profile} = useProfile();

  const [ object, setObject ] = useState(null);
  const [ selectEmployee, setSelectEmployee ] = useState(profile);
  const [ selectCompany, setSelectCompany ] = useState(null);
  const [ selectTab, setSelectTab ] = useState('my');
  const [ updateMenu, setUpdateMenu ] = useState(false);

  return (
    <div className="Appeals">
      <div className="Block">
        <div className="App App_vertical">
          <div className="Navigate" style={{
            width: 320,
          }}>
            <AppealsMenuTree setSelectEmployee={setSelectEmployee} setSelectCompany={setSelectCompany} setSelectTab={setSelectTab} />
          </div>

          <div className="Content" style={{
            boxShadow: "-8px 0 24px rgba(0,0,0, 0.05)"
          }}>
            <div className="Appeals">
              <div className="Block">
                <div className="App App_vertical">
                  <div className="Navigate" style={{
                    width: 320,
                  }}>
                    <AppealsMenu selectEmployee={selectEmployee} selectCompany={selectCompany} selectTab={selectTab} setObject={setObject}
                                 updateMenu={updateMenu}/>
                  </div>

                  <div className="Content" style={{
                    boxShadow: "-8px 0 24px rgba(0,0,0, 0.05)"
                  }}>
                    <Routes>
                      <Route
                        path="/:appealType"
                        element={<AppealsType selectEmployee={selectEmployee} selectCompany={selectCompany} selectTab={selectTab} object={object}
                                              setUpdateMenu={setUpdateMenu}/>}
                      />

                      <Route
                        exact
                        path="/"
                        element={<Navigate to="./all" replace/>}
                      />
                    </Routes>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
