import React, {useRef, useState} from "react";
import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";
import styleBlock from "../../../styles/modules/Block.module.scss";

function CalculatorView(props) {

  const [sumStr, setSumStr] = useState(0);
  const [sumVoz, setSumVoz] = useState(0);
  const [ndfl, setNdfl] = useState(0);
  const [handSum, setHandSum] = useState(0);
  const [check, setCheck] = useState(0);
  const [ifns, setIfns] = useState(0);
  const [oneMoreCheck, setOneMoreCheck] = useState(0);
  const [toNull, setToNull] = useState(0);
  const [finalCheck, setFinalCheck] = useState(0)

  const inputSum = useRef(0);

  const handleSum = () => {
    const nums = Number(inputSum.current.value)

    const str = nums * 30 / 130;
    setSumStr(str)

    const voz = nums - str;
    setSumVoz(voz)

    const ndfl = voz * 0.13;
    setNdfl(ndfl)

    const handSum = voz - ndfl;
    setHandSum(handSum)

    const Check = str + ndfl + handSum;
    setCheck(Check)

    const ifns = ndfl + str;
    setIfns(ifns)

    const oneMoreCheck = ifns + handSum;
    setOneMoreCheck(oneMoreCheck)

    const toNull = nums - Check;
    setToNull(toNull)

    const finalCheck = nums - oneMoreCheck;
    setFinalCheck(finalCheck)
  }


    return (
      <ScrollBlock style={{width: "100%", height: "100%"}}>
        <div className="Page">
          <div className="Page__title">
            <div className="Title__text">
              <div
                className="Title__text"
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  fontSize: 19,
                  fontWeight: 700,
                }}
              >
                <div>
                  Калькуляторы
                </div>
              </div>
            </div>

            <div className="Title__settings">
            </div>
          </div>

          <hr className={"Page__hr"} style={{marginBottom: 6}}/>

          <div
            className="Page__content"
            style={{
              position: "relative",
            }}
          >
            <div
              style={{
                overflow: "auto",
                width: "100%",
                height: "100%",
              }}
            >

              <div
                className={
                  styleBlock._block + " " + styleBlock._block_bottom
                }
              >
                <div className={styleBlock._block__title}>
                  Калькулятор ГПХ
                </div>
                <div className={styleBlock._block__content}>
                  <table className="Table" style={{
                    width: 520
                  }}>
                    <thead className="Thead">
                    <tr className="Table__tr Table__tr_padding Table__tr_border">
                      <th
                        className="Table__th Table__th_border"
                      >
                        <div className="Th__title" style={{ height: 16 }}>
                          Описание
                        </div>
                      </th>
                      <th
                        className="Table__th Table__th_border"
                      >
                        <div className="Th__title" style={{ height: 16 }}>
                          Значение
                        </div>
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                      className={
                        "Table__tr Table__tr_padding Table__tr_border"
                      }
                    >
                      <td
                        className="Table__td Table__td_border"
                        style={{
                          backgroundColor: "#c5e1a5",
                        }}
                      >
                        <div
                          className="Td__text"
                        >
                          Сумма договора
                        </div>
                      </td>
                      <td
                        className="Table__td Table__td_border"
                        style={{
                          backgroundColor: "#c5e1a5",
                        }}
                      >
                        <div
                          className="Td__text Td__text_center"
                          style={{
                            padding: 0
                          }}
                        >
                          <input type='number' ref={inputSum} onChange={handleSum}  placeholder="Сумма договора"

                                 style={{
                                   width: "100%",
                                   fontSize: 17,
                                   padding: "8px 10px 6px",
                                   textAlign: "left",
                                   border: "1px solid #5ea111",
                                   boxShadow: "inset 2px 2px 3px rgba(0,0,0,.17)",
                                   outline: "none!important",
                                   backgroundColor: "#f1f1f1"
                                 }}/>
                        </div>
                      </td>
                    </tr>

                    <tr
                      className={
                        "Table__tr Table__tr_padding Table__tr_border"
                      }
                    >
                      <td
                        className="Table__td Table__td_border"
                      >
                        <div
                          className="Td__text "
                        >
                          Сумма страховых взносов
                        </div>
                      </td>
                      <td
                        className="Table__td Table__td_border"
                      >
                        <div
                          className="Td__text"
                          style={{
                            fontSize: 19,
                            paddingLeft: 10
                          }}
                        >
                          {sumStr.toFixed(2)}
                        </div>
                      </td>
                    </tr>

                    <tr
                      className={
                        "Table__tr Table__tr_padding Table__tr_border"
                      }
                    >
                      <td
                        className="Table__td Table__td_border"
                      >
                        <div
                          className="Td__text "
                        >
                          Сумма вознаграждения с НДФЛ
                        </div>
                      </td>
                      <td
                        className="Table__td Table__td_border"
                      >
                        <div
                          className="Td__text"
                          style={{
                            fontSize: 19,
                            paddingLeft: 10
                          }}
                        >
                          {sumVoz.toFixed(2)}
                        </div>
                      </td>
                    </tr>

                    <tr
                      className={
                        "Table__tr Table__tr_padding Table__tr_border"
                      }
                    >
                      <td
                        className="Table__td Table__td_border"
                      >
                        <div
                          className="Td__text"
                        >
                          НДФЛ
                        </div>
                      </td>
                      <td
                        className="Table__td Table__td_border"
                      >
                        <div
                          className="Td__text"
                          style={{
                            fontSize: 19,
                            paddingLeft: 10
                          }}
                        >
                          {ndfl.toFixed(2)}
                        </div>
                      </td>
                    </tr>

                    <tr
                      className={
                        "Table__tr Table__tr_padding Table__tr_border"
                      }
                    >
                      <td
                        className="Table__td Table__td_border"
                      >
                        <div
                          className="Td__text"
                        >
                          Сумма на руки
                        </div>
                      </td>
                      <td
                        className="Table__td Table__td_border"
                      >
                        <div
                          className="Td__text"
                          style={{
                            fontSize: 19,
                            paddingLeft: 10
                          }}
                        >
                          {handSum.toFixed(2)}
                        </div>
                      </td>
                    </tr>

                    <tr
                      className={
                        "Table__tr Table__tr_padding Table__tr_border"
                      }
                    >
                      <td
                        className="Table__td Table__td_border"
                        style={{
                          backgroundColor: "#ff8a6550",
                        }}
                      >
                        <div
                          className="Td__text"
                        >
                          ПРОВЕРКА
                        </div>
                      </td>
                      <td
                        className="Table__td Table__td_border"
                        style={{
                          backgroundColor: "#ff8a6550",
                        }}
                      >
                        <div
                          className="Td__text"
                          style={{
                            fontSize: 19,
                            paddingLeft: 10
                          }}
                        >
                          {check.toFixed(2)} | {toNull.toFixed(2)}
                        </div>
                      </td>
                    </tr>

                    <tr
                      className={
                        "Table__tr Table__tr_padding Table__tr_border"
                      }
                    >
                      <td
                        className="Table__td Table__td_border"
                      >
                        <div
                          className="Td__text"
                        >
                          Сумма на руки в РИС
                        </div>
                      </td>
                      <td
                        className="Table__td Table__td_border"
                      >
                        <div
                          className="Td__text"
                          style={{
                            fontSize: 19,
                            paddingLeft: 10
                          }}
                        >
                          {handSum.toFixed(2)}
                        </div>
                      </td>
                    </tr>

                    <tr
                      className={
                        "Table__tr Table__tr_padding Table__tr_border"
                      }
                    >
                      <td
                        className="Table__td Table__td_border"
                      >
                        <div
                          className="Td__text"
                        >
                          Сумма ИФНС в РИС
                        </div>
                      </td>
                      <td
                        className="Table__td Table__td_border"
                      >
                        <div
                          className="Td__text"
                          style={{
                            fontSize: 19,
                            paddingLeft: 10
                          }}
                        >
                          {ifns.toFixed(2)}
                        </div>
                      </td>
                    </tr>

                    <tr
                      className={
                        "Table__tr Table__tr_padding Table__tr_border"
                      }
                    >
                      <td
                        className="Table__td Table__td_border"
                        style={{
                          backgroundColor: "#ff8a6550",
                        }}
                      >
                        <div
                          className="Td__text"
                        >
                          ПРОВЕРКА
                        </div>
                      </td>
                      <td
                        className="Table__td Table__td_border"
                        style={{
                          backgroundColor: "#ff8a6550",
                        }}
                      >
                        <div
                          className="Td__text"
                          style={{
                            fontSize: 19,
                            paddingLeft: 10
                          }}
                        >
                          {oneMoreCheck.toFixed(2)} | {finalCheck.toFixed(2)}
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

        </div>
      </ScrollBlock>
    );
  }

  export default CalculatorView;
