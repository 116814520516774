import React, { Fragment } from "react";
import "./HandbookArticle.scss";
import {Loading} from "@vokymlak/kabinet-ui";
import { ScrollBlock } from "../../../components/ScrollBlock/ScrollBlock";
import moment from "moment";
import styleArticle from "./Article.module.scss";

export const HandbookArticlePage = (props) => {
  const {
    object,

  } = props;

  return (
    <ScrollBlock style={{ width: "100%", height: "100%" }}>
      <div className="HandbookPage"  style={{
        backgroundColor: "white",
        height: "auto",
        padding: "56px 64px 128px"
      }}>
        {!!object && (
          <Fragment>
            <div
              className="Page__title"
              style={{
                width: 796.661608,
                marginBottom: 36
              }}
            >
              <div
                className="Title__text"
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  fontSize: 32,
                  fontWeight: 400,
                  opacity: 1,
                }}
              >
                <span>
                {object.handbook_entry.title}
                </span>
                <span
                  style={{
                    fontSize: 13,
                    opacity: .75,
                    fontWeight: 400,
                    marginTop: 4
                  }}
                >
                  <span>
                    {!!object.updated_at
                      ? 'Обновлено: ' + moment(object.updated_at.substring(0, 19)).format("DD.MM.YYYY")
                      : 'Добавлено: ' + moment(object.handbook_entry.created_at.substring(0, 19)).format("DD.MM.YYYY")}
                    </span>
                        </span>
              </div>

              <div className="Title__settings">
              </div>
            </div>

            <div
              className="Page__content"
              style={{
                height: "100%",
              }}
            >
              <div
                className={styleArticle.article}
                style={{
                  width: 796.661608,
                }}
                dangerouslySetInnerHTML={{
                  __html:
                    !object.article ||
                    (!!object.article &&
                      (object.article === "<p></p>" ||
                        object.article.length === 0))
                      ? "<p><br></p>"
                      : object.article,
                }}
              ></div>
            </div>
          </Fragment>
        )}

        {!object && (
          <div className={"Page__loader"}>
            <Loading />
          </div>
        )}
      </div>
    </ScrollBlock>
  );
};
