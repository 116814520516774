import React from "react";
import { ROOT_URL } from "../../../config/url";
import { useAction, useObject } from "@vokymlak/kabinet-ui";
import { useParams } from "react-router-dom";

function useProject() {
  let { projectUuid } = useParams();

  const {
    object: [object],
    update: [_isUpdateObject, _setIsUpdateObject, updateObject],
  } = useObject({apiUrl:"/api/client/project/" +projectUuid, rootUrl:ROOT_URL});

  useAction([projectUuid, "tasks"], () =>
    updateObject()
  );

  return {
    object,
  };
}

export default useProject;
