import React, { useRef } from "react";
import Modal from "../../../components/Modal/Modal";
import axios from "axios";
import { ROOT_URL } from "../../../config/url";
import { Input } from "../../../components/Input";
import useList from "../../../hooks/useList";
import {getErrorMessageByKey, useNotification, Select} from "@vokymlak/kabinet-ui";

function ModalPurchaseBaseAdd(props) {
  const {
    list: [listLaws],
  } = useList("/api/laws", ROOT_URL, false);

  const inputName = useRef(null);
  const selectLawTag = useRef(null);

  const { notification } = useNotification();

  const changeAdd = (event) => {
    event.preventDefault();

    const name = inputName.current.value;
    const lawTag = selectLawTag.current.value;

    if (!name || name.length === 0) {
      return;
    }

    const jsonData = JSON.stringify({
      name: name,
      law: {
        tag: lawTag.length > 0 ? (lawTag === "null" ? null : lawTag) : null,
      },
    });

    axios
      .post("/api/purchase-base/add", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });

    props.handleClose();
  };

  const getLawsSelectOptions = (list) => {
    const options = [];

    list.map((item) => {
      const option = {
        value: item.tag,
        title: item.name,
      };

      options.push(option);
    });

    return options;
  };

  return (
    <Modal handleClose={() => props.handleClose}>
      <form className="Form" onSubmit={(event) => changeAdd(event)}>
        <div className="Form__title">Новый</div>

        <div className="Form__field">
          <Input reference={inputName} type="text" label="Наименование *" />
        </div>

        <div className="Form__field Form__field_last">
          <Select
            isFixed={true}
            reference={selectLawTag}
            options={
              !!listLaws
                ? getLawsSelectOptions(listLaws)
                : getLawsSelectOptions([])
            }
            label="Закон *"
          />
        </div>

        <div className="Form__actions Form__actions_center">
          <button className="Action__button Action__button_all Action__button_blue">
            Добавить
          </button>
        </div>

        <div className="Form__actions Form__actions_center">
          <div className="Action__link" onClick={() => props.handleClose()}>
            Отмена
          </div>
        </div>
      </form>
    </Modal>
  );
}

export default ModalPurchaseBaseAdd;
