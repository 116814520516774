import React, {useEffect, useState} from "react";
import { ROOT_URL } from "../../../config/url";
import useList from "../../../hooks/useList";
import {useAction, useUnroll} from "@vokymlak/kabinet-ui";
import axios from "axios";
import {useNotification, getErrorMessageByKey} from "@vokymlak/kabinet-ui";

function useContractGroups() {
  const {
    list: [list, setList],
    update: [isUpdateList, setIsUpdateList],
  } = useList("/api/contract-groups", ROOT_URL);

  const { unrolls, isUnroll, changeUnroll, changeAllUnroll } = useUnroll();

  const [contractStatuses, setContractStatuses] = useState([]);
  const [purchaseBasis, setPurchaseBasis] = useState([]);

  const [isModalObjectAdd, setIsModalObjectAdd] = useState(false);
  const [modalItemEdit, setModalItemEdit] = useState(null);

  const [modalObjectItemAdd, setModalObjectItemAdd] = useState(null);
  const [modalObjectItem2Add, setModalObjectItem2Add] = useState(null);
  const [modalObjectItem3Add, setModalObjectItem3Add] = useState(null);

  useAction("isUpdate", () => setIsUpdateList(!isUpdateList));

  const { notification } = useNotification();

  useEffect(() => {
    if (!!list) {
      const contractStatuses = []
      const purchaseBasis = []
      list.map(item => {
        if (!!item.anchors) {
          item.anchors.map(anchor => {
            if (!!anchor.contract_status) {
              contractStatuses.push(anchor)
            }
            if (!!anchor.purchase_base) {
              purchaseBasis.push(anchor)
            }
          })
          }
      })
      setContractStatuses(contractStatuses)
      setPurchaseBasis(purchaseBasis)
    } else {
      setContractStatuses([])
      setPurchaseBasis([])
    }
  }, [list])

  const deleteItem = (itemUuid) => {
    axios
      .delete("/api/contract-group-contract-status/" + itemUuid + "/delete", {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        setIsUpdateList(!isUpdateList);
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const deleteItem2 = (itemUuid) => {
    axios
      .delete("/api/contract-group-purchase-base/" + itemUuid + "/delete", {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        setIsUpdateList(!isUpdateList);
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const deleteItem3 = (itemUuid) => {
    axios
      .delete("/api/contract-group-purchase-way/" + itemUuid + "/delete", {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        setIsUpdateList(!isUpdateList);
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return {
    list,
    setList,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    setIsUpdateList,
    isUpdateList,

    modalItemEdit,
    setModalItemEdit,
    isModalObjectAdd,
    setIsModalObjectAdd,
    modalObjectItemAdd,
    setModalObjectItemAdd,
    modalObjectItem2Add,
    setModalObjectItem2Add,
    modalObjectItem3Add,
    setModalObjectItem3Add,

    contractStatuses,
    purchaseBasis,

    deleteItem,
    deleteItem2,
    deleteItem3,
  };
}

export default useContractGroups;
