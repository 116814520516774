import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../config/url";
import moment from "moment";
import {
  getErrorMessageByKey,
  Textarea,
  useList,
  Select,
  useNotification,
  Modal,
  Input,
  useObject,
  jsonFormatText,
  isValidateNullText,
} from "@vokymlak/kabinet-ui";

function ModalAppealProcessing(props) {
  const {
    object: [object],
  } = useObject({
    apiUrl: "/api/appeal/" + props.object.uuid,
    rootUrl: ROOT_URL,
    callbackError: () => {
      props.handleClose();
    },
  });

  const inputDateEnd = useRef(null);

  const { notification } = useNotification();

  const changeEdit = (event) => {
    event.preventDefault();

    const dateEnd = inputDateEnd.current.value;


    const jsonData = JSON.stringify({
      date_end: !!dateEnd && dateEnd.length > 0 ? dateEnd : null,
    });

    axios
      .put("/api/appeal/" + props.object.uuid + "/processing", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Взят в работу", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    !!object && (
      <Modal handleClose={() => props.handleClose()}>
          <form className="Form" onSubmit={(event) => changeEdit(event)}>
            <div
              className="Form__title"
              style={{ fontSize: 19, fontWeight: 700 }}
            >
              Обращение
              <div className="Text__signature">В работу</div>
            </div>

            <div className="Form__field Form__field_last">
              <Input
                reference={inputDateEnd}
                type="date"
                isFixed={true}
                label="Дата окончания"
                value={
                  !!object.date_end
                    ? moment(object.date_end).format("YYYY-MM-DD")
                    : null
                }
              />
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_blue">
                В работу
              </button>
            </div>
          </form>
      </Modal>
    )
  );
}

export default ModalAppealProcessing;
