import React, { useRef, useState } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../../../../../config/url";
import {
  getErrorMessageByKey,
  useNotification,
  Icon,
  useProfile,
  Textarea,
  Select,
  useList,
  Input,
  Modal,
  jsonFormatText,
  isValidateNullText,
  useObject,
  getAccountFio,
} from "@vokymlak/kabinet-ui";
import {ROLE_DIRECTOR, ROLE_EMPLOYEE} from "../../../../../../../config/constants";

function ModalProjectTaskAdd(props) {
  const { profile } = useProfile();

  const {
    list: [list],
  } = useList({
    apiUrl: "/api/employees/list",
    rootUrl: ROOT_URL,
    urlParams: {
      roles: [ROLE_DIRECTOR, ROLE_EMPLOYEE],
    }
  });

  const {
    object: [project],
  } = useObject({
    apiUrl: "/api/project/" + props.project.uuid,
    rootUrl: ROOT_URL,
    callbackError: () => {
      props.handleClose();
    },
  });

  const [isPriority, setIsPriority] = useState(false);

  const inputName = useRef(null);
  const inputNote = useRef(null);
  const inputDateControl = useRef(null);
  const selectWhomId = useRef(null);

  const { notification } = useNotification();

  const changeAdd = (event) => {
    event.preventDefault();

    const name = jsonFormatText(inputName.current.value);
    const note = jsonFormatText(inputNote.current.value);
    const dateControl = inputDateControl.current.value;
    const whomId = selectWhomId.current.value;

    if (isValidateNullText(name) || !dateControl || dateControl.length === 0) {
      notification("Заполните все обязательные поля", "red");
      return;
    }

    const jsonData = JSON.stringify({
      name: name,
      note: note,
      date_control:
        !!dateControl && dateControl.length > 0 ? dateControl : null,
      whom_id:
        whomId.length > 0 ? (whomId === "null" ? null : Number(whomId)) : null,
      is_priority: isPriority,
    });

    axios
      .post("/api/project/" + props.project.uuid + "/finance-client-task/add", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Задача клиенту добавлена", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const getSelectOptions = (list) => {
    const options = [
      {
        value: "null",
        title: "Не выбрано",
      },
    ];

    list.map((item) => {
      const option = {
        value: item.account.id,
        title: getAccountFio(item.account),
      };

      options.push(option);
    });

    return options;
  };

  const isProfileInList = (list, profileId) => {
    let isProfile = false;
    if (!!list) {
      list.map((item) => {
        if (item.account.id === profileId) {
          isProfile = true;
        }
      });
    }
    return isProfile;
  };

  return (
    !!project && (
      <Modal handleClose={() => props.handleClose()}>
        <form className="Form" onSubmit={(event) => changeAdd(event)}>
          <div className="Form__title">
            Новая
            <div className="Text__signature">Задача клиенту</div>
          </div>

          <div className="Form__field">
            <Textarea reference={inputName} label="Наименование *" />
          </div>

          <div className="Form__field">
            <Textarea reference={inputNote} label="Примечание" />
          </div>

          <div className="Form__field">
            <Input
              reference={inputDateControl}
              type="date"
              isFixed={true}
              label="Дата исполнения *"
            />
          </div>

          <div className="Form__field">
            <Select
              isFixed={true}
              reference={selectWhomId}
              options={!!list ? getSelectOptions(list) : getSelectOptions([])}
              label="Исполнитель"
              value={isProfileInList(list, profile.id) ? profile.id : "null"}
            />
          </div>

          <div className="Form__field Form__field_last">
            <div
              className="Button__swith"
              style={{
                padding: "8px",
                width: "100%",
                border: isPriority
                  ? "2px solid #ffe082"
                  : "2px dashed #20202015",
                backgroundColor: isPriority ? "#ffe082" : "#ffffff",
                borderRadius: 6,
                marginTop: 20,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                cursor: "pointer",
              }}
              onClick={() => setIsPriority(!isPriority)}
            >
              <div
                className="Swith__text"
                style={{
                  fontSize: 17,
                  fontWeight: 600,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span style={{ marginRight: 8 }}>
                  <Icon
                    name={"custom-priority"}
                    size={22}
                    color={isPriority ? "#202020" : "#bdbdbd"}
                  />
                </span>
                Высокий приоритет
              </div>
              <div
                className={
                  "Swith Swith_gray" +
                  ((isPriority && " Swith_active Swith_active_gray") || "")
                }
              ></div>
            </div>
          </div>

          <div className="Form__actions Form__actions_center">
            <button className="Action__button Action__button_all Action__button_blue">
              Добавить
            </button>
          </div>

          {/*<div className="Form__actions Form__actions_center">*/}
          {/*  <div className="Action__link" onClick={() => props.handleClose()}>*/}
          {/*    Отмена*/}
          {/*  </div>*/}
          {/*</div>*/}
        </form>
      </Modal>
    )
  );
}

export default ModalProjectTaskAdd;
