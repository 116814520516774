import React, { useRef } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../../config/url";
import {
  getErrorMessageByKey,
  useNotification,
  useObject,
  useList,
  Select,
  Modal,
} from "@vokymlak/kabinet-ui";

function ModalFileInFolder(props) {
  const {
    object: [object],
  } = useObject({
    apiUrl: "/api/catalog-entry/" + props.object.uuid,
    rootUrl: ROOT_URL,
    callbackError: () => {
      props.handleClose();
    },
  });

  const {
    list: [list],
  } = useList({ apiUrl: "/api/catalog-entries", rootUrl: ROOT_URL });

  const selectArticleGroupUuid = useRef(null);

  const { notification } = useNotification();

  const changeEdit = (event) => {
    event.preventDefault();

    const folderUuid = selectArticleGroupUuid.current.value;

    axios
      .get("/api/catalog-entry/" + props.object.uuid + "/in-folder/" + folderUuid, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Данные сохранены", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const getSelectOptions = (list) => {
    const options = [
      {
        value: "null",
        title: "Не выбрано",
      },
    ];

    list.map((item) => {
      if (item.catalog_entry_type.tag === "FOLDER") {
        const option = {
          value: item.uuid,
          title: item.title,
        };

        options.push(option);
      }
    });

    return options;
  };

  return (
    !!object &&
    !!list && (
      <Modal handleClose={() => props.handleClose()}>
          <form className="Form" onSubmit={(event) => changeEdit(event)}>
            <div className="Form__title">
              {object.title}
              <div className="Text__signature">Перенести в папку</div>
            </div>

            <div className="Form__field Form__field_last">
              <Select
                reference={selectArticleGroupUuid}
                isFixed={true}
                options={!!list ? getSelectOptions(list) : getSelectOptions([])}
                label="Папка"
              />
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_blue">
                Перенести
              </button>
            </div>
          </form>
      </Modal>
    )
  );
}

export default ModalFileInFolder;
