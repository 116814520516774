import React, {useState} from "react";
import axios from "axios";
import {ROOT_URL} from "../../../../../config/url";
import {
  getErrorMessageByKey,
  isProfileRoleTypeTags,
  Loading,
  Modal,
  useList,
  useNotification,
  useProfile,
} from "@vokymlak/kabinet-ui";
import styleCard from "../../../../../styles/modules/Card.module.scss";
import {ROLE_ADMINISTRATOR} from "../../../../../config/constants";

function ModalProjectPfhdImport(props) {
  const {profile} = useProfile();

  const [isRequest, setIsRequest] = useState(false);

  const {notification} = useNotification();

  const {
    list: [list],
    update: [_isUpdateList, _setIsUpdateList, updateList],
  } = useList({
    apiUrl: "/api/company/" + props.project.company.uuid + "/authorize",
    rootUrl: ROOT_URL,
  });

  const changeLoginImport = (uuid) => {
    setIsRequest(true)

    axios
      .get(
        "/api/project/" + props.project.uuid + "/pfhd/import/authorize/" + uuid,
        {
          withCredentials: false,
          baseURL: ROOT_URL,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("tokenSession"),
          }
        })
      .then(() => {
        setIsRequest(false)
        props.successfulRequest();
        notification("Импорт завершён", "green");
        props.handleClose();
      })
      .catch((error) => {
        setIsRequest(false)
        if (error.response.data.error === "Status unauthorized") {
          notification("Авторизация не пройдена", "red");
        } else {
          notification(getErrorMessageByKey(error.response.data.error), "red");
        }
      });
  };

  const changeLoginPushImport = (uuid) => {
    setIsRequest(true)

    axios
      .get(
        "/api/project/" + props.project.uuid + "/pfhd/import/authorize/" + uuid + "/push",
        {
          withCredentials: false,
          baseURL: ROOT_URL,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("tokenSession"),
          }
        })
      .then(() => {
        setIsRequest(false)
        props.successfulRequest();
        notification("Импорт в очереди", "green");
        props.handleClose();
      })
      .catch((error) => {
        setIsRequest(false)
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const filterRis = (list) => {
    if (!!list) {
      return list.filter(i => !!i.authorize && !!i.authorize.authorize_type && i.authorize.authorize_type.tag === "RIS")
    }
    return []
  }

  return (
    <Modal handleClose={() => props.handleClose()}>
      <div className="Form">
        <div className="Form__title">
          Импорт
          <div className="Text__signature">ПФХД</div>
        </div>

        {!!isRequest && <div className="Form__field"><Loading size="medium" color="blue"/></div>}

        {!isRequest && (
          <>

            {isProfileRoleTypeTags(profile, [
              ROLE_ADMINISTRATOR,
            ]) && (
              <>
                <div className="Form__field">
                  <div className="Field__label">
                    Принудительно
                  </div>
                </div>

                {!!list && filterRis(list).map(item => (
                  <div className="Form__field" key={"authorize_" + item.uuid}>
                    <button className="Action__button Action__button_all Action__button_blue"
                            onClick={() => changeLoginImport(item.uuid)}
                            style={{
                              position: "relative"
                            }}>

                      <div
                        className={styleCard._block__number}
                        style={{
                          fontSize: 17,
                        }}
                      >
                        {item.authorize.name}
                      </div>
                      <div className={styleCard._block__title}>
                        {item.authorize.login}
                      </div>

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          position: "absolute",
                          top: -8,
                          left: 0,
                          justifyContent: " flex-end",
                          width: "100%",
                          padding: "0 8px",
                        }}
                      >
                        {!!item.authorize.authorize_type &&
                          item.authorize.authorize_type.tag === "RIS" && (
                            <div
                              style={{
                                fontSize: 11,
                                fontWeight: 700,
                                backgroundColor: "#0061ff",
                                display: "inline-block",
                                color: "white",
                                padding: "2px 4px 2px",
                                borderRadius: 3,
                                margin: "0 2px",
                              }}
                            >
                              {item.authorize.authorize_type.name}
                            </div>
                          )}
                      </div>
                    </button>
                  </div>
                ))}

              </>)}

            {/*<hr className={"Page__hr"}/>*/}

            <div className="Form__field">
              <div className="Field__label">
                В очередь
              </div>
            </div>

            {!!list && filterRis(list).map(item => (
              <div className="Form__field" key={"authorize_" + item.uuid}>
                <button className="Action__button Action__button_all Action__button_blue"
                        onClick={() => changeLoginPushImport(item.uuid)}
                        style={{
                          position: "relative"
                        }}>

                  <div
                    className={styleCard._block__number}
                    style={{
                      fontSize: 17,
                    }}
                  >
                    {item.authorize.name}
                  </div>
                  <div className={styleCard._block__title}>
                    {item.authorize.login}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      position: "absolute",
                      top: -8,
                      left: 0,
                      justifyContent: " flex-end",
                      width: "100%",
                      padding: "0 8px",
                    }}
                  >
                    {!!item.authorize.authorize_type &&
                      item.authorize.authorize_type.tag === "RIS" && (
                        <div
                          style={{
                            fontSize: 11,
                            fontWeight: 700,
                            backgroundColor: "#0061ff",
                            display: "inline-block",
                            color: "white",
                            padding: "2px 4px 2px",
                            borderRadius: 3,
                            margin: "0 2px",
                          }}
                        >
                          {item.authorize.authorize_type.name}
                        </div>
                      )}
                  </div>
                </button>
              </div>
            ))}

          </>
        )}
      </div>
    </Modal>
  );
}

export default ModalProjectPfhdImport;
