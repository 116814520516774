import {useRef, useState} from "react";
import {ROOT_URL} from "../../../config/url";
import axios from "axios";
import {getErrorMessageByKey, useNotification} from "@vokymlak/kabinet-ui";

export default function useParser() {
    const [isRequest, setIsRequest] = useState(false);
    const [object, setObject] = useState(null);
    const [selectFzType, setSelectFzType] = useState("null");
    const [selectYear, setSelectYear] = useState("null");

    const inputLogin = useRef(null);
    const inputPassword = useRef(null);
    const {notification} = useNotification();

    const onChangeParser = () => {
        setIsRequest(true)
        setObject(null)

        const login = inputLogin.current.value
        const password = inputPassword.current.value

        if (!login || login.length === 0) {
            return
        }

        if (!password || password.length === 0) {
            return
        }

        if ((!selectFzType || selectFzType.length === 0) || (!!selectFzType && selectFzType === "null")) {
            return
        }

        if ((!selectYear || selectYear.length === 0) || (!!selectYear && selectYear === "null")) {
            return
        }

        const jsonData = JSON.stringify({
            login: login,
            password: password,
            fz_type: selectFzType,
            year: selectYear
        });

        axios.post('/api/parser/ris', jsonData,
            {
                withCredentials: false,
                baseURL: ROOT_URL,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.getItem('tokenSession')
                }
            })
            .then(response => {
                setIsRequest(false)
                setObject(!!response.data ? response.data : null)
            }).catch(error => {
            setIsRequest(false)
            notification(getErrorMessageByKey(error.response.data.error), "red");
        });
    }

    const changeSelectFzType = (tag) => {
        setSelectFzType(tag);
    };

    const changeSelectYear = (year) => {
        setSelectYear(year);
    };

    return {
        object,
        inputLogin,
        inputPassword,
        onChangeParser,
        isRequest,

        selectFzType,
        changeSelectFzType,
        selectYear,
        changeSelectYear,
    };
}
