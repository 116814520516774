import React, { useState } from "react";
import { ROOT_URL } from "../../../config/url";
import useList from "../../../hooks/useList";
import {useAction, useUnroll} from "@vokymlak/kabinet-ui";
import {getErrorMessageByKey} from "@vokymlak/kabinet-ui";
import axios from "axios";
import {useNotification} from "@vokymlak/kabinet-ui";

function useLawContractStatuses() {
  const {
    list: [list, setList],
    update: [isUpdateList, setIsUpdateList],
  } = useList("/api/laws-contract-statuses", ROOT_URL);

  const { unrolls, isUnroll, changeUnroll, changeAllUnroll } = useUnroll();

  const [modalObjectItemAdd, setModalObjectItemAdd] = useState(null);
  const [isDrag, setIsDrag] = useState(false);

  useAction("isUpdate", () => setIsUpdateList(!isUpdateList));

  const { notification } = useNotification();

  const deleteLawContractStatus = (lawContractStatusUuid) => {
    axios
      .delete("/api/law-contract-status/" + lawContractStatusUuid + "/delete", {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        setIsUpdateList(!isUpdateList);
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const onDragStart = () => {
    setIsDrag(true);
  };

  const onDragEnd = (result) => {
    setIsDrag(false);

    if (result.destination) {
      const oldDroppableId = result.source.droppableId;
      const newDroppableId = result.destination.droppableId;

      const oldIndex = result.source.index;
      const newIndex = result.destination.index;
      const objectId = result.draggableId;

      if (oldDroppableId !== newDroppableId) {
        return;
      }

      if (!!list) {
        const listArray = [...list];
        let object = null;
        let array = null;

        listArray.map((item) => {
          if (oldDroppableId === item.tag) {
            if (!!item.contract_statuses) {
              array = [...item.contract_statuses];
              item.contract_statuses.map((status) => {
                if (objectId === status.uuid) {
                  object = { ...status };
                }
              });
            }
          }
        });

        if (!!array) {
          array = dndArray(array, oldIndex, newIndex);
        }

        listArray.map((item) => {
          if (oldDroppableId === item.tag) {
            if (!!item.contract_statuses) {
              item.contract_statuses = [...array];
            }
          }
        });

        setList([...listArray]);

        axios
          .get(
            "/api/law-contract-status/" + object.uuid + "/index/" + newIndex,
            {
              withCredentials: false,
              baseURL: ROOT_URL,
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: "Bearer " + localStorage.getItem("tokenSession"),
              },
            }
          )
          .then(() => {
            setIsUpdateList(!isUpdateList);
          })
          .catch(() => {
            setIsUpdateList(!isUpdateList);
          });
      }
    }
  };

  const dndArray = (arr, old_index, new_index) => {
    if (new_index >= arr.length) {
      let k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  };

  return {
    list,
    setList,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    setIsUpdateList,
    isUpdateList,

    modalObjectItemAdd,
    setModalObjectItemAdd,

    deleteLawContractStatus,

    onDragEnd,
    onDragStart,
    isDrag,
  };
}

export default useLawContractStatuses;
