import React, { Fragment, useState } from "react";
import {
  getAccountFio,
  Icon,
  TextLink,
  ButtonIcon,
} from "@vokymlak/kabinet-ui";
import moment from "moment";
import { NavLink } from "react-router-dom";
import ModalProjectGeneralTaskEdit from "../../ScreenObjects/Project/components/ProjectGeneralTasks/ModalProjectGeneralTaskEdit";
import ModalProjectGeneralTaskExecute from "../../ScreenObjects/Project/components/ProjectGeneralTasks/ModalProjectGeneralTaskExecute";

export const GeneralTaskProjectsList = (props) => {
  const [modalProjectTaskEdit, setModalProjectTaskEdit] = useState(null);
  const [modalProjectTaskExecute, setModalProjectTaskExecute] = useState(null);

  return (
    !!props.list && (
      <Fragment>
        {props.list.map((item) => (
          <tr
            className="Table__tr Table__tr_padding Table__tr_border Table__tr_hover"
            key={"item_" + item.uuid}
            style={{
              backgroundColor: !!item.date_execution
                ? "#a5d6a7"
                : !!item.date_control
                ? moment(item.date_control).format("YYYY-MM-DD") ===
                  moment().format("YYYY-MM-DD")
                  ? props.generalTask.is_priority
                    ? "#ffe082"
                    : item.is_priority
                    ? "#ffe082"
                    : false
                  : !!moment(
                      moment(item.date_control).format("YYYY-MM-DD")
                    ).isSameOrBefore(moment().format("YYYY-MM-DD"))
                  ? "#ef9a9a"
                  : props.generalTask.is_priority
                  ? "#ffe082"
                  : item.is_priority
                  ? "#ffe082"
                  : false
                : moment(props.generalTask.date_control).format(
                    "YYYY-MM-DD"
                  ) === moment().format("YYYY-MM-DD")
                ? props.generalTask.is_priority
                  ? "#ffe082"
                  : item.is_priority
                  ? "#ffe082"
                  : false
                : !!moment(
                    moment(props.generalTask.date_control).format("YYYY-MM-DD")
                  ).isSameOrBefore(moment().format("YYYY-MM-DD"))
                ? "#ef9a9a"
                : props.generalTask.is_priority
                ? "#ffe082"
                : item.is_priority
                ? "#ffe082"
                : false,
            }}
          >
            <td className="Table__td Table__td_border">
              <div
                className="Td__text Td__text_center"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {item.is_priority && (
                  <Icon name={"custom-priority"} size={16} color={"#202020"} />
                )}
                {!item.is_priority && "-"}
              </div>
            </td>

            <td className="Table__td Table__td_border">
              <div className="Td__text Td__text_center">
                {!!item.updated_at
                  ? moment(item.updated_at).format("DD.MM.YYYY")
                  : moment(item.created_at).format("DD.MM.YYYY")}
              </div>
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text Td__text_center">
                <NavLink
                  to={"/project/" + item.project.uuid + "/general-tasks"}
                >
                  <div
                    className="Td__text Td__text_center"
                    style={{
                      cursor: "pointer",
                      color: "#0064ff",
                      textDecoration: "underline",
                    }}
                  >
                    {(!!item.project &&
                      !!item.project.name &&
                      item.project.name) ||
                      "-"}
                  </div>
                </NavLink>
              </div>
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text Td__text_center">
                {(!!item.note && <TextLink text={item.note} />) || "-"}
              </div>
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text Td__text_center">
                {!!item.date_control
                  ? moment(item.date_control).format("DD.MM.YYYY")
                  : !!props.generalTask.date_control
                  ? moment(props.generalTask.date_control).format("DD.MM.YYYY")
                  : "-"}
              </div>
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text Td__text_center">
                {!!item.date_execution && (
                  <>
                    <div
                      className={
                        "Action__button Action__button_gray Action__button_mini"
                      }
                      onClick={() => setModalProjectTaskExecute(item)}
                    >
                      {moment(item.date_execution).format("DD.MM.YYYY")}
                    </div>
                    <div
                      style={{
                        fontSize: 12,
                        fontWeight: 400,
                      }}
                    >
                      {(!!item.executor && getAccountFio(item.executor)) || "-"}
                    </div>
                  </>
                )}
                {!item.date_execution && (
                  <div
                    className={
                      "Action__button Action__button_blue Action__button_maxi"
                    }
                    onClick={() => setModalProjectTaskExecute(item)}
                  >
                    Отметить
                  </div>
                )}
              </div>
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text Td__text_center">
                <ButtonIcon
                  tooltipText={"Редактировать"}
                  iconName={"custom-edit"}
                  iconColor={"#202020"}
                  size={36}
                  onClick={() => setModalProjectTaskEdit(item)}
                />
              </div>
            </td>
          </tr>
        ))}

        {!!modalProjectTaskEdit && (
          <ModalProjectGeneralTaskEdit
            object={modalProjectTaskEdit}
            successfulRequest={props.successfulRequest}
            handleClose={() => setModalProjectTaskEdit(null)}
          />
        )}

        {!!modalProjectTaskExecute && (
          <ModalProjectGeneralTaskExecute
            object={modalProjectTaskExecute}
            successfulRequest={props.successfulRequest}
            handleClose={() => setModalProjectTaskExecute(null)}
          />
        )}
      </Fragment>
    )
  );
};
