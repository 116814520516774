import React from "react";
import ModalStageAdd from "./ModalStageAdd";
import ModalStageEdit from "./ModalStageEdit";
import {ButtonIcon, Search} from "@vokymlak/kabinet-ui";
import {ContractStagesTable} from "./ContractStagesTable";

function ContractStagesPage(props) {
  const {
    object,

    countActAmount,
    countPaymentAmount,

    list,
    setList,
    count,

    setIsUpdateList,
    isUpdateList,

    modalStageAdd,
    setModalStageAdd,

    modalStageEdit,
    setModalStageEdit,

    isDragAndDrop,
    setIsDragAndDrop,
  } = props;

  function getColorAmount(amountAtc, amountPayment) {
    const actAmount = amountAtc;
    const paymentAmount = amountPayment;

    if (actAmount > paymentAmount &&
      paymentAmount > 0) {
      return "#cf2f00";
    } else if (actAmount === paymentAmount &&
      paymentAmount > 0) {
      return "#2e7d32";
    } else if (actAmount < paymentAmount) {
      return "#1565c0";
    }
    return "#202020";
  }

  return (
    !!object && (
      <>
        <div
          className="Page__title"
          style={{
            marginTop: 0,
            marginBottom: 6,
          }}
        >
          <div className="Title__text">
            <div
              className="Title__text"
              style={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
                fontSize: 19,
                fontWeight: 700,
                marginRight: 6,
              }}
            >
              <div>
                Этапы
                {!!list && (
                  <span style={{fontSize: 12, opacity: 0.75, marginLeft: 6}}>
                    ({count})
                  </span>
                )}
              </div>
            </div>

            {(
              <div style={{
                marginLeft: 6,
                display: "flex",
              }}>
                <span style={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: 6,
                  backgroundColor: "#dbdbdb",
                  padding: 4,
                  borderRadius: 5
                }}>
                <span style={{fontSize: 12, fontWeight: 400}}>
                    Сумма по актам
                  </span>
                  <span style={{fontSize: 19, fontWeight: 700}}>
                    {(!!countActAmount &&
                        new Intl.NumberFormat("ru-RU").format(
                          (countActAmount / 100).toFixed(2)
                        )) ||
                      "0,00"}
                  </span>
                </span>

                <span style={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: 6,
                  backgroundColor: "#dbdbdb",
                  padding: 4,
                  borderRadius: 5
                }}>
                <span style={{fontSize: 12, fontWeight: 400}}>
                    Сумма по платежам
                  </span>
                  <span style={{
                    fontSize: 19,
                    fontWeight: 700,
                    color: getColorAmount(countActAmount, countPaymentAmount)
                  }}>
                    {(!!countPaymentAmount &&
                        new Intl.NumberFormat("ru-RU").format(
                          (countPaymentAmount / 100).toFixed(2)
                        )) ||
                      "0,00"}
                  </span>
                </span>
              </div>
            )}
          </div>

          <div className="Title__settings">
            <ButtonIcon
              tooltipText={"Положение"}
              iconName={"custom-swap-ver"}
              iconColor={isDragAndDrop ? "#202020" : "#20202075"}
              size={26}
              style={{marginLeft: 12}}
              onClick={() => setIsDragAndDrop(!isDragAndDrop)}
            />

            <ButtonIcon
              tooltipText={"Обновить"}
              iconName={"custom-change-circle"}
              iconColor={"#20202075"}
              size={28}
              style={{marginLeft: 12}}
              onClick={() => (setList(null), setIsUpdateList(!isUpdateList))}
            />

            <ButtonIcon
              tooltipText={"Добавить"}
              iconName={"custom-add"}
              iconColor={"#0064ff"}
              size={36}
              style={{marginLeft: 12}}
              onClick={() => setModalStageAdd(true)}
            />
          </div>
        </div>

        {!!list && (
          <div
            className="Page__content"
            style={{
              position: "relative",
              height: "calc(100% - 48px - 26px - 2px - 10px - 28px)",
            }}
          >
            <div
              style={{
                overflow: "auto",
                width: "100%",
                height: "100%",
              }}
            >
              <ContractStagesTable {...props} />
            </div>
          </div>
        )}

        {!!modalStageAdd && (
          <ModalStageAdd
            contract={object}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalStageAdd(null)}
          />
        )}
        {!!modalStageEdit && (
          <ModalStageEdit
            stage={modalStageEdit}
            contract={object}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalStageEdit(null)}
          />
        )}
      </>
    )
  );
}

export default ContractStagesPage;
