import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../../../config/url";
import { getErrorMessageByKey, useNotification, Textarea, Input, Modal } from "@vokymlak/kabinet-ui";

function ModalProjectSmpItemEdit(props) {
  const [projectTask, setProjectTask] = useState(null);

  const inputName = useRef(null);
  const inputAmount = useRef(null);

  const [isArchive, setIsArchive] = useState(false);

  const { notification } = useNotification();

  useEffect(() => {
    if (props.object) {
      axios
        .get("/api/project-smp-expense/" + props.object.uuid, {
          withCredentials: false,
          baseURL: ROOT_URL,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("tokenSession"),
          },
        })
        .then((response) => {
          setProjectTask(response.data ? response.data : null);
        })
        .catch((error) => {
          notification(getErrorMessageByKey(error.response.data.error), "red");
        });
    }

    return () => {
      setProjectTask(null);
    };
  }, [props.projectTask]);

  const numberConvert = (numberString) => {
    return !!numberString && numberString.length > 0
      ? Number(String(Number(numberString).toFixed(2)).replace(".", ""))
      : null;
  };

  const changeEdit = (event) => {
    event.preventDefault();

    const name = inputName.current.value;
    const amount = inputAmount.current.value;

    if (!name || name.trim().length === 0) {
      return;
    }

    const jsonData = JSON.stringify({
      title: name.trim(),
      amount: numberConvert(amount),
    });

    axios
      .put("/api/project-smp-expense/" + projectTask.uuid + "/edit", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });

    props.handleClose();
  };

  const changeArchive = (event) => {
    event.preventDefault();

    axios
      .delete("/api/project-smp-expense/" + projectTask.uuid + "/delete", {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });

    props.handleClose();
  };

  return (
    !!projectTask && (
      <Modal handleClose={() => props.handleClose()}>
        {!isArchive && (
          <form className="Form" onSubmit={(event) => changeEdit(event)}>
            <div className="Form__title">
              {projectTask.title}
              <div className="Text__signature">Расход</div>
            </div>

            <div className="Form__field">
              <Textarea
                reference={inputName}
                label="Наименование *"
                value={projectTask.title}
              />
            </div>

            <div className="Form__field">
              <Input
                reference={inputAmount}
                type="number"
                label="Сумма"
                value={!!projectTask.amount && projectTask.amount / 100}
              />
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_blue">
                Изменить
              </button>
            </div>

            <div className="Form__actions">
              <div
                className="Action__link Action__link_red"
                onClick={() => setIsArchive(true)}
              >
                Удалить
              </div>
              <div className="Action__link" onClick={() => props.handleClose()}>
                Отмена
              </div>
            </div>
          </form>
        )}
        {isArchive && (
          <form className="Form" onSubmit={(event) => changeArchive(event)}>
            <div className="Form__title">
              {projectTask.title}
              <div className="Text__signature">Удалить</div>
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_red">
                Продолжить
              </button>
            </div>

            <div className="Form__actions Form__actions_center">
              <div className="Action__link" onClick={() => setIsArchive(false)}>
                Отмена
              </div>
            </div>
          </form>
        )}
      </Modal>
    )
  );
}

export default ModalProjectSmpItemEdit;
