import React from "react";
import ContractStagesWrapper from "./ContractStagesWrapper";

function ContractStages(props) {
  return (
      <ContractStagesWrapper
        {...props}
      />
  );
}

export default ContractStages;
