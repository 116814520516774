import React, {useRef, useState} from "react";
import Modal from "../../../components/Modal/Modal";
import axios from "axios";
import { ROOT_URL } from "../../../config/url";
import { Input } from "../../../components/Input";
import {getErrorMessageByKey, useNotification} from "@vokymlak/kabinet-ui";

function ModalContractGroupAdd(props) {
  const inputName = useRef(null);

  const { notification } = useNotification();
  const [isContractStatusNull, setIsContractStatusNull] = useState(false);
  const [isPurchaseBaseNull, setIsPurchaseBaseNull] = useState(false);

  const changeAdd = (event) => {
    event.preventDefault();

    const name = inputName.current.value;

    if (!name || name.length === 0) {
      return;
    }

    const jsonData = JSON.stringify({
      name: name,
      is_contract_status_null: isContractStatusNull,
      is_purchase_base_null: isPurchaseBaseNull,
    });

    axios
      .post("/api/contract-group/add", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });

    props.handleClose();
  };

  return (
    <Modal handleClose={() => props.handleClose}>
      <form className="Form" onSubmit={(event) => changeAdd(event)}>
        <div className="Form__title">Новый</div>

        <div className="Form__field">
          <Input reference={inputName} type="text" label="Наименование *" />
        </div>

        <div className="Form__field">
          <div className="Button__swith" style={{
            padding: "8px",
            width: "100%",
            border: "2px solid #20202015",
            backgroundColor: "#ffffff",
            borderRadius: 6,
            marginTop: 20,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            cursor: "pointer",
          }}

               onClick={() => setIsContractStatusNull(!isContractStatusNull)}
          >
            <div className="Swith__text" style={{
              fontSize: 17,
              fontWeight: 600,
              display: "flex",
              alignItems: "center",
              // color: isPriority ? "#ff3c00" : "#202020",
            }}>
              Без статуса договора
            </div>
            <div
              className={
                'Swith Swith_gray' +
                ((isContractStatusNull && ' Swith_active Swith_active_green') ||
                  '')
              }
            ></div>
          </div>
        </div>

        <div className="Form__field Form__field_last">
          <div className="Button__swith" style={{
            padding: "8px",
            width: "100%",
            border: "2px solid #20202015",
            backgroundColor: "#ffffff",
            borderRadius: 6,
            marginTop: 20,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            cursor: "pointer",
          }}

               onClick={() => setIsPurchaseBaseNull(!isPurchaseBaseNull)}
          >
            <div className="Swith__text" style={{
              fontSize: 17,
              fontWeight: 600,
              display: "flex",
              alignItems: "center",
              // color: isPriority ? "#ff3c00" : "#202020",
            }}>
              Без основания закупки
            </div>
            <div
              className={
                'Swith Swith_gray' +
                ((isPurchaseBaseNull && ' Swith_active Swith_active_green') ||
                  '')
              }
            ></div>
          </div>
        </div>

        <div className="Form__actions Form__actions_center">
          <button className="Action__button Action__button_all Action__button_blue">
            Добавить
          </button>
        </div>

        <div className="Form__actions Form__actions_center">
          <div className="Action__link" onClick={() => props.handleClose()}>
            Отмена
          </div>
        </div>
      </form>
    </Modal>
  );
}

export default ModalContractGroupAdd;
