import React, {Fragment} from "react";
import {
  Tooltip,
} from "@vokymlak/kabinet-ui";
import moment from "moment";

export const ContractStagesList = (props) => {
  const {
    list,
    object,
  } = props;

  function getColorAmount(amountAtc, amountPayment) {
    const actAmount = amountAtc;
    const paymentAmount = amountPayment;

    if (actAmount > paymentAmount &&
      paymentAmount > 0) {
      return "#1565c0";
    } else if (actAmount === paymentAmount &&
      paymentAmount > 0) {
      return "#2e7d32";
    } else if (actAmount < paymentAmount) {
      return "#cf2f00";
    }
    return "#202020";
  }

  return (
    !!list && (
      <Fragment>
        {list.map(item => (
                  <tr
                    className={"Table__tr Table__tr_padding Table__tr_border"}
                    key={"item_" + item.uuid}
                  >
                    <td
                      className="Table__td Table__td_border"
                    >
                      <Tooltip
                        text={"Наименование"}
                        style={{ width: "100%", height: "100%" }}
                      >
                        <div className="Td__text">
                          {!!item.name && item.name}
                        </div>
                      </Tooltip>
                    </td>

                    <td
                      className="Table__td Table__td_border"
                    >
                      <Tooltip
                        text={"Акты"}
                        style={{ width: "100%", height: "100%" }}
                      >
                        <div className="Td__text Td__text_center">
                          {(!!item.acts && item.acts.map(act => (
                            <span key={'act_' + act.uuid} style={{
                              textDecoration: "underline",
                              margin: "0 3px"
                            }}>
                              {!!act.number && "№ " + act.number} {!!act.date_number && " от " + moment(act.date_number).format("DD.MM.YYYY")}
                            </span>
                          ))) || "-"}
                        </div>
                      </Tooltip>
                    </td>

                    {!!object &&
                      !object.is_without_volume && (
                    <td
                      className="Table__td Table__td_border"
                    >
                      <Tooltip
                        text={"Объём"}
                        style={{ width: "100%", height: "100%" }}
                      >
                        <div className="Td__text Td__text_center"
                        style={{
                          fontSize: 19,
                        }}>
                          {(!!item.acts && (new Intl.NumberFormat("ru-RU").format(
                              (item.count_volume / 1000).toFixed(3)
                            ))) ||
                            "-"}
                        </div>
                      </Tooltip>
                    </td>
                      )}
                    <td
                      className="Table__td Table__td_border"
                    >
                      <Tooltip
                        text={"Сумма по актам"}
                        style={{ width: "100%", height: "100%" }}
                      >
                        <div className="Td__text Td__text_center"
                        style={{
                          fontSize: 19,
                        }}>
                          {(!!item.acts && (new Intl.NumberFormat("ru-RU").format(
                              (item.count_act_amount / 100).toFixed(2)
                            ))) ||
                            "-"}
                        </div>
                      </Tooltip>
                    </td>

                    <td
                      className="Table__td Table__td_border"
                    >
                      <Tooltip
                        text={"Сумма по платежам"}
                        style={{ width: "100%", height: "100%" }}
                      >
                        <div className="Td__text Td__text_center"
                             style={{
                               fontSize: 19,
                               color: getColorAmount(item.count_act_amount, item.count_payment_amount)
                             }}>
                          {(!!item.payments && (new Intl.NumberFormat("ru-RU").format(
                              (item.count_payment_amount / 100).toFixed(2)
                            ))) ||
                            "-"}
                        </div>
                      </Tooltip>
                    </td>
                  </tr>
          ))}
      </Fragment>
    )
  );
};
