import React, {Fragment} from "react";
import {ModalAddClient} from "./ModalAddClient";
import {ModalEditClient} from "./ModalEditClient";
import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";
import {
  ButtonIcon,
  getAccountFio,
  Icon,
  Loading,
  Marker,
  Pagination,
  Search,
  Select,
  TextSearchMarker,
} from "@vokymlak/kabinet-ui";
import {ClientProjectsList} from "./customs/ClientProjectsList";
import moment from "moment/moment";

export const ClientsPage = (props) => {
  const {
    list,
    setList,
    count,

    pageNumber,
    pageLimit,
    setPageLimit,
    changePage,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    inputSearchText,
    onChangeSearchText,
    searchQuery,

    setIsUpdateList,
    isUpdateList,

    isActivatedAccount,

    modalEditAccount,
    setModalEditAccount,
    isModalAddAccount,
    setIsModalAddAccount,
  } = props;

  return (
    <ScrollBlock style={{width: "100%", height: "100%"}}>
      <div className="Page">
        <div className="Page__title">
          <div
            className="Title__text"
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: 21,
              fontWeight: 700,
            }}
          >
            Клиенты
            {!!list && (
              <span style={{fontSize: 12, opacity: 0.75, marginLeft: 6}}>
                ({count})
              </span>
            )}
            <span style={{width: 220, marginLeft: 16}}>
              <Search
                reference={inputSearchText}
                type="text"
                label={"Поиск"}
                isFixed={true}
                onChange={() => onChangeSearchText()}
              />
            </span>
          </div>

          <div className="Title__settings">
            {!!list && (
              <ButtonIcon
                tooltipText={
                  list.length === unrolls.length ? "Свернуть" : "Развернуть"
                }
                iconName={
                  list.length === unrolls.length
                    ? "custom-unfold-less"
                    : "custom-unfold-more"
                }
                iconColor={"#20202075"}
                size={28}
                style={{marginLeft: 8}}
                onClick={() => changeAllUnroll(list, "created_at")}
              />
            )}

            <ButtonIcon
              tooltipText={"Обновить"}
              iconName={"custom-change-circle"}
              iconColor={"#20202075"}
              size={28}
              style={{marginLeft: 8}}
              onClick={() => (setList(null), setIsUpdateList(!isUpdateList))}
            />

            {!!count && !!pageLimit && (
              <div
                style={{
                  marginLeft: 12,
                  width: 64,
                }}
              >
                <Select
                  isFixed={true}
                  isDefaultOption={false}
                  value={pageLimit}
                  options={[
                    {
                      value: 100,
                      title: 100,
                    },
                    {
                      value: 1000,
                      title: 1000,
                    },
                  ]}
                  label="Лимит"
                  onChange={(event) => setPageLimit(Number(event.target.value))}
                />
              </div>
            )}

            {!!count && count > pageLimit && (
              <div style={{marginLeft: 8}}>
                <Pagination
                  totalRecords={count}
                  pageLimit={pageLimit}
                  currentPage={pageNumber}
                  onPageChanged={(data) => changePage(data)}
                />
              </div>
            )}

            <ButtonIcon
              tooltipText={"Добавить"}
              iconName={"custom-add"}
              iconColor={"#0064ff"}
              size={36}
              style={{marginLeft: 12}}
              onClick={() => setIsModalAddAccount(true)}
            />
          </div>
        </div>

        {!list && (
          <div className={"Page__loader"}>
            <Loading/>
          </div>
        )}

        {!!list && (
          <div className="Page__content">
            <ul className="List">
              {list.map((item) => (
                <Fragment key={"item_" + item.account.uuid}>
                  <li
                    className={
                      isUnroll(item.created_at)
                        ? "List__item active"
                        : "List__item"
                    }
                    onClick={() => changeUnroll(item.created_at)}
                    style={{
                      paddingLeft: 8,
                      position: "relative",
                      marginBottom: 12
                    }}
                  >
                    <div className="Item__title">
                      <div className="Element__title">
                        <div style={{
                          height: 36
                        }}>
                          <Icon
                            name={"custom-account-circle"}
                            size={36}
                            color={"#202020"}
                          />
                        </div>

                        <div style={{marginLeft: 8}}>
                          <div>
                            <TextSearchMarker
                              text={getAccountFio(item.account)}
                              search={searchQuery}
                            />
                            <span
                              style={{
                                fontSize: 11,
                                opacity: 0.75,
                                marginLeft: 4
                              }}
                            >
                              {!!item.account.position &&
                                "(" + item.account.position + ")"}
                            </span>
                          </div>
                          <div
                            style={{
                              fontSize: 12,
                              opacity: 0.5,
                            }}
                          >
                            {!!item.account.login && (
                              <div
                                style={{
                                  fontSize: 11,
                                  fontWeight: 700,
                                  backgroundColor: "#202020",
                                  display: "inline-block",
                                  color: "white",
                                  padding: "2px 6px",
                                  borderRadius: 24,
                                  marginRight: 4,
                                }}
                              >
                                {item.account.login}
                              </div>
                            )}

                            {!!item.account.email && (
                              <div
                                style={{
                                  fontSize: 11,
                                  fontWeight: 700,
                                  backgroundColor: "#202020",
                                  display: "inline-block",
                                  color: "white",
                                  padding: "2px 6px",
                                  borderRadius: 24,
                                  marginRight: 4,
                                }}
                              >
                                {item.account.email}
                              </div>
                            )}
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            position: "absolute",
                            top: -8,
                            left: 0,
                            justifyContent: " flex-end",
                            width: "100%",
                            padding: "0 8px",
                          }}
                        >
                          {item.account.is_activated && (
                            <div
                              style={{
                                fontSize: 11,
                                fontWeight: 700,
                                backgroundColor: "#4cae50",
                                display: "inline-block",
                                color: "white",
                                padding: "2px 4px 2px",
                                borderRadius: 3,
                                margin: "0 2px",
                              }}
                            >
                              Доступ открыт
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="Item__settings">
                      <div className="Element__datetime">
                        {!!item.account.last_action_in &&
                          moment(
                            item.account.last_action_in.substring(0, 19)
                          ).format("HH:mm DD.MM.YYYY")}
                        <Marker
                          size={10}
                          style={{
                            marginLeft: 8,
                            marginRight: 4,
                          }}
                          color={
                            !!item.account.last_action_in
                              ? moment()
                                .subtract(5, "minutes")
                                .isBefore(
                                  moment(
                                    item.account.last_action_in.substring(
                                      0,
                                      19
                                    )
                                  )
                                )
                                ? "#4caf50"
                                : "#9e9e9e"
                              : "#9e9e9e"
                          }
                        />
                      </div>
                    </div>
                  </li>

                  {isUnroll(item.created_at) && (
                    <li className="List__content">
                      <div className="Content__header">
                        <div className="Header__title"></div>
                        <div className="Header__settings">
                          <div className="Button__swith">
                            <div className="Swith__text">Доступ к работе</div>
                            <div
                              className={
                                "Swith" +
                                ((!!item.account.is_activated &&
                                    " Swith_active ") ||
                                  "")
                              }
                              onClick={() =>
                                isActivatedAccount(item.account.uuid)
                              }
                            ></div>
                          </div>
                          <ButtonIcon
                            tooltipText={"Редактировать"}
                            iconName={"custom-edit"}
                            iconColor={"#202020"}
                            size={36}
                            style={{marginLeft: 12}}
                            onClick={() => setModalEditAccount(item.account)}
                          />
                        </div>
                      </div>
                      <ClientProjectsList client={item.account}/>
                    </li>
                  )}
                </Fragment>
              ))}
            </ul>
          </div>
        )}

        {!!modalEditAccount && (
          <ModalEditClient
            account={modalEditAccount}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalEditAccount(null)}
          />
        )}
        {!!isModalAddAccount && (
          <ModalAddClient
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setIsModalAddAccount(false)}
          />
        )}
      </div>
    </ScrollBlock>
  );
};
