import React, { useRef } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../config/url";
import {
  getAccountFio,
  getErrorMessageByKey,
  isValidateNullText,
  jsonFormatText,
  useNotification,
  Modal,
  Input,
  useList,
  Select,
} from "@vokymlak/kabinet-ui";

function ModalCompanyAdd(props) {
  const {
    list: [list],
  } = useList({ apiUrl: "/api/clients/list", rootUrl: ROOT_URL });

  const inputShortName = useRef(null);
  const inputFullName = useRef(null);
  const inputOgrn = useRef(null);
  const inputInn = useRef(null);
  const inputKpp = useRef(null);
  const inputLegalAddress = useRef(null);
  const inputEmail = useRef(null);
  const inputPhone = useRef(null);
  const selectClientId = useRef(null);

  const { notification } = useNotification();

  const changeAdd = (event) => {
    event.preventDefault();

    const shortName = jsonFormatText(inputShortName.current.value);
    const fullName = jsonFormatText(inputFullName.current.value);
    const ogrn = jsonFormatText(inputOgrn.current.value);
    const inn = jsonFormatText(inputInn.current.value);
    const kpp = jsonFormatText(inputKpp.current.value);
    const legalAddress = jsonFormatText(inputLegalAddress.current.value);
    const email = jsonFormatText(inputEmail.current.value);
    const phone = jsonFormatText(inputPhone.current.value);
    // const clientId = selectClientId.current.value;

    if (isValidateNullText(shortName)) {
      notification("Заполните все обязательные поля", "red");
      return;
    }

    const jsonData = JSON.stringify({
      short_name: shortName,
      full_name: fullName,
      ogrn: ogrn,
      inn: inn,
      kpp: kpp,
      legal_address: legalAddress,
      email: email,
      phone: phone,
      // client_id:
      //   clientId.length > 0
      //     ? clientId === "null"
      //       ? null
      //       : Number(clientId)
      //     : null,
    });

    axios
      .post("/api/company/add", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Компания добавлена", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const getSelectOptions = (list) => {
    const options = [
      {
        value: "null",
        title: "Не выбрано",
      },
    ];

    list.map((item) => {
      const option = {
        value: item.account.id,
        title: getAccountFio(item.account),
      };

      options.push(option);
    });

    return options;
  };

  return (
    <Modal handleClose={() => props.handleClose()}>
      <form className="Form" onSubmit={(event) => changeAdd(event)}>
        <div className="Form__title">
          Новая
          <div className="Text__signature">Компания</div>
        </div>

        <div className="Form__field">
          <Input reference={inputShortName} label="Краткое наименование *" />
        </div>

        <div className="Form__field">
          <Input reference={inputFullName} label="Полное наименование" />
        </div>

        <div className="Form__field">
          <Input reference={inputOgrn} label="ОГРН" />
        </div>

        <div className="Form__field">
          <Input reference={inputInn} label="ИНН" />
        </div>

        <div className="Form__field">
          <Input reference={inputKpp} label="КПП" />
        </div>

        <div className="Form__field">
          <Input reference={inputLegalAddress} label="Юридический адрес" />
        </div>

        <div className="Form__field">
          <Input reference={inputEmail} label="Email" />
        </div>

        <div className="Form__field">
          <Input reference={inputPhone} label="Телефон (7...)" />
        </div>

        {/*<div className="Form__field Form__field_last">*/}
        {/*  <Select*/}
        {/*    isFixed={true}*/}
        {/*    reference={selectClientId}*/}
        {/*    options={!!list ? getSelectOptions(list) : getSelectOptions([])}*/}
        {/*    label="Клиент"*/}
        {/*  />*/}
        {/*</div>*/}

        <div className="Form__actions Form__actions_center">
          <button className="Action__button Action__button_all Action__button_blue">
            Добавить
          </button>
        </div>

        {/*<div className="Form__actions Form__actions_center">*/}
        {/*  <div className="Action__link" onClick={() => props.handleClose()}>*/}
        {/*    Отмена*/}
        {/*  </div>*/}
        {/*</div>*/}
      </form>
    </Modal>
  );
}

export default ModalCompanyAdd;
