import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import "../../App.scss";
import { SettingsMenu } from "./SettingsMenu/SettingsMenu";
import { PurchaseWays } from "./PurchaseWays";
import { ContractStatuses } from "./ContractStatuses";
import { ControlPoints } from "./ControlPoints";
import { PurchaseBaseControlPoints } from "./PurchaseBaseControlPoints";
import { LawContractStatuses } from "./LawContractStatuses";
import { ControlGroups } from "./ControlGroups";
import { PurchaseBasis } from "./PurchaseBasis";
import { ContractGroups } from "./ContractGroups";
import { SettingLogics } from "./SettingLogics";
import { SettingsView } from "./SettingsView";

export const SettingsRouting = () => {
  return (
    <div className="App App_vertical">
      <div className="Navigate" style={{ width: 240 }}>
        <SettingsMenu />
      </div>

      <div className="Content">
        <Routes>
          <Route path="/view" element={<SettingsView />} />
          <Route path="/purchase-ways" element={<PurchaseWays />} />
          <Route path="/contract-statuses" element={<ContractStatuses />} />
          <Route path="/control-points" element={<ControlPoints />} />
          <Route
            path="/purchase-base-control-points"
            element={<PurchaseBaseControlPoints />}
          />
          <Route
            path="/law-contract-statuses"
            element={<LawContractStatuses />}
          />
          <Route path="/control-groups" element={<ControlGroups />} />
          <Route path="/purchase-basis" element={<PurchaseBasis />} />
          <Route path="/contract-groups" element={<ContractGroups />} />
          <Route path="/logics" element={<SettingLogics />} />
          <Route exact path="/" element={<Navigate to="./view" replace />} />
        </Routes>
      </div>
    </div>
  );
};
