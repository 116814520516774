import React, { useEffect, useState } from "react";
import { ROOT_URL } from "../../../../config/url";
import { useAction, useObject } from "@vokymlak/kabinet-ui";
import { useParams } from "react-router-dom";

export const useHandbookArticle = () => {
  const [isEdit, setIsEdit] = useState(false);
  let { handbookEntryUuid } = useParams();

  const {
    object: [object, setObject],
    update: [isUpdateObject, setIsUpdateObject, updateObject],
    setApiUrl,
  } = useObject({
    apiUrl: "/api/handbook-entry/" + handbookEntryUuid + "/article",
    rootUrl: ROOT_URL,
  });

  useEffect(() => {
    setApiUrl("/api/handbook-entry/" + handbookEntryUuid + "/article");
    setObject(null);
    setIsEdit(false);
  }, [handbookEntryUuid]);

  useAction(handbookEntryUuid, () => updateObject());

  return {
    object,
    setObject,

    setIsUpdateObject,
    isUpdateObject,

    isEdit,
    setIsEdit,
  };
};
