import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../config/url";
import {
  getErrorMessageByKey,
  useNotification,
  Textarea,
  Input,
  useObject,
  Modal,
  jsonFormatText,
  isValidateNullText,
} from "@vokymlak/kabinet-ui";
import moment from "moment";

function ModalGeneralTaskCopy(props) {
  const [isPriority, setIsPriority] = useState(false);

  const {
    object: [object],
  } = useObject({
    apiUrl: "/api/general-task/" + props.object.uuid,
    rootUrl: ROOT_URL,
    callbackResponse: (data) => {
      setIsPriority(!!data && data.is_priority);
    },
    callbackError: () => {
      props.handleClose();
    },
  });

  const inputTitle = useRef(null);
  const inputDescription = useRef(null);

  const inputDateControl = useRef(null);

  const { notification } = useNotification();

  const changeEdit = (event) => {
    event.preventDefault();

    const title = jsonFormatText(inputTitle.current.value);
    const description = jsonFormatText(inputDescription.current.value);
    const dateControl = inputDateControl.current.value;

    if (isValidateNullText(title) || isValidateNullText(dateControl)) {
      notification("Заполните все обязательные поля", "red");
      return;
    }

    const jsonData = JSON.stringify({
      title: title,
      description: description,
      is_priority: isPriority,
      date_control:
        !!dateControl && dateControl.length > 0 ? dateControl : null,
    });

    axios
      .put("/api/general-task/" + props.object.uuid + "/copy", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Общая задача добавлена", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    !!object && (
      <Modal handleClose={() => props.handleClose()}>
          <form className="Form" onSubmit={(event) => changeEdit(event)}>
            <div className="Form__title">
              {object.title}
              <div className="Text__signature">Новая общая задача</div>
            </div>

            <div className="Form__field">
              <Textarea
                reference={inputTitle}
                label="Заголовок *"
                value={object.title}
              />
            </div>

            <div className="Form__field">
              <Textarea
                reference={inputDescription}
                label="Описание"
                value={object.description}
              />
            </div>

            <div className="Form__field">
              <Input
                reference={inputDateControl}
                type="date"
                isFixed={true}
                label="Дата исполнения *"
                value={
                  !!object.date_control
                    ? moment(object.date_control).format("YYYY-MM-DD")
                    : null
                }
              />
            </div>

            <div className="Form__field Form__field_last">
              <div
                className="Button__swith"
                style={{
                  padding: "8px",
                  width: "100%",
                  border: isPriority
                    ? "2px solid #ffe082"
                    : "2px dashed #20202015",
                  backgroundColor: isPriority ? "#ffe082" : "#ffffff",
                  borderRadius: 6,
                  marginTop: 20,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  cursor: "pointer",
                }}
                onClick={() => setIsPriority(!isPriority)}
              >
                <div
                  className="Swith__text"
                  style={{
                    fontSize: 17,
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Приоритетная
                </div>
                <div
                  className={
                    "Swith Swith_gray" +
                    ((isPriority && " Swith_active Swith_active_gray") || "")
                  }
                ></div>
              </div>
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_blue">
                Добавить
              </button>
            </div>
          </form>
      </Modal>
    )
  );
}

export default ModalGeneralTaskCopy;
