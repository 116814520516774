import React, {useRef, useState} from "react";
import { ROOT_URL } from "../../../config/url";
import { useList, Modal, Select, getAccountFio } from "@vokymlak/kabinet-ui";
import styleTumbler from "../../../styles/modules/Tumbler.module.scss";
import {ROLE_DIRECTOR, ROLE_EMPLOYEE} from "../../../config/constants";

function ModalProjectsFilter(props) {
  const {
    list: [listEmployees],
  } = useList({
    apiUrl: "/api/employees/list",
    rootUrl: ROOT_URL,
    urlParams: {
      roles: [ROLE_DIRECTOR, ROLE_EMPLOYEE],
    }
  });

  const selectEmployeeId = useRef(null);

  const changeAddEmployee = () => {
    const { filterEmployees, setFilterEmployees } = props.useSearchFilter;

    const employeeId = selectEmployeeId.current.value;

    if (
      !employeeId ||
      employeeId.length === 0 ||
      (employeeId.length > 0 && employeeId === "null")
    ) {
      setFilterEmployees([...[]]);
    } else {
      setFilterEmployees([employeeId]);
    }

    props.handleClose();
  };

  // const changeAddEmployee = () => {
  //   const { filterEmployees, setFilterEmployees } = props.useSearchFilter;
  //
  //   const employeeId = selectEmployeeId.current.value;
  //
  //   if (
  //     !employeeId ||
  //     employeeId.length === 0 ||
  //     (employeeId.length > 0 && employeeId === "null")
  //   ) {
  //     setFilterEmployees([...[]]);
  //   } else {
  //     let isNew = true;
  //     filterEmployees.map((f) => {
  //       if (f === employeeId) {
  //         isNew = false;
  //       }
  //     });
  //     if (isNew) {
  //       filterEmployees.push(employeeId);
  //       setFilterEmployees([...filterEmployees]);
  //     }
  //   }
  //
  //   props.handleClose();
  // };

  // const getEmployeesSelectOptions = (list) => {
  //   const options = [
  //     {
  //       value: "null",
  //       title: "Все",
  //     },
  //   ];
  //
  //   list.map((item) => {
  //     const option = {
  //       value: item.account.id,
  //       title: getAccountFio(item.account),
  //     };
  //
  //     options.push(option);
  //   });
  //
  //   return options;
  // };

  const getEmployeesSelectOptions = (list) => {
    const options = [
      {
        value: "null",
        title: "Все",
      },
    ];

    list.map((item) => {
      const option = {
        value: item.account.uuid,
        title: getAccountFio(item.account),
      };

      options.push(option);
    });

    return options;
  };

  // const changeAddEmployeeCurator = () => {
  //   const { filterCuratorEmployees, setFilterCuratorEmployees } = props.useSearchFilter;
  //
  //   const employeeId = selectEmployeeId.current.value;
  //
  //   if (
  //     !employeeId ||
  //     employeeId.length === 0 ||
  //     (employeeId.length > 0 && employeeId === "null")
  //   ) {
  //     setFilterCuratorEmployees([...[]]);
  //   } else {
  //     let isNew = true;
  //     filterCuratorEmployees.map((f) => {
  //       if (f === employeeId) {
  //         isNew = false;
  //       }
  //     });
  //     if (isNew) {
  //       filterCuratorEmployees.push(employeeId);
  //       setFilterCuratorEmployees([...filterCuratorEmployees]);
  //     }
  //   }
  //
  //   props.handleClose();
  // };
  //
  // const changeAddEmployeeSpecialist = () => {
  //   const { filterSpecialistEmployees, setFilterSpecialistEmployees } = props.useSearchFilter;
  //
  //   const employeeId = selectEmployeeId.current.value;
  //
  //   if (
  //     !employeeId ||
  //     employeeId.length === 0 ||
  //     (employeeId.length > 0 && employeeId === "null")
  //   ) {
  //     setFilterSpecialistEmployees([...[]]);
  //   } else {
  //     let isNew = true;
  //     filterSpecialistEmployees.map((f) => {
  //       if (f === employeeId) {
  //         isNew = false;
  //       }
  //     });
  //     if (isNew) {
  //       filterSpecialistEmployees.push(employeeId);
  //       setFilterSpecialistEmployees([...filterSpecialistEmployees]);
  //     }
  //   }
  //
  //   props.handleClose();
  // };


  // const [tumbler, setTumbler] = useState("ac");

  return (
    <Modal handleClose={() => props.handleClose()}>
      <div className="Form">
        <div className="Form__title">Фильтры поиска</div>

        <div className="Form__field Form__field_last">
          <Select
            isFixed={true}
            reference={selectEmployeeId}
            options={
              !!listEmployees
                ? getEmployeesSelectOptions(listEmployees)
                : getEmployeesSelectOptions([])
            }
            label="Сотрудник"
          />
        </div>

        <div className="Form__actions Form__actions_center">
          <button
            className="Action__button Action__button_all Action__button_blue"
            onClick={() => changeAddEmployee()}
          >
            Выбрать
          </button>
        </div>

        {/*<div className="Form__field">*/}
        {/*  <ul*/}
        {/*    className={styleTumbler._}*/}
        {/*    style={{*/}
        {/*      justifyContent: "space-between",*/}
        {/*      backgroundColor: "#f5f5f5",*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    <li className={styleTumbler._link}>*/}
        {/*      <a*/}
        {/*        className={*/}
        {/*          styleTumbler._text +*/}
        {/*          ((!!tumbler &&*/}
        {/*              tumbler === "ac" &&*/}
        {/*              " " + styleTumbler.active + " ") ||*/}
        {/*            "")*/}
        {/*        }*/}
        {/*        onClick={() => setTumbler("ac")}*/}
        {/*        style={{*/}
        {/*          cursor: "pointer",*/}
        {/*        }}*/}
        {/*      >*/}
        {/*        Сотрудник*/}
        {/*      </a>*/}
        {/*    </li>*/}
        {/*    <li className={styleTumbler._link}>*/}
        {/*      <a*/}
        {/*        className={*/}
        {/*          styleTumbler._text +*/}
        {/*          ((!!tumbler &&*/}
        {/*              tumbler === "aa" &&*/}
        {/*              " " + styleTumbler.active + " ") ||*/}
        {/*            "")*/}
        {/*        }*/}
        {/*        onClick={() => setTumbler("aa")}*/}
        {/*        style={{*/}
        {/*          cursor: "pointer",*/}
        {/*        }}*/}
        {/*      >*/}
        {/*        Куратор*/}
        {/*      </a>*/}
        {/*    </li>*/}
        {/*    <li className={styleTumbler._link}>*/}
        {/*      <a*/}
        {/*        className={*/}
        {/*          styleTumbler._text +*/}
        {/*          ((!!tumbler &&*/}
        {/*              tumbler === "ab" &&*/}
        {/*              " " + styleTumbler.active + " ") ||*/}
        {/*            "")*/}
        {/*        }*/}
        {/*        onClick={() => setTumbler("ab")}*/}
        {/*        style={{*/}
        {/*          cursor: "pointer",*/}
        {/*        }}*/}
        {/*      >*/}
        {/*        Специалист*/}
        {/*      </a>*/}
        {/*    </li>*/}
        {/*  </ul>*/}
        {/*</div>*/}

        {/*{!!tumbler && tumbler === "ac" && (*/}
        {/*  <>*/}
        {/*    <div className="Form__field Form__field_last">*/}
        {/*      <Select*/}
        {/*        isFixed={true}*/}
        {/*        reference={selectEmployeeId}*/}
        {/*        options={*/}
        {/*          !!listEmployees*/}
        {/*            ? getEmployeesSelectOptions(listEmployees)*/}
        {/*            : getEmployeesSelectOptions([])*/}
        {/*        }*/}
        {/*        label="Сотрудник"*/}
        {/*      />*/}
        {/*    </div>*/}

        {/*    <div className="Form__actions Form__actions_center">*/}
        {/*      <button*/}
        {/*        className="Action__button Action__button_all Action__button_blue"*/}
        {/*        onClick={() => changeAddEmployee()}*/}
        {/*      >*/}
        {/*        Добавить*/}
        {/*      </button>*/}
        {/*    </div>*/}
        {/*  </>*/}
        {/*)}*/}


        {/*{!!tumbler && tumbler === "aa" && (*/}
        {/*  <>*/}
        {/*    <div className="Form__field Form__field_last">*/}
        {/*      <Select*/}
        {/*        isFixed={true}*/}
        {/*        reference={selectEmployeeId}*/}
        {/*        options={*/}
        {/*          !!listEmployees*/}
        {/*            ? getEmployeesSelectOptions(listEmployees)*/}
        {/*            : getEmployeesSelectOptions([])*/}
        {/*        }*/}
        {/*        label="Куратор"*/}
        {/*      />*/}
        {/*    </div>*/}

        {/*    <div className="Form__actions Form__actions_center">*/}
        {/*      <button*/}
        {/*        className="Action__button Action__button_all Action__button_blue"*/}
        {/*        onClick={() => changeAddEmployeeCurator()}*/}
        {/*      >*/}
        {/*        Добавить*/}
        {/*      </button>*/}
        {/*    </div>*/}
        {/*  </>*/}
        {/*)}*/}

        {/*{!!tumbler && tumbler === "ab" && (*/}
        {/*  <>*/}
        {/*    <div className="Form__field Form__field_last">*/}
        {/*      <Select*/}
        {/*        isFixed={true}*/}
        {/*        reference={selectEmployeeId}*/}
        {/*        options={*/}
        {/*          !!listEmployees*/}
        {/*            ? getEmployeesSelectOptions(listEmployees)*/}
        {/*            : getEmployeesSelectOptions([])*/}
        {/*        }*/}
        {/*        label="Специалист"*/}
        {/*      />*/}
        {/*    </div>*/}

        {/*    <div className="Form__actions Form__actions_center">*/}
        {/*      <button*/}
        {/*        className="Action__button Action__button_all Action__button_blue"*/}
        {/*        onClick={() => changeAddEmployeeSpecialist()}*/}
        {/*      >*/}
        {/*        Добавить*/}
        {/*      </button>*/}
        {/*    </div>*/}
        {/*  </>*/}
        {/*)}*/}

      </div>
    </Modal>
  );
}

export default ModalProjectsFilter;
