import React, { useEffect, useRef, useState } from "react";
import { ROOT_URL } from "../../../../../config/url";
import {useList, useAction, useNotification} from "@vokymlak/kabinet-ui";
import axios from "axios";

function useContractStages(props) {
  const [selectStage, setSelectStage] = useState(null);

  const [countActAmount, setCountActAmount] = useState(0);
  const [countPaymentAmount, setCountPaymentAmount] = useState(0);

  const {notification} = useNotification();

  const {
    list: [list, count, setList],
    update: [isUpdateList, setIsUpdateList, updateList],
  } = useList({
    apiUrl: "/api/contract/" + props.contract.uuid + "/stages",
    rootUrl: ROOT_URL,
  });

  useEffect(() => {
    if (!!list) {
      let countActAmount = 0;
      let countPaymentAmount = 0;

      list.forEach((i) => {
        countActAmount += i.count_act_amount;
        countPaymentAmount += i.count_payment_amount;
      });

      setCountActAmount(countActAmount);
      setCountPaymentAmount(countPaymentAmount);
    }
  }, [list]);

  const { setAction } = useAction(props.contract.uuid, () => updateList());

  useEffect(() => {
    if (!!list) {
      const listUuid = list.map((i) => i.uuid);
      setAction([...listUuid, props.contract.uuid]);
    } else {
      setAction(props.contract.uuid);
    }
  }, [list]);

  const [modalStageImport, setModalStageImport] = useState(null);
  const [modalStageAdd, setModalStageAdd] = useState(null);
  const [modalStageEdit, setModalStageEdit] = useState(null);
  const [isDragAndDrop, setIsDragAndDrop] = useState(false);
  const [isDrag, setIsDrag] = useState(false);

  const onDragStart = () => {
    setIsDrag(true);
  };

  const onDragEnd = (result) => {
    setIsDrag(false);

    if (result.destination) {
      const oldDroppableId = result.source.droppableId;
      const newDroppableId = result.destination.droppableId;

      const oldIndex = result.source.index;
      const newIndex = result.destination.index;
      const objectId = result.draggableId;

      if (oldDroppableId !== newDroppableId) {
        return;
      }

      if (!!list) {
        const listArray = [...list];
        let object = null;

        listArray.map((item) => {
          if (objectId === item.uuid) {
            object = { ...item };
          }
        });

        setList([...dndArray(listArray, oldIndex, newIndex)]);

        axios
          .get("/api/contract-stage/" + object.uuid + "/index/" + newIndex, {
            withCredentials: false,
            baseURL: ROOT_URL,
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + localStorage.getItem("tokenSession"),
            },
          })
          .then(() => {
            setIsUpdateList(!isUpdateList);
          })
          .catch(() => {
            setIsUpdateList(!isUpdateList);
          });
      }
    }
  };

  const dndArray = (arr, old_index, new_index) => {
    if (new_index >= arr.length) {
      let k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  };

  return {
    object: props.contract,

    countActAmount,
    countPaymentAmount,

    list,
    setList,
    count,

    setIsUpdateList,
    isUpdateList,

    modalStageAdd,
    setModalStageAdd,

    modalStageEdit,
    setModalStageEdit,

    setModalStageImport,
    modalStageImport,

    selectStage,
    setSelectStage,

    notification,

    isDragAndDrop,
    setIsDragAndDrop,

    onDragEnd,
    onDragStart,
    isDrag,
  };
}

export default useContractStages;
