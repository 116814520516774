import React, { Fragment } from "react";
import moment from "moment";
import {
  getAccountFio,
  TextLink,
} from "@vokymlak/kabinet-ui";

export const ProjectTasksList = (props) => {
  const {
    list,
  } = props;

  return (
    !!list && (
      <Fragment>
        {list.map((item) => (
          <tr
            className="Table__tr Table__tr_padding Table__tr_border Table__tr_hover"
            key={"item_" + item.uuid}
            style={{
              backgroundColor: !!item.date_execution
                ? "#a5d6a7"
                : moment(item.date_control).format("YYYY-MM-DD") ===
                  moment().format("YYYY-MM-DD")
                ? item.is_priority
                  ? "#ffe082"
                  : "inherit"
                : !!moment(
                    moment(item.date_control).format("YYYY-MM-DD")
                  ).isSameOrBefore(moment().format("YYYY-MM-DD"))
                ? "#ef9a9a"
                : item.is_priority
                ? "#ffe082"
                : "inherit",
            }}
          >

            <td className="Table__td Table__td_border">
              <div className="Td__text Td__text_center">
                {!!item.updated_at
                  ? moment(item.updated_at).format("DD.MM.YYYY")
                  : moment(item.created_at).format("DD.MM.YYYY")}
              </div>
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text Td__text_center">
                {(!!item.name && item.name) || "-"}
              </div>
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text Td__text_center">
                {(!!item.note && <TextLink text={item.note} />) || "-"}
              </div>
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text Td__text_center">
                {!!item.date_control
                  ? moment(item.date_control).format("DD.MM.YYYY")
                  : "-"}
              </div>
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text Td__text_center">
                {(!!item.who && getAccountFio(item.who)) || "-"}
              </div>
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text Td__text_center">
                {(!!item.whom && getAccountFio(item.whom)) || "-"}
              </div>
            </td>
          </tr>
        ))}
      </Fragment>
    )
  );
};
