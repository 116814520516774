import React, { useState } from "react";
import axios from "axios";
import {
  Modal,
  getErrorMessageByKey,
  useNotification,
  useObject,
} from "@vokymlak/kabinet-ui";
import {ROOT_URL} from "../../../config/url";

function ModalContractEditWithoutVolume(props) {
  const [isIntermediateExecution, setIsIntermediateExecution] = useState(false);

  const {
    object: [object],
  } = useObject({
    apiUrl: "/api/contract/" + props.contract.uuid,
    rootUrl: ROOT_URL,
    callbackResponse: (data) => {
      setIsIntermediateExecution(!!data && data.is_without_volume);
    },
    callbackError: () => {
      props.handleClose();
    },
  });

  const { notification } = useNotification();

  const changeEdit = (event) => {
    event.preventDefault();

    const jsonData = JSON.stringify({
      is_without_volume: isIntermediateExecution,
    });

    axios
      .put("/api/contract/" + props.contract.uuid + "/edit/without-volume", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Данные сохранены", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    !!object && (
      <Modal handleClose={() => props.handleClose()}>
          <form className="Form" onSubmit={(event) => changeEdit(event)}>
            <div className="Form__title">
              {object.contract_number}
              <div className="Text__signature">Договор</div>
            </div>

            <div className="Form__field">
              <div
                className="Button__swith"
                style={{
                  padding: "8px",
                  width: "100%",
                  border: isIntermediateExecution
                    ? "2px solid #ffe082"
                    : "2px dashed #20202015",
                  // border: "2px dashed #20202015",
                  backgroundColor: isIntermediateExecution
                    ? "#ffe082"
                    : "#ffffff",
                  borderRadius: 6,
                  marginTop: 20,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  cursor: "pointer",
                }}
                onClick={() =>
                  setIsIntermediateExecution(!isIntermediateExecution)
                }
              >
                <div
                  className="Swith__text"
                  style={{
                    fontSize: 17,
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                    // color: isPriority ? "#ff3c00" : "#202020",
                  }}
                >
                  Без объёма
                </div>
                <div
                  className={
                    "Swith Swith_gray" +
                    ((isIntermediateExecution &&
                      " Swith_active Swith_active_gray") ||
                      "")
                  }
                ></div>
              </div>
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_blue">
                Применить
              </button>
            </div>
          </form>
      </Modal>
    )
  );
}

export default ModalContractEditWithoutVolume;
