import React, { useState } from "react";
import styleContent from "../../../../styles/modules/Block.module.scss";
import ModalProjectClientEdit from "./ModalProjectClientEdit";
import {getAccountFio, Icon} from "@vokymlak/kabinet-ui";

function ProjectClientsList(props) {
  const {
    setIsUpdateList,
    isUpdateList,
    object
  } = props

  const [modalObjectEdit, setModalObjectEdit] =
    useState(null);

  return (
    <>
      {!!object.clients && object.clients.length > 0 && (
        <>
          <div
            className={styleContent._block + " " + styleContent._block_bottom}
          >
            <div className={styleContent._block__title}>
              Клиенты{" "}
              {!!object.clients &&
                " (" + object.clients.length + ")"}
            </div>
            <div className={styleContent._block__content}>
              <table className="Table">
                <tbody>
                {object.clients.map((item) => (
                  <tr
                    className="Table__tr Table__tr_hover"
                    key={item.uuid}
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => setModalObjectEdit(item)}
                  >
                    <td className="Table__td" style={{
                      paddingBottom: 12
                    }}>
                      <div style={{
                        display: "flex"
                      }}>
                        <div style={{
                          marginRight: 8,
                          height: 42
                        }}>
                          <Icon
                            name={"custom-account-circle"}
                            size={42}
                            color={"#202020"}
                          />
                        </div>

                        <div
                          className="Td__title"
                          style={{
                            fontSize: 15,
                            fontWeight: 400,
                          }}
                        >
                          <div
                            style={{
                              marginBottom: 4,
                            }}
                          >
                            {getAccountFio(item.client)}
                            <span
                              style={{
                                fontSize: 11,
                                opacity: 0.75,
                                marginLeft: 4
                              }}
                            >
                              {!!item.client.position &&
                                "(" + item.client.position + ")"}
                            </span>
                          </div>

                          {!!item.client.login && (
                            <div
                              style={{
                                fontSize: 11,
                                fontWeight: 700,
                                backgroundColor: "#202020",
                                display: "inline-block",
                                color: "white",
                                padding: "2px 6px",
                                borderRadius: 24,
                                marginRight: 4,
                              }}
                            >
                              {item.client.login}
                            </div>
                          )}

                          {!!item.client.email && (
                            <div
                              style={{
                                fontSize: 11,
                                fontWeight: 700,
                                backgroundColor: "#202020",
                                display: "inline-block",
                                color: "white",
                                padding: "2px 6px",
                                borderRadius: 24,
                                marginRight: 4,
                              }}
                            >
                              {item.client.email}
                            </div>
                          )}
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
          </div>

          {!!modalObjectEdit && (
            <ModalProjectClientEdit
              object={modalObjectEdit}
              successfulRequest={() => setIsUpdateList(!isUpdateList)}
              handleClose={() => setModalObjectEdit(null)}
            />
          )}
        </>
      )}
    </>
  );
}

export default ProjectClientsList;
