import React from "react";
import { Icon } from "@vokymlak/kabinet-ui";
import { GeneralTaskProjectsList } from "./GeneralTaskProjectsList";

export const GeneralTaskProjectsTable = (props) => {
  const {
    item,

    setIsUpdateList,
    isUpdateList,
  } = props;

  return (
    <table className="Table">
      <thead className="Thead">
        <tr className="Table__tr Table__tr_padding Table__tr_border">
          <th
            className="Table__th Table__th_border"
            style={{
              backgroundColor: "#dfdfdf",
              width: "0%",
            }}
          >
            <div
              className="Th__title"
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Icon name={"custom-priority"} size={12} color={"#202020"} />
            </div>
          </th>
          <th
            className="Table__th Table__th_border"
            style={{
              backgroundColor: "#dfdfdf",
            }}
          >
            <div
              className="Th__title"
              style={{
                textAlign: "center",
              }}
            >
              Обновлено
            </div>
          </th>
          <th
            className="Table__th Table__th_border"
            style={{
              backgroundColor: "#dfdfdf",
            }}
          >
            <div
              className="Th__title"
              style={{
                textAlign: "center",
              }}
            >
              Проект
            </div>
          </th>
          <th
            className="Table__th Table__th_border"
            style={{
              backgroundColor: "#dfdfdf",
            }}
          >
            <div
              className="Th__title"
              style={{
                textAlign: "center",
              }}
            >
              Примечание
            </div>
          </th>
          <th
            className="Table__th Table__th_border"
            style={{
              backgroundColor: "#dfdfdf",
            }}
          >
            <div
              className="Th__title"
              style={{
                textAlign: "center",
              }}
            >
              Дата исполнения
            </div>
          </th>
          <th
            className="Table__th Table__th_border"
            style={{
              backgroundColor: "#dfdfdf",
            }}
          >
            <div
              className="Th__title"
              style={{
                textAlign: "center",
              }}
            >
              Отметка
            </div>
          </th>
          <th
            className="Table__th Table__th_border"
            style={{
              backgroundColor: "#dfdfdf",
              width: "0%",
            }}
          >
            <div
              className="Th__title"
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              -
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <GeneralTaskProjectsList
          list={item.general_task_projects}
          generalTask={item}
          successfulRequest={() => setIsUpdateList(!isUpdateList)}
        />
      </tbody>
    </table>
  );
};
