import React, { Fragment, useEffect, useState } from "react";
import {Tooltip, Loading, Icon} from "@vokymlak/kabinet-ui";
import ModalControlPointAdd from "./ModalControlPointAdd";
import ModalControlPointEdit from "./ModalControlPointEdit";
import styleContent from "../../../styles/modules/Block.module.scss";
import styleCard from "../../../styles/modules/Card.module.scss";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";

export const StrictModeDroppable = ({ children, ...props }) => {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  if (!enabled) {
    return null;
  }

  return <Droppable {...props}>{children}</Droppable>;
};

function ControlPointsList(props) {
  const {
    list,
    setList,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    setIsUpdateList,
    isUpdateList,

    modalItemEdit,
    setModalItemEdit,
    isModalObjectAdd,
    setIsModalObjectAdd,

    onDragEndDrop,
  } = props;

  return (
    <ScrollBlock
      style={{ width: '100%', height: '100%' }}>
    <div className="Page">
      <div className="Page__title">
        <div
          className="Title__text"
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: 27,
            fontWeight: 700,
          }}
        >
          Точки контроля{!!list && " (" + list.length + ")"}
        </div>

        <div className="Title__settings">
          {!!list && (
            <div
              style={{
                margin: "0 12px",
              }}
              className="Button__text"
              onClick={() => changeAllUnroll(list, "uuid")}
            >
              {list.length === unrolls.length ? "Свернуть" : "Развернуть"}
            </div>
          )}

          <div
            style={{
              margin: "0 12px",
            }}
            className="Button__text"
            onClick={() => (setList(null), setIsUpdateList(!isUpdateList))}
          >
            Обновить
          </div>

          <Tooltip text={"Добавить"} style={{ marginLeft: 24 }}>
            <div
              className="Button__icon Button__icon_42"
              onClick={() => setIsModalObjectAdd(true)}
            >
              <Icon name={"custom-add"} size={42} color={""} />
            </div>
          </Tooltip>
        </div>
      </div>

      <div className="Page__content">
        <ul className="List">
          {!list && (
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                flexWrap: "nowrap",
                alignItems: "center",
                justifyContent: "center",
                padding: "100px 0",
              }}
            >
              <Loading />
            </div>
          )}

          <DragDropContext onDragEnd={onDragEndDrop}>
            <StrictModeDroppable droppableId={"droppable"}>
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {!!list &&
                    list.map((item, index) => (
                      <Draggable
                        draggableId={String(item.uuid)}
                        index={index}
                        key={"draggable_" + item.uuid}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.dragHandleProps}
                            {...provided.draggableProps}
                          >
                            <Fragment key={"item_" + item.uuid}>
                              <li
                                className={
                                  isUnroll(item.uuid)
                                    ? "List__item active"
                                    : "List__item"
                                }
                                onClick={() => changeUnroll(item.uuid)}
                              >
                                <div className="Item__title">
                                  <div className="Element__title">
                                    {index + 1}
                                    {" | "}
                                    {item.name}
                                  </div>
                                </div>
                                <div className="Item__settings">
                                  <div className="Element__datetime"></div>
                                </div>
                              </li>

                              {isUnroll(item.uuid) && (
                                <li className="List__content">
                                  <div className={styleContent._block}>
                                    <div
                                      className={styleContent._block__content}
                                    >
                                      <div className={styleCard._block}>
                                        <div
                                          className={styleCard._block__number}
                                        >
                                          {!!item.control_point_purchase_basis
                                            ? item.control_point_purchase_basis
                                              .length
                                            : "0"}
                                        </div>
                                        <div
                                          className={styleCard._block__title}
                                        >
                                          Условия
                                        </div>
                                      </div>
                                      {/*<div className={styleCard._block}>*/}
                                      {/*  <div*/}
                                      {/*    className={styleCard._block__number}*/}
                                      {/*  >*/}
                                      {/*    {!!item.control_point_contracts*/}
                                      {/*      ? item.control_point_contracts*/}
                                      {/*          .length*/}
                                      {/*      : "0"}*/}
                                      {/*  </div>*/}
                                      {/*  <div*/}
                                      {/*    className={styleCard._block__title}*/}
                                      {/*  >*/}
                                      {/*    Договоры*/}
                                      {/*  </div>*/}
                                      {/*</div>*/}
                                    </div>
                                  </div>

                                  <div className="Content__footer">
                                    <div className="Footer__title"></div>
                                    <div className="Footer__settings">
                                      <Tooltip
                                        text={"Редактировать"}
                                        style={{ marginLeft: 24 }}
                                      >
                                        <div
                                          className="Button__icon Button__icon_42"
                                          onClick={() => setModalItemEdit(item)}
                                        >
                                          <Icon
                                            name={"custom-edit"}
                                            size={42}
                                            color={""}
                                          />
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </div>
                                </li>
                              )}
                            </Fragment>
                          </div>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </StrictModeDroppable>
          </DragDropContext>
        </ul>
      </div>

      {!!modalItemEdit && (
        <ModalControlPointEdit
          controlPoint={modalItemEdit}
          successfulRequest={() => setIsUpdateList(!isUpdateList)}
          handleClose={() => setModalItemEdit(null)}
        />
      )}
      {!!isModalObjectAdd && (
        <ModalControlPointAdd
          successfulRequest={() => setIsUpdateList(!isUpdateList)}
          handleClose={() => setIsModalObjectAdd(false)}
        />
      )}
    </div>
    </ScrollBlock>
  );
}

export default ControlPointsList;
