import React, {Fragment, useState} from "react";
import {NavLink} from "react-router-dom";
import moment from "moment";
import {getAccountFio, Icon, TextSearchMarker, Tooltip} from "@vokymlak/kabinet-ui";
import ModalControlPointExecute from "../../ScreenObjects/Project/components/ProjectContracts/ModalControlPointExecute";

export const ErrorExecutionContractsList = (props) => {
  const {
    list,
    searchQuery,
  } = props
  const [modalControlPointExecute, setModalControlPointExecute] =
    useState(null);

  function truncateString(str) {
    if (str.length <= 260) {
      return str; // возвращает строку без изменений, если количество символов не превышает 160
    } else {
      return str.slice(0, 260) + '...'; // обрезает строку до 160 символов и добавляет троеточие
    }
  }

  return (
    !!list && (
      <Fragment>
        {list.map((item) => (
          <tr
            className="Table__tr Table__tr_padding Table__tr_border Table__tr_hover"
            key={"item_" + item.uuid}
          >
            <td className="Table__td Table__td_border">
              <div
                className="Td__text Td__text_center"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {!!item.project && (
                  <NavLink
                    to={
                      "/project/" +
                      item.project.uuid +
                      "/contracts?search=" +
                      item.uuid
                    }
                    target={"_blank"}>
                    <Icon
                      name={"custom-circle-arrow-right"}
                      size={20}
                      color={"#202020"}
                    />
                  </NavLink>
                )}
              </div>
            </td>

            <td
              className="Table__td Table__td_border"
              style={{
                maxWidth: 140,
              }}
            >
              <div
                className="Td__text Td__text_center"
                style={{
                  maxWidth: 140,
                }}
              >
                {(!!item.project &&
                    item.project.name) ||
                  "-"}
              </div>
            </td>

            <td
              className="Table__td Table__td_border"
              style={{
                backgroundColor: "#fff9c450",
              }}
            >
              <Tooltip
                text={"Обновлено"}
                style={{width: "100%", height: "100%"}}
              >
                <div className="Td__text Td__text_center">
                  {!!item.updated_at
                    ? moment(item.updated_at).format("DD.MM.YYYY HH:mm")
                    : moment(item.created_at).format("DD.MM.YYYY HH:mm")}
                  <div style={{
                    fontSize: 13,
                    opacity: .75
                  }}>
                    ({moment(item.created_at).format("DD.MM.YYYY HH:mm")})
                  </div>
                </div>
              </Tooltip>
            </td>

            <td
              className="Table__td Table__td_border"
              style={{
                minWidth: 100,
              }}
            >
              <Tooltip
                text={"Номер договора"}
                style={{width: "100%", height: "100%"}}
              >
                <div className="Td__text Td__text_center Td__text_break">
                  {(!!item.contract_number && (
                      <TextSearchMarker
                        text={item.contract_number}
                        search={searchQuery}
                      />
                    )) ||
                    "-"}
                </div>
              </Tooltip>
            </td>
            <td
              className="Table__td Table__td_border"
            >
              <Tooltip
                text={"Реестровый номер"}
                style={{width: "100%", height: "100%"}}
              >
                <div className="Td__text Td__text_center">
                  {(!!item.registry_number && (
                      <TextSearchMarker
                        text={item.registry_number}
                        search={searchQuery}
                      />
                    )) ||
                    "-"}
                </div>
                <div className="Td__text Td__text_center" style={{
                  fontSize: 10,
                  fontWeight: 400
                }}>
                  {(!!item.ris_id && item.ris_id) ||
                    "-"} / {(!!item.ris_entity_id && item.ris_entity_id) ||
                  "-"}
                </div>
              </Tooltip>
            </td>

            <td
              className="Table__td Table__td_border"
              style={{
                cursor: "pointer",
              }}
            >
              <Tooltip
                text={"Дата заключения"}
                style={{width: "100%", height: "100%"}}
              >
                <div className="Td__text Td__text_center">
                  {(!!item.date_viewed &&
                      moment(item.date_viewed).format("DD.MM.YYYY")) ||
                    "-"}
                </div>
              </Tooltip>
            </td>
            <td
              className="Table__td Table__td_border Table__td_sticky"
              style={{
                cursor: "pointer",
                backgroundColor: "#f1f1f1",
                maxWidth: 140,
              }}
            >
              <Tooltip text={"Предмет"} style={{width: 190, height: "100%"}}>
                <div className="Td__text Td__text_center">
                  {(!!item.subject && (
                      <TextSearchMarker
                        text={item.subject}
                        search={searchQuery}
                      />
                    )) ||
                    "-"}
                </div>
              </Tooltip>
            </td>
            <td
              className="Table__td Table__td_border"
              style={{
                backgroundColor: "#bdbdbd50",
              }}
            >
              <Tooltip
                text={"Цена договора"}
                style={{width: "100%", height: "100%"}}
              >
                <div className="Td__text Td__text_center">
                  {(item.amount !== null &&
                      new Intl.NumberFormat("ru-RU").format(
                        (item.amount / 100).toFixed(2)
                      )) ||
                    "-"}
                </div>
              </Tooltip>
            </td>
            <td
              className="Table__td Table__td_border"
              style={{
                backgroundColor: "#673ab733",
                maxWidth: 140,
              }}
            >
              <Tooltip
                text={"Комментарий по исполнению"}
                style={{width: "100%", height: "100%"}}
              >
                <div className="Td__text Td__text_center">
                  {(!!item.ct_execution_note && (
                      <TextSearchMarker
                        text={item.ct_execution_note}
                        search={searchQuery}
                      />
                    )) ||
                    "-"}
                </div>
              </Tooltip>
            </td>

            <td
              className="Table__td Table__td_border"
            >
              <Tooltip
                text={"Поставщик"}
                style={{width: "100%", height: "100%"}}
              >
                <div className="Td__text Td__text_center">
                  {(!!item.supplier && (
                      <TextSearchMarker
                        text={item.supplier}
                        search={searchQuery}
                      />
                    )) ||
                    "-"}
                </div>
              </Tooltip>
            </td>

            {/* ЭД Договор зарегистрирован */}
            <td
              className="Table__td Table__td_border"
              style={{
                backgroundColor: !!item.contract_registration_status
                  ? item.contract_registration_status.tag === "REMARK_ISSUED"
                    ? "#ff572290"
                    : item.contract_registration_status.tag === "NOT_REGISTERED"
                      ? "#ffc10790"
                      : item.contract_registration_status.tag === "NOT_REQUIRED"
                        ? "#bdbdbd50"
                        : "#bbdefb50"
                  : "#bbdefb50",
              }}
            >
              <div className="Td__text Td__text_center">
                {(!!item.contract_registration_status && (
                    <TextSearchMarker
                      text={item.contract_registration_status.name.toUpperCase()}
                      search={searchQuery}
                    />
                  )) ||
                  "-"}
              </div>
            </td>

            {/* Ошибка регистрации */}
            <td
              className="Table__td Table__td_border"
              style={{
                backgroundColor: "#bbdefb50",
              }}
            >
              <div className="Td__text Td__text_center" style={{
                maxWidth: 140,
              }}>
                {(!!item.registration_error && (
                    <TextSearchMarker
                      text={truncateString(item.registration_error)}
                      search={searchQuery}
                    />
                  )) ||
                  "-"}
              </div>
            </td>

            <>
              {/* Ошибка регистрации */}
              <td
                className="Table__td Table__td_border"
                style={{
                  backgroundColor: "#bbdefb50",
                  maxWidth: 140,
                }}
              >
                <div className="Td__text Td__text_center">
                  {(!!item.registration_error_note && (
                      <TextSearchMarker
                        text={item.registration_error_note}
                        search={searchQuery}
                      />
                    )) ||
                    "-"}
                </div>
              </td>
            </>

            <td
              className="Table__td Table__td_border"
              style={{
                backgroundColor: "#c5cae950",
                maxWidth: 140,
              }}
            >
              <Tooltip
                text={"Примечание"}
                style={{width: "100%", height: "100%"}}
              >
                <div className="Td__text Td__text_center">
                  {(!!item.note && (
                      <TextSearchMarker
                        text={item.note}
                        search={searchQuery}
                      />
                    )) ||
                    "-"}
                </div>
              </Tooltip>
            </td>

            <td
              className="Table__td Table__td_border"
              style={{
                backgroundColor: "#ffd54f50",
              }}
            >
              <Tooltip
                text={"Статус"}
                style={{width: "100%", height: "100%"}}
              >
                <div className="Td__text Td__text_center">
                  {(!!item.contract_status && (
                      <TextSearchMarker
                        text={item.contract_status.name}
                        search={searchQuery}
                      />
                    )) ||
                    "-"}
                </div>
              </Tooltip>
            </td>
            <td
              className="Table__td Table__td_border"
              style={{
                backgroundColor: "#ffd54f50",
              }}
            >
              <Tooltip
                text={"Срок действия"}
                style={{width: "100%", height: "100%"}}
              >
                <div className="Td__text Td__text_center">
                  {(!!item.date_validity &&
                      moment(item.date_validity).format("DD.MM.YYYY")) ||
                    "-"}
                </div>
              </Tooltip>
            </td>

          </tr>
        ))}

        {!!modalControlPointExecute && (
          <ModalControlPointExecute
            contractControlPoint={modalControlPointExecute}
            successfulRequest={props.successfulRequest}
            handleClose={() => setModalControlPointExecute(null)}
          />
        )}
      </Fragment>
    )
  );
};
