import React from "react";
import styles from "./Viewer.module.scss";
import {Icon, Loading} from "@vokymlak/kabinet-ui";

const ViewerView = (props) => {
  const { file, mimeType, viewerClose, isLoading } = props;

  return (
    (!!file || isLoading) && (
      <div className={styles._block}>
        <div className={styles._mask} onClick={() => viewerClose()} />
        <div className={styles._close} onClick={() => viewerClose()}>
          <div className={styles._close__button}>
            <Icon name={"custom-close"} color={"#ffffff"} size={24} />
          </div>
        </div>

        {!!file ? (
          <img className={styles._image} src={`data:${mimeType};base64, ${file}`} />
        ) : isLoading ? (
          <div className={styles._load}>
            <Loading color={"white"} />
          </div>
        ) : null}
      </div>
    )
  );
};

export default ViewerView;
