import React from "react";
import ProjectContractsWrapper from "./ProjectContractsWrapper";
import { useList } from "@vokymlak/kabinet-ui";
import { ROOT_URL } from "../../../../../config/url";

function ProjectContracts(props) {
  const {
    list: [listContractGroups],
  } = useList({
    apiUrl: "/api/contract-groups",
    rootUrl: ROOT_URL,
  });

  const {
    list: [listControlPoints],
  } = useList({
    apiUrl: "/api/control-points",
    rootUrl: ROOT_URL,
  });

  const {
    list: [listPurchaseBasis],
  } = useList({
    apiUrl: "/api/purchase-basis",
    rootUrl: ROOT_URL,
  });

  return (
    !!listContractGroups &&
    !!listControlPoints &&
    !!listPurchaseBasis && (
      <ProjectContractsWrapper
        {...props}
        listContractGroups={listContractGroups}
        listControlPoints={listControlPoints}
        listPurchaseBasis={listPurchaseBasis}
      />
    )
  );
}

export default ProjectContracts;
