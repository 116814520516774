import React, {useRef, useState} from "react";
import axios from "axios";
import {
  getErrorMessageByKey,
  Input,
  isValidateNullText,
  Modal,
  Select,
  useList,
  useNotification,
  useObject,
} from "@vokymlak/kabinet-ui";
import {ROOT_URL} from "../../../config/url";
import moment from "moment";

function ModalImportContractsScheduler(props) {
  const {notification} = useNotification();

  const [isWork, setIsWork] = useState(false);
  const [isTimer, setIsTimer] = useState(false);
  const selectCompanyAuthorizeUuid = useRef(null);

  const {
    object: [project],
  } = useObject({
    apiUrl: "/api/project/" + props.project.uuid,
    rootUrl: ROOT_URL,
    callbackError: () => {
      props.handleClose();
    },
  });

  const {
    object: [object],
  } = useObject({
    apiUrl: "api/project/" + props.project.uuid + "/ris-scheduler",
    rootUrl: ROOT_URL,
    callbackResponse: (data) => {
      setIsWork(!!data && data.is_work);
      setIsTimer(!!data && data.is_timer);
    },
    callbackError: () => {
      props.handleClose();
    },
  });

  const {
    list: [list],
    update: [_isUpdateList, _setIsUpdateList, updateList],
  } = useList({
    apiUrl: "/api/company/" + ((!!props.project.company && props.project.company.uuid) || "null") + "/authorize",
    rootUrl: ROOT_URL,
    callbackError: () => {
      notification("Не указана компания в проекте", "red");
      props.handleClose();
    },
  });

  const inputBreakTime = useRef(null);

  const changeEdit = (event) => {
    event.preventDefault();

    const breakTime = inputBreakTime.current.value;
    const companyAuthorizeUuid = selectCompanyAuthorizeUuid.current.value;

    if (isValidateNullText(companyAuthorizeUuid) || companyAuthorizeUuid === "null") {
      notification("Выберите данные авторизации", "red");
      return;
    }

    const jsonData = JSON.stringify({
      break_time: Number(breakTime),
      company_authorize: companyAuthorizeUuid.length > 0 && companyAuthorizeUuid !== "null" ? {
        uuid: companyAuthorizeUuid
      } : null,
      is_work: isWork,
      // is_timer: isTimer,
      is_timer: false,
    });

    axios
      .put("/api/project/" + props.project.uuid + "/ris-scheduler/edit", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Данные сохранены", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const getSelectOptions = (list) => {
    const options = [{
      value: "null",
      title: "Не указан",
    }];

    list.map((item) => {
      if (item.authorize && item.authorize.authorize_type && item.authorize.authorize_type.tag === "RIS") {
        const option = {
          value: item.uuid,
          title: item.authorize.login,
        };

        options.push(option);
      }
    });

    return options;
  };


  return (
    !!project && !!object && (
      <Modal handleClose={() => props.handleClose()}>
        <form className="Form" onSubmit={(event) => changeEdit(event)}>
          <div className="Form__title">
            {project.name}
            <div className="Text__signature">Планировщик интеграции с РИС</div>
          </div>

          {project.is_import_by_numbers && (
            <div className="Form__field">
              <div
                style={{
                  padding: "8px",
                  backgroundColor: "#20202015",
                  borderRadius: 6,
                  fontSize: 15,
                  fontWeight: 400,
                }}
              >
                Импорт договоров происходит только по номерам РИС
              </div>
            </div>
          )}

          <div className="Form__field">
            <div
              className="Button__swith"
              style={{
                padding: "8px",
                width: "100%",
                border: isWork
                  ? "2px solid #ffe082"
                  : "2px dashed #20202015",
                backgroundColor: isWork ? "#ffe082" : "#ffffff",
                borderRadius: 6,
                marginTop: 20,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                cursor: "pointer",
              }}
              onClick={() => setIsWork(!isWork)}
            >
              <div
                className="Swith__text"
                style={{
                  fontSize: 17,
                  fontWeight: 600,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                В работе
              </div>
              <div
                className={
                  "Swith Swith_gray" +
                  ((isWork && " Swith_active Swith_active_gray") || "")
                }
              ></div>
            </div>
          </div>

          {/*<div className="Form__field">*/}
          {/*  <div*/}
          {/*    className="Button__swith"*/}
          {/*    style={{*/}
          {/*      padding: "8px",*/}
          {/*      width: "100%",*/}
          {/*      border: isTimer*/}
          {/*        ? "2px solid #ffe082"*/}
          {/*        : "2px dashed #20202015",*/}
          {/*      backgroundColor: isTimer ? "#ffe082" : "#ffffff",*/}
          {/*      borderRadius: 6,*/}
          {/*      marginTop: 20,*/}
          {/*      display: "flex",*/}
          {/*      alignItems: "center",*/}
          {/*      justifyContent: "space-between",*/}
          {/*      cursor: "pointer",*/}
          {/*    }}*/}
          {/*    onClick={() => setIsTimer(!isTimer)}*/}
          {/*  >*/}
          {/*    <div*/}
          {/*      className="Swith__text"*/}
          {/*      style={{*/}
          {/*        fontSize: 17,*/}
          {/*        fontWeight: 600,*/}
          {/*        display: "flex",*/}
          {/*        alignItems: "center",*/}
          {/*      }}*/}
          {/*    >*/}
          {/*      Как таймер*/}
          {/*    </div>*/}
          {/*    <div*/}
          {/*      className={*/}
          {/*        "Swith Swith_gray" +*/}
          {/*        ((isTimer && " Swith_active Swith_active_gray") || "")*/}
          {/*      }*/}
          {/*    ></div>*/}
          {/*  </div>*/}
          {/*</div>*/}

          {/*<div className="Form__field">*/}
          {/*  <Input reference={inputBreakTime}*/}
          {/*         type="number"*/}
          {/*         value={object.break_time}*/}
          {/*         label="Время перерыва в секундах"/>*/}
          {/*</div>*/}


          {!isTimer && (
            <div className="Form__field">
              <Select
                reference={inputBreakTime}
                isFixed={true}
                options={[
                  {
                    value: 60 * 60,
                    title: "1 час ночи",
                  },
                  {
                    value: 60 * 60 * 2,
                    title: "2 часа ночи",
                  },
                  {
                    value: 60 * 60 * 3,
                    title: "3 часа ночи",
                  },
                  // {
                  //   value: 60 * 60 * 4,
                  //   title: "4 часа ночи",
                  // },
                  // {
                  //   value: 60 * 60 * 5,
                  //   title: "5 часов ночи",
                  // },
                  // {
                  //   value: 60 * 60 * 6,
                  //   title: "6 часов ночи",
                  // },
                ]}
                label="По времени в "
                value={
                  !!object.break_time
                    ? object.break_time
                    : 60 * 60
                }
              />
            </div>
          )}

          {/*{isTimer && (*/}
          {/*<div className="Form__field">*/}
          {/*  <Select*/}
          {/*    reference={inputBreakTime}*/}
          {/*    isFixed={true}*/}
          {/*    options={[*/}
          {/*      // {*/}
          {/*      //   value: 60 * 60,*/}
          {/*      //   title: "60 минут",*/}
          {/*      // },*/}
          {/*      {*/}
          {/*        value: 60 * 60 * 4,*/}
          {/*        title: "4 часа",*/}
          {/*      },*/}
          {/*      {*/}
          {/*        value: 60 * 60 * 6,*/}
          {/*        title: "6 часов",*/}
          {/*      },*/}
          {/*      {*/}
          {/*        value: 60 * 60 * 8,*/}
          {/*        title: "8 часов",*/}
          {/*      },*/}
          {/*      {*/}
          {/*        value: 60 * 60 * 12,*/}
          {/*        title: "12 часов",*/}
          {/*      },*/}
          {/*    ]}*/}
          {/*    label="Каждые"*/}
          {/*    value={*/}
          {/*      !!object.break_time*/}
          {/*        ? object.break_time*/}
          {/*        : 60 * 60*/}
          {/*    }*/}
          {/*  />*/}
          {/*</div>*/}
          {/*)}*/}

          <div className="Form__field">
            <Select
              reference={selectCompanyAuthorizeUuid}
              isFixed={true}
              options={!!list ? getSelectOptions(list) : getSelectOptions([])}
              label="Данные авторизации *"
              value={
                !!object.company_authorize && !object.company_authorize.deleted_at
                  ? object.company_authorize.uuid
                  : null
              }
            />
          </div>

          <div className="Form__field Form__field_last">
            <div className="Field__label">
              Дата попытки
            </div>
            <div className="Field__text">
              {(!!object.attempt_date && moment(object.attempt_date.substring(0, 19)).format('DD.MM.YYYY HH:mm:ss')) || "-"}
            </div>
          </div>

          <div className="Form__actions Form__actions_center">
            <button className="Action__button Action__button_all Action__button_blue">
              Применить
            </button>
          </div>
        </form>
      </Modal>
    )
  );
}

export default ModalImportContractsScheduler;
