import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import {Accounts} from "./ScreenAccounts/Accounts";
import {
  ROLE_ADMINISTRATOR,
  ROLE_CLIENT,
  ROLE_DIRECTOR,
  ROLE_EMPLOYEE,
} from "../config/constants";
import { Clients } from "./ScreenAccounts/Clients";
import Companies from "./ScreenObjects/Companies/Companies";
import { Projects } from "./ScreenObjects/Projects";
import { Project } from "./ScreenObjects/Project";
import Tasks from "./ScreenTasks/Tasks/Tasks";
import { Employees } from "./ScreenAccounts/Employees";
import {MyProjects} from "./ScreenMy/MyProjects";
import {MyProject} from "./ScreenMy/MyProject";
import { GeneralTasks } from "./ScreenTasks/GeneralTasks";
import {
  useProfile,
  isProfileRoleTypeTags,
} from "@vokymlak/kabinet-ui";
import { SettingsRouting } from "./ScreenSettings/SettingsRouting";
import SettingsView from "./ScreenSettings/SettingsView/SettingsView";
import { Contract } from "./ScreenObjects/Contract";
import {FnsCertificates} from "./ScreenCertificates/FnsCertificates";
import {HandbookRouting} from "./modules/ScreenHandbook/HandbookRouting";
import {HandbookClientRouting} from "./ScreenHandbookClient/HandbookClientRouting";
import {MyAppeals} from "./ScreenMy/MyAppeals";
import {RisParser} from "./ScreenParser/RisParser";
import {Calculator} from "./ScreenCalculator/Calculator";
import {MyContract} from "./ScreenMy/MyContract";
import {AppealsRouting} from "./ScreenAppeals/AppealsRouting";
import {TicketsRouting} from "./modules/ScreenTickets/TicketsRouting";
import {MyMarkContracts} from "./ScreenMy/MyMarkContracts";
import {Home} from "./ScreenHome/Home";
import {ExecutionTasks} from "./ScreenTasks/ExecutionTasks";
import {ErrorExecutionContracts} from "./ScreenTasks/ErrorExecutionContracts";

export const Routing = () => {
  const { profile } = useProfile();

  return (
    !!profile && (
    <Routes>
      {isProfileRoleTypeTags(profile, [ROLE_CLIENT]) && (
        <>
          <Route path="/my/projects" element={<MyProjects />} />
          <Route path="/my/project/:projectUuid/*" element={<MyProject />} />
          <Route path="/my/contract/:contractUuid/*" element={<MyContract />} />
          <Route path="/my/contracts" element={<MyMarkContracts />} />
          <Route path="/my/appeals" element={<MyAppeals />} />
          <Route path={"/handbook/*"} element={<HandbookClientRouting />} />
        </>
      )}

      {isProfileRoleTypeTags(profile, [
        ROLE_ADMINISTRATOR,
        ROLE_DIRECTOR,
        ROLE_EMPLOYEE,
      ]) && (
        <>
          <Route path="/home" element={<Home />} />
          <Route path="/employees" element={<Employees />} />
          <Route path="/clients" element={<Clients />} />

          <Route path="/general-tasks" element={<GeneralTasks />} />
          <Route path="/tasks" element={<Tasks />} />
          <Route path="/execution-tasks" element={<ExecutionTasks />} />
          <Route path="/error-execution-contracts" element={<ErrorExecutionContracts />} />

          <Route path="/companies" element={<Companies />} />
          <Route path="/fns-certificates" element={<FnsCertificates />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/project/:projectUuid/*" element={<Project />} />
          <Route
            path="/contract/:contractUuid/*"
            element={<Contract />}
          />

          <Route path="/tickets/*" element={<TicketsRouting />} />

          <Route path="/settings-view" element={<SettingsView />} />

          <Route path={"/handbook/*"}
                 element={<HandbookRouting />} />
          <Route path="/appeals/*" element={<AppealsRouting />} />

          {/*<Route path="/appeals-all" element={<Appeals />} />*/}

          <Route path={"/calculator"} element={<Calculator />} />

          {isProfileRoleTypeTags(profile, [
            ROLE_ADMINISTRATOR,
            ROLE_DIRECTOR,
          ]) && (
            <>
              <Route path="/settings/*" element={<SettingsRouting />} />

              {isProfileRoleTypeTags(profile, [ROLE_ADMINISTRATOR]) && (
                <>
                  <Route path="/accounts" element={<Accounts />} />
                </>
              )}
            </>
          )}
        </>
      )}

      {isProfileRoleTypeTags(profile, [ROLE_CLIENT]) && (
        <Route
          exact
          path="/"
          element={<Navigate to="/my/projects" replace />}
        />
      )}

      {isProfileRoleTypeTags(profile, [
        ROLE_DIRECTOR,
        ROLE_EMPLOYEE,
      ]) && (
        <>
          <Route
            exact
            path="/"
            element={<Navigate to="/home" replace />}
          />
        </>
      )}

      {isProfileRoleTypeTags(profile, [ROLE_ADMINISTRATOR]) && (
        <>
        <Route
          exact
          path="/"
          element={<Navigate to="/accounts" replace />}
        />

          <Route path="/parser-ris" element={<RisParser />} />
          </>
      )}

      <Route path="/login" element={<Navigate to="/" />} />
    </Routes>
  )
  );
};
