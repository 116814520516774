import { VIEWER_CLEAN, VIEWER_FILE, VIEWER_LOAD } from "../actionTypes";

export const initialState = {
  isLoading: false,
  file: null,
  mimeType: null,
};

export const viewerReducer = (initialState, action) => {
  switch (action.type) {
    case VIEWER_LOAD:
      return {
        ...initialState,
        isLoading: true,
      };
    case VIEWER_FILE:
      return {
        ...initialState,
        file: action.file,
        mimeType: action.mimeType,
        isLoading: false,
      };
    case VIEWER_CLEAN:
      return {
        ...initialState,
        file: null,
        mimeType: null,
        isLoading: false,
      };
    default:
      return initialState;
  }
};
