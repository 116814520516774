import React from "react";
import ContractActsWrapper from "./ContractActsWrapper";

function ContractActs(props) {
  return (
      <ContractActsWrapper
        {...props}
      />
  );
}

export default ContractActs;
