import React from "react";
import { BrowserRouter } from "react-router-dom";
import "@vokymlak/kabinet-ui/dist/esm/index.css";
import { Screen } from "./screens/Screen";
import { Kabinet } from "@vokymlak/kabinet-ui";
import { ROOT_URL, WS_URL } from "./config/url";
import {ViewerProvider} from "./components/Viewer";

function App() {
  return (
    <Kabinet rootUrl={ROOT_URL} wsUrl={WS_URL}>
      <ViewerProvider>
        <BrowserRouter>
          <Screen />
        </BrowserRouter>
      </ViewerProvider>
    </Kabinet>
  );
}

export default App;
