import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AuthContext,
  authLogout,
  useProfile,
  getAccountFio,
  useAction,
  profileInit,
  ProfileContext,
  isProfileRoleTypeTags, ButtonIcon, Icon,
} from "@vokymlak/kabinet-ui";
import ModalProfile from "../../Profile/ModalProfile";
import "./MenuVertical.scss";
import {
  ROLE_CLIENT,
} from "../../../config/constants";
import { ScrollBlock } from "../../../components/ScrollBlock/ScrollBlock";
import { Avatar } from "../../../components/Avatar/Avatar";
import { ROOT_URL } from "../../../config/url";
import MenuRouting from "../MenuRouting";
import ModalLogout from "../ModalLogout";

const getPhoneNumber = (value) => {
  if (!value) {
    value = '';
  }
  return value.replace(/\D/g, '').length > 11
    ? value.replace(/\D/g, '').substring(0, 11)
    : value.replace(/\D/g, '');
};

// Функция для форматирования номера телефона
const formatPhoneNumber = (value) => {
  if (value.length === 0 || value.length === 1) {
    return '+7 (';
  } else if (value.length === 2) {
    return '+7 (' + value.substring(1, 2);
  } else if (value.length === 3) {
    return '+7 (' + value.substring(1, 3);
  } else if (value.length === 4) {
    return '+7 (' + value.substring(1, 4);
  } else if (value.length === 5) {
    return '+7 (' + value.substring(1, 4) + ') ' + value.substring(4, 5);
  } else if (value.length === 6) {
    return '+7 (' + value.substring(1, 4) + ') ' + value.substring(4, 6);
  } else if (value.length === 7) {
    return '+7 (' + value.substring(1, 4) + ') ' + value.substring(4, 7);
  } else if (value.length === 8) {
    return (
      '+7 (' +
      value.substring(1, 4) +
      ') ' +
      value.substring(4, 7) +
      '-' +
      value.substring(7, 8)
    );
  } else if (value.length === 9) {
    return (
      '+7 (' +
      value.substring(1, 4) +
      ') ' +
      value.substring(4, 7) +
      '-' +
      value.substring(7, 9)
    );
  } else if (value.length === 10) {
    return (
      '+7 (' +
      value.substring(1, 4) +
      ') ' +
      value.substring(4, 7) +
      '-' +
      value.substring(7, 9) +
      '-' +
      value.substring(9, 10)
    );
  } else if (value.length === 11 || value.length > 11) {
    return (
      '+7 (' +
      value.substring(1, 4) +
      ') ' +
      value.substring(4, 7) +
      '-' +
      value.substring(7, 9) +
      '-' +
      value.substring(9, 11)
    );
  } else {
    return ''
  }
};

function MenuVertical() {
  const { authDispatch } = useContext(AuthContext);
  const { profileDispatch } = useContext(ProfileContext);

  const [isModalProfile, setIsModalProfile] = useState(false);
  const [isModalLogout, setIsModalLogout] = useState(false);

  const { profile } = useProfile();

  const { setAction } = useAction(!!profile ? profile.uuid : null, () => {
    void profileInit(profileDispatch, ROOT_URL, null).then();
  });

  useEffect(() => {
    setAction(!!profile ? profile.uuid : null);
  }, [profile]);

  const navigate = useNavigate();

  const handleLogout = () => {
    authLogout(authDispatch, () => {
      navigate("/");
    });
  };

  function isMD5Hash(str) {
    // Проверяем, соответствует ли строка формату MD5 хэша.
    const md5Regex = /^[a-f0-9]{32}$/i;
    return md5Regex.test(str);
  }


  return (
    <ScrollBlock
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <div className="MenuVertical">
        <div>
          <ul className="Menu__list" style={{ marginBottom: 8 }}>
            {profile && (
              <li
                className="List__link List__link_header"
                onClick={() => setIsModalProfile(true)}
              >
                <div className="Link__text">
                  {!isProfileRoleTypeTags(profile, [
                    ROLE_CLIENT,
                  ]) && (
                  <div
                    style={{
                      borderRadius: 7,
                      backgroundColor: "#fff",
                      boxShadow: "0 16px 20px rgba(0, 0, 0, 0.07)",
                      height: 64,
                      width: 64,
                      marginBottom: 8,
                    }}
                  >
                    {!!profile.avatar && isMD5Hash(profile.avatar) && (
                      <img height={64} width={64} style={{
                        borderRadius: 7,
                      }}  src={ROOT_URL + "/api/account/avatar/" + profile.avatar}/>
                    )}
                    {!!profile.avatar && !isMD5Hash(profile.avatar) && (
                      <img height={64} width={64} style={{
                        borderRadius: 7,
                      }}  src={"data:image/jpeg;base64, "+ profile.avatar}/>
                    )}
                    {!profile.avatar && (
                      <Avatar size={64} value={getAccountFio(profile)} />
                    )}
                  </div>
                    )}

                  {isProfileRoleTypeTags(profile, [
                    ROLE_CLIENT,
                  ]) && (
                    <div
                      style={{
                        marginBottom: 8,
                      }}
                    >
                      <div style={{
                        borderRadius: 48,
                        // backgroundColor: "#f3f3f3",
                        height: 48,
                        width: 48,
                      }}>
                        <Icon size={48} name={'custom-account-circle'} color={"#20202095"}/>
                      </div>
                    </div>
                  )}

                  <div>
                    <div
                      className="Text__title"
                    >
                      {getAccountFio(profile)}
                    </div>
                    <div
                    >
                      <div className="Text__signature">
                        {profile.login && (
                          <div
                            style={{
                              fontSize: 12,
                              opacity: 0.5,
                            }}
                          >
                            {profile.login}
                          </div>
                        )}
                        {profile.email && (
                          <div
                            style={{
                              fontSize: 12,
                              opacity: 0.5,
                            }}
                          >
                            {profile.email}
                          </div>
                        )}
                        {profile.phone && (
                          <div
                            style={{
                              fontSize: 12,
                              opacity: 0.5,
                            }}
                          >
                            {formatPhoneNumber(getPhoneNumber(profile.phone))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            )}
          </ul>

          <MenuRouting />

          <ul className="Menu__list">
            <li className="List__link" onClick={() => setIsModalLogout(true)}>
              <ButtonIcon
                onClick={() => setIsModalLogout(true)}
                iconName={"custom-exit"}
                iconColor={"#20202090"}
                size={36}
              />
            </li>
          </ul>

          {!!isModalProfile && (
            <ModalProfile handleClose={() => setIsModalProfile(false)} />
          )}

          {!!isModalLogout && (
            <ModalLogout
              successfulRequest={() => (setIsModalLogout(false), handleLogout())}
              handleClose={() => setIsModalLogout(false)}
            />
          )}
        </div>
        <div>
          <ul className="Menu__list">
            {!isProfileRoleTypeTags(profile, [ROLE_CLIENT]) && (
              <li
                className="List__link"
                style={{ paddingTop: 0, paddingLeft: 12, paddingBottom: 0 }}
              >
                <span
                  style={{
                    fontSize: 13,
                    fontWeight: 400,
                    opacity: 0.75,
                  }}
                >
                  <span
                    style={{
                      fontSize: 11,
                      fontWeight: 700,
                      opacity: 0.5,
                    }}
                  >
                    v
                  </span>{" "}
                  {process.env.REACT_APP_VERSION}
                </span>
              </li>
            )}
          </ul>
        </div>
      </div>
    </ScrollBlock>
  );
}

export default MenuVertical;
