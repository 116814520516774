import React, { useState, useRef } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../config/url";
import {
  getErrorMessageByKey,
  useNotification,
  Modal,
  Input,
  useList,
  Select,
  useObject,
  jsonFormatText,
  isValidateNullText,
  getAccountFio,
} from "@vokymlak/kabinet-ui";
import {ROLE_DIRECTOR, ROLE_EMPLOYEE} from "../../../config/constants";

function ModalProjectEmployeeAdd(props) {
  const {
    list: [listManagers],
  } = useList({
    apiUrl: "/api/employees/list",
    rootUrl: ROOT_URL,
    urlParams: {
      roles: [ROLE_DIRECTOR, ROLE_EMPLOYEE]
    }
  });

  const {
    object: [project],
  } = useObject({
    apiUrl: "/api/project/" + props.project.uuid,
    rootUrl: ROOT_URL,
    callbackError: () => {
      props.handleClose();
    },
  });

  const selectManagerId = useRef(null);
  const selectRoleType = useRef(null);

  const { notification } = useNotification();

  const changeEdit = (event) => {
    event.preventDefault();

    const managerId = selectManagerId.current.value;
    const roleType = selectRoleType.current.value;

    if (isValidateNullText(managerId) || managerId === "null") {
      notification("Выберите сотрудника", "red");
      return;
    }

    if (isValidateNullText(roleType) || roleType === "null") {
      notification("Укажите роль сотрудника", "red");
      return;
    }

    const jsonData = JSON.stringify({
      employee_id:
        managerId.length > 0
          ? managerId === "null"
            ? null
            : Number(managerId)
          : null,
      project_employee_role_type: {
        tag: roleType.length > 0 ? (roleType === "null" ? null : roleType) : null,
      },
    });

    axios
      .post("/api/project/" + props.project.uuid + "/employee/add", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Данные сохранены", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const getAccountsSelectOptions = (list) => {
    const options = [
      {
        value: "null",
        title: "Не выбрано",
      },
    ];

    list.map((item) => {
      const option = {
        value: item.account.id,
        title: getAccountFio(item.account),
      };

      options.push(option);
    });

    return options;
  };

  return (
    !!project &&
    !!listManagers && (
      <Modal handleClose={() => props.handleClose()}>
          <form className="Form" onSubmit={(event) => changeEdit(event)}>
            <div className="Form__title">
              {project.name}
              <div className="Text__signature">Проект</div>
            </div>

            <div className="Form__field">
              <Select
                isFixed={true}
                reference={selectManagerId}
                options={
                  !!listManagers
                    ? getAccountsSelectOptions(listManagers)
                    : getAccountsSelectOptions([])
                }
                label="Сотрудник"
              />
            </div>

            <div className="Form__field Form__field_last">
              <Select
                isFixed={true}
                reference={selectRoleType}
                options={[
                  {
                    value: "null",
                    title: "Не выбран",
                  },
                  {
                    value: "CURATOR",
                    title: "Куратор",
                  },
                  {
                    value: "LAWYER",
                    title: "Юрист",
                  },
                  {
                    value: "EXECUTOR",
                    title: "Специалист по исполнению",
                  },
                  {
                    value: "ECONOMIST",
                    title: "Экономист",
                  },
                ]}
                label="Роль сотрудника в проекте"
              />
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_blue">
                Добавить
              </button>
            </div>
          </form>
      </Modal>
    )
  );
}

export default ModalProjectEmployeeAdd;
