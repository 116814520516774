import React from "react";
import ContractPaymentsWrapper from "./ContractPaymentsWrapper";

function ContractPayments(props) {
  return (
      <ContractPaymentsWrapper
        {...props}
      />
  );
}

export default ContractPayments;
