import React, { useEffect, useState } from "react";
import { ROOT_URL } from "../../../../../config/url";
import ModalCompanyAuthorizeEdit from "./ModalCompanyAuthorizeEdit";
import styleContent from "../../../../../styles/modules/Block.module.scss";
import styleCard from "../../../../../styles/modules/Card.module.scss";
import {Loading, useAction, useList} from "@vokymlak/kabinet-ui";

function CompanyAuthorizeList(props) {
  const {
    list: [list],
    update: [_isUpdateList, _setIsUpdateList, updateList],
  } = useList({
    apiUrl: "/api/company/" + props.company.uuid + "/authorize",
    rootUrl: ROOT_URL,
  });

  useEffect(() => {
    updateList();
  }, [props.isUpdate]);

  const [modalCompanyAuthorizeEdit, setModalCompanyAuthorizeEdit] =
    useState(null);

  const { setAction } = useAction([props.company.uuid], () =>
    updateList()
  );

  useEffect(() => {
    if (!!list) {
      const listUuid = list.map((i) => i.uuid);
      setAction([...listUuid, props.company.uuid]);
    } else {
      setAction(props.company.uuid);
    }
  }, [list]);

  return (
    <>
      {/*{!list && (*/}
      {/*  <div className={"Page__loader"}>*/}
      {/*    <Loading />*/}
      {/*  </div>*/}
      {/*)}*/}

      {!!list && list.length > 0 && (
        <>
          <div className={styleContent._block}>
            <div className={styleContent._block__title}>Доступы</div>
            <div className={styleContent._block__content}>
              {list.map((item) => (
                <div
                  className={styleCard._block + " " + styleCard._block_hover}
                  key={props.company.uuid + ":" + item.uuid}
                  onClick={() => setModalCompanyAuthorizeEdit(item)}
                  style={{
                    position: "relative"
                  }}
                >
                  <div
                    className={styleCard._block__number}
                    style={{
                      fontSize: 17,
                    }}
                  >
                    {item.authorize.name}
                  </div>
                  <div className={styleCard._block__title}>
                    {item.authorize.login}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      position: "absolute",
                      top: -8,
                      left: 0,
                      justifyContent: " flex-end",
                      width: "100%",
                      padding: "0 8px",
                    }}
                  >
                    {!!item.authorize.authorize_type &&
                      item.authorize.authorize_type.tag === "RIS" && (
                        <div
                          style={{
                            fontSize: 11,
                            fontWeight: 700,
                            backgroundColor: "#0061ff",
                            display: "inline-block",
                            color: "white",
                            padding: "2px 4px 2px",
                            borderRadius: 3,
                            margin: "0 2px",
                          }}
                        >
                          {item.authorize.authorize_type.name}
                        </div>
                      )}
                  </div>
                </div>
              ))}
            </div>
          </div>

          {!!modalCompanyAuthorizeEdit && (
            <ModalCompanyAuthorizeEdit
              authorize={modalCompanyAuthorizeEdit}
              successfulRequest={() => updateList()}
              handleClose={() => setModalCompanyAuthorizeEdit(null)}
            />
          )}
        </>
      )}
    </>
  );
}

export default CompanyAuthorizeList;
