import React, {Fragment} from "react";
import { ScrollBlock } from "../../../components/ScrollBlock/ScrollBlock";
import {ButtonIcon, getAccountFio, Icon, Loading, TextSearchMarker, Tooltip} from "@vokymlak/kabinet-ui";
import {ROOT_URL} from "../../../config/url";
import {Avatar} from "../../../components/Avatar/Avatar";
import {NavLink} from "react-router-dom";

function MyProjectsList(props) {
  const {
    list,
    setList,
    count,

    setIsUpdateList,
    isUpdateList,
  } = props;

  function isMD5Hash(str) {
    // Проверяем, соответствует ли строка формату MD5 хэша.
    const md5Regex = /^[a-f0-9]{32}$/i;
    return md5Regex.test(str);
  }

  return (
    <ScrollBlock style={{ width: "100%", height: "100%" }}>
      <div className="Page">
        <div className="Page__title">
          <div
            className="Title__text"
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: 21,
              fontWeight: 700,
            }}
          >
            Проекты
            {!!list && (
              <span style={{ fontSize: 12, opacity: 0.75, marginLeft: 6 }}>
                ({count})
              </span>
            )}
          </div>

          <div className="Title__settings">
            <ButtonIcon
              tooltipText={"Обновить"}
              iconName={"custom-change-circle"}
              iconColor={"#20202075"}
              size={28}
              style={{ marginLeft: 8 }}
              onClick={() => (setList(null), setIsUpdateList(!isUpdateList))}
            />
          </div>
        </div>

        {!list && (
          <div className={"Page__loader"}>
            <Loading />
          </div>
        )}

        {!!list && (
          <div className="Page__content show">
            <ul className="List">
              {list.map((item) => (
                <Fragment key={"item_" + item.uuid}>
                  <li
                    className={"List__item"}
                  >
                    <div className="Item__title">
                      <div className="Element__title">
                        <div>
                          <div>
                            {item.name}
                            {!!item.project_year && (
                              <span
                                style={{
                                  opacity: 0.5,
                                  marginLeft: 4,
                                  fontSize: 13,
                                }}
                              >
                                {item.project_year}
                              </span>
                            )}

                            {!!item.law && (
                              <span
                                style={{
                                  marginLeft: 4,
                                  fontSize: 13,
                                  opacity: 0.5,
                                }}
                              >
                                {"("}
                                {item.law.name}
                                {")"}
                              </span>
                            )}
                          </div>
                          {!!item.company && (
                            <div
                              style={{
                                fontSize: 13,
                                fontWeight: 500,
                              }}
                            >
                              {item.company.short_name}
                            </div>
                          )}

                          {!!item.employees && (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                                alignItems: "center",
                                marginTop: 6,
                              }}
                            >
                              {!!item.employees && (
                                <div
                                  style={{
                                    fontSize: 13,
                                    fontWeight: 500,
                                    display: "flex"
                                  }}
                                >
                                  {item.employees.map((item) => (
                                    <div
                                      key={item.uuid}
                                      style={{
                                        fontSize: 12,
                                        fontWeight: 400,
                                        padding: "3px 9px 3px 3px",
                                        backgroundColor: "#20202015",
                                        marginRight: 4,
                                        borderRadius: 24,
                                        display: "flex",
                                        alignItems: "center",
                                        position: "relative",
                                      }}
                                    >
                                      {!!item.project_employee_role_type && (
                                        <span
                                          style={{
                                            fontSize: 10,
                                            position: "absolute",
                                            left: 16,
                                            top: -8,
                                            opacity: 1,
                                            backgroundColor: "#202020",
                                            color: "white",
                                            padding: "0px 4px",
                                            borderRadius: 24,
                                            whiteSpace: "nowrap"
                                          }}
                                        >
                                          {item.project_employee_role_type.name}
                                        </span>
                                      )}
                                      <span style={{
                                        display: "flex"
                                      }}>
                                        <span>
                                          <div style={{
                                            marginRight: 4,
                                            height: 16
                                          }}>
                                            {!!item.employee.avatar && isMD5Hash(item.employee.avatar) && (
                                              <img height={16} width={16} style={{
                                                borderRadius: 16,
                                              }} src={ROOT_URL + "/api/account/avatar/" + item.employee.avatar}/>
                                            )}
                                            {!item.employee.avatar && (
                                              <Avatar size={16} value={getAccountFio(item.employee)}/>
                                            )}
                                          </div>
                                        </span>
                                        <span>
                                          {getAccountFio(item.employee)}
                                        </span>
                                      </span>
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="Item__settings">

                      <div style={{
                        height: 32,
                        width: 2,
                        backgroundColor: "#20202010",
                        borderRadius: 4,
                        marginRight: 12,
                        marginLeft: 2,
                        textAlign: "right"
                      }}>
                      </div>

                      <Tooltip
                        text={"Перейти"}
                      >
                        <div style={{height: 24}}>
                          <NavLink
                            to={"/my/project/" + item.uuid + "/contracts"}
                            style={{ width: "100%" }}
                            target={"_black"}
                          >
                            <Icon
                              name={"custom-circle-arrow-right"}
                              size={24}
                              color={"#202020"}
                            />
                          </NavLink>
                        </div>
                      </Tooltip>
                    </div>
                  </li>
                </Fragment>
              ))}
            </ul>
          </div>
        )}
      </div>
    </ScrollBlock>
  );
}

export default MyProjectsList;
