import React from "react";

export const ContractSpecificationsTableHead = (props) => {
  const {
    object,
  } = props;

  return (
    !!object && (
      <tr className="Table__tr Table__tr_padding Table__tr_border">
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">Обновлено</div>
        </th>

        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">№</div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">Реестровый номер</div>
        </th>
        {!!object.project && !!object.project.law && object.project.law.tag === "44" && (
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">КПГЗ</div>
        </th>
          )}
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">СПГЗ</div>
        </th>
        {!!object.project && !!object.project.law && object.project.law.tag === "44" && (
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">КТРУ</div>
        </th>
          )}
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">ОКПД-2</div>
        </th>

        {!!object.project && !!object.project.law && object.project.law.tag === "223" && (
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">ОКВЭД-2</div>
        </th>
          )}

        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">Единица измерения</div>
        </th>

        {!!object &&
          !object.is_without_volume && (
            <>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">Объем</div>
        </th>
              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#dfdfdf",
                }}
              >
                <div className="Th__title">Не распределено</div>
              </th>
              </>
          )}

        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">Цена за единицу</div>
        </th>

        {!!object &&
          !object.is_without_volume && (
            <>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">Сумма</div>
        </th>
              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#dfdfdf",
                }}
              >
                <div className="Th__title">Не распределено</div>
              </th>
              </>
          )}
      </tr>
    )
  );
};
