import React from 'react'
import { InputViewProps } from './useInput'
import styles from './Input.module.scss'

const InputView = (props: InputViewProps): JSX.Element => {
  const {
    value,
    label,
    isFixed,
    type,
    reference,
    disabled,
    isFocus,
    setIsFocus,
    isDashed,
    classNameValueFocuse,
    changeInput,
  } = props;

  return (
    <div className={styles._field}>
      <div
        className={
          styles._field__box +
          (!!classNameValueFocuse(value, isFocus, isFixed) ? ' ' +
            classNameValueFocuse(value, isFocus, isFixed) : '') +
          (!!isDashed ? ' ' + styles._field__box_dashed : '')
        }>
        <label>
          <span
            className={styles._field__label}>
            {label}
          </span>
          <input
            className={styles._field__input}
            type={!!type && type || 'text'}
            defaultValue={!!value && value || undefined}
            autoComplete="off"
            disabled={disabled}
            ref={reference || undefined}
            onWheel={(event: React.WheelEvent<HTMLInputElement>) => event.currentTarget.blur()}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              changeInput(event)
            }}
            onFocus={() => {
              setIsFocus(true)
            }}
            onBlur={() => {
              setIsFocus(false)
            }} />
        </label>
      </div>
    </div>
  )
}

export default InputView
