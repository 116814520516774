import React from "react";
import moment from "moment/moment";
import { ControlPointTasksList } from "./ControlPointTasksList";
import { Icon } from "@vokymlak/kabinet-ui";

export const ControlPointTasksTable = (props) => {
  const {
    isUpdateCalendarTasks,
    setIsUpdateCalendarTasks,

    dayTasks,
    isUpdateDayTasks,
    setIsUpdateDayTasks,

    overdueTasks,
    isUpdateOverdueTasks,
    setIsUpdateOverdueTasks,

    isControlPointTasksOverdue,

    selectDate,
    toDay,
  } = props;

  return (
    <table className="Table">
      <thead className="Thead">
      <tr className="Table__tr Table__tr_padding Table__tr_border">
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
            width: "0%",
          }}
        >
          <div
            className="Th__title"
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Icon name={"custom-priority"} size={12} color={"#202020"}/>
          </div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">Контрагент</div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">Дата</div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">Предмет</div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">Закон</div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">Способ закупки</div>
        </th>

        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">Реестровый номер</div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">Номер в ЕИС</div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">Цена договора</div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">Этап</div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">Регистрация</div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">Примечание</div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">Куратор</div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">Специалист</div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">Назначил</div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">Исполнитель</div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">Отметка</div>
        </th>
      </tr>
      </thead>
      <tbody>
      {/*{!!overdueTasks &&*/}
      {/*  toDay.format("YYYY-MM-DD") ===*/}
      {/*    moment(selectDate, "YYYY-MM-DD").format("YYYY-MM-DD") && (*/}
      {/*    <ControlPointTasksList*/}
        {/*      list={overdueTasks}*/}
        {/*      isRed={true}*/}
        {/*      successfulRequest={() => (*/}
        {/*        setIsUpdateCalendarTasks(!isUpdateCalendarTasks),*/}
        {/*        setIsUpdateDayTasks(!isUpdateDayTasks),*/}
        {/*        setIsUpdateOverdueTasks(!isUpdateOverdueTasks)*/}
        {/*      )}*/}
        {/*    />*/}
        {/*  )}*/}

        {isControlPointTasksOverdue && !!overdueTasks && (
            <ControlPointTasksList
              list={overdueTasks}
              isRed={true}
              successfulRequest={() => (
                setIsUpdateCalendarTasks(!isUpdateCalendarTasks),
                  setIsUpdateDayTasks(!isUpdateDayTasks),
                  setIsUpdateOverdueTasks(!isUpdateOverdueTasks)
              )}
            />
          )}

        {!isControlPointTasksOverdue && !!dayTasks && (
          <ControlPointTasksList
            list={dayTasks}
            isRed={false}
            successfulRequest={() => (
              setIsUpdateCalendarTasks(!isUpdateCalendarTasks),
              setIsUpdateDayTasks(!isUpdateDayTasks),
              setIsUpdateOverdueTasks(!isUpdateOverdueTasks)
            )}
          />
        )}
      </tbody>
    </table>
  );
};
