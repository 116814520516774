import React, {Fragment, useEffect, useState} from "react";
import {NavLink, useMatch, useNavigate} from "react-router-dom";
import "./HandbookMenu.scss";
import { ScrollBlock } from "../../../components/ScrollBlock/ScrollBlock";
import { ROOT_URL } from "../../../config/url";
import {ButtonIcon, useAction, useList, useUnroll} from "@vokymlak/kabinet-ui";

export const HandbookClientMenu = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(true);
  const match = useMatch("/handbook/article/:handbookEntryUuid");

  const { isUnroll, changeUnroll } = useUnroll();

  const {
    list: [list],
    update: [_isUpdateList, _setIsUpdateList, updateList],
  } = useList({ apiUrl: "/api/handbook-entries/public", rootUrl: ROOT_URL});

  useEffect(() => {
    let handbookEntryUuid
    if (!!match && !!match.params && !!match.params.handbookEntryUuid) {
      handbookEntryUuid = match.params.handbookEntryUuid
    }

    if (!!list && list.length > 0 && isOpen && !handbookEntryUuid) {
      let link = null;
      list.forEach(l => {
        if (!link && l.handbook_entry_type.tag === "ARTICLE") {
          link = l
        }
        if (!link && l.handbook_entry_type.tag === "GROUP_ARTICLES") {
          if (!!l.articles) {
            l.articles.forEach(article => {
              if (!link && article.handbook_entry_type.tag === "ARTICLE") {
                link = article
              }
            })
          }
        }
      })
      if (!!link) {
        navigate("/handbook/article/" + link.uuid);
      }
      setIsOpen(false);
    }
  }, [list])

  useEffect(() => {
    let handbookEntryUuid
    if (!!match && !!match.params && !!match.params.handbookEntryUuid) {
      handbookEntryUuid = match.params.handbookEntryUuid
    }

    if (!!list && list.length > 0 && !!handbookEntryUuid) {
      list.forEach(l => {
        if (l.handbook_entry_type.tag === "GROUP_ARTICLES") {
          if (!!l.articles) {
            l.articles.forEach(article => {
              if (article.uuid === handbookEntryUuid) {
                if (!isUnroll(l.uuid)) {
                  changeUnroll(l.uuid)
                }
              }
            })
          }
        }
      })
    }
  }, [list])

  useAction("handbook", () => updateList());

  return (
    <ScrollBlock style={{ width: "100%", height: "100%" }}>
      <div className="HandbookMenu">
        <div className="Menu__header">
          <div className="Header__text">
            Справочник
          </div>
        </div>

        {/*<div className="Menu__search">*/}
        {/*  <Search label={'Поиск'} />*/}
        {/*</div>*/}

        {!!list && (
          <ul className="Menu__list">
            {list.map((item) => (
              !item.anchor_id && (
                <Fragment key={item.uuid}>
                {item.handbook_entry_type.tag === "HEADING" && (
                <div
                  className={"List__title"}
                  style={{opacity: .75, cursor: 'default', fontSize: 16}}
                >
                  {item.title}
                </div>
                )}

                {item.handbook_entry_type.tag === "GROUP_ARTICLES" && (
                  <Fragment>
                  <div
                    className={"List__group"}
                    onClick={() => changeUnroll(item.uuid)}
                  >
                    {item.title}
                    <ButtonIcon
                      tooltipText={null}
                      iconName={
                        isUnroll(item.uuid)
                          ? "custom-expand-circle-up"
                          : "custom-expand-circle-down"
                      }
                      iconColor={"#20202095"}
                      size={18}
                      style={{ marginLeft: 12, marginRight: 3 }}
                      onClick={() => null}
                    />
                  </div>

                    {isUnroll(item.uuid) && (
                      <ul className="List__menu">
                        {!!item.articles && item.articles.map((article) => (
                          <li key={article.uuid} className="List__link">
                            <NavLink
                              className="Link__text"
                              to={"/handbook/article/" + article.uuid}
                            >
                              {article.title}
                            </NavLink>
                          </li>
                        ))}
                      </ul>
                    )}
                  </Fragment>
                )}

                {item.handbook_entry_type.tag === "ARTICLE" && (
                  <li className="List__link">
                  <NavLink
                    className="Link__text"
                  to={"/handbook/article/" + item.uuid}
                  >
                    {item.title}
                  </NavLink>
                  </li>
                )}
                </Fragment>
              )
            ))}
          </ul>
        )}
      </div>
    </ScrollBlock>
  );
};
