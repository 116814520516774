import React, { useState } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../../../../../config/url";
import {
  getErrorMessageByKey,
  useNotification,
  Modal,
  useObject,
} from "@vokymlak/kabinet-ui";

function ModalProjectTasksArchive(props) {
  const {
    object: [project],
  } = useObject({
    apiUrl: "/api/project/" + props.project.uuid,
    rootUrl: ROOT_URL,
    callbackError: () => {
      props.handleClose();
    },
  });

  const { notification } = useNotification();

  const changeAdd = (event) => {
    event.preventDefault();

    const jsonData = JSON.stringify({
      project_tasks: props.projectTasks,
      project_id: project.id,
    });

    axios
      .post("/api/project-finance-tasks/archive", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Задачи по проекту сотруднику в архиве", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    !!project && (
      <Modal handleClose={() => props.handleClose()}>
        <form className="Form" onSubmit={(event) => changeAdd(event)}>
          <div className="Form__title">
            Задачи по проекту
            <div className="Text__signature">В архив</div>
          </div>

          <div className="Form__actions Form__actions_center">
            <button className="Action__button Action__button_all Action__button_gray">
              Переместить
            </button>
          </div>
        </form>
      </Modal>
    )
  );
}

export default ModalProjectTasksArchive;
