import { useContext } from "react";
import {
  viewerFile,
  viewerClean,
  viewerLoad,
  ViewerContext,
} from "../contexts/viewer";

function useViewer() {
  const { viewerState, viewerDispatch } = useContext(ViewerContext);

  const viewerLoading = () => {
    viewerLoad(viewerDispatch).then();
  };

  const viewer = (file, mimeType) => {
    viewerFile(viewerDispatch, file, mimeType).then();
  };

  const viewerClose = () => {
    viewerClean(viewerDispatch).then();
  };

  return {
    viewerLoading,
    viewer,
    viewerClose,
  };
}

export default useViewer;
