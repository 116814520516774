import React, {useState} from "react";
import ModalPaymentAdd from "./ModalPaymentAdd";
import ModalPaymentEdit from "./ModalPaymentEdit";
import {
  ButtonIcon, Icon, TextSearchMarker, Tooltip,
} from "@vokymlak/kabinet-ui";
import moment from "moment";
import ModalPaymentFileAdd from "./ModalPaymentFileAdd";
import ModalPaymentFileEdit from "./ModalPaymentFileEdit";
import styleContent from "../../../../../styles/modules/Block.module.scss";
import styleCard from "../../../../../styles/modules/Card.module.scss";

function ContractPaymentsPage(props) {
  const {
    object,

    countPaymentAmount,

    list,
    setList,
    count,

    searchQuery,

    setIsUpdateList,
    isUpdateList,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    modalPaymentAdd,
    setModalPaymentAdd,

    modalPaymentEdit,
    setModalPaymentEdit,

    changeViewer,
  } = props;


  const [modalFileAdd, setModalFileAdd] = useState(null);
  const [modalFileEdit, setModalFileEdit] = useState(null);

  function getColorFile(text) {
    if (text.toLowerCase().includes('docx') || text.toLowerCase().includes('doc')) {
      return "#3d6cc0";
    } else if (text.toLowerCase().includes('xlsx')) {
      return "#107c41";
    } else if (text.toLowerCase().includes('pdf')) {
      return "#b9090b";
    }
    return "#202020";
  }

  return (
    !!object && (
      <>
        <div
          className="Page__title"
          style={{
            marginTop: 0,
            marginBottom: 6,
          }}
        >
          <div className="Title__text">
            <div
              className="Title__text"
              style={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
                fontSize: 19,
                fontWeight: 700,
                marginRight: 6,
              }}
            >
              <div>
                Платежи
                {!!list && (
                  <span style={{ fontSize: 12, opacity: 0.75, marginLeft: 6 }}>
                    ({count})
                  </span>
                )}
              </div>
            </div>

            {(
              <div style={{
                marginLeft: 6,
                display: "flex",
              }}>
                <span style={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: 6,
                  backgroundColor: "#dbdbdb",
                  padding: 4,
                  borderRadius: 5
                }}>
                <span style={{fontSize: 12, fontWeight: 400}}>
                    Сумма
                  </span>
                  <span style={{
                    fontSize: 19,
                    fontWeight: 700,
                  }}>
                    {(!!countPaymentAmount &&
                        new Intl.NumberFormat("ru-RU").format(
                          (countPaymentAmount / 100).toFixed(2)
                        )) ||
                      "0,00"}
                  </span>
                </span>
              </div>
            )}
          </div>

          <div className="Title__settings">
            {!!list && (
              <ButtonIcon
                tooltipText={
                  list.length === unrolls.length ? "Свернуть" : "Развернуть"
                }
                iconName={
                  list.length === unrolls.length
                    ? "custom-unfold-less"
                    : "custom-unfold-more"
                }
                iconColor={"#20202075"}
                size={28}
                style={{ marginLeft: 8 }}
                onClick={() => changeAllUnroll(list, "uuid")}
              />
            )}

            <ButtonIcon
              tooltipText={"Обновить"}
              iconName={"custom-change-circle"}
              iconColor={"#20202075"}
              size={28}
              style={{ marginLeft: 12 }}
              onClick={() => (setList(null), setIsUpdateList(!isUpdateList))}
            />

            <ButtonIcon
              tooltipText={"Добавить"}
              iconName={"custom-add"}
              iconColor={"#0064ff"}
              size={36}
              style={{ marginLeft: 12 }}
              onClick={() => setModalPaymentAdd(true)}
            />
          </div>
        </div>

        {!!list && (
          <div className="Page__content">
            <ul className="List">
              {list.map((item) => (
                <React.Fragment key={"item_" + item.uuid}>
                  <li
                    className={
                      isUnroll(item.uuid) ? "List__item active" : "List__item"
                    }
                    onClick={() => changeUnroll(item.uuid)}
                  >
                    <div className="Item__title">
                      <div className="Element__title">
                        <div>
                          <div style={{
                            fontSize: 21
                          }}>
                            {!!item.number && "№ " + item.number} {!!item.date_number && " от " + moment(item.date_number).format("DD.MM.YYYY")}
                            {!item.date_number && !item.number && "-"}
                            {!!item.files && (
                              <Tooltip text={'Прикрепления'} style={{height: 14, marginLeft: 8}}>
                                <Icon
                                  name={"custom-attach-file"}
                                  color={"#202020"}
                                  size={14}
                                />
                              </Tooltip>
                            )}
                          </div>
                          <div style={{
                            fontSize: 13,
                            fontWeight: 400
                          }}>
                            {!!item.date_receipt && moment(item.date_receipt).format("DD.MM.YYYY") + " (получен)"}
                          </div>
                          <div style={{
                            fontSize: 13,
                            fontWeight: 400
                          }}>
                            <Tooltip
                              text={"Реестровый номер"}
                              style={{width: "100%", height: "100%"}}
                            >
                              <div className="Td__text">
                                {(!!item.registry_number && (
                                    <TextSearchMarker
                                      text={item.registry_number + " (реестровый номер)"}
                                      search={searchQuery}
                                    />
                                  )) ||
                                  "-"}
                              </div>
                              <div className="Td__text" style={{
                                fontSize: 10,
                                fontWeight: 400
                              }}>
                                {(!!item.ris_id && item.ris_id) ||
                                  "-"} / {(!!item.ris_entity_id && item.ris_entity_id) ||
                                "-"}
                              </div>
                            </Tooltip>
                          </div>

                          <div style={{
                            fontSize: 13,
                            fontWeight: 400
                          }}>
                            {!!item.updated_at
                              ? moment(item.updated_at).format("DD.MM.YYYY")
                              : moment(item.created_at).format("DD.MM.YYYY")}
                            &nbsp;(обновлено)
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="Item__settings">
                      <div className="Element__datetime">
                        {!!item.contract_stage && (
                          <div style={{
                            padding: "4px 8px",
                            backgroundColor: "#20202010",
                            borderRadius: 4,
                            marginLeft: 12,
                            textAlign: "right"
                          }}>
                            <div style={{
                              fontSize: 21,
                              fontWeight: 700,
                            }}>
                            {(!!item.contract_stage.name && item.contract_stage.name) || "-"}
                          </div>
                          <div style={{
                            fontSize: 10,
                            fontWeight: 400,
                            opacity: .75,
                          }}>Этап</div>
                        </div>
                        )}
                        {!!item.contract_act && (
                        <div style={{
                          padding: "4px 8px",
                          backgroundColor: "#20202010",
                          borderRadius: 4,
                          marginLeft: 12,
                          textAlign: "right"
                        }}>
                          <div style={{
                            fontSize: 21,
                            fontWeight: 700,
                          }}>
                            {!!item.contract_act.number && "№ " + item.contract_act.number} {!!item.contract_act.date_number && " от " + moment(item.contract_act.date_number).format("DD.MM.YYYY")}
                            {!item.contract_act.date_number && !item.contract_act.number && "-"}
                          </div>
                          <div style={{
                            fontSize: 10,
                            fontWeight: 400,
                            opacity: .75,
                          }}>Акт</div>
                        </div>
                          )}
                        {(!!item.contract_stage || !!item.contract_act) && (
                        <div style={{
                          height: 32,
                          width: 2,
                          backgroundColor: "#20202010",
                          borderRadius: 4,
                          marginLeft: 12,
                          textAlign: "right"
                        }}>
                        </div>
                          )}

                        <div style={{
                          padding: "4px 8px",
                          backgroundColor: "#20202010",
                          borderRadius: 4,
                          marginLeft: 12,
                          textAlign: "right"
                        }}>
                          <div style={{
                            fontSize: 21,
                            fontWeight: 700,
                          }}>
                            {(!!item.amount && new Intl.NumberFormat("ru-RU").format(
                                (item.amount / 100).toFixed(2)
                              )) ||
                              "-"}
                          </div>
                          <div style={{
                            fontSize: 10,
                            fontWeight: 400,
                            opacity: .75,
                          }}>Сумма</div>
                        </div>
                      </div>
                    </div>
                  </li>

                  {isUnroll(item.uuid) && (
                    <li className="List__content">
                      <div className="Content__header">
                        <div className="Header__title">
                          <div
                            className="Button__text Button__text_right"
                            onClick={() => setModalFileAdd(item)}
                          >
                            Прикрепить файлы
                          </div>
                        </div>
                        <div className="Header__settings">
                          <ButtonIcon
                            tooltipText={"Редактировать"}
                            iconName={"custom-edit"}
                            iconColor={"#202020"}
                            size={36}
                            style={{ marginLeft: 12 }}
                            onClick={() => setModalPaymentEdit(item)}
                          />
                        </div>
                      </div>

                      {!!item.files && (
                        <div className={styleContent._block}>
                          <div className={styleContent._block__content}>
                            {item.files.map((file) => (
                              <div
                                className={styleCard._block + " " + styleCard._block_hover}
                                key={item.uuid + ":" + file.uuid}
                                // onContextMenu={(event) => (event.preventDefault(), setModalFileEdit(file))}
                                // onClick={(!!file.mime_type && (file.mime_type === "image/jpeg" || file.mime_type === "image/png"))
                                //   ? (event) => (event.preventDefault(), changeViewer(file.data, file.mime_type))
                                //   : (event) => (event.preventDefault(), setModalFileEdit(file))
                                // }
                                // onContextMenu={(event) => (event.preventDefault(), setModalFileEdit(file))}
                                onClick={(event) => (event.preventDefault(), setModalFileEdit(file))}
                              style={{
                                minWidth: "auto",
                                display: "flex",
                                alignItems: "center"
                              }}>
                                {!!file.expansion && (
                                  <span
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "flex-start",
                                      justifyContent: "center",
                                      textTransform: "uppercase",
                                      fontSize: 10,
                                      fontWeight: 900,
                                      borderRadius: 3,
                                      color: "white",
                                      backgroundColor: getColorFile(file.expansion.slice(1)),
                                      marginRight: 8,
                                      padding: "2px 4px"
                                    }}>
                                    {file.expansion.slice(1)}
                                  </span>
                                )}
                                <span className={styleCard._block__title} style={{
                                  fontSize: 15
                                }}>
                                  {file.name}
                                </span>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </li>
                  )}
                </React.Fragment>
              ))}
            </ul>
          </div>
        )}

        {!!modalPaymentAdd && (
          <ModalPaymentAdd
            contract={object}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalPaymentAdd(null)}
          />
        )}
        {!!modalPaymentEdit && (
          <ModalPaymentEdit
            payment={modalPaymentEdit}
            contract={object}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalPaymentEdit(null)}
          />
        )}

        {!!modalFileAdd && (
          <ModalPaymentFileAdd
            object={modalFileAdd}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalFileAdd(null)}
          />
        )}
        {!!modalFileEdit && (
          <ModalPaymentFileEdit
            file={modalFileEdit}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalFileEdit(null)}
          />
        )}
      </>
    )
  );
}

export default ContractPaymentsPage;
