import React, {useState} from "react";
import moment from "moment/moment";
import styleContent from "../../../styles/modules/Block.module.scss";
import styleCard from "../../../styles/modules/Card.module.scss";
import ReactECharts from "echarts-for-react";
import ModalTasksFilter from "./ModalTasksFilter";
import {ButtonIcon, ButtonIconExport, getAccountFio, Loading, Marker,} from "@vokymlak/kabinet-ui";
import styleTumbler from "../../../styles/modules/Tumbler.module.scss";
import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";
import {ExecutionTasksTable} from "./ExecutionTasksTable";
import {ROOT_URL} from "../../../config/url";

function ExecutionTasksPage(props) {
  const {
    calendarTasks,
    setCalendarTasks,
    isUpdateCalendarTasks,
    setIsUpdateCalendarTasks,

    dayExecutionTasks,
    setDayExecutionTasks,
    isUpdateDayExecutionTasks,
    setIsUpdateDayExecutionTasks,

    overdueExecutionTasks,
    setOverdueExecutionTasks,
    isUpdateOverdueExecutionTasks,
    setIsUpdateOverdueExecutionTasks,

    listEmployees,
    listProjects,
    listControlPoints,

    getObjectByFilter,
    deleteFilter,
    getProjectByFilter,
    getEmployeeByFilter,
    filterControlPoints,
    setFilterControlPoints,
    filterEmployees,
    setFilterEmployees,
    filterCuratorEmployees,
    setFilterCuratorEmployees,
    filterExecutorEmployees,
    setFilterExecutorEmployees,
    filterSpecialistEmployees,
    setFilterSpecialistEmployees,
    filterProjects,
    setFilterProjects,

    changeSelectMonthMinus,
    changeSelectMonthPlus,
    changeSelectDay,
    monthText,
    weekDayText,
    selectMonth,
    selectYear,
    days,
    selectDate,
    toDay,

    isDayMarker,
    getDayCount,
    isExecution,

    option,

    listContractGroups,
    contractGroup,
    setContractGroup,

    paramsDayExecutionTasks,
  } = props;

  const [isModalFilter, setIsModalFilter] = useState(false);
  const [isOverdue, setIsOverdue] = useState(false);
  const [link, setLink] = useState('executionTasks');

  return (
    <ScrollBlock style={{width: "100%", height: "100%"}}>
      <div className="Page">
        <div className="Page__title">
          <div
            className="Title__text"
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: 21,
              fontWeight: 700,
            }}
          >
            Задачи исполнения
            <ButtonIcon
              tooltipText={"Фильтры&nbsp;поиска"}
              iconName={"custom-search-filter"}
              iconColor={"#20202075"}
              size={24}
              style={{marginLeft: 12}}
              onClick={() => setIsModalFilter(!isModalFilter)}
            />
          </div>

          <div
            className="Title__settings"
            style={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              justifyContent: "flex-start",
              alignItems: "center",
              alignContent: "center",
            }}
          >
            {!!listContractGroups && (
              <ul className={styleTumbler._}>
                {listContractGroups.map((item, index) => (
                  <li className={styleTumbler._link} key={"item_" + index}>
                    <a
                      className={
                        styleTumbler._text +
                        ((!!contractGroup &&
                            contractGroup.uuid === item.uuid &&
                            " " + styleTumbler.active + " ") ||
                          "")
                      }
                      onClick={() =>
                        setContractGroup(
                          !!contractGroup && contractGroup.uuid === item.uuid
                            ? null
                            : item
                        )
                      }
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            )}
          </div>

          <div className="Title__settings">
            <div
              className="Title__text"
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: 21,
                fontWeight: 700,
                marginLeft: 12,
              }}
            >
              {monthText(selectMonth)}
              <span style={{fontSize: 12, opacity: 0.75, marginLeft: 6}}>
                {selectYear}
              </span>
              <ButtonIcon
                tooltipText={"Назад"}
                iconName={"custom-circle-arrow-left"}
                iconColor={"#202020"}
                size={24}
                style={{marginLeft: 12}}
                onClick={() => changeSelectMonthMinus()}
              />
              <ButtonIcon
                tooltipText={"В&nbsp;перёд"}
                iconName={"custom-circle-arrow-right"}
                iconColor={"#202020"}
                size={24}
                style={{marginLeft: 6}}
                onClick={() => changeSelectMonthPlus()}
              />
            </div>
            <ButtonIcon
              tooltipText={"Обновить"}
              iconName={"custom-change-circle"}
              iconColor={"#20202075"}
              size={28}
              style={{marginLeft: 12}}
              onClick={() => (
                setCalendarTasks(null),
                  setIsUpdateCalendarTasks(!isUpdateCalendarTasks),
                  setIsUpdateOverdueExecutionTasks(!isUpdateOverdueExecutionTasks),
                  setIsUpdateDayExecutionTasks(!isUpdateDayExecutionTasks)
              )}
            />
          </div>
        </div>

        {((!!filterEmployees &&
            filterEmployees.length > 0 &&
            !!listEmployees) ||
          (!!filterCuratorEmployees && filterCuratorEmployees.length > 0 && !!listEmployees) ||
          (!!filterSpecialistEmployees && filterSpecialistEmployees.length > 0 && !!listEmployees) ||
          (!!filterExecutorEmployees && filterExecutorEmployees.length > 0 && !!listEmployees) ||
          (!!filterProjects && filterProjects.length > 0 && !!listProjects) ||
          (!!filterControlPoints &&
            filterControlPoints.length > 0 &&
            !!listControlPoints)) && (
          <div
            style={{
              marginBottom: 12,
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <div
              className="Title__text"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {!!filterEmployees &&
                !!listEmployees &&
                filterEmployees.map((filter) => (
                  <div
                    key={"filter1_" + filter}
                    style={{
                      fontSize: 12,
                      fontWeight: 400,
                      padding: "3px 3px 3px 9px",
                      backgroundColor: "#dfdfdf",
                      margin: "0 2px",
                      borderRadius: 24,
                      display: "flex",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    <span
                      style={{
                        fontSize: 10,
                        position: "absolute",
                        left: 12,
                        top: -6,
                        opacity: 0.75,
                      }}
                    >
                      Сотрудник
                    </span>
                    <span>
                      {!!getEmployeeByFilter(listEmployees, filter) &&
                        getAccountFio(
                          getEmployeeByFilter(listEmployees, filter).account
                        )}
                    </span>
                    <ButtonIcon
                      tooltipText={"Убрать"}
                      iconName={"custom-circle-close"}
                      iconColor={"#20202075"}
                      size={18}
                      style={{marginLeft: 3}}
                      onClick={() =>
                        deleteFilter(
                          filterEmployees,
                          filter,
                          setFilterEmployees
                        )
                      }
                    />
                  </div>
                ))}

              {!!filterCuratorEmployees &&
                !!listEmployees &&
                filterCuratorEmployees.map((filter) => (
                  <div
                    key={"filter11_" + filter}
                    style={{
                      fontSize: 12,
                      fontWeight: 400,
                      padding: "3px 3px 3px 9px",
                      backgroundColor: "#dfdfdf",
                      margin: "0 2px",
                      borderRadius: 24,
                      display: "flex",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    <span
                      style={{
                        fontSize: 10,
                        position: "absolute",
                        left: 12,
                        top: -6,
                        opacity: 0.75,
                      }}
                    >
                      Куратор
                    </span>
                    <span>
                      {!!getEmployeeByFilter(listEmployees, filter) &&
                        getAccountFio(
                          getEmployeeByFilter(listEmployees, filter).account
                        )}
                    </span>
                    <ButtonIcon
                      tooltipText={"Убрать"}
                      iconName={"custom-circle-close"}
                      iconColor={"#20202075"}
                      size={18}
                      style={{marginLeft: 3}}
                      onClick={() =>
                        deleteFilter(
                          filterCuratorEmployees,
                          filter,
                          setFilterCuratorEmployees
                        )
                      }
                    />
                  </div>
                ))}

              {!!filterSpecialistEmployees &&
                !!listEmployees &&
                filterSpecialistEmployees.map((filter) => (
                  <div
                    key={"filter12_" + filter}
                    style={{
                      fontSize: 12,
                      fontWeight: 400,
                      padding: "3px 3px 3px 9px",
                      backgroundColor: "#dfdfdf",
                      margin: "0 2px",
                      borderRadius: 24,
                      display: "flex",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    <span
                      style={{
                        fontSize: 10,
                        position: "absolute",
                        left: 12,
                        top: -6,
                        opacity: 0.75,
                      }}
                    >
                      Специалист
                    </span>
                    <span>
                      {!!getEmployeeByFilter(listEmployees, filter) &&
                        getAccountFio(
                          getEmployeeByFilter(listEmployees, filter).account
                        )}
                    </span>
                    <ButtonIcon
                      tooltipText={"Убрать"}
                      iconName={"custom-circle-close"}
                      iconColor={"#20202075"}
                      size={18}
                      style={{marginLeft: 3}}
                      onClick={() =>
                        deleteFilter(
                          filterSpecialistEmployees,
                          filter,
                          setFilterSpecialistEmployees
                        )
                      }
                    />
                  </div>
                ))}

              {!!filterExecutorEmployees &&
                !!listEmployees &&
                filterExecutorEmployees.map((filter) => (
                  <div
                    key={"filter12_" + filter}
                    style={{
                      fontSize: 12,
                      fontWeight: 400,
                      padding: "3px 3px 3px 9px",
                      backgroundColor: "#dfdfdf",
                      margin: "0 2px",
                      borderRadius: 24,
                      display: "flex",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    <span
                      style={{
                        fontSize: 10,
                        position: "absolute",
                        left: 12,
                        top: -6,
                        opacity: 0.75,
                      }}
                    >
                      Исполнитель
                    </span>
                    <span>
                      {!!getEmployeeByFilter(listEmployees, filter) &&
                        getAccountFio(
                          getEmployeeByFilter(listEmployees, filter).account
                        )}
                    </span>
                    <ButtonIcon
                      tooltipText={"Убрать"}
                      iconName={"custom-circle-close"}
                      iconColor={"#20202075"}
                      size={18}
                      style={{marginLeft: 3}}
                      onClick={() =>
                        deleteFilter(
                          filterExecutorEmployees,
                          filter,
                          setFilterExecutorEmployees
                        )
                      }
                    />
                  </div>
                ))}


              {!!filterProjects &&
                !!listProjects &&
                filterProjects.map((filter) => (
                  <div
                    key={"filter2_" + filter}
                    style={{
                      fontSize: 12,
                      fontWeight: 400,
                      padding: "3px 3px 3px 9px",
                      backgroundColor: "#dfdfdf",
                      margin: "0 2px",
                      borderRadius: 24,
                      display: "flex",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    <span
                      style={{
                        fontSize: 10,
                        position: "absolute",
                        left: 12,
                        top: -6,
                        opacity: 0.75,
                      }}
                    >
                      Проект
                    </span>
                    <span>
                      {!!getProjectByFilter(listProjects, filter) &&
                        getProjectByFilter(listProjects, filter).name}
                    </span>
                    <ButtonIcon
                      tooltipText={"Убрать"}
                      iconName={"custom-circle-close"}
                      iconColor={"#20202075"}
                      size={18}
                      style={{marginLeft: 3}}
                      onClick={() =>
                        deleteFilter(filterProjects, filter, setFilterProjects)
                      }
                    />
                  </div>
                ))}

              {!!filterControlPoints &&
                !!listControlPoints &&
                filterControlPoints.map((filter) => (
                  <div
                    key={"filter3_" + filter}
                    style={{
                      fontSize: 12,
                      fontWeight: 400,
                      padding: "3px 3px 3px 9px",
                      backgroundColor: "#dfdfdf",
                      margin: "0 2px",
                      borderRadius: 24,
                      display: "flex",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    <span
                      style={{
                        fontSize: 10,
                        position: "absolute",
                        left: 12,
                        top: -6,
                        opacity: 0.75,
                      }}
                    >
                      Этап
                    </span>
                    <span>
                      {!!getObjectByFilter(listControlPoints, filter) &&
                        getObjectByFilter(listControlPoints, filter).name}
                    </span>

                    <ButtonIcon
                      tooltipText={"Убрать"}
                      iconName={"custom-circle-close"}
                      iconColor={"#20202075"}
                      size={18}
                      style={{marginLeft: 3}}
                      onClick={() =>
                        deleteFilter(
                          filterControlPoints,
                          filter,
                          setFilterControlPoints
                        )
                      }
                    />
                  </div>
                ))}
            </div>
          </div>
        )}

        <div
          className="Page__title"
          style={{
            marginBottom: 72,
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          {!!days &&
            days.map((day, index) => (
              <div
                key={index}
                onClick={() => changeSelectDay(day.day)}
                style={{
                  backgroundColor: "white",
                  borderRadius: 7,
                  padding: "3px 7px",
                  minWidth: 56,
                  margin: 2,
                  cursor: "pointer",
                  border:
                    Number(moment(selectDate, "YYYY-MM-DD").format("DD")) ===
                    Number(day.day) &&
                    Number(moment(selectDate, "YYYY-MM-DD").format("MM")) ===
                    Number(selectMonth) &&
                    Number(moment(selectDate, "YYYY-MM-DD").format("YYYY")) ===
                    Number(selectYear)
                      ? Number(toDay.format("DD")) === Number(day.day) &&
                      Number(toDay.format("MM")) === Number(selectMonth) &&
                      Number(toDay.format("YYYY")) === Number(selectYear)
                        ? "3px solid #f44336"
                        : "3px solid #202020"
                      : "3px solid white",
                  position: "relative",
                  opacity:
                    moment(
                      selectYear + "-" + selectMonth + "-" + day.day,
                      "YYYY-MM-DD"
                    ).weekday() === 0 ||
                    moment(
                      selectYear + "-" + selectMonth + "-" + day.day,
                      "YYYY-MM-DD"
                    ).weekday() === 6
                      ? 0.5
                      : 1,
                }}
              >
                <div
                  style={{
                    color:
                      Number(toDay.format("DD")) === Number(day.day) &&
                      Number(toDay.format("MM")) === Number(selectMonth) &&
                      Number(toDay.format("YYYY")) === Number(selectYear)
                        ? "#f44336"
                        : "#202020",
                    fontSize: 11,
                    textTransform: "capitalize",
                  }}
                >
                  {weekDayText(
                    moment(
                      selectYear + "-" + selectMonth + "-" + day.day,
                      "YYYY-MM-DD"
                    ).weekday()
                  )}
                </div>
                <div
                  style={{
                    color:
                      Number(toDay.format("DD")) === Number(day.day) &&
                      Number(toDay.format("MM")) === Number(selectMonth) &&
                      Number(toDay.format("YYYY")) === Number(selectYear)
                        ? "#f44336"
                        : "#202020",
                    fontSize: 17,
                    fontWeight: 700,
                  }}
                >
                  {day.day}
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: 3,
                    right: 3,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      fontSize: 8,
                      marginRight: 3,
                    }}
                  >
                    {getDayCount(calendarTasks, selectYear, selectMonth, day.day).countPoints -
                      getDayCount(calendarTasks, selectYear, selectMonth, day.day).countExecutionPoints}
                  </div>
                  {isDayMarker(
                    calendarTasks,
                    selectYear,
                    selectMonth,
                    day.day,
                    "count_points"
                  ) && (
                    <Marker
                      size={6}
                      style={{
                        marginLeft: 2,
                      }}
                      color={
                        isExecution(
                          calendarTasks,
                          selectYear,
                          selectMonth,
                          day.day,
                          "count_points",
                          "count_execution_points"
                        )
                          ? "#bdbdbd"
                          : "#0064ff"
                      }
                    />
                  )}
                </div>
              </div>
            ))}
        </div>

        <ReactECharts
          option={option(calendarTasks, selectYear, selectMonth, days)}
          style={{height: "114px", width: "100%"}}
        />

        <hr className={"Page__hr"}/>

        <div className="Page__title">
          <div
            className="Title__text"
            style={{
              fontSize: 21,
              fontWeight: 900,
            }}
          >
            {moment(selectDate, "YYYY-MM-DD").format("DD.MM.YYYY")}
          </div>
        </div>

        {isDayMarker(
          calendarTasks,
          selectYear,
          selectMonth,
          selectDate.substr(-2),
          "count_points"
        )
          ? !dayExecutionTasks && (
          <div className="Page__loader">
            <Loading/>
          </div>
        )
          : !dayExecutionTasks && (
          <div className="Page__content">
            <ul className="List">
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "nowrap",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "100px 0",
                  fontSize: 48,
                  opacity: 0.1,
                }}
              >
                Задач нет
              </div>
            </ul>
          </div>
        )}

        {(!!dayExecutionTasks ||
          !!overdueExecutionTasks) && (
          <>
            <div className="Page__title">
              <div
                className="Title__text"
                style={{
                  fontSize: 15,
                  fontWeight: 600
                }}
              >
                <a
                  className={styleCard._block + ' ' + styleCard._block_hover + ' ' +
                    (link === 'executionTasks' ? styleCard._block_focus : '')}
                  onClick={() => setLink('executionTasks')}
                  style={{
                    opacity: 1,
                    minWidth: "inherit"
                  }}>
                  По исполнению
                  {!!dayExecutionTasks && <span style={{
                    marginLeft: 4
                  }}> ({dayExecutionTasks.length}
                    {!!overdueExecutionTasks && overdueExecutionTasks.length > 0 && <span style={{
                      marginLeft: 4,
                      color: "#cf2f00"
                    }}>/ {overdueExecutionTasks.length} </span>})</span>}
                </a>
              </div>
              <div className={"Title__settings"}>
              </div>
            </div>

            <div className="Page__title">
              <div
                className="Title__text"
                style={{
                  fontSize: 15,
                  fontWeight: 600
                }}
              >
                <a
                  className={styleCard._block + ' ' + styleCard._block_hover + ' ' +
                    (!isOverdue ? styleCard._block_focus : '')}
                  onClick={() => setIsOverdue(false)}
                  style={{
                    opacity: 1,
                    minWidth: "inherit"
                  }}>
                  Текущие

                  {link === 'executionTasks' && (
                    <>
                      {!!dayExecutionTasks && <span style={{
                        marginLeft: 4
                      }}> ({dayExecutionTasks.length})</span>}
                    </>
                  )}
                </a>
                <a
                  className={styleCard._block + ' ' + styleCard._block_hover + ' ' +
                    (isOverdue ? styleCard._block_focus : '')}
                  onClick={() => setIsOverdue(true)}
                  style={{
                    opacity: 1,
                    minWidth: "inherit",
                    backgroundColor: isOverdue ? false : "#ff572233",
                    color: "#cf2f00"
                  }}>
                  Просроченные

                  {link === 'executionTasks' && (
                    <>
                      {!!overdueExecutionTasks && overdueExecutionTasks.length > 0 && <span style={{
                        marginLeft: 4,
                        color: "#cf2f00"
                      }}>({overdueExecutionTasks.length})</span>}
                    </>
                  )}
                </a>

                {link === 'executionTasks' && !isOverdue && (
                  <ButtonIconExport
                    tooltipText={"Выгрузить"}
                    iconName={"custom-download"}
                    iconColor={"#202020"}
                    size={24}
                    style={{marginLeft: 12}}
                    apiUrl={
                      "/api/tasks/calendar/day/execution/export/xlsx?" + paramsDayExecutionTasks
                    }
                    rootUrl={ROOT_URL}
                    mimeType={
                      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    }
                    fileName={(
                      "Задачи по исполнению"
                        .trim()
                        .replaceAll(" ", "_") +
                      "_на_" +
                      moment(selectDate).format("DD_MM_YYYY") +
                      ".xlsx"
                    ).toLowerCase()}
                  />
                )}

                {link === 'executionTasks' && isOverdue && (
                  <ButtonIconExport
                    tooltipText={"Выгрузить"}
                    iconName={"custom-download"}
                    iconColor={"#202020"}
                    size={24}
                    style={{marginLeft: 12}}
                    apiUrl={
                      "/api/tasks/calendar/day/execution/overdue/export/xlsx?" + paramsDayExecutionTasks
                    }
                    rootUrl={ROOT_URL}
                    mimeType={
                      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    }
                    fileName={(
                      "Задачи по исполнению"
                        .trim()
                        .replaceAll(" ", "_") +
                      "_на_" +
                      moment(selectDate).format("DD_MM_YYYY") +
                      ".xlsx"
                    ).toLowerCase()}
                  />
                )}
              </div>
              <div className={"Title__settings"}>
              </div>
            </div>

            {link === 'executionTasks' && (
              <div
                className={
                  styleContent._block + " " + styleContent._block_bottom
                }
              >
                <div className={styleContent._block__content}>
                  <ExecutionTasksTable
                    {...props}
                    isExecutionTasks={true}
                    isExecutionTasksOverdue={isOverdue}
                  />
                </div>
              </div>
            )}
          </>
        )}

        {!!isModalFilter && (
          <ModalTasksFilter
            useSearchFilter={{
              filterControlPoints,
              setFilterControlPoints,
              filterEmployees,
              setFilterEmployees,
              filterCuratorEmployees,
              setFilterCuratorEmployees,
              filterSpecialistEmployees,
              setFilterSpecialistEmployees,
              filterExecutorEmployees,
              setFilterExecutorEmployees,
              filterProjects,
              setFilterProjects,
            }}
            successfulRequest={() => (
              setIsUpdateCalendarTasks(!isUpdateCalendarTasks),
                setIsUpdateDayExecutionTasks(!isUpdateDayExecutionTasks),
                setIsUpdateOverdueExecutionTasks(!isUpdateOverdueExecutionTasks)
            )}
            handleClose={() => setIsModalFilter(false)}
          />
        )}
      </div>
    </ScrollBlock>
  );
}

export default ExecutionTasksPage;
