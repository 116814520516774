import React from "react";
import moment from "moment/moment";
import { ProjectTasksList } from "./ProjectTasksList";
import { Icon } from "@vokymlak/kabinet-ui";

export const ProjectTasksTable = (props) => {
  const {
    isUpdateCalendarTasks,
    setIsUpdateCalendarTasks,

    dayProjectTasks,
    isUpdateDayProjectTasks,
    setIsUpdateDayProjectTasks,

    overdueProjectTasks,
    isUpdateOverdueProjectTasks,
    setIsUpdateOverdueProjectTasks,

    isProjectTasksOverdue,

    selectDate,
    toDay,
  } = props;

  return (
    <table className="Table">
      <thead className="Thead">
        <tr className="Table__tr Table__tr_padding Table__tr_border">
          <th
            className="Table__th Table__th_border"
            style={{
              backgroundColor: "#dfdfdf",
              width: "0%",
            }}
          >
            <div
              className="Th__title"
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Icon name={"custom-priority"} size={12} color={"#202020"} />
            </div>
          </th>
          <th
            className="Table__th Table__th_border"
            style={{
              backgroundColor: "#dfdfdf",
            }}
          >
            <div className="Th__title">Проект</div>
          </th>
          <th
            className="Table__th Table__th_border"
            style={{
              backgroundColor: "#dfdfdf",
            }}
          >
            <div className="Th__title">Задача</div>
          </th>
          <th
            className="Table__th Table__th_border"
            style={{
              backgroundColor: "#dfdfdf",
            }}
          >
            <div className="Th__title">Примечание</div>
          </th>
          <th
            className="Table__th Table__th_border"
            style={{
              backgroundColor: "#dfdfdf",
            }}
          >
            <div className="Th__title">Дата исполнения</div>
          </th>
          <th
            className="Table__th Table__th_border"
            style={{
              backgroundColor: "#dfdfdf",
            }}
          >
            <div className="Th__title">Назначил</div>
          </th>
          <th
            className="Table__th Table__th_border"
            style={{
              backgroundColor: "#dfdfdf",
            }}
          >
            <div className="Th__title">Исполнитель</div>
          </th>
          <th
            className="Table__th Table__th_border"
            style={{
              backgroundColor: "#dfdfdf",
            }}
          >
            <div className="Th__title">Отметка</div>
          </th>
        </tr>
      </thead>
      <tbody>
        {/*{!!overdueProjectTasks &&*/}
        {/*  toDay.format("YYYY-MM-DD") ===*/}
        {/*    moment(selectDate, "YYYY-MM-DD").format("YYYY-MM-DD") && (*/}
        {/*    <ProjectTasksList*/}
        {/*      list={overdueProjectTasks}*/}
        {/*      isRed={true}*/}
        {/*      successfulRequest={() => (*/}
        {/*        setIsUpdateCalendarTasks(!isUpdateCalendarTasks),*/}
        {/*        setIsUpdateDayProjectTasks(!isUpdateDayProjectTasks),*/}
        {/*        setIsUpdateOverdueProjectTasks(!isUpdateOverdueProjectTasks)*/}
        {/*      )}*/}
        {/*    />*/}
        {/*  )}*/}

        {isProjectTasksOverdue && !!overdueProjectTasks && (
            <ProjectTasksList
              list={overdueProjectTasks}
              isRed={true}
              successfulRequest={() => (
                setIsUpdateCalendarTasks(!isUpdateCalendarTasks),
                  setIsUpdateDayProjectTasks(!isUpdateDayProjectTasks),
                  setIsUpdateOverdueProjectTasks(!isUpdateOverdueProjectTasks)
              )}
            />
          )}

        {!isProjectTasksOverdue && !!dayProjectTasks && (
          <ProjectTasksList
            list={dayProjectTasks}
            isRed={false}
            successfulRequest={() => (
              setIsUpdateCalendarTasks(!isUpdateCalendarTasks),
              setIsUpdateDayProjectTasks(!isUpdateDayProjectTasks),
              setIsUpdateOverdueProjectTasks(!isUpdateOverdueProjectTasks)
            )}
          />
        )}
      </tbody>
    </table>
  );
};
