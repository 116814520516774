import React from "react";

function ContractStatusesTable(props) {
  const { contractStatuses } = props;

  return (
    !!contractStatuses && (
      <table
        className="Table"
        style={{
          marginBottom: 12,
        }}
      >
        <tbody>
          {contractStatuses.map((item, index) => (
            <tr
              className="Table__tr Table__tr_padding Table__tr_border"
              key={"item_" + item.contract_status.uuid}
            >
              <td className="Table__td Table__td_border">
                <div
                  className="Td__text"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    flexWrap: "nowrap",
                  }}
                >
                  <span
                    style={{
                      fontSize: 15,
                      fontWeight: 400,
                    }}
                  >
                    {index + 1}. {item.contract_status.name}
                  </span>
                  <span>
                    {!!item.contract_status.contract_status_contract_groups &&
                      item.contract_status.contract_status_contract_groups.map(
                        (group) => (
                          <div
                            key={"contract_group_" + group.contract_group.uuid}
                            style={{
                              fontSize: 11,
                              fontWeight: 700,
                              backgroundColor: "#202020",
                              display: "inline-block",
                              color: "white",
                              padding: "2px 4px 2px",
                              borderRadius: 3,
                              marginLeft: 8,
                            }}
                          >
                            {group.contract_group.name}
                          </div>
                        )
                      )}
                    {!!item.contract_status.contract_status_logics &&
                      item.contract_status.contract_status_logics.map(
                        (group) => (
                          <div
                            key={"logic_" + group.uuid}
                            style={{
                              fontSize: 11,
                              fontWeight: 700,
                              backgroundColor: "#fff59d",
                              display: "inline-block",
                              color: "#202020",
                              padding: "2px 4px 2px",
                              borderRadius: 3,
                              marginLeft: 8,
                            }}
                          >
                            {group.logic.name}
                          </div>
                        )
                      )}
                  </span>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    )
  );
}

export default ContractStatusesTable;
