import React, {useState} from "react";
import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";
import {ButtonIcon, Icon, Loading, Pagination, Search, Select, TextSearchMarker, Tooltip} from "@vokymlak/kabinet-ui";
import ModalMyMarkContractEdit from "./ModalMyMarkContractEdit";
import moment from "moment/moment";
import {TICKET_TYPE_ERROR, TICKET_TYPE_IDEA} from "../../../config/constants";

function MyMarkContractsPage(props) {
  const {
    projects,
    setSelectProjectUuid,

    list,
    setList,
    count,

    pageNumber,
    pageLimit,
    changePage,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    inputSearchText,
    onChangeSearchText,
    searchQuery,

    setIsUpdateList,
    isUpdateList,
  } = props;

  const getProjectSelectOptions = (list) => {
    const options = [
      {
        value: "null",
        title: "Не указан",
      },
    ];

    list.map((item) => {
      let title = !!item.project_year ? item.project_year + " " : ""
      title = title + item.name
      title = title + (!!item.law ? " (ФЗ-" + item.law.name + ")" : "")

      const option = {
        value: item.uuid,
        title: title,
      };

      options.push(option);
    });

    return options;
  };

  const [modalContractEdit, setModalContractEdit] = useState(null);

  return (
    <ScrollBlock style={{width: "100%", height: "100%"}}>
      <div className="Page">
        <div className="Page__title">
          <div
            className="Title__text"
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: 21,
              fontWeight: 700,
            }}
          >
            Договоры
            {!!list && (
              <span style={{fontSize: 12, opacity: 0.75, marginLeft: 6}}>
                ({count})
              </span>
            )}
            <span style={{width: 220, marginLeft: 16}}>
              <Search
                reference={inputSearchText}
                type="text"
                label={"Поиск"}
                isFixed={true}
                onChange={() => onChangeSearchText()}
              />
            </span>
            <span style={{minWidth: 320, maxWidth: 560, marginLeft: 16, fontWeight: 400}}>
                <Select
                  isFixed={true}
                  options={!!projects ? getProjectSelectOptions(projects) : getProjectSelectOptions([])}
                  label="Проект"
                  onChange={(event) => setSelectProjectUuid(event.target.value)}
                />
              </span>
          </div>

          <div className="Title__settings">
            {/*{!!list && (*/}
            {/*  <ButtonIcon*/}
            {/*    tooltipText={*/}
            {/*      list.length === unrolls.length ? "Свернуть" : "Развернуть"*/}
            {/*    }*/}
            {/*    iconName={*/}
            {/*      list.length === unrolls.length*/}
            {/*        ? "custom-unfold-less"*/}
            {/*        : "custom-unfold-more"*/}
            {/*    }*/}
            {/*    iconColor={"#20202075"}*/}
            {/*    size={28}*/}
            {/*    style={{ marginLeft: 8 }}*/}
            {/*    onClick={() => changeAllUnroll(list, "uuid")}*/}
            {/*  />*/}
            {/*)}*/}


            <ButtonIcon
              tooltipText={"Обновить"}
              iconName={"custom-change-circle"}
              iconColor={"#20202075"}
              size={28}
              style={{marginLeft: 8}}
              onClick={() => (setList(null), setIsUpdateList(!isUpdateList))}
            />

            {/*{!!count && !!pageLimit && (*/}
            {/*  <div*/}
            {/*    style={{*/}
            {/*      marginLeft: 12,*/}
            {/*      width: 64,*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    <Select*/}
            {/*      isFixed={true}*/}
            {/*      isDefaultOption={false}*/}
            {/*      value={pageLimit}*/}
            {/*      options={[*/}
            {/*        {*/}
            {/*          value: 100,*/}
            {/*          title: 100,*/}
            {/*        },*/}
            {/*        {*/}
            {/*          value: 500,*/}
            {/*          title: 500,*/}
            {/*        },*/}
            {/*      ]}*/}
            {/*      label="Лимит"*/}
            {/*      onChangeSelect={(limit) => setPageLimit(Number(limit))}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*)}*/}

            {!!count && count > pageLimit && (
              <div style={{marginLeft: 8}}>
                <Pagination
                  totalRecords={count}
                  pageLimit={pageLimit}
                  currentPage={pageNumber}
                  onPageChanged={(data) => changePage(data)}
                />
              </div>
            )}
          </div>
        </div>

        {!list && (
          <div className={"Page__loader"}>
            <Loading/>
          </div>
        )}

        {!!list && (
          <div className="Page__content show">
            <ul className="List">
              {list.map((item) => (
                <React.Fragment key={"item_" + item.uuid}>
                  <li
                    className={"List__item"}
                    style={{
                      paddingRight: 8,
                      cursor: "default",
                      position: "relative",
                      border: !!item.ct_date_closing && "3px solid #673ab7",
                      borderRadius: !!item.ct_date_closing && 9
                    }}
                    // onClick={() => setModalContractEdit(item)}
                  >
                    <div className="Item__title">
                      <div className="Element__title">
                        <div>
                          <div>
                            {!!item.project && (
                              <div>
                                {!!item.project.name && (
                                  <span
                                    style={{
                                      marginRight: 4,
                                      fontSize: 13,
                                      fontWeight: 500,
                                    }}
                                  >
                                    {item.project.project_year}{" "}{item.project.name}
                              </span>
                                )}
                              </div>
                            )}
                          </div>
                          <div>
                            {item.registry_number && (
                              <span style={{
                                marginRight: 6
                              }}>
                                <span style={{
                                  fontSize: 12,
                                  marginRight: 2
                                }}>№</span>
                                <TextSearchMarker
                                  text={item.registry_number + " "}
                                  search={searchQuery}
                                />
                              </span>
                            )}
                            <TextSearchMarker
                              text={item.contract_number}
                              search={searchQuery}
                            />
                            <div style={{
                              fontSize: 16,
                              fontWeight: 400
                            }}>
                              <TextSearchMarker
                                text={item.subject}
                                search={searchQuery}
                              />
                            </div>
                            <div style={{
                              fontSize: 14,
                              fontWeight: 400
                            }}>
                              <TextSearchMarker
                                text={item.supplier}
                                search={searchQuery}
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            position: "absolute",
                            top: -8,
                            left: 0,
                            justifyContent: " flex-end",
                            width: "100%",
                            padding: "0 8px",
                          }}
                        >
                          {!!item.ct_date_closing && (
                            <div
                              style={{
                                fontSize: 11,
                                fontWeight: 700,
                                backgroundColor: "#673ab7",
                                display: "inline-block",
                                color: "white",
                                padding: "2px 4px 2px",
                                borderRadius: 3,
                                margin: "0 2px",
                              }}
                            >
                              На закрытии
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="Item__settings">
                      {!item.ct_date_closing && !item.uis_number && (
                        <div style={{
                          marginRight: 12
                        }}>
                          <button
                            className="Action__button Action__button_maxi Action__button_gray"
                            onClick={() => setModalContractEdit(item)}
                          >
                            Закрыть
                          </button>
                        </div>
                      )}
                      {!!item.ct_date_closing && (
                        <div style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: " flex-start",
                          width: "100%",
                        }}>
                          {/* Комментарий по исполнению */}
                          {(!!item.ct_execution_note && (
                            <Tooltip text={item.ct_execution_note}
                                     style={{height: 24, marginLeft: 8}}>
                              <Icon
                                name={"custom-info"}
                                color={"#202020"}
                                size={24}
                              />
                            </Tooltip>
                          ))}

                          {/* Источники исполнения */}
                          <div style={{
                            padding: "4px 8px",
                            backgroundColor: "#20202010",
                            borderRadius: 4,
                            marginLeft: 8,
                            textAlign: "right"
                          }}>
                            <div style={{
                              fontSize: 21,
                              fontWeight: 700,
                            }}>
                              {(!!item.ct_execution_source && (item.ct_execution_source)) || "-"}
                            </div>
                            <div style={{
                              fontSize: 12,
                              fontWeight: 600,
                            }}>
                              Источник
                            </div>
                          </div>

                          {/* Сумма исполнения */}
                          <div style={{
                            padding: "4px 8px",
                            backgroundColor: "#20202010",
                            borderRadius: 4,
                            marginLeft: 8,
                            textAlign: "right"
                          }}>
                            <div style={{
                              fontSize: 21,
                              fontWeight: 700,
                            }}>
                              {(!!item.ct_execution_amount && new Intl.NumberFormat("ru-RU").format(
                                Number(item.ct_execution_amount / 100).toFixed(2)
                              )) || "-"}
                            </div>
                            <div style={{
                              fontSize: 12,
                              fontWeight: 600,
                            }}>
                              Сумма
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </li>

                  {/*{!isUnroll(item.uuid) && (*/}
                  {/*  <li className="List__content">*/}
                  {/*    <CompanyProjectsList*/}
                  {/*      company={item}*/}
                  {/*      isUpdate={isUpdateList}*/}
                  {/*      isClient={true}*/}
                  {/*    />*/}
                  {/*  </li>*/}
                  {/*)}*/}
                </React.Fragment>
              ))}
            </ul>
          </div>
        )}

        {!!modalContractEdit && (
          <ModalMyMarkContractEdit
            contract={modalContractEdit}
            successfulRequest={() => setIsUpdateList(!isUpdateList)}
            handleClose={() => setModalContractEdit(null)}
          />
        )}
      </div>
    </ScrollBlock>
  );
}

export default MyMarkContractsPage;
