import React from "react";
import {Route, Routes} from "react-router-dom";
import "./Handbook.scss";
import { HandbookClientMenu } from "./HandbookClientMenu/HandbookClientMenu";
import { HandbookClientArticle } from "./HandbookClientArticle";

export const HandbookClientRouting = () => {

  return (
    <div className="Handbook">

      <div className="Block" >
        <div className="App App_vertical" style={{
        }}>
          <div className="Navigate" style={{
            width: 320,
          }}>
            <HandbookClientMenu />
          </div>

          <div className="Content" style={{
            boxShadow: "-8px 0 24px rgba(0,0,0, 0.05)"
          }}>
            <Routes>
              <Route
                path="/article/:handbookEntryUuid"
                element={<HandbookClientArticle />}
              />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
};
