import React, { useState } from "react";
import { ROOT_URL } from "../../../config/url";
import { useAction, useObject } from "@vokymlak/kabinet-ui";
import { useParams } from "react-router-dom";

function useProject() {
  let { projectUuid } = useParams();

  const {
    object: [object],
    update: [isUpdateObject, setIsUpdateObject, updateObject],
  } = useObject({ apiUrl: "/api/project/" + projectUuid, rootUrl: ROOT_URL });

  const [modalProjectEdit, setModalProjectEdit] = useState(null);
  const [isModalImportContractsScheduler, setIsModalImportContractsScheduler] = useState(false);

  useAction([projectUuid, "tasks", "project-tasks", "general-tasks"], () =>
    updateObject()
  );

  return {
    object,
    isUpdateObject,
    setIsUpdateObject,

    modalProjectEdit,
    setModalProjectEdit,

    isModalImportContractsScheduler,
    setIsModalImportContractsScheduler,
  };
}

export default useProject;
