import React, { useEffect, useState } from "react";

import {Tooltip, Loading, Icon} from "@vokymlak/kabinet-ui";
import ModalPurchaseBaseAdd from "./ModalPurchaseBaseAdd";
import ModalPurchaseBaseEdit from "./ModalPurchaseBaseEdit";
import ModalPurchaseBaseWayAdd from "./ModalPurchaseBaseWayAdd";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";


export const StrictModeDroppable = ({ children, ...props }) => {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  if (!enabled) {
    return null;
  }

  return <Droppable {...props}>{children}</Droppable>;
};

function PurchaseBasisList(props) {
  const {
    list,
    setList,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    setIsUpdateList,
    isUpdateList,

    modalItemEdit,
    setModalItemEdit,
    isModalObjectAdd,
    setIsModalObjectAdd,
    modalObjectItemAdd,
    setModalObjectItemAdd,

    updateList,

    deletePurchaseBaseWay,

    onDragEnd,
    onDragStart,
    isDrag,
  } = props;

  return (
    <ScrollBlock
      style={{ width: '100%', height: '100%' }}>
    <div className="Page">
      <div className="Page__title">
        <div
          className="Title__text"
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: 27,
            fontWeight: 700,
          }}
        >
          Основания закупок
        </div>

        <div className="Title__settings">
          {!!list && (
            <div
              style={{
                margin: "0 12px",
              }}
              className="Button__text"
              onClick={() => changeAllUnroll(list, "uuid")}
            >
              {list.length === unrolls.length ? "Свернуть" : "Развернуть"}
            </div>
          )}

          <div
            style={{
              margin: "0 12px",
            }}
            className="Button__text"
            onClick={() => (setList(null), setIsUpdateList(!isUpdateList))}
          >
            Обновить
          </div>

          <Tooltip text={"Добавить"} style={{ marginLeft: 24 }}>
            <div
              className="Button__icon Button__icon_42"
              onClick={() => setIsModalObjectAdd(true)}
            >
              <Icon name={"custom-add"} size={42} color={""} />
            </div>
          </Tooltip>
        </div>
      </div>

      <div className="Page__content">
        <ul className="List">
          {!list && (
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                flexWrap: "nowrap",
                alignItems: "center",
                justifyContent: "center",
                padding: "100px 0",
              }}
            >
              <Loading />
            </div>
          )}

          {!!list &&
            list.map((item) => (
              <React.Fragment key={"item_" + item.uuid}>
                <li
                  className={
                    isUnroll(item.uuid) ? "List__item active" : "List__item"
                  }
                  onClick={() => changeUnroll(item.uuid)}
                >
                  <div className="Item__title">
                    <div className="Element__title">
                      {!!item.law && item.law.tag + " | "} {item.name}
                    </div>
                  </div>
                  <div className="Item__settings">
                    <div className="Element__datetime"></div>
                  </div>
                </li>

                {isUnroll(item.uuid) && (
                  <li className="List__content">
                    <div>
                      <table className="Table">
                        {/*<tbody>*/}

                        <DragDropContext
                          onDragEnd={onDragEnd}
                          onDragStart={onDragStart}
                        >
                          <StrictModeDroppable droppableId={String(item.uuid)}>
                            {(provided) => (
                              <tbody
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                              >
                                {!!item.ways &&
                                  item.ways.map((way, index) => (
                                    <Draggable
                                      draggableId={String(way.uuid)}
                                      index={index}
                                      key={"draggable_" + way.uuid}
                                    >
                                      {(provided) => (
                                        <tr
                                          className="Table__tr"
                                          ref={provided.innerRef}
                                          {...provided.dragHandleProps}
                                          {...provided.draggableProps}
                                        >
                                          {/*<tr className="Table__tr" key={index}>*/}
                                          <td className="Table__td">
                                            <div className="Td__title">
                                              {index + 1}
                                              {". "}
                                              {way.purchase_way.name}
                                            </div>
                                          </td>
                                          <td className="Table__td Table__td_child">
                                            {!isDrag && (
                                              <div
                                                className="Button__icon Button__icon_32"
                                                onClick={() =>
                                                  deletePurchaseBaseWay(
                                                    way.uuid
                                                  )
                                                }
                                              >
                                                <Icon
                                                  name={"custom-delete"}
                                                  size={32}
                                                  color={""}
                                                />
                                              </div>
                                            )}
                                          </td>
                                          {/*</tr>*/}
                                        </tr>
                                      )}
                                    </Draggable>
                                  ))}
                                {provided.placeholder}
                              </tbody>
                            )}
                          </StrictModeDroppable>
                        </DragDropContext>
                        {/*</tbody>*/}
                      </table>
                    </div>

                    <div className="Content__footer">
                      <div className="Footer__title">
                        <div
                          className="Button__text"
                          onClick={() => setModalObjectItemAdd(item)}
                        >
                          Добавить способ
                        </div>
                      </div>
                      <div className="Footer__settings">
                        <Tooltip
                          text={"Редактировать"}
                          style={{ marginLeft: 24 }}
                        >
                          <div
                            className="Button__icon Button__icon_42"
                            onClick={() => setModalItemEdit(item)}
                          >
                            <Icon name={"custom-edit"} size={42} color={""} />
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                  </li>
                )}
              </React.Fragment>
            ))}
        </ul>
      </div>

      {!!modalItemEdit && (
        <ModalPurchaseBaseEdit
          purchaseBase={modalItemEdit}
          successfulRequestEdit={(item) => updateList(item)}
          successfulRequest={() => setIsUpdateList(!isUpdateList)}
          handleClose={() => setModalItemEdit(null)}
        />
      )}
      {!!isModalObjectAdd && (
        <ModalPurchaseBaseAdd
          successfulRequest={() => setIsUpdateList(!isUpdateList)}
          handleClose={() => setIsModalObjectAdd(false)}
        />
      )}
      {!!modalObjectItemAdd && (
        <ModalPurchaseBaseWayAdd
          purchaseBase={modalObjectItemAdd}
          successfulRequest={() => setIsUpdateList(!isUpdateList)}
          handleClose={() => setModalObjectItemAdd(null)}
        />
      )}
    </div>
    </ScrollBlock>
  );
}

export default PurchaseBasisList;
