import React, { useRef, useState } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../../../config/url";
import {
  Modal,
  getErrorMessageByKey,
  useNotification,
  Input,
  Textarea,
  useObject,
  jsonFormatText,
} from "@vokymlak/kabinet-ui";

function ModalSpecificationEdit(props) {
  const {
    object: [contract],
  } = useObject({
    apiUrl: "/api/contract/" + props.contract.uuid,
    rootUrl: ROOT_URL,
    callbackError: () => {
      props.handleClose();
    },
  });

  const {
    object: [object],
  } = useObject({
    apiUrl: "/api/contract-specification/" + props.specification.uuid,
    rootUrl: ROOT_URL,
    callbackError: () => {
      props.handleClose();
    },
  });

  const [isDelete, setIsDelete] = useState(false);

  const inputRegistryIndex = useRef(null);
  const inputRegistryNumber = useRef(null);

  const inputKpgz = useRef(null);
  const inputSpgz = useRef(null);
  const inputKtru = useRef(null);
  const inputOkpd2 = useRef(null);
  const inputOkved2 = useRef(null);

  const inputUnitMeasurement = useRef(null);
  const inputVolume = useRef(null);

  const inputPricePerUnit = useRef(null);
  const inputAmount = useRef(null);

  const { notification } = useNotification();

  const numberConvert = (numberString) => {
    return !!numberString && numberString.length > 0
      ? Number(String(Number(numberString).toFixed(2)).replace(".", ""))
      : null;
  };

  const numberConvertV = (numberString) => {
    return !!numberString && numberString.length > 0
      ? Number(String(Number(numberString).toFixed(3)).replace(".", ""))
      : null;
  };

  const changeEdit = (event) => {
    event.preventDefault();

    const registryIndex = inputRegistryIndex.current.value;
    const registryNumber = jsonFormatText(inputRegistryNumber.current.value);

    const kpgz = jsonFormatText(inputKpgz.current.value);
    const spgz = jsonFormatText(inputSpgz.current.value);
    const ktru = jsonFormatText(inputKtru.current.value);
    const okpd2 = jsonFormatText(inputOkpd2.current.value);
    const okved2 = jsonFormatText(inputOkved2.current.value);

    const unitMeasurement = jsonFormatText(inputUnitMeasurement.current.value);
    const volume = inputVolume.current.value;

    const pricePerUnit = inputPricePerUnit.current.value;
    const amount = inputAmount.current.value;

    const jsonData = JSON.stringify({
      registry_index: Number(registryIndex),
      registry_number: registryNumber,

      kpgz: kpgz,
      spgz: spgz,
      ktru: ktru,
      okpd_2: okpd2,
      okved_2: okved2,

      unit_measurement: unitMeasurement,
      volume: numberConvertV(volume),

      price_per_unit: numberConvert(pricePerUnit),
      amount: numberConvert(amount)
    });

    axios
      .put("/api/contract-specification/" + props.specification.uuid + "/edit", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Данные сохранены", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const changeDelete = (event) => {
    event.preventDefault();

    axios
      .delete("/api/contract-specification/" + props.specification.uuid + "/delete", {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Спецификация удалена", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    !!object &&
    !!contract && (
      <Modal handleClose={() => props.handleClose()}>
        {!isDelete && (
          <form className="Form" onSubmit={(event) => changeEdit(event)}>
            <div className="Form__title">
              {object.registry_number}
              <div className="Text__signature">Спецификация</div>
            </div>

            <div className="Form__field">
              <Input reference={inputRegistryIndex}
                     type="number"
                     value={object.registry_index}
                     label="Позиция в реестре" />
            </div>

            <div className="Form__field">
              <Input reference={inputRegistryNumber}
                     value={object.registry_number}
                     label="Реестровый номер" />
            </div>



            <div className="Form__field" style={{
              opacity: !!contract.project && !!contract.project.law && contract.project.law.tag === "223" && 0.33
            }}>
              <Textarea
                reference={inputKpgz}
                label="КПГЗ"
                value={object.kpgz}
                disabled={!!contract.project && !!contract.project.law && contract.project.law.tag === "223"}
              />
            </div>
            <div className="Form__field">
              <Textarea
                reference={inputSpgz}
                label="СПГЗ"
                value={object.spgz}
              />
            </div>
            <div className="Form__field" style={{
              opacity: !!contract.project && !!contract.project.law && contract.project.law.tag === "223" && 0.33
            }}>
              <Textarea
                reference={inputKtru}
                label="КТРУ"
                value={object.ktru}
                disabled={!!contract.project && !!contract.project.law && contract.project.law.tag === "223"}
              />
            </div>
            <div className="Form__field">
              <Textarea
                reference={inputOkpd2}
                label="ОКПД-2"
                value={object.okpd_2}
              />
            </div>

            <div className="Form__field" style={{
              opacity: !!contract.project && !!contract.project.law && contract.project.law.tag === "44" && 0.33
            }}>
              <Textarea
                reference={inputOkved2}
                label="ОКВЭД-2"
                value={object.okved_2}
                disabled={!!contract.project && !!contract.project.law && contract.project.law.tag === "44"}
              />
            </div>

            <div className="Form__field">
              <Input
                reference={inputUnitMeasurement}
                label="Единица измерения"
                value={object.unit_measurement}
              />
            </div>
            <div className="Form__field" style={{
              opacity: contract.is_without_volume && 0.33
            }}>
              <Input
                reference={inputVolume}
                type="number"
                label="Объем"
                value={!!object.volume && object.volume / 1000}
                disabled={contract.is_without_volume}
              />
            </div>

            <div className="Form__field">
              <Input
                reference={inputPricePerUnit}
                type="number"
                label="Цена за единицу"
                value={!!object.price_per_unit && object.price_per_unit / 100}
              />
            </div>
            <div className="Form__field Form__field_last" style={{
              opacity: contract.is_without_volume && 0.33
            }}>
              <Input
                reference={inputAmount}
                type="number"
                label="Сумма"
                value={!!object.amount && object.amount / 100}
                disabled={contract.is_without_volume}
              />
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_blue">
                Применить
              </button>
            </div>

            <div className="Form__actions Form__actions_center">
              <div
                className="Action__link Action__link_red"
                onClick={() => setIsDelete(true)}
              >
                Удалить
              </div>
            </div>
          </form>
        )}

        {isDelete && (
          <form className="Form" onSubmit={(event) => changeDelete(event)}>
            <div className="Form__title">
              {object.registry_number}
              <div className="Text__signature">Удалить</div>
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_red">
                Удалить
              </button>
            </div>

            <div className="Form__actions Form__actions_center">
              <div className="Action__link" onClick={() => setIsDelete(false)}>
                Отмена
              </div>
            </div>
          </form>
        )}
      </Modal>
    )
  );
}

export default ModalSpecificationEdit;
