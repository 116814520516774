import React, { useState } from "react";
import styleTumbler from "../../../styles/modules/Tumbler.module.scss";
import ContractStatusesTable from "./ContractStatusesTable";
import PurchaseBasisTable from "./PurchaseBasisTable";

function LawList(props) {
  const { law } = props;

  const [tumbler, setTumbler] = useState("a");

  return (
    !!law && (
      <li className="List__content">
        <div className="Content__header" style={{ marginBottom: 4 }}>
          <div className="Header__title">
            <ul className={styleTumbler._}>
              <li className={styleTumbler._link}>
                <a
                  className={
                    styleTumbler._text +
                    ((!!tumbler &&
                      tumbler === "a" &&
                      " " + styleTumbler.active + " ") ||
                      "")
                  }
                  onClick={() => setTumbler("a")}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  Основания
                </a>
              </li>
              <li className={styleTumbler._link}>
                <a
                  className={
                    styleTumbler._text +
                    ((!!tumbler &&
                      tumbler === "b" &&
                      " " + styleTumbler.active + " ") ||
                      "")
                  }
                  onClick={() => setTumbler("b")}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  Статусы
                </a>
              </li>
            </ul>
          </div>
          <div className="Header__settings"></div>
        </div>
        <div>
          {tumbler === "a" && (
            <PurchaseBasisTable purchaseBasis={law.purchase_basis} />
          )}
          {tumbler === "b" && (
            <ContractStatusesTable contractStatuses={law.contract_statuses} />
          )}
        </div>
      </li>
    )
  );
}

export default LawList;
