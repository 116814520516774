import React from "react";
import { ContractPfhdTableHead } from "./ContractPfhdTableHead";
import { ContractPfhdList } from "./ContractPfhdList";

export const ContractPfhdTable = (props) => {
  const { object, list } = props;

  return (
    !!object && (
      <table className="Table">
        <thead className="Thead">
          <ContractPfhdTableHead {...props} />
        </thead>
        <tbody>{!!list && <ContractPfhdList {...props} />}</tbody>
      </table>
    )
  );
};
