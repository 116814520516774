import React, { useState, useRef } from "react";
import axios from "axios";
import { ROOT_URL } from "../../../../../config/url";
import {
  getErrorMessageByKey,
  useNotification,
  Input,
  Modal,
  useObject,
  jsonFormatText,
  isValidateNullText, Select,
} from "@vokymlak/kabinet-ui";

function ModalCompanyAuthorizeEdit(props) {
  const inputAuthorizeTypeTag = useRef(null);

  const {
    object: [object],
  } = useObject({
    apiUrl: "/api/authorize/" + props.authorize.uuid,
    rootUrl: ROOT_URL,
    callbackError: () => {
      props.handleClose();
    },
  });

  const inputName = useRef(null);
  const inputLogin = useRef(null);
  const inputPassword = useRef(null);

  const [isDelete, setIsDelete] = useState(false);

  const { notification } = useNotification();

  const changeEdit = (event) => {
    event.preventDefault();

    const name = jsonFormatText(inputName.current.value);
    const login = jsonFormatText(inputLogin.current.value);
    const password = jsonFormatText(inputPassword.current.value);

    const authorizeTypeTag = inputAuthorizeTypeTag.current.value;

    if (
      isValidateNullText(name) ||
      isValidateNullText(login) ||
      isValidateNullText(password)
    ) {
      notification("Заполните все обязательные поля", "red");
      return;
    }

    const jsonData = JSON.stringify({
      name: name,
      login: login,
      password: password,
      authorize_type: authorizeTypeTag.length > 0
        ? authorizeTypeTag === "null"
          ? null
          : {tag: authorizeTypeTag}
        : null,
    });

    axios
      .put("/api/authorize/" + props.authorize.uuid + "/edit", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Данные сохранены", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const changeDelete = (event) => {
    event.preventDefault();

    axios
      .delete("/api/authorize/" + props.authorize.uuid + "/delete", {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Запись удалена", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  return (
    !!object && (
      <Modal handleClose={() => props.handleClose()}>
        {!isDelete && (
          <form className="Form" onSubmit={(event) => changeEdit(event)}>
            <div className="Form__title">
              {object.authorize.name}
              <div className="Text__signature">Доступ</div>
            </div>

            <div className="Form__field">
              <Input
                reference={inputName}
                label="Наименование"
                value={object.authorize.name}
              />
            </div>

            <div className="Form__field">
              <Select
                isFixed={true}
                reference={inputAuthorizeTypeTag}
                options={[
                  {
                    title: "Не указан",
                    value: "null",
                  },
                  {
                    title: "РИС",
                    value: "RIS",
                  },
                ]}
                label="Тип доступа"
                value={!!object.authorize.authorize_type && object.authorize.authorize_type.tag}
              />
            </div>

            <div className="Form__field">
              <Input
                reference={inputLogin}
                label="Логин"
                value={object.authorize.login}
              />
            </div>

            <div className="Form__field Form__field_last">
              <Input
                reference={inputPassword}
                label="Пароль"
                value={object.authorize.password}
              />
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_blue">
                Изменить
              </button>
            </div>

            <div className="Form__actions Form__actions_center">
              <div
                className="Action__link Action__link_red"
                onClick={() => setIsDelete(true)}
              >
                Удалить
              </div>
              {/*<div className="Action__link" onClick={() => props.handleClose()}>*/}
              {/*  Отмена*/}
              {/*</div>*/}
            </div>
          </form>
        )}
        {isDelete && (
          <form className="Form" onSubmit={(event) => changeDelete(event)}>
            <div className="Form__title">
              {object.authorize.name}
              <div className="Text__signature">Удалить</div>
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_red">
                Удалить
              </button>
            </div>

            <div className="Form__actions Form__actions_center">
              <div className="Action__link" onClick={() => setIsDelete(false)}>
                Отмена
              </div>
            </div>
          </form>
        )}
      </Modal>
    )
  );
}

export default ModalCompanyAuthorizeEdit;
