import React, { useState } from "react";
import { ROOT_URL } from "../../../config/url";
import useList from "../../../hooks/useList";
import {useAction, useUnroll} from "@vokymlak/kabinet-ui";
import axios from "axios";

function usePurchaseWays() {
  const {
    list: [list, setList],
    update: [isUpdateList, setIsUpdateList],
  } = useList("/api/purchase-ways", ROOT_URL);

  const { unrolls, isUnroll, changeUnroll, changeAllUnroll } = useUnroll();

  const [isModalObjectAdd, setIsModalObjectAdd] = useState(false);
  const [modalItemEdit, setModalItemEdit] = useState(null);

  useAction("isUpdate", () => setIsUpdateList(!isUpdateList));

  const onDragEndDrop = (result) => {
    if (result.destination) {
      const oldIndex = result.source.index;
      const newIndex = result.destination.index;
      const objectId = result.draggableId;

      let object = null;

      if (!!list) {
        list.map((item) => {
          if (objectId === item.uuid) {
            object = { ...item };
          }
        });

        setList([...dndArray(list, oldIndex, newIndex)]);

        axios
          .get("/api/purchase-way/" + object.uuid + "/index/" + newIndex, {
            withCredentials: false,
            baseURL: ROOT_URL,
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + localStorage.getItem("tokenSession"),
            },
          })
          .then(() => {
            setIsUpdateList(!isUpdateList);
          })
          .catch(() => {
            setIsUpdateList(!isUpdateList);
          });
      }
    }
  };

  const dndArray = (arr, old_index, new_index) => {
    if (new_index >= arr.length) {
      let k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  };

  return {
    list,
    setList,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    setIsUpdateList,
    isUpdateList,

    modalItemEdit,
    setModalItemEdit,
    isModalObjectAdd,
    setIsModalObjectAdd,

    onDragEndDrop,
  };
}

export default usePurchaseWays;
