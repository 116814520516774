import React from "react";
import { Icon } from "@vokymlak/kabinet-ui";
import { ProjectGeneralTasksList } from "./ProjectGeneralTasksList";

export const ProjectGeneralTasksTable = (props) => {
  const {
    list,

    setIsUpdateList,
    isUpdateList,
  } = props;

  return (
    <table className="Table">
      <thead className="Thead">
        <tr className="Table__tr Table__tr_padding Table__tr_border">
          <th
            className="Table__th Table__th_border"
            style={{
              backgroundColor: "#dfdfdf",
              width: "0%",
            }}
          >
            <div
              className="Th__title"
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Icon name={"custom-priority"} size={12} color={"#202020"} />
            </div>
          </th>
          <th
            className="Table__th Table__th_border"
            style={{
              backgroundColor: "#dfdfdf",
            }}
          >
            <div className="Th__title">Обновлено</div>
          </th>
          <th
            className="Table__th Table__th_border"
            style={{
              backgroundColor: "#dfdfdf",
            }}
          >
            <div className="Th__title">Задача</div>
          </th>
          <th
            className="Table__th Table__th_border"
            style={{
              backgroundColor: "#dfdfdf",
            }}
          >
            <div className="Th__title">Описание</div>
          </th>
          <th
            className="Table__th Table__th_border"
            style={{
              backgroundColor: "#dfdfdf",
            }}
          >
            <div className="Th__title">Примечание</div>
          </th>
          <th
            className="Table__th Table__th_border"
            style={{
              backgroundColor: "#dfdfdf",
            }}
          >
            <div className="Th__title">Дата исполнения</div>
          </th>
          <th
            className="Table__th Table__th_border"
            style={{
              backgroundColor: "#dfdfdf",
            }}
          >
            <div className="Th__title">Отметка</div>
          </th>
          <th
            className="Table__th Table__th_border"
            style={{
              backgroundColor: "#dfdfdf",
              width: "0%",
            }}
          >
            <div
              className="Th__title"
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              -
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <ProjectGeneralTasksList
          list={list}
          successfulRequest={() => setIsUpdateList(!isUpdateList)}
        />
      </tbody>
    </table>
  );
};
