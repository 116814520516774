import React, { useRef } from "react";
import Modal from "../../../components/Modal/Modal";
import axios from "axios";
import { ROOT_URL } from "../../../config/url";
import useList from "../../../hooks/useList";
import useObject from "../../../hooks/useObject";
import {getErrorMessageByKey, useNotification, Select} from "@vokymlak/kabinet-ui";

function ModalPurchaseBaseControlPointAdd(props) {
  const {
    list: [listControlPoints],
  } = useList("/api/control-points", ROOT_URL, false);

  const {
    object: [object],
  } = useObject("/api/purchase-base/" + props.purchaseBase.uuid, ROOT_URL);

  const selectControlPointUuid = useRef(null);

  const { notification } = useNotification();

  const changeAdd = (event) => {
    event.preventDefault();

    const controlPointUuid = selectControlPointUuid.current.value;

    if (
      !controlPointUuid ||
      controlPointUuid.length === 0 ||
      (controlPointUuid.length > 0 && controlPointUuid === "null")
    ) {
      return;
    }

    axios
      .get(
        "/api/purchase-base-control-point/purchase-base/" +
          props.purchaseBase.uuid +
          "/control-point/" +
          controlPointUuid +
          "/add",
        {
          withCredentials: false,
          baseURL: ROOT_URL,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("tokenSession"),
          },
        }
      )
      .then(() => {
        props.successfulRequest();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });

    props.handleClose();
  };

  const getSelectOptions = (list) => {
    const options = [
      {
        value: "null",
        title: "Не выбрано",
      },
    ];

    list.map((item) => {
      const option = {
        value: item.uuid,
        title: item.name,
      };

      options.push(option);
    });

    return options;
  };

  return (
    object && (
      <Modal handleClose={() => props.handleClose}>
        <form className="Form" onSubmit={(event) => changeAdd(event)}>
          <div className="Form__title">
            Добавить способ
            <div className="Text__signature">{object.name}</div>
          </div>

          <div className="Form__field Form__field_last">
            <Select
              isFixed={true}
              reference={selectControlPointUuid}
              options={
                !!listControlPoints
                  ? getSelectOptions(listControlPoints)
                  : getSelectOptions([])
              }
              label="Точка контроля"
            />
          </div>

          <div className="Form__actions Form__actions_center">
            <button className="Action__button Action__button_all Action__button_blue">
              Добавить
            </button>
          </div>

          <div className="Form__actions Form__actions_center">
            <div className="Action__link" onClick={() => props.handleClose()}>
              Отмена
            </div>
          </div>
        </form>
      </Modal>
    )
  );
}

export default ModalPurchaseBaseControlPointAdd;
