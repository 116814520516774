import React, { createContext, useReducer } from "react";
import { initialState, viewerReducer } from "./viewerReducer";
import { Viewer } from "../../components/Viewer";

export const ViewerContext = createContext(initialState);

export const ViewerProvider = ({ children }) => {
  const [viewerState, viewerDispatch] = useReducer(viewerReducer, initialState);

  return (
    <ViewerContext.Provider value={{ viewerState, viewerDispatch }}>
      {children}
      <Viewer />
    </ViewerContext.Provider>
  );
};
