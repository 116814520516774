import React, { Fragment } from "react";
import "./HandbookArticle.scss";
import {ButtonIcon, getAccountFio, Icon, Loading, Tooltip} from "@vokymlak/kabinet-ui";
import { HandbookArticleEditor } from "./HandbookArticleEditor";
import { ScrollBlock } from "../../../../components/ScrollBlock/ScrollBlock";
import moment from "moment";
import styleArticle from "./Article.module.scss";

export const HandbookArticlePage = (props) => {
  const {
    object,

    isEdit,
    setIsEdit,
  } = props;

  return (
    <ScrollBlock style={{ width: "100%", height: "100%" }}>
      <div className="HandbookPage"  style={{
        backgroundColor: "white",
        height: !isEdit && "auto",
        padding: !isEdit ? "56px 64px 128px" : "32px 64px 0"
      }}>
        {!!object && (
          <Fragment>
            <div
              className="Page__title"
              style={{
                width: 796.661608,
                marginBottom: !isEdit && 36
              }}
            >
              <div
                className="Title__text"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  fontSize: !isEdit ? 32 : 19,
                  fontWeight: !isEdit ? 400 : 700,
                  opacity: 1
                }}
              >
                <span>
                {object.handbook_entry.title}
                </span>
                <span
                  style={{
                    fontSize: 13,
                    opacity: .75,
                    fontWeight: 400,
                    marginTop: 4
                  }}
                >
                  <span style={{marginRight: 8}}>
                          {'Автор: ' + getAccountFio(object.handbook_entry.author)}
                    </span>
                  <span>
                    {!!object.updated_at
                      ? 'Обновлено: ' + moment(object.updated_at.substring(0, 19)).format("DD.MM.YYYY HH:mm")
                      : 'Добавлено: ' + moment(object.handbook_entry.created_at.substring(0, 19)).format("DD.MM.YYYY HH:mm")}
                    </span>
                        </span>
              </div>

              <div className="Title__settings">
                {(!!object.handbook_entry && (
                  <Tooltip
                    text={object.handbook_entry.is_public ? 'Опубликована'
                      : 'Не&nbsp;опубликована'}
                    style={{
                      marginLeft: 24,
                      height: 24
                    }}
                  >
                    <Icon
                      name={object.handbook_entry.is_public ? "custom-eye-solid"
                        : "custom-eye-slash-solid"
                      }
                      color={object.handbook_entry.is_public ? "#0061ff" : "#20202075" }
                      size={26}
                    />
                  </Tooltip>
                ))}
                <ButtonIcon
                  iconName={"custom-edit"}
                  iconColor={"#202020"}
                  size={36}
                  style={{ marginLeft: 16, marginRight: 0 }}
                  onClick={() => setIsEdit(!isEdit)}
                />
              </div>
            </div>

            {/*{!isEdit && (*/}
            {/*  <hr className={"Page__hr"} />*/}
            {/*)}*/}

            <div
              className="Page__content"
              style={{
                height: isEdit && "100%",
              }}
            >
              {isEdit && <HandbookArticleEditor {...props} />}
              {!isEdit && (
                <div
                  className={styleArticle.article}
                  style={{
                    width: 796.661608,
                    // height: "calc(100% - 24px - 12px)",
                  }}
                  dangerouslySetInnerHTML={{
                    __html:
                      !object.article ||
                      (!!object.article &&
                        (object.article === "<p></p>" ||
                          object.article.length === 0))
                        ? "<p><br></p>"
                        : object.article,
                  }}
                ></div>
              )}
            </div>
          </Fragment>
        )}

        {!object && (
          <div className={"Page__loader"}>
            <Loading />
          </div>
        )}
      </div>
    </ScrollBlock>
  );
};
