import React, { useState } from "react";
import ModalProjectTaskAdd from "./ModalProjectTaskAdd";
import { ButtonIcon, Loading } from "@vokymlak/kabinet-ui";
import { ProjectTasksTable } from "./ProjectTasksTable";
import ModalProjectTasksReArchive from "./ModalProjectTasksReArchive";
import ModalProjectTasksArchive from "./ModalProjectTasksArchive";

function ProjectTasksPage(props) {
  const {
    object,

    list,
    setList,
    setIsUpdateList,
    isUpdateList,

    isArchive,
    setIsArchive,

    selectContracts,
    setSelectContracts,
  } = props;

  const [modalProjectTaskAdd, setModalProjectTaskAdd] = useState(null);
  const [isModalContractsArchive, setIsModalContractsArchive] = useState(null);
  const [isModalContractsReArchive, setIsModalContractsReArchive] = useState(null);

  return (
    <>
      <div className="Page__title">
        <div className="Title__text">
          <div
            className="Title__text"
            style={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
              fontSize: 19,
              fontWeight: 700,
              marginRight: 6,
            }}
          >
            <div>
              Задачи сотрудников
              {!!list && (
                <span style={{ fontSize: 12, opacity: 0.75, marginLeft: 6 }}>
                  ({list.length})
                </span>
              )}
            </div>
          </div>
          {/*{!!object.count_overdue_tasks && object.count_overdue_tasks > 0 && (*/}
          {/*  <div*/}
          {/*    className={"Collected__count Collected__count_red"}*/}
          {/*    style={{*/}
          {/*      marginRight: 2,*/}
          {/*      marginLeft: 2,*/}
          {/*      borderRadius: 24,*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    {object.count_overdue_tasks}*/}
          {/*  </div>*/}
          {/*)}*/}
          {/*{!!object.count_current_tasks && object.count_current_tasks > 0 && (*/}
          {/*  <div*/}
          {/*    className={"Collected__count Collected__count_yellow"}*/}
          {/*    style={{*/}
          {/*      marginRight: 2,*/}
          {/*      marginLeft: 2,*/}
          {/*      borderRadius: 24,*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    {object.count_current_tasks}*/}
          {/*  </div>*/}
          {/*)}*/}
          {/*{!!object.count_planned_tasks && object.count_planned_tasks > 0 && (*/}
          {/*  <div*/}
          {/*    className={"Collected__count Collected__count_green"}*/}
          {/*    style={{*/}
          {/*      marginRight: 2,*/}
          {/*      marginLeft: 2,*/}
          {/*      borderRadius: 24,*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    {object.count_planned_tasks}*/}
          {/*  </div>*/}
          {/*)}*/}
          <ButtonIcon
            tooltipText={"Архив"}
            iconName={"custom-archive"}
            iconColor={isArchive ? "#202020" : "#20202075"}
            size={24}
            style={{
              marginLeft: 16,
            }}
            onClick={() => setIsArchive(!isArchive)}
          />

          {!!selectContracts && selectContracts.length > 0 && (
            <div
              style={{
                backgroundColor: "#0061ff",
                padding: "0 4px",
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 24,
                marginLeft: 12,
              }}
            >
              <div
                style={{
                  padding: "4px 6px",
                  color: "white",
                  fontSize: 14,
                  pointerEvents: "auto",
                  fontWeight: 700,
                  cursor: "default"
                }}
              >
                {selectContracts.length}
              </div>
              <ButtonIcon
                tooltipText={"Отменить"}
                iconName={"custom-circle-close"}
                iconColor={"white"}
                size={18}
                onClick={() => setSelectContracts([])}
              />
            </div>
          )}
        </div>

        <div className="Title__settings">
          <ButtonIcon
            tooltipText={"Обновить"}
            iconName={"custom-change-circle"}
            iconColor={"#20202075"}
            size={28}
            style={{ marginLeft: 8 }}
            onClick={() => (setList(null), setIsUpdateList(!isUpdateList))}
          />

          <ButtonIcon
            tooltipText={"Добавить"}
            iconName={"custom-add"}
            iconColor={"#0064ff"}
            size={36}
            style={{ marginLeft: 12 }}
            onClick={() => setModalProjectTaskAdd(object)}
          />
        </div>
      </div>

      {!list && (
        <div className={"Page__loader"}>
          <Loading />
        </div>
      )}

      {!!list && (
        <div className="Page__content">
          <ProjectTasksTable {...props} />

          {!!selectContracts && selectContracts.length > 0 && (
            <div
              style={{
                position: "absolute",
                bottom: 24,
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                alignItems: "center",
                justifyContent: "center",
                right: 48,
                zIndex: 1,
                pointerEvents: "none",
              }}
            >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "nowrap",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {!isArchive && (
                    <div
                      style={{
                        backgroundColor: "#202020",
                        padding: "8px 12px",
                        color: "white",
                        marginLeft: 3,
                        marginRight: 3,
                        cursor: "pointer",
                        borderRadius: 24,
                        fontSize: 15,
                        fontWeight: 700,
                        pointerEvents: "auto",
                      }}
                      onClick={() => setIsModalContractsArchive(true)}
                    >
                      В архив
                    </div>
                  )}

                  {isArchive && (
                    <div
                      style={{
                        backgroundColor: "#202020",
                        padding: "8px 12px",
                        color: "white",
                        marginLeft: 3,
                        marginRight: 3,
                        cursor: "pointer",
                        borderRadius: 24,
                        fontSize: 15,
                        fontWeight: 700,
                        pointerEvents: "auto",
                      }}
                      onClick={() => setIsModalContractsReArchive(true)}
                    >
                      Из архив
                    </div>
                  )}
                </div>
            </div>
          )}
        </div>
      )}

      {!!modalProjectTaskAdd && (
        <ModalProjectTaskAdd
          project={modalProjectTaskAdd}
          successfulRequest={() => setIsUpdateList(!isUpdateList)}
          handleClose={() => setModalProjectTaskAdd(null)}
        />
      )}

      {!!isModalContractsArchive && (
        <ModalProjectTasksArchive
          projectTasks={selectContracts}
          project={object}
          successfulRequest={() => (setSelectContracts([]), setIsUpdateList(!isUpdateList))}
          handleClose={() => setIsModalContractsArchive(false)}
        />
      )}

      {!!isModalContractsReArchive && (
        <ModalProjectTasksReArchive
          projectTasks={selectContracts}
          project={object}
          successfulRequest={() => (setSelectContracts([]), setIsUpdateList(!isUpdateList))}
          handleClose={() => setIsModalContractsReArchive(false)}
        />
      )}
    </>
  );
}

export default ProjectTasksPage;
