import React, { Fragment, useState } from "react";
import moment from "moment";
import {getAccountFio, Icon, TextLink} from "@vokymlak/kabinet-ui";
import ModalProjectGeneralTaskExecute
  from "../../ScreenObjects/Project/components/ProjectGeneralTasks/ModalProjectGeneralTaskExecute";
import {NavLink} from "react-router-dom";

export const GeneralTasksList = (props) => {
  const [modalProjectTaskExecute, setModalProjectTaskExecute] = useState(null);

  return (
    !!props.list && (
      <Fragment>
        {props.list.map((item) => (
          <tr
            className="Table__tr Table__tr_padding Table__tr_border Table__tr_hover"
            key={"item_" + item.uuid}
            style={{
              backgroundColor: props.isRed
                ? "#ef9a9a"
                : !!item.date_execution
                ? "#a5d6a7"
                : !!item.date_control
                  ? moment(item.date_control).format("YYYY-MM-DD") ===
                  moment().format("YYYY-MM-DD")
                    ? item.general_task.is_priority
                      ? "#ffe082"
                      : item.is_priority
                        ? "#ffe082"
                        : false
                    : !!moment(
                      moment(item.date_control).format("YYYY-MM-DD")
                    ).isSameOrBefore(moment().format("YYYY-MM-DD"))
                      ? "#ef9a9a"
                      : item.general_task.is_priority
                        ? "#ffe082"
                        : item.is_priority
                          ? "#ffe082"
                          : false
                  : moment(item.general_task.date_control).format(
                    "YYYY-MM-DD"
                  ) === moment().format("YYYY-MM-DD")
                    ? item.general_task.is_priority
                      ? "#ffe082"
                      : item.is_priority
                        ? "#ffe082"
                        : false
                    : !!moment(
                      moment(item.general_task.date_control).format("YYYY-MM-DD")
                    ).isSameOrBefore(moment().format("YYYY-MM-DD"))
                      ? "#ef9a9a"
                      : item.general_task.is_priority
                        ? "#ffe082"
                        : item.is_priority
                          ? "#ffe082"
                          : false,
            }}
          >
            <td className="Table__td Table__td_border">
              <div
                className="Td__text Td__text_center"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {item.general_task.is_priority ? (
                  <Icon name={"custom-priority"} size={16} color={"#202020"} />
                ) : item.is_priority ? (
                  <Icon name={"custom-priority"} size={16} color={"#202020"} />
                ) : (
                  "-"
                )}
              </div>
            </td>

            <td className="Table__td Table__td_border">
              <div className="Td__text Td__text_center">
                <NavLink
                  to={"/project/" + item.project.uuid + "/general-tasks"}
                >
                  <div
                    className="Td__text Td__text_center"
                    style={{
                      cursor: "pointer",
                      color: "#0064ff",
                      textDecoration: "underline",
                    }}
                  >
                    {(!!item.project &&
                        !!item.project.name &&
                        item.project.name) ||
                      "-"}
                  </div>
                </NavLink>
              </div>
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text Td__text_center">
                {(!!item.general_task.title && item.general_task.title) || "-"}
              </div>
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text Td__text_center">
                {(!!item.general_task.description &&
                    item.general_task.description) ||
                  "-"}
              </div>
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text Td__text_center"
                   style={{
                     maxWidth: 600,
                   }}>
                {(!!item.note && <TextLink text={item.note} />) || "-"}
              </div>
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text Td__text_center">
                {!!item.date_control
                  ? moment(item.date_control).format("DD.MM.YYYY")
                  : !!item.general_task.date_control
                    ? moment(item.general_task.date_control).format("DD.MM.YYYY")
                    : "-"}
              </div>
            </td>
            <td className="Table__td Table__td_border">
              <div className="Td__text Td__text_center">
                {!!item.date_execution && (
                  <>
                    <div
                      className={
                        "Action__button Action__button_gray Action__button_mini"
                      }
                      onClick={() => setModalProjectTaskExecute(item)}
                    >
                      {moment(item.date_execution).format("DD.MM.YYYY")}
                    </div>
                    <div
                      style={{
                        fontSize: 12,
                        fontWeight: 400,
                      }}
                    >
                      {(!!item.executor && getAccountFio(item.executor)) || "-"}
                    </div>
                  </>
                )}
                {!item.date_execution && (
                  <div
                    className={
                      "Action__button Action__button_blue Action__button_maxi"
                    }
                    onClick={() => setModalProjectTaskExecute(item)}
                  >
                    Отметить
                  </div>
                )}
              </div>
            </td>
          </tr>
        ))}

        {!!modalProjectTaskExecute && (
          <ModalProjectGeneralTaskExecute
            object={modalProjectTaskExecute}
            successfulRequest={props.successfulRequest}
            handleClose={() => setModalProjectTaskExecute(null)}
          />
        )}
      </Fragment>
    )
  );
};
