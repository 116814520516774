import React from "react";
import {Icon, Input} from "@vokymlak/kabinet-ui";

export const ProjectContractsTableHead = (props) => {
  const {
    object,

    list,

    listControlPoints,

    isInfo,
    isAmount,
    isAddition,

    isCtExecution,

    controlGroups,
    isViewControlGroup,

    selectContracts,
    isSelectAllContract,
    changeSelectAllContract,

    isClient,
  } = props;

  return (
    !!object && (
      <tr className="Table__tr Table__tr_padding Table__tr_border">
        {isClient && (
          <>
            <th
              className="Table__th Table__th_border"
              style={{
                backgroundColor: "#fff59d",
              }}
            >
              <div className="Th__title" style={{height: 16}}>
                <Icon
                  name={"custom-circle-arrow-right"}
                  size={16}
                  color={"#20202050"}
                />
              </div>
            </th>
          </>
        )}


        {!isClient && (
          <>
            <th
              className="Table__th Table__th_border"
              style={{
                backgroundColor: "#fff59d",
              }}
            >
              <div className="Th__title" style={{height: 16}}>
                <Icon
                  name={"custom-circle-arrow-right"}
                  size={16}
                  color={"#20202050"}
                />
              </div>
            </th>
          </>
        )}

        {!isClient && (
          <>
            <th
              className="Table__th Table__th_border"
              style={{
                backgroundColor: "#fff59d",
                width: 24
              }}
            >
              <div className="Th__title" style={{height: 40}}>
                <Input type={"checkbox"} onChange={() => changeSelectAllContract(list, selectContracts)}
                       isChecked={isSelectAllContract(list, selectContracts)}/>
              </div>
            </th>
          </>
        )}

        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#fff59d",
          }}
        >
          <div className="Th__title">№</div>
        </th>

        {!isClient && (
          <>
            <th
              className="Table__th Table__th_border"
              style={{
                backgroundColor: "#fff59d",
              }}
            >
              <div className="Th__title">Обновлено</div>
            </th>
          </>
        )}

        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">Номер договора</div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">Реестровый номер</div>
        </th>
        {(isInfo || isClient) && (
          <>
            <th
              className="Table__th Table__th_border"
              style={{
                backgroundColor: "#bbdefb",
              }}
            >
              <div className="Th__title">Номер в ЕИС</div>
            </th>
            {/*<th*/}
            {/*  className="Table__th Table__th_border"*/}
            {/*  style={{*/}
            {/*    backgroundColor: "#bbdefb",*/}
            {/*  }}*/}
            {/*>*/}
            {/*  <div className="Th__title">ЕИС</div>*/}
            {/*</th>*/}
          </>
        )}
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">Основание заключения</div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">Дата заключения</div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#ffe082",
          }}
        >
          <div className="Th__title">Срок действия</div>
        </th>
        <th
          className="Table__th Table__th_border Table__th_sticky"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">Предмет</div>
        </th>

        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#bdbdbd",
          }}
        >
          <div className="Th__title">Начальная цена</div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#bdbdbd",
          }}
        >
          <div className="Th__title">Цена договора</div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#bdbdbd",
          }}
        >
          <div className="Th__title">Сумма текущего года</div>
        </th>

        {(isAmount || isClient) && (
          <>
            <th
              className="Table__th Table__th_border"
              style={{
                backgroundColor: "#bdbdbd",
              }}
            >
              <div className="Th__title">Сумма экономии</div>
            </th>
            <th
              className="Table__th Table__th_border"
              style={{
                backgroundColor: "#ffe082",
              }}
            >
              <div className="Th__title">Сумма по&nbsp;плана закупки</div>
            </th>
          </>
        )}
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#bdbdbd",
          }}
        >
          <div className="Th__title">Тип действия</div>
        </th>

        {(isCtExecution || isClient) && (
          <>
            <th
              className="Table__th Table__th_border"
              style={{
                backgroundColor: "#c5b7de",
              }}
            >
              <div className="Th__title">Источник исполнения</div>
            </th>
            <th
              className="Table__th Table__th_border"
              style={{
                backgroundColor: "#c5b7de",
              }}
            >
              <div className="Th__title">Сумма исполнения</div>
            </th>
            <th
              className="Table__th Table__th_border"
              style={{
                backgroundColor: "#c5b7de"
              }}
            >
              <div
                className="Th__title"
                style={{
                  width: 190
                }}>
                Комментарий по исполнению
              </div>
            </th>
          </>
        )}

        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#dfdfdf",
          }}
        >
          <div className="Th__title">Поставщик</div>
        </th>

        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#bbdefb",
          }}
        >
          <div className="Th__title">ЭД Договор зарегистрирован</div>
        </th>

        <>
          <th
            className="Table__th Table__th_border"
            style={{
              backgroundColor: "#bbdefb",
            }}
          >
            <div className="Th__title">Ошибка регистрации</div>
          </th>
        </>

        {!isClient && (
          <>
            <th
              className="Table__th Table__th_border"
              style={{
                backgroundColor: "#bbdefb",
              }}
            >
              <div className="Th__title">Комментарий к ошибке</div>
            </th>
          </>
        )}

        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#c5e1a5",
          }}
        >
          <div className="Th__title">Закон-основание</div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#c5e1a5",
          }}
        >
          <div className="Th__title">КП/ЕП</div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#c5e1a5",
          }}
        >
          <div className="Th__title">СМП</div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#c5e1a5",
          }}
        >
          <div className="Th__title">АГ</div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#c5e1a5",
          }}
        >
          <div className="Th__title">ЭМ</div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#c5e1a5",
          }}
        >
          <div className="Th__title">Способ закупки</div>
        </th>
        {isAddition && (
          <>
            <th
              className="Table__th Table__th_border"
              style={{
                backgroundColor: "#bbdefb",
              }}
            >
              <div className="Th__title">Результат правового анализа</div>
            </th>
            <th
              className="Table__th Table__th_border"
              style={{
                backgroundColor: "#c5cae9",
              }}
            >
              <div className="Th__title">Примечание</div>
            </th>
          </>
        )}
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#bdbdbd",
          }}
        >
          <div className="Th__title">Заявки по КП</div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#ffe082",
          }}
        >
          <div className="Th__title">Статус</div>
        </th>
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#bbdefb",
          }}
        >
          <div className="Th__title">Плановая дата</div>
        </th>

        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#bbdefb",
          }}
        >
          <div className="Th__title">Плановая дата исполнения</div>
        </th>

        {/* Точки контроля */}
        {!!listControlPoints &&
          listControlPoints.map(
            (controlPoint) =>
              (isViewControlGroup(controlGroups, controlPoint) || isClient) && (
                <th
                  className="Table__th Table__th_border"
                  key={"th_control_point_" + controlPoint.uuid}
                  style={{
                    backgroundColor: "#bbdefb",
                  }}
                >
                  <div className="Th__title">{controlPoint.name}</div>
                </th>
              )
          )}

        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#bdbdbd",
          }}
        >
          <div className="Th__title">Сумма по платежам</div>
        </th>
        {!isClient && (
          <th
            className="Table__th Table__th_border"
            style={{
              backgroundColor: "#ffe082",
            }}
          >
            <div className="Th__title">Комментарий к исполнению</div>
          </th>
        )}
        <th
          className="Table__th Table__th_border"
          style={{
            backgroundColor: "#bdbdbd",
          }}
        >
          <div className="Th__title">Остаток оплаты по контракту</div>
        </th>
      </tr>
    )
  );
};
