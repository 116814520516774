import React from "react";
import {NavLink} from "react-router-dom";
import "./SettingsMenu.scss";
import {ScrollBlock} from "../../../components/ScrollBlock/ScrollBlock";

export const SettingsMenu = () => {
  return (
    <ScrollBlock
      style={{width: '100%', height: '100%'}}>
      <div className="SettingsMenu">
        <ul className="Menu__list"></ul>

        <ul className="Menu__list Menu__list_box">
          <li className="List__link">
            <NavLink className={"Link__text"} to={"./view"}>
              Просмотр
            </NavLink>
          </li>
        </ul>
        <ul className="Menu__list Menu__list_box">
          <li className="List__link">
            <NavLink className={"Link__text"} to={"./purchase-basis"}>
              Основания закупок
            </NavLink>
          </li>
          <li className="List__link">
            <NavLink className={"Link__text"} to={"./purchase-ways"}>
              Способы закупок
            </NavLink>
          </li>
        </ul>
        <ul className="Menu__list Menu__list_box">
          <li className="List__link">
            <NavLink className={"Link__text"} to={"./contract-statuses"}>
              Статусы договора
            </NavLink>
          </li>
          <li className="List__link">
            <NavLink className={"Link__text"} to={"./law-contract-statuses"}>
              Условия статусов
            </NavLink>
          </li>
        </ul>
        <ul className="Menu__list Menu__list_box">
          <li className="List__link">
            <NavLink className={"Link__text"} to={"./control-points"}>
              Точки контроля
            </NavLink>
          </li>
          <li className="List__link">
            <NavLink className={"Link__text"} to={"./control-groups"}>
              Группы контроля
            </NavLink>
          </li>
          <li className="List__link">
            <NavLink className={"Link__text"} to={"./purchase-base-control-points"}>
              Условия контроля
            </NavLink>
          </li>
        </ul>
        <ul className="Menu__list Menu__list_box">
          <li className="List__link">
            <NavLink className={"Link__text"} to={"./contract-groups"}>
              Группы договоров
            </NavLink>
          </li>
        </ul>
        <ul className="Menu__list Menu__list_box">
          <li className="List__link">
            <NavLink className={"Link__text"} to={"./logics"}>
              Логика
            </NavLink>
          </li>
        </ul>
      </div>
    </ScrollBlock>
  );
}
