import React, {useRef, useState} from "react";
import Modal from "../../../components/Modal/Modal";
import axios from "axios";
import {ROOT_URL} from "../../../config/url";
import {Input} from "../../../components/Input";
import useList from "../../../hooks/useList";
import {getErrorMessageByKey, Select, useNotification} from "@vokymlak/kabinet-ui";
import useObject from "../../../hooks/useObject";

function ModalPurchaseBaseEdit(props) {
  const {
    list: [listLaws],
  } = useList("/api/laws", ROOT_URL, false);

  const {
    object: [object],
  } = useObject("/api/purchase-base/" + props.purchaseBase.uuid, ROOT_URL);

  const inputName = useRef(null);
  const selectLawTag = useRef(null);

  const [isDelete, setIsDelete] = useState(false);

  const {notification} = useNotification();

  const changeEdit = (event) => {
    event.preventDefault();

    const name = inputName.current.value;
    const lawTag = selectLawTag.current.value;

    if (!name || name.length === 0) {
      return;
    }

    const jsonData = JSON.stringify({
      name: name,
      law: {
        tag: lawTag.length > 0 ? (lawTag === "null" ? null : lawTag) : null,
      },
    });

    axios
      .put(
        "/api/purchase-base/" + props.purchaseBase.uuid + "/edit",
        jsonData,
        {
          withCredentials: false,
          baseURL: ROOT_URL,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("tokenSession"),
          },
        }
      )
      .then((response) => {
        props.successfulRequestEdit(response.data);
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });

    props.handleClose();
  };

  const changeDelete = (event) => {
    event.preventDefault();

    axios
      .delete("/api/purchase-base/" + props.purchaseBase.uuid + "/delete", {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });

    props.handleClose();
  };

  const getLawsSelectOptions = (list) => {
    const options = [];

    list.map((item) => {
      const option = {
        value: item.tag,
        title: item.name,
      };

      options.push(option);
    });

    return options;
  };

  return (
    !!object &&
    !!listLaws && (
      <Modal handleClose={() => props.handleClose}>
        {!isDelete && (
          <form className="Form" onSubmit={(event) => changeEdit(event)}>
            <div className="Form__title">
              {object.name}
              <div className="Text__signature">Основание закупки</div>
            </div>

            <div className="Form__field">
              <Input
                reference={inputName}
                type="text"
                label="Наименование *"
                value={object.name}
              />
            </div>

            <div className="Form__field Form__field_last">
              <Select
                isFixed={true}
                reference={selectLawTag}
                options={
                  !!listLaws
                    ? getLawsSelectOptions(listLaws)
                    : getLawsSelectOptions([])
                }
                label="Закон *"
                value={!!object.law ? String(object.law.tag) : "null"}
              />
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_blue">
                Применить
              </button>
            </div>

            <div className="Form__actions">
              <div
                className="Action__link Action__link_red"
                onClick={() => setIsDelete(true)}
              >
                Удалить
              </div>
              <div className="Action__link" onClick={() => props.handleClose()}>
                Отмена
              </div>
            </div>
          </form>
        )}
        {isDelete && (
          <form className="Form" onSubmit={(event) => changeDelete(event)}>
            <div className="Form__title">
              {object.name}
              <div className="Text__signature">Удалить</div>
            </div>

            <div className="Form__actions Form__actions_center">
              <button className="Action__button Action__button_all Action__button_red">
                Продолжить
              </button>
            </div>

            <div className="Form__actions Form__actions_center">
              <div className="Action__link" onClick={() => setIsDelete(false)}>
                Отмена
              </div>
            </div>
          </form>
        )}
      </Modal>
    )
  );
}

export default ModalPurchaseBaseEdit;
