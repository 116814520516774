import {useEffect, useRef, useState} from "react";
import { ROOT_URL } from "../../../../../config/url";
import {useList, useAction, useObject, useUnroll} from "@vokymlak/kabinet-ui";

function useProjectPfhd(props) {
  const {
    list: [list, count, setList],
    update: [isUpdateList, setIsUpdateList, updateList],
    page: [pageNumber, setPageNumber, pageLimit, setPageLimit],
    search: [searchQuery, setSearchQuery],
  } = useList({
    apiUrl: "/api/project/" + props.project.uuid + "/pfhd",
    rootUrl: ROOT_URL,
    isPagination: true,
    paginationLimit: 100,
    isSearch: true,
    urlParams: {
      "not-null": "true"
    }
  });

  const {
    object: [amount, setAmount],
    update: [isUpdateAmount, setIsUpdateAmount, updateAmount],
  } = useObject({
    apiUrl: "/api/project/" + props.project.uuid + "/pfhd/amount",
    rootUrl: ROOT_URL,
  });

  const inputSearchText = useRef(null);
  useEffect(() => {
    setPageNumber(1);
  }, [searchQuery]);

  function onChangeSearchText() {
    setSearchQuery(inputSearchText.current.value.trim());
  }

  const changePage = (data) => {
    if (pageNumber !== data.currentPage) {
      setPageNumber(data.currentPage);
    }
  };

  const { setAction } = useAction(props.project.uuid, () => (updateList(), updateAmount()));

  useEffect(() => {
    if (!!list) {
      const listUuid = list.map((i) => i.uuid);
      setAction([...listUuid, props.project.uuid]);
    } else {
      setAction(props.project.uuid);
    }
  }, [list]);

  const [modalImport, setModalImport] = useState(null);
  const [modalViewTable, setModalViewTable] = useState(null);

  const { unrolls, isUnroll, changeUnroll, changeAllUnroll } = useUnroll();

  return {
    searchQuery,
    inputSearchText,
    onChangeSearchText,

    object: props.project,

    amount,
    isUpdateAmount,
    setIsUpdateAmount,

    list,
    setList,
    count,

    pageNumber,
    pageLimit,
    setPageLimit,
    changePage,

    setIsUpdateList,
    isUpdateList,

    setModalImport,
    modalImport,

    modalViewTable,
    setModalViewTable,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,
  };
}

export default useProjectPfhd;
