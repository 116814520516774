import React, { useEffect, useRef, useState } from "react";
import { ROOT_URL } from "../../../../../config/url";
import {useList, useAction, useNotification, useUnroll} from "@vokymlak/kabinet-ui";
import {useViewer} from "../../../../../components/Viewer";

function useContractActs(props) {
  const [selectAct, setSelectAct] = useState(null);

  const [countActAmount, setCountActAmount] = useState(0);
  const [countPaymentAmount, setCountPaymentAmount] = useState(0);

  const {notification} = useNotification();

  const { viewer } = useViewer();

  const changeViewer = (image, mimeType) => {
    viewer(image, mimeType);
  };

  const {
    list: [list, count, setList],
    update: [isUpdateList, setIsUpdateList, updateList],
  } = useList({
    apiUrl: "/api/contract/" + props.contract.uuid + "/acts",
    rootUrl: ROOT_URL,
  });

  useEffect(() => {
    if (!!list) {
      let countActAmount = 0;
      let countPaymentAmount = 0;

      list.forEach((i) => {
        countActAmount += i.amount;

        if (!!i.payments) {
          const paymentAmount = i.payments.map(a => ((!!a.amount && a.amount) || 0)).reduce((a, b) => a + b, 0)
          countPaymentAmount += paymentAmount;
        }
      });

      setCountActAmount(countActAmount);
      setCountPaymentAmount(countPaymentAmount);
    }
  }, [list]);

  const { unrolls, isUnroll, changeUnroll, changeAllUnroll } = useUnroll();

  const { setAction } = useAction(props.contract.uuid, () => updateList());

  useEffect(() => {
    if (!!list) {
      const listUuid = list.map((i) => i.uuid);
      setAction([...listUuid, props.contract.uuid]);
    } else {
      setAction(props.contract.uuid);
    }
  }, [list]);

  const [modalActImport, setModalActImport] = useState(null);
  const [modalActAdd, setModalActAdd] = useState(null);
  const [modalActEdit, setModalActEdit] = useState(null);

  return {
    object: props.contract,

    countActAmount,
    countPaymentAmount,

    list,
    setList,
    count,

    setIsUpdateList,
    isUpdateList,

    unrolls,
    isUnroll,
    changeUnroll,
    changeAllUnroll,

    modalActAdd,
    setModalActAdd,

    modalActEdit,
    setModalActEdit,

    setModalActImport,
    modalActImport,

    selectAct,
    setSelectAct,

    changeViewer,
  };
}

export default useContractActs;
