import React from "react";
import styleTumbler from "../../../../../styles/modules/Tumbler.module.scss";
import {
  Search,
  ButtonIconExport, Select, Pagination, ButtonIcon,
} from "@vokymlak/kabinet-ui";
import {
  ProjectContractsTable
} from "../../../../ScreenObjects/Project/components/ProjectContracts/ProjectContractsTable";

function ProjectContractsView(props) {
  const {
    searchParam,

    object,

    list,
    setList,
    count,

    pageNumber,
    pageLimit,
    setPageLimit,
    changePage,

    setIsUpdateList,
    isUpdateList,

    inputSearchText,
    onChangeSearchText,
    searchQuery,

    apiUrl,
    apiUrlParams,

    isUpdateTotal,
    setIsUpdateTotal,

    selectView,
    setSelectView,

    isUis,
    setIsUis,

    isRis,
    setIsRis,

    setIsRegistrationError,
    isRegistrationError,

    setIsCtClosing,
    isCtClosing
  } = props;

  return (
    !!object && (
      <>
        <div
          className="Page__title"
          style={{
            marginTop: 0,
            marginBottom: 8,
          }}
        >
          <div className="Title__text">
            <div
              className="Title__text"
              style={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
                fontSize: 19,
                fontWeight: 700,
                marginRight: 6,
              }}
            >
              <div>
                Договоры
                {!!list && (
                  <span style={{ fontSize: 12, opacity: 0.75, marginLeft: 6 }}>
                    ({count})
                  </span>
                )}
              </div>
            </div>

            <span style={{ width: 140, marginLeft: 6, marginRight: 6 }}>
              <Search
                reference={inputSearchText}
                isFixed={true}
                label={"Поиск"}
                onChange={() => onChangeSearchText()}
                value={searchParam}
              />
            </span>

            {/*<ButtonIconExport*/}
            {/*  tooltipText={"Выгрузить"}*/}
            {/*  iconName={"custom-download"}*/}
            {/*  iconColor={"#202020"}*/}
            {/*  size={24}*/}
            {/*  style={{ marginLeft: 12 }}*/}
            {/*  apiUrl={apiUrl + "/export/xlsx?" + apiUrlParams}*/}
            {/*  rootUrl={ROOT_URL}*/}
            {/*  mimeType={*/}
            {/*    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"*/}
            {/*  }*/}
            {/*  fileName={(*/}
            {/*    object.name.trim().replaceAll(" ", "_") +*/}
            {/*    "_договоры_на_" +*/}
            {/*    moment().format("DD_MM_YYYY") +*/}
            {/*    ".xlsx"*/}
            {/*  ).toLowerCase()}*/}
            {/*/>*/}
          </div>

          <div
            className="Title__settings"
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
              justifyContent: "center",
            }}
          >
            <ul
              className={styleTumbler._}
              style={{
                marginRight: 2,
                marginLeft: 4,
              }}
            >
              <li className={styleTumbler._link}>
                <a
                  className={
                    styleTumbler._text +
                    ((!!selectView &&
                        selectView === "all" &&
                        " " + styleTumbler.active + " ") ||
                      "")
                  }
                  onClick={() => setSelectView("all")}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  Все
                </a>
              </li>
              <li className={styleTumbler._link}>
                <a
                  className={
                    styleTumbler._text +
                    ((!!selectView &&
                        selectView === "current" &&
                        " " + styleTumbler.active + " ") ||
                      "")
                  }
                  onClick={() => setSelectView("current")}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  Действующие
                </a>
              </li>
              <li className={styleTumbler._link}>
                <a
                  className={
                    styleTumbler._text +
                    ((!!selectView &&
                        selectView === "completed" &&
                        " " + styleTumbler.active + " ") ||
                      "")
                  }
                  onClick={() => setSelectView("completed")}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  Завершенные
                </a>
              </li>
            </ul>

            <ul
              className={styleTumbler._}
              style={{
                marginRight: 2,
                marginLeft: 4,
              }}
            >
              <li className={styleTumbler._link}>
                <a
                  className={
                    styleTumbler._text +
                    ((isRis &&
                        " " + styleTumbler.active + " ") ||
                      "")
                  }
                  onClick={isRis ? () => setIsRis(!isRis) : () => (setIsRis(!isRis))}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  РИС
                </a>
              </li>
            </ul>
            <ul
              className={styleTumbler._}
              style={{
                marginRight: 2,
                marginLeft: 4,
              }}
            >
              <li className={styleTumbler._link}>
                <a
                  className={
                    styleTumbler._text +
                    ((isUis &&
                        " " + styleTumbler.active + " ") ||
                      "")
                  }
                  onClick={isUis ? () => setIsUis(!isUis) : () => (setIsUis(!isUis))}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  ЕИС
                </a>
              </li>
            </ul>
            <ul
              className={styleTumbler._}
              style={{
                marginRight: 2,
                marginLeft: 4,
              }}>
              <li className={styleTumbler._link}>
                <a
                  className={
                    styleTumbler._text +
                    ((isRegistrationError &&
                        " " + styleTumbler.active + " ") ||
                      "")
                  }
                  onClick={() => setIsRegistrationError(!isRegistrationError)}
                  style={{
                    cursor: "pointer",
                    position: "relative",
                  }}
                >
                  Ошибки

                  <div
                    style={{
                      position: "absolute",
                      top: -8,
                      right: -8,
                      display: "flex",
                    }}
                  >
                    {(!!object.count_registration_errors &&
                      object.count_registration_errors > 0) && (
                      <div
                        className={"Collected__count Collected__count_red"}
                        style={{
                          padding: "0 3px",
                          borderRadius: 24,
                          height: 16,
                          fontSize: 12,
                          fontWeight: 700
                        }}
                      >
                        {object.count_registration_errors}
                      </div>
                    )}
                  </div>


                </a>
              </li>
            </ul>
            <ul
              className={styleTumbler._}
              style={{
                marginRight: 2,
                marginLeft: 4,
              }}>
              <li className={styleTumbler._link}>
                <a
                  className={
                    styleTumbler._text +
                    ((isCtClosing &&
                        " " + styleTumbler.active + " ") ||
                      "")
                  }
                  onClick={() => setIsCtClosing(!isCtClosing)}
                  style={{
                    cursor: "pointer",
                    position: "relative",
                  }}
                >
                  Закрытие
                  <div
                    style={{
                      position: "absolute",
                      top: -8,
                      right: -8,
                      display: "flex",
                    }}
                  >
                    {(!!object.count_ct_closing &&
                      object.count_ct_closing > 0) && (
                      <div
                        className={"Collected__count Collected__count_red"}
                        style={{
                          padding: "0 3px",
                          borderRadius: 24,
                          height: 16,
                          fontSize: 12,
                          fontWeight: 700,
                          backgroundColor: "#673ab7",
                          borderColor: "#673ab7"
                        }}
                      >
                        {object.count_ct_closing}
                      </div>
                    )}
                  </div>
                </a>
              </li>
            </ul>
          </div>

          <div className="Title__settings">
            <ButtonIcon
              tooltipText={"Обновить"}
              iconName={"custom-change-circle"}
              iconColor={"#20202075"}
              size={28}
              style={{marginLeft: 12}}
              onClick={() => (setList(null), setIsUpdateList(!isUpdateList))}
            />

            {!!count && !!pageLimit && (
              <div
                style={{
                  marginLeft: 12,
                  width: 56,
                }}
              >
                <Select
                  isFixed={true}
                  isDefaultOption={false}
                  value={pageLimit}
                  options={[
                    {
                      value: 250,
                      title: 250,
                    },
                    {
                      value: 500,
                      title: 500,
                    },
                    {
                      value: 750,
                      title: 750,
                    },
                    {
                      value: 1000,
                      title: 1000,
                    },
                  ]}
                  label="Лимит"
                  onChange={(event) => setPageLimit(Number(event.target.value))}
                />
              </div>
            )}

            {!!count && count > pageLimit && (
              <div
                style={{
                  marginLeft: 12,
                }}
              >
                <Pagination
                  totalRecords={count}
                  pageLimit={pageLimit}
                  pageNeighbours={3}
                  currentPage={pageNumber}
                  onPageChanged={(data) => changePage(data)}
                />
              </div>
            )}
          </div>
        </div>

        {!!list && (
          <div
            className="Page__content show"
            style={{
              position: "relative",
              height: "calc(100% - 48px - 26px - 2px - 10px - 10px)",
            }}
          >
            <div
              style={{
                overflow: "auto",
                width: "100%",
                height: "100%",
              }}
            >
              <ProjectContractsTable {...props} isClient={true} />
            </div>
          </div>
        )}
      </>
    )
  );
}

export default ProjectContractsView;
