import React, {useRef, useState} from "react";
import axios from "axios";
import { ROOT_URL } from "../../../../../config/url";
import {
  getErrorMessageByKey,
  useNotification,
  Modal,
  useObject,
  Input,
  useList, Select, ButtonIcon,
} from "@vokymlak/kabinet-ui";
import moment from "moment/moment";

function ModalActItemAdd(props) {
  const {
    list: [listSpecifications],
  } = useList({ apiUrl: "/api/contract/" + props.contract.uuid + "/specifications", rootUrl: ROOT_URL });

  const {
    object: [contract],
  } = useObject({
    apiUrl: "/api/contract/" + props.contract.uuid,
    rootUrl: ROOT_URL,
    callbackError: () => {
      props.handleClose();
    },
  });

  const {
    object: [object],
  } = useObject({
    apiUrl: "/api/contract-act/" + props.act.uuid,
    rootUrl: ROOT_URL,
    callbackError: () => {
      props.handleClose();
    },
  });

  const [itemAmountVat, setItemAmountVat] = useState(null)

  const inputAmount = useRef(null);
  const inputAmountVat = useRef(null);

  const inputVolume = useRef(null);

  const selectContractStageId = useRef(null);

  const { notification } = useNotification();

  const numberConvert = (numberString) => {
    return !!numberString && numberString.length > 0
      ? Number(String(Number(numberString).toFixed(2)).replace(".", ""))
      : null;
  };

  const numberConvertV = (numberString) => {
    return !!numberString && numberString.length > 0
      ? Number(String(Number(numberString).toFixed(3)).replace(".", ""))
      : null;
  };

  const changeAdd = (event) => {
    event.preventDefault();

    const amount = inputAmount.current.value;
    const amountVat = inputAmountVat.current.value;

    const volume = inputVolume.current.value;

    const contractStageId = selectContractStageId.current.value;

    const jsonData = JSON.stringify({
      amount: numberConvert(amount),
      amount_vat: numberConvert(amountVat),

      volume: numberConvertV(volume),

      contract_specification_id:
        contractStageId.length > 0
          ? contractStageId === "null"
            ? null
            : Number(contractStageId)
          : null,
    });

    axios
      .post("/api/contract-act/" + props.act.uuid + "/item/add", jsonData, {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("tokenSession"),
        },
      })
      .then(() => {
        props.successfulRequest();
        notification("Акт добавлен", "green");
        props.handleClose();
      })
      .catch((error) => {
        notification(getErrorMessageByKey(error.response.data.error), "red");
      });
  };

  const getContractStageSelectOptions = (list) => {
    const options = [
      {
        value: "null",
        title: "Не выбрано",
      },
    ];

    list.map((item) => {
      const option = {
        value: item.id,
        title: item.registry_index  + " - " + item.registry_number + " - " + item.spgz,
      };

      options.push(option);
    });

    return options;
  };

  const changeCalculateVat = (percent) => {
    const amount = numberConvert(inputAmount.current.value);
    let amountVat = 0
    if (!!amount) {
      amountVat = Number(((amount / 100) * percent).toFixed(2));
    }
    setItemAmountVat(amountVat)
  }

  return (!!object &&
    !!contract && (
      <Modal handleClose={() => props.handleClose()}>
        <form className="Form" onSubmit={(event) => changeAdd(event)}>
          <div className="Form__title">
            {!!object.number && "№ " + object.number} {!!object.date_number && " от " + moment(object.date_number).format("DD.MM.YYYY")}
            <div style={{
              fontSize: 17
            }}>
              Новая
            </div>
            <div className="Text__signature">Позиция</div>
          </div>

          <div className="Form__field">
            <Select
              isFixed={true}
              reference={selectContractStageId}
              options={!!listSpecifications ? getContractStageSelectOptions(listSpecifications) : getContractStageSelectOptions([])}
              label="Наименование услуг"
            />
          </div>

          <div className="Form__field" style={{
            opacity: contract.is_without_volume && 0.33
          }}>
            <Input
              reference={inputVolume}
              type="number"
              label="Объем"
              disabled={contract.is_without_volume}
            />
          </div>

          <div className="Form__field">
            <Input
              reference={inputAmount}
              type="number"
              label="Сумма"
            />
          </div>

          <div className="Form__field">
            <Input
              reference={inputAmountVat}
              isFixed={true}
              type="number"
              label="Сумма НДС"
              value={!!itemAmountVat && (itemAmountVat / 100).toFixed(2)}
            />
          </div>

          <div className="Form__field Form__field_last" style={{
            display: "flex",
            marginTop: 12
          }}>
            <div
              style={{
                backgroundColor: "#0061ff",
                padding: "0 4px",
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 24,
                cursor: "pointer"
              }}
              onClick={() => changeCalculateVat(10)}
            >
              <div
                style={{
                  padding: "4px 6px",
                  color: "white",
                  fontSize: 14,
                  pointerEvents: "auto",
                  fontWeight: 700,
                  cursor: "pointer"
                }}
              >
                10%
              </div>
              <ButtonIcon
                tooltipText={"Вычислить"}
                iconName={"custom-calculate"}
                iconColor={"white"}
                size={18}
              />
            </div>

            <div
              style={{
                backgroundColor: "#0061ff",
                padding: "0 4px",
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 24,
                marginLeft: 12,
                cursor: "pointer"
              }}
              onClick={() => changeCalculateVat(20)}
            >
              <div
                style={{
                  padding: "4px 6px",
                  color: "white",
                  fontSize: 14,
                  pointerEvents: "auto",
                  fontWeight: 700,
                  cursor: "pointer"
                }}
              >
                20%
              </div>
              <ButtonIcon
                tooltipText={"Вычислить"}
                iconName={"custom-calculate"}
                iconColor={"white"}
                size={18}
              />
            </div>
          </div>


          <div className="Form__actions Form__actions_center">
            <button className="Action__button Action__button_all Action__button_blue">
              Добавить
            </button>
          </div>
        </form>
      </Modal>
    )
  );
}

export default ModalActItemAdd;
