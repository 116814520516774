import React from "react";
import { ProjectPfhdTableHead } from "./ProjectPfhdTableHead";
import { ProjectPfhdList } from "./ProjectPfhdList";

export const ProjectPfhdTable = (props) => {
  const { object, list } = props;

  return (
    !!object && (
      <table className="Table">
        <thead className="Thead">
          <ProjectPfhdTableHead {...props} />
        </thead>
        <tbody>{!!list && <ProjectPfhdList {...props} />}</tbody>
      </table>
    )
  );
};
