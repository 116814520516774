import React from "react";
import ProjectFinanceClientTasks from "./components/ProjectFinanceClientTasks/ProjectFinanceClientTasks";

function ProjectTasksPage(props) {
  return (
    <>
      <ProjectFinanceClientTasks {...props}/>
    </>
  );
}

export default ProjectTasksPage;
